import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

import { getHosRespsDetailQuery, getHospitalQuery } from '../graphql';
import { Flex } from '../components/atomic/atoms/Flex';
import { Title } from '../components/atomic/atoms/Title/Title';
import DatePicker from '../components/atomic/atoms/Select/DatePicker';
import { PaddingDiv } from '../components/basic/HtmlComponents';
import { ReactComponent as DropDownIcon } from '../images/drop-down.svg';
import FlexGrid from '../components/atomic/molecules/FlexGrid';
import StretchButton from '../components/atomic/atoms/Button/StretchButton';
import InputButton from '../components/atomic/atoms/Button/InputButton';
import { sendSlack } from '../utils/slack';
import { OdocDateFomatter2 } from '../utils/dates';


const Container = styled.div`
  padding: 120px 20px;
  margin: 0;
`;

const Header = styled.div`
  width: 100%;
  height: 58px;
  position: absolute;
  top: 0;
  left: 0;
  .prev-icon{
    width: 24px;
    height: 24px;
    padding: 17px 20px;
    cursor: pointer;
    box-sizing: content-box;
  }
`;


const ExtendedTitle = styled(Title)`
  font-size: 26px;
  letter-spacing: -.6px;
  color: #000;
  font-weight: bold;
  line-height: 34px; 
  margin-top: 0;
`;


const StyledTitleDescription = styled.div`
  margin-top: 10px;
  line-height: 20px;
  font-size: 14px;
  letter-spacing: -0.4px;
  color: #b1b3b2;
`;

const InputArea = styled.div`
  margin-top: 57px;
  width: 100%;
`;

const DatePickerWrapper = styled.div`
  .reservation_date_picker {
    .SingleDatePicker {
      width: 100%;
      .SingleDatePickerInput__withBorder {
        border: none;
        width: 100%;
        .SingleDatePickerInput_calendarIcon {
          position: absolute;
          z-index: 1;
          right: 0;
          top: 9px;
        }
        
        .DateInput {
          width: 100%;
          height: auto;
          input {
            border-radius: 4px;
            border: solid 1px #e1e3e2;
            background-color: #ffffff;
            padding: 17px 14px;
            font-size: 13px;
            font-family: 'Saira Semi Condensed';
            font-weight: normal;
            font-stretch: semi-condensed;
            font-style: normal;
            line-height: 1.54;
            letter-spacing: normal;
            color: #000000;
            min-height: 56px;
          }
        }
        .SingleDatePickerInput_arrow {
          display: none;
        }
      }
      .SingleDatePickerInput_clearDates {
        right: -23px;
      }
    }
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 15px;
  position: relative;
  margin-bottom: 5px;
`;

const StyledButton = styled.div`
  margin: 5px 0;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Saira Semi Condensed';
`;

const BottomBtnWapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 64px;
  text-align: center;
  button {
    max-width: 760px;
  }
  button > div {
    font-weight: 600;
    font-size: 14px;
  }
`;

const timeOptions = [
  {
    id: '9:00',
    name: '9:00',
    description: null,
    value: '9:00',
  },
  {
    id: '9:30',
    name: '9:30',
    description: null,
    value: '9:30',
  },
  {
    id: '10:00',
    name: '10:00',
    description: null,
    value: '10:00',
  },
  {
    id: '10:30',
    name: '10:30',
    description: null,
    value: '10:30',
  },
  {
    id: '11:00',
    name: '11:00',
    description: null,
    value: '11:00',
  },
  {
    id: '11:30',
    name: '11:30',
    description: null,
    value: '11:30',
  },
  {
    id: '12:00',
    name: '12:00',
    description: null,
    value: '12:00',
  },
  {
    id: '12:30',
    name: '12:30',
    description: null,
    value: '12:30',
  },
  {
    id: '14:00',
    name: '14:00',
    description: null,
    value: '14:00',
  },
  {
    id: '14:30',
    name: '14:30',
    description: null,
    value: '14:30',
  },
  {
    id: '15:00',
    name: '15:00',
    description: null,
    value: '15:00',
  },
  {
    id: '15:30',
    name: '15:30',
    description: null,
    value: '15:30',
  },
  {
    id: '16:00',
    name: '16:00',
    description: null,
    value: '16:00',
  },
  {
    id: '16:30',
    name: '16:30',
    description: null,
    value: '16:30',
  },
  {
    id: '17:00',
    name: '17:00',
    description: null,
    value: '17:00',
  },
  {
    id: '17:30',
    name: '17:30',
    description: null,
    value: '17:30',
  },
  {
    id: '18:00',
    name: '18:00',
    description: null,
    value: '18:00',
  },
];


class ReservationCreate extends Component {
  state = {
    date: null, // 희망내원일
    times: [], // 희망내원시간
    requested: false, // 요청 완료되었나요?
  };

  toggleTimeItem = (time) => {
    const { times } = this.state;
    const newTimes = _.xor(times, [time]);

    this.setState({ times: newTimes });
  }

  onSubmit = () => {
    const { date, times } = this.state;
    const {
      getHospital: { getHospital },
      getHosRespsDetail: { getHosRespsDetail },
      match,
    } = this.props;

    // - 답변 아이디
    const { hrid } = match.params;

    // - 병원 이름 (병원 전화번호)
    const { id: hospitalId, name: hospitalName, phone: hospitalPhone } = getHospital;

    // - 사용자 이름 (사용자 전화번호)
    const { username: userName, id: userId, phonenum: userPhone } = getHosRespsDetail?.userRequest?.user;

    // 내원 희망일
    const dueDate = OdocDateFomatter2(date);

    // 내원 희망 시간
    const dueTimes = JSON.stringify(times);

    const msg = '[예약 요청]\n'
      + `유저: ${userName}(${userId}) / ${userPhone}\n`
      + `병원: ${hospitalName}(${hospitalId}) / ${hospitalPhone}\n`
      + `답변 아이디: ${hrid}\n`
      + `내원 희망일: ${dueDate}\n`
      + `내원 희망 시간: ${dueTimes}\n`;

    // send slackMsg
    sendSlack(msg, true, process.env.REACT_APP_SLACK_WEBHOOK_RESERVATION_URL);

    // 완료플래그 update
    this.setState({ requested: true });
  }

  goBack = () => {
    const {
      match,
      history,
    } = this.props;

    history.push(`/hosresps/${match?.params?.hrid}/hid/${match?.params?.hid}`);
  }

  render() {
    const {
      getHosRespsDetail: { getHosRespsDetail },
      getHospital: { getHospital },
    } = this.props;
    const { date, times, requested } = this.state;

    if (!getHosRespsDetail) {
      return null;
    }

    return (
      <Container>
        <div>
          {requested ? (
            <div>
              <Flex
                column
                justifyContent="space-between"
              >
                <div style={{ width: '100%' }}>
                  <ExtendedTitle>
                    예약이
                    <br />
                    요청되었습니다.
                  </ExtendedTitle>
                  <StyledTitleDescription>
                    병원의 일정을 확인하여,
                    <br />
                    예약이 확정되면 메시지로 알려드리니 잠시만 기다려주세요!
                    <PaddingDiv padding="5px 0" />
                    주말이나 야간에 예약을 요청하실 경우
                    <br />
                    병원의 일정 확인이 다소 늦어질 수 있으니 양해 부탁드려요.
                  </StyledTitleDescription>
                </div>
              </Flex>
              <BottomBtnWapper>
                <StretchButton
                  fixed
                  disabled={(date === null || times?.length === 0)}
                  onClick={this.goBack}
                >
                  확인
                </StretchButton>
              </BottomBtnWapper>
            </div>
          ) : (
            <>
              {/* 백버튼 영역 */}
              <Header>
                <div
                  className="prev-icon"
                  onClick={this.goBack}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path fill="#A4A4A4" fillRule="evenodd" d="M23.865 10.08v3l-18.157-.001 6.279 6.278-2.122 2.122-9.9-9.9 9.9-9.9 2.122 2.122-6.278 6.278h18.156z" />
                  </svg>
                </div>
              </Header>
              {/* 타이틀. 설명, 버튼, 플로팅버튼 */}
              <div>
                <Flex
                  column
                  justifyContent="space-between"
                >
                  <div style={{ width: '100%' }}>
                    <ExtendedTitle>
                      {getHospital?.name}
                      에
                      <br />
                      언제 방문하고 싶으신가요?
                    </ExtendedTitle>
                    <StyledTitleDescription>
                      병원의 일정 확인 후에 예약이 완료됩니다.
                      <br />
                      복수선택 가능합니다 :)
                    </StyledTitleDescription>
                  </div>
                  <InputArea>
                    <DatePickerWrapper>
                      <DatePicker
                        id="reservation_date"
                        date={date}
                        onDateChange={newDate => this.setState({ date: newDate })}
                        placeholder="날짜 선택"
                        className="reservation_date_picker"
                        customInputIcon={<DropDownIcon style={{ verticalAlign: 'middle' }} />}
                        displayFormat="YYYY년 MM월 DD일"
                        readOnly
                        numberOfMonths={1}
                      />
                    </DatePickerWrapper>
                    <ButtonWrapper>
                      <FlexGrid
                        column
                        items={timeOptions}
                        cols={3}
                        render={item => (
                          <StyledButton>
                            <InputButton
                              onClick={() => this.toggleTimeItem(item.value)}
                              buttonType="textButton"
                              title={item.name}
                              description={item.description}
                              image={item.image}
                              active={_.indexOf(times, item.value) !== -1}
                            />
                          </StyledButton>
                        )}
                      />
                    </ButtonWrapper>
                  </InputArea>
                </Flex>
                <BottomBtnWapper>
                  <StretchButton
                    fixed
                    disabled={(date === null || times?.length === 0)}
                    onClick={this.onSubmit}
                  >
                    예약 요청완료
                  </StretchButton>
                </BottomBtnWapper>
              </div>
            </>
          )}
        </div>
      </Container>
    );
  }
}

export default compose(
  graphql(getHosRespsDetailQuery, {
    name: 'getHosRespsDetail',
    options: ({ match }) => ({
      variables: { hrid: match.params.hrid },
      fetchPolicy: 'network-only',
    }),
  }),
  graphql(getHospitalQuery, {
    name: 'getHospital',
    options: ({ match }) => ({
      variables: { id: match.params.hid },
      fetchPolicy: 'network-only',
    }),
  }),
)(withRouter(ReservationCreate));
