// RespsHsDetail.js
// /hosresps/1  - 맞춤상담/다이렉트상담
import React, { Component } from 'react';
import styled from 'styled-components';
import { compose, graphql } from 'react-apollo';
import withToast from '../utils/toasthoc';
import withAlertModal from '../utils/alerthoc';
import HospitalInfoContainer from '../containers/HospitalInfoContainer';
import {
  getHospitalQuery,
  getHosRespsDetailQuery,
  submitReservationMutation,
  phoneReservationMutation,
} from '../graphql';
import { media } from '../globalStyle';
import CounselResponseComponent from '../components/ReqsHsDetail/CounselResponseComponent';
import MyCounselInfo from '../components/ReqsHsDetail/MyCounselInfo';

import ReservationIcon from '../images/reservation.png';
import ReservationDefaultIcon from '../images/reservation-default.png';
import ReservationConfirmModal from '../components/ReqsHsDetail/ReservationConfirmModal';
import MobileCallModal from '../components/MobileCallModal';
import { logEvent } from '../utils/loging';
import { meQuery } from '../graphql/me';

const PageWrapper = styled.div`
  max-width: 1080px;
  margin: 0 auto;
  padding: 40px 20px;
  ${media.tablet`
    padding-top: 58px;
    padding-bottom: 90px;
  `};
`;

const ReservationButton = styled.button`
  padding: 0;
  border: 0;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.6px;
  text-align: center;
  margin-left: 39px;
  color: #033e52;
  &:disabled {
    color: #bbbcbc;
  }
  img {
    width: 36px;
    height: 36px;
    object-fit: contain;
    margin-bottom: 7px;
  }
`;

// 맞춤상담/다이렉트상담
class RespsHsDetail extends Component {
  state = {
    isOpenReservationConfirmModal: false,
    isOpenPhoneModal: false,
  };

  reserve = async (requirements, availtime) => {
    try {
      const {
        match,
        getHosRespsDetail: { getHosRespsDetail },
        submitReservation,
        toast,
      } = this.props;
      const variables = {
        ureqId: getHosRespsDetail.userRequest.id,
        hospitalId: match.params.hid,
      };

      if (requirements) {
        variables.requirements = requirements;
      }

      if (availtime) {
        variables.availtime = availtime;
      }

      const res = await submitReservation({
        variables,
      });

      const { data } = res;
      if (data && data.submitReservation && data.submitReservation.id) {
        toast(
          <div>
            {'예약이 완료되었습니다.'}
            <br />
            {'자세한 예약정보는 예약내역에서 확인하세요.'}
          </div>,
          'left',
        );
      }
    } catch (e) {
      // todo : 예외처리
      // console.error(e);
    }
  };

  // 전화할때에도 예약을 넣어준다.
  reserveByCall = async () => {
    try {
      const {
        match,
        getHosRespsDetail: { getHosRespsDetail },
        phoneReservation,
      } = this.props;

      const variables = {
        ureqId: getHosRespsDetail.userRequest.id,
        hospitalId: match.params.hid,
      };

      const res = await phoneReservation({
        variables,
      });
      if (res?.error) {
        throw Error(res.error);
      }
    } catch (e) {
      // todo : 예외처리
      // console.error(e);
    }
  };

  render() {
    const {
      hospital: { getHospital, loading },
      getHosRespsDetail: { getHosRespsDetail, refetch },
      meQuery: { me },
      toast,
    } = this.props;

    const {
      isOpenPhoneModal,
      isOpenReservationConfirmModal,
    } = this.state;

    // todo : getHosRespsDetail 호출할때 제대로 바인딩이 안됨
    // 쿼리 하나로 merge
    if (loading || this.props.getHosRespsDetail.loading) {
      return null;
    }

    if (!getHosRespsDetail?.userRequest) {
      return null;
    }

    // 답변 컴포넌트
    const respsComponent = (
      <CounselResponseComponent
        reqType={getHosRespsDetail.userRequest.reqType}
        refetch={refetch}
        reqId={getHosRespsDetail.ureqId}
        hid={getHosRespsDetail.hospitalId}
        priceResult={getHosRespsDetail}
        hospitalName={getHospital.name}
        discount={getHosRespsDetail.discount}
        userRequest={getHosRespsDetail.userRequest}
        toast={toast}
      />
    );

    // 상담 정보 컴포넌트
    const reqComponent = (
      <MyCounselInfo userreq={getHosRespsDetail.userRequest} />
    );

    // 예약버튼 컴포넌트
    const reservationBtnComponent = (
      <ReservationButton
        onClick={() => {
          this.setState({ isOpenReservationConfirmModal: true });
          logEvent({
            category: '답변(병원) 상세',
            action: '클릭',
            label: '예약하기',
          });
        }}
        disabled={getHosRespsDetail.reserved}
      >
        <img
          src={!getHosRespsDetail.reserved ? ReservationIcon : ReservationDefaultIcon}
          alt="path"
        />
        <div>{getHosRespsDetail.reserved ? '예약완료' : '예약하기'}</div>
      </ReservationButton>
    );

    //  예약버튼 컴포넌트(모바일)
    const reservationMobileBtnComponent = (
      <button
        type="button"
        onClick={() => {
          this.setState({ isOpenReservationConfirmModal: true });
          logEvent({
            category: '답변(병원) 상세',
            action: '클릭',
            label: '예약하기',
          });
        }}
        disabled={getHosRespsDetail.reserved}
      >
        {getHosRespsDetail.reserved ? '예약완료' : '예약하기'}
      </button>
    );

    return (
      <PageWrapper>
        <HospitalInfoContainer
          openCallModal={() => this.setState({ isOpenPhoneModal: true })}
          reservationMobileBtnComponent={reservationMobileBtnComponent}
          reservationBtnComponent={reservationBtnComponent}
          recentReservationCnt={getHospital.recentReservationCnt}
          reserved={getHosRespsDetail.reserved}
          respsComponent={respsComponent}
          reqComponent={reqComponent}
          hospital={getHospital}
          userRequest={getHosRespsDetail.userRequest}
          resps={getHosRespsDetail}
        />
        <ReservationConfirmModal
          onSubmit={(requirements, availtime) => {
            this.reserve(requirements, availtime);
          }}
          isOpen={isOpenReservationConfirmModal}
          onClose={() => this.setState({ isOpenReservationConfirmModal: false })}
          hospital={getHospital}
        />
        <MobileCallModal
          onBtnClick={this.reserveByCall}
          isOpen={isOpenPhoneModal}
          hospital={getHospital}
          me={me}
          userRequest={getHosRespsDetail.userRequest}
          onClose={() => this.setState({ isOpenPhoneModal: false })}
        />
      </PageWrapper>
    );
  }
}

export default compose(
  graphql(meQuery, {
    name: 'meQuery',
    options: () => ({
      fetchPolicy: 'network-only',
    }),
  }),
  graphql(getHosRespsDetailQuery, {
    name: 'getHosRespsDetail',
    options: ({ match }) => ({
      variables: { hrid: match.params.id },
      fetchPolicy: 'network-only',
    }),
  }),
  graphql(getHospitalQuery, {
    name: 'hospital',
    options: ({ match }) => ({
      variables: { id: match.params.hid },
    }),
  }),
  graphql(submitReservationMutation, {
    name: 'submitReservation',
    options: ({ match }) => ({
      refetchQueries: [
        {
          query: getHosRespsDetailQuery,
          variables: { hrid: match.params.id },
        },
      ],
    }),
  }),
  graphql(phoneReservationMutation, {
    name: 'phoneReservation',
    options: ({ match }) => ({
      refetchQueries: [
        {
          query: getHosRespsDetailQuery,
          variables: { hrid: match.params.id },
        },
      ],
    }),
  }),
)(withAlertModal(withToast(RespsHsDetail)));
