import React from 'react';
import styled from 'styled-components';
import queryString from 'query-string';
import HospitalCase from '../containers/HospitalCase';
import ScrollToTopOnMount from '../components/basic/ScrollToTopOnMount';
import { FormContainer } from '../components/basic/HtmlComponents';
import { media } from '../globalStyle';

const HospitalDetailContainer = styled.div`
  padding: 40px 0;
  ${media.tablet`
    padding: 90px 0;
  `};
  * {
    font-family: 'Saira Semi Condensed', AppleSDGothicNeo, Apple SD Gothic Neo, 애플 SD 산돌고딕 Neo, 나눔고딕, "NanumGothic", "맑은 고딕", "Malgun Gothic", Circular, Helvetica Neue, Helvetica, Arial, sans-serif;
    letter-spacing: -.5px;
  }
`;

class HsCase extends React.Component {
  render() {
    const query = queryString.parse(this.props.location.search)
    return (
      <div>
        <ScrollToTopOnMount />
        <FormContainer>
          <HospitalDetailContainer>
            <HospitalCase
              {...query}
            />
          </HospitalDetailContainer>
        </FormContainer>
      </div>
    );
  }
}

export default HsCase;
