import React from 'react';
import styled, { css } from 'styled-components';
import { media } from '../../../../globalStyle';

const CSSTitle = css`
  color: #000000;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  word-wrap: break-word;
  word-break: keep-all;
`;

const StyledH1 = styled.h1`
  ${CSSTitle};
  font-size: 26px;
  ${media.tablet`
    font-size: 18px;
    font-weight: bold;
    line-height: 1.4;
  `};
`;

const StyledH2 = styled.h2`
  ${CSSTitle};
  font-size: 20px;
  ${media.tablet`
    font-size: 18px;
    font-weight: bold;
    line-height: 1.4;
  `};
`;

const StyledH3 = styled.h3`
  ${CSSTitle};
  font-size: 18px;
  ${media.tablet`
    font-size: 20px;
    font-weight: bold;
    line-height: 1.4;
  `};
`;

/**
 *
 * @param children
 * @param className
 * @returns {*}
 * @constructor
 */
export const Title = ({ children, className }) => (
  <StyledH1 className={className}>
    { children || '대제목' }
  </StyledH1>
);

/**
 *
 * @param children
 * @param className
 * @returns {*}
 * @constructor
 */
export const SubTitle = ({ children, className }) => (
  <StyledH2 className={className}>
    { children || '서브 제목' }
  </StyledH2>
);

export const SectionTitle = ({ children, className }) => (
  <StyledH3 className={className}>
    { children || '섹션 제목' }
  </StyledH3>
);
