import React from 'react';
import styled from 'styled-components';
import { Input } from '../atoms/Text/Input';
import { media } from '../../../globalStyle';

const StyledInput = styled(Input)` 
  height: 48px;
  border: solid 1px #e0e0e0;
  padding: 0 10px;
  font-size: 100%;
  width: 100%;
`;

const StyledHorizonCardGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledVerticalCardGroup = styled.div`
  display: flex;
  align-items: center;
`;

const StyledImageBox = styled.div.attrs(props => ({
  width: props.width || '100px',
  height: props.height || '150px',
}))`
  flex: 0 0 ${props => props.width};
  width: ${props => props.width};
  height: ${props => props.height};
`;

const StyledTextBox = styled.div`
  font-size: 14px;
  width: 100%;
  margin-left: ${props => (props.imgRender ? '40px' : 0)};
  strong {
    line-height: 24px;
    font-weight: bold;
  }
  /*margin-left: 40px;*/
`;

const StyledReviewCardWrapper = styled.div`
  .reviewWriter{
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: -0.09px;
    color: #000000;
  }
  .reviewDate{
    margin-left: 10px;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #bbbcbc;
    font-family: 'Saira Semi Condensed', sans-serif;
  }
  
  ${media.tablet`
    .reviewDate, .reviewWriter>span{
      font-size: 14px;
    } 
  `};
`;

export const Card = ({
  className, width, height, imgRender, title, children,
}) => (
  <StyledHorizonCardGroup className={`card ${className}`}>
    <StyledImageBox
      className="card-image"
      width={width}
      height={height}
    >
      {imgRender ? imgRender() : ''}
    </StyledImageBox>
    <StyledTextBox>
      <p className="card-title"><b>{title || '타이틀'}</b></p>
      <p className="card-text">{ children }</p>
    </StyledTextBox>
  </StyledHorizonCardGroup>
);

/**
 *
 * @param width
 * @param height
 * @param imgRender 이미지 여부, margin-left를 조절할때 사용
 * @param title
 * @param desc
 * @returns {*}
 * @constructor
 */
export const HorizonCard = ({
  width, height, imgRender, title, desc,
}) => (
  <StyledHorizonCardGroup className="card-body">
    { imgRender ? (
      <StyledImageBox
        width={width}
        height={height}
      >
        {imgRender ? imgRender() : ''}
      </StyledImageBox>
    ) : null}
    <StyledTextBox imgRender={imgRender}>
      <p><strong>{title || '타이틀'}</strong></p>
      {desc}
    </StyledTextBox>
  </StyledHorizonCardGroup>
);

/**
 *
 * @param width
 * @param height
 * @param imgRender 이미지 여부, margin-left를 조절할때 사용
 * @param title
 * @param desc
 * @returns {*}
 * @constructor
 */
export const VCard = ({
  width, height, imgRender, title, desc,
}) => (
  <StyledVerticalCardGroup className="card-body">
    { imgRender ? (
      <StyledImageBox
        width={width}
        height={height}
      >
        {imgRender ? imgRender() : ''}
      </StyledImageBox>
    ) : null}
    <StyledTextBox imgRender={imgRender}>
      <p><strong>{title || '타이틀'}</strong></p>
      {desc}
    </StyledTextBox>
  </StyledVerticalCardGroup>
);

/**
 *
 * @param width
 * @param height
 * @param imgRender
 * @param defaultValue
 * @param onChange
 * @param placeholder
 * @returns {*}
 * @constructor
 */
export const HorizonCardInput = ({
  width, height, imgRender, defaultValue, onChange, placeholder,
}) => (
  <StyledHorizonCardGroup className="card-body">
    { imgRender ? (
      <StyledImageBox
        width={width}
        height={height}
      >
        {imgRender ? imgRender() : ''}
      </StyledImageBox>
    ) : null}
    <StyledTextBox imgRender={imgRender}>
      <StyledInput
        placeholder={placeholder}
        onChange={onChange}
        value={defaultValue}
      />
    </StyledTextBox>
  </StyledHorizonCardGroup>
);

export const ReviewCard = ({ children, name, date }) => (
  <StyledReviewCardWrapper>
    <div className="r-c-wrapper">{children}</div>
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <span className="reviewWriter">{name}</span>
      <span className="reviewDate">{date}</span>
    </div>
  </StyledReviewCardWrapper>
);
