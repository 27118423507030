import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import RouteHead from '../basic/RouteHeader';
import { Hr, PaddingDiv } from '../basic/HtmlComponents';

const NoticeListBtn = styled.div`
  display: inline-block;
  cursor: pointer;
  width: 160px;
  border-radius: 3px;
  background-color: #f7f7f7;
  border: solid 1px #e4e4e4;  
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  color: #484848;
  padding: 8px 0;
  float:right;
`;

const NoticeHead = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 16px;
  color: #484848;
  @media (max-width: 576px) {
    font-size: 14px;
  }
`;

const NoticeDate = styled.div`
  font-size: 15px;
  color: #8a8a8a;
  @media (max-width: 576px) {
    font-size: 13px;
  }
`;

const NoticeContent = styled.div`
  font-size: 15px;
  color: #484848;
  white-space: pre-wrap;
  @media (max-width: 576px) {
    font-size: 13px;
  }
`;

class NoticeDetailComponent extends Component {
  render() {
    const { notice, goToNoticeList } = this.props;
    return (
      <div>
        <RouteHead>공지사항</RouteHead>
        <NoticeHead>
          <Title>{notice.title}</Title>
          <NoticeDate>{moment(notice.createdAt).format('YYYY.MM.DD')}</NoticeDate>
        </NoticeHead>
        <Hr color="#e0e0e0" margin="10px 0 0" />
        <PaddingDiv padding="24px 0">
          <NoticeContent dangerouslySetInnerHTML={{ __html: notice.description }} />
        </PaddingDiv>
        <Hr color="#e0e0e0" margin="0 0 16px" />
        <NoticeListBtn onClick={goToNoticeList}>목록보기</NoticeListBtn>
      </div>
    );
  }
}

export default NoticeDetailComponent;
