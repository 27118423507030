import React, { Component } from 'react';
import styled from 'styled-components';
import PlusMinusCounter from './form/PlusMinusCounter';
import { media } from '../globalStyle';
import PriceRadioCheckedIcon from '../images/checkbox-checked-32.svg'
import PriceRadioUnCheckedIcon from '../images/checkbox-unchecked-32.svg'

const TherapyOptionContainer = styled.div`
  align-items: center;
  border-bottom: solid 1px #e4e4e4;
  box-sizing: border-box;
  height: auto;
  min-height: 120px;
  padding: 0;
  width: 100%;
  ${media.tablet`
    width: 100%;
    margin-bottom: 10px;
  `};
`;

const TherapyNameContainer = styled.div`
  width: 100%;
  flex: 1 1 auto;
  margin-right: 26px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TherapyName = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 4px;
  ${media.tablet`
    font-size: 14px;
    margin-bottom: 6px;
  `};
`;

const CollapseTherapy = styled.div`
  display: flex;
  padding: 18px 32px;
  z-index: 3;
  position: relative;
  width: 100%;
  background-color: transparent;
  ${media.tablet`
    padding: 14px 0;
  `};
`;

const CollapsedDiv = styled.div`
  padding: 0 30px 30px 132px;
  ${media.tablet`
    padding: 12px 0;
  `};
`;

const SubOptionsTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: #033e52;
  ${media.tablet`
    font-size: 13px;
    line-height: 1.46;
  `};
`;

const SubOptionContainer = styled.div`
  margin: 0 -5px;
  ${media.tablet`
    margin: 0;
  `};
`;

const TherapySubs = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  ${media.tablet`
    font-size: 13px;
    letter-spacing: -1px;
  `};
`;

const TherapyImgContainer = styled.div`
  align-items: center;
  margin-right: 16px;
  ${media.tablet`
    margin-right: 12px;
  `};
`;

const TherapyInfoContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  ${media.tablet`
    display: block;
  `};
`;

const TherapyInfo = styled.div`
  display: flex;
  width: 100%;
`;

const TherapyImg = styled.img`
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 50px;
  vertical-align: middle;
`;

const SubOptionComponent = styled.div`
  display: inline-block;
  padding: 0 5px;
  cursor: pointer;
  width: 20%;
  min-width: 166px;
  margin-top: 10px;
  ${media.tablet`
    padding: 0;
    margin-top: 8px;
    display: block;
    width: 100%;
  `};
`;


const SubOptionName = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -1px;
  text-align: center;
  color: #8a8a8a;
  margin-bottom: 16px;
  ${media.tablet`
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: -0.5px;
  `};
`;

const SubOptionInnerComponent = styled.div`
  padding: 20px 25px 16px;
  text-align: center;
  border: solid 1px #e0e0e0;
  background-color: #ffffff;
  box-sizing: border-box;
  &.active {
    border-color: #033e52;
    border-width: 1px;
    ${SubOptionName} {
      color: #033e52;
    }
  }
  ${media.tablet`
    padding: 16px 66px 12px;
    text-align: center;
  `};
`;


const SubOptionTypeContainer = styled.div`
  display: inline-block;
  text-align: center;
  width: 33.333%;
`;

const SubOptionValueName = styled.div`
  display: inline-block;
  margin-top: 6px;
  width: 100%;
  vertical-align: middle;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  text-align: center;
  color: #033e52;
  ${media.tablet`
    margin-top: 4px;
  `};
`;

const StickGraph = styled.div`
  width: 4px;
  height: 50px;
  background-color: #f0f0f0;
  border-radius: 2px;
  margin: 0 auto;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 4px;
    background-color: #033e52;
    border-radius: 2px;
    height: ${props => `${props.rating * 10}px`};
  }
`;

const RightControlContainer = styled.div`
  display: inline-block;
  align-self: center;
  width: 114px;
  ${media.tablet`
    display: none;
    margin-left: 96px;
  `};
`;

const SelectToggleBtn = styled.span`
  width: 100%;
  border-radius: 34.5px;
  border: solid 1px #bbbcbc;
  display: inline-block;
  background-color: transparent;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #bbbcbc;
  padding: 8px 0px;
  cursor: pointer;
  ${props =>
    props.active &&
    `
    background-color: #033e52;
    border-color: #033e52;
    font-weight: bold;
    color: #ffffff;
  `};
`;

const SubOptionSelectBtn = styled.div`
  width: 100%;
  border-radius: 34.5px;
  border: solid 1px #bbbcbc;
  margin-top: 8px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #bbbcbc;
  padding: 8px 0px;
  ${props =>
    props.active &&
    `
    color: #fff;
    background-color: #033e52;
    border-color: #033e52;
    font-weight: bold;
  `};
  ${media.tablet`
    width: 116px;
    height: 40px;
    margin: 6px auto 0;
  `};
`;

const StyledToggleIcon = styled.div`
  background-size: 30px 30px;
  width: 30px;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  filter: brightness(0.95);
  background-image: url(${ props => props.active ? PriceRadioCheckedIcon : PriceRadioUnCheckedIcon});
  display: none;
  ${media.tablet`
    display: block;
  `}
`;

export const SubOption = ({
  id,
  name,
  onSubOptionChange,
  children,
  optionName,
  value,
}) => (
  <SubOptionComponent onClick={e => onSubOptionChange(id, optionName, name, e)}>
    <SubOptionInnerComponent className={value === optionName ? 'active' : ''}>
      <SubOptionName>{name}</SubOptionName>
      {children}
      <SubOptionSelectBtn active={value === optionName ? 'active' : ''}>
        {value === optionName ? '선택됨' : '선택'}
      </SubOptionSelectBtn>
    </SubOptionInnerComponent>
  </SubOptionComponent>
);

export const SubOptionType = ({ name, rating }) => (
  <SubOptionTypeContainer>
    <StickGraph rating={rating} />
    <SubOptionValueName>{name}</SubOptionValueName>
  </SubOptionTypeContainer>
);

export const SelectTherapy = ({
  id,
  name,
  image,
  therapyName,
  therapySubs,
  selectTherapy,
  deselectTherapy,
  active,
  children,
  unit,
}) => (
  <TherapyOptionContainer active={active} id={id} name={name}>
    <CollapseTherapy name={name}>
      <TherapyInfoContainer onClick={!!unit ? deselectTherapy.bind(this, id, name) : selectTherapy.bind(this, id, name)}>
        <TherapyInfo>
          <TherapyImgContainer>
            <TherapyImg src={image} />
          </TherapyImgContainer>
          <TherapyNameContainer>
            <TherapyName>{therapyName}</TherapyName>
            <TherapySubs>{therapySubs}</TherapySubs>
          </TherapyNameContainer>
          <TherapyImgContainer>
            <StyledToggleIcon active={!!unit} />
          </TherapyImgContainer>
        </TherapyInfo>
        <RightControlContainer>
          {unit > 0 ? (
            <SelectToggleBtn
              active={active}
            >
              선택됨
            </SelectToggleBtn>
          ) : (
            <SelectToggleBtn>
              선택
            </SelectToggleBtn>
          )}
        </RightControlContainer>
      </TherapyInfoContainer>
    </CollapseTherapy>
    {children && unit > 0 ? (
      <CollapsedDiv>
        <SubOptionsTitle>치료 소재 선택</SubOptionsTitle>
        <SubOptionContainer>{children}</SubOptionContainer>
      </CollapsedDiv>
    ) : null}
  </TherapyOptionContainer>
);

class TherapyOption extends Component {
  static defaultProps = {
    showCounter: true,
    unit: 0,
  };

  constructor(props) {
    super(props);

    this.handleCounterChange = this.handleCounterChange.bind(this);
    this.handleSubOptionChange = this.handleSubOptionChange.bind(this);
  }

  handleCounterChange = (id, name, value) => {
    this.props.onOptionUnitChange(id, name, value);
  };

  handleSubOptionChange = (name, value) => {
    this.props.onSubOptionChange(name, value);
  };

  render() {
    const {
      id,
      name,
      image,
      therapyName,
      therapySubs,
      unit,
      active,
      children,
    } = this.props;

    return (
      <TherapyOptionContainer active={active}>
        <CollapseTherapy name={name}>
          <TherapyInfoContainer>
            <TherapyInfo>
              <TherapyImgContainer>
                <TherapyImg src={image} />
              </TherapyImgContainer>
              <TherapyNameContainer>
                <TherapyName>{therapyName}</TherapyName>
                <TherapySubs>{therapySubs}</TherapySubs>
              </TherapyNameContainer>
            </TherapyInfo>
            <RightControlContainer>
              <PlusMinusCounter
                onCounterChange={(value) => {
                  this.handleCounterChange(id, name, value);
                }}
                currentCount={unit}
              />
            </RightControlContainer>
          </TherapyInfoContainer>
        </CollapseTherapy>
        {children && unit > 0 ? (
          <CollapsedDiv>
            <SubOptionsTitle>치료 소재 선택</SubOptionsTitle>
            <SubOptionContainer>{this.props.children}</SubOptionContainer>
          </CollapsedDiv>
        ) : null}
      </TherapyOptionContainer>
    );
  }
}

export default TherapyOption;
