import React, { Component } from 'react';
import styled from 'styled-components';
import logopng from '../../images/logo.png';
import ToggleBtnIcon from '../../images/ic-menu-44.svg';
import WhiteLogo from '../../images/img-logo-gnb-white.svg';
import CloseIcon from '../../images/ic-close-44.svg';
import { media } from '../../globalStyle';

const StyledNavbar = styled.nav`
  max-width: 1080px;
  margin: 10px auto 0;
  display: block;
  box-shadow: none;
  border: 0;
  background: transparent;
  position: relative;
  width: 100%;
  padding: 28px 20px;
  border-radius: 0;
  ${props => props.location === '/' && `
    position: absolute;
    z-index: 3;
    border: none;
    max-width: none;
  `};
  ${media.tablet`
    padding: 0;
    margin: 0;
    height: 44px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 4;
    background: rgba(255, 255, 255, 0.8);
  `};
`;

const NavInner = styled.div`
  max-width: 1040px;
  margin: auto;
`;

const Brand = styled.div`
  display: inline-block;
  ${media.tablet`
    display: block;
    text-align: center;
    ${props => props.invisible && 'display: none'};
  `};
`;

const LogoA = styled.a`
  height: 100%;
  display: block;
`;

const Logo = styled.img`
  width: 125px;
  height: 24px;
  margin: 0;
  object-fit: contain;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  ${media.tablet`
    width: 104px;
    height: auto;
    margin-top: 11px;
    cursor: pointer;
  `};
`;

const CloseBtn = styled.img`
  width: 44px;
  height: 44px;
  object-fit: contain;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
`;

const ToggleBtn = styled.img`
  display: none;
  ${media.tablet`
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    width: 44px;
    height: 44px;
    object-fit: contain;
    cursor: pointer;
  `};
`;

const MobileCollapseHeader = styled.div`
  position: relative;
  text-align: center;
  height: 44px;
`;

const StyledCollapse = styled.div`
  float: right;
  margin-left: -24px;
  margin-top: 3px;
  &::after {
    content: '';
    clear: both;
  }
  & > ${MobileCollapseHeader} {
    display: none;
  }
  ${media.tablet`
    ${props => (props.open ? 'display: flex' : 'display: none')};
    flex-direction: column;
    float: none;
    text-align: center;
    position: fixed;
    background-color: rgba(1, 127, 160, 0.89);
    margin: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 450px;
    & > ${MobileCollapseHeader} {
      display: block;
    } 
  `};
`;

export default class EmptyNavigationBar extends Component {
  state = {
    isOpenCollaseMenu: false,
  };

  closeMobileCollapse = () => {
    this.setState({ isOpenCollaseMenu: false });
  };

  render() {
    return (
      <StyledNavbar collapseOnSelect location={document.location.pathname}>
        <NavInner>
          <Brand invisible={this.state.isOpenCollaseMenu}>
            <LogoA id="OdoctorLogo" href="/" title="오닥터 치과 " rel="home">
              <Logo src={logopng} />
            </LogoA>
            <ToggleBtn
              src={ToggleBtnIcon}
              alt="ToggleBtnIcon"
              onClick={() =>
                this.setState({
                  isOpenCollaseMenu: !this.state.isOpenCollaseMenu,
                })
              }
            />
          </Brand>
          <StyledCollapse open={this.state.isOpenCollaseMenu}>
            <MobileCollapseHeader>
              <Logo
                src={WhiteLogo}
                onClick={() => {
                  this.props.history.push('/');
                  this.closeMobileCollapse();
                }}
              />
              <CloseBtn
                src={CloseIcon}
                onClick={() => this.closeMobileCollapse()}
              />
            </MobileCollapseHeader>
          </StyledCollapse>
        </NavInner>
      </StyledNavbar>
    );
  }
}
