import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { isValidPass } from '../../utils/validationInput';
import {
  FormTitle,
  FormSubTitle,
  FormInputRow,
  FormConfirmBtn,
} from './FormCommonComponent';
import { media } from '../../globalStyle';
import PasswordInput from './PasswordInput';
import ResetPassPhoneConfirmInput from './ResetPassPhoneConfirmInput';
import { marginProps } from '../basic/HtmlComponents';

const ExtendedFormSubTitle = styled(FormSubTitle)`
  margin-bottom: 60px;
  margin-top: 0;
  ${media.tablet`
    margin-bottom: 60px;
    margin-top: 0;
  `};
`;

const ExtendedFormTitle = styled(FormTitle)`
  margin-bottom: 20px;
  ${media.tablet`
    margin-bottom: 20px;
  `};
`;

const BtnContainer = styled.div`
  margin-top: 16px;
  ${marginProps}
`;

class ResetPasswordForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phonenum: '',
      password: '',
      fireSubmitValidation: false,
      phoneConfirmed: false,
      phoneErrorMsg: null,
      resetToken: null, // password reset token
      isDoneResetPass: false, // 비밀번호 변경 완료
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange({ target }) {
    const { name, value } = target;

    this.setState({
      [name]: value,
      fireSubmitValidation: false,
    });
  }

  handleSubmit = async () => {
    const { phonenum, resetToken, password } = this.state;
    if (!password) {
      this.setState({ fireSubmitValidation: true });
      return;
    }

    if (!isValidPass(password)) {
      return;
    }

    const result = await this.props.resetPassword(phonenum, resetToken, password);

    if (result.ok) {
      this.setState({ isDoneResetPass: true });
    }
  };

  render() {
    const { phonenum, password, fireSubmitValidation, phoneConfirmed, phoneErrorMsg, isDoneResetPass } = this.state;

    return (
      <React.Fragment>
        {isDoneResetPass ? (
          <div>
            <ExtendedFormTitle>비밀번호 변경완료</ExtendedFormTitle>
            <ExtendedFormSubTitle>
              비밀번호 변경이 완료되었습니다.<br />
              변경된 비밀번호로 로그인 하실 수 있습니다.
            </ExtendedFormSubTitle>
            <BtnContainer marginTop="104px">
              <FormConfirmBtn
                onClick={() => this.props.close()}
              >
                확인
              </FormConfirmBtn>
            </BtnContainer>
          </div>
        ) : (
          <div>
            <div>
              <ExtendedFormTitle>비밀번호 재설정</ExtendedFormTitle>
              <ExtendedFormSubTitle>
                {phoneConfirmed ? '새로운 비밀번호를 입력하세요.' : '휴대폰 번호를 인증하세요'}
              </ExtendedFormSubTitle>
            </div>
            <div>
              {phoneConfirmed ? (
                <React.Fragment>
                  <FormInputRow>
                    <PasswordInput
                      id="loginPassword"
                      name="password"
                      onChange={this.onChange}
                      value={password}
                      placeholder="비밀번호 (4~12자)"
                      textVisible
                      fireSubmitValidation={fireSubmitValidation}
                      style={{ letterSpacing: '0px' }}
                    />
                  </FormInputRow>
                  <BtnContainer>
                    <FormConfirmBtn
                      disabled={!password}
                      onClick={this.handleSubmit}
                    >
                      완료
                    </FormConfirmBtn>
                  </BtnContainer>
                </React.Fragment>
              ) : (
                <FormInputRow>
                  <ResetPassPhoneConfirmInput
                    name="phonenum"
                    onChange={this.onChange}
                    value={phonenum}
                    fireSubmitValidation={fireSubmitValidation}
                    disabled={phoneConfirmed}
                    errorMsg={phoneErrorMsg}
                    onDuplicated={this.onPhoneDuplicated}
                    onConfirmed={resetToken => this.setState({ phoneConfirmed: true, resetToken })}
                    clearError={() => this.setState({
                      fireSubmitValidation: false,
                      phoneErrorMsg: null,
                    })}
                  />
                </FormInputRow>
              )}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(ResetPasswordForm);
