const onUnloadListener = (e) => {
  const message = '이 페이지에서 나가면 상담이 저장되지 않습니다..';
  e.preventDefault();
  e.stopPropagation();
  // For IE and Firefox
  e.returnValue = message;
  // For Safari
  return message;
};

export const blockEscape = () => {
  window.addEventListener('beforeunload', onUnloadListener);
  window.addEventListener('unload', onUnloadListener);
};

export const unBlockEscape = () => {
  window.removeEventListener('beforeunload', onUnloadListener);
  window.removeEventListener('unload', onUnloadListener);
};
