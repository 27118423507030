import React from 'react';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { media } from '../globalStyle';

const StyledToastContainer = styled(ToastContainer)`
  .body-style {
    padding: 16px;
    box-shadow: 0 6px 14px 0 rgba(88, 88, 88, 0.24);
    background-color: #ffb3aa;
    ${media.miniDesktop`
      width: 280px;
      margin: auto;
    `};
  }
  .inner-style {
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: -0.9px;
    text-align: center;
    color: #ffffff;
  }

  .center {
    text-align: center;
  }

  .left {
    text-align: left;
  }

  .right {
    text-align: right;
  }

  @keyframes FadeIn {
    from {
      opacity: 0;
      transform: translateY(-100%);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .fadeIn {
    animation-name: FadeIn;
  }

  @keyframes FadeOut {
    from {
      opacity: 1;
      transform: translateY(0%);
    }

    to {
      opacity: 0;
      transform: translateY(-100%);
    }
  }

  .fadeOut {
    animation-name: FadeOut;
  }

  .animate {
    animation-duration: 800ms;
  }
`;

class OdocToastContainer extends React.Component {
  render() {
    return (
      <StyledToastContainer
        style={{
          width: '380px',
          marginLeft: '-190px',
          top: '40px',
          padding: '0',
          position: 'fixed',
          left: '50%',
          zIndex: '101',
        }}
        draggable={false}
      />
    );
  }
}

export default OdocToastContainer;
