import React from 'react';
import styled from 'styled-components';

const StyledTextArea = styled.textarea`
  ime-mode:active;
  width: 100%;
  height: 221px;
  border: solid 1px #e1e3e2;
  padding: 20px 14px;
  color: #000;
  font-size: 14px;
  letter-spacing: -.4px;
`;

export const DescComponent = ({ onClick }) => {
  const savedData = JSON.parse(localStorage.getItem('pData'));

  return (
    <StyledTextArea
      value={savedData.desc}
      placeholder="개인 정보를 위해 전화번호, 메신저 ID는 절대 기재하지 마세요"
      onChange={(e) => {
        onClick({ value: e.target.value });
      }}
    />
  );
};

export default DescComponent;
