import React from 'react';
import styled, { css } from 'styled-components';
import { media } from '../../../../globalStyle';

const StyledButton = styled.button`
  cursor: pointer;
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: -1px;
  text-align: center;
  color: ${props => props.color || '#033e52'};
  border: 0;
  padding: 5px 10px;
  &:hover,
  &:focus {
    outline: none;
  }

  ${media.tablet`
    font-size: 18px;
  `};  
`;

const StyledOutline = css`
  border: 1px solid #033e52;
  background-color: #fff;
  color: #033e52;
`;

const StyledFlexStretch = css`
  flex: 1 1 auto;
`;

const textAlignLeft = css`
  text-align: left;
`;

const textAlignRight = css`
  text-align: right;
`;

const textAlignCenter = css`
  text-align: center;
`;

const StyledFlexButton = styled(StyledButton)`
  height: 100%;
  width: 100%;
  border: 0;
  border-radius: 0;
  color: #fff;
  background-color: #033e52;
  ${props => props.outline && StyledOutline};
  ${props => props.stretch && StyledFlexStretch};
  ${props => props.textCenter && textAlignCenter};
  ${props => props.textRight && textAlignRight};
  ${props => props.textLeft && textAlignLeft};
`;

const StyledImageButton = styled.button`
  font-size:0;
  cursor: pointer;
  padding: 10px;
  > span {
    width:24px;
    height:24px;
    display: inline-block;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4NCjxzdmcgd2lkdGg9IjI0cHgiIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAwIDI0IDI0IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPg0KICAgIDwhLS0gR2VuZXJhdG9yOiBza2V0Y2h0b29sIDUxLjIgKDU3NTE5KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4NCiAgICA8dGl0bGU+QTY0NkM5MTYtRDlDNS00RUFGLUExNEUtQjM5M0FBRDY1NUY2PC90aXRsZT4NCiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggc2tldGNodG9vbC48L2Rlc2M+DQogICAgPGRlZnM+PC9kZWZzPg0KICAgIDxnIGlkPSJTeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4NCiAgICAgICAgPGcgaWQ9Ikljb25zLS8tQ2xvc2VfZ3JheV8yNCI+DQogICAgICAgICAgICA8Zz4NCiAgICAgICAgICAgICAgICA8cG9seWdvbiBpZD0iU2hhcGUiIHBvaW50cz0iMCAwIDI0IDAgMjQgMjQgMCAyNCI+PC9wb2x5Z29uPg0KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0xMS40NTAyNTgyLDExLjc4NTQ1MDUgTDE5LjY1NzMxNDgsMy41NzgzOTM4NSBMMjAuMDg5MjY1Miw0LjAxMDM0NDIgTDExLjg4MjIwODYsMTIuMjE3NDAwOCBMMjAuMDg5MjY1MiwyMC40MjQ0NTc0IEwxOS42NTczMTQ4LDIwLjg1NjQwNzggTDExLjQ1MDI1ODIsMTIuNjQ5MzUxMiBMMy42NzUxNTE5NSwyMC40MjQ0NTc0IEwzLjI0MzIwMTYsMTkuOTkyNTA3MSBMMTEuMDE4MzA3OSwxMi4yMTc0MDA4IEwzLjI0MzIwMTYsNC40NDIyOTQ1NSBMMy42NzUxNTE5NSw0LjAxMDM0NDIgTDExLjQ1MDI1ODIsMTEuNzg1NDUwNSBaIiBpZD0iQ29tYmluZWQtU2hhcGUiIHN0cm9rZT0iIzhBOEE4QSIgZmlsbD0iIzhBOEE4QSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj48L3BhdGg+DQogICAgICAgICAgICA8L2c+DQogICAgICAgIDwvZz4NCiAgICA8L2c+DQo8L3N2Zz4=);
    background-repeat: no-repeat;
  }
`;

const onClickOk = () => {
  // console.log('Ok Click');
};

const onClickCancel = () => {
  // console.log('Close Click');
};

const onClickFlex = () => {
  // console.log('Flex Click');
};

export const CloseWindowButton = ({ onClick, className }) => (
  <StyledImageButton onClick={onClick} className={className}>
    <span />
  </StyledImageButton>
);

export const OkButton = ({ children, onClick }) => (
  <StyledButton
    onClick={onClick || onClickOk}
  >
    {children || '확인'}
  </StyledButton>
);

export const CancelButton = ({ children, onClick }) => (
  <StyledButton
    onClick={onClick || onClickCancel}
    color="#8a8a8a"
  >
    {children || '취소'}
  </StyledButton>
);

export const FlexButton = ({
  children, onClick, type, ...props
}) => (
  <StyledFlexButton
    {...props}
    onClick={onClick || onClickFlex}
    {...(type && { type })}
    primary
  >
    {children || '확인'}
  </StyledFlexButton>
);
