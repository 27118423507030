import React from 'react';
import styled from 'styled-components';
import { toast, cssTransition } from 'react-toastify';
import CloseImage from '../images/ic-close-white-20.svg';

const CloseBtn = styled.div`
  position: absolute;
  top: 50%;
  margin-top: -10px;
  right: 16px;
  cursor: pointer;
  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
`;

const closeButton = (
  <CloseBtn>
    <img src={CloseImage} alt="CloseImage" />
  </CloseBtn>
);

function withToast(WrappedComponent) {
  const Fade = cssTransition({
    enter: 'fadeIn',
    exit: 'fadeOut',
    duration: 750,
  });

  return class extends React.Component {
    customToast = (message, textAlign) => {
      toast(message, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        type: 'warning',
        closeButton: false,
        autoClose: 1200,
        transition: Fade,
        className: 'body-style',
        bodyClassName: `${textAlign} inner-style`,
      });
    };

    render() {
      return (
        <WrappedComponent {...this.props} toast={this.customToast} />
      );
    }
  };
}

export default withToast;
