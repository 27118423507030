import React, { Component } from 'react';
import styled from 'styled-components';
import {withApollo} from 'react-apollo';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import SubHeader from './SubHeader';
import withAlertModal from '../utils/alerthoc';
import {locationGunguQuery} from '../graphql';
import { media } from '../globalStyle';
import { RadioButton} from "./atomic/molecules/ButtonGroup";
import PriceRadioCheckedIcon from '../images/checkbox-checked-32.svg';
import PriceRadioUnCheckedIcon from '../images/checkbox-unchecked-32.svg';
import ArrowImg from '../images/ic-more-gray-24.png';
import { marginProps } from './basic/HtmlComponents';
import { AuthContext } from './AuthProvider';
import { draftData as data } from "../data";
import { logPageView, userActivitySendSlack } from '../utils/loging';

const FormContainer = styled.div`
  max-width: 1080px;
  margin: 0 auto 60px;
  padding: 0;
  position: relative;
  ${media.tablet`
    padding: 0
  `};
`;

const SettingRow = styled.div`
  margin-bottom: 60px;
  &:last-of-type {
    margin: 0;
  }
  ${media.tablet`
    margin-bottom: 72px;
  `};
`;

const FlexContainer = styled.div`
  position: relative;
  margin-top: 52px;
  ${media.miniDesktop`
    display: block;
    padding: 0;
  `};
  ${media.tablet`
    padding: 0 20px;
    margin-top: 60px;
  `};
`;

const LeftContainer = styled.div`
  display: inline-block;
  width: 100%;
  height: auto;
  ${media.tablet`
    padding-bottom: 60px;
    margin-bottom: 100px;
  `};
`;

const SettingTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: -1px;
  color: #000000;
  margin-bottom: 20px;
  ${marginProps}
  span {
    margin-left: 8px;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -1px;
    color: #dd4124;
    display: inline-block;
    vertical-align: top;
    margin-top: 2px;
  }

  ${media.tablet`
    margin-bottom: 10px;
  `};
`;

const SettingSubTitle = styled.div`
  margin-top: -10px;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  ${media.tablet`
    margin-top: 0;
    margin-bottom: 30px;
    font-size: 13px;
  `};
`;

const GenderBtnContainer = styled.div`
  display: inline-block;
  width: 267px;
  ${media.tablet`
    width: 100%;
    display: flex;
    margin-top: 20px;
  `};
`;

const GenderBtn = styled.div`
  display: inline-block;
  input {
    display: none;
    & + label {
      width: 110px;
      margin-bottom: 0;
      padding: 18px 0;
      border-radius: 4px;
      border: solid 1px #e0e0e0;
      background: #ffffff;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: -1px;
      text-align: center;
      color: #b1b3b2;
      cursor: pointer;
    }
    &:checked + label {
      border-color: #033e52;
      color: #033e52;
    }
  }
  & ~ & {
    margin-left: 10px;
  }
  ${media.tablet`
    width: 100%;
    input + label {
      width: 100%;
    }
    & ~ & {
      margin-left: 10px;
    }
  `};
`;

const PriceSubTitle = styled.div`
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.46;
  letter-spacing: normal;
  color: #033e52;
`;

const AddressBox = styled.div`
  display: inline-flex;
  width: 245px;
  border-bottom: 1px solid #e0e0e0;
  justify-content: space-between;
  margin: -10px 20px 0 0;
  position: relative;
  ${media.tablet`
    width: 100%;
    letter-spacing: -1px;
    padding-left: 0;
    margin: 0 0 10px 0;
  `};
`;

const AddressSelect = styled.select`
  cursor: pointer;
  border: none;
  height: 51px;
  width: 100%;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000000;
  background-color: #ffffff;
  background-image: url(${ArrowImg});
  background-position-x: right;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: none;
  ::-ms-expand {
    display: none;
  }
  &:invalid {
    color: #8a8a8a;
  }
  &:disabled {
    color: #bcbcbc;
    cursor: not-allowed;
  }
  &:hover,
  &:focus {
    outline: none;
  }
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #bcbcbc;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #bcbcbc;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #bcbcbc;
  }
  &:disabled {
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: transparent;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: transparent;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: transparent;
    }
    + img {
      display: none;
    }
  }
  ${media.tablet`
    width: 100%;
    letter-spacing: -1px;
  `};
`;

const AgeBox = styled.div`
  display: inline-block;
  width: 245px;
  border-bottom: 1px solid #e0e0e0;
  margin: 0;
  position: relative;
  ${media.tablet`
    width: 100%;
    letter-spacing: -1px;
    padding-left: 0;
    margin-top: 20px;
  `};
`;

const AgeSelect = styled.select`
  cursor: pointer;
  border: none;
  height: 51px;
  width: 100%;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000000;
  background-color: #ffffff;
  background-image: url(${ArrowImg});
  background-position-x: right;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: none;
  ::-ms-expand {
    display: none;
  }
  &:invalid {
    color: #8a8a8a;
  }
  &:hover,
  &:focus {
    outline: none;
  }
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #bcbcbc;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #bcbcbc;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #bcbcbc;
  }
  &:disabled {
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: transparent;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: transparent;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: transparent;
    }
    + img {
      display: none;
    }
  }
  ${media.tablet`
    width: 100%;
    letter-spacing: -1px;
  `};
`;

const SettingRadioInput = styled.input`
  display: none;
`;

const Tags = styled.div`
  margin-top: -10px;
  margin-right: -12px;
  max-width: 700px;
  ${media.tablet`
    margin-right: -10px;
    margin-top: 14px;
  `};
`;

const CounselingRadioInput = styled(SettingRadioInput)`
  &:checked {
    + label {
      color: #fff;
      background-color: #033e52;
      border-color: #033e52;
      font-weight: bold;
    }
  }
`;

const CounselingRadioLabel = styled.label`
  width: 160px;
  border-radius: 26px;
  background-color: #ffffff;
  border: solid 1px #bbbcbc;
  padding: 14px 0;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -1px;
  text-align: center;
  color: #bbbcbc;
  margin: 10px 12px 0 0;
  cursor: pointer;
  ${media.tablet`
    font-size: 12px;
    width: 135px;
    line-height: 1.71;
    letter-spacing: -0.9px;
    margin: 10px 10px 0 0;
  `};
`;

const CarePriceRadioBtnContainer = styled.div`
  padding: 30px 0 0;
  ${media.tablet`
    padding: 10px 0 0;
  `};
`;

const CarePriceRadioBtn = styled.div`
  display: inline-block;
  input {
    display: none;
  }
  input + label {
    cursor: pointer;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.07;
    letter-spacing: -1.1px;
    color: #000000;
    background-image: url(${PriceRadioUnCheckedIcon});
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position-y: 50%;
    background-position-x: 0;
    padding: 6px 0 6px 28px;
  }
  input:checked + label {
    background-image: url(${PriceRadioCheckedIcon});
  }
  &:nth-of-type(2) {
    margin-left: 40px;
  }
  ${media.tablet`
    input + label {
      font-size: 16px;
      line-height: 0.94;
      letter-spacing: -1.2px;
      padding: 4px 0 4px 28px;
    }
  `};
`;

const PriceContainer = styled.div`
  @media (max-width: 700px) {
    display: block;
  }
`;

const PriceHalf = styled.div`
  width: 245px;
  padding: 0;
  display: ${props => (props.show ? 'inline-block' : 'none')};
  @media (max-width: 700px) {
    width: 100%;
    padding: 0;
    margin-bottom: 3px;
    &:first-of-type {
      margin-bottom: 31px;
    }
  }
  &:first-of-type {
    margin-right: 20px;
  }
  ${media.tablet`
    display: ${props => (props.show ? 'block' : 'none')};
    margin-bottom: 30px;
    width: 100%;
    &:first-of-type {
      margin-right: 0;
      margin-bottom: 30px;
    }
  `};
`;

const PriceTextInputContainer = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  justify-content: space-between;
  margin: 10px 0 0;
  height: 47px;
  padding: 10px 38px 0px 0;
  position: relative;
  ${props => props.active && 'border-color: #033e52'};
  ${media.tablet`
    width: 100%;
  `};
`;

const PriceLabel = styled.span`
  display: none;
  position: absolute;
  right: 6px;
  top: 14px;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #bbbcbc;
  ${props => props.value && 'display: inline-block'};
`;

const PriceTextInput = styled(NumberFormat)`
  border: none;
  height: 32px;
  width: 293px;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #033e52;
  background-color: transparent;
  &:hover,
  &:focus {
    outline: none;
  }
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    opacity: 1; /* Firefox */
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: -0.9px;
    color: #bbbcbc;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: -0.9px;
    color: #bbbcbc;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: -0.9px;
    color: #bbbcbc;
  }
  &:focus {
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: transparent;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: transparent;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: transparent;
    }
    + img {
      display: none;
    }
  }
  &:focus {
    + span {
      display: block;
    }
  }
  ${media.tablet`
    width: 100%;
  `};
`;

const BtnWrapper = styled.div`
  margin-top: 90px;
  text-align: center;
  ${media.tablet`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: left;
    background-color: #033e52;
    padding: 20px;
  `};
`;

const BackBtn = styled.button`
  border: solid 1px #033e52;
  background-color: #ffffff;
  padding: 20px 0;
  width: 230px;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -0.89px;
  text-align: center;
  color: #033e52;
  margin-right: 5px;
  ${media.tablet`
    width: auto;
    margin: 0;
    padding: 0;
    background-color: #033e52;
    color: #ffffff;
  `};
`;

const SubmitBtn = styled.button`
  width: 230px;
  padding: 21px 0;
  background-color: #033e52;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -0.89px;
  text-align: center;
  color: #ffffff;
  margin-left: 5px;
  &:disabled {
    background-color: #bbbcbc;
  }
  ${media.tablet`
    float: right;
    width: auto;
    margin: 0;
    padding: 0;
    opacity: 1;
    &:disabled {
      opacity: 0.5;
      background-color: transparent;
    }
  `};
`;

const RadioWarp = styled.div`
  &:not(:last-child){
    margin-bottom: 8px;
  }
`;

class CounselSettingForm extends Component {
  constructor(props) {
    super(props);

    if (
      Object.entries(props.initialData).length !== 0
      || props.initialData.constructor !== Object
    ) {
      this.state = { ...props.initialData };
      return;
    }

    this.state = {
      gender: '', // 성별
      considerTags: [], // 상담시 고려사항
      txMaxPrice: null, // 희망 치과 치료비
      orthoMaxPrice: null, // 희망 교정 치료비
      carePrice: null, // 가격 입력 여부. default: '', 'dontcare': 상관없음, 'noinput': 없음
      addressRegion: '', // 상담 - 도시(시/도)
      addressCity: '', // 상담 - 도시(시/군/구)
      cityList: [], // 선택된 도시에 대한 군구를 담는 state
      ageGroup: '', // 연령대
      wantLevel: null,
      isOpenBottomCart: false,
      isSubmitted: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleRegionChange = this.handleRegionChange.bind(this);
    this.handleConsiderationChange = this.handleConsiderationChange.bind(this);

    // ga 목표 설정을위한 pageview log
    logPageView('/settings');
  }

  componentDidMount() {
    if ( this.props.selectedRegionFromMain ) {
      this.handleRegionChange({
        target:{
          name:'addressRegion',
          value:this.props.selectedRegionFromMain,
        }
      });
    }
    userActivitySendSlack('?', 'in-step2');
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleRegionChange = async (e) => {
    // 시/도를 고르고 시/군/구 세팅해준다.
    const { name, value } = e.target;
    this.setState({ [name]: value });

    const gungu = await this.props.client.query({
      query: locationGunguQuery,
      variables: {
        siName: value,
      },
    });

    if (gungu && gungu.data && gungu.data.locationGungu) {
      this.setState({ cityList: gungu.data.locationGungu });
    }
  };

  quickSelectClick = (amount, isTx) => {
    if (isTx) {
      this.setState({ txMaxPrice: amount });
    } else {
      this.setState({ orthoMaxPrice: amount });
    }
  };

  handleConsiderationChange = (e) => {
    const tag = e.target.value;
    const newConsideration = this.state.considerTags;
    const considerIdx = newConsideration.indexOf(tag);

    // 원래 존재하는 고려사항이면 삭제, 없으면 추가
    if (considerIdx > -1) {
      newConsideration.splice(considerIdx, 1);
    } else if (this.state.considerTags.length < 3) {
      newConsideration.push(tag);
    }

    this.setState({
      considerTags: newConsideration,
    });
  };

  getReqType = (pickedTxTherapy, pickedOrthoType, orthoPart) => {
    let isTx = false;
    let isOrtho = false;

    if (pickedTxTherapy && !_.isEmpty(pickedTxTherapy)) {
      isTx = true;
    }

    if (pickedOrthoType && orthoPart && orthoPart.length > 0) {
      isOrtho = true;
    }

    if (isTx && isOrtho) {
      return 'all';
    }

    if (isTx) {
      return 'tx';
    }

    if (isOrtho) {
      return 'ortho';
    }

    return null;
  };

  handleSubmit = async (skipMaxPriceValidation = false, e) => {
    const {
      // gender,
      // ageGroup,
      // txMaxPrice,
      // orthoMaxPrice,
      // carePrice,
      considerTags,
      addressRegion,
      addressCity,
      wantLevel,
    } = this.state;

    const {isDirect} = this.props;

    // const reqType = this.getReqType(
    //   therapyData.pickedTxTherapy,
    //   therapyData.pickedOrthoType,
    //   therapyData.orthoPart,
    // );

    // validation
    if (!isDirect) {
      if (!addressRegion || !addressCity) {
        this.props.openAlert({
          title: '알림',
          message: '희망지역을 선택해주세요.',
          showConfirmBtn: true,
          confirmBtnName: '확인',
          confirmBtnOnClick: this.props.closeAlert,
          showCancelBtn: false,
          textAlign: 'left'
        });
        return;
      }

      if (!considerTags || !considerTags.length > 0) {
        this.props.openAlert({
          title: '알림',
          message: '상담시 고려사항을 선택해 주세요',
          showConfirmBtn: true,
          confirmBtnName: '확인',
          confirmBtnOnClick: this.props.closeAlert,
          showCancelBtn: false,
          textAlign: 'left'
        });
        return;
      }
    }

    // 상담의향 필수 체크
    if (!wantLevel) {
      this.props.openAlert({
        title: '알림',
        message: '치료의향을 선택해주세요.',
        showConfirmBtn: true,
        confirmBtnName: '확인',
        confirmBtnOnClick: this.props.closeAlert,
        showCancelBtn: false,
      });
      return;
    }

    // 희망치료비 삭제 (2019/10/16)
    // if (
    //   !skipMaxPriceValidation
    //   && (((reqType === 'all' || reqType === 'tx') && !txMaxPrice && !carePrice)
    //     || ((reqType === 'all' || reqType === 'ortho')
    //       && !orthoMaxPrice
    //       && !carePrice))
    // ) {
    //   this.props.openAlert({
    //     title: '알림',
    //     message: '희망가격 없이 등록하시겠습니까?',
    //     showConfirmBtn: true,
    //     confirmBtnName: '확인',
    //     confirmBtnOnClick: () => {
    //       this.handleSubmit(true);
    //     },
    //     showCancelBtn: true,
    //     cancleBtnName: '취소',
    //     textAlign: 'left',
    //     cancleBtnOnClick: this.props.closeAlert,
    //   });
    //   return;
    // }
    // validation end

    this.props.saveSettingData({ ...this.state });

    if (this.context.isLoggedIn) {
      this.setState({
        isSubmitted: true
      });
      await this.props.onSubmit();
      await userActivitySendSlack('desktop', 'done-all');
      return;
    } else {
      // 비로그인 상태
    }

    this.props.goToStep(3);
  };

  deleteConsiderTag = (tag) => {
    const newConsideration = this.state.considerTags;
    const considerIdx = newConsideration.indexOf(tag);

    if (considerIdx > -1) {
      newConsideration.splice(considerIdx, 1);
    }

    this.setState({
      considerTags: newConsideration,
    });
  };

  clearForm = () => {
    this.setState({
      considerTags: [], // 상담시 고려사항
      ageGroup: '',
      gender: '',
      txMaxPrice: null, // 희망 치과 치료비
      orthoMaxPrice: null, // 희망 교정 치료비
      addressRegion: '', // 상담정보 - 유저 시/도
      addressCity: '', // 상담정보 - 유저 시/군/구
    });
  };

  render() {
    const {
      cityList,
      gender,
      ageGroup,
      addressRegion,
      addressCity,
      carePrice,
      txMaxPrice,
      orthoMaxPrice,
      considerTags,
      wantLevel,
      isSubmitted
    } = this.state;
    const { therapyData, isDirect, selectedRegionFromMain } = this.props;
    const reqType = this.getReqType(
      therapyData.pickedTxTherapy,
      therapyData.pickedOrthoType,
      therapyData.orthoPart,
    );

    return (
      <div>
        <FormContainer>
          <SubHeader step={2} />
          <FlexContainer>
            {/* 왼쪽 상담정보 설정 */}
            <LeftContainer className="cartTop">
              {!isDirect && (
                <SettingRow>
                  <SettingTitle>
                    희망지역
                    <span>*필수선택</span>
                  </SettingTitle>
                  <AddressBox>
                    <AddressSelect
                      name="addressRegion"
                      placeholder="시/도 선택"
                      onChange={this.handleRegionChange}
                      value={addressRegion || selectedRegionFromMain}
                      required
                    >
                      <option value="" unselectable="true">
                        시/도 선택
                      </option>
                      <option key={11} value="서울">
                        서울
                      </option>
                      <option key={41} value="경기">
                        경기
                      </option>
                      <option key={28} value="인천">
                        인천
                      </option>
                      {/* <Query query={locationSiQuery}>
                        {({ loading, error, data }) => {
                          if (loading) {
                            return null;
                          }

                          if (!loading && data.locationSi) {
                            return data.locationSi.map(l => (
                              <option key={l.code} value={l.name}>
                                {l.name}
                              </option>
                            ));
                          }

                          return null;
                        }}
                      </Query> */}
                    </AddressSelect>
                  </AddressBox>
                  <AddressBox>
                    <AddressSelect
                      name="addressCity"
                      placeholder="군/구 선택"
                      value={addressCity}
                      onChange={this.handleChange}
                      required
                      disabled={addressRegion === ''}
                    >
                      <option value="" unselectable="true">
                        군/구 선택
                      </option>
                      {cityList.map(l => (
                        <option key={`${l.id}-${l.code}`} value={l.name}>
                          {l.name}
                        </option>
                      ))}
                    </AddressSelect>
                  </AddressBox>
                </SettingRow>
              )}
              <SettingRow>
                <SettingTitle>기본정보</SettingTitle>
                <GenderBtnContainer>
                  <GenderBtn>
                    <input
                      type="radio"
                      id="gender-female"
                      name="gender"
                      value="female"
                      onChange={this.handleChange}
                      checked={gender === 'female'}
                    />
                    <label htmlFor="gender-female" value="female">
                      여자
                    </label>
                  </GenderBtn>
                  <GenderBtn>
                    <input
                      type="radio"
                      id="gender-male"
                      name="gender"
                      value="male"
                      onChange={this.handleChange}
                      checked={gender === 'male'}
                    />
                    <label htmlFor="gender-male" value="male">
                      남자
                    </label>
                  </GenderBtn>
                </GenderBtnContainer>
                <AgeBox>
                  <AgeSelect
                    value={ageGroup}
                    name="ageGroup"
                    onChange={this.handleChange}
                    placeholder="연령대 선택"
                    required
                  >
                    <option value="" hidden>
                      연령대 선택
                    </option>
                    <option value="less_teens">10대 이하</option>
                    <option value="twenties">20대</option>
                    <option value="thirties">30대</option>
                    <option value="forties">40대</option>
                    <option value="fifties">50대</option>
                    <option value="over_sixties">60대 이상</option>
                  </AgeSelect>
                </AgeBox>
              </SettingRow>

              <SettingRow>
                <SettingTitle marginBottom="0">
                  치료의향
                  <span>*필수선택</span>
                </SettingTitle>
                <CarePriceRadioBtnContainer>
                  {
                    data.wantLevel.map(wl => (
                      <RadioWarp key={wl.value}>
                        <RadioButton
                          id={`wl-${wl.value}`}
                          name={`wantLevel`}
                          value={wl.value}
                          onChange={() => {
                            this.setState({
                              wantLevel: wl.value
                            })
                          }}
                          checked={wantLevel === wl.value}
                        >{wl.title}</RadioButton>
                      </RadioWarp>
                    ))
                  }
                </CarePriceRadioBtnContainer>
              </SettingRow>

              <SettingRow style={{ display: 'none' }}>
                <SettingTitle>희망치료비</SettingTitle>
                <SettingSubTitle>
                  희망하는 최대 치료비를 입력해주세요
                </SettingSubTitle>
                <PriceContainer>
                  <PriceHalf
                    show={
                      therapyData && (reqType === 'all' || reqType === 'tx')
                    }
                  >
                    <PriceSubTitle>치과치료</PriceSubTitle>
                    <PriceTextInputContainer active={txMaxPrice}>
                      <PriceTextInput
                        thousandSeparator
                        type="tel"
                        value={txMaxPrice}
                        name="txMaxPrice"
                        placeholder="금액(만원)"
                        disabled={carePrice === 'dontcare'}
                        onChange={this.handleChange}
                      />
                      <PriceLabel value={txMaxPrice}>만원</PriceLabel>
                    </PriceTextInputContainer>
                  </PriceHalf>
                  <PriceHalf
                    show={
                      therapyData && (reqType === 'all' || reqType === 'ortho')
                    }
                  >
                    <PriceSubTitle>교정치료</PriceSubTitle>
                    <PriceTextInputContainer active={orthoMaxPrice}>
                      <PriceTextInput
                        thousandSeparator
                        type="tel"
                        value={orthoMaxPrice}
                        name="orthoMaxPrice"
                        placeholder="금액(만원)"
                        disabled={carePrice === 'dontcare'}
                        onChange={this.handleChange}
                      />
                      <PriceLabel value={orthoMaxPrice}>만원</PriceLabel>
                    </PriceTextInputContainer>
                  </PriceHalf>
                </PriceContainer>
                <CarePriceRadioBtnContainer>
                  <CarePriceRadioBtn>
                    <input
                      type="checkbox"
                      id="price-dontcare"
                      name="carePrice"
                      // value={carePrice === 'dontcare'}
                      onChange={() => (carePrice === 'dontcare'
                        ? this.setState({
                          carePrice: '',
                        })
                        : this.setState({
                          carePrice: 'dontcare',
                          txMaxPrice: '',
                          orthoMaxPrice: '',
                        }))
                      }
                      checked={carePrice === 'dontcare'}
                    />
                    <label htmlFor="price-dontcare" value="dontcare">
                      잘 모르겠어요
                    </label>
                  </CarePriceRadioBtn>
                </CarePriceRadioBtnContainer>
              </SettingRow>
              {!isDirect && (
                <SettingRow>
                  <SettingTitle>
                    상담시 고려사항
                    <span>*최대 3개 필수선택</span>
                  </SettingTitle>
                  <Tags>
                    <CounselingRadioInput
                      type="checkbox"
                      name="considerTags"
                      id="distance"
                      value="가까운 거리"
                      checked={considerTags.indexOf('가까운 거리') > -1}
                      onChange={this.handleConsiderationChange}
                    />
                    <CounselingRadioLabel htmlFor="distance">
                      <div>가까운 거리</div>
                    </CounselingRadioLabel>
                    <CounselingRadioInput
                      type="checkbox"
                      name="considerTags"
                      id="cheap"
                      value="최신 시설 및 설비"
                      checked={considerTags.indexOf('최신 시설 및 설비') > -1}
                      onChange={this.handleConsiderationChange}
                    />
                    <CounselingRadioLabel htmlFor="cheap">
                      <div>최신 시설 및 설비</div>
                    </CounselingRadioLabel>
                    <CounselingRadioInput
                      type="checkbox"
                      name="considerTags"
                      id="career"
                      value="자세한 설명과 친절함"
                      checked={
                        considerTags.indexOf('자세한 설명과 친절함') > -1
                      }
                      onChange={this.handleConsiderationChange}
                    />
                    <CounselingRadioLabel htmlFor="career">
                      <div>자세한 설명과 친절함</div>
                    </CounselingRadioLabel>
                    <CounselingRadioInput
                      type="checkbox"
                      name="considerTags"
                      id="quality"
                      value="편안하고 안아픈 진료"
                      checked={
                        considerTags.indexOf('편안하고 안아픈 진료') > -1
                      }
                      onChange={this.handleConsiderationChange}
                    />
                    <CounselingRadioLabel htmlFor="quality">
                      <div>편안하고 안아픈 진료</div>
                    </CounselingRadioLabel>
                    <CounselingRadioInput
                      type="checkbox"
                      name="considerTags"
                      id="installment"
                      value="치료분야별 주치의"
                      checked={considerTags.indexOf('치료분야별 주치의') > -1}
                      onChange={this.handleConsiderationChange}
                    />
                    <CounselingRadioLabel htmlFor="installment">
                      <div>치료분야별 주치의</div>
                    </CounselingRadioLabel>
                    <CounselingRadioInput
                      type="checkbox"
                      name="considerTags"
                      id="sizable"
                      value="합리적 가격"
                      checked={considerTags.indexOf('합리적 가격') > -1}
                      onChange={this.handleConsiderationChange}
                    />
                    <CounselingRadioLabel htmlFor="sizable">
                      <div>합리적 가격</div>
                    </CounselingRadioLabel>
                  </Tags>
                </SettingRow>
              )}
            </LeftContainer>
          </FlexContainer>
          <BtnWrapper>
            <BackBtn
              onClick={() => {
                this.setState({ isOpenBottomCart: false });
                this.props.saveSettingData({ ...this.state });
                this.props.goToStep(1);
              }}
            >
              이전
            </BackBtn>
            <SubmitBtn
              disabled={
                !(
                  (!isDirect
                    && (addressRegion
                      && addressCity
                      && considerTags
                      && considerTags.length > 0
                      && wantLevel
                    ))
                  || (isDirect && wantLevel)
                ) || isSubmitted
              }
              onClick={this.handleSubmit.bind(this, false)}
            >
              상담신청하기
            </SubmitBtn>
          </BtnWrapper>
        </FormContainer>
      </div>
    );
  }
}

CounselSettingForm.contextType = AuthContext;
const CounselSettingFormWithFunc = withRouter(withAlertModal(withApollo(CounselSettingForm)));
export default CounselSettingFormWithFunc;
