import React, { Component } from 'react';
import styled from 'styled-components';
import { media } from '../../globalStyle';

const LeftMenu = styled.div`
    width: 100%;
    padding-bottom: 20px;
    border-bottom: solid 1px #000000;
`;

const BoldMenu = styled.div`
  cursor: pointer;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.9px;
  color: #000000;
  & ~ & {
    margin-top: 30px;
  }
  &.active {
    font-weight: bold;
    color: #033e52;
  }
  
    line-height: 1.33;
    font-weight: 300;
    letter-spacing: -1.1px;
    display: inline-block;
    margin: 0;
    border: none;
    padding: 0;
    &:first-of-type {
      margin-right:20px;
      padding-right:20px;
      border-right: 1px solid #e0e0e0;
      text-align: left;
    }
    &.active {
      font-weight: bold;
      color: #000000;
    }
`;

export default class MyPageLeftMenu extends Component {
  render() {
    const { location } = this.props;
    return (
      <LeftMenu>
        <BoldMenu
          className={
            `${location.pathname}` === '/mypage/profile' ? 'active' : ''
          }
          onClick={() => this.props.history.push('/mypage/profile')}
        >
          나의 정보
        </BoldMenu>
        <BoldMenu
          className={
            `${location.pathname}` === '/mypage/counsel' ? 'active' : ''
          }
          onClick={() => this.props.history.push('/mypage/counsel')}
        >
          상담 내역
        </BoldMenu>
        {/* 답변조회로 변경되면서 삭제 <BoldMenu
          className={
            `${location.pathname}` === '/mypage/reservations' ? 'active' : ''
          }
          onClick={() => this.props.history.push('/mypage/reservations')}
        >
          예약 내역
        </BoldMenu>*/}
        {/* <BoldMenu onClick={this.terminateUser}>회원 탈퇴</BoldMenu>
              <SoftLine /> */}
      </LeftMenu>
    );
  }
}
