import React from 'react';
import styled from 'styled-components';
import { StyledModal } from './basic/HtmlComponents';
import { media } from '../globalStyle';
import ModalCloseIcon from '../images/ic-close-24.svg';
import { logEvent, userPhoneCallSendSlack } from '../utils/loging';

const PhoneModal = styled(StyledModal)`
  height: auto;
  padding: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  border: 0;
  border-radius: 0;
  ${media.tablet`
    width: 100%;
    bottom: 0;
    transform: none;
    top: unset;
    top: auto;
    left: 0;
    padding: 0;
    display: flex;
    flex: 1;
    flex-direction: column;
    border: 0;
    border-radius: 0;
  `};
`;

const ModalHeader = styled.div`
  padding: 20px;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #033e52;
`;

const ModalCloseBtn = styled.button`
  border: 0;
  padding: 0;
  float: right;
  vertical-align: middle;
  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    cursor: pointer;
  }
`;

const ModalContent = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -1px;
  color: #000000;
  padding: 30px 20px 20px;
  word-break: keep-all;
  height: 100%;
  position: relative;
  min-height: 296px;
  & > div:last-of-type {
    position: absolute;
    bottom: 20px;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: -0.7px;
    color: #bbbcbc;
  }
`;

const HospitalName = styled.div`
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.8px;
  color: #000000;
  margin-bottom: 12px;
`;

const ModalBtn = styled.button`
  bottom: 0;
  width: 100%;
  height: 64px;
  background-color: #033e52;
  padding: 0;
  a, a:link, a:hover, a:active {
    display: block;
    text-decoration: none;
    width: 100%;
    padding: 20px 0;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: -0.9px;
    text-align: center;
    color: #ffffff;
  }
`;

const MobileCallModal = ({
  onBtnClick,
  isOpen,
  hospital,
  onClose,
  userRequest,
  me,
}) => (
  <PhoneModal
    style={{
      overlay: {
        width: '100%',
        zIndex: 100,
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
        overflow: 'auto',
      },
    }}
    ariaHideApp={false}
    isOpen={isOpen}
  >
    <ModalHeader>
      전화문의하기
      <ModalCloseBtn onClick={onClose}>
        <img src={ModalCloseIcon} alt="ModalCloseIcon" />
      </ModalCloseBtn>
    </ModalHeader>
    <ModalContent>
      <div>
        <HospitalName>{hospital.name}</HospitalName>
        <div>
          오닥터를 통해 상담 후 연락한다고 말씀하시면
          <br />
          상담내용을 통해 간편하게 상담 받으실 수 있습니다.
        </div>
      </div>
      <div>
        {/* 안내 삭제 10-02 - css 때문에  div는 유지
        정확한 상담을 위해 고객님의 성함과 연락처가 병원에 전달됩니다.
        */}
      </div>
    </ModalContent>
    <ModalBtn
      onClick={() => {
        onBtnClick && onBtnClick();
        onClose();
        logEvent({
          category: '답변(병원) 상세',
          action: '클릭',
          label: '전화하기(팝업)',
        });
        userPhoneCallSendSlack({
          userName: me.username,
          userId: me.id,
          userPhone: me.phonenum,
          hospitalName: hospital.name,
          hospitalPhone: hospital.phonenum,
          hospitalId: hospital.id,
          answerId: userRequest.id,
        });
      }}
    >
      <a href={`tel:${hospital.phone}`}>전화하기</a>
    </ModalBtn>
  </PhoneModal>
);

export default MobileCallModal;
