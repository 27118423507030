import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CloseImg from '../../images/ic-close-gray-24-px.svg';
import { StyledModal, marginProps } from '../basic/HtmlComponents';
import { media } from '../../globalStyle';
import { FormTitle } from '../form/FormCommonComponent';
import LogoutForm from '../LogoutForm';
import ResetPasswordForm from '../form/ResetPasswordForm';
import ChangePassword from '../../routes/ChangePassword';

const AuthStyledModal = styled(StyledModal)`
  width: 380px;
  height: auto;
  position: relative;
  left: unset;
  top: unset;
  left: auto;
  top: auto;
  transform: none;
  align-self: center;
  margin: 120px auto;
  border: 1px solid #e0e0e0;
  box-shadow: none;
  padding: 30px;
  ${media.tablet`
    min-width: 319px;
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    transform: none;
    bottom: 0;
    overflow: auto;
    margin: 0;
  `};
`;

const CloseBtn = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
  top: 42px;
  right: 30px;
  position: absolute;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  ${media.tablet`
    top: 32px;
    right: 20px;
  `};
`;

const AlertContent = styled.div`
  font-size: 18px;
  text-align: left;
  color: #484848;
  @media (max-width: 576px) {
    font-size: 12px;
  }
`;

export const FormInputRow = styled.div`
  min-height: 86px;
  width: 100%;
  position: relative;
  & ~ & {
    margin-top: 12px;
  }
`;

export const FormInputRowTitle = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #ffffff;
  margin-bottom: 8px;
`;


export const FormBtnContainer = styled.div`
  margin: 22px 0 40px;
  text-align: center;
  ${marginProps}
`;

export const FormConfirmBtn = styled.button`
  width: 100%;
  height: 60px;
  border-radius: 30px;
  background-color: #ffffff;
  border: none;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #34bcd7;
  opacity: 1;
  &:disabled {
    opacity: 0.4;
    background-color: #ffffff;
  }
  ${props => props.disabledView && `
    opacity: 0.4;
    background-color: #ffffff;
  `}
`;

const ExtendedChangePassword = styled(ChangePassword)`
  padding: 0;
  margin: 0 auto;
`;

const ExtendedFormTitle = styled(FormTitle)`
  margin-bottom: 20px;
  ${media.tablet`
    margin-bottom: 10px;
  `};
`;

class AuthModal extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    modalType: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    resetPassword: PropTypes.func.isRequired,
    updateAuthModalType: PropTypes.func.isRequired,
  };

  render() {
    const {
      isOpen,
      close,
      modalType,
    } = this.props;

    let modalContent = null;

    if (modalType === 'changePassword') {
      modalContent = (
        <div>
          <div>
            <ExtendedFormTitle>비밀변호 변경</ExtendedFormTitle>
          </div>
          <AlertContent>
            <ExtendedChangePassword
              hideTitle
              {...this.props}
            />
          </AlertContent>
        </div>
      );
    }

    if (modalType === 'resetpassword') {
      modalContent = (
        <div>
          <ResetPasswordForm
            {...this.props}
          />
        </div>
      );
    }

    if (modalType === 'logout') {
      modalContent = (
        <div>
          <div>
            <ExtendedFormTitle>로그아웃</ExtendedFormTitle>
          </div>
          <AlertContent>
            <LogoutForm {...this.props} />
          </AlertContent>
        </div>
      );
    }

    return (
      <AuthStyledModal
        style={{
          overlay: {
            zIndex: 100,
            height: '100vh',
            overflow: 'auto',
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
          },
        }}
        ariaHideApp={false}
        isOpen={isOpen}
      >
        <CloseBtn src={CloseImg} alt="closeImg" onClick={close} />
        {modalContent}
      </AuthStyledModal>
    );
  }
}

export default AuthModal;
