import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { Flex } from '../atomic/atoms/Flex';
import { AuthContext } from '../AuthProvider';
import { StretchButton } from './EnhancedComponents';
import withAlertModal from '../../utils/alerthoc';
import withToast from '../../utils/toasthoc';
import withAuthModal from '../../utils/authmodalhoc';

const StyledPhoneInputContainer = styled(Flex)`
  flex-wrap: nowrap;
  margin-bottom: 10px;
`;

const StyledPhoneInputWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  border-radius: 4px;
  border: solid 1px #e1e3e2;
  & + button {
    font-size: 13px;
    color: #fff;
    min-width: 100px;
    margin-left: 5px;
    height: 56px;
    border-radius: 4px;
    background-color: #033e52;
    &:disabled{
      background-color: #e1e3e2;    
    }
    &.re-send-pin{
      border: solid 1.5px #033e52;
      background-color: #ffffff;
      color: #000;
    }
  }
`;


const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 0 14px;
  border: 0;
  outline: 0;
  font-size: 18px;
  letter-spacing: -.6px;
  &.fs-13{
    font-family: 'Saira Semi Condensed';
    font-size: 13px;
    font-weight: normal;
    font-stretch: semi-condensed;
    font-style: normal;
    letter-spacing: normal;
    color: #000000;
  }
`;


const StyledOptionContainer = styled.div`
  margin-top: 30px;
  a{
    text-decoration: underline;
    color: #000
  }
`;

const PasswordComponent = ({ onClick, toast }) => {
  // 새로 등록한 전화번호 유저
  // 입력 받은 비밀 번호로 회원가입을 먼저 진행하고,
  // 로그인 후,
  // progress 화면에서 submit() 담당
  const [agreeTerms, setAgreeTerms] = useState(true);
  const [agreeMarket, setAgreeMarket] = useState(false);
  const [inputPW, setInputPW] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const contextLogin = useContext(AuthContext);

  const regist = async ({
    name, phone, password,
  }) => {
    try {
      return await contextLogin.regist(
        name,
        phone, // phonenum
        password,
        agreeMarket,
      );
    } catch (error) {
      return error;
    }
  };

  const login = async ({ phonenum, password }) => {
    try {
      // login
      return await contextLogin.login(phonenum, password);
    } catch (error) {
      return error;
    }
  };

  const tryLogin = () => {
    const phonenum = JSON.parse(localStorage.getItem('pData')).phone;
    const password = inputPW;
    // 1 로그인 시도
    // 로그인이 되면 다음 페이지로 이동
    login({ phonenum, password })
      .then(r => r)
      .then((res) => {
        if (res.ok) {
          // 로그인 성공 이동
          onClick({ value: true });
        } else {
          // 로그인 실패
        }
      });
  };

  // 회원가입 시도
  const tryRegist = () => {
    if (!agreeTerms) {
      toast('약관 및 개인정보 취급방침에 동의해주세요');
      return;
    }

    setButtonDisabled(true);
    const savedData = JSON.parse(localStorage.getItem('pData'));
    const password = inputPW;

    // 회원가입 시도
    // 회원가입이 되면 로그인시도..
    regist({ ...savedData, password })
      .then(r => r)
      .then((res) => {
        if (res.ok) {
          // 회원가입 성공
          // 로그인 시도
          // console.log('Register success');
          tryLogin();
        } else {
          // 로그인 실패
          setButtonDisabled(false);
        }
      });
  };

  return (
    <div>
      <StyledPhoneInputContainer>
        {/* 번호 입력 */}
        <StyledPhoneInputWrap>
          <StyledInput
            onChange={(e) => {
              setInputPW(e.target.value);
            }}
            type="password"
            maxLength={12}
            className="fs-13"
            placeholder="4-12자"
          />
        </StyledPhoneInputWrap>
      </StyledPhoneInputContainer>

      <StyledOptionContainer>
        <div style={{ height: '56px', marginBottom: '10px' }}>
          <StretchButton
            active={agreeTerms}
            white
            onClick={() => {
              setAgreeTerms(!agreeTerms);
            }}
          >
            (필수)
            <span
              role="button"
              onClick={(e) => {
                e.stopPropagation();
                window.open('https://odoctor.co.kr/manual/terms');
              }}
            >
              <u>이용약관</u>
            </span>
            {' '}
            및
            <span
              role="button"
              onClick={(e) => {
                e.stopPropagation();
                window.open('https://odoctor.co.kr/manual/privacypolicy');
              }}
            >
              <u>개인정보 취급방침</u>
            </span>
            에 동의
          </StretchButton>
        </div>

        <div style={{ height: '56px' }}>
          <StretchButton
            active={agreeMarket}
            white
            onClick={() => {
              setAgreeMarket(!agreeMarket);
            }}
          >
            (선택) 광고성 정보 수신 동의
          </StretchButton>
        </div>

      </StyledOptionContainer>

      <div style={{
        position: 'fixed', bottom: 0, left: 0, width: '100%', height: '64px', textAlign: 'center'
      }}
      >
        <StretchButton
          style={{'max-width': '760px'}}
          className="fs-14"
          fixed
          disabled={inputPW.length < 4 || buttonDisabled}
          onClick={tryRegist}
        >
          상담신청 완료
        </StretchButton>
      </div>

    </div>
  );
};

PasswordComponent.contextType = AuthContext;
// export default PasswordComponent;
export default withAlertModal(withToast(withAuthModal(PasswordComponent)));
