import gql from 'graphql-tag';

export const meQuery = gql`
  {
    me {
      id
      username
      email
      sex
      phoneConfirmed
      phonenum
      metadata
    }
  }
`;

export const idk = {};
