import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PasswordInput from './PasswordInput';
import { FormInputRow, FormStyledLink, FormConfirmBtn, SignupAndSigninBtn } from './FormCommonComponent';
import { media } from '../../globalStyle';
import PhonenumInput from './PhonenumInput';

const BottomContainer = styled.div`
  margin: 12px 0 30px;
  ${media.tablet`
    margin-top: 20px;
    margin-bottom: 40px;
  `};
`;

const LinkContainer = styled.div`
  text-align: right;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: -0.8px;
  color: #000000;
  text-decoration: underline;
  margin-top: 3px;
  ${FormStyledLink} {
    text-decoration: underline;
  }
`;

class LoginForm extends React.Component {
  static propTypes = {
    goToResetPass: PropTypes.func.isRequired,
    goToSignup: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      phonenum: '',
      password: '',
      fireSubmitValidation: false,
    };
  }

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value, fireSubmitValidation: false });
  };

  onCheck = (e) => {
    const { name } = e.target;
    if (this.state[name]) {
      this.setState({ [name]: false });
    } else {
      this.setState({ [name]: true });
    }
  };

  login = async () => {
    const { phonenum, password } = this.state;

    if (!phonenum || !password) {
      this.setState({ fireSubmitValidation: true });
      return;
    }

    try {
      const loginRes = await this.props.login(phonenum, password);
    } catch (err) {
      throw err;
    }
  };

  render() {
    const { phonenum, password, fireSubmitValidation } = this.state;
    const { goToResetPass, goToSignup } = this.props;
    return (
      <div>
        <FormInputRow>
          <PhonenumInput
            name="phonenum"
            onChange={this.onChange}
            value={phonenum}
            placeholder="휴대폰 번호 (- 없이 입력)"
            fireSubmitValidation={fireSubmitValidation}
          />
        </FormInputRow>
        <FormInputRow>
          <PasswordInput
            id="loginPassword"
            name="password"
            onChange={this.onChange}
            value={password}
            placeholder="비밀번호 (4~12자)"
            noValidate
            fireSubmitValidation={fireSubmitValidation}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                this.login();
              }
            }}
          />
        </FormInputRow>
        <BottomContainer>
          <LinkContainer>
            <FormStyledLink onClick={goToResetPass}>비밀번호를 잊으셨나요?</FormStyledLink>
          </LinkContainer>
        </BottomContainer>
        <div>
          <FormConfirmBtn
            onClick={this.login}
          >
            로그인
          </FormConfirmBtn>
        </div>
        <div>
          <SignupAndSigninBtn onClick={goToSignup}>회원가입</SignupAndSigninBtn>
        </div>
      </div>
    );
  }
}

export default LoginForm;
