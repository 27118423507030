import React from 'react';
import ImageGallery from 'react-image-gallery';
import styled from 'styled-components';
import LeftArrow from '../images/ic-arrow-left-white-22-px.svg';
import { media } from '../globalStyle';

const Photo = styled.img`
  width: 100%;
  height: 273px;
  object-fit: cover;
  vertical-align: middle;
  ${media.miniDesktop`
    height: 180px;
  `};
`;

const NavBtn = styled.button`
  width: 70px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
  background-color: transparent;
  padding:0;
  img {
    display: inline-block;
    vertical-align: middle;
  }
  ${props => props.flip && `
    img {
      transform: rotate(-180deg);
    }
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
    right: 0;
    left: unset;
    left: auto;
  `}
`;

class HospitalImageGallery extends React.Component {
  renderLeftNav = (onClick, disabled) => (
    <NavBtn
      disabled={disabled}
      onClick={onClick}
    >
      <img src={LeftArrow} alt="병원사진" />
    </NavBtn>
  );

  renderRightNav = (onClick, disabled) => (
    <NavBtn
      flip
      disabled={disabled}
      onClick={onClick}
    >
      <img src={LeftArrow} alt="병원사진" />
    </NavBtn>
  );

  renderItem = item => <Photo src={item.original} alt="hospitalPhoto" />;
  render() {
    const { items } = this.props;
    return (
      <ImageGallery
        items={items}
        showThumbnails={false}
        showFullscreenButton={false}
        showPlayButton={false}
        renderItem={this.renderItem}
        renderLeftNav={this.renderLeftNav}
        renderRightNav={this.renderRightNav}
      />
    );
  }
}

export default HospitalImageGallery;
