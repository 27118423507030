import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { Flex } from '../Flex';

const StyledReactSelectWrap = styled.div`
  width: 280px; 
  .rcs__control{
    height: 56px;
    border-radius: 4px;
    box-shadow: none;
    border-color: #e0e0e0;   
    &:hover{
      border-color: #e0e0e0;
    }
  }
  .rcs__value-container{
    border-color: #e0e0e0;
  }
  .rcs__control--is-focused{
    border-color: #e0e0e0;
  }
  .rcs__value-container{
    // Select 컨테이너    
  }
  .rcs__single-value:before{
    content: '${props => props.prefixText}'
  }
  .rcs__indicators{
    .css-1gtu0rj-indicatorContainer, .css-tlfecz-indicatorContainer{
      color: #000 !important;    
    }
    .rcs__indicator-separator{
      display: none;
    }
  }
  .rcs__menu{
    border-radius: 0;
    margin: 0;  
    padding: 0;
  }
  .rcs__menu-list{
    margin: 0;
    padding: 0;
  }
  .rcs__option{
    height: 48px;
    line-height: 48px;
    padding: 0 12px;
    background-color: #fff;
    &:active{
      background-color: #033e52;
      color: #fff;
    }
    &:hover{
      background-color: #033e52;
      color: #fff;
    }
    &:focus{
      background-color: #e0e0e0;
      color: #fff;
    }
  }
  .rcs__option--is-selected{ // 선택된 항목
    background-color: #033e52;
      color: #fff;
  }
`;

const StyledNormalSelectWrap = styled.div`
  border: 1px solid #e0e0e0;
  position: relative;
  select{
    flex: 1;
    z-index: 2;
    flex-wrap: wrap;
    border: none;
    background-color: transparent;
    height: 64px;
    width: 100%;
    padding: 0 20px;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    option:disabled{
      display: none;
    }
  }
  select:focus + div{
    color: hsl(0, 0%, 40%);
  }
`;

const StyledIndicator = styled.div`
  flex-shrink: 0;
  position: absolute;
  right: 0;
`;

const ExtendedFlex = styled(Flex)`
  flex-direction: row;
`;

/**
 *
 * @param className : sc extend classname
 * @param classNamePrefix : react-select op
 * @param placeholder : react-select op
 * @param options : react-select options list
 * @returns {*}
 * @constructor
 */
export const ReactSelect = ({
  className, classNamePrefix, options, prefixText, ...others
}) => (
  <StyledReactSelectWrap
    className={className}
    prefixText={prefixText}
  >
    <Select
      classNamePrefix={classNamePrefix || 'rcs'}
      options={options}
      {...others}
    />
  </StyledReactSelectWrap>
);

export const NormalSelect = ({
  className, options, defaultValue, ...others
}) => (
  <StyledNormalSelectWrap className={className}>
    <ExtendedFlex>
      <StyledIndicator aria-hidden="true" className="rcs__indicator rcs__dropdown-indicator css-tlfecz-indicatorContainer">
        <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg">
          <path
            d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
          />
        </svg>
      </StyledIndicator>
      <select
        {...others}
      >
        {!defaultValue && <option value disabled selected>치료항목</option>}
        {
          options.map((o, i) => (
            <option key={i} value={o.value}>{o.label}</option>
          ))
        }
      </select>
    </ExtendedFlex>
  </StyledNormalSelectWrap>
);
