import React, { Component } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { compose, graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import withAlertModal from '../utils/alerthoc';
import {
  phoneNumberConfirmMutation,
  phonePinConfirmMutation,
  isExistingUserMutation,
  loginUserByPhoneMutation,
  registerUserByPhoneMutation,
} from '../graphql';
import SubHeader from './SubHeader';
import NameInput from './form/NameInput';
import PasswordInput from './form/PasswordInput';
import withToast from '../utils/toasthoc';
import therapyInfo from './form/therapyInfo';
import CheckboxInput from './form/CheckboxInput';
import { isValidPass, isValidName } from '../utils/validationInput';
import { media } from '../globalStyle';
import { AuthContext } from './AuthProvider';
import { AgreeTermCheckboxLabel } from './form/SignupForm';
import CounselPhoneConfirmInput from './form/CounselPhoneConfirmInput';
import withAuthModal from '../utils/authmodalhoc';
import {logEvent, logPageView, userActivitySendSlack} from '../utils/loging';
import CounselProcessInfoModal from "./CounselProcessInfoModal";

const Container = styled.div`
  padding-bottom: 120px;
  position: relative;
`;

const Content = styled.div`
  margin-top: 60px;
  ${media.tablet`
    padding: 0 20px;
  `};
`;

const FormSubTitle = styled.div`
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: -0.53px;
  color: #000000;
  margin-bottom: 10px;
  ${media.tablet`
    font-size: 14px;
    line-height: 1.43;
  letter-spacing: -0.41px;
  `};
`;

const InputContainer = styled.div`
  width: 320px;
  ${media.tablet`
    width: 100%;
  `};
`;

const ContainerRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  > div:first-child {
    width: 100%;
  }
  & ~ & {
    margin-top: 16px;
  }
`;

/*const PhoneCertificationBtn = styled.button`
  min-width: 120px;
  margin-left: 4px;
  min-width: 100px;
  height: 48px;
  background-color: #033e52;
  border: solid 1px #033e52;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  text-align: center;
  color: #ffffff;
  &:disabled {
    background-color: #bbbcbc;
    border-color: #bbbcbc;
    ${props => props.confirmed
      && `
      border-color: #033e52;
      background-color: #ffffff;
      color: #033e52;
    `};
  }
  ${media.tablet`
    width: 94px;
    min-width: 0;
  `};
`;*/

const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ResetPasswordLink = styled.a`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.8px;
  text-align: right;
  display: block;
  width: 100%;
  color: #000000;
  text-decoration: underline;
  margin-top: 10px;
  &:hover,
  &:link {
    color: #000000;
    text-decoration: underline;
  }
`;

const BtnWrapper = styled.div`
  margin-top: 90px;
  text-align: center;
  ${media.tablet`
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  `};
`;

const SubmitBtn = styled.button`
  background-color: #033e52;
  padding: 20px 0;
  width: 260px;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -0.89px;
  text-align: center;
  color: #ffffff;
  &:disabled {
    background-color: #bbbcbc;
  }
  ${media.tablet`
    width: 100%;
  `};
`;

const TermCheckWrapper = styled.div`
  margin-top: 30px;
`;

const PhoneInfoMessage = styled.div`
  margin-top: 10px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.8px;
  color: #bbbcbc;
  text-indent: -7px;
  padding-left: 7px;
`;

const CounselProcessInfoModalLink = styled.button`
  display: block;
  padding: 0;
  margin: 0 0 40px;
  border: 0; 
  text-decoration: underline;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.41px;
  color: #033e52;
`;

class CounselUserInfoForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phonenum: '',
      phoneConfirmed: false,
      phoneErrorMsg: '',
      fireSubmitValidation: false,
      isOdoctorUser: false,
      agreeTerms: true,
      agreeMarket: false,
      isOpenInfoModal: false,
      isSubmitted: false,
    };

    const { therapyData } = props;

    if (therapyData) {
      this.therapyListString = this.getTherapyNames(
        therapyData.pickedTxTherapy,
        therapyData.pickedOrthoType,
      );
    }

    // ga 목표 설정을위한 pageview log
    logPageView('/counsellogin');
  }

  componentDidMount() {
    userActivitySendSlack('desktop', 'in-step3');
  }

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value, fireSubmitValidation: false });
  };

  handleSubmit = async () => {
    const { isOdoctorUser, phoneConfirmed, password, name, agreeTerms, phonenum, agreeMarket } = this.state;

    if (!phoneConfirmed) {
      return;
    }

    // 가입자
    if (isOdoctorUser) {
      if (!password || !phonenum) {
        this.setState({ fireSubmitValidation: true });
        return;
      }
    }

    // button lock
    this.setState({
      isSubmitted: true
    });

    // 미가입자 - 회원가입
    if (!isOdoctorUser) {
      if (!name || !isValidName(name) || !password || !isValidPass(password)) {
        this.setState({
          fireSubmitValidation: true,
          isSubmitted: false
        });
        return;
      }

      if (!agreeTerms) {
        this.props.toast('약관 및 개인정보 취급방침에 동의해주세요');
        // button lock
        this.setState({
          isSubmitted: false
        });
        return;
      }

      // regist
      const registRes = await this.context.regist(
        name,
        phonenum,
        password,
        agreeMarket,
      );

      const { ok } = registRes;

      if (!ok) {
        // 회원가입 실패
        // button lock
        this.setState({
          isSubmitted: false
        });
        return;
      }
    }

    // login
    const loginRes = await this.context.login(
      phonenum,
      password,
    );

    if (loginRes.ok) {
      // 상담 submit
      await userActivitySendSlack('', 'done-all');
      this.props.onSubmit();
    }
  };

  getTherapyNames = (pickedTxTherapy, pickedOrthoType) => {
    let therapyNames = '';

    if (pickedTxTherapy && !_.isEmpty(pickedTxTherapy)) {
      Object.keys(pickedTxTherapy).forEach((therapyName) => {
        const therapyObj = pickedTxTherapy[therapyName];
        therapyNames += therapyInfo[therapyObj.name].viewName;
        therapyNames += ', ';
      });
    }

    if (pickedOrthoType) {
      therapyNames += therapyInfo[pickedOrthoType].viewName;
      therapyNames += ', ';
    }

    if (therapyNames.length > 2) {
      return therapyNames.substr(0, therapyNames.length - 2);
    }

    return therapyNames;
  };

  scrollTop= () => {
    window.scrollTo(0, 0);
  };

  onCheck = (e) => {
    const { name } = e.target;
    if (this.state[name]) {
      this.setState({ [name]: false });
    } else {
      this.setState({ [name]: true });
    }
  };

  render() {
    const {
      phonenum,
      fireSubmitValidation,
      phoneConfirmed,
      phoneErrorMsg,
      isOdoctorUser,
      name,
      password,
      agreeTerms,
      agreeMarket,
      isSubmitted
    } = this.state;
    return (
      <Container>
        <SubHeader
          step={3}
          phoneConfirmed={phoneConfirmed}
          isOdoctorUser={isOdoctorUser}
        />
        <Content>
          {
            !phoneConfirmed &&
            <FormSubTitle>
              상담 신청 시, 고객님의 연락처는<br/>
              절대 노출되지 않으니 걱정하지 마세요!
            </FormSubTitle>
          }
          {
            phoneConfirmed && !isOdoctorUser &&
            <FormSubTitle>
              비밀번호는 아무런 제약 없이<br/>
              4자 이상만 남겨주시면 되요!
            </FormSubTitle>
          }
          {
            phoneConfirmed && isOdoctorUser &&
            <FormSubTitle>
              이전에 설정하셨던 비밀번호 힌트는<br/>
              아무런 규칙 없이 4자 ~ 12자입니다.
            </FormSubTitle>
          }
          {/*<FormSubTitle>
            입력해주신 연락처와 정보를 통해
            {' '}
            <SmVisibleBr />
            <BlueSpan>{this.therapyListString}</BlueSpan>
            {' '}
치료에 대한
            <SmHideBr /> 답변을 확인하실 수 있어요.
          </FormSubTitle>*/}
          <CounselProcessInfoModalLink
            onClick={() => {
              this.setState({ isOpenInfoModal: true });
              logEvent({
                category: '상담신청',
                action: '클릭',
                label: '절차안내 ',
              });
            }}
          >
            상담 진행절차 알아보기
          </CounselProcessInfoModalLink>
          <InputContainer>
            <ContainerRow>
              <InputBox>
                <CounselPhoneConfirmInput
                  name="phonenum"
                  onChange={this.onChange}
                  value={phonenum}
                  fireSubmitValidation={fireSubmitValidation}
                  disabled={phoneConfirmed}
                  forceConfirm={phoneConfirmed}
                  errorMsg={phoneErrorMsg || ''}
                  onDuplicated={() => this.setState({ phoneConfirmed: true, isOdoctorUser: true })}
                  onConfirmed={() => {
                    this.scrollTop();
                    return this.setState({ phoneConfirmed: true, isOdoctorUser: false })
                  }}
                  clearError={() => this.setState({
                    fireSubmitValidation: false,
                    phoneErrorMsg: null,
                  })}
                  confirmBtnName="확인"
                />
              </InputBox>
            </ContainerRow>
            { false && !phoneConfirmed && (
              <PhoneInfoMessage>
                * 휴대폰번호는 치과와 예약이 확정되기 전까지는
                <br />
                절대 노출되지 않으니 걱정 마세요!
              </PhoneInfoMessage>
            )}
            {phoneConfirmed && !isOdoctorUser && (
              <React.Fragment>
                <ContainerRow>
                  <NameInput
                    /*autoFocus*/
                    id="counselSignupName"
                    type="text"
                    placeholder="이름"
                    name="name"
                    onChange={this.onChange}
                    value={name}
                    fireSubmitValidation={fireSubmitValidation}
                    onFocus={() => {
                      userActivitySendSlack('desktop', 'focus-name-input');
                    }}
                    onBlur={() => {
                      userActivitySendSlack('desktop', 'blur-name-input', name);
                    }}
                  />
                </ContainerRow>
                <ContainerRow>
                  <PasswordInput
                    id="loginPassword"
                    name="password"
                    onChange={this.onChange}
                    onFocus={() => {
                      userActivitySendSlack('desktop', 'focus-pw-input');
                    }}
                    onBlur={() => {
                      userActivitySendSlack('desktop', 'blur-pw-input');
                    }}
                    value={password}
                    placeholder="비밀번호 (4~12자)"
                    textVisible
                    fireSubmitValidation={fireSubmitValidation}
                  />
                </ContainerRow>
                <TermCheckWrapper>
                  <CheckboxInput
                    type="checkbox"
                    id="agreeTerms"
                    name="agreeTerms"
                    checked={agreeTerms}
                    onChange={this.onCheck}
                    labelText="(필수) 이용약관 및 개인정보 취급방침에 동의"
                    labelComponent={<AgreeTermCheckboxLabel />}
                  />
                  <CheckboxInput
                    type="checkbox"
                    id="agreeMarket"
                    name="agreeMarket"
                    checked={agreeMarket}
                    onChange={this.onCheck}
                    labelText="(선택) 광고성 정보 수신 동의"
                  />
                </TermCheckWrapper>
              </React.Fragment>
            )}
            {phoneConfirmed && isOdoctorUser && (
              <React.Fragment>
                <ContainerRow>
                  <PasswordInput
                    id="loginPassword"
                    name="password"
                    onChange={this.onChange}
                    value={password}
                    placeholder="비밀번호"
                    noValidate
                    fireSubmitValidation={fireSubmitValidation}
                    scrollTo={() =>{this.scrollTop()}}
                  />
                </ContainerRow>
                <ResetPasswordLink onClick={() => this.props.openAuthModal('resetpassword')}>비밀번호를 잊으셨나요?</ResetPasswordLink>
              </React.Fragment>
            )}
          </InputContainer>
        </Content>
        {phoneConfirmed && (
          <BtnWrapper>
            <SubmitBtn
              disabled={isSubmitted}
              onClick={this.handleSubmit}>상담신청 완료!</SubmitBtn>
          </BtnWrapper>
        )}
        <CounselProcessInfoModal isOpen={this.state.isOpenInfoModal} onClose={() => this.setState({ isOpenInfoModal: false })} />
      </Container>
    );
  }
}

CounselUserInfoForm.contextType = AuthContext;
const CounselUserInfoFormWithFunc = compose(
  graphql(isExistingUserMutation, {
    name: 'isExistingUser',
  }),
  graphql(phoneNumberConfirmMutation, {
    name: 'phoneNumberConfirm',
  }),
  graphql(phonePinConfirmMutation, {
    name: 'phonePinConfirm',
  }),
  graphql(loginUserByPhoneMutation, {
    name: 'loginUserByPhone',
  }),
  graphql(registerUserByPhoneMutation, {
    name: 'registerUserByPhone',
  }),
)(withAlertModal(withRouter(withToast(withAuthModal(CounselUserInfoForm)))));
export default CounselUserInfoFormWithFunc;
