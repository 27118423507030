import React, { Component, useRef, useState, useEffect  } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { SectionHr } from '../atomic/atoms/Shape/Bar'
import { Section} from '../atomic/molecules/Section';
import { StarWithPoint } from '../atomic/molecules/StarRating';
import { ReviewCard } from '../atomic/molecules/Card';
import { Flex, FlexItem } from '../atomic/atoms/Flex';
import { StretchButton } from '../atomic/molecules/ButtonGroup';
import { MaskedName } from '../atomic/atoms/Text/Text.js';
import { media } from '../../globalStyle';
import useIntersection from '../../components/atomic/useIntersection';

const ExtendedSection =  styled(Section)`
  h3{
    margin: 0;
    padding-bottom: 40px;
    ${media.tablet`
      padding-bottom: 20px;
    `};
  }
  .star-box{
    margin-bottom: 20px;
    * {
      cursor: default;
    }
  }
  .r-c-wrapper{
    margin-bottom: 15px;
  }
  .r-row{
    ${ props => !props.viewAll && 'margin-bottom: 50px' };
    flex-direction: row;
    align-items: flex-start;
    ${media.tablet`
      ${ props => !props.viewAll && 'margin-bottom: 40px' };
    `};
  }
  .view-all-reviews{
    text-align: center;
    button{
      width: 60%;
      ${media.miniDesktop`
        width: 100%;
      `};  
    }
    margin-top: 30px;
  }
`;

const ExtendedStretchButton = styled(StretchButton)`
  font-size: 13px;
  letter-spacing: -.5px;
  height: 56px;
  border-radius: 4px;
  border: solid 1px #e1e3e2;
  color: #000;
  font-weight: normal;
`;

const StyledReviewText = styled.div`
  width: 100%;
  position: relative;
  text-align: justify;
  line-height: 1.5;
  letter-spacing: -.5px;
  font-size: 14px;
  .text-more{
    letter-spacing: -1px;
    color: #033e52;
    text-decoration: underline;
    cursor: pointer;
    padding: 5px;    
    display: inline;
    word-break: keep-all;
  }
  
  ${media.tablet`
    width: 100%;
  `};
`;

const StyledSectionHr = styled(SectionHr)`
  margin: 30px 0;
`;

const StyledNoRevies = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -0.8px;
  color: #bbbcbc;
`;

const StyledDoctorTherapy = styled.div`
  margin-top: -8px;
  font-size: 14px; 
  letter-spacing: -1px;
  color: #033e52;
  &>div{
    display: inline-block;
    vertical-align: top;
  }
  .tp-text{
    display: inline-block;
    position: relative;
    .mask{
      display: inline-block;
      width: 230px;
      white-space: nowrap; 
      overflow: hidden;
      text-overflow: ellipsis;
    }
    ${media.tablet`
      margin-bottom: 20px;
    `};
  }
  .dname-text{
    display: inline-block;
    &::before{
      content: '';
      display: inline-block;
      width: 1px;
      height: 10px;
      margin-right: 8px;
      margin-left: 8px;
      background-color: #e4e4e4;
    }  
  }
`;

const StyledReviewInfo = styled.div`
  width: 140px;
  ${media.tablet`
    font-size: 16px;
  `};
`;

const ExtendedFlexItem = styled(FlexItem)`
  flex: 1;
  ${media.tablet`
    width: 100%;
    flex: auto;
  `};
`;

const NoReviewComponent = ({title}) => (
  <ExtendedSection title={title}>
    <StyledNoRevies>
      아직 리뷰가 없습니다.<br />
      이 병원에서 치료경험이 있다면 리뷰를 남겨주세요!
    </StyledNoRevies>
  </ExtendedSection>
);

export const ReviewViewerComponent = (
  {
    title,
    viewAll,
    hideStar,
    starSize,
    fontSize,
    moreButtonText,
    reviews,
    onLoadMore,
    paging, // total, perPage
  }
) => {
  // instance variable
  const currentPage = useRef(1);
  const totalPage = useRef(Math.ceil(paging.total/paging.perPage));
  const targetRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // re
  const [review, setReview] = useState(reviews || []);

  useEffect(
    () => {
      // if (!root) return;
      setReview(reviews);
      setLoading(false);
    }, [reviews]
  );

  // infinite scrolling
  useIntersection({
    // root: null,
    target: targetRef,
    targetRef: targetRef,
    onIntersect: ([{isIntersecting}]) => {
      if(
        isIntersecting &&
        !loading &&
        currentPage.current < totalPage.current
      ) {
        setLoading(true)
        // console.log('loadmore');
        currentPage.current++;
        onLoadMore(currentPage.current)
      }
    }
  });

  return (
    <React.Fragment>
      <ExtendedSection title={title} viewAll={viewAll}>
        {
          review.map((r, idx) => (
            <React.Fragment key={r.id}>
              <Flex className={'r-row'}>
                <StyledReviewInfo>
                  {
                    !hideStar
                    &&
                    (
                      <div className={'star-box'}>
                        <StarWithPoint
                          size={starSize}
                          fontSize={fontSize || '14px'}
                          selectedNum={r.rating.toFixed(1)}
                        />
                      </div>
                    )
                  }
                </StyledReviewInfo>
                <ExtendedFlexItem stretch>
                  <ReviewCard
                    name={<MaskedName >{r.reviewerName}</MaskedName>}
                    date={moment(r.reviewDate).format('YYYY.MM.DD')}
                  >
                    <StyledReviewText
                      className={r.isMore ? 'isMore' : ''}
                    >
                      {!r.isMore ? r.content : `${r.content.slice(0, 90)}...`}
                    </StyledReviewText>
                    {
                      review.length - 1 === idx && targetRef &&
                      <div ref={targetRef} />
                    }

                  </ReviewCard>
                </ExtendedFlexItem>
              </Flex>
              { viewAll && <StyledSectionHr /> }
            </React.Fragment>
          ))
        }
        <div className={'view-all-reviews'}>
          { moreButtonText &&
          <ExtendedStretchButton
            outline
            onClick={onLoadMore}
          >
            {moreButtonText}
          </ExtendedStretchButton> }
        </div>
      </ExtendedSection>
    </React.Fragment>
  )
};

export class ReviewViewerComponentBak extends Component {
  constructor(props) {
    super(props);
    // Set init
    this.state = {
      reviews: this.props.reviews,
      // reviews: this.props.reviews.map(r => {
      //   r.isMore = this.props.viewAll ? false : r.desc.length > 50;
      //   return r;
      // }),
      remains: null,
    };

    this.viewMore = this.viewMore.bind(this);
    this.loadMoreReview = this.loadMoreReview.bind(this);

    const infiniteObserver = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.reviews !== prevState.reviews) {
      return { reviews: nextProps.reviews };
    }
    return null;
  }

  componentDidMount() {
    // calc 남은 리뷰
    const { total, lastPage, currentPage, perPage } = this.props.paging;
    const remains = !lastPage === currentPage && total - ((currentPage-1) * perPage);
    this.setState({
      remains
    });
  }

  // 리뷰 펼쳐서 '더보기'
  viewMore(id) {
    this.setState({
      reviews: this.state.reviews.map((r) => {
        if(id === r.id) r.isMore = !r.isMore;
        return r;
      })
    })
  }

  getTherapyName = (tps) => (
    <span className={tps.length > 3 ? 'mask' : ''} >
      {tps.map((t) => (this.props.therapy[t])).join(', ')}
    </span>
  );

  loadMoreReview() {
    this.props.onLoadMore()
  }

  render() {
    const { moreButtonText, title, viewAll, hideStar, hideMoreButton } = this.props;
    const { reviews } = this.state;
    const starSize = 14;

    if(reviews.length === 0) {
      return ( <NoReviewComponent title={title} /> )
    }

    return (
      <React.Fragment>
        <ExtendedSection title={title} viewAll={viewAll}>
          {
            reviews.map((r) => (
              <React.Fragment key={r.id}>
                <Flex className={'r-row'}>
                  <StyledReviewInfo>
                    {
                      !hideStar
                      &&
                      (
                        <div className={'star-box'}>
                          <StarWithPoint
                            size={'14px'}
                            fontSize='14px'
                            selectedNum={r.rating}
                          />
                        </div>
                      )
                    }

                    { /* 전체 보기 때문 노출 */
                      // 05-08 리뷰 개선 삭제
                      // viewAll &&
                      // <StyledDoctorTherapy>
                      //   <div className={'tp-text'}>{this.getTherapyName(r.jsondata.therapyType)}</div>
                      //   { r.doctor && <div className={'dname-text'}>{r.doctor.name} 원장님</div>}
                      // </StyledDoctorTherapy>
                    }
                  </StyledReviewInfo>
                  <FlexItem stretch>
                    <ReviewCard
                      name={<MaskedName >{r.reviewerName}</MaskedName>}
                      date={moment(r.reviewDate).format('YYYY.MM.DD')}
                    >
                      <StyledReviewText className={r.isMore ? 'isMore' : ''}>
                        {!r.isMore ? r.content : `${r.content.slice(0, 90)}...`}
                        { !hideMoreButton && (
                          r.isMore &&
                          <span
                            className={'text-more'}
                            onClick={() => {this.viewMore(r.id)}}
                          >
                           더보기
                          </span>
                        )}
                      </StyledReviewText>
                    </ReviewCard>
                  </FlexItem>
                </Flex>
                { viewAll && <StyledSectionHr /> }
              </React.Fragment>
            ))
          }
          <div className={'view-all-reviews'}>
            { moreButtonText &&
                <ExtendedStretchButton
                  ref={this.infiniteObserver}
                  outline
                  onClick={this.loadMoreReview}
                >
                  {moreButtonText}
                </ExtendedStretchButton> }
          </div>
        </ExtendedSection>

      </React.Fragment>
    )
  }
}

ReviewViewerComponent.propTypes = {
  // reservationId: PropTypes.number.isRequired,
};
