import styled, { css } from 'styled-components';
import Modal from 'react-modal';
import { media } from '../../globalStyle';

export const hideScrollBar = css`
  overflow: -moz-scrollbars-none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  ::-webkit-scrollbar { 
    width: 0 !important;
    display: none; /* Safari and Chrome */
    -webkit-appearance: none;
  }
`;

export const scrollTouch = css`
  -webkit-overflow-scrolling: touch;
`;

export const textProps = props => css`
  ${props.color && `color: ${props.color || 'inherit'}`};
  ${props.fontSize && `font-size: ${props.fontSize || 'inherit'}`};
  ${props.fontWeight && `font-weight: ${props.fontWeight || 'inherit'}`};
  ${props.textAlign && `text-align: ${props.textAlign || 'inherit'}`};
  ${props.lineHeight && `line-height: ${props.lineHeight || 'normal'}`};
`;

export const borderProps = props => css`
  ${props.borderBottom && `border-bottom: ${props.borderWidth || '1px'} solid ${props.borderColor}`};
  ${props.borderTop && `border-top: ${props.borderWidth || '1px'} solid ${props.borderColor}`};
  ${props.borderLeft && `border-left: ${props.borderWidth || '1px'} solid ${props.borderColor}`};
  ${props.borderRight && `border-right: ${props.borderWidth || '1px'} solid ${props.borderColor}`};
`;

export const marginProps = props => css`
  ${props.marginBottom && `margin-bottom: ${typeof (props.marginBottom) === 'string' ? props.marginBottom : '1em'}`};
  ${props.marginTop && `margin-top: ${typeof (props.marginTop) === 'string' ? props.marginTop : '1em'}`};
  ${props.marginLeft && `margin-left: ${typeof (props.marginLeft) === 'string' ? props.marginLeft : '1em'}`};
  ${props.marginRight && `margin-right: ${typeof (props.marginRight) === 'string' ? props.marginRight : '1em'}`};
  ${props.margin && `margin: ${typeof (props.margin) === 'string' ? props.margin : '1em'}`};
  ${props.marginVertical && `
    margin-top: ${typeof (props.marginVertical) === 'string' ? props.marginVertical : '1em'}
    margin-bottom: ${typeof (props.marginVertical) === 'string' ? props.marginVertical : '1em'}
  `};
  ${props.marginHorizontal && `
    margin-left: ${typeof (props.marginHorizontal) === 'string' ? props.marginHorizontal : '1em'}
    margin-right: ${typeof (props.marginHorizontal) === 'string' ? props.marginHorizontal : '1em'}
  `};
`;

export const paddingProps = props => css`
  ${props.paddingBottom && `padding-bottom: ${typeof (props.paddingBottom) === 'string' ? props.paddingBottom : '1em'}`};
  ${props.paddingTop && `padding-top: ${typeof (props.paddingTop) === 'string' ? props.paddingTop : '1em'}`};
  ${props.paddingLeft && `padding-left: ${typeof (props.paddingLeft) === 'string' ? props.paddingLeft : '1em'}`};
  ${props.paddingRight && `padding-right: ${typeof (props.paddingRight) === 'string' ? props.paddingRight : '1em'}`};
  ${props.padding && `padding: ${typeof (props.padding) === 'string' ? props.padding : '1em'}`};
  ${props.paddingVertical && `
    padding-top: ${typeof (props.paddingVertical) === 'string' ? props.paddingVertical : '1em'}
    padding-bottom: ${typeof (props.paddingVertical) === 'string' ? props.paddingVertical : '1em'}
  `};
  ${props.paddingHorizontal && `
    padding-left: ${typeof (props.paddingHorizontal) === 'string' ? props.paddingHorizontal : '1em'}
    padding-right: ${typeof (props.paddingHorizontal) === 'string' ? props.paddingHorizontal : '1em'}
  `};
`;

export const sizeProps = props => css`
  ${props.width && `width: ${typeof (props.width) === 'string' ? props.width : 'auto'}`};
  ${props.height && `height: ${typeof (props.height) === 'string' ? props.height : 'auto'}`};
`;

export const Col = styled.div`
  display: inline-block;
  width: ${props => props.width || 'auto'};
`;

export const InlineDiv = styled.div`
  display: inline-block;
`;

export const PaddingDiv = styled.div`
  ${paddingProps};
`;

export const FlexRow = styled.div`
  display: flex;
  width: ${props => props.width || '100%'};
  justify-content: ${props => props.justifyContent || 'initial'};
`;

export const Ul = styled.ul`
  list-style: none;
`;

export const Li = styled.li`
  list-style: none;
`;

export const Hr = styled.hr`
  margin: ${props => props.margin || 0};
  border-top: ${props => props.height || 1}px solid ${props => props.color || '#8a8a8a'};
`;

export const A = styled.a`
  color: #2b2b2b;
  &:hover {
    color: #2b2b2b;
    box-shadow: 0 2px 0 #2b2b2b;
  }
  &:active {
    color: #2b2b2b;
  }
`;

export const BlankDiv = styled.div`
  width: 100%;
  height: ${props => props.height}px;
`;

export const ShowSm = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;

export const HideSm = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

export const StyledModal = styled(Modal)`
  width: 460px;
  left: 50%;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  height: auto;
  transform: translate(-50%, -50%);
  top: 50%;
  position: absolute;
  border: 1px solid #eeeeee;
  padding: 30px;
  &:hover,
  &:focus {
    outline: none;
  }
  ${media.tablet`
    width: 280px;
    height: auto;
    left: 50%;
    top: 50%;
    margin: 0;
    padding: 20px;
    transform: translate(-50%, -50%);
  `};
`;

export const DashedDiv = styled.div`
  padding: 10px;
  border-width: 1px;
  border-color: rgb(102, 102, 102);
  border-style: dashed;
  border-radius: 5px;
`;

export const TextDiv = styled.div`
  color: ${props => props.color || 'inherit'};
  font-size: ${props => props.fontSize || 'inherit'};
  font-weight: ${props => props.fontWeight || 'inherit'};
  text-align: ${props => props.textAlign || 'inherit'};
  line-height: ${props => props.lineHeight || 'normal'};
  margin: ${props => props.margin || 0};
  width: ${props => props.width || 'auto'};
`;

export const FormContainer = styled.div`
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 20px;
`;

export const FormBackground = styled.div`
  min-height: 100vh;
  background-color: #38c8e5;
  padding: 180px 0 80px;
`;

export const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  text-align: left;
  ${media.tablet`
    font-size: 30px;
  `};
`;

export const SubTitle = styled.div`
  opacity: 0.78;
  font-size: 17px;
  line-height: 1.53;
  letter-spacing: -0.3px;
  text-align: left;
  margin-top:24px;
  ${media.tablet`
    font-size: 15px;
  `};
`;

export const BlueLine = styled.div`
  width: 64px;
  height: 6px;
  background-color: #38c8e5;
  margin-top:42px;
  margin-bottom:82px;
`;

export const CheckboxInput = styled.input`
  display: none;
  &:checked {
    + label {
      color: #38c8e5;
      border-color : #38c8e5;
    }
  }
`;

export const CheckboxLabel = styled.label`
  border-radius: 30px;
  background-color: #ffffff;
  border: solid 1px #e4e4e4;
  padding: 6px 18px;
  font-size: 15px;
  text-align: center;
  margin: 10px 8px 0 0;
  cursor: pointer;
  color: gray;
  @media(max-width: 700px) {
    font-size: 15px;
    padding: 7px 11px;
    margin: 10px 9px 1px 0;
  }
`;

export const HospitalCardListContainer = styled.div`
  margin: 0 -5px;
`;

export const HospitalImageContainer = styled.div`
  position: relative;
  margin-bottom: 12px;
`;

export const HospitalImage = styled.img`
  width: 100%;
  height: 200px;
  margin-bottom: 12px;
  background-color: #e0e0e0;
  object-fit: cover;
`;

export const FavoriteBtn = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  z-index: 2;
`;
export const FavoriteImage = styled.img`
  width: 32px;
  height: 32px;
  cursor: pointer;
  object-fit: contain;
  vertical-align: middle;
`;

export const HospitalName = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.9px;
  color: #000000;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${media.tablet`
    font-size: 18px;
    letter-spacing: -1px;
    display: inline-block;
    max-width: calc(100% - 86px);
    vertical-align: middle;
  `};
`;

export const PinkSpan = styled.span`
  font-weight: bold;
  letter-spacing: -0.6px;
  color: #ffb3aa;
`;

export const BlueSpan = styled.span`
  font-weight: bold;
  letter-spacing: -0.6px;
  color: #033e52;
`;

export const HospitalAddress = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.92;
  letter-spacing: -0.6px;
  color: #000000;
  margin-bottom: 20px;
  ${media.tablet`
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: -0.5px;
    margin-bottom: 10px;
  `};
`;

export const TagContainer = styled.div`
  ${media.tablet`
    margin-top: 0px;
  `};
`;

export const Tag = styled.span`
  margin-right: 10px;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: -0.7px;
  color: #bbbcbc;
  display: inline-block;
  ${media.tablet`
    font-size: 13px;
    line-height: 1.77;
    letter-spacing: -0.5px;
  `};
  ${props => props.active && 'color: #000000;'};
`;

export const PrevButton = styled.button`
  width: 318px;
  height: 50px;
  border-radius: 3px;
  border: solid 2px #38c8e5;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: #38c8e5;
  margin-right: 20px;
  background: #fff;
  @media (max-width: 700px) {
    font-size: 14px;
    height: 40px;
    margin-right: 12px;
  }
`;

export const ConfirmButton = styled.button`
  width: 318px;
  height: 50px;
  border-radius: 3px;
  background-color: #38c8e5;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: white;
  @media (max-width: 700px) {
    font-size: 14px;
    height: 40px;
  }
`;

export const Card = styled.div`
  width: ${props => props.width || '50%'};
  display: inline-block;
  padding: 0 5px;
  min-height: 425px;
  cursor: pointer;
  vertical-align: top;
  ${props => props.theme === 'local' && `
    min-height: 219px;
    width: 33.333%;
  `};
  ${props => props.theme === 'resp' && `
    width: 50%;
    min-height: 425px;
    ${HospitalImageContainer} {
      margin-bottom: 0;
    }
  `};
  ${props => props.theme === 'direct' && `
    min-height: 0;
  `};
  ${media.tablet`
    width: 100%;
    max-width: 100%;
    min-height: 0;
    & ~ & {
      margin-top: 60px;
    }
  `};
`;

export const SmHideBr = styled.br`
  display: block;
  ${media.tablet`
    display: none;
  `};
`;

export const SmVisibleBr = styled.br`
  display: none;
  ${media.tablet`
    display: block;
  `};
`;
