import { ApolloLink } from 'apollo-link';

const omitDeepArrayWalk = (arr, key) => arr.map((val) => {
  if (Array.isArray(val)) return omitDeepArrayWalk(val, key);
  if (typeof val === 'object') return omitDeep(val, key);
  return val;
});

const omitDeep = (obj, key) => {
  const keys = Object.keys(obj);
  const newObj = {};
  keys.forEach((i) => {
    if (i !== key) {
      const val = obj[i];
      if (Array.isArray(val)) newObj[i] = omitDeepArrayWalk(val, key);
      else if (typeof val === 'object' && val !== null) newObj[i] = omitDeep(val, key);
      else newObj[i] = val;
    }
  });
  return newObj;
};

// graphql variables에 있는 __typename field를 삭제하는 ApolloLink 정의.
// https://gist.github.com/cdelgadob/4041818430bc5802016332dbe5611570 참고
const cleanTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = omitDeep(operation.variables, '__typename');
  }
  return forward(operation).map(data => data);
});

export default cleanTypenameLink;
