import React from 'react';
import styled from 'styled-components';

const StyledProgressBar = styled.div`
  height:100%;
  min-height: 2px;
  background: #fff;
`;

const StyledProgressThumb = styled.div`
  height: 100%;
  width: ${props => props.pct}%;
  max-width: 100%;
  background-color: ${props => props.color || '#1b6d85'};
  transition:width 0.3s;
`;

const ProgressBar = ({
  stages = 4, currStep = 0, color, className,
}) => {
  if (!Number.isInteger(currStep * stages)) {
    throw Error('숫자만');
  }

  const sg = stages < 0 ? 0 : stages;
  const sc = currStep < 0 ? 0 : currStep;
  const pct = Math.ceil(100 / sg * sc);

  return (
    <StyledProgressBar className={className}>
      <StyledProgressThumb
        color={color}
        pct={pct || 0}
      />
    </StyledProgressBar>
  );
};

export { ProgressBar };
