import React from 'react';
import styled from 'styled-components';
import { graphql, compose } from 'react-apollo';
import { getDoctorQuery } from '../graphql';
import { media } from '../globalStyle';
import { marginProps, paddingProps, hideScrollBar, scrollTouch } from '../components/basic/HtmlComponents';
import DoctorPhotoDefault from '../images/doctor-default-lg.png';
import VideoPlayBtnIcon from '../images/btn-play.png';
import { logEvent } from '../utils/loging';
import {ReviewViewerComponent} from "../components/ReviewViewer/ReviewViewerComponent";
import {SectionHr} from "../components/atomic/atoms/Shape/Bar";
import {UserReviewListQuery} from "../graphql/reviewQuery";


const Container = styled.div`
  padding: 40px 20px 130px;
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: space-between;
  ${media.miniDesktop`
    min-width: 320px;
    padding: 0 0 130px;
    display: flex;
    flex-direction: column-reverse;
  `};
  ${media.tablet`
    padding-top: 58px;
  `};
`;

const Left = styled.div`
  width: 100%;
  margin-right: 10px;
  ${media.miniDesktop`
    padding: 0px 20px;
  `};
`;

const Right = styled.div`
  width: 350px;
  min-width: 350px;
  ${media.miniDesktop`
    width: 100%;
    min-width: 0;
  `};
`;

const Section = styled.div`
  ${marginProps};
`;

const Title = styled.div`
  &.doctor-clip{
    ${media.miniDesktop`
      margin-top: 20px;
    `}
  }
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: -1px;
  color: #000000;
  margin-bottom: 21px;
  span {
    display: none;
    float: right;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.9px;
    text-align: right;
    color: #000000;
    padding-top: 6px;
  }
  ${paddingProps};
  ${media.miniDesktop`
    span {
      display: inline;
    }
  `};
`;

const History = styled.ul`
  list-style-type: none;
  padding: 0;
  li {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -1px;
    color: #000000;
    padding-left: 14px;
    position: relative;
    &:before {
      content: '';
      width: 4px;
      height: 4px;
      background-color: #000000;
      position: absolute;
      left: 0;
      top: 6px;
    }
  }
  li ~ li {
    margin-top: 10px;
  }
`;

const DoctorProfile = styled.div`
  border: solid 1px #e4e4e4;
  ${media.miniDesktop`
    border: 0;
  `};
`;

const DoctorBottom = styled.div`
  padding: 20px 20px 40px;
  ${media.miniDesktop`
    padding: 20px 20px 0;
  `};
`;

const DoctorInfo = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: -0.5px;
  color: #000000;
  margin-bottom: 20px;
  div {
    display: inline-block;
    &:nth-of-type(2) {
      border-left: 1px solid #e4e4e4;
      padding-left: 8px;
      margin-left: 8px;
    }
  }
  ${media.miniDesktop`
    margin-bottom: 10px;
  `};
`;

const DoctorPhoto = styled.div`
  img {
    width: 100%;
    height: 261px;
    object-fit: cover;
    vertical-align: middle;
  }
  ${media.miniDesktop`
    width: 100%;
    padding-bottom: 75%;
    margin: 0;
    position: relative;
    img {
      height: 100%;
      position: absolute;
    }
  `};
`;

const DoctorName = styled.div`
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: -1.3px;
  color: #000000;
  margin-bottom: 6px;
  ${media.miniDesktop`
    font-size: 18px;
    line-height: 1.67;
    letter-spacing: -1px;
    margin-bottom: 2px;
  `};
`;

const DoctorIntro = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -0.9px;
  color: #000000;
  white-space: pre-wrap;
  word-break: keep-all;
  ${media.miniDesktop`
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: -0.8px;
  `};
`;


const Video = styled.div`
  display: inline-block;
  margin-right: 20px;
  margin-top: 20px;
  width: 213px;
  ${media.miniDesktop`
    min-width: calc(100% - 30px);
    margin-right: 0;
    & ~ & {
      margin-left: 10px;
    }
  `};
`;

const VideoContainer = styled.div`
  margin-right: -20px;
  margin-top: -20px;
  ${hideScrollBar};
  ${scrollTouch};
  ${media.miniDesktop`
    margin-right: 0;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    ${props => (props.single && `
      ${Video} {
        width: 100%;
      }
    `)};
  `};
`;


const VideoWapper = styled.div`
  ${media.miniDesktop`
    position: relative;
    width: 100%;
    padding-bottom: 56.5%;  
  `};
`;

const VideoPreview = styled.div`
  width: 100%;
  height: 120px;
  background-color: rgba(0, 0, 0, 0.3);
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  ${props => (props.thumbnail && `
    background-image: url(${props.thumbnail});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  `)};
  ${media.miniDesktop`
    position: absolute;
    width: 100%;
    height: 100%;
  `};
  ::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.3);
    background-image: url(${VideoPlayBtnIcon});
    background-size: 46px 46px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
`;

const VideoName = styled.div`
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -0.9px;
  color: #000000;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const VideoSpeaker = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: -0.7px;
  color: #000000;
`;

const StyledSectionHr = styled(SectionHr)`
  margin: 30px 0;
`;



class Doctor extends React.Component {
  moveToReviews = () => {
    const {history, match, getDoctor} = this.props;
    const doctorId = match.params.id;
    const hospitalId = getDoctor.getDoctor.hospital.id;
    history.push(`/hospitals/${hospitalId}/reviews/${doctorId}`);
  };

  render() {
    if (this.props.getDoctor.loading) {
      return null;
    }

    if (this.props.userReviewList.loading) {
      return null;
    }

    const { getDoctor } = this.props.getDoctor;
    const { reviews, pagination } = this.props.userReviewList.userReviewList;

    if (!getDoctor) {
      // 의사를 찾을 수가 없습니다.
      return null;
    }

    return (
      <Container>
        <Left>
          {getDoctor.videos && getDoctor.videos.length > 0 && (
            <>
              <Section>
                <Title className={'doctor-clip'}>
                  동영상
                  <span>총 <b>{getDoctor.videos.length}</b>건</span>
                </Title>
                <VideoContainer single={getDoctor.videos.length === 1}>
                  {getDoctor.videos.map(video => (
                    <Video key={`${video.thumbnail}_${video.id}`}>
                      <VideoWapper>
                        <VideoPreview
                          thumbnail={video.thumbnail}
                          onClick={() => {
                            window.open(video.url);
                            logEvent({
                              category: '의사 상세',
                              action: '클릭',
                              label: '동영상 목록',
                              value: video.id,
                            });
                          }}
                        />
                      </VideoWapper>
                      <VideoName>{video.title}</VideoName>
                      <VideoSpeaker>{getDoctor.name} 원장님</VideoSpeaker>
                    </Video>
                  ))}
                </VideoContainer>
              </Section>
              <StyledSectionHr />
            </>
          )}
          { false && !!pagination.total && (
            <>
            <StyledSectionHr />
            <ReviewViewerComponent
              title={'리뷰'}
              reviews={reviews}
              paging={pagination}
              onLoadMore={this.moveToReviews}
              moreButtonText={pagination.total > 3 && `${pagination.total}개 리뷰 전체보기`}
            />
            </>
          )
          }
          <Section>
            <Title>약력</Title>
            <div>
              <History>
                {getDoctor.metadata && getDoctor.metadata.brief_history && getDoctor.metadata.brief_history.map((history, idx) => (
                  <li key={`history-${idx}`}>{history}</li>
                ))}
              </History>
            </div>
          </Section>
        </Left>
        <Right>
          <DoctorProfile>
            <DoctorPhoto>
              {getDoctor.photos && getDoctor.photos.length > 0 && getDoctor.photos[0].url ? (
                <img
                  src={getDoctor.photos[0].url}
                  onError={(e) => {
                    e.target.src = DoctorPhotoDefault;
                  }}
                  alt="doctor"
                />
              ) : (
                <img src={DoctorPhotoDefault} alt="doctor" />
              )}
            </DoctorPhoto>
            <DoctorBottom>
              <DoctorName>
                {getDoctor.name} 원장님
              </DoctorName>
              <DoctorInfo>
                <div>{getDoctor.hospital.name}</div>
                {getDoctor.speciality && (<div>{getDoctor.speciality}</div>)}
              </DoctorInfo>
              {getDoctor.metadata && getDoctor.metadata.oneline_profile && (
                <DoctorIntro>
                  {getDoctor.metadata.oneline_profile}
                </DoctorIntro>
              )}
            </DoctorBottom>
          </DoctorProfile>
        </Right>
      </Container>
    );
  }
}
export default compose(
  graphql(getDoctorQuery, {
    name: 'getDoctor',
    options: ({ match }) => ({
      variables: { id: match.params.id },
    }),
  }),
  graphql(UserReviewListQuery, {
    name: 'userReviewList',
    options: ({ match }) => ({
      variables: {
        doctorId: match.params.id,
        perPage: 3,
        currentPage: 1,
      },
    }),
  }),
)(Doctor);
