import React from 'react';
import styled from 'styled-components';

import { graphql } from 'react-apollo';
import { Route, withRouter, Redirect } from 'react-router-dom';

import withAlertModal from '../utils/alerthoc';
import { meQuery } from '../graphql/me';
import MyCounselList from './MyPage/MyCounselList';
import MyProfile from './MyPage/MyProfile';
import MyPageLeftMenu from '../components/MyPage/MyPageLeftMenu';
import { media } from '../globalStyle';

const MyPageContainer = styled.div`
  padding: 40px 20px;
  max-width: 1080px;
  width: 100%;
  margin: auto;
  ${media.tablet`
    padding: 80px 20px 40px;
  `};
`;

const Title = styled.div`
  margin-bottom: 40px;
  font-size: 34px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
  ${media.tablet`
    margin-bottom: 30px;
    font-size: 24px;
    letter-spacing: -1.7px;
  `};
`;

const Content = styled.div`
  //display: flex;
  width: 100%;
  justify-content: space-beteween;
  ${media.tablet`
    display: block;
  `};
`;

const RightPanel = styled.div`
  width: 100%;
  //margin: 0 0 0 73px;
  //max-width: 738px;
  margin: 0 auto;
    padding: 20px 0;
  ${media.tablet`
    margin: 0 auto;
    padding: 20px 0;
  `};
`;

class MyPage extends React.Component {
  render() {
    const { match } = this.props;
    const {
      data: { loading, me },
    } = this.props;

    if (loading) {
      return <div />;
    }

    if (!me) {
      return <Redirect to={{ pathname: '/' }} />;
    }

    return (
      <MyPageContainer>
        <Title>마이 페이지</Title>
        <Content>
          <MyPageLeftMenu {...this.props} />
          <RightPanel>
            <Route
              exact
              path={`${match.url}/counsel`}
              render={props => <MyCounselList {...props} />}
            />
            {/*<Route
              exact
              path={`${match.url}/reservations`}
              render={props => <MyReservations {...props} />}
            />*/}
            <Route
              exact
              path={`${match.url}/profile`}
              render={() => (
                <MyProfile {...this.props} refetch={this.props.data} me={me} />
              )}
            />
          </RightPanel>
        </Content>
      </MyPageContainer>
    );
  }
}

export default graphql(meQuery, { options: { fetchPolicy: 'network-only' } })(withRouter(withAlertModal(MyPage)));
