import React, { Component } from 'react';
import axios from 'axios';
import _ from 'lodash';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import formatFilename from '../utils/FileUpload';
import therapyInfo from './form/therapyInfo';
import { fixBody, unfixBody } from '../utils/style-utils';
import ReqForm from './ReqForm';
import {
  TxPhotoGuideModal,
  OrthoPhotoGuideModal,
} from './form/PhotoGuideModal';
import withAlertModal from '../utils/alerthoc';
import SubHeader from './SubHeader';
import { media } from '../globalStyle';
import { userActivitySendSlack } from '../utils/loging';

const RelativeDiv = styled.div`
  position: relative;
  margin-top: 90px;
  margin-bottom: 120px;
  ${media.miniDesktop`
    margin-top: 30px;
    margin-bottom: 100px;
    padding-right: 0;
  `};
`;

const SubmitBtnWapper = styled.div`
  text-align: center;
  ${media.tablet`
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 3;
  `};
`;

const SubmitBtn = styled.button`
  padding: 20px 102px;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -0.89px;
  text-align: center;
  color: #ffffff;
  margin: 90px auto 0;
  background-color: #033e52;
  &:disabled {
    background-color: #bbbcbc;
  }
  ${media.tablet`
    width: 100%;
    padding: 20px 0;
    margin: 0;
  `};
`;

class CounselTherapySelectForm extends Component {
  static propTypes = {
    directHospitalId: PropTypes.string,
    saveTheraphy: PropTypes.func.isRequired,
    goToStep: PropTypes.func.isRequired,
  };

  static defaultProps = {
    directHospitalId: '',
  };

  constructor(props) {
    super(props);

    if (Object.entries(props.initialData).length !== 0 || props.initialData.constructor !== Object) {
      this.state = { ...props.initialData };
      return;
    }

    this.state = {
      mode: 'quick', // 간편상담 or 자세한 상담
      isSelectTxTab: true,

      isOpenTxPhotoGuideModal: false, // 치료 사진 가이드 모달 오픈
      isOpenOrthoPhotoGuideModal: false, // 교정 사진 가이드 모달 오픈
      isOpenBottomCart: false, // 하단 상담 장바구니 오픈

      // 치료 정보
      txImageFiles: [], // 치과 치료 사진
      orthoImageFiles: [], // 교정 치료 사진
      pickedTxTherapy: {}, // 선택된 치과 치료 항목
      pickedOrthoType: '', // 선택된 교정 희망 치료
      orthoPart: [], // 교정 부위
      orthoWanna: [], // 교정 시 개선을 원하는 사항
      desc: '', // 추가 불편 사항
    };
  }

  componentDidMount() {
    const { selectedTherapyFromMain } = this.props;

    // selectedRegionFromMain
    selectedTherapyFromMain && this.setState(
      selectedTherapyFromMain === 'Ortho'
        ? {
          isSelectTxTab: false
        } : {
          pickedTxTherapy: {
            [selectedTherapyFromMain]:{
              unit: 1,
              name:`${selectedTherapyFromMain}`,
            }
          }
        });

    userActivitySendSlack('desktop', 'in-step1');
  }

  // 이미지 파일 로드
  onDrop = async (files, targetName, tag) => {
    const newFile = files[0];
    newFile.original = newFile.preview;
    newFile.thumbnail = newFile.preview;

    if (targetName === 'tx') {
      const newFileState = this.state.txImageFiles;
      newFile.thumbnailLabel = tag || '일반치료';
      newFileState.push(newFile);
      this.setState({ txImageFiles: newFileState });
    } else if (targetName === 'ortho') {
      const newFileState = this.state.orthoImageFiles;
      newFile.thumbnailLabel = tag || '교정치료';
      newFileState.push(newFile);
      this.setState({ orthoImageFiles: newFileState });
    }
  };

  // 옵션 갯수 변경
  onOptionUnitChange = (id, name, unit) => {
    const newPickedTxTherapy = this.state.pickedTxTherapy;

    if (unit === 0) {
      delete newPickedTxTherapy[id];
      this.setState({ pickedTxTherapy: newPickedTxTherapy });
      return;
    }

    if (newPickedTxTherapy[id]) {
      newPickedTxTherapy[id].unit = unit;
      this.setState({ pickedTxTherapy: newPickedTxTherapy });
      return;
    }

    newPickedTxTherapy[id] = {
      unit,
      name,
    };

    this.setState({ pickedTxTherapy: newPickedTxTherapy });
  };

  // 옵션 타입 변경
  onOptionTypeChange = (id, name, shortName, e) => {
    e.stopPropagation();
    const newPickedTxTherapy = { ...this.state.pickedTxTherapy };
    if (newPickedTxTherapy[id]) {
      newPickedTxTherapy[id].name = name;
      this.setState({ pickedTxTherapy: newPickedTxTherapy });
    }
  };

  // 치료 선택
  selectTherapy = (id, name) => {
    const newPickedTxTherapy = this.state.pickedTxTherapy;
    if (newPickedTxTherapy[id]) {
      delete newPickedTxTherapy[id];
    }

    newPickedTxTherapy[id] = {
      unit: 1,
      name,
    };

    this.setState({ pickedTxTherapy: newPickedTxTherapy });
  };

  // 치료 취소
  deselectTherapy = (id) => {
    const newPickedTxTherapy = this.state.pickedTxTherapy;
    if (newPickedTxTherapy[id]) {
      delete newPickedTxTherapy[id];
      this.setState({ pickedTxTherapy: newPickedTxTherapy });
    }
  };

  // 희망 교정 선택
  selectOrthoType = (id, name) => {
    let newPickedOrthoType = '';
    if (this.state.pickedOrthoType !== name) {
      newPickedOrthoType = name;
    }

    this.setState({ pickedOrthoType: newPickedOrthoType });
  };

  // 희망 교정 취소
  deselectOrthoType = () => {
    this.setState({ pickedOrthoType: '' });
  };

  // 교정 시 개선을 원하는 사항 변경
  handleOrthoWannaChange = (wanna) => {
    const newOrthoWanna = [...this.state.orthoWanna]; // 새로 Array 를 만들어줘야한다. deconstruct 방법.
    const idx = _.indexOf(newOrthoWanna, wanna);

    if (idx !== -1) {
      newOrthoWanna.splice(idx, 1);
    } else {
      newOrthoWanna.push(wanna);
    }

    this.setState({ orthoWanna: newOrthoWanna });
  };

  // 교정 부위 갱신
  toggleOrthoPart = (orthoParts) => {
    this.setState({ orthoPart: orthoParts });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    const newState = { ...this.state };
    _.set(newState, name, value);
    this.setState(newState);
  };

  // 이미지 업로드
  imageUpload = async (files) => {
    const imageUrls = [];

    try {
      await Promise.all(
        files.map(async (file) => {
          const res = await this.props.signS3({
            variables: {
              filename: formatFilename(file.name),
              filetype: file.type,
            },
          });

          const { signedRequest, url } = res.data.signS3;

          try {
            await this.uploadToS3(file, signedRequest);
            imageUrls.push(url);
            return;
          } catch (e) {
            console.log('Image Upload Failed!');
          }
        }),
      );

      return imageUrls;
    } catch (e) {
      console.log(e);
      return [];
    }
  };

  // S3에 이미지 POST
  uploadToS3 = async (file, signedRequest) => {
    const options = {
      headers: {
        'Content-Type': file.type,
      },
    };
    const resp = await axios.put(signedRequest, file, options);
    return resp;
  };

  // pickedTxTherapy를 mutateForm에 맞게 txItems로 변환
  convertToTxItem = async (pickedTxTherapy) => {
    const txItems = await Object.keys(pickedTxTherapy).map(id => ({
      itemcode: therapyInfo[pickedTxTherapy[id].name].item_id,
      unit: pickedTxTherapy[id].unit,
    }));

    return txItems;
  };

  // orthoPart, pickedOrthoType를 mutateForm에 맞게 orthoItems로 변환
  convertToOrthoItem = async (orthoPart, pickedOrthoType) => {
    const orthoItems = await orthoPart.map(part => ({
      itemcode: therapyInfo[pickedOrthoType].item_id,
      part,
    }));

    return orthoItems;
  };

  // 이미지 삭제 확인 팝업 오픈
  openTxImageFileDeleteModal = (idx) => {
    this.props.openAlert({
      title: '사진 삭제',
      message: '선택하신 사진을 정말로 삭제하시겠습니까?',
      showConfirmBtn: true,
      confirmBtnName: '확인',
      confirmBtnOnClick: this.deleteTxImageFile.bind(this, idx),
      showCancelBtn: true,
      cancleBtnName: '취소',
      cancleBtnOnClick: this.props.closeAlert,
      textAlign: 'left',
    });
  };

  // 이미지 삭제 확인 팝업 오픈
  openOrthoImageFileDeleteModal = (idx) => {
    this.props.openAlert({
      title: '사진 삭제',
      message: '선택하신 사진을 정말로 삭제하시겠습니까?',
      showConfirmBtn: true,
      confirmBtnName: '사진 삭제',
      confirmBtnOnClick: this.deleteOrthoImageFile.bind(this, idx),
      showCancelBtn: true,
      cancleBtnName: '취소',
      cancleBtnOnClick: this.props.closeAlert,
      textAlign: 'left',
    });
  };

  // tx 이미지 삭제
  deleteTxImageFile = (idx) => {
    const files = [...this.state.txImageFiles];
    files.splice(idx, 1);
    this.setState({ txImageFiles: files });
  };

  // ortho 이미지 삭제
  deleteOrthoImageFile = async (idx) => {
    const files = [...this.state.orthoImageFiles];
    files.splice(idx, 1);
    this.setState({ orthoImageFiles: files });
  };

  scrollDown = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };

  // 사진 가이드 모달 open
  openTxPhotoGuideModal = () => {
    this.setState({ isOpenTxPhotoGuideModal: true });
    fixBody();
  };

  // 사진 가이드 모달 open
  openOrthoPhotoGuideModal = () => {
    this.setState({ isOpenOrthoPhotoGuideModal: true });
    fixBody();
  };

  clearForm = () => {
    this.setState({
      isSelectTxTab: true,
      txImageFiles: [], // 치과 치료 사진
      orthoImageFiles: [], // 교정 치료 사진

      pickedTxTherapy: {}, // 선택된 치과 치료 항목
      pickedOrthoType: '', // 선택된 교정 희망 치료
      desc: '', // 추가 불편 사항
      orthoPart: [], // 교정 부위
      orthoWanna: [], // 교정 시 개선을 원하는 사항

      isOpenTxPhotoGuideModal: false, // 치료 사진 가이드 모달 오픈
      isOpenOrthoPhotoGuideModal: false, // 교정 사진 가이드 모달 오픈
      isOpenBottomCart: false, // 하단 상담 장바구니 오픈
    });
  };

  handleSubmit = async () => {
    const { orthoPart, pickedTxTherapy, pickedOrthoType } = this.state;

    if (orthoPart.length !== 0 && !pickedOrthoType) {
      this.props.openAlert({
        title: '희망 교정을 선택해주세요.',
        message: '교정 방법 중 1가지는 필수 선택 사항 입니다.',
        showConfirmBtn: true,
        confirmBtnName: '확인',
        textAlign: 'left',
        confirmBtnOnClick: this.props.closeAlert,
      });
      return;
    }

    // 교정 부위나 재료가 하나라도 있으면 둘다 필수체크
    if (pickedOrthoType && orthoPart.length === 0) {
      this.props.openAlert({
        title: '교정 부위를 선택해주세요.',
        message: '교정 부위 중 1가지는 필수 선택 사항 입니다.',
        showConfirmBtn: true,
        confirmBtnName: '확인',
        textAlign: 'left',
        confirmBtnOnClick: this.props.closeAlert,
      });
      return;
    }

    // 교정, 치료를 하나도 선택 안했을경우
    // 사진만 있고 아무것도 선택하지 않으면 에러
    if (
      _.isEmpty(pickedTxTherapy)
      && (!pickedOrthoType || !orthoPart || orthoPart.length === 0)
    ) {
      this.props.openAlert({
        title: '희망하는 치료를 선택해주세요.',
        message: '원하시는 치료를 선택해 상담을 진행해주세요.',
        showConfirmBtn: true,
        confirmBtnName: '확인',
        textAlign: 'left',
        confirmBtnOnClick: this.props.closeAlert,
      });
      return;
    }

    this.setState({ isOpenBottomCart: false });
    this.props.saveTheraphy({ ...this.state });
    this.props.goToStep(2);
  };

  toggleQuickCounsel = () => {
    if (this.state.mode === 'quick') {
      this.props.openAlert({
        title: '자세한상담하기',
        message: '자세한상담하기로 전환하시면 작성하던 내용이 사라집니다.',
        showConfirmBtn: true,
        confirmBtnName: '확인',
        confirmBtnOnClick: () => {
          this.setState({ mode: 'general' });
          this.clearForm();
        },
        showCancelBtn: true,
        cancleBtnName: '취소',
        cancleBtnOnClick: this.props.closeAlert,
        textAlign: 'left',
      });
      return;
    }

    this.props.openAlert({
      title: '간편상담하기',
      message: '간편상담하기로 전환하시면 작성하던 내용이 사라집니다.',
      showConfirmBtn: true,
      confirmBtnName: '확인',
      confirmBtnOnClick: () => {
        this.setState({ mode: 'quick' });
        this.clearForm();
      },
      showCancelBtn: true,
      cancleBtnName: '취소',
      cancleBtnOnClick: this.props.closeAlert,
      textAlign: 'left',
    });
  };

  render() {
    const data = this.state;
    const {
      mode,
      directHospitalId,
      isOpenTxPhotoGuideModal,
      isOpenOrthoPhotoGuideModal,
      pickedOrthoType,
      pickedTxTherapy,
      orthoImageFiles,
      txImageFiles,
      orthoPart,
      orthoWanna,
      desc,
      isSelectTxTab,
    } = this.state;

    return (
      <div>
        <SubHeader
          step={1}
          mode={mode}
          toggleQuickCounsel={this.toggleQuickCounsel.bind(this)}
        />
        <RelativeDiv>
          {/* 간편상담, 자세한상담 */}
          <ReqForm
            {...this.props}
            mode={mode}
            isSelectTxTab={isSelectTxTab}
            goToTxPanel={() => this.setState({ isSelectTxTab: true })}
            goToOrthoPanel={() => this.setState({ isSelectTxTab: false })}
            directHospitalId={directHospitalId}
            saveTheraphy={this.saveTheraphy}
            goToStep={this.goToStep}
            data={data}
            onDrop={this.onDrop}
            onOptionUnitChange={this.onOptionUnitChange}
            onOptionTypeChange={this.onOptionTypeChange}
            selectTherapy={this.selectTherapy}
            deselectTherapy={this.deselectTherapy}
            selectOrthoType={this.selectOrthoType}
            deselectOrthoType={this.deselectOrthoType}
            handleOrthoWannaChange={this.handleOrthoWannaChange}
            toggleOrthoPart={this.toggleOrthoPart}
            handleChange={this.handleChange}
            imageUpload={this.imageUpload}
            uploadToS3={this.uploadToS3}
            scrollDown={this.scrollDown}
            openTxPhotoGuideModal={this.openTxPhotoGuideModal}
            openOrthoPhotoGuideModal={this.openOrthoPhotoGuideModal}
            openTxImageFileDeleteModal={this.openTxImageFileDeleteModal}
            openOrthoImageFileDeleteModal={this.openOrthoImageFileDeleteModal}
            clearForm={this.clearForm}
            handleSubmit={this.handleSubmit}
          />
          <SubmitBtnWapper>
            <SubmitBtn
              disabled={!(
                !_.isEmpty(pickedTxTherapy)
                || txImageFiles.length > 0
                || pickedOrthoType
                || orthoPart.length > 0
                || orthoWanna.length > 0
                || orthoImageFiles.length > 0
                || desc
              )}
              onClick={this.handleSubmit}
            >
              다음단계 ({ Object.keys(pickedTxTherapy).length + Object.keys(orthoPart).length})
            </SubmitBtn>
          </SubmitBtnWapper>
        </RelativeDiv>

        {/* 사진등록 가이드 모달 */}
        <TxPhotoGuideModal
          isOpen={isOpenTxPhotoGuideModal}
          onClose={() => {
            this.setState({ isOpenTxPhotoGuideModal: false });
            unfixBody();
          }}
        />
        <OrthoPhotoGuideModal
          isOpen={isOpenOrthoPhotoGuideModal}
          onClose={() => {
            this.setState({ isOpenOrthoPhotoGuideModal: false });
            unfixBody();
          }}
        />
      </div>
    );
  }
}

export default withAlertModal(CounselTherapySelectForm);
