import React from 'react';
import styled, { css } from 'styled-components';

const TextCSS = css`
  color: #000;
  font-size: 14px;
  line-height: 1;
  letter-spacing: -1px;
  white-space: normal;
`;

const SplitterCss = css`
  content: '';
  display: inline-block;
  width: 1px;
  height: 10px;
  margin-right: 8px;
  margin-left: 8px;
  background-color: #e4e4e4;
`;

const LeftSplitLineCss = css`
  &::before{
    ${SplitterCss}
  }  
`;

const StyledWord = styled.span`
  ${props => props.leftSplitLine && LeftSplitLineCss};
  ${TextCSS}
`;

const LinkedStyledWord = styled(StyledWord)`
  ${props => props.leftSplitLine && LeftSplitLineCss};
  cursor: pointer;
  text-decoration: underline;
  color: #033e52;
`;

const StyledParagraph = styled.p`
  ${TextCSS}
`;

const StyledLinkedParagraph = styled(StyledParagraph)`
  text-decoration: underline;
  color: #033e52;
`;

const mask = (name) => {
  const dif = name.length > 3 ? 2 : 1;
  const leng = name.length - dif;
  const reg = new RegExp(`.{${leng}}$`);
  return name.replace(reg, new Array(leng).fill('*').join(''));
};

const StyledSpan = styled.span`
  ${props => props.fontNum && 'font-family: "Saira Semi Condensed"; font-stretch: semi-condensed'};
  font-weight: ${props => props.semiBold && '600'};
  font-size: ${props => props.fontSize || '14px'};
  letter-spacing: -.5px;
  line-height: normal;
`;

export const TextSpan = ({ className, children, ...other }) => (
  <StyledSpan className={className} {...other}>{children}</StyledSpan>
);

export const TextSemiBoldSpan = ({ className, children, ...other }) => (
  <StyledSpan className={className} {...other}>{children}</StyledSpan>
);


export const TextBoldSpan = ({ className, children, ...other }) => (
  <StyledSpan className={className} {...other}><b>{children}</b></StyledSpan>
);

export const NumSpan = ({ className, children, ...other }) => (
  <StyledSpan fontNum className={className} {...other}>{children}</StyledSpan>
);

export const NumSemiBoldSpan = ({ className, children, ...other }) => (
  <StyledSpan fontNum semiBold className={className} {...other}>{children}</StyledSpan>
);
export const NumBoldSpan = ({ className, children, ...other }) => (
  <StyledSpan fontNum className={className} {...other}><b>{children}</b></StyledSpan>
);

export const MaskedName = ({ children }) => (
  <span>{mask(children)}</span>
);

export const Word = ({ className, children, ...other }) => (
  <StyledWord className={className} {...other}>{children}</StyledWord>
);

export const LinkedWord = ({ className, children, ...other }) => (
  <LinkedStyledWord className={className} {...other}>{children}</LinkedStyledWord>
);

export const Paragraph = ({ className, children, ...other }) => (
  <StyledParagraph className={className} {...other}>{children}</StyledParagraph>
);

export const LinkedParagraph = ({ className, children, ...other }) => (
  <StyledLinkedParagraph className={className} {...other}>{children}</StyledLinkedParagraph>
);
