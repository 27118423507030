import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { media } from '../globalStyle';

const Container = styled.div`
  width: 100%;
  margin: auto;
  text-align: center;
  padding: 120px 0 190px;
  ${media.tablet`
    padding: 88px 20px 40px;
    height: 100vh;
    display: flex;
    text-align: left;
    flex-direction: column;
    justify-content: space-between;
  `};
`;

const Title = styled.div`
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: -0.5px;
  color: #000000;
  margin-bottom: 10px;
`;

const SubTitle = styled.div`  
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.5px;
  color: #b1b3b2;
  .cs-link{
    margin-top: 30px;
    text-decoration: underline;
  }
`;

const Buttons = styled.div`
  margin-top: 60px;
`;

const Button = styled.button`
  width: 164px;
  min-height: 52px;
  border: solid 1px #033e52;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.9px;
  text-align: center;
  color: #033e52;
  ${props => props.active && `
    background-color: #033e52;
    color: #ffffff;
  `};
  ${media.tablet`
    width: 100%;
  `};
  & ~ & {
    margin-left: 10px;
    ${media.tablet`
      margin: 10px 0 0 0;
    `};
  }
`;

class Errors extends React.Component {
  render() {
    const { history } = this.props;
    return (
      <Container>
        <div>
          <Title>
            {'앗!'}
            <br />
            {'문제가 발생하여 페이지를'}
            <br />
            {'불러오지 못했어요ㅠㅠ'}
          </Title>
          <SubTitle>
            {'반복적으로 문제가 발생할 경우 문의를 남겨주시면'}
            <br />
            {'재빨리 처리해드릴게요!'}
            <div
              className="cs-link"
              onClick={() => window.open('http://pf.kakao.com/_vxnMbC/chat')}
            >
              {'카톡으로 문의하기'}
            </div>
          </SubTitle>
        </div>
        <Buttons>
          <Button active onClick={() => history.push('/')}>
            {'메인페이지'}
          </Button>
          <Button onClick={() => history.goBack()}>
            {'이전 페이지'}
          </Button>
        </Buttons>
      </Container>
    );
  }
}

export default withRouter(Errors);
