import React, { useState } from 'react';
import { faqList } from '../data';
import { logEvent } from '../utils/loging';
import styled from 'styled-components';
import { media } from '../globalStyle';
import FaqOpenIcon from '../images/faq-open.png';
import FaqCloseIcon from '../images/faq-close.png';
import CounselProcessInfoModal from "../components/CounselProcessInfoModal";

const Faqs = styled.div`
  border-top: 1px solid #d4d4d4;
  ${media.tablet`
    margin-top: 30px;
  `};
`;

const ToggleFaqBtn = styled.div`
  display: inline-block;
  background-image: url(${FaqOpenIcon});
  background-size: 22px 22px;
  background-repeat: no-repeat;
  min-width: 22px;
  width: 22px;
  height: 22px;
  object-fit: contain;
  cursor: pointer;
  ${media.tablet`
    display: none;
  `};
`;

const FaqTitle = styled.div`
  .faq-title{
    font-size: 16px;
    font-weight: bold;  
  }
  
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.4px;
  color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  cursor: pointer;
  word-break: keep-all;
  ${media.tablet`
    font-size: 18px;
    letter-spacing: -0.3px;
  `};
`;

const FaqContent = styled.div`
  .faq-content{
    font-size: 14px;
    line-height: 1.43;
    .process-btn{
      padding:10px 0;
      margin: 0;
      color: #033e52;
      text-decoration: #033e52 underline;
      cursor: pointer;
    }
  }
  
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.4px;
  color: #000000;
  text-align: left;
  white-space: pre-wrap;
  word-break: keep-all;
  display: none;
  & > div {
    width: 734px;
    padding: 0 0 30px;
  }

  hr {
    margin: 0;
    border-color: #d4d4d4;
  }

  ${media.tablet`
    & > div {
      width: 100%;
      padding: 0 0 20px;
    }
  `};
`;

const Faq = styled.div`
  border-bottom: 1px solid #d4d4d4;
  hr {
    display: none;
  }
  ${props =>
  props.active &&
  `
    border: 0;
    hr {
      display: block;
    }
    ${ToggleFaqBtn} {
      background-image: url(${FaqCloseIcon});
      width: 20px;
      height: 20px;
      min-width: 20px;
      background-size: 20px 20px;
    }
    ${FaqContent} {
      display: block;
    }
    ${FaqTitle} {
      color: #033e52;
    }
  `};
`;

export default () => {
  const [active, setActive] = useState(null);
  const [isOpenInfoModal, setIsOpenInfoModal] = useState(false);

  return (
      <Faqs>
        {
          faqList.map((faq, idx) => (
            <Faq
              key={faq.title}
              active={active === idx}
            >
              <FaqTitle
                onClick={() => {
                  // this.toggleFaq(idx);
                  setActive(idx);
                  logEvent({
                    category: '메인',
                    action: '클릭',
                    label: 'FAQ',
                    value: idx,
                  });
                }}
              >
                <div className={'faq-title'}>{faq.title}</div>
                <ToggleFaqBtn />
              </FaqTitle>
              <FaqContent>
                <div className={'faq-content'}>
                  {faq.content}
                  {faq.component && <p className="process-btn" onClick={()=>{setIsOpenInfoModal(true)}}>{faq.component.children}</p>}
                </div>
                <hr />
              </FaqContent>
            </Faq>
          ))
        }
        <CounselProcessInfoModal isOpen={isOpenInfoModal} onClose={() => setIsOpenInfoModal(false)} />
      </Faqs>
  )
};
