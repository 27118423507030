import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import NoticeContainer from '../../containers/Manual/NoticeContainer';
import { announcementsListQuery } from '../../graphql';

class Notice extends Component {
  render() {
    const { announcementsList, loading } = this.props.announcementsList;

    if (loading) {
      return null;
    }

    return (
      <NoticeContainer {...this.props} announcementsList={announcementsList} />  
    );
  }
}

export default graphql(announcementsListQuery, {
  name: 'announcementsList',
  options: {
    variables: { forWho: 'user' },
  },
})(withRouter(Notice));
