import _ from 'lodash';

export const convertTxPricesResponseToObj = (txItems, txPrices) => {
  const Obj = {};

  if (txItems && txItems.length > 0) {
    txItems.forEach((txItem) => {
      const txPrice = _.find(txPrices, { itemcode: txItem.itemcode });

      if (txPrice) {
        Obj[txItem.itemcode] = txPrice.price;
      } else {
        Obj[txItem.itemcode] = 0;
      }
    });

    return Obj;
  }
  return {};
};

export const convertOrthoPricesResponseToObj = (orthoItems, orthoPrices) => {
  const Obj = {};

  if (orthoItems && orthoItems.length > 0) {
    orthoItems.forEach((orthoItem) => {
      const orthoPrice = _.find(orthoPrices, {
        itemcode: orthoItem.itemcode,
        part: orthoItem.part,
      });

      const ItemObj = Obj[orthoItem.itemcode] || {};

      if (orthoPrice) {
        ItemObj[orthoItem.part] = orthoPrice.price;
      } else {
        ItemObj[orthoItem.part] = 0;
      }

      Obj[orthoItem.itemcode] = ItemObj;
    });

    return Obj;
  }
  return {};
};

const getPriceTableByReq = () => {};
export default getPriceTableByReq;
