import React from 'react';
import styled from 'styled-components';
import SpeechBubble from '../atomic/atoms/Shape/SpeechBubble';

const BubbleContainer = styled.div`
  & ~ & {
    margin-top: 20px;
  }
  &.right {
    text-align: right;
  }
`;

const ExtendedSpeechBubble = styled(SpeechBubble)`
  padding: 30px 20px 20px;
  color: #000000;
  width: 330px;
  &.right {
    width: auto;
    padding: 20px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: -0.4px;
    text-align: right;
    color: #ffffff;
  }
`;

const RespSpeechBubble = ({ children, ...rest }) => {
  return (
    <BubbleContainer {...rest}>
      <ExtendedSpeechBubble {...rest}>
        {children}
      </ExtendedSpeechBubble>
    </BubbleContainer>
  );
}

export default RespSpeechBubble;
