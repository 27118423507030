import WaingRoomImg1 from '../images/home/05-waitingroom-01@2x.jpg';
import WaingRoomImg2 from '../images/home/05-waitingroom-02@2x.jpg';
import WaingRoomImg3 from '../images/home/05-waitingroom-03.png';

const Implant = {
  img: WaingRoomImg1,
  title: '임플란트 A to Z',
  desc: '임플란트는 무엇이고, 언제 필요한가요?',
  path: '/lounge/implant',
};

const Cavity = {
  img: WaingRoomImg2,
  title: '충치치료 A to Z',
  desc: '충치치료 증상에 따른 치료는?',
  path: '/lounge/caries',
};

const WisdomTeeth = {
  img: WaingRoomImg3,
  title: '사랑니발치 A to Z',
  desc: '사랑니, 언제 어떻게 뽑을까?',
  path: '/lounge/wisdom',
};

export const ContentByItemcode = {
  'IMP-001': Implant,
  CAV: Cavity,
  'GEN-002': WisdomTeeth,
};

export const ContentsList = [
  Implant,
  Cavity,
  WisdomTeeth,
];
