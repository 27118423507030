import React from 'react';
import styled from 'styled-components';
import { marginProps } from '../../basic/HtmlComponents';
import { SectionTitle } from '../atoms/Title/Title';

const StyledSection = styled.section`
  & ~ &  {
    margin-top: 30px;
  }
  ${marginProps};
`;

const Section = ({ children, className, title }) => (
  <StyledSection className={className}>
    {title && <SectionTitle>{title}</SectionTitle>}
    {children || 'This is Section'}
  </StyledSection>
);

export { Section };
