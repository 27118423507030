import React from 'react';
import styled from 'styled-components';
import { media } from '../../globalStyle';
import { Title } from '../atomic/atoms/Title/Title';
import ContactTypeSelector from './ContactTypeSelector';

const ExtendedTitle = styled(Title)`
  font-size: 34px;
  margin: 0 0 10px 0;
  ${media.tablet`
    font-size: 24px;
  `};
  >span{
    ${media.tablet`
      display: block
    `};
  }
`;

const Container = styled.div`
  margin-bottom: 90px;
`;

const SubMsg = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.4px;
  color: #b1b3b2;
`;

// 답변 리스트 제목 + (연락방법 or 답변없을때 메세지)
const RespListHeader = ({ request, resps }) => {
  const {
    status,
    directReq,
    user,
  } = request;
  const isDirect = !!directReq; // 다이렉트 상담인지
  const isActive = status === 'published'; // 상담 진행중 여부
  const isExistResp = resps?.length > 0; // 답변이 있는지

  // 답변 있음
  if (isExistResp) {
    return (
      <Container>
        {
          // 다이렉트 상담과 일반 상담에서 병원 보여주는 차이
          isDirect ? (
            <ExtendedTitle>
              {resps[0]?.hospital?.name} 병원에서
              <br />
              메시지를 보냈어요 :)
            </ExtendedTitle>
          ) : (
            <ExtendedTitle>
              {resps?.length} 개의 병원에서
              <br />
              메시지를 보냈어요 :)
            </ExtendedTitle>
          )
        }
        <ContactTypeSelector />
      </Container>
    );
  }

  // 답변 없음 + 진행중
  if (isActive) {
    return (
      <Container>
        <ExtendedTitle>
          병원에서
          {' '}
          {user?.username}님을 위한
          <br />
          답변을 작성하고 있습니다!
        </ExtendedTitle>
        <SubMsg>
          병원의 답변이 등록되면, 바로 문자로 알려드려요.
          <br />
          주말이나 야간 등 진료시간 외일 경우 답변 등록이
          <br />
          다소 늦어질 수 있어요.
        </SubMsg>
      </Container>
    );
  }

  // 답변 없음 + 종료됨
  return (
    <Container>
      <ExtendedTitle>
        병원의 답변없이 상담이
        <br />
        종료되었어요
      </ExtendedTitle>
      <SubMsg>
        새롭게 상담을 신청하셔서, 상담이 자동으로 종료되었어요.
        <br />
        상담은 한번에 하나만 받을 수 있으니
        <br />
        진행중인 상담의 병원 답변을 기다려주세요.
      </SubMsg>
    </Container>
  );
};

export default RespListHeader;
