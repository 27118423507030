import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import {
  Card,
  HospitalImageContainer,
  FavoriteBtn,
  FavoriteImage,
  HospitalName,
  HospitalAddress,
  TagContainer,
  Tag,
} from './HtmlComponents';
import { toggleFavoriteHospitalMutation } from '../../graphql';
import FavoriteOn from '../../images/ic-like-active-24.png';
import FavoriteOff from '../../images/ic-like-default-24.png';
import withAlertModal from '../../utils/alerthoc';
import HospitalImageCarousel from './HospitalImageCarousel';
import { media } from '../../globalStyle';

const MatchPercentBar = styled.div`
  ${props => (props.percent ? `width: ${props.percent}` : 'width: 0')};
  height: 4px;
  background-color: #033e52;
  margin-bottom: 10px;
`;

const MatchPercentText = styled.div`
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.92;
  letter-spacing: normal;
  color: #033e52;
  margin-bottom: 10px;
  ${media.tablet`
    float: right;
    margin-bottom: 0;
  `};
`;

const NullMatchContainer = styled.div`
  height: 12px;
  width: 100%;
`;

const RespPriceStateContainer = styled.div`
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: -0.6px;
  color: #000000;
  &:nth-of-type(2) {
    margin-left: 8px;
    padding-left: 8px;
    border-left: 1px solid #d3d3d3;
  }
`;

const RespPriceStateName = styled.span``;

const RespPriceState = styled.span`
  font-weight: bold;
  ${props => props.color && `color: ${props.color};`};
  display: inline-block;
  margin-left: 4px;
`;

class HospitalViewCard extends Component {
  toggleFavorite = async (hid, e) => {
    e.stopPropagation();

    // mutation
    try {
      await this.props.toggleFavorite({
        variables: {
          hospitalId: hid,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const {
      perPage,
      onClick,
      photos,
      name,
      address,
      tags,
      favorited,
      hid,
      hideToggleFavoriteBtn,
      theme,
    } = this.props;

    const carouselItem = [];

    if (photos && photos.length > 0) {
      photos.forEach((photo) => {
        carouselItem.push(photo.url);
      });
    }

    if (theme && theme === 'local') {
      return (
        <LocalHospitalCard
          theme={theme}
          perPage={perPage}
          onClick={onClick}
          carouselItem={carouselItem}
          name={name}
          address={address}
          favorited={favorited}
          hid={hid}
          hideToggleFavoriteBtn={hideToggleFavoriteBtn}
          toggleFavorite={this.toggleFavorite.bind(this, hid)}
        />
      );
    }

    if (theme && theme === 'resp') {
      return (
        <RespHospitalCard
          theme={theme}
          perPage={perPage}
          onClick={onClick}
          carouselItem={carouselItem}
          name={name}
          address={address}
          tags={tags}
          favorited={favorited}
          txPriceMatchScore={this.props.txPriceMatchScore}
          orthoPriceMatchScore={this.props.orthoPriceMatchScore}
          locationMatchScore={this.props.locationMatchScore}
          hid={hid}
          hideToggleFavoriteBtn={hideToggleFavoriteBtn}
          toggleFavorite={this.toggleFavorite.bind(this, hid)}
          userRequest={this.props.userRequest}
        />
      );
    }

    if (theme && theme === 'direct') {
      return (
        <DirectHospitalCard
          theme={theme}
          perPage={perPage}
          onClick={onClick}
          carouselItem={carouselItem}
          name={name}
          address={address}
          tags={tags}
          favorited={favorited}
          hid={hid}
          hideToggleFavoriteBtn={hideToggleFavoriteBtn}
          toggleFavorite={this.toggleFavorite.bind(this, hid)}
          userRequest={this.props.userRequest}
        />
      );
    }

    return (
      <CommonHospitalCard
        theme={theme}
        perPage={perPage}
        onClick={onClick}
        carouselItem={carouselItem}
        name={name}
        address={address}
        tags={tags}
        favorited={favorited}
        hid={hid}
        hideToggleFavoriteBtn={hideToggleFavoriteBtn}
        toggleFavorite={this.toggleFavorite.bind(this, hid)}
      />
    );
  }
}

// 희망 지역 병원 뷰
const LocalHospitalCard = ({
  theme,
  perPage,
  onClick,
  carouselItem,
  name,
  address,
  favorited,
  hideToggleFavoriteBtn,
  toggleFavorite,
}) => (
  <Card theme={theme} onClick={onClick} width={`${100 / perPage}%`}>
    <HospitalImageContainer>
      <HospitalImageCarousel theme={theme} photos={carouselItem} />
      {!hideToggleFavoriteBtn && (
        <FavoriteBtn onClick={toggleFavorite}>
          {favorited ? (
            <FavoriteImage src={FavoriteOn} alt="favorite" />
          ) : (
            <FavoriteImage src={FavoriteOff} alt="favorite" />
          )}
        </FavoriteBtn>
      )}
    </HospitalImageContainer>
    <div>
      <HospitalName>{name}</HospitalName>
      <HospitalAddress>{address}</HospitalAddress>
    </div>
  </Card>
);

// 일반 병원 뷰
const CommonHospitalCard = ({
  perPage,
  onClick,
  carouselItem,
  name,
  address,
  favorited,
  tags,
  hideToggleFavoriteBtn,
  toggleFavorite,
}) => (
  <Card onClick={onClick} width={`${100 / perPage}%`}>
    <HospitalImageContainer>
      <HospitalImageCarousel photos={carouselItem} />
      {!hideToggleFavoriteBtn && (
        <FavoriteBtn onClick={toggleFavorite}>
          {favorited ? (
            <FavoriteImage src={FavoriteOn} alt="favorite" />
          ) : (
            <FavoriteImage src={FavoriteOff} alt="favorite" />
          )}
        </FavoriteBtn>
      )}
    </HospitalImageContainer>
    <div>
      <HospitalName>{name}</HospitalName>
      <HospitalAddress>{address}</HospitalAddress>
    </div>
    {tags && tags.length > 0 && (
      <TagContainer>
        {tags.map(tag => (
          <Tag active key={`tag_${tag.tagName}`}>#{tag.tagName}</Tag>
        ))}
      </TagContainer>
    )}
  </Card>
);

const getPriceMatchScoreLabelText = (priceMatchScore = 0) => {
  if (priceMatchScore === 0) {
    return '과중';
  }

  if (priceMatchScore === 15 || priceMatchScore === 30) {
    return '초과';
  }

  if (priceMatchScore === 20 || priceMatchScore === 40) {
    return '적당';
  }

  if (priceMatchScore === 25 || priceMatchScore === 50) {
    return '여유';
  }

  return '';
};

const getPriceMatchScoreLabelColor = (priceMatchScore) => {
  if (priceMatchScore === 0) {
    return '#8f001b';
  }

  if (priceMatchScore === 15 || priceMatchScore === 30) {
    return '#f69a47';
  }

  if (priceMatchScore === 20 || priceMatchScore === 40) {
    return '#00431b';
  }

  if (priceMatchScore === 25 || priceMatchScore === 50) {
    return '#003558';
  }

  return '';
};

// 맞춤 상담 답변 병원 뷰
const RespHospitalCard = ({
  theme,
  perPage,
  onClick,
  carouselItem,
  name,
  address,
  favorited,
  tags,
  hideToggleFavoriteBtn,
  toggleFavorite,
  orthoPriceMatchScore,
  txPriceMatchScore,
  locationMatchScore,
  userRequest,
}) => (
  <Card theme={theme} onClick={onClick} width={`${100 / perPage}%`}>
    <HospitalImageContainer>
      <HospitalImageCarousel theme={theme} photos={carouselItem} />
      {!hideToggleFavoriteBtn && (
        <FavoriteBtn onClick={toggleFavorite}>
          {favorited ? (
            <FavoriteImage src={FavoriteOn} alt="favorite" />
          ) : (
            <FavoriteImage src={FavoriteOff} alt="favorite" />
          )}
        </FavoriteBtn>
      )}
    </HospitalImageContainer>
    {locationMatchScore !== null ? (
      <div>
        <MatchPercentBar
          percent={`${txPriceMatchScore +
            orthoPriceMatchScore +
            locationMatchScore}%`}
        />
        <MatchPercentText>
          {`${txPriceMatchScore + orthoPriceMatchScore + locationMatchScore}%`} 상담
          일치
        </MatchPercentText>
      </div>
    ) : (
      <NullMatchContainer />
    )}
    <div>
      <HospitalName>{name}</HospitalName>
      <HospitalAddress>{address}</HospitalAddress>
    </div>
    <div>
      {txPriceMatchScore !== null && (
        <RespPriceStateContainer>
          <RespPriceStateName>치과 치료비</RespPriceStateName>
          <RespPriceState color={getPriceMatchScoreLabelColor(txPriceMatchScore)}>
            {getPriceMatchScoreLabelText(txPriceMatchScore)}
          </RespPriceState>
        </RespPriceStateContainer>
      )}
      {orthoPriceMatchScore !== null && (
        <RespPriceStateContainer>
          <RespPriceStateName>교정 치료비</RespPriceStateName>
          <RespPriceState
            color={getPriceMatchScoreLabelColor(orthoPriceMatchScore)}
          >
            {getPriceMatchScoreLabelText(orthoPriceMatchScore)}
          </RespPriceState>
        </RespPriceStateContainer>
      )}
    </div>
    {tags && tags.length > 0 && (
      <TagContainer>
        {tags.map(tag => (
          <Tag
            active={
              userRequest &&
              userRequest.considerTags &&
              userRequest.considerTags.length > 0 &&
              userRequest.considerTags.indexOf(tag.tagName) > -1
            }
            key={`tag_${tag.tagName}`}
          >
            #{tag.tagName}
          </Tag>
        ))}
      </TagContainer>
    )}
  </Card>
);

// 다이렉트 상담 답변 병원 뷰
const DirectHospitalCard = ({
  theme,
  perPage,
  onClick,
  carouselItem,
  name,
  address,
  tags,
  favorited,
  hideToggleFavoriteBtn,
  toggleFavorite,
}) => (
  <Card theme={theme} onClick={onClick} width={`${100 / perPage}%`}>
    <HospitalImageContainer>
      <HospitalImageCarousel theme={theme} photos={carouselItem} />
      {!hideToggleFavoriteBtn && (
        <FavoriteBtn onClick={toggleFavorite}>
          {favorited ? (
            <FavoriteImage src={FavoriteOn} alt="favorite" />
          ) : (
            <FavoriteImage src={FavoriteOff} alt="favorite" />
          )}
        </FavoriteBtn>
      )}
    </HospitalImageContainer>
    <div>
      <HospitalName>{name}</HospitalName>
      <HospitalAddress>{address}</HospitalAddress>
    </div>
    {tags && tags.length > 0 && (
      <TagContainer>
        {tags.map(tag => (
          <Tag
            active
            key={`tag_${tag.tagName}`}
          >
            #{tag.tagName}
          </Tag>
        ))}
      </TagContainer>
    )}
  </Card>
);

export default graphql(toggleFavoriteHospitalMutation, {
  name: 'toggleFavorite',
  options: ({ refetchQueries }) => ({
    refetchQueries,
  }),
})(withAlertModal(withRouter(HospitalViewCard)));
