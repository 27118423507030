import React from 'react';
import styled from 'styled-components';
import HospitalDetail from '../containers/HospitalDetail';
import ScrollToTopOnMount from '../components/basic/ScrollToTopOnMount';
import { FormContainer } from '../components/basic/HtmlComponents';
import { media } from '../globalStyle';

const HospitalDetailContainer = styled.div`
  padding: 40px 0;
  ${media.tablet`
    padding: 58px 0 90px;
  `};
`;

class HsDetail extends React.Component {
  render() {
    const { match } = this.props;
    const hospitalId = match.params.id;

    return (
      <div>
        <ScrollToTopOnMount />
        <FormContainer>
          <HospitalDetailContainer>
            <HospitalDetail hid={hospitalId} />
          </HospitalDetailContainer>
        </FormContainer>
      </div>
    );
  }
}

export default HsDetail;
