import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { media } from '../globalStyle';
import { Acc } from '../components/ImporvedCounsel/EnhancedContainer';
import * as sheets from '../data/sheets';
import { AuthContext } from '../components/AuthProvider';
import { blockEscape, unBlockEscape } from '../components/ImporvedCounsel/unBlock';

const StyledMainBody = styled.div`
  .row{
    padding: 120px 20px;
    margin: 0;
    &.mt-60{
      padding-top: 60px;
      ${media.tablet`
        padding-top: 0;
      `}; 
    }
    &.bg-f6{
      background-color: #f6f6f6;
      /*.flex{
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 20px;
        ${media.tablet`
          margin-left: 0;
          margin-right: 0;
          padding-left: 0;
        `}; 
      }*/
    }
  }
  
  .m-hide{
    ${media.tablet`
      display: none;
    `};
  }
  
  ${media.tablet`
    margin-top: 0 !important;
   `}
`;

class Counsel extends React.Component {
  constructor(props) {
    super(props);

    const { history } = this.props;
    const savedStep = localStorage.getItem('pStep');
    blockEscape();
    this.unblock = history.block((location) => {
      if (location.pathname === '/counsel') {
        return null;
      }
      return '이 페이지에서 나가면 상담이 저장되지 않습니다.';
    });
    // const steps = JSON.parse(savedStep).map(s => (sheets[s]));

    this.state = {
      steps: JSON.parse(savedStep).map(s => (sheets[s])),
      simpleSteps: JSON.parse(savedStep).map(s => (sheets[s])).length,
    };

    // 홈에서 계정관련 모달 띄우는 로직.
    // const params = queryString.parse(this.props.location.search);
    // const action = params.q;

    // if (action === 'login') {
    //   this.props.history.push('/login');
    // }
    //
    // if (action === 'resetpassword') {
    //   this.props.history.push('/resetpassword');
    // }
    //
    // if (action === 'signup') {
    //   this.props.history.push('/signup');
    // }
    // window.history.pushState({}, document.title, '/');
    this.mapper = this.mapper.bind(this);
  }

  // unblock() {
  //   this.props.history.block((location, action) => {
  //     if (location.pathname === '/counsel') {
  //       return null;
  //     }
  //     return '이 페이지에서 나가면 상담이 저장되지 않습니다.';
  //   })
  // }

  componentWillUnmount() {
    unBlockEscape();
    this.unblock();
  }

  mapper(array) {
    return array.map(s => (sheets[s]));
  }

  render() {
    const { steps, simpleSteps } = this.state;
    const { isLoggedIn } = this.context;
    const detailStep = ['gender', 'ageGroup', 'txImageUrls', 'desc'];
    const userInfoStep = ['name', 'phone'];
    const newBieStep = ['password'];
    const memberStep = ['memberPassword'];
    const processStep = ['process'];
    const allStep = [...detailStep, ...userInfoStep, ...newBieStep, ...memberStep, ...processStep];


    return (
      <StyledMainBody id="main-body">
        {/* {Counsel Main} */}

        <div className="row">
          <Acc
            steps={steps}
            simpleSteps={simpleSteps}
            updateStep={(o) => { // updateStep({isDetail : true || false})
              // value : detail || simple
              const [key, value] = [...Object.entries(o)[0]];

              if (key === 'isExistUser') {
                // 이전 step에서 덧 붙인다
                this.setState({
                  steps: [
                    ...steps.filter(i => ![...newBieStep, ...memberStep, ...processStep].includes(i.name)),
                    ...this.mapper([
                      ...(value ? memberStep : newBieStep),
                      ...processStep,
                    ]),
                  ],
                }, () => {
                  // fn
                });
              }

              if (key === 'isDetail') {
                this.setState({
                  steps: [
                    ...steps.filter(i => !allStep.includes(i.name)),
                    ...this.mapper([
                      ...(value ? detailStep : []),
                      ...(isLoggedIn ? processStep : userInfoStep),
                    ]),
                  ],
                });
              }
            }}
            dataHandler={(o) => {
              this.setState(o);
            }}
          />
        </div>
      </StyledMainBody>
    );
  }
}

Counsel.contextType = AuthContext;
export default withRouter(Counsel);
