import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { media } from '../../globalStyle';

const LeftMenu = styled.div`
  width: 228px;
  ${media.tablet`
    width: 100%;
    padding: 0 20px;
    border-bottom: solid 1px #e0e0e0;
  `};
`;

const BoldMenu = styled.div`
  cursor: pointer;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.9px;
  color: #000000;
  & ~ & {
    margin-top: 30px;
  }
  &.active {
    font-weight: bold;
    color: #033e52;
  }
  ${media.tablet`
    width: 25%;
    font-size: 12px;
    line-height: 1.71;
    letter-spacing: -0.9px;
    text-align: center;
    display: inline-block;
    margin: 0;
    border: none;
    padding: 9px 0;
    & ~ & {
      margin-top: 0;
    }
    &.active {
      font-weight: bold;
      color: #000000;
    }
  `};
`;

class NoticeLeftMenu extends Component {
  render() {
    const { location } = this.props;
    return (
      <LeftMenu>
        <BoldMenu
          className={
            `${location.pathname}` === '/manual/notice' ? 'active' : ''
          }
          onClick={() => this.props.history.push('/manual/notice')}
        >
          공지사항
        </BoldMenu>
        <BoldMenu
          className={`${location.pathname}` === '/manual/faq' ? 'active' : ''}
          onClick={() => this.props.history.push('/manual/faq')}
        >
          FAQ
        </BoldMenu>
        <BoldMenu
          className={
            `${location.pathname}` === '/manual/terms' ? 'active' : ''
          }
          onClick={() => this.props.history.push('/manual/terms')}
        >
          이용 약관
        </BoldMenu>
        <BoldMenu
          className={
            `${location.pathname}` === '/manual/privacypolicy' ? 'active' : ''
          }
          onClick={() => this.props.history.push('/manual/privacypolicy')}
        >
          개인정보 처리방침
        </BoldMenu>
        {/* <BoldMenu onClick={this.terminateUser}>회원 탈퇴</BoldMenu>
              <SoftLine /> */}
      </LeftMenu>
    );
  }
}

export default withRouter(NoticeLeftMenu);
