import React, { useState } from 'react';
import * as moment from 'moment';
import styled from 'styled-components';
import HospitalMapInfoComponent from './HospitalMapInfoComponent';
import { media } from '../../globalStyle';
import HospitalImageGallery from '../HospitalImageGallery';
import FourDigitTime from '../basic/FourDigitTime';
import DoctorIcon from '../../images/doctor-default.png';
import IconGT from '../../images/ic-arrow-gray-20.png';
import VideoPlayBtnIcon from '../../images/btn-play.png';
import PhotoViewModal from '../PhotoViewModal';
import Slide from '../../components/atomic/organisms/slide'
import {marginProps, paddingProps, hideScrollBar, scrollTouch} from '../basic/HtmlComponents';
import {logEvent} from '../../utils/loging';
import { Section } from '../atomic/molecules/Section';

const StyledSection = styled(Section)`
  & ~ & {
    margin: 0;
  }
  h3{
    margin-bottom: 20px;
  }
`;

const BlankImg = styled.div`
  width: 100%;
  height: 273px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(228, 228, 228, 0.2);
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 30px;
  letter-spacing: -0.6px;
  color: #bcbcbc;
  ${media.miniDesktop`
    height: 180px;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: -1px;
  `};
`;

const Sectiond = styled.div`
  border-top: 1px solid #e4e4e4;
  padding-top: 30px;
  & ~ &  {
    margin-top: 30px;
  }
  ${marginProps};
`;

const PhotoSection = styled(Section)`
  border: 0;
  padding-top: 30px;
  & ~ &  {
    margin-top: 30px;
    border-top: 1px solid #e4e4e4;
  }
  ${media.miniDesktop`
    display: inline-block;
    width: 50%;
    padding: 0 10px;
    & ~ &  {
      border: 0;
    }
  `};
  ${media.tablet`
    margin-top: 30px;
    width: 100%;
    display: block;
    padding: 30px 0 0 0;
    border-top: 1px solid #e4e4e4;
    & ~ &  {
      border-top: 1px solid #e4e4e4;
    }
  `};
`;


const ModalViewBtn = styled.span`
  vertical-align: middle;
  float: right;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: -1px;
  text-align: right;
  color: #000000;
  cursor: pointer;
  text-decoration: underline;
`;

const Title = styled.div`
  font-family: 'Saira Semi Condensed', sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: -1px;
  color: #000000;
  margin-bottom: 21px;
  span {
    display: none;
    float: right;
    padding-top: 4px;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: -1px;
    text-align: right;
    color: #000000;
  }
  ${ModalViewBtn} {
    display: inline;
  }
  ${paddingProps};
  ${media.miniDesktop`
    span {
      display: inline;
    }
  `};
`;

const SubSection = styled.div`
  & ~ &  {
    margin-top: 36px;
  }
  &.hideSm {
    ${media.miniDesktop`
      display: none;
    `};
  }
  &.hide {
    display: none;
  }
`;

const SubSectionTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.88;
  letter-spacing: -0.1px;
  color: #000000;
  margin-bottom: 4px;
`;

const HospitalStaticInfoContent = styled.div`
  width: 100%;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.88;
  letter-spacing: -0.1px;
  color: #000000;
  div {
    & ~ & {
      margin-top: 4px;
    }
    line-height: 30px;
  }
  ${media.miniDesktop`
    line-height: 1.5;
    div {
      & ~ & {
        margin-top: 0;
      }
      line-height: 24px;
    }
  `};
`;

const HospitalPathMapLinkBtn = styled.button`
  display: none;
  ${media.miniDesktop`
    display: block;
    width: 100%;
    min-height: 56px;
    padding: 14px 0;
    border: solid 1px #e4e4e4;
    background-color: #ffffff;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.9px;
    text-align: center;
    color: #000000;
    margin-top: 20px;
  `};
`;

const HospitalOpenTimes = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.88;
  letter-spacing: -0.1px;
  color: #000000;
  ${media.miniDesktop`
    letter-spacing: normal;
  `};
`;

const Times = styled.div``;

const TimeLeft = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-right: 87px;
  ${media.miniDesktop`
    display: block;
    margin: 0;
  `};
`;

const TimeRight = styled.div`
  display: inline-block;
  vertical-align: top;
  ${media.miniDesktop`
    display: block;
    margin-top: 10px;
  `};
`;

const TimeTitle = styled.span`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.88;
  letter-spacing: -0.1px;
  color: #000000;
  margin-right: 16px;
`;

const Phonenum = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.88;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 3px;
`;

const PhonenumInfoMsg = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -0.8px;
  color: #000000;
`;

const TagContainer = styled.div``;

const Tag = styled.span`
  display: inline-block;
  margin-right: 14px;
  margin-bottom: 2px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.88;
  letter-spacing: -0.8px;
  color: #000000;
  ${media.miniDesktop`
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: -1px;
    margin: 0 10px 0 0;
  `};
`;

const InfoTagContainer = styled.div`
  margin-top: 10px;
  ${media.miniDesktop`
    margin-top: 6px;
    margin-right: -20px;
  `};
`;

const InfoTag = styled.div`
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.7px;
  text-align: center;
  color: #033e52;
  margin-top: 10px;
  margin-right: 30px;
  img {
    width: 64px;
    height: 64px;
    object-fit: contain;
    margin-bottom: 4px;
  }
  ${media.miniDesktop`
    margin-top: 14px;
  `};
`;

const NoInformation = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 30px;
  letter-spacing: -0.6px;
  color: #bcbcbc;
  ${media.miniDesktop`
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: -1px;
  `};
`;

const DoctorIContainer = styled.div`
  width: 100%;
  margin-right: -21px;
  ${media.miniDesktop`
    margin-right: -11px;
  `};
`;

const Doctor = styled.div`
  display: flex;
  align-items: center;
  margin-right: 21px;
  margin-bottom: 21px;
  cursor: pointer;
  img {
    width: 96px;
    height: 96px;
    object-fit: cover;
    border-radius: 50px;
  }
  ${media.miniDesktop`
    margin-right: 11px;
    img {
      width: 72px;
      height: 72px;
    }
  `};
  .doctor-info{
    margin-left: 10px;
    font-size: 12px;
    color: #000;
    letter-spacing: -.5px;
    div:first-child{
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 5px;
    }
  }
`;

const VideoTitle = styled(Title)`
  ${media.miniDesktop`
    padding: 0 20px;
  `};
`;

const Video = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
  margin-top: 20px;
  width: 213px;
  ${media.miniDesktop`
    min-width: calc(100% - 30px);
    margin-right: 0;
    & ~ & {
      margin-left: 10px;
    }
  `};
`;

const VideoContainer = styled.div`
  margin-right: -20px;
  margin-top: -20px;
  ${hideScrollBar};
  ${scrollTouch};
  ${media.miniDesktop`
    margin-right: 0;
    padding-left: 20px;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    ${props => (props.single && `
      ${Video} {
        width: 100%;
        padding-right: 20px;
      }
    `)};
  `};
`;

const VideoWapper = styled.div`
  ${media.miniDesktop`
    position: relative;
    width: 100%;
    padding-bottom: 56.5%;  
  `};
`;

const VideoPreview = styled.div`
  width: 100%;
  height: 120px;
  background-color: rgba(0, 0, 0, 0.3);
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  ${props => (props.thumbnail && `
    background-image: url(${props.thumbnail});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  `)};
  ${media.miniDesktop`
    position: absolute;
    width: 100%;
    height: 100%;
  `};
  ::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.3);
    background-image: url(${VideoPlayBtnIcon});
    background-size: 46px 46px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
`;

const VideoName = styled.div`
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -0.9px;
  color: #000000;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const VideoSpeaker = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: -0.7px;
  color: #000000;
`;

const HospitalImageGalleryContainer = styled.div`
  
`;

const Compliment = styled.div`
  & ~ & {
    margin-top: 30px;
  }
`;

const ComplimentHeader = styled.div`
  img {
    width: 58px;
    height: 58px;
    object-fit: cover;
    border-radius: 50px;
    float: left;
    margin-right: 10px;
  }
  & > div {
    display: inline-block;
  }
`;

const ComlimentFrom = styled.div`
  padding-top: 5px;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -0.9px;
  color: #000000;
`;

const ComlimentFromHospital = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: -0.8px;
  color: #000000;
`;

const ComplimentContent = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -1px;
  color: #000000;
  margin-top: 20px;
  word-break: break-word;
  white-space: normal;
`;

const StyledDoctorWrap = styled.div`

`;

export const HospitalDoctors = ({ doctors, title }) => {
  return (
    <StyledSection
      title="의료진"
    >
      <StyledDoctorWrap>
        {
          <DoctorIContainer>
            {doctors.map(doc => (
              <Doctor
                key={`doctor-${doc.id}`}
                onClick={() => {
                  window.open(`/doctors/${doc.id}`);
                  logEvent({
                    category: '답변(병원) 상세',
                    action: '클릭',
                    label: '의사 목록',
                  });
                }}
              >
                <div>
                  <img
                    src={doc.photos[0]?.url || DoctorIcon}
                    onError={(e) => {
                      // e.target.src = DoctorIcon;
                    }}
                    alt="doctor"
                  />
                </div>
                <div className='doctor-info'>
                  <div>{doc.name}</div>
                  <div>{doc.speciality}</div>
                </div>
                <div style={{marginLeft: 'auto'}}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                    <path fill="#B1B3B2" fillRule="evenodd" d="M8 15.071l-1.414-1.414L12.242 8 6.586 2.343 8 .93 15.071 8l-1.414 1.414L8 15.071z"/>
                  </svg>
                </div>
              </Doctor>
            ))}
          </DoctorIContainer>
        }
      </StyledDoctorWrap>

    </StyledSection>
  );
};

export const HospitalVideos = ({ hospital: { doctors, metadata }, hospital, allVideos, title}) => {
  // let allVideos = [];

  // if (metadata && metadata.videos && metadata.videos.length > 0) {
  //   allVideos = allVideos.concat(metadata.videos);
  // }

  // doctors.forEach(doctor => {
  //   doctor.videos.map((v) => {
  //     allVideos.push({
  //       title: v.title,
  //       url: v.url,
  //       img: v.thumbnail,
  //       desc: doctor.name,
  //     });
  //   });
  // });

  if (allVideos && allVideos.length > 0) {
    // 여러명의 의사의 비디오를 나열.
    return (
      <StyledSection
        title={title(allVideos.length)}
      >
        <Slide
          data={allVideos}
          // onClick={() => {
          //   window.open(video.url);
          //   logEvent({
          //     category: '답변(병원) 상세',
          //     action: '클릭',
          //     label: '동영상 목록',
          //     value: video.id,
          //   });
          // }}
        />
      </StyledSection>
    );
  }

  return null;
};

const StyledFeatureWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  flex: auto;
  margin: 0 calc((100% - 288px) / -20);
  ${media.miniDesktop`
    margin: 0 calc((100% - 288px) / -8);
  `};  
  
  .icon{
    text-align: center;
    margin: 0 calc((100% - 288px) / 20);
    ${media.miniDesktop`
      margin: 0 calc((100% - 288px) / 8);
    `};  
  }
  .text{
    width: 72px;
    color: #033e52;
    font-size: 14px;
    letter-spacing: -.5px;
    text-align: center;
    margin: 10px 0 20px
  }
  margin-bottom: -20px;
`;

export const HospitalFeature = ({infos}) => {
  return (
    <StyledSection
      title="병원특징"
    >
      <StyledFeatureWrap>
        {
          infos.map((info) =>
            (
              <div className={'icon'} key={`info_tag_${info.id}`}>
                <img
                  width={'64'}
                  height={'64'}
                  src={`/img/${info.icon}`}
                  alt="info-tag"
                />
                <div className={'text'}>{info.infoText}</div>
              </div>
            )
          )
        }
      </StyledFeatureWrap>

    </StyledSection>
  )
};


const StyledMapWrap = styled.div`
  .text-address{
    font-size: 14px;
    letter-spacing: -.5px;
    margin: 20px 0 30px;
  }
  .button-find-way{
    width: 60%;
    ${media.miniDesktop`
      width: 100%;
    `};  
    height: 56px;
    border-radius: 4px;
    border: solid 1px #e1e3e2;
    font-size: 13px;
    letter-spacing: -.5px;
  }
`;

export const HospitalMap = ({title, hospital}) => {
  return (
    <StyledSection
      title={title}
    >
      <StyledMapWrap>
        <div>
          <HospitalMapInfoComponent
            hospital={hospital}
          />
          <div
            className={'text-address'}
          >
            {hospital.address}
          </div>
          <div style={{'textAlign':'center'}}>
            <button
              className='button-find-way'
              onClick={() => {
                window.open(`http://map.daum.net/link/to/${hospital.name},${hospital.lat},${hospital.lng}`);
                logEvent({
                  category: '답변(병원) 상세',
                  action: '클릭',
                  label: '길찾기',
                });
              }}
            >
              {'길찾기'}
            </button>
          </div>
        </div>
      </StyledMapWrap>
    </StyledSection>
  )
};

const StyledOpenTimeWrap = styled.div`
  font-size: 14px;
  letter-spacing: -.5px;
  .h-row{
    margin-bottom: 12px;
  }
  .day{
    margin-right: 10px;
  }
  .time{
    font-family: 'Saira Semi Condensed', sans-serif;
  }
  
`;

export const HospitalOpenTime = ({title, openHour}) => {
  const week = ['월요일', '화요일', '수요일', '목요일', '금요일', '토요일', '일요일'];
  return (
    <StyledSection
      title={title}
    >
      <StyledOpenTimeWrap>
        <TimeLeft>
          {
            week.map((w, idx) => {
              return (
                <div key={idx} className='h-row'>
                  <span className='day'>
                    {w}
                  </span>
                  <FourDigitTime
                    className='time'
                    startTime={openHour[`dutyTime${idx+1}s`]}
                    closeTime={openHour[`dutyTime${idx+1}c`]}
                  />
                </div>
              )
            })
          }
        </TimeLeft>
        {/*
        {hospital.metadata.openhour.lunchTimes && hospital.metadata.openhour.lunchTimec && (
          <TimeRight>
            <TimeTitle>점심시간</TimeTitle>
            <FourDigitTime startTime={hospital.metadata.openhour.lunchTimes} closeTime={hospital.metadata.openhour.lunchTimec} />
          </TimeRight>
        )}*/}
      </StyledOpenTimeWrap>
    </StyledSection>
  )
};

export const HospitalBasicInfo = ({ hospital, exposePhonenum }) =>{
  return (

    <Sectiond>
      <div>
        <SubSection className={!exposePhonenum ? 'hideSm hide' : 'hideSm'}>
          <SubSectionTitle>전화번호</SubSectionTitle>
          <Phonenum>{hospital.phone}</Phonenum>
          <PhonenumInfoMsg>
            오닥터를 통해 상담 후 연락한다고 말씀하시면
            <br />
            상담내용을 통해 간편하게 상담 받으실 수 있습니다.
          </PhonenumInfoMsg>
        </SubSection>
        <SubSection>
          <SubSectionTitle>위치</SubSectionTitle>
          <HospitalStaticInfoContent>
            {hospital.address ? (
              <React.Fragment>

                {hospital.lat && hospital.lng && (
                  <div>
                    <HospitalMapInfoComponent hospital={hospital} />
                    <div>{hospital.address}</div>
                    <HospitalPathMapLinkBtn
                      onClick={() => {
                        window.open(`http://map.daum.net/link/to/${hospital.name},${hospital.lat},${hospital.lng}`);
                        logEvent({
                          category: '답변(병원) 상세',
                          action: '클릭',
                          label: '길찾기',
                        });
                      }}
                    >
                      길찾기
                    </HospitalPathMapLinkBtn>
                  </div>
                )}
              </React.Fragment>
            ) : (
              <NoInformation>앗! 아직 정보가 없어요</NoInformation>
            )}
          </HospitalStaticInfoContent>
        </SubSection>
        <SubSection>
          <SubSectionTitle>진료 시간</SubSectionTitle>
          <HospitalOpenTimes>
            {hospital.metadata &&
            hospital.metadata.openhour ? (
              <React.Fragment>
                <Times>
                  <TimeLeft>
                    <div>
                      <TimeTitle>월요일</TimeTitle>
                      <FourDigitTime startTime={hospital.metadata.openhour.dutyTime1s} closeTime={hospital.metadata.openhour.dutyTime1c} />
                    </div>
                    <div>
                      <TimeTitle>화요일</TimeTitle>
                      <FourDigitTime startTime={hospital.metadata.openhour.dutyTime2s} closeTime={hospital.metadata.openhour.dutyTime2c} />
                    </div>
                    <div>
                      <TimeTitle>수요일</TimeTitle>
                      <FourDigitTime startTime={hospital.metadata.openhour.dutyTime3s} closeTime={hospital.metadata.openhour.dutyTime3c} />
                    </div>
                    <div>
                      <TimeTitle>목요일</TimeTitle>
                      <FourDigitTime startTime={hospital.metadata.openhour.dutyTime4s} closeTime={hospital.metadata.openhour.dutyTime4c} />
                    </div>
                    <div>
                      <TimeTitle>금요일</TimeTitle>
                      <FourDigitTime startTime={hospital.metadata.openhour.dutyTime5s} closeTime={hospital.metadata.openhour.dutyTime5c} />
                    </div>
                    <div>
                      <TimeTitle>토요일</TimeTitle>
                      <FourDigitTime startTime={hospital.metadata.openhour.dutyTime6s} closeTime={hospital.metadata.openhour.dutyTime6c} />
                    </div>
                    <div>
                      <TimeTitle>일요일</TimeTitle>
                      <FourDigitTime startTime={hospital.metadata.openhour.dutyTime7s} closeTime={hospital.metadata.openhour.dutyTime7c} />
                    </div>
                  </TimeLeft>
                  {hospital.metadata.openhour.lunchTimes && hospital.metadata.openhour.lunchTimec && (
                    <TimeRight>
                      <TimeTitle>점심시간</TimeTitle>
                      <FourDigitTime startTime={hospital.metadata.openhour.lunchTimes} closeTime={hospital.metadata.openhour.lunchTimec} />
                    </TimeRight>
                  )}
                </Times>
              </React.Fragment>
            ) : (
              <NoInformation>앗! 아직 정보가 없어요</NoInformation>
            )}
          </HospitalOpenTimes>
        </SubSection>
      </div>
    </Sectiond>
  )};



const StyledMainPhotoWrap = styled.div`
  margin-left: -20px;
  margin-right: -20px;
`;

export const HospitalMainPhotos = ({ photos }) => {
  const [isOpenPhotoView, setIsOpenPhotoView] = useState(false);

  return (
    <StyledSection>
      <StyledMainPhotoWrap>
        {photos && photos.length > 0 ? (
          <HospitalImageGallery items={photos} />
        ) : (
          <BlankImg>앗! 아직 정보가 없어요</BlankImg>
        )}
      </StyledMainPhotoWrap>
      <PhotoViewModal
        items={photos}
        isOpen={isOpenPhotoView}
        close={() =>
          setIsOpenPhotoView(false)
        }
      />
    </StyledSection>
  );
};

export const HospitalLifeAndEpisodePhotos = ({ hospital }) => {
  const [isOpenPhotoView, setIsOpenPhotoView] = useState(false);

  const photos = [];

  hospital.photos.forEach((photo) => {
    if (photo.category === 'life') {
      photos.push({
        original: photo.url,
        title: photo.title,
        desc: photo.description,
      });
    }
  });

  return (
    <PhotoSection>
      <Title>
        병원 Life & Episode
        {photos &&
        photos.length > 0 && (
          <ModalViewBtn
            onClick={() => {
              setIsOpenPhotoView(true);
              logEvent({
                category: '답변(병원) 상세',
                action: '클릭',
                label: 'LIFE & EPISODE 전체보기',
              });
            }}
          >
            {'전체보기'}
          </ModalViewBtn>
        )}
      </Title>
      <HospitalImageGalleryContainer>
        {photos && photos.length > 0 ? (
          <HospitalImageGallery
            onClick={() => {
              setIsOpenPhotoView(true);
              logEvent({
                category: '답변(병원) 상세',
                action: '클릭',
                label: 'LIFE & EPISODE 전체보기',
              });
            }}
            items={photos} />
        ) : (
          <BlankImg>앗! 아직 정보가 없어요</BlankImg>
        )}
      </HospitalImageGalleryContainer>
      <PhotoViewModal
        items={photos}
        isOpen={isOpenPhotoView}
        close={() =>
          setIsOpenPhotoView(false)
        }
      />
    </PhotoSection>
  );
};

export const HospitalComplimenets = ({ hospital: { compliments } }) => {
  if (!compliments || compliments.length === 0) {
    return null;
  }

  return (
    <Sectiond>
      <Title>동료 의료진의 칭찬</Title>
      <div>
        {compliments.map(compliment => (
          <Compliment key={`compliment_${compliment.id}`}>
            <ComplimentHeader>
              <img
                src={
                  compliment.fromHuser &&
                  compliment.fromHuser.photos &&
                  compliment.fromHuser.photos.length > 0
                    ? compliment.fromHuser.photos[0].url
                    : DoctorIcon
                }
                onError={(e) => {
                  e.target.src = DoctorIcon;
                }}
                alt="compliment"
              />
              <div>
                <ComlimentFrom>
                  {compliment.fromHuser && compliment.fromHuser.username} 원장님
                </ComlimentFrom>
                <ComlimentFromHospital>
                  {compliment.fromHuser &&
                  compliment.fromHuser.hospital &&
                  compliment.fromHuser.hospital.name}
                </ComlimentFromHospital>
              </div>
            </ComplimentHeader>
            <ComplimentContent>{compliment.desc}</ComplimentContent>
          </Compliment>
        ))}
      </div>
    </Sectiond>
  );
};

export const HospitalBeforeAndAfterPhotos = ({ hospital }) => {
  const [isOpenPhotoView, setIsOpenPhotoView] = useState(false);

  const photos = [];

  hospital.photos.forEach((photo) => {
    if (photo.category === 'casebook') {
      photos.push({
        original: photo.url,
        title: photo.title,
        desc: photo.description,
      });
    }
  });

  return (
    <PhotoSection>
      <Title>
        Before & After
        {photos &&
        photos.length > 0 && (
          <ModalViewBtn
            onClick={() => {
              setIsOpenPhotoView(true);
              logEvent({
                category: '답변(병원) 상세',
                action: '클릭',
                label: 'BEFORE & AFTER 전체보기',
              });
            }}
          >
            {'전체보기'}
          </ModalViewBtn>
        )}
      </Title>
      <HospitalImageGalleryContainer>
        {photos && photos.length > 0 ? (
          <HospitalImageGallery items={photos} />
        ) : (
          <BlankImg>앗! 아직 정보가 없어요</BlankImg>
        )}
      </HospitalImageGalleryContainer>
      <PhotoViewModal
        items={photos}
        isOpen={isOpenPhotoView}
        close={() =>
          setIsOpenPhotoView(false)
        }
      />
    </PhotoSection>
  );
};

export default {};
