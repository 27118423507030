const therapyInfo = {
  implant_implant: {
    item_id: 'IMP-001',
    type: 'implant_implant',
    viewName: '임플란트 식립',
    category: 'tx',
  },

  implant_boneGraft: {
    item_id: 'IMP-002',
    type: 'implant_boneGraft',
    viewName: '뼈이식 수술',
    category: 'tx',
  },

  implant_maxillarySinus: {
    item_id: 'IMP-003',
    type: 'implant_maxillarySinus',
    viewName: '상악동 수술',
    category: 'tx',
  },

  cavity: {
    item_id: 'CAV',
    type: 'cavity',
    viewName: '충치때우기',
    category: 'tx',
  },

  cavity_resin: {
    item_id: 'CAV-001',
    type: 'cavity_resin',
    viewName: '레진치료',
    category: 'tx',
  },

  cavity_inlay_gold: {
    item_id: 'CAV-002-01',
    type: 'cavity_inlay',
    viewName: '인레이 (금)',
    category: 'tx',
  },
  cavity_inlay_resin: {
    item_id: 'CAV-002-02',
    type: 'cavity_inlay',
    viewName: '인레이 (레진)',
    category: 'tx',
  },
  cavity_inlay_ceramic: {
    item_id: 'CAV-002-03',
    type: 'cavity_inlay',
    viewName: '인레이 (세라믹)',
    category: 'tx',
  },

  cavity_onlay_gold: {
    item_id: 'CAV-003-01',
    type: 'cavity_onlay',
    viewName: '온레이 (금)',
    category: 'tx',
  },
  cavity_onlay_ceramic: {
    item_id: 'CAV-003-02',
    type: 'cavity_onlay',
    viewName: '온레이 (세라믹)',
    category: 'tx',
  },

  cavity_crown_gold: {
    item_id: 'CAV-004-01',
    type: 'cavity_crown',
    viewName: '크라운 (금)',
    category: 'tx',
  },
  cavity_crown_pfm: {
    item_id: 'CAV-004-02',
    type: 'cavity_crown',
    viewName: '크라운 (pfm)',
    category: 'tx',
  },
  cavity_crown_zirconia: {
    item_id: 'CAV-004-03',
    type: 'cavity_crown',
    viewName: '크라운 (지르코니아)',
    category: 'tx',
  },

  aesthetic_dentDesign: {
    item_id: 'AES-001',
    type: 'aesthetic_dentDesign',
    viewName: '치아성형',
    category: 'tx',
  },

  aesthetic_frontTooth: {
    item_id: 'AES-002',
    type: 'aesthetic_frontTooth',
    viewName: '앞니 심미치료',
    category: 'tx',
  },

  aesthetic_frontTooth_resin: {
    item_id: 'AES-002-01',
    type: 'aesthetic_frontTooth',
    viewName: '앞니 심미치료 (레진)',
    category: 'tx',
  },
  aesthetic_frontTooth_pfm: {
    item_id: 'AES-002-02',
    type: 'aesthetic_frontTooth',
    viewName: '앞니 심미치료 (pfm)',
    category: 'tx',
  },
  aesthetic_frontTooth_woolCeramic: {
    item_id: 'AES-002-03',
    type: 'aesthetic_frontTooth',
    viewName: '앞니 심미치료 (올세라믹)',
    category: 'tx',
  },
  aesthetic_frontTooth_zirconia: {
    item_id: 'AES-002-04',
    type: 'aesthetic_frontTooth',
    viewName: '앞니 심미치료 (지르코니아)',
    category: 'tx',
  },
  aesthetic_frontTooth_laminate: {
    item_id: 'AES-002-05',
    type: 'aesthetic_frontTooth',
    viewName: '앞니 심미치료(라미네이트)',
    category: 'tx',
  },

  aesthetic_bleaching: {
    item_id: 'AES-003',
    type: 'aesthetic_bleaching',
    viewName: '치아 미백',
    category: 'tx',
  },

  aesthetic_botox: {
    item_id: 'AES-004',
    type: 'aesthetic_botox',
    viewName: '보톡스',
    category: 'tx',
  },
  aesthetic_scaling: {
    item_id: 'AES-005',
    type: 'aesthetic_scaling',
    viewName: '스케일링',
    category: 'tx',
  },

  aesthetic_gumDesign: {
    item_id: 'AES-006',
    type: 'aesthetic_gumDesign',
    viewName: '잇몸성형',
    category: 'tx',
  },
  general_rootCanal: {
    item_id: 'GEN-001',
    type: 'general_rootCanal',
    viewName: '신경치료',
    category: 'tx',
  },
  general_wisdomtooth: {
    item_id: 'GEN-002',
    type: 'general_wisdomtooth',
    viewName: '사랑니발치',
    category: 'tx',
  },
  general_fluoride: {
    item_id: 'GEN-003',
    type: 'general_fluoride',
    viewName: '불소도포',
    category: 'tx',
  },
  gum_tx: {
    item_id: 'GUM-001',
    type: 'gum_tx',
    viewName: '잇몸치료',
    category: 'tx',
  },
  facial_jaw: {
    item_id: 'FACE-001',
    type: 'facial_jaw',
    viewName: '턱관절치료',
    category: 'tx',
  },
  facial_twojaw: {
    item_id: 'FACE-002',
    type: 'facial_twojaw', // paul 의 의견.
    viewName: '양악수술',
    category: 'tx',
  },

  ortho_visible: {
    item_id: 'ORT-001',
    type: 'ortho',
    viewName: '보이는 교정',
    category: 'ortho',
  },
  ortho_visible_metal: {
    item_id: 'ORT-001-01',
    type: 'ortho',
    viewName: '보이는 교정 (메탈교정)',
    category: 'ortho',
  },
  ortho_visible_clipyC: {
    item_id: 'ORT-001-02',
    type: 'ortho',
    viewName: '보이는 교정 (Clipy-C)',
    category: 'ortho',
  },
  ortho_visible_resin: {
    item_id: 'ORT-001-03',
    type: 'ortho',
    viewName: '보이는 교정 (레진브라켓)',
    category: 'ortho',
  },
  ortho_visible_mta: {
    item_id: 'ORT-001-04',
    type: 'ortho',
    viewName: '보이는 교정 (MTA)',
    category: 'ortho',
  },
  ortho_visible_damon: {
    item_id: 'ORT-001-05',
    type: 'ortho',
    viewName: '보이는 교정 (DAMON)',
    category: 'ortho',
  },

  ortho_unvisible: {
    item_id: 'ORT-002',
    type: 'ortho',
    viewName: '안보이는 교정',
    category: 'ortho',
  },
  ortho_unvisible_2d: {
    item_id: 'ORT-002-01',
    type: 'ortho',
    viewName: '안보이는 교정 (2d교정)',
    category: 'ortho',
  },
  ortho_unvisible_clipyL: {
    item_id: 'ORT-002-02',
    type: 'ortho',
    viewName: '안보이는 교정 (Clipy-L)',
    category: 'ortho',
  },
  ortho_unvisible_tiny: {
    item_id: 'ORT-002-03',
    type: 'ortho',
    viewName: '안보이는 교정 (티끌 교정)',
    category: 'ortho',
  },

  ortho_transparent: {
    item_id: 'ORT-003',
    type: 'ortho',
    viewName: '투명교정',
    category: 'ortho',
  },
  ortho_transparent_transparent: {
    item_id: 'ORT-003-01',
    type: 'ortho',
    viewName: '투명 교정 (투명교정)',
    category: 'ortho',
  },
  ortho_transparent_invisalign: {
    item_id: 'ORT-003-02',
    type: 'ortho',
    viewName: '투명 교정 (인비잘라인)',
    category: 'ortho',
  },
};

export default therapyInfo;

export const txTherapyTypeName = {
  implant_implant: 'implant',
  implant_boneGraft: 'implant',
  implant_maxillarySinus: 'implant',

  cavity: 'cavity',
  cavity_resin: 'cavity',
  cavity_inlay: 'cavity',
  cavity_onlay: 'cavity',
  cavity_crown: 'cavity',

  aesthetic_dentDesign: 'aesthetic',
  aesthetic_frontTooth: 'aesthetic',
  aesthetic_bleaching: 'aesthetic',
  aesthetic_botox: 'aesthetic',
  aesthetic_gumDesign: 'aesthetic',

  aesthetic_scaling: 'general',
  general_rootCanal: 'general',
  general_wisdomtooth: 'general',
  general_fluoride: 'general',

  gum_tx: 'gum',

  facial_jaw: 'facial',
  facial_twojaw: 'facial',
};


export const therapyInfoById = {
  'IMP-001': therapyInfo.implant_implant,
  'IMP-002': therapyInfo.implant_boneGraft,
  'IMP-003': therapyInfo.implant_maxillarySinus,

  'CAV': therapyInfo.cavity,
  'CAV-001': therapyInfo.cavity_resin,

  'CAV-002-01': therapyInfo.cavity_inlay_gold,
  'CAV-002-02': therapyInfo.cavity_inlay_resin,
  'CAV-002-03': therapyInfo.cavity_inlay_ceramic,

  'CAV-003-01': therapyInfo.cavity_onlay_gold,
  'CAV-003-02': therapyInfo.cavity_onlay_ceramic,

  'CAV-004-01': therapyInfo.cavity_crown_gold,
  'CAV-004-02': therapyInfo.cavity_crown_pfm,
  'CAV-004-03': therapyInfo.cavity_crown_zirconia,

  'AES-001': therapyInfo.aesthetic_dentDesign,

  'AES-002': therapyInfo.aesthetic_frontTooth,
  'AES-002-01': therapyInfo.aesthetic_frontTooth_resin,
  'AES-002-02': therapyInfo.aesthetic_frontTooth_pfm,
  'AES-002-03': therapyInfo.aesthetic_frontTooth_woolCeramic,
  'AES-002-04': therapyInfo.aesthetic_frontTooth_zirconia,
  'AES-002-05': therapyInfo.aesthetic_frontTooth_laminate,

  'AES-003': therapyInfo.aesthetic_bleaching,

  'AES-004': therapyInfo.aesthetic_botox,

  'AES-005': therapyInfo.aesthetic_scaling,

  'AES-006': therapyInfo.aesthetic_gumDesign,

  'GEN-001': therapyInfo.general_rootCanal,
  'GEN-002': therapyInfo.general_wisdomtooth,
  'GEN-003': therapyInfo.general_fluoride,

  'GUM-001': therapyInfo.gum_tx,

  'FACE-001': therapyInfo.facial_jaw,
  'FACE-002': therapyInfo.facial_twojaw,

  'ORT-001': therapyInfo.ortho_visible,
  'ORT-001-01': therapyInfo.ortho_visible_metal,
  'ORT-001-02': therapyInfo.ortho_visible_clipyC,
  'ORT-001-03': therapyInfo.ortho_visible_resin,
  'ORT-001-04': therapyInfo.ortho_visible_mta,
  'ORT-001-05': therapyInfo.ortho_visible_damon,

  'ORT-002': therapyInfo.ortho_unvisible,
  'ORT-002-01': therapyInfo.ortho_unvisible_2d,
  'ORT-002-02': therapyInfo.ortho_unvisible_clipyL,
  'ORT-002-03': therapyInfo.ortho_unvisible_tiny,

  'ORT-003': therapyInfo.ortho_transparent,
  'ORT-003-01': therapyInfo.ortho_transparent_transparent,
  'ORT-003-02': therapyInfo.ortho_transparent_invisalign,
};

export const getOrthoType = (itemcode) => {
  if (itemcode.includes('ORT-001')) {
    return 'ortho_visible';
  } else if (itemcode.includes('ORT-002')) {
    return 'ortho_unvisible';
  } else if (itemcode.includes('ORT-003')) {
    return 'ortho_transparent';
  } else {
    return '';
  }
};
