import React from 'react';
import styled from 'styled-components';
import Star from '../atoms/StarMark';
import { NumSemiBoldSpan } from '../atoms/Text/Text';

const StyledStarWrap = styled.div`
  display: block;
`;

const StyledStarPoint = styled.span`
  display: inline-block;;
  margin-left: 2px;
  font-size: 100%;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #033e52;
  vertical-align: middle;
`;

const StarRating = ({
  selectedNum, starsNum, onClick, size, children, className, ahead,
}) => (
  <StyledStarWrap className={className}>
    { ahead && children }
    {
      Array.apply(null, { length: starsNum || 5 })
        .map((a, b) => (
          <Star
            key={b}
            size={size}
            {...(onClick && { onClick: () => onClick(b + 1) })}
                       // onClick={() => onClick(b + 1)}
            selected={(selectedNum > b)}
          />
        ))
    }
    { !ahead && children }
  </StyledStarWrap>
);

const ExtendedStarRating = styled(StarRating)`
  cursor: inherit;
`;

export const StarWithPoint = ({
  selectedNum,
  size,
  children,
  className,
  fontSize,
  ...others
}) => (
  <StyledStarWrap className={className}>
    <ExtendedStarRating
      size={size}
      selectedNum={selectedNum}
      {...others}
    >
      <StyledStarPoint className="star-point">
        <NumSemiBoldSpan fontSize={fontSize}>
          {children || selectedNum}
        </NumSemiBoldSpan>
      </StyledStarPoint>
    </ExtendedStarRating>
  </StyledStarWrap>
);

export default StarRating;
