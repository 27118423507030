import React from 'react';
import styled from 'styled-components';
import { StyledModal } from './basic/HtmlComponents';
import { media } from '../globalStyle';
import { ModalTitle, ModalCloseBtn } from './basic/ModalComponents';
import IconFeatureCompare from '../images/home/icon-02.svg';
import IconFeatureLocation from '../images/home/icon-location.svg';
import IconFeatureCheck from '../images/home/icon-check.svg';
import IconFeatureHeart from '../images/home/icon-heart.svg';

const CounselProcessModal = styled(StyledModal)`
  width: 380px;
  height: auto;
  position: relative;
  left: unset;
  top: unset;
  left: auto;
  top: auto;
  transform: none;
  align-self: center;
  margin: 120px auto;
  border: 1px solid #e0e0e0;
  box-shadow: none;
  padding: 30px;
  ${media.tablet`
    min-width: 319px;
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    transform: none;
    bottom: 0;
    overflow: hidden;
    margin: 0;
  `};
`;

const Content = styled.div`
  padding: 60px 0 30px 0;
`;

const StyledHr = styled.hr`
  margin: 7px 0 10px 0;
  border-color: #e1e3e2;
`;

const StepWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-bottom: 27px;
  & > div{
    width: 100%;
  }
`;

const StepTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: -.5px;
  color: #000a12;
  span {
    font-family: 'Saira Semi Condensed', sans-serif;
    display: inline-block;
    margin-right: 3px;
  }
  .title-icon{
    margin-left: auto;
  }
`;

const StepDesc = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -.5px;
  color: #000000;
`;

const Step = (
  {
    no,
    title,
    icon,
    desc,
  },
) => (
  <StepWrapper>
    <div>
      <StepTitle>
        <span>
          {no}
          .
        </span>
        {title}
        <img
          src={icon}
          className="title-icon"
          alt={icon}
        />
      </StepTitle>
      <StyledHr />
      <StepDesc>
        {desc}
      </StepDesc>
    </div>
  </StepWrapper>
);

const CounselProcessInfoModal = ({ isOpen, onClose }) => (
  <CounselProcessModal
    style={{
      overlay: {
        zIndex: 100,
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
      },
    }}
    isOpen={isOpen}
    ariaHideApp={false}
  >
    <ModalCloseBtn onClick={onClose} />
    <div style={{ marginTop: '70px' }}>
      <ModalTitle>오닥터 상담 진행절차</ModalTitle>
    </div>
    <Content>
      <Step
        no="1"
        title="병원의 답변을 비교"
        desc={(
          <div>
            오닥터의 검증된 치과들이 제시하는
            <br />
            소견과 예상 치료비를 비교하세요
          </div>
        )}
        icon={IconFeatureCompare}
      />
      <Step
        no="2"
        title="내게 맞는 병원을 예약"
        desc={(
          <div>
            병원의 위치, 시설 뿐만 아니라 치료해줄 치과 선생님을
            <br />
            꼼꼼히 알아보고 원하는 병원에 예약하세요
          </div>
        )}
        icon={IconFeatureLocation}
      />
      <Step
        no="3"
        title="원하는 치료만 받기"
        desc={(
          <div>
            예약한 일정에 맞춰 내원하여
            <br />
            꼭 필요하고 원하는 치료만 받으세요
          </div>
        )}
        icon={IconFeatureCheck}
      />
      <Step
        no="4"
        title="병원 리뷰하기"
        desc={(
          <div>
            치료 과정에서 느낀 점을 솔직하게
            <br />
            평가하고, 리워드까지 받아가세요
          </div>
        )}
        icon={IconFeatureHeart}
      />
    </Content>
  </CounselProcessModal>
);

export default CounselProcessInfoModal;
