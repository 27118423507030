import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { toggleFavoriteHospitalMutation, getHospitalQuery } from '../graphql';
import { HospitalReviewsQuery, reviewAndCaseQuery } from '../graphql/reviewQuery';
import { media } from '../globalStyle';
import PathIcon from '../images/road.png';
import DirectIcon from '../images/direct.png';
import {
  HospitalDoctors,
  HospitalVideos,
  HospitalBasicInfo,
  HospitalFeature,
  HospitalMap,
  HospitalOpenTime,
  HospitalMainPhotos,
  HospitalLifeAndEpisodePhotos,
  HospitalBeforeAndAfterPhotos,
  HospitalComplimenets,
} from '../components/hospital/HospitalInfoSections';
import { ReviewViewerComponent } from '../components/ReviewViewer/ReviewViewerComponent';
import withAlertModal from '../utils/alerthoc';
import { logEvent } from '../utils/loging';
import { SectionHr } from '../components/atomic/atoms/Shape/Bar';
import { Word, LinkedWord } from '../components/atomic/atoms/Text/Text';
import { ReactComponent as PhoneIcon } from '../images/call.svg';
import { ReactComponent as HeartIcon } from '../images/heart.svg';
import { StarWithPoint } from "../components/atomic/molecules/StarRating";
import { CaseCard, CaseList } from "./HospitalCase";
import {StretchButton} from "../components/atomic/molecules/ButtonGroup";

const HospitalIntro = styled.div`
  max-width: 387px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: -0.8px;
  color: #000000;
  margin: 0;
  word-break: keep-all;
  ${media.miniDesktop`
    line-height: 1.43;
    letter-spacing: -0.5px;
    margin: 8px 0 0;
    max-width: 100%;
  `};
`;

const Header = styled.div`
  margin-bottom: 30px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  margin-top: 60px;
  ${media.miniDesktop`
    margin-top: 30px;
    margin-bottom: 30px;
  `};
`;

const HeaderLeft = styled.div`
  width: 100%;
  ${media.miniDesktop`
    padding: 0;
  `};
`;

const HospitalTitle = styled.div`
  padding: 0;
`;

const HeaderRight = styled.div`
  min-width: 228px;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  margin-top: 30px;
  ${media.miniDesktop`
    display: none;
  `};
`;

const PathMapLinkBtn = styled.button`
  width: 112px;
  border: 0;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.6px;
  text-align: center;
  color: #033e52;
  position: relative;
  img {
    width: 36px;
    height: 36px;
    object-fit: contain;
    margin-bottom: 7px;
  }
  // &::after {
  //   content: '';
  //   width: 1px;
  //   background-color: ${props => (props.isLine ? '#e4e4e4' : '#fff')};
  //   height: 60px;
  //   position: absolute;
  //   right: 0;
  //   top: 0;
  // }
`;

const DirectCounselBtn = styled.button`
  width: 112px;
  border: 0;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.6px;
  text-align: center;
  color: #033e52;
  &:disabled {
    color: #bbbcbc;
  }
  img {
    width: 36px;
    height: 36px;
    object-fit: contain;
    margin-bottom: 7px;
  }
`;

const HospitalName = styled.div`
  font-size: 46px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -2.1px;
  color: #000000;
  margin-right: 23px;
  display: inline-block;
  vertical-align: middle;
  ${media.tablet`
    font-size: 20px;
    letter-spacing: -.5px;
  `};
`;

const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${media.miniDesktop`
    display: block;
  `};
`;

const LeftSection = styled.div`
  margin-right: 10px;
  width: 100%;
  ${media.tablet`
    margin-right: 0;
    .star-box{
      display: none;
    }
  `};
`;

const RightSection = styled.div`
  width: 100%;
  max-width: 350px;
  min-width: 300px;
  ${media.miniDesktop`
    min-width: 0;
    max-width: 100%;
  `};
`;

const MobileBottomBtns = styled.div`
  display: none;
  ${media.miniDesktop`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    min-height: 64px;
    bottom: 0;
    left: 0;
    z-index: 1;
    padding: 8px 20px;
    box-shadow: 0 -3px 8px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    button {
      width: 160px;
      height: 48px;
      border-radius: 4px;
      background-color: #033e52;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: -0.65px;
      text-align: center;
      color: #ffffff;
      position: relative;
      &:nth-of-type(2)::before {
        content: '';
        width: 1px;
        height: 18px;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -10px;
        opacity: 0.5;
        background-color: #ffffff;
      }
      :disabled {
        opacity: 0.5;
      }
      &.circle {
        border: solid 1px #e4e4e4;
        background-color: #ffffff;
        border-radius: 40px;
        width: 40px;
        height: 40px;
        padding: 0;
        margin-right: 10px;
        :last-of-type {
          margin: 0;
        }
        svg {
          vertical-align: middle;
        }
      }
      &.active-heart {
        svg > path {
          fill: #dd4124;
          stroke: #dd4124;
        }
      }
    }
  `};
`;

const HospitalPhotos = styled.div`
  margin-top: 30px;

  &:first-of-type {
    margin-top: 170px;
  }
  ${media.miniDesktop`
    width: 100%;
    margin-top: 30px;
    border-top: 1px solid #e4e4e4;
    &:first-of-type {
      margin-top: 30px;
    }
  `};

  ${media.tablet`
    margin-right: 0;
    border: 0;
  `};
`;

const StyledSectionHr = styled(SectionHr)`
  margin: 30px 0;
`;

const HospitalNullMsg = styled.div`
  padding: 15px 4px;
  text-align: center;
`;

const StyledAddressReviewsWrap = styled.div`
  margin-top: 30px;
  .location-icon{
    display: inline-block;
    vertical-align: middle;
    height: 11px;
  }
`;

const ExtendedLinkedWord = styled(LinkedWord)`
  margin-right: 5px;
`;

const StyledRRWrap = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  .reservation-cnt{
    font-size: 14px;
    letter-spacing: -.5px;
  }
`;

const StyledJustifyHeader = styled.div`
  display: flex;
  align-items: center;
  line-height: 1;
  >div{
    margin-left: auto;
  }
`;

class HospitalInfoContainer extends Component {
  toggleFavorite = async (hid, e) => {
    e.stopPropagation();
    const { toggleFavorite } = this.props;

    try {
      await toggleFavorite({
        variables: {
          hospitalId: hid,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  moveToReviews = () => {
    const { history, hospital } = this.props;
    history.push(`/hospitals/${hospital?.id}/reviews`);
  };

  moveToCases = () => {
    const { history, hospital } = this.props;
    history.push(`/case?&hid=${hospital?.id}`);
  };

  render() {
    const {
      hospital,
      match,
      recentReservationCnt,
      history,
      openCallModal,
      reservationMobileBtnComponent,
      reservationBtnComponent,
      respsComponent,
      reqComponent,
      reviewAndCase,
    } = this.props;

    if(reviewAndCase.loading) return null;


    const { hospitalReviews : { data: reviews  }, reqCases: {data: cases, pagination: caseCnt} } = reviewAndCase;
    console.log('reviews', reviews)
    console.log('cases', cases)

    if (!hospital) {
      return (
        <HospitalNullMsg>병원을 찾을 수 없습니다.</HospitalNullMsg>
      );
    }

    const mainPhoto = hospital.photos
      .filter(photo => photo.category === 'main')
      .map(photo => (
        { // return
          original: photo.url,
          title: photo.title,
          desc: photo.description,
        }
      ));

    const allVideos = [];

    if(hospital?.metadata?.videos) {
      hospital.metadata.videos.forEach((v)=>{
        allVideos.push({
          title: v.title,
          url: v.url,
          img: v.thumbnail,
        })
      });
    }

    hospital.doctors.forEach(doctor => {
      doctor.videos.map((v) => {
        allVideos.push({
          title: v.title,
          url: v.url,
          img: v.thumbnail,
          desc: doctor.name,
        });
      });
    });

    return (
      <Container>
        <LeftSection>
          {/*대표사진*/}
          {
            mainPhoto.length > 0
            &&
            <HospitalMainPhotos
              photos={mainPhoto}
            />
          }

          <Header>
            <HeaderLeft>
              <HospitalTitle>
                <HospitalName>{hospital.name}</HospitalName>
              </HospitalTitle>
              {hospital.metadata && hospital.metadata.intro && (
                <HospitalIntro>
                  { hospital.metadata && hospital.metadata.intro }
                </HospitalIntro>
              )}
              <StyledAddressReviewsWrap>
                <Word styled={{ verticalAlign: 'middle' }}>
                  {hospital.address}
                </Word>

                <StyledRRWrap>
                  {
                    !!hospital.reviewCnt &&
                    (
                      <ExtendedLinkedWord onClick={this.moveToReviews}>
                        <StarWithPoint
                          starsNum={1}
                          size={'14px'}
                          fontSize='14px'
                          selectedNum={1}
                        >
                          {hospital.avgRating}
                          <span style={{color: '#000', fontWeight: 'normal', marginLeft: '2px'}}>
                          ({hospital.reviewCnt})
                        </span>
                        </StarWithPoint>
                      </ExtendedLinkedWord>
                    )
                  }
                  <div className="reservation-cnt">
                    {!!hospital.reviewCnt && ' · '}
                    {'최근예약 '}
                    {recentReservationCnt}
                    {'개'}
                  </div>
                </StyledRRWrap>


              </StyledAddressReviewsWrap>
            </HeaderLeft>
            <HeaderRight>
              {hospital.lat && hospital.lng && (
                <PathMapLinkBtn
                  isLine={!reservationBtnComponent}
                  onClick={() => {
                    window.open(`http://map.daum.net/link/to/${hospital.name},${hospital.lat},${hospital.lng}`);
                    logEvent({
                      category: '답변(병원) 상세',
                      action: '클릭',
                      label: '길찾기',
                    });
                  }}
                >
                  <img src={PathIcon} alt="path" />
                  <div>길찾기</div>
                </PathMapLinkBtn>
              )}
              {/*{reservationBtnComponent ? (*/}
              {/*  <>/!* reservationBtnComponent *!/</>*/}
              {/*) : (*/}
              {/*  <DirectCounselBtn*/}
              {/*    onClick={() => {*/}
              {/*      history.push({*/}
              {/*        pathname: `/counsel/${hospital.id}`,*/}
              {/*        state: { mode: 'quick' },*/}
              {/*      });*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    <img src={DirectIcon} alt="direct" />*/}
              {/*    <div>다이렉트상담1</div>*/}
              {/*  </DirectCounselBtn>*/}
              {/*)}*/}
            </HeaderRight>
          </Header>
          {respsComponent || null}
          <StyledSectionHr />
          {/* 의사 목록 */}
          {
            hospital.doctors.length > 0 &&
            <>
              <HospitalDoctors
                doctors={hospital.doctors}
              />
              <StyledSectionHr />
            </>
          }
          {/* 동료의사 칭찬 */}
          {/* <HospitalComplimenets hospital={hospital} /> */}

          {
            !! cases.length &&
              (
                <>
                <CaseList
                  hid={hospital.id}
                  topBorderHide={true}
                  title={'상담사례'}
                  history={history}
                  caseList={cases}
                  onLoadMore={this.moveToCases}
                  moreButtonText={`${caseCnt.total}개 상담사례 전체보기`}
                />
                  <StyledSectionHr />
                </>
              )
          }
          { !!hospital.reviewCnt
          && (
            <>
            <div onClick={this.moveToReviews}>
              <ReviewViewerComponent
                title={(<StyledJustifyHeader>
                  리뷰
                    <StarWithPoint
                    starsNum={1}
                    size={'14px'}
                    fontSize='14px'
                    selectedNum={1}
                    >
                    {hospital.avgRating}
                    <span style={{color: '#000', fontWeight: 'normal', marginLeft: '2px'}}>
                    ({hospital.reviewCnt})
                    </span>
                    </StarWithPoint>
                </StyledJustifyHeader>)}
                reviews={reviews}
                paging={1}
                fontSize={'16px'}
                starSize={'16px'}
                // hideStar={true}
                hideMoreButton={true}
                // onLoadMore={this.moveToReviews}
                moreButtonText={`${hospital.reviewCnt}개 리뷰 전체보기`}
              />
            </div>
            <StyledSectionHr />
            </>
          )
          }

          {/* 동영상 */}
          {
            !!allVideos.length && (
              <>
              <HospitalVideos
                title={(videoCnt) => (
                  <StyledJustifyHeader>동영상<div style={{fontSize: '14px', fontWeight: 'normal'}}>총 {videoCnt}건</div></StyledJustifyHeader>
                )}
                hospital={hospital}
                allVideos={allVideos}
              />
              <StyledSectionHr />
              </>
            )
          }
          {/* 병원 특징 */}
          <HospitalFeature
            infos={hospital.infos}
          />
          <StyledSectionHr />
          {/* 병원 지도 */}
          { hospital.address && <HospitalMap title='위치' hospital={hospital} />}
          <StyledSectionHr />
          {/* 병원 운영 시간 */}
          <HospitalOpenTime title='진료 시간' openHour={hospital.metadata.openhour} />

          {/*<HospitalBasicInfo
            exposePhonenum={reqComponent}
            hospital={hospital}
          />*/}
          {/* 병원 위치(지도) */}

          <HospitalPhotos>
            {/*<HospitalMainPhotos hospital={hospital} />*/}
            <HospitalLifeAndEpisodePhotos hospital={hospital} />
            <HospitalBeforeAndAfterPhotos hospital={hospital} />
          </HospitalPhotos>

        </LeftSection>
        {/*<RightSection>*/}
        {/*  {reqComponent || null}*/}
        {/*  <HospitalPhotos>*/}
        {/*    hi*/}
        {/*    /!*<HospitalMainPhotos hospital={hospital} />*!/*/}
        {/*    <HospitalLifeAndEpisodePhotos hospital={hospital} />*/}
        {/*    <HospitalBeforeAndAfterPhotos hospital={hospital} />*/}
        {/*  </HospitalPhotos>*/}
        {/*</RightSection>*/}
        <MobileBottomBtns className="mobile-bottom-btns">
          {reqComponent && openCallModal && (
            <React.Fragment>
              <div>
                <button
                  type="button"
                  className="circle"
                  onClick={() => {
                    openCallModal();
                    logEvent({
                      category: '답변(병원) 상세',
                      action: '클릭',
                      label: '전화하기',
                    });
                  }}
                >
                  <PhoneIcon />
                </button>
                <button
                  type="button"
                  className={`circle ${hospital.favorited ? 'active-heart' : ''}`}
                  onClick={this.toggleFavorite.bind(this, hospital.id)}
                >
                  <HeartIcon />
                </button>
              </div>
              <button
                type="button"
                onClick={() => {
                  history.push(`/reservation/${match.params.id}/${match.params.hid}`);
                  logEvent({
                    category: '답변 상세',
                    action: '예약 요청하기 선택',
                    label: '',
                  });
                }}
              >
                {'예약 요청하기'}
              </button>
            </React.Fragment>
          )}
          {reservationMobileBtnComponent ? (
            <>{/* this.props.reservationMobileBtnComponent */}</>
          ) : (
            /* 다이렉트 상담 -> 일반 상담 임시 수정 0428 */
            <>
              <div>
                <button
                  type="button"
                  className="circle"
                  onClick={() => {
                    openCallModal();
                    logEvent({
                      category: '병원 상세',
                      action: '클릭',
                      label: '전화하기',
                    });
                  }}
                >
                  <PhoneIcon />
                </button>
                <button
                  type="button"
                  className={`circle ${hospital.favorited ? 'active-heart' : ''}`}
                  onClick={this.toggleFavorite.bind(this, hospital.id)}
                >
                  <HeartIcon />
                </button>
              </div>
              <button
                type="button"
                onClick={() => {
                  history.push({
                    pathname: '/',
                    // pathname: `/counsel/${hospital.id}`,
                    // state: { mode: 'quick' },
                  });
                }}
              >
                {'상담받기'}
              </button>
            </>
          )}
        </MobileBottomBtns>
      </Container>
    );
  }
}

export default compose(
  graphql(toggleFavoriteHospitalMutation, {
    name: 'toggleFavorite',
    options: ({ hospital }) => ({
      refetchQueries: [
        {
          query: getHospitalQuery,
          variables: {
            id: hospital.id,
          },
        },
      ],
    }),
  }),
  graphql(reviewAndCaseQuery, {
    name: 'reviewAndCase',
    options: ({hospital}) => ({
      variables: {
        hospitalId: hospital.id,
        perPage: 1,
        currentPage: 1,
      },
    }),
  }),
)(withAlertModal(withRouter(HospitalInfoContainer)));
