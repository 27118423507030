import React from 'react';
import styled from 'styled-components';
import { compose, graphql } from 'react-apollo';
import PhonenumInput from './PhonenumInput';
import PinNumInput from './PinNumInput';
import withToast from '../../utils/toasthoc';
import { phoneNumberConfirmMutation, isExistingUserMutation, pinConfirmForPasswordUpdateMutation } from '../../graphql';
import { BlueSpan } from '../basic/HtmlComponents';
import withAlertModal from '../../utils/alerthoc';

const ContainerRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  &:nth-of-type(2) {
    margin-top: 16px;
  }
`;

const PhoneCertificationBtn = styled.button`
  min-width: 120px;
  margin-left: 4px;
  min-width: 100px;
  height: 48px;
  background-color: #033e52;
  border: solid 1px #033e52;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  text-align: center;
  color: #ffffff;
  &:disabled {
    background-color: #bbbcbc;
    border-color: #bbbcbc;
    ${props =>
    props.confirmed &&
      `
      border-color: #033e52;
      background-color: #ffffff;
      color: #033e52;
    `};
  }
`;


const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;


class ResetPassPhoneConfirmInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      originPhonenum: props.value || null,
      showCertificationNumInput: false, // 휴대폰 인증번호 입력 활성화
      phoneErrorMsg: null, // 휴대폰 번호 입력 에러 메세지
      phonePinNumErrorMsg: null, // 휴대폰 인증번호 입력 에러 메세지
      min: '3', // 휴대폰 인증번호 타임아웃 (분)
      sec: '00', // 휴대폰 인증번호 타임아웃 (초)
      phoneConfirmed: false, // 휴대폰 인증 완료 플래그
      pinObjId: null, // db 에서 인증번호 table id
      pin: '', // 휴대폰 인증번호
    };

    this.secondsRemaining = null;
    this.intervalHandle = null;
    this.startCountDown = this.startCountDown.bind(this);
    this.tick = this.tick.bind(this);
  }

  // 휴대폰 인증번호 확인
  confirmPinNum = async () => {
    try {
      // 에러메시지 클리어
      this.props.clearError();


      if (!this.state.pinObjId) {
        this.setState({
          phonePinNumErrorMsg: '인증번호를 재전송 후 이용해주세요',
        });
        return;
      }

      if (!this.state.pin) {
        this.setState({ phonePinNumErrorMsg: '인증번호를 입력하세요.' });
        return;
      }

      // sms 확인 API
      const res = await this.props.pinConfirmForPasswordUpdate({
        variables: {
          id: this.state.pinObjId,
          pin: this.state.pin,
        },
      });

      if (res.data.pinConfirmForPasswordUpdate && res.data.pinConfirmForPasswordUpdate.ok && res.data.pinConfirmForPasswordUpdate.resetToken) {
        // success
        this.setState({
          showCertificationNumInput: false,
          phoneConfirmed: true,
        });
        clearInterval(this.intervalHandle);
        this.props.onConfirmed(res.data.pinConfirmForPasswordUpdate.resetToken);
      } else {
        this.setState({ phonePinNumErrorMsg: '인증번호가 일치하지 않습니다' });
      }
    } catch (e) {
      // fail
      // error
    }
  };

  // 휴대폰 인증번호 발송
  certifyPhone = async () => {
    const phonenum = this.props.value;
    const { originPhonenum } = this.state;

    // 에러메시지 클리어
    this.props.clearError();

    if (!phonenum) {
      return;
    }

    if (phonenum.length !== 10 && phonenum.length !== 11) {
      this.setState({ phoneErrorMsg: '올바른 휴대폰 번호를 입력해주세요.' });
      return;
    }

    if (originPhonenum && originPhonenum === phonenum) {
      this.setState({ phoneErrorMsg: '현재 등록된 휴대전화 정보와 동일합니다.' });
      return;
    }

    // 인증하기전 가입 완료 눌렀을 경우 메세지 없애기
    this.setState({ phoneErrorMsg: null, phonePinNumErrorMsg: null });

    try {
      // 핸드폰 번호 중복 검사
      const phoneDuplimatedRes = await this.props.isExistingUser({
        variables: {
          phonenum,
        },
      });

      if (!phoneDuplimatedRes.data.isExistingUser) {
        throw new Error(phoneDuplimatedRes.data.errors);
      }

      const {
        isExistingUser: { ok, error },
      } = phoneDuplimatedRes.data;

      if (!ok) {
        if (error && error.message && error.message === 'isHuser') {
          this.props.openAlert({
            title: '오닥터 파트너로 가입되어 있어요!',
            message: (
              <div>
                <BlueSpan>{phonenum}</BlueSpan>로 이미 가입된 계정입니다. 파트너병원 비밀번호 찾기 페이지로 이동합니다.
              </div>
            ),
            showConfirmBtn: true,
            confirmBtnName: '확인',
            textAlign: 'left',
            confirmBtnOnClick: () => {
              window.location = 'https://hospital.odoctor.co.kr/findpassword';
            },
          });
        } else {
          this.props.openAlert({
            title: '비밀번호 재설정 오류',
            message: (
              <div>
                <BlueSpan>{phonenum}</BlueSpan>와 일치하는 오닥터 계정이 없습니다. 다시 시도하거나 회원가입을 진행해 주세요.
              </div>
            ),
            showConfirmBtn: true,
            confirmBtnName: '확인',
            textAlign: 'left',
            confirmBtnOnClick: this.props.closeAlert,
          });
        }
        return;
      }
    
      // 인증 sms 발송 API
      const ConfirmSmsRes = await this.props.phoneNumberConfirm({
        variables: {
          phonenum,
        },
      });

      if (
        ConfirmSmsRes.data.phoneNumberConfirm &&
        ConfirmSmsRes.data.phoneNumberConfirm.ok &&
        ConfirmSmsRes.data.phoneNumberConfirm.id
      ) {
        // toast 알림
        this.props.toast(
          <div>입력하신 휴대폰 번호로 인증번호를 발송했습니다.</div>,
          'info',
        );

        this.setState({
          showCertificationNumInput: true,
          pinObjId: ConfirmSmsRes.data.phoneNumberConfirm.id,
        });
        this.startCountDown();
      } else {
        this.props.toast(
          <div>
            발송실패
            <br />
            잠시후에 다시 시도해주세요.
          </div>,
          'left',
        );
      }
    } catch (err) {
      this.props.toast(
        <div>
          번호 중복 확인 실패
          <br />
          휴대폰 번호 확인을 위해 번호를 다시 입력해주세요.
        </div>,
        'left',
      );
    }
  };

  // 휴대폰 인증 타임아웃을 계산하는 타이머
  tick() {
    const min = Math.floor(this.secondsRemaining / 60);
    let sec = this.secondsRemaining - min * 60;

    if (min === 0 && sec === 0) {
      this.setState({
        phonePinNumErrorMsg:
          '입력시간을 초과하였습니다. 인증번호 재전송 후 다시 시도하세요.',
        pin: '',
        pinObjId: null,
      });
      clearInterval(this.intervalHandle);
    }

    if (sec < 10) {
      sec = `0${sec}`;
    }

    this.setState({
      min,
      sec,
    });

    this.secondsRemaining -= 1;
  }

  // 타이머 시작
  startCountDown() {
    clearInterval(this.intervalHandle);
    this.intervalHandle = setInterval(this.tick, 1000);
    this.secondsRemaining = 180;
  }

  render() {
    const {
      value,
      onChange,
      name,
      fireSubmitValidation,
      errorMsg,
      confirmBtnName,
      forceConfirm,
    } = this.props;

    const {
      phoneErrorMsg,
      showCertificationNumInput,
      phonePinNumErrorMsg,
      pin,
      min,
      sec,
    } = this.state;
    const phonenum = value;

    const phoneConfirmed = forceConfirm || this.state.phoneConfirmed;

    return (
      <div style={{ width: '100%' }}>
        <ContainerRow>
          <InputBox>
            <PhonenumInput
              id="signupPhone"
              placeholder="휴대폰 번호 (- 없이 입력)"
              name={name}
              onChange={(e) => {
                onChange(e);
                this.setState({ phoneErrorMsg: '', phonePinNumErrorMsg: '', showCertificationNumInput: false, pinObjId: null });
              }}
              value={phonenum}
              fireSubmitValidation={fireSubmitValidation}
              disabled={phoneConfirmed}
              errorMsg={phoneErrorMsg || errorMsg || ''}
            />
          </InputBox>
          {phoneConfirmed ? (
            <PhoneCertificationBtn
              confirmed={phoneConfirmed}
              disabled={phoneConfirmed || phonenum === ''}
            >
              {'인증완료'}
            </PhoneCertificationBtn>
          ) : (
            <PhoneCertificationBtn
              onClick={this.certifyPhone}
              confirmed={phoneConfirmed}
              disabled={phoneConfirmed || phonenum === ''}
            >
              {showCertificationNumInput ? '재전송' : (confirmBtnName || '인증하기')}
            </PhoneCertificationBtn>
          )}
        </ContainerRow>

        {showCertificationNumInput && (
          <ContainerRow>
            <InputBox>
              <PinNumInput
                type="number"
                id="pin"
                name="pin"
                onChange={e => this.setState({ pin: e.target.value, phonePinNumErrorMsg: '' })}
                placeholder="인증번호 4자리"
                errorMsg={phonePinNumErrorMsg}
                fireSubmitValidation={fireSubmitValidation}
                value={pin}
              >
                <span>{`${min} : ${sec}`}</span>
              </PinNumInput>
            </InputBox>
            <PhoneCertificationBtn disabled={!pin} onClick={this.confirmPinNum}>
              확인
            </PhoneCertificationBtn>
          </ContainerRow>
        )}
      </div>
    );
  }
}

export default compose(
  graphql(isExistingUserMutation, {
    name: 'isExistingUser',
  }),
  graphql(phoneNumberConfirmMutation, {
    name: 'phoneNumberConfirm',
  }),
  graphql(pinConfirmForPasswordUpdateMutation, {
    name: 'pinConfirmForPasswordUpdate',
  }),
)(withToast(withAlertModal(ResetPassPhoneConfirmInput)));
