import React from 'react';
import styled from 'styled-components';
import { getNameValidationMessage } from '../../utils/validationInput';
import FormInput from './FormInput';


const NameInputContainer = styled.div`
  position: relative;
`;

class NameInput extends React.Component {
  state = {
    nameValidationMsg: '',
  };

  onChange = (e) => {
    this.props.onChange(e);
    const nameValidationMsg = getNameValidationMessage(e.target.value);
    this.setState({ nameValidationMsg });
  }

  render() {
    const { nameValidationMsg } = this.state;
    const {
      value, name, placeholder, noValidate, fireSubmitValidation, errorMsg,
    } = this.props;

    let nameErrorMsg = '';

    if (!noValidate && nameValidationMsg) {
      nameErrorMsg = nameValidationMsg;
    }

    return (
      <NameInputContainer>
        <FormInput
          {...this.props}
          type="text"
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={this.onChange}
          style={{ paddingRight: '42px' }}
          fireSubmitValidation={fireSubmitValidation}
          errorMsg={errorMsg || nameErrorMsg || ''}
        />
      </NameInputContainer>
    );
  }
}
export default NameInput;
