import React from 'react';
import styled from 'styled-components';
import ScrollToTopOnMount from '../components/basic/ScrollToTopOnMount';
import HospitalList from '../containers/HospitalList';

const Wrapper = styled.div`
  margin: 0 auto;
  @media (max-width: 576px) {
    padding: 90px 8px;
    font-size: 18px;
  }
`;

const Content = styled.div`
  @media (max-width: 576px) {
    margin-top: 0;
  }
`;

class HsList extends React.Component {
  render() {
    return (
      <div>
        <ScrollToTopOnMount />
        <Wrapper>
          <Content>
            <HospitalList />
          </Content>
        </Wrapper>
      </div>
    );
  }
}
export default HsList;
