import React, { useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { Query } from 'react-apollo';
import { locationGunguQuery } from '../../graphql';
import { StyledModal } from '../atomic/atoms/Modal/Modal';
import { si } from '../../data/index';
import { FlexGrid, CreateButton } from './EnhancedComponents';
import { media } from '../../globalStyle';
import closeBtn from '../../images/close.svg';
import deleteTag from '../../images/delete-tag.svg';
import addrHrLine from '../../images/addr-shadow-line.png';

const StyledButton = styled.div`
  margin: 5px 0;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center;
  position: relative;
  .delete-btn-tag{
    width: 16px;
    height: 16px;
    position: absolute;
    top: -7px;
    right: -7px;
    padding: 10px;
    margin: -10px;
    box-sizing: content-box;
    background: url(${deleteTag}) no-repeat center center;
  }
`;

const PanelModal = styled(StyledModal)`
  width: 380px;
  height: auto;
  position: relative;
  left: unset;
  top: unset;
  left: auto;
  top: auto;
  transform: none;
  align-self: center;
  margin: 120px auto;
  border: 1px solid #e0e0e0;
  box-shadow: none;
  padding: 30px;
  ${media.tablet`
    min-width: 319px;
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    transform: none;
    bottom: 0;
    overflow: hidden;
    margin: 0;
  `};
  .panel-top{
    text-align: right;
    margin-bottom: 15px;
  }
`;

const StyledCityPanel = styled.div`
   
`;

const StyledTownPanel = styled.div`
  max-height: calc(100% - 110px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 15px 20px;
  margin: 15px -20px 0 -20px;
`;

const StyledLine = styled.div`
  position: relative;
  z-index:99;
  left: 0;
  width: calc(100% + 40px);
  margin: 15px -20px;  
  div{
    position: absolute;
    height: 12px;
    width: 100%;
    background: url(${addrHrLine});  
  }
`;

// 시군구
const SelectTownPanel = ({ siName, gunguName, onClick }) => (
  <Query
    query={locationGunguQuery}
    variables={{
      siName,
    }}
  >
    {({ loading, error, data }) => {
      if (loading) return null;
      if (error) return `Error! ${error.message}`;

      if (loading && data && data.locationGungu) {
        // this.setState({ cityList: gunGu.data.locationGungu });
      }

      // 가나다 정렬
      const sortGungu = data.locationGungu.sort((a, b) => (a.name < b.name ? -1 : 1));

      const OPTION = sortGungu.map(
        op => (
          {
            ...op,
            active: false,
            value: op.name,
          }
        ),
      );

      return ( // Todo : rebuiild , FlexGrid가 component안의 FelxGrid와 중복됨 정리되야함
        <StyledTownPanel>
          <FlexGrid
            column
            items={OPTION}
            cols={3}
            render={item => (
              <StyledButton>
                {
                    CreateButton(() => { onClick({ siName, gunguName: item.name }); }, // onClickHandler
                      'textButton', // buttonType,
                      item.name,
                      item.description,
                      item.image,
                      // selectedData.replace(/\"/gi, '').match(new RegExp('^' + item.value, 'gi')))
                      item.name === gunguName)
                  }
              </StyledButton>
            )}
          />
        </StyledTownPanel>
      );
    }}
  </Query>
);

const StyledCloseBtn = styled.button`
  background: url(${props => props.img}) no-repeat center center;
  box-sizing: content-box;
  width: 18px;
  height: 18px;
  padding: 20px;
  margin: -20px -20px 0;
`;

const SelectCityPanel = ({ onClick, data, closeModal }) => {
  const [siName, setSiName] = useState(data?.siName || '서울');
  const [gunguName] = useState(data?.gunguName || null);

  return (
    <PanelModal isOpen>
      <div className="panel-top">
        <StyledCloseBtn img={closeBtn} onClick={() => { closeModal(false); }} />
      </div>
      <StyledCityPanel>
        <FlexGrid
          column
          items={si}
          cols={3}
          render={item => (
            <StyledButton>
              {
                  CreateButton(() => { setSiName(item.name); }, // onClickHandler
                    'textButton', // buttonType,
                    item?.name,
                    item.description || 'hihi',
                    item.image,
                    item.name === siName)
                }
            </StyledButton>
          )}
        />
      </StyledCityPanel>
      <StyledLine>
        <div />
      </StyledLine>
      <SelectTownPanel
        siName={siName}
        gunguName={gunguName}
        onClick={onClick}
      />
    </PanelModal>
  );
};

const SelectAddressComponent = ({ onClick }) => {
  let { locations } = JSON.parse(localStorage.getItem('pData'));
  // 기본적으로 dataType의 [] 가 기본값으로 저장됨
  // 고로 null 로 초기화
  locations = _.isEmpty(locations) ? [{}, {}] : locations;

  // siName
  // gunguName
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState(locations);

  const dataHandler = (inputData) => {
    // setState
    setData(inputData);
    // 두개의 지역이 비어있다면 {}
    // null 로 초기화 해서 '다음'버튼 비활성화
    const f = _.isEmpty(inputData[0]) && _.isEmpty(inputData[1]);
    onClick({ value: f ? null : inputData });
  };

  return (
    <div>
      <FlexGrid
        column
        items={data}
        cols={2}
        render={(item, idx) => (
          <StyledButton>
            {
                // 선택된 아이템이 있으면
                // 삭제 버튼 활성화
                item?.siName
                && (
                <div
                  className="delete-btn-tag"
                  onClick={() => {
                    const tmp = data;
                    tmp[idx] = {};
                    dataHandler(tmp);
                  }}
                />
              )
            }
            {
                CreateButton(() => { setIsOpen(idx + 1); }, // onClickHandler
                  'textButton', // buttonType,
                  (item?.siName && `${item.siName} ${item.gunguName}`) || '지역선택',
                  item.description || 'hihi',
                  item.image,
                  data[idx]?.siName)
              }
          </StyledButton>
        )}
      />
      <div>
        { isOpen && (
        <SelectCityPanel
          isOpen
          closeModal={setIsOpen}
          data={data[isOpen - 1]}
          onClick={(selectData) => {
            const idx = isOpen - 1; // [1,2]
            const tmp = data;
            tmp[idx] = {
              ...selectData,
            };
            dataHandler(tmp);
            setIsOpen(false);
          }}
        />
        )}
      </div>
    </div>
  );
};

export default SelectAddressComponent;
