import React, { Component }  from 'react';
import styled from 'styled-components';

const App = styled.div`
  margin: 0 -20px;
  .menu-item {
    width: 90px;
    height: 46px;
    user-select: none;
    cursor: pointer;
    border-radius: 4px;
    border: solid 1px #e1e3e2;
    margin-right: 5px;
    line-height: 1;
    font-size: 13px;
    letter-spacing: .5px;
  }
    .menu-item-wrapper {
    
  }    .menu-item-wrapper.active {
    //border: solid 1px #033e52;
  }
    .menu-item.active {
    border: solid 1px #033e52;
  }

    .scroll-menu-arrow {
    padding: 20px;
    cursor: pointer;
  }
`;

const StyledScrollMenu = styled.div`
  display: flex;
  overflow-y: hidden;
  width: 100%;
  padding-left: 20px;
  &>div {
  width: 100px;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  .menu-item {
    width: 90px;
    height: 46px;
    user-select: none;
    cursor: pointer;
    border-radius: 4px;
    border: solid 1px #e1e3e2;
    margin-right: 10px;
    line-height: 1;
    font-size: 13px;
    letter-spacing: .5px;
    &:last-child{
      margin-right: 20px;
    }
    &.active{
      border: solid 1px #033e52;
    }
  }
`;
// One item component
// selected prop will be passed
const MenuItem = ({text, selected}) => {
  return <button
    className={`menu-item ${selected ? 'active' : ''}`}
  >{text}</button>;
};

// All items component
// Important! add unique key
export const Menu = (list, selected) =>
  list.map(el => {
    const {
      id,
      name,
    } = el;

    return <MenuItem text={name} key={id} selected={selected} />;
  });

export default class extends Component {
  constructor(props) {
    super(props);
    // call it again if items count changes
    this.menuItems = Menu(this.props.cat, this.props.selected);
  }

  static getDerivedStateFromProps(props, state) {
    if ( props.selected !== state.selected) {
      return {
        selected: props.selected || 'all',
      }
    }
  }


  state = {
    selected: this.props.selected || 'all',
  };

  onSelect = key => {
    this.props.onClick(key);
    this.setState({ selected: key });
  };


  render() {
    const { selected } = this.state;
    // Create menu from items
    const menu = this.menuItems;

    const props = {
      onClick: (key) => {this.onSelect(key)},
      selected: selected,
    };

    const aa = menu.map((el) =>
      React.cloneElement(
        el,
        props,
      )
    );

    return (
      <div>
      <StyledScrollMenu>
        {
          menu.map((el) => {
            const props = {
              onClick: (key) => {this.onSelect(key)},
              selected: el.key === selected,
            };

            return (
              <div className='hihiihidid' onClick={()=>{
                props.onClick(el.key)
              }}>
                {
                  React.cloneElement(
                    el,
                    props,
                  )
                }
              </div>
            )
          }
          )
        }

        {/*{*/}
        {/*  aa.map((el) => {*/}
        {/*   return (*/}
        {/*     <div className='hihiihidid' onClick={()=>{*/}
        {/*       el.props.onClick(el.key)*/}
        {/*     }}>*/}
        {/*       {*/}
        {/*         el*/}
        {/*       }*/}
        {/*     </div>*/}
        {/*   )*/}
        {/*  })*/}
        {/*}*/}

      </StyledScrollMenu>
      </div>
    );
  }
}
