import React from 'react';
import styled from 'styled-components';
import RouteHead from '../../components/basic/RouteHeader';
import PrivatePolicyData from '../../data/PrivatePolicyData';

const PolicyContent = styled.div`
  white-space: pre-wrap;
  word-break: break-all;
  font-size: 18px;
  text-align: left;
  color: #484848;
  @media (max-width: 576px) {
    font-size: 13px;
  }
`;

const PrivacyPolicy = () => (
  <div>
    <RouteHead>개인정보 수집·이용 및 제3자 제공</RouteHead>
    <PolicyContent>
      {PrivatePolicyData}
    </PolicyContent>
  </div>
);

export default PrivacyPolicy;
