import React, { Component } from 'react';
import styled from 'styled-components';
import CloseImg from '../images/ic-close-gray-24-px.svg';
import { StyledModal } from './basic/HtmlComponents';
import { media } from '../globalStyle';

const AlertTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
  word-wrap: break-word;
  word-break: keep-all;
  margin: 0px 30px 20px 0;
  ${media.tablet`
    font-size: 20px;
    font-weight: bold;
    line-height: 1.4;
  `};
`;

const AlertContent = styled.div``;

const CloseBtn = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
  top: 32px;
  right: 20px;
  position: absolute;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  ${media.tablet`
    top: 32px;
    right: 20px;
  `};
`;

const AlertMessage = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #000000;
  text-align: ${props => props.textAlign || 'left'};
  white-space: pre-line;
  word-break: keep-all;
  ${media.tablet`
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #8a8a8a;
  `};
`;

const AlertBtnContainer = styled.div`
  margin-top: 30px;
  text-align: right;
`;

const AlertConfirmBtn = styled.button`
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: -1px;
  text-align: center;
  color: #033e52;
  border: 0;
  padding: 0;
  &:hover,
  &:focus {
    outline: none;
  }
  ${media.tablet`
    font-size: 18px;
  `};
`;

const AlertCancelBtn = styled.button`
  padding: 0;
  border-radius: 3px;
  background-color: #ffffff;
  border: none;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #8a8a8a;
  margin-left: 40px;
  &:hover,
  &:focus {
    outline: none;
  }
  @media (max-width: 576px) {
    font-size: 16px;
    padding: 5px;
  }
`;

class AlertModal extends Component {
  render() {
    return (
      <StyledModal
        style={{
          overlay: {
            zIndex: 100,
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
          },
        }}
        ariaHideApp={false}
        isOpen={this.props.isOpen}
      >
        {this.props.closeBtn && (
          <CloseBtn
            src={CloseImg}
            alt="closeImg"
            onClick={this.props.onClose}
          />
        )}
        {this.props.title && (
          <div>
            <AlertTitle>{this.props.title}</AlertTitle>
          </div>
        )}
        <AlertContent>
          {this.props.message && (
            <AlertMessage textAlign={this.props.textAlign}>
              {this.props.message}
            </AlertMessage>
          )}
          {(this.props.showConfirmBtn || this.props.showCancelBtn) && (
            <AlertBtnContainer>
              {this.props.showConfirmBtn && (
                <AlertConfirmBtn
                  isExistAnotherBtn={this.props.showCancelBtn}
                  onClick={this.props.confirmBtnOnClick || this.props.onClose}
                >
                  {this.props.confirmBtnName}
                </AlertConfirmBtn>
              )}
              {this.props.showCancelBtn && (
                <AlertCancelBtn
                  isExistAnotherBtn={this.props.showConfirmBtn}
                  onClick={this.props.cancleBtnOnClick || this.props.onClose}
                >
                  {this.props.cancleBtnName}
                </AlertCancelBtn>
              )}
            </AlertBtnContainer>
          )}
        </AlertContent>
      </StyledModal>
    );
  }
}

export default AlertModal;
