import React from 'react';
import styled, { css } from 'styled-components';

const CssActiveBorder = css`
  border: 1px solid #033e52;
`;

const CssActiveFont = css`
  //font-weight: 600;
  font-weight: bold;
`;

const CssDisabledButton = css`
  font-weight: 600;
  color: rgba(255, 255, 255, .5)
`;

const StyledStretchButton = styled.button.attrs(props => ({
  borderColor: props.white ? '#e1e3e2' : '#033e52',
  bgColor: props.white ? '#fff' : '#033e52',
  color: props.white ? '#000' : '#fff',
  radius: props.fixed ? '0' : '4px',
}))`
  width: 100%;
  height: inherit;
  border-radius: ${props => props.radius};
  color: ${props => props.color};
  border: solid 1px ${props => props.borderColor};
  background-color: ${props => props.bgColor};
  ${props => props.disabled && CssDisabledButton}
  ${props => props.active && CssActiveBorder};
  ${props => props.active && CssActiveFont};
  &.fs-14 div{
    font-size: 14px;
  }
`;

const StyledTextButton = styled.div`
  width: 100%;
  text-align: center;
  font-size: 13px;
  letter-spacing: -.6px;
`;

const StretchButton = ({ children, ...others }) => (
  <StyledStretchButton {...others}>
    <StyledTextButton>{children}</StyledTextButton>
  </StyledStretchButton>
);

export default StretchButton;
