/*
 * Using ref -> https://reactjs.org/docs/refs-and-the-dom.html
 */

import React from 'react';

export const Input = ({
  className, value, name, type, onChange, placeholder,
}) => {
  const textInput = React.createRef();

  const handleChange = () => {
    onChange(textInput.current.value);
  };

  return (
    <input
      className={className}
      value={value}
      placeholder={placeholder}
      name={name || 'inputText'}
      type={type || 'text'}
      ref={textInput}
      {...(onChange && { onChange: handleChange })}
    />
  );
};

export const InputRef = ({
  className, inputRef, name, type, onChange, ...others
}) => (
  <input
    className={className}
    name={name || 'inputText'}
    type={type || 'text'}
    ref={inputRef}
    {...(onChange && { onChange })}
    {...others}
  />
);
