import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import SignupForm from '../components/form/SignupForm';
import { AuthContext } from '../components/AuthProvider';
import { FormTitle, AuthFormPageContainer, FormInputContainer } from '../components/form/FormCommonComponent';

class Signup extends React.Component {
  login = async (email, password) => {
    const {
      login,
    } = this.context;
    const {
      history,
      location,
    } = this.props;
    const prePage = location.state?.back;

    if (email && password) {
      // mutation
      try {
        const loginRes = await login(email, password);

        const { ok } = loginRes;

        if (ok) {
          // login 전에 방문한 페이지로 redirect
          if (prePage) {
            history.push(location.state.back);
          } else {
            history.push('/');
          }
        }
        return loginRes;
      } catch (err) {
        throw err;
      }
    }
  }

  render() {
    const {
      isLoggedIn,
      regist,
      logout,
    } = this.context;
    const {
      location,
      history,
    } = this.props;
    if (isLoggedIn) {
      return <Redirect to={{ pathname: '/' }} />;
    }

    return (
      <AuthFormPageContainer>
        <div>
          <FormTitle>회원가입</FormTitle>
        </div>
        <FormInputContainer>
          <SignupForm
            {...this.props}
            login={this.login}
            regist={regist}
            logout={logout}
            goToLogin={() => history.push('/login', location.state)}
          />
        </FormInputContainer>
      </AuthFormPageContainer>
    );
  }
}

const withFunc = withRouter(Signup);
Signup.contextType = AuthContext;
export default withFunc;
