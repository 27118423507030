import styled from 'styled-components';

const Cell = styled.div`
  padding: 0 5px;
  width: ${props => 100 / (props.cols || 2)}%;
  flex-direction: ${props => props.column && 'column'};
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: #fff;
`;

export default Cell;
