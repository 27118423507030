import React, { useState } from 'react';
import styled from 'styled-components';
import { media } from '../../globalStyle';
import { StyledModal } from '../basic/HtmlComponents';
import ModalCloseIcon from '../../images/ic-close-24.svg';
import ArrowImg from '../../images/ic-more-gray-24.png';
import { logEvent } from '../../utils/loging';

const ReservationConfirmModal = styled(StyledModal)`
  height: auto;
  padding: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  border: 0;
  border-radius: 0;
  width: 380px;
  ${media.tablet`
    width: 100%;
    bottom: 0;
    transform: none;
    top: unset;
    top: auto;
    left: 0;
    padding: 0;
    display: flex;
    flex: 1;
    flex-direction: column;
    border: 0;
    border-radius: 0;
  `};
`;

const ModalHeader = styled.div`
  padding: 20px 20px 0;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
  ${media.tablet`
    padding: 20px;
    font-size: 16px;
    color: #033e52;
  `}
`;

const ModalCloseBtn = styled.button`
  border: 0;
  padding: 0;
  float: right;
  vertical-align: middle;
  img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    cursor: pointer;
  }
  ${media.tablet`
    img {
      width: 20px;
      height: 20px;
    }
  `};
`;

const ModalContent = styled.div`
  padding: 4px 20px 30px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.6px;
  color: #000000;
  word-break: keep-all;
  height: 100%;
  position: relative;
  ${media.tablet`
    line-height: 0.86;
    letter-spacing: -0.8px;
    padding: 20px;
    min-height: 296px;
  `};
`;

const Form = styled.div`
  margin-top: 40px;
  div ~ div {
    margin-top: 30px;
  }
  select {
    margin-top: 10px;
    display: block;
    width: 100%;
    height: 48px;
    border-radius: 1px;
    border: solid 1px #e0e0e0;
    background-color: #ffffff;
    padding: 14px 8px;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.8px;
    color: #000000;
    background-image: url(${ArrowImg});
    background-position-x: 96%;
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    ::-ms-expand {
      display: none;
    }
    &:active, &:focus {
      outline: none;
    }
  }
  textarea {
    margin-top: 10px;
    width: 100%;
    height: 88px;
    border-radius: 1px;
    border: solid 1px #e0e0e0;
    background-color: #ffffff;
    resize: none;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.8px;
    color: #000000;
    padding: 10px 8px;
  }
  ${media.tablet`
    div ~ div {
      margin-top: 20px;
    }
  `};
`;

const ModalBtn = styled.button`
  bottom: 0;
  width: 100%;
  min-height: 52px;
  padding: 14px 0;
  background-color: #033e52;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  text-align: center;
  color: #ffffff;
  ${media.tablet`
    min-height: 64px;
    padding: 20px 0;
    line-height: 1.5;
    letter-spacing: -0.9px;
  `};
`;

export default ({
  isOpen, onClose, onSubmit,
}) => {
  const [availtime, setAvailtime] = useState('언제나 가능');
  const [requirements, setRequirements] = useState('');
  return (
    <ReservationConfirmModal
      style={{
        overlay: {
          width: '100%',
          zIndex: 100,
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.85)',
          overflow: 'auto',
        },
      }}
      ariaHideApp={false}
      isOpen={isOpen}
      shouldCloseOnOverlayClick
      onRequestClose={onClose}
    >
      <ModalHeader>
        예약하기
        <ModalCloseBtn onClick={onClose}>
          <img src={ModalCloseIcon} alt="ModalCloseIcon" />
        </ModalCloseBtn>
      </ModalHeader>
      <ModalContent>
        <div>
          예약 시 병원에 전달할 정보를 입력해주세요.
        </div>
        <Form>
          <div>
            연락 가능시간
            <select value={availtime} onChange={({ target }) => setAvailtime(target.value)}>
              <option value="언제나 가능" defaultChecked>언제나 가능</option>
              <option value="10:00 ~ 12:00">10:00 ~ 12:00</option>
              <option value="12:00 ~ 14:00">12:00 ~ 14:00</option>
              <option value="14:00 ~ 16:00">14:00 ~ 16:00</option>
              <option value="16:00 ~ 18:00">16:00 ~ 18:00</option>
              <option value="18:00 ~ 20:00">18:00 ~ 20:00</option>
            </select>
          </div>
          <div>
            요청사항
            <textarea placeholder="병원에 추가로 요청하실 내용을 남겨주세요" value={requirements} onChange={({ target }) => setRequirements(target.value)} />
          </div>
        </Form>
      </ModalContent>
      <ModalBtn onClick={() => {
          onSubmit(requirements, availtime);
          onClose();
          logEvent({
            category: '답변(병원) 상세',
            action: '클릭',
            label: '예약하기(팝업)',
          });
        }}
      >
        예약하기
      </ModalBtn>
    </ReservationConfirmModal>
  );
};
