import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import { MyPageRouteHead } from '../../components/basic/RouteHeader';
import { MyRequestsQuery } from '../../graphql/index';
import { therapyInfoById } from '../../components/form/therapyInfo';
import { media } from '../../globalStyle';
import { getOrthoPartName } from '../../utils/req-utils';
import StyledPagination from '../../components/basic/StyledPagination';
import { logEvent } from '../../utils/loging';

const CounselWapper = styled.div`
  cursor: pointer;
  width: 100%;
  border: solid 1px #e0e0e0;
  border-top: 0;
  background-color: #ffffff;
  position: relative;
  & ~ & {
    margin-top: 10px;
  }
  ${media.tablet`
    border: 0;
    & ~ & {
      margin-top: 0;
    }
    &:last-of-type {
      border-bottom: solid 1px #e0e0e0;
    }
  `};
`;

const CounselRect = styled.div`
  padding: 14px 20px 30px;
  ${media.tablet`
    padding: 12px 0 40px;
  `};
`;

const CounselHeaderLine = styled.div`
  width: 100%;
  height: 4px;
  background-color: ${props => (props.active ? '#033e52' : '#bbbcbc')};
`;

const CounselHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding-bottom: 14px;
  margin-bottom: 20px;
  border-bottom: solid 1px #e0e0e0;
  > div {
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.8px;
    color: #bbbcbc;
  }
  ${props =>
    props.active &&
    `
    > div:first-of-type {
      color: #033e52;
    }
    > div:last-of-type {
      color: #000000;
    }
  `};
  ${media.tablet`
    padding-bottom: 12px;
    margin-bottom: 14px;
  `};
`;

const CounselDesc = styled.div`
  position: relative;
  font-size: 18px;
  ${media.tablet`
    
  `};
`;

const CounselDate = styled.div`
  float: right;
  min-width: 130px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #000000;
  ${media.tablet`
    float: none;
    margin-bottom: 10px;
    text-align: left;
  `};
`;

const Item = styled.div`
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.9px;
  color: #000000;
  
  &::after {
    content: '';
    height: 14px;
    border-right: 1px solid #e1e3e2;
    display: inline-block;
    margin: 0px 10px;
    vertical-align: middle;
  }

  &:last-of-type {
    &::after {
      content: none;
    }
  }
  
  ${media.tablet`
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.9px;
    color: #000000;
  `};
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.9px;
  color: #000000;
  margin-bottom: 6px;
  ${media.tablet`
    font-size: 14px;
    letter-spacing: -0.8px;
    margin-bottom: 6px;
  `};
`;

const TheraphyContainer = styled.div`
  & ~ & {
    margin-top: 21px;
  }
  ${media.tablet`
    & ~ & {
      margin-top: 14px;
    }
  `}
`;

const AddReqBtn = styled.div`
  width: 100%;
  background-color: #ffffff;
  padding: 0;
  margin-bottom: 60px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  ${media.tablet`
    margin-top: 20px;
    margin-bottom: 60px;
  `};
`;

const LeftInfo = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -0.8px;
  color: #033e52;
  ${media.tablet`
    br {
      display: none;
    }
  `};
`;

const RightBtn = styled.button`
  min-width: 246px;
  height: 52px;
  border-radius: 1px;
  background-color: #033e52;
  display: inline-block;
  border: none;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  text-align: center;
  color: #ffffff;
  padding: 0;
  position: relative;
  cursor: pointer;
  ${media.tablet`
    margin-top: 20px;
    width: 100%;
  `};
`;

const ListSection = styled.div`
  & ~ & {
    margin-top: 60px;
  }
`;

const ListTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: -1px;
  color: #000000;
  margin-bottom: 20px;
  span {
    float: right;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: -1px;
    text-align: right;
    color: #000000;
    margin-top: 4px;
  }
`;

const PaginationWapper = styled.div`
  padding: 34px 0;
  ${media.tablet`
    border-top: 1px solid #e0e0e0;
  `};
`;

const ITEMS_PER_PAGE = 5;

// 마이페이지 - 상담내역
class MyCounselList extends Component {
  gotoHslist = (reqId, isDirect) => {
    // 견적 요청 id 가 있는 경우 해당 routing
    if (reqId) {
      this.props.history.push({
        pathname: `/userreq/${reqId}`,
        state: {
          isDirect,
        },
      });
    } else {
      // ERROR 처리
      // this.props.history.push('/mypage/counsel'); - 같은 페이지야..
    }
  };

  render() {
    return (
      <div>
        <AddReqBtn>
          <LeftInfo>
            지금 바로 상담을 통해 우리동네 치과들의 소견과 <br />
            예상비용을 한눈에 확인하세요.
          </LeftInfo>
          <RightBtn
            onClick={() => {
              this.props.history.push({
                pathname: '/',
                state: {
                  mode: 'quick',
                },
              });
              logEvent({
                category: '상담내역',
                action: '클릭',
                label: '상담신청',
              });
            }}
          >
            상담 받기
          </RightBtn>
        </AddReqBtn>
        <div>
          {/* 진행중인 상담 */}
          <Query query={MyRequestsQuery} variables={{ status: 'published' }}>
            {({ loading, error, data }) => {
              if (
                !loading &&
                data &&
                data.myRequests.myRequests &&
                data.myRequests.myRequests.length > 0
              ) {
                const publishedReqs = data.myRequests.myRequests;
                return (
                  <ListSection>
                    <ListTitle>진행중인 상담</ListTitle>
                    <div>
                      {publishedReqs.map(req => (
                        <CounselWapper key={`myCounselList_${req.id}`}>
                          <CounselHeaderLine active />
                          <CounselRect
                            onClick={() => {
                              this.gotoHslist(
                                req.id,
                                req.directReq,
                              );
                              logEvent({
                                category: '상담내역',
                                action: '클릭',
                                label: '진행중인 상담 목록',
                              });
                            }}
                            key={req.id}
                          >
                            {req.directReq ? (
                              <CounselHeader active>
                                <div>다이렉트 상담</div>
                                <div>
                                  {req.hosResps &&
                                  req.hosResps.length > 0 &&
                                  req.directReq.hospital
                                    ? `${req.directReq.hospital.name} 답변완료`
                                    : `${
                                        req.directReq.hospital.name
                                      } 답변 대기중`}
                                </div>
                              </CounselHeader>
                            ) : (
                              <CounselHeader active>
                                <div>맞춤상담</div>
                                <div>
                                  {req.hosResps && req.hosResps.length > 0
                                    ? `${req.hosResps.length}개 답변 비교중`
                                    : '답변 대기중'}
                                </div>
                              </CounselHeader>
                            )}
                            <CounselDesc>
                              <CounselDate active>
                                {moment(req.createdAt).format('YYYY.MM.DD(ddd)')}
                              </CounselDate>
                              <div>
                                {(req.reqType === 'all' ||
                                  req.reqType === 'tx') && (
                                  <TheraphyContainer>
                                    <Title>일반 치료</Title>
                                    <div>
                                      {req.txItems &&
                                        req.txItems.length > 0 &&
                                        req.txItems.map((data, idx) => (
                                          <Item key={`tx_${idx}`}>
                                            {
                                              therapyInfoById[data.itemcode]
                                                .viewName
                                            }
                                            {data.unit > 1
                                              ? ` ${data.unit}개`
                                              : ''}
                                          </Item>
                                        ))}
                                    </div>
                                  </TheraphyContainer>
                                )}
                                {(req.reqType === 'all' ||
                                  req.reqType === 'ortho') && (
                                  <TheraphyContainer>
                                    <Title>교정 치료</Title>
                                    <div>
                                      {req.orthoItems &&
                                        req.orthoItems.length > 0 &&
                                        req.orthoItems.map((data, idx) => (
                                          <Item key={`ortho_${idx}`}>
                                            {getOrthoPartName(data.part)}-
                                            {
                                              therapyInfoById[data.itemcode]
                                                .viewName
                                            }
                                          </Item>
                                        ))}
                                      {req.orthoWanna.length > 0 &&
                                        req.orthoWanna.map(str => (
                                          <Item key={str}>{str}</Item>
                                        ))}
                                    </div>
                                  </TheraphyContainer>
                                )}
                              </div>
                            </CounselDesc>
                          </CounselRect>
                        </CounselWapper>
                      ))}
                    </div>
                  </ListSection>
                );
              }

              return null;
            }}
          </Query>
          {/* 종료된 상담 */}
          <Query
            query={MyRequestsQuery}
            variables={{
              status: 'terminated',
              perPage: ITEMS_PER_PAGE,
              currentPage: 1,
            }}
          >
            {({
              loading, error, data, fetchMore,
              }) => {
              if (data && data.myRequests && data.myRequests.myRequests &&
                data.myRequests.myRequests.length > 0
              ) {
                const { myRequests, pagination } = data.myRequests;

                return (
                  <ListSection>
                    <ListTitle>
                      종료된 상담
                      <span>
                        총 <b>{pagination.total || '0'}</b>건
                      </span>
                    </ListTitle>
                    <div>
                      {myRequests.map(req => (
                        <CounselWapper key={`myCounselList_${req.id}`}>
                          <CounselHeaderLine active={false} />
                          <CounselRect
                            onClick={() => {
                              this.gotoHslist(
                                req.id,
                                req.directReq,
                              );
                              logEvent({
                                category: '상담내역',
                                action: '클릭',
                                label: '종료된 상담 목록',
                              });
                            }}
                            key={req.id}
                          >
                            {req.directReq ? (
                              <CounselHeader active={false}>
                                <div>다이렉트 상담</div>
                                <div>
                                  {req.hosResps &&
                                  req.hosResps.length > 0 &&
                                  req.directReq.hospital
                                    ? `${req.directReq.hospital.name} 답변완료`
                                    : `${req.directReq.hospital.name} 답변없음`}
                                </div>
                              </CounselHeader>
                            ) : (
                              <CounselHeader active={false}>
                                <div>맞춤상담</div>
                                <div>
                                  {req.hosResps && req.hosResps.length > 0
                                    ? `${req.hosResps.length}개 답변 비교중`
                                    : '답변없음'}
                                </div>
                              </CounselHeader>
                            )}
                            <CounselDesc>
                              <CounselDate>
                                {moment(req.createdAt).format('YYYY.MM.DD(ddd)')}
                              </CounselDate>
                              <div>
                                {(req.reqType === 'all' ||
                                  req.reqType === 'tx') && (
                                  <TheraphyContainer>
                                    <Title>일반 치료</Title>
                                    <div>
                                      {req.txItems &&
                                        req.txItems.length > 0 &&
                                        req.txItems.map((data, idx) => (
                                          <Item key={`tx_${idx}`}>
                                            {
                                              therapyInfoById[data.itemcode]
                                                .viewName
                                            }
                                            {data.unit > 1
                                              ? ` ${data.unit}개`
                                              : ''}
                                          </Item>
                                        ))}
                                    </div>
                                  </TheraphyContainer>
                                )}
                                {(req.reqType === 'all' ||
                                  req.reqType === 'ortho') && (
                                  <TheraphyContainer>
                                    <Title>교정 치료</Title>
                                    <div>
                                      {req.orthoItems &&
                                        req.orthoItems.length > 0 &&
                                        req.orthoItems.map((data, idx) => (
                                          <Item key={`ortho_${idx}`}>
                                            {getOrthoPartName(data.part)}-
                                            {
                                              therapyInfoById[data.itemcode]
                                                .viewName
                                            }
                                          </Item>
                                        ))}
                                      {req.orthoWanna.length > 0 &&
                                        req.orthoWanna.map(str => (
                                          <Item key={str}>{str}</Item>
                                        ))}
                                    </div>
                                  </TheraphyContainer>
                                )}
                              </div>
                            </CounselDesc>
                          </CounselRect>
                        </CounselWapper>
                      ))}
                      <PaginationWapper>
                        <StyledPagination
                          activePage={pagination.currentPage}
                          itemsCountPerPage={ITEMS_PER_PAGE}
                          totalItemsCount={pagination.total}
                          pageRangeDisplayed={3}
                          onChange={page =>
                            fetchMore({
                              variables: { currentPage: page },
                              updateQuery: (previousResult, { fetchMoreResult }) => {
                                if (!fetchMoreResult) {
                                  return previousResult;
                                }
                                return Object.assign({}, previousResult, {
                                  myRequests: fetchMoreResult.myRequests,
                                });
                              },
                            })
                          }
                        />
                      </PaginationWapper>
                    </div>
                  </ListSection>
                );
              }

              return null;
            }}
          </Query>
        </div>
      </div>
    );
  }
}

export default withRouter(MyCounselList);
