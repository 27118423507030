export const draftData = {
  'wantLevel': [
    {
      title: '<b>최대한 빠른 치료</b>를 원해요.',
      value: 5,
    },
    {
      title: '치료가 필요하지만 <b>급하진 않아요.</b>',
      value: 3,
    },
    {
      title: '<b>천천히 알아보는 중</b>이에요.',
      value: 1,
    }
  ]
};

export const faqList = [
  {
    title: '오닥터는 어떤 서비스인가요?',
    content: '여러 병원에 방문하고 비교할 필요 없이, 오닥터는 한 번에 여러 병원에게 상담을 받고 나에게 맞는 병원을 방문할 수 있는 온라인 상담실입니다.',
  },
  {
    title: '상담은 어떻게 진행되나요?',
    content: '방문 가능한 지역과 간단한 상담정보를 입력하시면 상담 신청이 완료되며, 곧 오닥터 파트너병원들의 답변을 받아보실 수 있습니다.',
    component: {
      type: 'click',
      children: '오닥터의 상담진행 절차 알아보기',
    },
  },
  {
    title: '상담 비용을 따로 내야 하나요?',
    content: '오닥터는 완전 무료 서비스로, 아무 비용 없이 상담받을 수 있습니다.',
  },
  {
    title: '병원의 답변을 받기까지 얼마나 걸리나요?',
    content: '요청하신 상담은 파트너 병원으로 즉시 전달되어, 실시간으로 답변이 등록됩니다. 다만 주말이나 야간 등 병원 진료시간이 아닐 경우 답변 등록이 다소 늦어질 수 있습니다.',
  },
  {
    title: '답변해주는 병원은 믿을 만한가요?',
    content: '오닥터의 파트너병원은 오닥터에서 직접 방문하여 전문성과 시설, 서비스 등을 모두 검토한 병원들로, 신뢰할 수 있습니다.',
  },
  {
    title: '방문했더니 병원에서 답변 내용과 다르게 치료비를 말하면 어떻게하나요?',
    content:
      '병원에서 답변을 통해 제시하는 치료비는 입력하신 설명과 사진을 토대로 추산한 예상 치료비로, 방문 후 진료 결과에 따라 실제 치료비는 다소 조정될 수 있습니다.'
      + ' 만약 치료비가 조정될 경우, 조정사유에 대해 자세하게 설명드릴 것이며 진행을 원치 않으실 경우 치료를 받지 않으셔도 무방합니다.',
  },
];

export const liveReviewList = [
  {
    rating:4,
    comment:'장마 때 비 한방울 맞지 않고 집에서 편히 상담 받아 봤슴다. 발품 하나두 안팔구 시간두 아껴서 좋았어유!!!',
    name:'오*훈 님'
  },
  {
    rating:5,
    comment:'바빠서 치과 못가다가 사진으로 상담 받을 수 있대서 해봤는데, 근처 치과에서 바로 답변 받아서 그날 치료까지 받았음',
    name:'임*주 님'
  },
  {
    rating:5,
    comment:'병원 이벤트? 이런거 신청하면 광고 문자만 계속 오는데, 그런거 없이 딱 예약한 곳이랑만 연락할 수 있어서 쓸만한듯',
    name:'김*빈 님'
  },
  {
    rating:4,
    comment:'치과 갈때마다 그냥 하라는대로 다 했었는데.. 가기 전에 미리 알아볼 수 있는 것만으로도 쓸 만하다고 생각합니다',
    name:'서*헌 님'
  },
  {
    rating:4,
    comment:'어차피 구체적인 건 치과 가봐야 알 수 있다고 하지만... 적어도 뭐가 얼마인지 알고 가서 얘기하기 훨씬 편했음.',
    name:'유*현 님'
  },
  {
    rating:5,
    comment:'막 보험처럼 개인정보 여기저기 안 팔아먹고 안전하게 견적 비교할 수 있어서 너무 좋았어요. 덕분에 싸게 잘 했네요.',
    name:'허*식 님'
  },
  {
    rating:5,
    comment:'솔직히 임플란트 조금 이라도 싸게 해보려고 써봤는데 오히려 가격보단 퀄리티에 200프로 만족합니다. 번창하세요.',
    name:'서*성 님'
  },
  {
    rating:5,
    comment:'다른건 몰라도 교정은 특히 미리 꼭 알아보세요 대만족중ㅋㅋ 무턱대고 친구말만 믿구 쫓아갔다가 클날뻔ㅋㅋ 흥하세요',
    name:'박*영 님'
  },
  {
    rating:5,
    comment:'어떤 치과를 가야되나 막막했는데 치아상태만 쓰니 여러곳에서 상담해 주더라고요. 한곳 찜해서 바로 고고씽하려고요^^',
    name:'최*정 님'
  },
  {
    rating:5,
    comment:'솔직히 치과를 인터넷으로 알아 보고 가는게... 음... 근데 확실히 비교도 되고, 시간도 절약되서 너무 좋았음~!',
    name:'김*호 님'
  },{
    rating:4,
    comment:'회사 근처 치과 검색하다 친구 추천으로 써봤는데, 덕분에 훠월씬 저렴하고 친절한 병원 찾은거 같아요. 일단 추천',
    name:'이*빈 님'
  },{
    rating:5,
    comment:'서울로 이사온 지 얼마 안되서 치과를 어디로 가야할지 막막했는데 덕분에 괜찮은 곳을 찾아서 앞으로 쭉 다니려구요.',
    name:'이*민 님'
  },{
    rating:5,
    comment:'교정 때매 전화해서 물어보면 자세하게 안 알려주는데 한번에 치료 방법이랑 치료비까지 비교할 수 있어서 짱 좋음.',
    name:'최*정 님'
  },{
    rating:5,
    comment:'그동안 치과 찾느라 발품 판게 부질 없었네요, 바쁜 직장인에게 정말 추천하는 편리한 서비스입니다 개발자님 짱짱맨!',
    name:'허*민 님'
  }
];

export const featureText = [
  {
    title: '쉬워요',
    text: ''
  }
];

// todo : 우선 데이터는 이렇게 하고 되도록 서버에서 받아서 쓰는 걸로
export const si = [{
  id: '11',
  name: '서울',
},{
  id: '41',
  name: '경기',
},{
  id: '28',
  name: '인천',
}];

export const wantLevelText = {
  0: '치과 치료를 원해요',
  1: '치과지료를 알아보는 중이에요.',
  2: '',
  3: '급하진 않지만 치과치료가 필요해요.',
  4: '',
  5: '최대한 빨리 치과치료를 원해요.',
};

export const getWantLevelText = (level = 0) => (
  wantLevelText[level]
);
