import React from 'react';
import * as Sentry from '@sentry/browser';
import { Redirect } from 'react-router-dom';

class CommonErrorBoundary extends React.Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    // local test 일때는 error 로깅하지 않는다.
    if (process.env.REACT_APP_NODE_ENV !== 'development') {
      Sentry.withScope((scope) => {
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, errorInfo[key]);
        });

        Sentry.captureException(error);
      });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <Redirect
          to={{
            pathname: '/errors',
          }}
        />
      );
    }
    return this.props.children;
  }
}

export default CommonErrorBoundary;
