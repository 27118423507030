import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { ApolloLink, from } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';
import moment from 'moment';
import ReactGA from 'react-ga';
import * as Sentry from '@sentry/browser';

import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';

import 'react-image-gallery/styles/css/image-gallery.css';
import 'react-toastify/dist/ReactToastify.css';
import 'rc-pagination/assets/index.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { IntlProvider, addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import ko from 'react-intl/locale-data/ko';
import koreanString from './locale/ko';
// import englishString from './locale/en';

import Routes from './routes';
// import AuthProvider from './components/AuthProvider';
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';
// import { initialize } from './utils/channel';
import { logout } from './utils/auth';
import OdocToastContainer from './utils/OdocToastContainer';
import './styles/bootstrap/css/bootstrap.min.css';
import GlobalStyle from './globalStyle';
import cleanTypenameLink from './utils/cleanTypenameLink';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

global.Intl = require('intl');

// Google Analytics
ReactGA.initialize('UA-128513215-1');

Sentry.init({
  dsn: 'https://ef9aaefae2ae49f991c34d36085cee02@sentry.io/1319424',
});

const BACKEND_URL = process.env.REACT_APP_API_URL;
const httpLink = new HttpLink({ uri: `${BACKEND_URL}/graphql` });

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'x-token': localStorage.getItem('token'),
      'x-refresh-token': localStorage.getItem('refreshToken'),
    },
  }));

  return forward(operation);
});

const afterwareLink = new ApolloLink((operation, forward) =>
  forward(operation).map((response) => {
    const {
      response: { headers },
    } = operation.getContext();
    if (headers) {
      const token = headers.get('x-token');
      const refreshToken = headers.get('x-refresh-token');

      if (token) {
        localStorage.setItem('token', token);
      }

      if (refreshToken) {
        localStorage.setItem('refreshToken', refreshToken);
      }
    }

    return response;
  }));

const errorHandleLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (message === 'Not authenticated') {
        alert('로그인이 필요한 기능입니다.');
        logout();
        document.location.href = '/';
      }

      if (message === 'NotFoundError') {
        alert('서버에 문제가 있습니다.');
        return;
      }
    });
  }

  if (networkError && networkError.statusCode === 401) {
    logout();
    document.location.href = '/';
  }
});

// Apollo Client default options (network-only 지원하려고 넣음 2018.2/17 hko)
const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

const client = new ApolloClient({
  link: from([authMiddleware, afterwareLink, cleanTypenameLink, errorHandleLink.concat(httpLink)]),
  cache: new InMemoryCache(),
  defaultOptions,
});

// moment js locale 설정.
moment.locale('kr', {
  months: '1월_2월_3월_4월_5월_6월_7월_8월_9월_10월_11월_12월'.split('_'),
  monthsShort: '1월_2월_3월_4월_5월_6월_7월_8월_9월_10월_11월_12월'.split('_'),
  weekdays: '일요일_월요일_화요일_수요일_목요일_금요일_토요일'.split('_'),
  weekdaysShort: '일_월_화_수_목_금_토'.split('_'),
  weekdaysMin: '일_월_화_수_목_금_토'.split('_'),
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD/MM/YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'D MMMM YYYY LT',
    LLLL: 'dddd D MMMM YYYY LT',
  },

  ordinal(number) {
    return `${number}일`;
  },
});

// 국제화 설정. (react-intl)
addLocaleData([...en, ...ko]);

// channel initialize
// initialize();

const App = (
  <React.Fragment>
    <IntlProvider locale="ko" messages={koreanString}>
      <ApolloProvider client={client}>
        <Routes />
      </ApolloProvider>
    </IntlProvider>
    <OdocToastContainer />
    <GlobalStyle />
  </React.Fragment>
);

ReactDOM.render(App, document.getElementById('root'));
unregister();
