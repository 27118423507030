import React from 'react';
import styled from 'styled-components';
import minusActiveBtn from '../../images/btn-minus-blue-32.png';
import plusActiveBtn from '../../images/btn-plus-blue-32.png';
import minusBtn from '../../images/btn-minus-gray-32.png';
import plusBtn from '../../images/btn-plus-gray-32.png';

const CtnBtn = styled.img`
  width: 30px;
  height: 30px;
  object-fit: contain;
  cursor: pointer !important;
`;

const CurCount = styled.div`
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: ${props => (props.active ? '#033e52' : '#bcbcbc')};
`;

const Counter = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

class PlusMinusCounter extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this); // Lifting state up
  }

  handleChange(e) {
    let value = this.props.currentCount;

    if (e.target.name === 'minus') {
      value = value > 0 ? value - 1 : 0;
    } else if (e.target.name === 'plus') {
      value += 1;
    }

    this.props.onCounterChange(value);
  }

  render() {
    const { currentCount } = this.props;
    return (
      <Counter>
        <CtnBtn
          onClick={this.handleChange}
          name="minus"
          src={currentCount > 0 ? minusActiveBtn : minusBtn}
          alt="minus"
        />
        <CurCount active={currentCount > 0}>{currentCount}</CurCount>
        <CtnBtn
          onClick={this.handleChange}
          name="plus"
          src={currentCount > 0 ? plusActiveBtn : plusBtn}
          alt="plus"
        />
      </Counter>
    );
  }
}

export default PlusMinusCounter;
