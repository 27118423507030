import gql from 'graphql-tag';

export const UserReviewListQuery = gql`
  query($hospitalId: ID, $doctorId: ID, $currentPage: Int, $perPage: Int){
    userReviewList(perPage:$perPage, currentPage:$currentPage, hospitalId:$hospitalId, doctorId: $doctorId){
      reviews{
        id,
        user{
          id,
          username,
        },
        doctor{
          id,
          name
        },
        reservation{
          id,
          ureqId,
          hospitalId,
          state,
        },
        desc,
        jsondata,
        status,
        score1,
        score2,
        score3,
        score4,
        createdAt,
      },
      pagination{
        total,
        perPage,
        currentPage,
        lastPage
      }
    }
  }  
`;

export const HospitalReviewsQuery = gql`
  query($hospitalId: ID, $currentPage: Int, $perPage: Int){
    hospitalReviews(filter: {hospitalId: $hospitalId}, perPage: $perPage, currentPage: $currentPage) {
      data {
        id #리뷰아이디
        hospital {
          id
          name
        }
        content #내용
        reviewerName #리뷰작성자
        reviewDate #리뷰날짜
        referenceDescription #리뷰출처
        rating #점수
      }
      pagination {
        total
        perPage
      }
    }
#    getHospital(id: $hospitalId) {
#      id
#      avgRating # 병원 리뷰 평점
#      reviews {
#        id
#        reviewerName #리뷰작성자
#        rating #점수
#        content #리뷰 내용
#        reviewDate #리뷰날짜
#      }
#    }
  }
`;

export const reviewAndCaseQuery = gql`
  query($hospitalId: ID, $currentPage: Int, $perPage: Int, $therapyCategoryId: ID,){
    hospitalReviews(filter: {hospitalId: $hospitalId}, perPage: $perPage, currentPage: $currentPage) {
      data {
        id #리뷰아이디
        hospital {
          id
          name
        }
        content #내용
        reviewerName #리뷰작성자
        reviewDate #리뷰날짜
        referenceDescription #리뷰출처
        rating #점수
      }
      pagination {
        total 
        perPage
      }
    }
    reqCases(filter: {therapyCategoryId: $therapyCategoryId, hospitalId: $hospitalId}, perPage: $perPage, currentPage: $currentPage) {
      data {
        id
        location { # 사례 지역
          id
          siName
          gunguName
        }
        title
        # content
        contentSummary (maxLength: 150)
        userGender # 사례자 이름
        userAgeGroup # 사례자 나이대
        reqDate  # 사례 날짜
        categories {  # 사례 카테고리 리스트
          id
          name  # 사례 카테고리 이름
        }
        casePhotos { # 사례 사진
          id
          url
        }
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const submitUserReviewMutation = gql`
  mutation(
    $hospitalId: ID
    $doctorId: ID
    $reservationId: ID
    $desc: String
    $score1: Int
    $score2: Int
    $score3: Int
    $score4: Int
    $score5: Int
    $jsondata: JSON
  ) {
    submitUserReview(
      hospitalId: $hospitalId
      doctorId: $doctorId
      reservationId: $reservationId
      desc: $desc
      score1: $score1
      score2: $score2
      score3: $score3
      score4: $score4
      score5: $score5
      jsondata: $jsondata
    ) {
      id
      desc
      status
      createdAt
      updatedAt
    }
  }
`;
