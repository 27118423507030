import moment from 'moment';

// example: 2020.01.01
export const OdocDateFomatter = (date) => {
  if (!date) {
    return '';
  }

  return moment(date).format('YYYY.MM.DD');
};

// example: 2020년 01월 01일
export const OdocDateFomatter2 = (date) => {
  if (!date) {
    return '';
  }

  return moment(date).format('YYYY년 MM월 DD일');
};

export default {};
