// style-utils.js - styled component 용 util
// media query 등을 쉽게 사용하기 위해 사용한다.
// https://github.com/styled-components/styled-components/blob/master/docs/tips-and-tricks.md

import { css } from 'styled-components';

const sizes = {
  giant: 1170,
  desktop: 992,
  tablet: 768,
  phone: 376,
};

// iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce((accumulator, label) => {
  // use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  const emSize = sizes[label] / 16;
  accumulator[label] = (...args) => css`
    @media (max-width: ${emSize}em) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {});

// 글자 '...' 으로 줄여주는 유틸.
export function truncate(width) {
  return `
    width: ${width};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;
}

export const fixBody = () => {
  // 모달 정책 - 같은 자리에서 scroll 만 안되도록 바뀜. 아래 3개의 주석을 풀 경우 스크롤이 맨 위로 올라가는데
  // 고객 입장에서 갑자기 화면이 바뀌는 것 처럼 보여서 불편을 유발할 수 있다.
  if (document && document.body && document.body.style) {
    // document.body.style.position = 'fixed';
    document.body.style.overflow = 'hidden';
    // document.body.style.width = '100%';
    // document.body.style.height = '100%';
  }
};

export const unfixBody = () => {
  if (document && document.body && document.body.style) {
    document.body.style.position = 'static';
    document.body.style.overflow = 'visible';
    document.body.style.width = 'auto';
    document.body.style.height = 'auto';
  }
};
