import React from 'react';
import FormInput from './FormInput';

class PhonenumInput extends React.Component {
  onChange = (e) => {
    const { value } = e.target;
    if (value.match(/^\d+$/) || value === '') {
      this.props.onChange(e);
    }
  };

  render() {
    const {
      name, value, placeholder, fireSubmitValidation, onBlur, errorMsg, readOnly, disabled,
    } = this.props;

    let phoneValidationMsg = '';

    if (value && fireSubmitValidation) {
      if (value.length !== 10 && value.length !== 11) {
        phoneValidationMsg = '올바른 휴대폰 번호를 입력해주세요.';
      }
    }

    return (
      <React.Fragment>
        <FormInput
          /*autoFocus*/
          type="tel"
          name={name}
          onChange={this.onChange}
          value={value}
          placeholder={placeholder}
          autoComplete="off"
          fireSubmitValidation={!value && fireSubmitValidation}
          errorMsg={phoneValidationMsg || errorMsg || ''}
          onBlur={onBlur}
          maxLength="11"
          readOnly={readOnly}
          disabled={disabled}
          style={{ letterSpacing: '0px' }}
          {...this.props}
        />
      </React.Fragment>
    );
  }
}

export default PhonenumInput;
