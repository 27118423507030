import React, { Component } from 'react';
import styled from 'styled-components';
import StarBuckIcon from '../../images/starbucks.jpg';
import { media } from '../../globalStyle';

const BannerContainer = styled.div`
  width: 100%;
  background-color: #1a1a1a;
  text-align: center;
  
  ${media.tablet`
    padding: 13px 0;
  `};

`;

const PinkSpan = styled.span`
  font-weight: bold;
  letter-spacing: normal;
  color: #ffb3aa;
`;

const BannerText = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.9px;
  text-align: center;
  color: #ffffff;
  display: inline-block;
  vertical-align: middle;
  br {
    display: none;
  }
  ${media.tablet`
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: -0.8px;
    br {
      display: block;
    }
  `};
`;

const BannerIcon = styled.img`
  width: 60px;
  height: 60px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 30px;
  ${media.tablet`
    display: none;
  `};
`;

class EventBanner extends Component {
  render() {
    return (
      <BannerContainer id="event_banner">
        <BannerText>
          매월 상담 사진을 첨부한 <PinkSpan>100분</PinkSpan>을 선정하여<br /> 익월 3일, <PinkSpan>스타벅스 기프트콘</PinkSpan>을 드립니다.
        </BannerText>
        <BannerIcon src={StarBuckIcon} alt="스타벅스쿠폰 이벤트" />
      </BannerContainer>
    );
  }
}

export default EventBanner;
