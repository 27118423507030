import React, { useState } from 'react';
import styled from 'styled-components';
import CounselProcessInfoModal from '../CounselProcessInfoModal';

const FaqContainer = styled.div`
  border-bottom: 1px solid #e0e0e0;
`;

const FaqHeader = styled.div`
  width: 100%;
  min-height: 50px;
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

const FaqTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  color: #484848;

  @media (max-width: 576px) {
    font-size: 14px;
  }
`;

const Angle = styled.i`
  color: black;
  padding-right: 20px;
`;

const FaqContent = styled.div`
  width: 100%;
  display: ${props => (props.open ? 'block' : 'none')};
  padding-top: 10px;
  padding-bottom: 32px;
  font-size: 18px;
  text-align: left;
  color: #484848;
  line-height: 1.4;
  p {
    padding: 10px 0;
    margin: 0;
    color: #033e52;
    text-decoration: #033e52 underline;
    cursor: pointer;
  }
  @media (max-width: 576px) {
    font-size: 13px;
  }
`;

const FaqRow = ({ faq: { title, content, component } }) => {
  const [collapse, setCollapse] = useState(false);
  const [isOpenInfoModal, setIsOpenInfoModal] = useState(false);

  return (
    <FaqContainer>
      <FaqHeader onClick={() => setCollapse(!collapse)}>
        <FaqTitle>{title}</FaqTitle>
        <Angle className={collapse ? 'fa fa-angle-up' : 'fa fa-angle-down'} />
      </FaqHeader>
      <FaqContent open={collapse}>
        {content}
        {component && (
          <p onClick={() => setIsOpenInfoModal(true)}>{component.children}</p>
        )}
      </FaqContent>
      <CounselProcessInfoModal
        isOpen={isOpenInfoModal}
        onClose={() => setIsOpenInfoModal(false)}
      />
    </FaqContainer>
  );
};

export default FaqRow;
