import {
  txTherapyTypeName,
  therapyInfoById,
} from '../components/form/therapyInfo';

export const getOrthoPartName = (part) => {
  const partName = {
    AU: '윗니 전체',
    PU: '윗니 부분',
    AD: '아랫니 전체',
    PD: '아랫니 부분',
  };

  return partName[part];
};

export const currencyFormat = (c, unit, boolean) => {
  if (c) return c.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + unit;
  return boolean ? false : 0;
};

export const getPriceFormat = (price = 0, unit = 1) => (
  currencyFormat(price * unit) || 0
);

export const getTxTherapyNamesByTxItems = (txItems) => {
  const obj = {
    implant: [],
    cavity: [],
    aesthetic: [],
    general: [],
    gum: [],
    facial: [],
  };

  txItems.forEach((item) => {
    const c = txTherapyTypeName[therapyInfoById[item.itemcode].type];
    obj[c].push(item);
  });

  return obj;
};

const reqUtils = {};

export default reqUtils;
