import React from 'react';
import _ from 'lodash';
import { withRouter, Redirect } from 'react-router-dom';
import { AuthContext } from '../components/AuthProvider';
import LoginForm from '../components/form/LoginForm';
import { FormTitle, AuthFormPageContainer, FormInputContainer } from '../components/form/FormCommonComponent';
import withAuthModal from '../utils/authmodalhoc';

const titles = [
  {
    pathPrefix: '/userreq',
    title: '상담결과 확인',
  },
  {
    pathPrefix: '/hosresps',
    title: '상담답변 확인',
  },
  {
    pathPrefix: '/reqdone',
    title: '상담정보 확인',
  },
  {
    pathPrefix: '/myrequest',
    title: '상담정보 확인',
  },
  {
    pathPrefix: '/changepassword',
    title: '비밀번호 변경',
  },
  {
    pathPrefix: '/settings',
    title: '상담설정',
  },
  {
    pathPrefix: '/favorites',
    title: '관심병원',
  },
  {
    pathPrefix: '/mypage/counsel',
    title: '상담내역 확인',
  },
  {
    pathPrefix: '/mypage/reservations',
    title: '예약내역 확인',
  },
  {
    pathPrefix: '/mypage',
    title: '마이페이지',
  },
];

class Login extends React.Component {
  getTitle = (pathname) => {
    const result = _.find(titles, titleObj => (pathname.indexOf(titleObj.pathPrefix) !== -1));

    if (result && result.title) {
      return result.title;
    }

    return '로그인';
  };

  login = async (phonenum, password) => {
    const {
      login,
    } = this.context;

    const {
      history,
      location,
    } = this.props;

    if (phonenum && password) {
      // mutation
      try {
        const loginRes = await login(phonenum, password);

        const { ok } = loginRes;

        if (ok) {
          // login 전에 방문한 페이지로 redirect
          const prePage = location?.state?.back;

          if (prePage) {
            history.push(prePage);
          } else {
            history.push('/');
          }
        }
        return loginRes;
      } catch (err) {
        throw err;
      }
    }
  };

  render() {
    const {
      regist,
      resetPassword,
      logout,
    } = this.context;
    const {
      openAuthModal,
      history,
      location,
    } = this.props;

    let title = '로그인';
    const prePage = location?.state?.back;

    if (prePage) {
      title = this.getTitle(prePage) || '로그인';
    }

    const { isLoggedIn } = this.context;
    if (isLoggedIn) {
      return <Redirect to={{ pathname: '/' }} />;
    }

    return (
      <AuthFormPageContainer>
        <div>
          <FormTitle>{title}</FormTitle>
        </div>
        <FormInputContainer>
          <LoginForm
            {...this.props}
            login={this.login}
            regist={regist}
            resetPassword={resetPassword}
            logout={logout}
            goToResetPass={() => openAuthModal('resetpassword')}
            goToSignup={() => history.push('/signup', prePage)}
          />
        </FormInputContainer>
      </AuthFormPageContainer>
    );
  }
}

const withFunc = withRouter(withAuthModal(Login));
Login.contextType = AuthContext;
export default withFunc;
