import React, { useState } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import _ from 'lodash';
import { OdocDateFomatter } from '../../utils/dates';
import RespSpeechBubble from './RespSpeechBubble';
import CounselProcessInfoModal from '../CounselProcessInfoModal';
import { Card } from '../atomic/molecules/Card';
import { ContentByItemcode } from '../../data/OdocContents';
import { StarWithPoint } from '../atomic/molecules/StarRating';

const SenderName = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.4px;
  color: #000000;
`;

const HospitalInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: -0.34px;
  color: #000000;
  margin-bottom: 30px;
  div {
    display: inline-block;
    vertical-align: middle;
  }
`;

const HospitalComment = styled.div`
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: -0.34px;
  color: #033e52;
  margin-bottom: 6px;
`;

const HospitalRespMsg = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: -0.46px;
  color: #000000;
  margin-bottom: 30px;
`;

const HospitalRespDate = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: semi-condensed;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 20px;
`;

const DetailViewButton = styled.button`
  height: 56px;
  width: 100%;
  border-radius: 4px;
  border: solid 1px #e1e3e2;
  background-color: transparent;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.58px;
  text-align: center;
  color: #000000;
`;

const IsCheckedFlag = styled.span`
  display: inline-block;
  vertical-align: top;
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background-color: #b1b3b2;
  margin-left: 4px;
  margin-top: 8px;
  &.active {
    background-color: #dd4124;
  }
`;

const SubMsg = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.4px;
  color: #000000;
  &.mb-40 {
    margin-bottom: 40px;
  }
  &.mb-30 {
    margin-bottom: 30px;
  }
`;

const StyledSlider = styled(Slider)`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: #000000;
  .slick-list {
    padding: 0px 0;
    margin-right: -20px;
  }
  .slick-slide {
    width: 237px;
    margin-right: 10px;
  }
  .card-title {    
    font-weight: bold;
    line-height: 1.71;
    letter-spacing: -0.44px;
    text-align: left;
    margin-top: 9px;
    margin-bottom: 0;
    .orange {
      line-height: 1.71;
      letter-spacing: normal;
      color: #dd4124;
      font-family: Saira Semi Condensed;
      font-size: 14px;
      font-weight: 600;
      font-stretch: semi-condensed;
    }
  }
  .card-text {
    line-height: 1.43;
    letter-spacing: -0.4px;
    color: #b1b3b2;
  }
`;

const CardWrapper = styled.div`
  display: inline-block;
`;

const ContentDoorImg = styled.img`
  display: block;
  width: 237px;
  height: 133px;
  border-radius: 4px;
`;

const getHosRespCntLabel = (recentRespCnt) => {
  if (!recentRespCnt) {
    return null;
  }

  if (recentRespCnt > 100) {
    return '100+';
  }

  return `${recentRespCnt} 개`;
};

const CaseSpeechBubble = styled(RespSpeechBubble)`
  width: 100%;
  max-width: 430px;
`;

// 상담 사례용 답변 영역 카드
export const CaseResponseCard = ({ response, responseRender, onClick }) => {
  const respCntLabel = getHosRespCntLabel(response?.hospital.recentReservationCnt);
  return (
    <CaseSpeechBubble side="left" backgroundColor="#f5f7f7" textColor="#000000">
      <div>
        <SenderName>{response?.hospital?.name}</SenderName>
        <HospitalInfo>
          <div>{response?.hospital?.locationString}</div>
          <div>
            {
              !!response?.hospital?.reviewCnt
              && (
                <StarWithPoint
                  starsNum={1}
                  size="12px"
                  fontSize="12px"
                  selectedNum={1}
                >
                  {response?.hospital?.avgRating}
                  <span style={{ color: '#000', fontWeight: 'normal', marginLeft: '2px' }}>
                    {`(${response?.hospital?.reviewCnt})`}
                  </span>
                </StarWithPoint>
              )
            }
            <div>
              {(response?.hospital?.reviewCnt && respCntLabel) ? <span style={{ margin: '0 4px' }}> · </span> : null}
              {'최근예약 '}
              {response?.hospital?.recentReservationCnt}
              {'개'}
            </div>
          </div>

        </HospitalInfo>
        <HospitalRespMsg>{responseRender || response?.content}</HospitalRespMsg>
        <DetailViewButton
          onClick={() => onClick()}
        >
          {'병원정보 보러가기'}
        </DetailViewButton>
      </div>
    </CaseSpeechBubble>
  );
};

// 상담 결과 카드
export const RespCard = ({ hosresp, onClick }) => {
  const respCntLabel = getHosRespCntLabel(hosresp?.recentRespCnt);
  return (
    <RespSpeechBubble side="left" backgroundColor="#f5f7f7" textColor="#000000">
      <div>
        <SenderName>{hosresp?.hospital?.name}</SenderName>
        <HospitalInfo>
          <div>{hosresp?.hospital?.locationString}</div>
          <div>
            <StarWithPoint
              starsNum={1}
              size="12px"
              fontSize="12px"
              selectedNum={1}
            >
              {hosresp?.hospital?.avgRating}
              <span style={{ color: '#000', fontWeight: 'normal', marginLeft: '2px' }}>
                {`(${hosresp?.hospital?.reviewCnt})`}
              </span>
            </StarWithPoint>
            <div style={{ fontSize: '12px', letterSpacing: '-.5px' }}>
              {
                (hosresp?.hospital?.reviewCnt && respCntLabel)
                  ? <span style={{ margin: '0 4px' }}> · </span> : null
              }
              {/* {respCntLabel ? `최근답변 ${respCntLabel}` : null} */}
              {'최근예약 '}
              {hosresp?.hospital?.recentReservationCnt}
              {'개'}
            </div>
          </div>
        </HospitalInfo>
        {hosresp?.hospital?.metadata?.onelineComment && (
          <HospitalComment>
            {hosresp?.hospital?.metadata?.onelineComment}
          </HospitalComment>
        )}
        <HospitalRespMsg>{hosresp?.shortRespStr ? `${hosresp?.shortRespStr}...` : ''}</HospitalRespMsg>
        <HospitalRespDate>
          {OdocDateFomatter(hosresp?.createdAt)}
          <IsCheckedFlag className={!hosresp?.checkedAns && 'active'} />
        </HospitalRespDate>
        <DetailViewButton onClick={() => onClick(hosresp)}>
          {'자세히보기'}
        </DetailViewButton>
      </div>
    </RespSpeechBubble>
  );
};

// 상담진행절차 카드
export const CounselProcessCard = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <RespSpeechBubble side="left" className="border" backgroundColor="#fff" textColor="#000000" borderColor="#e1e3e2">
      <div>
        <SenderName>오닥터</SenderName>
        <SubMsg className="mb-40">상담 진행절차가 궁금하세요?</SubMsg>
        <DetailViewButton onClick={() => setIsOpen(true)}>
          {'자세히보기'}
        </DetailViewButton>
      </div>
      <CounselProcessInfoModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </RespSpeechBubble>
  );
};

export const ContentCard = ({ reqItems }) => {
  // 컨텐츠 리스트 뽑기
  const contentList = reqItems.reduce((items, item) => {
    if (ContentByItemcode[item?.itemcode] && !items.includes(item)) {
      return [
        ...items,
        ContentByItemcode[item?.itemcode],
      ];
    }

    return items;
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    className: 'odoc-slide',
    arrows: false,
  };

  // 컨텐츠 있을 때에만 노출
  if (contentList?.length > 0) {
    return (
      <RespSpeechBubble side="left" className="border" backgroundColor="#fff" textColor="#000000" borderColor="#e1e3e2">
        <div>
          <SenderName>오닥터</SenderName>
          <SubMsg className="mb-30">병원 가기 전 치료에 대해 알아두면 좋아요!</SubMsg>

          {/* 컨텐츠 슬라이드 */}
          <StyledSlider {...settings}>
            {_.shuffle(contentList).map((content, idx) => (
              <CardWrapper key={`${idx}_${content.title}`} onClick={() => window.open(`${content.path}`)}>
                <Card
                  width="100%"
                  height="auto"
                  imgRender={() => <ContentDoorImg src={content.img} />}
                  title={content.title}
                >
                  {content.desc}
                </Card>
              </CardWrapper>
            ))}
          </StyledSlider>
        </div>
      </RespSpeechBubble>
    );
  }
  return null;
};

export default RespCard;
