import React from 'react';
import styled from 'styled-components';
import { compose, graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import {
  HospitalCardListContainer,
} from '../components/basic/HtmlComponents';
import ScrollToTopOnMount from '../components/basic/ScrollToTopOnMount';

import { myFavoriteHospitalsQuery } from '../graphql';
import { media } from '../globalStyle';
import HospitalViewCard from '../components/basic/HospitalViewCard';

const Container = styled.div`
  max-width: 1080px;
  margin: 0 auto;
  padding: 40px 20px 80px;
  ${media.tablet`
    width: 100%;
    padding-top: 78px;
  `};
`;

const H1 = styled.h1`
  font-size: 34px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
  margin-bottom: 40px;
  ${media.tablet`
    font-size: 24px;
    letter-spacing: -1.7px;
    margin-bottom: 22px;
  `};
`;

const Count = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -1px;
  text-align: right;
  color: #000000;
  margin-bottom: 12px;
  ${media.tablet`
    font-size: 13px;
  `};
`;

const NoCounselComment = styled.p`
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.53;
  letter-spacing: normal;
  color: #767676;
  height: 124px;
`;


class Favorites extends React.Component {
  render() {
    const {
      loading,
      myFavoriteHospitals
    } = this.props.allFavorites;
    const {history} = this.props;

    if (loading) {
      return null;
    }

    return (
      <Container>
        <ScrollToTopOnMount />
        <div>
          <H1>관심병원 리스트 입니다.</H1>
          <Count>총 <b>{myFavoriteHospitals && (myFavoriteHospitals.length || 0)}</b>건</Count>
          <div>
            {myFavoriteHospitals && myFavoriteHospitals.length > 0 ? (
              <HospitalCardListContainer>
                {myFavoriteHospitals.map(h =>
                    h.favorited && (
                      <HospitalViewCard
                        perPage={3}
                        key={`HospitalCards_${h.name}`}
                        onClick={() => history.push(`/hospitals/${h.id}`)}
                        photos={
                          h.photos && h.photos.length > 0
                            ? h.photos.filter(photo => photo.category === 'main')
                            : null
                        }
                        name={h.name}
                        address={h.address}
                        tags={h.tags}
                        favorited={h.favorited}
                        hid={h.id}
                        refetchQuery={[
                          {
                            query: myFavoriteHospitalsQuery,
                          },
                        ]}
                        hideToggleFavoriteBtn
                      />
                    ))}
              </HospitalCardListContainer>
            ) : (
              <NoCounselComment>관심병원이 존재하지 않습니다.</NoCounselComment>
            )}
          </div>
        </div>
      </Container>
    );
  }
}

export default compose(graphql(myFavoriteHospitalsQuery, {
  name: 'allFavorites',
  options: {
    fetchPolicy: 'network-only',
  },
}))(withRouter(Favorites));
