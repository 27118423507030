import React, { useState } from 'react';
import { graphql } from 'react-apollo';
import styled from 'styled-components';

import SelectAngleIcon from '../../images/drop-down.svg';
import { AuthConsumer } from '../AuthProvider';
import { updateUserInfoMutation } from '../../graphql';

const PrefixLabel = styled.div`
  position: absolute;
  display: none;
  left: 14px;
  top: 19px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: -0.6px;
  color: #000000;
  z-index: 0;
`;

const Select = styled.select`
  border-radius: 4px;
  border: solid 1px #e1e3e2;
  background-color: #ffffff;
  width: 100%;
  padding: 18px 30px 18px 14px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: -0.6px;
  color: #000000;
  -webkit-appearance: none;
  background-image: url(${SelectAngleIcon});
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 96% center;
  z-index: 1;
  position: relative;
  background-color: transparent;
  :active,
  :focus {
    outline: none;
  }
`;

const SelectWrapper = styled.div`
  margin-top: 20px;
  display: inline-block;
  width: 100%;
  position: relative;

  &.active {
    ${PrefixLabel} {
      display: inline-block;
    }
    ${Select} {
      padding-left: 120px;
    }
  }
`;

const ContactTypeOptions = [
  '전화(언제든지)',
  '카톡',
  '전화(10:00 ~ 12:00)',
  '전화(12:00 ~ 14:00)',
  '전화(14:00 ~ 16:00)',
  '전화(16:00 ~ 18:00)',
  '전화(18:00 ~ 20:00)',
  '전화(20:00 이후)',
];

const ContactTypeSelector = ({ updateMe }) => {
  const [contactType, setContactType] = useState('전화(언제든지)');
  return (
    <AuthConsumer>
      {value => (
        <SelectWrapper className={contactType && 'active'}>
          <PrefixLabel>병원과 희망연락방법:</PrefixLabel>
          <Select
            isSearchable={false}
            placeholder="연락방법"
            defaultValue={value?.me?.metadata?.callmethod || contactType}
            onChange={async ({ target }) => {
              const callmethod = target?.value;
              // 연락방법 유저에 저장하기
              await updateMe({
                variables: {
                  metadata: {
                    ...value?.me?.metadata,
                    callmethod,
                  },
                },
              });
              value.setMe({
                ...value.me,
                metadata: { ...value?.me?.metadata, callmethod },
              });
              setContactType(callmethod);
            }}
          >
            {ContactTypeOptions.map(optionLabel => (
              <option key={optionLabel} value={optionLabel}>
                {optionLabel}
              </option>
            ))}
          </Select>
        </SelectWrapper>
      )}
    </AuthConsumer>
  );
};

export default graphql(updateUserInfoMutation, {
  name: 'updateMe',
})(ContactTypeSelector);
