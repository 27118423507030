import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';
import logo from '../../images/logo.png';
import ToggleBtnIcon from '../../images/menu.png';
import CloseIcon from '../../images/ic-close-44.png';
import { media } from '../../globalStyle';
import { fixBody, unfixBody } from '../../utils/style-utils';
import withAuthModal from '../../utils/authmodalhoc';
import { logEvent } from '../../utils/loging';
import EventBanner from './EventBanner';
import { sizeProps } from './HtmlComponents';
import { AuthContext } from '../AuthProvider';

const ToggleBtn = styled.img`
  display: none;
  ${media.tablet`
    display: inline-block;
    width: 22px;
    height: 22px;
    object-fit: contain;
    cursor: pointer;
  `};
`;

const Nav = styled.ul`
  ${media.tablet`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    margin-top: 60px;
    overflow-y: auto;
    li ~ li {
      margin-top: 30px;
    }
  `};
`;

const NavItem = styled.li`
  list-style: none;
  display: inline-block;
  margin-left: 20px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.9px;
  text-align: right;
  color: #000000;
  cursor: pointer;
  vertical-align: middle;
  &:hover,
  &:focus {
    text-decoration: none;
    outline: none;
  }

  ${props => props.active && `
    font-weight: bold;
    color: #033e52;
  `};

  ${media.tablet`
    display: block;
    text-align: left;
    font-size: 18px;;
    line-height: 1;
    letter-spacing: -1.1px;
    color: #033e52;
    margin: 0;
    ${props => props.active && `
      color: #033e52;
      font-weight: normal;
    `};
  `};
`;

const CounselMenu = styled(NavItem)`
  position: relative;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.8px;
  text-align: right;
  color: #033e52;
  width: 90px;
  border-radius: 17px;
  border: solid 1px #033e52;
  background-color: #ffffff;
  text-align: center;
  padding: 7px 0;
  ${props => props.active && `
    color: #033e52;
  `};
  ${media.tablet`
    margin-right: 0;
    padding: 0;
    border: 0;
    font-weight: normal;
    ${props => props.active && `
      color: #033e52;
      font-weight: normal;
    `};
  `};
`;

const Brand = styled.div`
  display: inline-block;
  margin-top: 7px;
  ${media.tablet`
    height: 58px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0;
    ${props => props.invisible && 'display: none'};
  `};
`;

const LogoA = styled.div`
  height: 100%;
  display: block;
`;

const DebugStateStr = styled.div`
  color: #6495ed;
  margin-left: 3px;
  font-size: 14px;
  font-weight: bold;
`;

const Logo = styled.img`
  width: 125px;
  height: 24px;
  object-fit: contain;
  margin: 0;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  ${media.tablet`
    width: 104px;
    height: auto;
    cursor: pointer;
  `};
`;

const MobileCollapseHeader = styled.div`
  ${media.tablet`
    position: relative;
    height: auto;
    width: 100%;
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.42;
    letter-spacing: -1.5px;
    color: #033e52;
    text-align: left;
  `};
`;

const Background = styled.div`
  ${media.tablet`
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
  `};
`;

const MobileNav = styled.div`
  display: inline-block;
  ${media.tablet`
    position: absolute;
    left: 40px;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    animation-name: SlideIn;
    animation-duration: 0.4s;
    transform: translateX(0%);
    transition: transform 0.3s ease-in-out;
  `};

  @keyframes SlideIn{
    0% { transform: translateX(100%) }
    100% { transform: translateX(0%) }
  }

  @keyframes SlideOut{
    0% { transform: translateX(0%) }
    100% { transform: translateX(100%) }
  }
`;

const MobileNavFooter = styled.div`
  ${media.tablet`
    height: 68px;
    width: 100%;
    border-top: 1px solid #e0e0e0;
    padding-top: 10px;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #033e52;
    display: flex;
    justify-content: space-between;
    a, a:link, a:hover, a:focus, a:active {
      color: #033e52;
      text-decoration: underline;
    }
  `};
`;

const StyledCollapse = styled.div`
  float: right;
  margin-left: -24px;
  margin-top: 3px;
  &::after {
    content: '';
    clear: both;
  }
  ${MobileCollapseHeader}, ${MobileNavFooter} {
    display: none;
  }
  ${media.tablet`
    ${props => (props.open ? 'display: flex' : 'display: none')};
    flex-direction: column;
    float: none;
    text-align: center;
    position: fixed;
    margin: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 450px;
    ${MobileCollapseHeader}, ${Background} {
      ${props => (props.open && 'display: block')};
    }
    ${MobileNavFooter} {
      ${props => (props.open && 'display: flex')};
    }
  `};
`;

const CloseBtn = styled.img`
  width: 22px;
  height: 22px;
  object-fit: contain;
  cursor: pointer;
  margin-bottom: 38px;
  float: right;
`;

const NavInner = styled.div`
  max-width: 1080px;
  margin: auto;
  padding: 23px 20px;
  min-height: 86px;
`;

const StyledNavbar = styled.nav`
  display: block;
  box-shadow: none;
  width: 100%;
  position: relative;
  margin: 0px auto;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  border-radius: 0px;
  background: rgb(255, 255, 255);
  ${props =>
    (
      props.location === '/' ||
      props.location === '/a' ||
      props.location === '/b' ||
      props.location === '/c' ||
      props.location === '/d'
    ) &&
    `
    position: fixed;
    z-index: 4;
    max-width: none;
  `};
  ${media.tablet`
    padding: 0;
    margin: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 4;
    ${props => props.open && 'z-index: 6'};
    ${NavInner} {
      min-height: 0;
      padding: 0;
    }
  `};
  ${props => props.hideTopMenu && `
    ${ToggleBtn}, ${StyledCollapse} {
      display: none;
    }
  `};
`;

const EventNavSpace = styled.div`
  ${sizeProps}
  display: none;
  position: relative;
  width: 100%;
  top: 0;
  /* ${media.tablet`
    height: 0;
  `}; */
`;

const EventStyledNavbar = styled(StyledNavbar)`
  padding: 0;
  max-width: 100%;
  position: relative;
  ${NavInner} {
    max-width: 1080px; 
  }
  &.fixed {
    position: fixed;
    ${EventNavSpace} {
      display: block;
    }
  }
  ${media.tablet`
    position: relative;
    ${NavInner} {
      min-height: 0;
      padding: 0; 
    }
    &.fixed {
      position: relative;
      margin-bottom: 120px;
      ${NavInner} {
        position: fixed;
        top: 0;
        width: 100%;
        background: #ffffff;
      }
    }
  `};
`;

const NavSpace = styled.div`
  ${sizeProps}
`;

class NavigationBar extends React.Component {
  state = {
    isOpenCollaseMenu: false,
    scrollTop: 0,
    anim: 'SlideIn',
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScrollEvt);
    window.addEventListener('resize', this.handleScrollEvt);
    this.handleScrollEvt();

    if (this.props.event) {
      const eventBanner = document.getElementById('event_banner');
      this.eventBannerHeight = eventBanner.offsetHeight;
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScrollEvt);
    window.removeEventListener('resize', this.handleScrollEvt);
  }

  getNavHeight = () => {
    const nav = document.getElementsByTagName('nav')[0];
    this.navHeight = nav.offsetHeight;
  }

  handleScrollEvt = () => {
    const { scrollTop } = document.scrollingElement || document.documentElement;
    this.setState({ scrollTop });
    this.getNavHeight();
    const eventBanner = document.getElementById('event_banner');
    const eventNav = document.getElementById('event_nav');
    const eventNavSpace = document.getElementById('event_nav_space');
    const mainBody = document.getElementById('main-body');
    if (this.props.event && this.eventBannerHeight) {
      // event popup scroll따라 위로 올라가도록
      if (scrollTop > this.eventBannerHeight) {
        mainBody.style.marginTop = `${this.eventBannerHeight + 95}px`;
        eventNav.classList.add('fixed');
        eventBanner.classList.add('hide');
        eventNavSpace.classList.add('show');
      }
      if (scrollTop < this.eventBannerHeight) {
        mainBody.style.marginTop = '0px';
        eventNav.classList.remove('fixed');
        eventBanner.classList.remove('hide');
        eventNavSpace.classList.remove('show');
      }
    }
  };

  closeMobileCollapse = () => {
    this.setState({ anim: 'SlideOut' });
    unfixBody();
    setTimeout(() => {
      this.setState({ isOpenCollaseMenu: false });
    }, 300);
  };

  render() {
    const {
      history,
      location,
      hideTopMenu,
      openLogoutModal,
    } = this.props;

    const env = process.env.REACT_APP_NODE_ENV || 'development';
    const { isLoggedIn } = this.context;
    const { scrollTop } = this.state;
    const { pathname } = location;

    const inner = (
      <NavInner>
        <Brand invisible={this.state.isOpenCollaseMenu}>
          <Link to="/" >
            <LogoA>
              <Logo src={logo} />
            </LogoA>
          </Link>
          {env === 'development' && <DebugStateStr>development 서버</DebugStateStr>}
          {env === 'staging' && <DebugStateStr>staging 서버</DebugStateStr>}
          <ToggleBtn
            src={ToggleBtnIcon}
            alt="ToggleBtnIcon"
            onClick={() => {
              this.setState({
                anim: 'SlideIn',
                isOpenCollaseMenu: !this.state.isOpenCollaseMenu,
              });
              fixBody();
              logEvent({
                category: '네비게이션',
                action: '클릭',
                label: 'Drawer',
              });
            }}
          />
        </Brand>
        <StyledCollapse open={this.state.isOpenCollaseMenu}>
          <Background />
          <MobileNav style={{ animationName: this.state.anim }}>
            <MobileCollapseHeader>
              <div>
                <CloseBtn
                  src={CloseIcon}
                  onClick={() => this.closeMobileCollapse()}
                />
              </div>
              <div style={{ clear: 'both' }}>
                내 손안의 치과 상담실<br />
                <b>오닥터</b>
              </div>
            </MobileCollapseHeader>

            {isLoggedIn ? (
              <Nav>
                <CounselMenu
                  onClick={() => {
                    this.props.history.push({
                      pathname: '/',
                      state: {
                        mode: 'quick',
                      },
                    });
                    this.closeMobileCollapse();
                    logEvent({
                      category: '네비게이션',
                      action: '클릭',
                      label: '상담신청',
                    });
                  }}
                  active={pathname === '/'}
                >
                  <FormattedMessage id="topmenu_quickcounsel" />
                </CounselMenu>
                <NavItem
                  onClick={() => {
                    this.props.history.push({
                      pathname: '/case',
                    });
                    this.closeMobileCollapse();
                    logEvent({
                      category: '네비게이션',
                      action: '클릭',
                      label: '상담사례',
                    });
                  }}
                  active={pathname === '/case'}
                >
                  <FormattedMessage id="topmenu_case" />
                </NavItem>
                <NavItem
                  onClick={() => {
                    history.push('/mypage/profile');
                    this.closeMobileCollapse();
                    logEvent({
                      category: '네비게이션',
                      action: '클릭',
                      label: ' 나의정보',
                    });
                  }}
                  active={pathname === '/mypage/profile'}
                >
                  <FormattedMessage id="topmenu_myinfo" />
                </NavItem>
                <NavItem
                  onClick={() => {
                    history.push('/favorites');
                    this.closeMobileCollapse();
                  }}
                  active={pathname === '/favorites'}
                >
                  <FormattedMessage id="topmenu_favHospital" />
                </NavItem>
                <NavItem
                  onClick={() => {
                    this.props.history.push('/mypage/counsel');
                    this.closeMobileCollapse();
                    logEvent({
                      category: '네비게이션',
                      action: '클릭',
                      label: '상담내역',
                    });
                  }}
                  active={pathname === '/mypage/counsel'}
                >
                  <FormattedMessage id="topmenu_userReqs" />
                </NavItem>
                <NavItem
                  onClick={() => {
                    this.closeMobileCollapse();
                    openLogoutModal();
                  }}
                >
                  로그아웃
                </NavItem>
              </Nav>
            ) : (
              <Nav>
                <CounselMenu
                  onClick={() => {
                    this.props.history.push({
                      pathname: '/',
                      state: {
                        mode: 'quick',
                      },
                    });
                    this.closeMobileCollapse();
                    logEvent({
                      category: '네비게이션',
                      action: '클릭',
                      label: '상담신청',
                    });
                  }}
                  active={pathname === '/'}
                >
                  <FormattedMessage id="topmenu_quickcounsel" />
                </CounselMenu>
                <NavItem
                  onClick={() => {
                    this.props.history.push({
                      pathname: '/case',
                    });
                    this.closeMobileCollapse();
                    logEvent({
                      category: '네비게이션',
                      action: '클릭',
                      label: '상담사례',
                    });
                  }}
                  active={pathname === '/case'}
                >
                  <FormattedMessage id="topmenu_case" />
                </NavItem>
                <NavItem
                  onClick={() => {
                    this.closeMobileCollapse();
                    this.props.history.push({ pathname: '/login' });
                  }}
                >
                  <FormattedMessage id="menu_login" />
                </NavItem>
                <NavItem
                  onClick={() => {
                    this.closeMobileCollapse();
                    this.props.history.push('/signup');
                  }}
                >
                  <FormattedMessage id="menu_signup" />
                </NavItem>
              </Nav>
            )}
            <MobileNavFooter>
              <div>
                cs@odoctor.co.kr
              </div>
              <div>
                <a href="mailto: cs@odoctor.co.kr?subject=문의하기&body=오닥터%20고객센터입니다.%0A문의할%20사항이나%20서비스%20사용중%20궁금하신점을%20작성해주세요%0A%0A성함%20혹은%20아이디%20:%0A%0A문의내용%20:">문의하기</a>
              </div>
            </MobileNavFooter>
          </MobileNav>
        </StyledCollapse>
      </NavInner>
    );

    if (this.props.event) {
      return (
        <div>
          <EventStyledNavbar
            hideTopMenu={hideTopMenu}
            collapseOnSelect
            location={document.location.pathname}
            open={this.state.isOpenCollaseMenu}
            scrollTop={scrollTop}
            id="event_nav"
          >
            <EventBanner />
            {inner}
          </EventStyledNavbar>
          <EventNavSpace height={this.navHeight} id="event_nav_space" />
        </div>
      );
    }

    return (
      <div>
        <StyledNavbar
          hideTopMenu={hideTopMenu}
          collapseOnSelect
          location={document.location.pathname}
          open={this.state.isOpenCollaseMenu}
          scrollTop={scrollTop}
          id="nav"
        >
          {inner}
        </StyledNavbar>
        <NavSpace height={this.navHeight} />
      </div>
    );
  }
}

NavigationBar.contextType = AuthContext;
const NavigationBarWithFunction = withRouter(withAuthModal(NavigationBar));
export default NavigationBarWithFunction;
