// Graphql query 들 모아놓을 예정. (2018/1/23 start!)
import gql from 'graphql-tag';

export const myDraftQuery = gql`
  query {
    myDraft {
      id
      reqType
      createdAt
      txItems {
        itemcode
        unit
      }
      orthoItems {
        itemcode
        part
      }
      status
      txMaxPrice
      orthoMaxPrice
      carePrice
      txImageUrls
      orthoImageUrls
      orthoWanna
      alarmOn
      considerTags
      gender
      ageGroup
      desc
      directReq {
        id
        hospital {
          id
          name
        }
      }
    }
  }
`;

export const MyRequestsQuery = gql`
  query($perPage: Int, $currentPage: Int, $status: UserReqStatus) {
    myRequests(perPage: $perPage, currentPage: $currentPage, status: $status) {
      myRequests {
        id
        reqType
        createdAt
        txItems {
          itemcode
          unit
        }
        orthoItems {
          itemcode
          part
        }
        status
        txMaxPrice
        orthoMaxPrice
        carePrice
        txImageUrls
        orthoImageUrls
        orthoWanna
        alarmOn
        considerTags
        gender
        ageGroup
        desc
        hosResps {
          id
        }
        directReq {
          id
          hospital {
            id
            name
          }
        }
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const userRequestQuery = gql`
  query($reqId: ID!) {
    getMyReq(reqId: $reqId) {
      id
      reqType
      createdAt
      txItems {
        itemcode
        unit
      }
      orthoItems {
        itemcode
        part
      }
      user {
        id
        username
      }
      status
      txMaxPrice
      orthoMaxPrice
      carePrice
      txImageUrls
      orthoImageUrls
      orthoWanna
      alarmOn
      considerTags
      gender
      ageGroup
      desc
      locations {
        id
        siName
        gunguName
      }
      wantLevel
      directReq {
        id
        hospitalId
        hospital {
          id
          name
        }
      }
    }
  }
`;

export const updateUserInfoMutation = gql`
  mutation(
    $username: String
    $sex: String
    $phonenum: String
    $email: String
    $agreemarket: Boolean
    $metadata: JSON
  ) {
    updateUserInfo(
      username: $username
      sex: $sex
      phonenum: $phonenum
      email: $email
      agreemarket: $agreemarket
      metadata: $metadata
    ) {
      id
      username
      sex
      phonenum
      agreemarket
      metadata
    }
  }
`;

export const autoCsHospitalListQuery = gql`
  query($ureqId: ID!, $perPage: Int, $currentPage: Int) {
    autoCsHospitalList(
      ureqId: $ureqId
      perPage: $perPage
      currentPage: $currentPage
    ) {
      hospitals {
        id
        name
        address
        favorited
        photos {
          url
          category
          title
          description
        }
        tags {
          id
          tagName
        }
        infos {
          id
          infoText
          icon
          type
        }
        reserved
        locationId
        locationString
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const getHosRespsListQuery = gql`
  query($ureqId: ID!, $perPage: Int, $currentPage: Int) {
    getHosRespsList(
      ureqId: $ureqId
      perPage: $perPage
      currentPage: $currentPage
    ) {
      hospitals {
        id
        reserved
        locationMatchScore
        txPriceMatchScore
        orthoPriceMatchScore
        recentRespCnt
        shortRespStr
        checkedAns {
          id
          callState
        }
        createdAt
        hospital {
          id
          name
          address
          favorited
          recentReservationCnt
          avgRating
          reviewCnt
          photos {
            url
            category
          }
          tags {
            id
            tagName
          }
          infos {
            id
            infoText
            icon
            type
          }
          locationId
          locationString
          metadata
        }
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const allHospitalsQuery = gql`
  query($perPage: Int, $currentPage: Int, $latStart: Float, $latEnd: Float, $lngStart: Float, $lngEnd: Float) {
    allHospitals(perPage: $perPage, currentPage: $currentPage, latStart: $latStart, latEnd: $latEnd, lngStart: $lngStart, lngEnd: $lngEnd) {
      hospitals {
        id
        address
        name
        lat
        lng
        favorited
        locationString
        locationId
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const getHospitalQuery = gql`
  query($id: ID!) {
    getHospital(id: $id) {
      id
      address
      lat
      lng
      phone
      starRating
      name
      metadata
      favorited
      favoriteCounts
      updatedAt
      locationId
      locationString
      recentReservationCnt
      avgRating
      reviewCnt
      tags {
        id
        tagName
      }
      infos {
        id
        infoText
        icon
        type
      }
      compliments {
        id
        desc
        createdAt
        toHuser {
          id
          username
          photos {
            url
          }
          hospital {
            id
            name
          }
        }
        fromHuser {
          id
          username
          photos {
            url
          }
          hospital {
            id
            name
          }
        }
      }
      photos {
        url
        category
        title
        description
      }
      doctors {
        id
        name
        speciality
        metadata
        photos {
          id
          url
          category
        }
        videos {
          id
          url
          thumbnail
          title
          description
        }
      }
    }
  }
`;

export const toggleFavoriteHospitalMutation = gql`
  mutation($hospitalId: ID!) {
    toggleFavoriteHospital(hospitalId: $hospitalId) {
      id
      favorited
    }
  }
`;

// 미사용
export const calcAutoPriceQuery = gql`
  query($hid: ID!, $reqId: ID!) {
    calcAutoPrice(hid: $hid, reqId: $reqId) {
      ok
      reserved
      txMaxPrice
      orthoMaxPrice
      undefinedTherapy {
        itemcode
        part
      }
      reqType
      errors {
        path
        message
      }
    }
  }
`;

export const myFavoriteHospitalsQuery = gql`
  query {
    myFavoriteHospitals {
      id
      name
      address
      locationId
      locationString
      favorited
      photos {
        url
        category
        title
        description
      }
      tags {
        id
        tagName
      }
      infos {
        id
        infoText
        icon
        type
      }
    }
  }
`;

export const getHosRespsDetailQuery = gql`
  query($hrid: ID!) {
    getHosRespsDetail(hrid: $hrid) {
      id
      reserved
      ureqId
      hospitalId
      hospital {
        id
        counselSetting {
          greeting # 인사말
          closing # 맺음말
        }
      }
      txMaxPrice
      orthoMaxPrice
      symptomAnswer
      treatmentAnswer
      metadata
      txPrices {
        itemcode
        price
      }
      orthoPrices {
        itemcode
        price
        part
      }
      checkedAns {
        id
        callState
        createdAt
      }
      discount {
        id
        discountRatio
      }
      userRequest {
        id
        txItems {
          itemcode
          unit
        }
        orthoItems {
          itemcode
          part
        }
        user {
          id
          username
          phonenum
        }
        txMaxPrice
        orthoMaxPrice
        carePrice
        reqType
        desc
        wantLevel
        createdAt
      }
    }
  }
`;

export const announcementsListQuery = gql`
  query($forWho: String!) {
    announcementsList(forWho: $forWho) {
      id
      title
      forWho
      description
      importance
      createdAt
    }
  }
`;

export const myRecentReqQuery = gql`
  query {
    myRecentReq {
      id
      reqType
      createdAt
      txItems {
        itemcode
        unit
      }
      orthoItems {
        itemcode
        part
      }
      txMaxPrice
      orthoMaxPrice
      carePrice
      txImageUrls
      orthoImageUrls
      orthoWanna
      alarmOn
      considerTags
      gender
      ageGroup
      desc
    }
  }
`;

export const submitReservationMutation = gql`
  mutation($ureqId: ID!, $hospitalId: ID!, $requirements: String, $availtime: String) {
    submitReservation(ureqId: $ureqId, hospitalId: $hospitalId, requirements: $requirements, availtime: $availtime) {
      id
      ureqId
      hospitalId
      state
      createdAt
    }
  }
`;

export const phoneReservationMutation = gql`
  mutation($ureqId: ID!, $hospitalId: ID!) {
    phoneReservation(ureqId: $ureqId, hospitalId: $hospitalId) {
      id
      ureqId
      hospitalId
      state
      createdAt
    }
  }
`;

export const myReservationQuery = gql`
  query($hospitalId: ID!, $ureqId: ID!) {
    myReservation(hospitalId: $hospitalId, ureqId: $ureqId) {
      id
      ureqId
      hospitalId
      state
      createdAt
      hospitalInfo {
        id
        name
      }
      hosResps {
        id
      }
      userReqs {
        id
        reqType
        status
        txMaxPrice
        orthoMaxPrice
        carePrice
        txItems {
          itemcode
          unit
        }
        orthoItems {
          itemcode
          part
        }
        orthoWanna
        directReq {
          id
        }
      }
    }
  }
`;

export const myReservationsQuery = gql`
  query($perPage: Int, $currentPage: Int) {
    myReservations(perPage: $perPage, currentPage: $currentPage) {
      reservations {
        id
        ureqId
        hospitalId
        state
        createdAt
        hasMyReview
        hospitalInfo {
          id
          name
        }
        hosResps {
          id
        }
        userReqs {
          id
          reqType
          status
          txMaxPrice
          orthoMaxPrice
          carePrice
          txItems {
            itemcode
            unit
          }
          orthoItems {
            itemcode
            part
          }
          orthoWanna
          directReq {
            id
          }
        }
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const betaLoginMutation = gql`
  mutation($betacode: String!) {
    betaLogin(betacode: $betacode) {
      ok
      token
    }
  }
`;

export const getComplimentQuery = gql`
  query($compId: ID!) {
    getCompliment(compId: $compId) {
      id
      desc
      status
      phonenum
      toName
      toHosName
      fromHuser {
        id
        username
        email
        phonenum
        hospital {
          id
          name
        }
      }
      toHuser {
        id
        username
        email
        phonenum
        hospital {
          id
          name
          metadata
        }
      }
      publishedAt
      createdAt
    }
  }
`;

export const terminateUserMutation = gql`
  mutation($reason: String) {
    terminateUser(reason: $reason) {
      ok
      error {
        path
        message
      }
    }
  }
`;

export const findEmailForUserMutation = gql`
  mutation(
    $username: String!
    $phonenum: String!
  ) {
    findEmailForUser(
      username: $username
      phonenum: $phonenum
    ) {
      email
      isPartner
    }
  }
`;

export const passwordResetMutation = gql`
  mutation(
    $phonenum: String
    $resetToken: String
    $newPass: String
    $forHuser: Boolean
  ) {
    passwordReset(
      phonenum: $phonenum
      resetToken: $resetToken
      newPass: $newPass
      forHuser: $forHuser
    ) {
      ok
    }
  }
`;

export const passwordResetAvailableQuery = gql`
  query(
    $email: String!
    $forHuser: Boolean
  ) {
    passwordResetAvailable(email: $email, forHuser: $forHuser) {
      ok
    }
  }
`;

export const sendPasswordUpdateMailToUserMutation = gql`
  mutation(
    $email: String!
  ) {
    sendPasswordUpdateMailToUser(
      email: $email
    ) {
      ok
      isPartner
    }
  }
`;

export const isSafePhoneMutation = gql`
  mutation(
    $phonenum: String!
  ) {
    isSafePhone(
      phonenum: $phonenum
    ) {
      ok
      email
      isPartner
    }
  }
`;

export const isSafeEmailMutation = gql`
  mutation(
    $email: String!
  ) {
    isSafeEmail(
      email: $email
    ) {
      ok
      isPartner
    }
  }
`;

export const loginMutation = gql`
  mutation($email: String!, $password: String!) {
    loginUserByEmail(email: $email, password: $password) {
      ok
      isPartner
      token
      refreshToken
      errors {
        path
        message
        code
      }
    }
  }
`;

export const registMutation = gql`
  mutation($email: String!, $password: String!, $username: String!, $phonenum: String!, $agreemarket: Boolean) {
    registerUserByEmail(email: $email, password: $password, username: $username, phonenum: $phonenum, agreemarket: $agreemarket) {
      ok
      user {
        id
        username
        email
        phonenum
      }
      errors {
        path

      }
    }
  }
`;

export const phoneNumberConfirmMutation = gql`
  mutation($phonenum: String!) {
    phoneNumberConfirm(phonenum: $phonenum) {
      ok
      id
    }
  }
`;

export const phonePinConfirmMutation = gql`
  mutation($id: ID!, $pin: String!) {
    phonePinConfirm(id: $id, pin: $pin) {
      ok
      error {
        path
      }
    }
  }
`;

export const pinConfirmForPasswordUpdateMutation = gql`
  mutation($id: ID!, $pin: String!) {
    pinConfirmForPasswordUpdate(id: $id, pin: $pin) {
      ok
      resetToken
    }
  }
`;

export const updatePasswordMutation = gql`
  mutation($origpass: String!, $newpass: String!) {
    updateUserPassword(origpass: $origpass, newpass: $newpass) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const getDoctorQuery = gql`
  query($id: ID!) {
    getDoctor(id: $id) {
      id
      name
      speciality
      metadata
      photos {
        id
        url
        category
      }
      videos {
        id
        url
        thumbnail
        title
        description
      }
      hospital {
        id
        name
      }
    }
  }
`;

export const locationSiQuery = gql`
  query {
    locationSi {
      code 
      name
    }
  }
`;

export const locationGunguQuery = gql`
  query($siName: String!)  {
    locationGungu(siName: $siName) {
      id
      code 
      name
      siCode
    }
  }
`;

export const findPartnerHospitalInHosRespsQuery = gql`
  query($reqId: ID!)  {
    findPartnerHospitalInHosResps(reqId: $reqId) {
      id
      address
      lat
      lng
      phone
      starRating
      name
      metadata
      favorited
      favoriteCounts
      updatedAt
      locationId
      locationString
      tags {
        id
        tagName
      }
      infos {
        id
        infoText
        icon
        type
      }
      photos {
        url
        category
        title
        description
      }
    }
  }
`;

export const isExistingUserMutation = gql`
  mutation($phonenum: String!) {
    isExistingUser(phonenum: $phonenum) {
      ok
      error {
        path
        message
      }
    }
  }
`;

export const loginUserByPhoneMutation = gql`
  mutation($phonenum: String!, $password: String!) {
    loginUserByPhone(phonenum: $phonenum, password: $password) {
      ok
      isPartner
      token
      refreshToken
      errors {
        path
        message
        code
      }
    }
  }
`;

export const registerUserByPhoneMutation = gql`
  mutation($phonenum: String!, $username: String!, $password: String!, $agreemarket: Boolean) {
    registerUserByPhone(phonenum: $phonenum, username: $username, password: $password, agreemarket: $agreemarket) {
      ok
      user {
        id
        username
      }
      errors {
        path
        message
        code
      }
    }
  }
`;

export const publishUserReqsMutate = gql`
  mutation(
    $directHospitalId: ID
    $txItems: [TxItemInput]
    $orthoItems: [OrthoItemInput]
    $reqType: ReqType
    $txMaxPrice: Float
    $orthoMaxPrice: Float
    $carePrice: String
    $orthoWanna: [String]
    $txImageUrls: [String]
    $orthoImageUrls: [String]
    $considerTags: [String]
    $desc: String
    $gender: String
    $ageGroup: String
    $locations: [LocationInput]
    $wantLevel: Int
  ) {
    publishUserReqs(
      directHospitalId: $directHospitalId
      txItems: $txItems
      orthoItems: $orthoItems
      reqType: $reqType
      txMaxPrice: $txMaxPrice
      orthoMaxPrice: $orthoMaxPrice
      carePrice: $carePrice
      orthoWanna: $orthoWanna
      txImageUrls: $txImageUrls
      orthoImageUrls: $orthoImageUrls
      considerTags: $considerTags
      desc: $desc
      gender: $gender
      ageGroup: $ageGroup
      locations: $locations
      wantLevel: $wantLevel
    ) {
      id
      createdAt
      reqType
      txItems {
        itemcode
        unit
      }
      orthoItems {
        itemcode
        part
      }
      alarmOn
      txMaxPrice
      considerTags
      orthoWanna
      txImageUrls
      orthoImageUrls
      desc
      gender
      ageGroup
      wantLevel
    }
  }
`;

export const checkHosRespsMutate = gql`
  mutation($hrId: ID!) {
    checkHosResps(
      hrId: $hrId
    ){
      id
      createdAt
      updatedAt
      callState
    }
  }
`;

export const sendHosResRequestMsgMutate = gql`
  mutation($hospitalIds: [ID!]!, $ureqId: ID!) {
    sendHosRespRequestMessage(
      hospitalIds: $hospitalIds
      ureqId: $ureqId
    ){
      ok
      errors {
        path
        message
      }
    }
  }
`;


export const idk = {};
