import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Flex } from '../atomic/atoms/Flex';
import { Image } from '../atomic/atoms/Image';
import DescComponent from './DescComponent';
import PhotoComponent from './PhotoComponent';
import NameComponent from './NameComponent';
import PhoneComponent from './PhoneComponent';
import PasswordComponent from './PasswordComponent';
import MemberPasswordComponent from './MemberPasswordComponent';
import ProcessComponent from './ProcessComponent';
import CityComponent from './CityComponent';
import SelectAddressComponent from './SelectAddressComponent';

const ExtendedFlexRow = styled(Flex)`
  margin-left: -5px;
  margin-right: -5px;
`;

const Cell = styled.div`
  padding: 0 5px;
  width: ${props => 100 / (props.cols || 2)}%;
  flex-direction: ${props => props.column && 'column'};
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: #fff;
`;

/**
 * @description Grid UI
 * @param children
 * @param render
 * @param cols 열의 갯수
 * @param className StyledComponent 확장할때 className 받는 용도
 * @param column Cell 안의 컨테츠 정렬 flex-direction
 * @param items
 * @returns {*}
 * @constructor
 */
export const FlexGrid = (
  {
    children,
    render,
    cols,
    className,
    column,
    items,
  },
) => {
  const array = Array.isArray(items) ? items : items.buttons;

  const OPTION = array.reduce(
    (options, option) => ({
      ...options,
      [option.id]: false,
    }), {},
  );

  const [radioboxes, setRadioboxes] = useState(OPTION);

  return (
    <ExtendedFlexRow>
      {
        array.map((t, i) => (
          <Cell
            key={i}
            cols={cols}
            column={column}
            className={className}
            onClick={() => {
              setRadioboxes({
                [t.id]: true,
              });
            }}
            data-test={radioboxes[t.id]}
          >
            {/* <Checkbox
                name={'test'}
                value={t.value}
                // isSelected={checkboxes[t.id]}
                isSelected={radioboxes[t.id]}
                onChange={handleCheckboxChange}
              >
                {render ? render(t) : children}
              </Checkbox> */}
            {render ? render(t, i) : children}
          </Cell>
        ))
      }
    </ExtendedFlexRow>
  );
};


const CssActiveBorder = css`
  border: 1px solid #033e52;
`;

const CssActiveFont = css`
  //font-weight: 600;
  font-weight: bold;
`;

const CssDisabledButton = css`
  font-weight: 600;
  color: rgba(255, 255, 255, .5)
`;

const StyledImageButtonWrap = styled.div`
  width: 64%;
  min-width: 64px;
  border-radius: 50%;
  &>div{
    padding-top: 100%; // aspect ratio
  }
  border: 1px solid #e8e8e8;
  ${props => props.active && CssActiveBorder};
  ${props => props.active && CssActiveFont};
`;

const StyledTitle = styled.div`
  ${props => props.height && `height:${props.height}`};
  font-size: 13px;
  margin-top: 10px;
  text-align: center;
  word-break: keep-all;
  letter-spacing: -0.6px;
`;

const StyledButtonWrap = styled(Flex)`
  width: inherit;
  height: 56px;
  background-color: #fff;
  border-radius: 4px;
  border: solid 1px #e1e3e2;
  ${props => props.active && CssActiveBorder};
  ${props => props.active && CssActiveFont};
`;

const StyledTextButton = styled.div`
  width: 100%;
  text-align: center;
  font-size: 13px;
  letter-spacing: -.6px;
`;

const StyledCardButtonWrap = styled(Flex)`
  width: 100%;
  flex-direction: row;
  justify-content: left;
  border: 1px solid #e1e3e2;
  ${props => props.active && CssActiveBorder};
  ${props => props.active && CssActiveFont};
  border-radius: 4px;
  ${props => (props.description
    ? css`
   padding: 20px 0 20px 15px;
  `
    : css`
    padding: 10px 18px;
  `
  )};

  .text{
    letter-spacing: -0.6px;
    font-size: 13px;
    ${props => (props.description ? 'margin-left:10px' : 'margin-left:29px')}
  }
  .title{
    font-weight: bold;
  }
  .description{
    letter-spacing: -1px;
  }

  .image-box{
    ${props => (props.description ? css`
      width: 58px; height: 58px;
` : css`
      width: 36px; height: 36px;
`)}
  }

`;


const StyledRoundButtonWrap = styled(Flex)`
  background-color: #fff;
  border-radius: 50%;
  border: solid 1px #e1e3e2;
  ${props => props.active && CssActiveBorder};
  ${props => props.active && CssActiveFont};
  position: relative; // aspect ratio
  padding-top: 100%; // aspect ratio
  width: 100%;
  &> div { // to center an element vertically
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
`;


const StyledImageRoundButtonWrap = styled(Flex)`
  background-color: #fff;
  border-radius: 50%;
  border: solid 1px #e1e3e2;
  ${props => props.active && CssActiveBorder};
  ${props => props.active && CssActiveFont};
  width: 120px;
  max-width: 100%;
  background-image: url(${props => props.bg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: initial;
`;


const StyledImageRoundButton = styled.div`
  width: 100%;
  position: relative; // aspect ratio
  padding-top: 100%; // aspect ratio
  &> div { // to center an element vertically
    position: absolute;
    bottom: -30px;
    letter-spacing: -.6px;
  }
`;

const StyledStretchButton = styled.button.attrs(props => ({
  borderColor: props.white ? '#e1e3e2' : '#033e52',
  bgColor: props.white ? '#fff' : '#033e52',
  color: props.white ? '#000' : '#fff',
  radius: props.fixed ? '0' : '4px',
}))`
  width: 100%;
  height: inherit;
  border-radius: ${props => props.radius};
  color: ${props => props.color};
  border: solid 1px ${props => props.borderColor};
  background-color: ${props => props.bgColor};
  ${props => props.disabled && CssDisabledButton}
  ${props => props.active && CssActiveBorder};
  ${props => props.active && CssActiveFont};
  &.fs-14 div{
    font-size: 14px;
  }
`;

const ReturnNewLine = text => (
  text.split('\n').map((t, i) => (
    <span key={i}>
      {t}
      <br />
    </span>
  ))
);

export const StretchButton = ({ children, ...others }) => (
  <StyledStretchButton {...others}>
    <StyledTextButton>{children}</StyledTextButton>
  </StyledStretchButton>
);

export const CreateButton = (onClick, buttonType, title, description, image, active) => {
  const button = {
    imageButton: (() => (
      <>
        <StyledImageButtonWrap
          className={`type-image-button${active && 'active'}`}
          onClick={onClick}
          active={active}
        >
          <Image
            radius={50}
            imgUrl={image}
          >

            image
          </Image>
        </StyledImageButtonWrap>
        <StyledTitle height="40px">{ReturnNewLine(title)}</StyledTitle>
      </>
    ))(),
    textButton: (() => (
      <StyledButtonWrap
        className={`type-text-button ${active && 'active'}`}
        onClick={onClick}
        active={active}
      >
        <StyledTextButton>{title}</StyledTextButton>
      </StyledButtonWrap>
    ))(),
    cardButton: (() => (
      <StyledCardButtonWrap
        description={description}
        className={`type-card-button ${active && 'active'}`}
        onClick={onClick}
        active={active}
      >
        <div className="image-box">
          <Image
            radius={58}
            imgUrl={image}
          />
        </div>
        <div className="text">
          { description
            ? (
              <>
                <div className="title">{title}</div>
                <div className="description">{description}</div>
              </>
            )
            : (
              <>
                <div>{title}</div>
              </>
            )
          }

        </div>
      </StyledCardButtonWrap>
    ))(),
    roundButton: (() => (
      <StyledRoundButtonWrap
        className={`type-round-button ${active && 'active'}`}
        onClick={onClick}
        active={active}
      >
        <StyledTextButton>{title}</StyledTextButton>
      </StyledRoundButtonWrap>
    ))(),
    imageRoundButton: (() => (
      <StyledImageRoundButtonWrap
        className={`type-image-round-button ${active && 'active'}`}
        onClick={onClick}
        active={active}
        bg={image}
      >
        <StyledImageRoundButton>
          <StyledTextButton>{title}</StyledTextButton>
        </StyledImageRoundButton>
      </StyledImageRoundButtonWrap>
    ))(),
  };
  return button[buttonType];
};

export const createComponent = (onClick, cType, photo) => {
  const component = {
    descComponent: (() => (
      <DescComponent onClick={onClick} />
    ))(),
    photoComponent: (() => (
      <PhotoComponent onClick={onClick} />
    ))(),
    nameComponent: (() => (
      <NameComponent onClick={onClick} />
    ))(),
    phoneComponent: (() => (
      <PhoneComponent onClick={onClick} />
    ))(),
    passwordComponent: (() => (
      <PasswordComponent onClick={onClick} />
    ))(),
    memberPasswordComponent: (() => (
      <MemberPasswordComponent onClick={onClick} />
    ))(),
    processComponent: (() => (
      <ProcessComponent photo={photo} />
    ))(),
    cityComponent: (() => <CityComponent onClick={onClick} />)(),
    selectAddressComponent: (() => <SelectAddressComponent onClick={onClick} />)(),
  };
  return component[cType];
};
