import React, { Component } from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import { Redirect, withRouter } from 'react-router-dom';
import { getComplimentQuery } from '../graphql';
import { media } from '../globalStyle';
import MaskBackgroundImageDesktop from '../images/mask_imgs/d_mask_bg.png';
import MaskBackgroundImageMobile from '../images/mask_imgs/m_mask_bg@2x.png';
import MaskHospitalNameImageDesktop from '../images/mask_imgs/d_mask_h_name_88.png';
import MaskHospitalNameImageMobile from '../images/mask_imgs/m_mask_h_name_88@2x.png';
import EmptyNavigationBar from '../components/basic/EmptyNavigationBar';
import btnHeartOn from '../images/ic-like-active-24.svg';

const DimOverlay = styled.div`
  position:fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  background-color: #000;
  opacity: 0.32;
  z-index:1001;
`;

const OverlayMessage = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 460px;
  height: 290px;
  margin-top: -170px;
  margin-left: -230px;
  z-index: 1002;
  ${media.tablet`
    margin-left: 0px;
    left: 0;
    width: 100%;
    padding: 0 20px;
  `};
`;

const OverlayWhite = styled.div`
  background-color: #fff;
  padding: 32px 50px 36px 50px;
`;

const SignupContainer = styled.div`
  margin-top: 16px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  letter-spacing: -1px;
`;

const SignupATag = styled.a`
  cursor: pointer;
  margin-left: 5px;
  letter-spacing: -1px;
  color: #1f8ae3;
  text-decoration: underline;
`;

const Header = styled.div`
  margin-bottom: 26px;
  padding-top: 76px;
  @media (max-width: 576px) {
    margin: 0;
  }
`;

const HospitalName = styled.div`
  font-size: 34px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #484848;
  margin-right: 12px;
  display: inline-block;
  vertical-align: middle;
  @media (max-width: 576px) {
    font-size: 20px;
  }
`;

const BtnFavorite = styled.div`
  cursor: pointer;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  width: 71px;
  height: 36px;
  border-radius: 18px;
  border: solid 1px #e0e0e0;
  span {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #767676;
    display: inline-block;
    vertical-align: middle;
    margin-top: 3px;
  }
  @media (max-width: 576px) {
    width: 54px;
    height: 28px;
  }
`;

const FavoriteImg = styled.img`
  width: 32px;
  height: 32px;
  object-fit: contain;
  margin-right: 2px;
  display: inline-block;
  vertical-align: middle;
  @media (max-width: 576px) {
    width: 24px;
    height: 24px;
  }
`;

const HospitalRealNameContainer = styled.div`
  position: relative;
  max-width: 1040px;
  margin: 0 auto;
`;

const MaskHospitalName = styled.div`
  position: relative;
  max-width: 1040px;
  height: 88px;
  margin: 0 auto;
  background: url(${MaskHospitalNameImageDesktop}) no-repeat 0 0;
  background-size: contain;
  ${media.tablet`
    display: none;
    /* TODO : 위 아래가 cover 로 어떻게 맞춰야 할 지 모르겠어서 일단 대충 none 으로 처리.. (kosick) */
    /* margin-top: 71px;
    /* background: url(${MaskHospitalNameImageMobile}) no-repeat 0 0; */ */
  `};
`;

const MaskBackground = styled.div`
  position: relative;
  height: 1252px;
  background-image: url(${MaskBackgroundImageDesktop});
  background-size: contain;
  background-repeat: no-repeat;
  ${media.tablet`
    background-position-x: center;
    background-image: url(${MaskBackgroundImageMobile});
  `};
`;

const FromWhomContainer = styled.div`
  height: 54px;
  margin: 0 auto;
`;

const FromWhom = styled.span`
  font-size: 24px;
  color: #000000;
  strong {
    font-weight: bold;
  }
  ${media.tablet`
    font-size: 16px;
    letter-spacing: -1px;
  `};
`;

const ComplimentContainer = styled.div`
  width: 360px;
  margin-top: 33px;
  ${media.tablet`
    max-width: 320px;
    width: 100%;
  `};
`;

const ComplimentDesc = styled.div`
  font-size: 16px;
  line-height: 1.63;
  color: #484848;
  word-wrap: break-word;
  ${media.tablet`
    font-size: 14px;
    letter-spacing: -1px;
  `};
`;

const ConfirmBtn = styled.button`
  display: block;
  position: relative;
  height: 52px;
  width: 100%;
  background-color: #033e52;
  font-size: 16px;
  font-weight: bold;
  line-height: 52px;
  text-align: center;
  margin: 0 auto;
  margin-top: 24px;
  color: #ffffff;
  img {
    position: absolute;
    top: 20px;
    right: 20px;
    height: 20px;
    width: 20px;
  }
  ${media.tablet`
    font-size: 16px;
  `};
`;

const InnerContainer = styled.div`
  padding: 0 20px;
  max-width: 1080px;
  width: 100%;
  margin: auto;
`;

class HsDemo extends Component {
  goToPartnerLoginPage = (compId) => {
    // window.open(`${process.env.REACT_APP_PARTNER_APP_URL}/login?compId=${compId}`);
    // 원래는 위와 같았는데 그냥 아래처럼 무조건 회원가입 페이지로 보내기로 바꿈. (2018/12/12)
    window.open(`${process.env.REACT_APP_PARTNER_APP_URL}/complimentsignup?compId=${compId}`);
  }

  goToPartnerSignupPage = (compId) => {
    window.open(`${process.env.REACT_APP_PARTNER_APP_URL}/complimentsignup?compId=${compId}`);
  }

  render() {
    const { compId } = this.props.match.params;

    return (
      <Query query={getComplimentQuery} variables={{ compId }}>
        {({ loading, error, data }) => {
          if (loading) return null;
          if (error) return `Error! ${error.message}`;

          const { getCompliment } = data;

          if (!getCompliment) {
            return <Redirect to={{ pathname: '/404' }} />;
          }

          let descMsg = getCompliment.desc;

          {/* TODO: Line break(\n) 안되는데? */}
          if (getCompliment.desc.length > 44) {
            descMsg = getCompliment.desc.substring(0, 44) + '...';
          }

          let hospitalName = '';

          if (getCompliment.toHosName) {
            hospitalName = getCompliment.toHosName;
          } else if (getCompliment.toHuser && getCompliment.toHuser.hospital && getCompliment.toHuser.hospital.name) {
            hospitalName = getCompliment.toHuser.hospital.name;
          }

          return (
            <div>
              <EmptyNavigationBar />
              <InnerContainer>
                <Header>
                  {hospitalName ? (
                    <HospitalRealNameContainer>
                      <HospitalName>{hospitalName}</HospitalName>
                      <BtnFavorite>
                        <FavoriteImg src={btnHeartOn} alt="" />
                        <span>6</span>
                      </BtnFavorite>
                      {/* {hospital.metadata &&
                      hospital.metadata.intro && (
                        <HospitalIntro>
                          {hospital.metadata && hospital.metadata.intro}
                        </HospitalIntro>
                      )} */}
                    </HospitalRealNameContainer>
                  ) : (
                    <MaskHospitalName />
                  )}
                </Header>
                <MaskBackground>
                  <DimOverlay />
                </MaskBackground>
                <OverlayMessage>
                  <OverlayWhite>
                    <FromWhomContainer>
                      <FromWhom>
                        <strong>'{data.getCompliment.fromHuser.username}'원장님</strong>이
                      </FromWhom>
                      <br />
                      <FromWhom>보낸 칭찬이 도착했습니다!</FromWhom>
                    </FromWhomContainer>
                    <ComplimentContainer>
                      <ComplimentDesc>
                        {descMsg}
                      </ComplimentDesc>
                    </ComplimentContainer>
                    <ConfirmBtn onClick={this.goToPartnerLoginPage.bind(this, compId)}>
                      칭찬 확인하기
                    </ConfirmBtn>
                  </OverlayWhite>
                  <SignupContainer>
                    <span>앗! 아직 회원이 아니세요?</span>
                    <SignupATag onClick={this.goToPartnerSignupPage.bind(this, compId)}>회원가입 하러가기 ></SignupATag>
                  </SignupContainer>
                </OverlayMessage>
                {/* <pre>{JSON.stringify(data, null, 4)}</pre> */}
              </InnerContainer>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default withRouter(HsDemo);
