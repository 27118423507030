import React, { Component } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Card } from '../components/atomic/molecules/Card';
import withAlertModal from '../utils/alerthoc';
import { ContentsList } from '../data/OdocContents';

const StyledSlider = styled(Slider)`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: #000000;
  .slick-list {
    padding: 0 0;
    margin-right: -20px;
    margin-left: -20px;
    .slick-track{
      margin-left: 20px;
    }
  }
  .slick-slide {
    width: 237px;
    margin-right: 10px;
  }
  .card-title {    
    font-weight: bold;
    line-height: 1.71;
    letter-spacing: -0.44px;
    text-align: left;
    margin-top: 9px;
    margin-bottom: 0;
    .orange {
      line-height: 1.71;
      letter-spacing: normal;
      color: #dd4124;
      font-family: Saira Semi Condensed;
      font-size: 14px;
      font-weight: 600;
      font-stretch: semi-condensed;
    }
  }
  .card-text {
    line-height: 1.43;
    letter-spacing: -0.4px;
    color: #b1b3b2;
  }
`;

const ContentDoorImg = styled.img`
  display: block;
  width: 237px;
  height: 133px;
  border-radius: 4px;
`;

const CardWrapper = styled.div`
  display: inline-block;
`;

// // comming soon 표시 예제
// alert: {
//       msg: '1월 중 오픈될 예정입니다!\n알림을 신청하시면 충치치료가 오픈될 때 알려드려요.\n오픈 알림을 신청하시겠어요?',
//       url: 'https://forms.gle/1jPkizp5oRXhP8YU7',
//     },
// <React.Fragment>
//   <b className="orange">[Soon]</b>
//   {' '}
//   충치치료 A to Z
// </React.Fragment>

class MainOdoctorLounge extends Component {
  onClick = (path, alert) => {
    const { history } = this.props;
    if (path) {
      history.push(path);
      return;
    }

    if (alert && alert.msg && alert.url) {
      const { openAlert, closeAlert } = this.props;
      openAlert({
        title: '알림',
        message: (
          <div>
            {alert.msg}
          </div>
        ),
        showConfirmBtn: true,
        confirmBtnName: '확인',
        confirmBtnOnClick: () => window.open(alert.url),
        showCancelBtn: true,
        cancelBtnOnClick: closeAlert,
        textAlign: 'left',
      });
    }
  }

  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      className: 'odoc-slide',
      arrows: false,
    };
    return (
      <StyledSlider {...settings}>
        {ContentsList.map((content, idx) => (
          <CardWrapper key={`${idx}_${content.title}`} onClick={() => this.onClick(content.path, content.alert)}>
            <Card
              width="100%"
              height="auto"
              imgRender={() => <ContentDoorImg src={content.img} />}
              title={content.title}
            >
              {content.desc}
            </Card>
          </CardWrapper>
        ))}
      </StyledSlider>
    );
  }
}

export default withAlertModal(withRouter(MainOdoctorLounge));
