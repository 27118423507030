const channelPluginSettings = {
  pluginKey: 'bda73575-86c9-4fd6-ac68-6e91d49d06de',
  zIndex: 99,
  customLauncherSelector: '.custom-channel-btn', // https://developers.channel.io/docs/web-channelpluginsettings
  hideDefaultLauncher: true, // 필요한 route 에서만 문의하기 버튼을 노출한다
};

export default function channel() {}

export const openChannelWindow = () => {
  window.ChannelIO('show');
};

export const channelCheckIn = (user) => {
  window.ChannelIO('shutdown');
  window.ChannelIO('boot', { profile: user, ...channelPluginSettings });
};

export const channelCheckOut = () => {
  window.ChannelIO('shutdown');
  window.ChannelIO('boot', channelPluginSettings);
};

export const initialize = () => {
  const w = window;
  if (w.ChannelIO) {
    return (window.console.error || window.console.log || function () {})('ChannelIO script included twice.');
  }
  var ch = function () {
    ch.c(arguments);
  };
  ch.q = [];
  ch.c = function (args) {
    ch.q.push(args);
  };
  w.ChannelIO = ch;
  function l() {
    if (w.ChannelIOInitialized) {
      return;
    }
    w.ChannelIOInitialized = true;
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
    s.charset = 'UTF-8';
    const x = document.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);
  }
  if (document.readyState === 'complete') {
    l();
  } else if (window.attachEvent) {
    window.attachEvent('onload', l);
  } else {
    window.addEventListener('DOMContentLoaded', l, false);
    window.addEventListener('load', l, false);
  }
};
