import React, { Component } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import { media } from '../globalStyle';

import { Card } from '../components/atomic/molecules/Card';
import PhoneImg from '../images/home/phone_bg@2x.png';
import Process1Img from '../images/home/step_01@3x.jpg';
import Process2Img from '../images/home/step_02@3x.jpg';
import Process3Img from '../images/home/step_03@3x.jpg';

const ShorcutImgComponent = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  .background-img {
    width: 100%;
    position: relative;
    z-index: 3;
  }
  .top-img {
    width: 100%;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
  }
  + hr {
    margin: 0 20px;
    border-color: #f0f2f1;
  }
  .card-text{
    letter-spacing: -.5px;
  }
`;

const StyledSlider = styled(Slider)`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.4px;
  text-align: center;
  color: #000000;
  .card-title {    
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 4px
  }
  .card-text {
    white-space: pre-wrap;
    margin-bottom: 0;
  }
  .slick-dots {
    bottom: -36px;
    li {
      margin: 0 7px;
      width: 6px;
      height: 6px;
      button {
        width: 6px;
        height: 6px;
        padding: 0;
      }
      button:before {
        width: 6px;
        height: 6px;
        opacity: 1;
        color: #e1e3e2;
        font-size: 6px;
        line-height: 6px;
      }
    }
    li.slick-active {
      button:before {
        opacity: 1;
        color: #033e52;
      }
    }
  }
`;

const Title = styled.div`
  margin-bottom: 60px;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  .top {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.71;
    letter-spacing: -0.44px;
    color: #033e52;
    margin-bottom: 10px;
  }
  .bottom {
    font-size: 24px;
    font-weight: 300;
    line-height: 1.33;
    letter-spacing: -0.5px;
    color: #000000;
  }
`;

const MobileShortcutImg = ({ src }) => (
  <React.Fragment>
    <ShorcutImgComponent>
      <img className="background-img" src={PhoneImg} />
      <img className="top-img" src={src} />
    </ShorcutImgComponent>
    <hr />
  </React.Fragment>
);

const shortcutList = [
  {
    title: '상담신청',
    description: '모든 치과를 방문할 필요 없이,\n3분 만에 손안에서 무료 상담 신청',
    img: Process1Img,
  },
  {
    title: '답변확인',
    description: '예상 치료비까지 포함된\n우리 동네 치과들의 답변을 받고, 비교할 수 있어요',
    img: Process2Img,
  },
  {
    title: '내원예약',
    description: '가장 마음에 드는 치과를 예약하고,\n시간과 비용을 아끼세요',
    img: Process3Img,
  },
];

const StyledSliderWrap = styled.div`
  .no-slide{
      display:flex;
      * {
        text-align: center;
      }
      .card-text{
        margin: 0 10px 10px;
      }
  }
  .slide{
    display: none;
  }
  ${media.tablet`
    .no-slide{
      display: none;
    }
    .slide{
      display: block;
     }
  `};
`;

class MainOdoctorShortcut extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      arrows: false,
    };
    return (
      <StyledSliderWrap>
        {/* title 영역 */}
        <Title>
          <div className="top">대한민국 1등 치과 상담실 오닥터</div>
          <div className="bottom">
            <b>한번에</b>
            {' '}
            모든 치과와 상담받고
            <br />
            시간과 비용을
            {' '}
            <b>절약</b>
            하세요
          </div>
        </Title>

        {/**/}

        <div className='no-slide'>
          {shortcutList.map(shortcut => (
            <Card
              width="100%"
              height="auto"
              imgRender={() => <MobileShortcutImg src={shortcut.img} />}
              title={shortcut.title}
              key={`main-shorcut_${shortcut.title}`}
            >
              {shortcut.description}
            </Card>
          ))}
        </div>
        {/* carousel 부분 */}
        <StyledSlider {...settings} className='slide'>
          {shortcutList.map(shortcut => (
            <Card
              width="100%"
              height="auto"
              imgRender={() => <MobileShortcutImg src={shortcut.img} />}
              title={shortcut.title}
              key={`main-shorcut_${shortcut.title}`}
            >
              {shortcut.description}
            </Card>
          ))}
        </StyledSlider>
      </StyledSliderWrap>
    );
  }
}

export default MainOdoctorShortcut;
