import React, { Component } from 'react';
import gql from 'graphql-tag';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';

import NoticeDetailComponent from '../../components/Manual/NoticeDetailComponent';

class NoticeDetailContainer extends Component {
  render() {
    const { data: { getAnnouncement, loading } } = this.props;

    if (loading) {
      return <div>loading...</div>;
    }

    return (
      <NoticeDetailComponent
        notice={getAnnouncement}
        goToNoticeList={() => this.props.history.push('/manual/notice')}
      />
    );
  }
}

const announcement = gql`
  query($id: ID!) {
    getAnnouncement(id: $id) {
      id
      title
      description
      importance
      createdAt
    }
  }
`;

export default graphql(announcement, {
  options: ({ id }) => ({
    variables: { id },
  }),
})(withRouter(NoticeDetailContainer));
