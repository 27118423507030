import styled from 'styled-components';
import { media } from '../../globalStyle';

const RouteHead = styled.div`
  width: 100%;
  border-bottom: 1px solid #000000;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
  padding-bottom: 10px;
  margin-bottom: 30px;
  span {
    float: right;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: -1px;
    text-align: right;
    color: #000000;
    margin-top: 11px;
  }
  ${media.tablet`
    margin-top: 16px;
    margin-bottom: 16px;
    font-size: 16px;
    span {
      font-size: 13px;
      margin-top: 3px;
    }
  `};
`;

export const MyPageRouteHead = styled.div`
  width: 100%;
  border-bottom: 1px solid #000000;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
  padding-bottom: 10px;
  margin-bottom: 30px;
  span {
    float: right;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: -1px;
    text-align: right;
    color: #000000;
    margin-top: 11px;
  }
  ${media.tablet`
    display: none;
  `};
`;

export default RouteHead;
