import React from 'react';
import styled from 'styled-components';
import FormInput from './FormInput';

const Timer = styled.div`
  position: absolute;
  right: 8px;
  top: 17px;
  cursor: pointer;
  z-index: 2;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #dd4124;
`;

const PinNumContainer = styled.div`
  position: relative;
  width: 100%;
`;

class PinNumInput extends React.Component {
  onChange = (e) => {
    const { value } = e.target;
    if (value.match(/^\d+$/) || value === '') {
      this.props.onChange(e);
    }
  };

  render() {
    const {
      name,
      value,
      placeholder,
      onChange,
      errorMsg,
      fireSubmitValidation,
      onBlur,
      readOnly,
      disabled,
      children,
      onKeyPress
    } = this.props;

    return (
      <PinNumContainer>
        <FormInput
          autoFocus
          type="tel"
          name={name}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          autoComplete="off"
          errorMsg={errorMsg || ''}
          onBlur={onBlur}
          maxLength="4"
          readOnly={readOnly}
          disabled={disabled}
          style={{ paddingRight: '45px', letterSpacing: '0px' }}
          fireSubmitValidation={!value && fireSubmitValidation}
          onKeyPress={onKeyPress}
        />
        <Timer>
          {children}
        </Timer>
      </PinNumContainer>
    );
  }
}

export default PinNumInput;
