import React from 'react';
import ImageGallery from 'react-image-gallery';
import styled from 'styled-components';
import LeftArrow from '../../images/ic-arrow-left-white-22-px.svg';
import { media } from '../../globalStyle';

const Photo = styled.img`
  width: 100%;
  height: 200px;
  background-color: #e0e0e0;
  object-fit: cover;
  vertical-align: middle;
  ${media.miniDesktop`
    height: 180px;
  `};
  ${media.tablet`
    height: 168px;
  `};
`;

const NavBtn = styled.button`
  width: 70px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.4)
  );
  background-color: transparent;
  padding: 0;
  display: none;
  img {
    display: inline-block;
    vertical-align: middle;
  }
  ${props =>
    props.flip &&
    `
    img {
      transform: rotate(-180deg);
    }
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
    right: 0;
    left: auto;
    left: unset;
  `};
`;

const ImageGalleryContainer = styled.div`
  .image-gallery-bullets .image-gallery-bullet {
    padding: 3px;
    border: none;
    box-shadow: none;
    background-color: #ffffff;
    opacity: 0.8;
    box-shadow: 1px 1px rgba(0, 0, 0, 0.2);
  }
  .image-gallery-bullets .image-gallery-bullet.active {
    padding: 4px;
    opacity: 1;
  }
  &:hover {
    ${NavBtn} {
      display: block;
    }
  }
  ${media.tablet`
    display: none;
  `};
`;

const SinglePhotoGallery = styled.div`
  display: none;
  ${media.tablet`
    display: block;
  `};
`;

const PhotoDefault = styled.div`
  width: 100%;
  height: 200px;
  background-color: #e1e3e2;
  vertical-align: middle;
  ${media.miniDesktop`
    height: 180px;
  `};
  ${media.tablet`
    height: 168px;
  `};
`;


const HospitalImageCarouselContainer = styled.div`
  ${props => props.theme === 'local' && `
    ${Photo}, ${PhotoDefault} {
      height: 127px; 
    }
  `};

  ${media.tablet`
    ${props => props.theme === 'local' && `
      ${Photo}, ${PhotoDefault} {
        height: 168px;
      }
    `};
  `};
`;


class HospitalImageCarousel extends React.Component {
  renderLeftNav = (onClick, disabled) => (
    <NavBtn
      disabled={disabled}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      <img src={LeftArrow} alt="병원사진" />
    </NavBtn>
  );

  renderRightNav = (onClick, disabled) => (
    <NavBtn
      flip
      disabled={disabled}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      <img src={LeftArrow} alt="병원사진" />
    </NavBtn>
  );

  renderItem = item => <Photo src={item} alt="hospitalPhoto" />;

  render() {
    const { photos } = this.props;

    return (
      <HospitalImageCarouselContainer theme={this.props.theme}>
        {photos && photos.length > 0 ? (
          <React.Fragment>
            <ImageGalleryContainer>
              <ImageGallery
                items={photos}
                showThumbnails={false}
                showFullscreenButton={false}
                showPlayButton={false}
                showBullets={photos && photos.length > 1}
                renderItem={this.renderItem}
                renderLeftNav={this.renderLeftNav}
                renderRightNav={this.renderRightNav}
              />
            </ImageGalleryContainer>
            <SinglePhotoGallery>
              <Photo src={photos[0]} />
            </SinglePhotoGallery>
          </React.Fragment>
        ) : (
          <PhotoDefault />
        )}
      </HospitalImageCarouselContainer>
    );
  }
}

export default HospitalImageCarousel;
