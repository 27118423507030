import React from 'react';
import styled from 'styled-components';
import { Flex } from '../atoms/Flex';
import Cell from '../atoms/Cell';

const ExtendedFlexRow = styled(Flex)`
  margin-left: -5px;
  margin-right: -5px;
`;

/**
 * @description Grid UI
 * @param children
 * @param render
 * @param cols 열의 갯수
 * @param className StyledComponent 확장할때 className 받는 용도
 * @param column Cell 안의 컨테츠 정렬 flex-direction
 * @param items
 * @returns {*}
 * @constructor
 */
export const FlexGrid = ({
  children,
  render,
  cols,
  className,
  column,
  items,
}) => {
  const array = Array.isArray(items) ? items : [];

  return (
    <ExtendedFlexRow>
      {array.map((t, i) => (
        <Cell key={i} cols={cols} column={column} className={className}>
          {render ? render(t) : children}
        </Cell>
      ))}
    </ExtendedFlexRow>
  );
};

export default FlexGrid;
