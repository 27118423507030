import ReactGA from 'react-ga';
import moment from 'moment';
import 'moment/locale/ko';
import axios from 'axios';
import { sendSlack } from './slack';
import MobileDetect from './mobileDetect';

let hidden;
let visibilityChange;
if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}


// If the page is hidden, pause the video;
// if the page is shown, play the video
function handleVisibilityChange() {
  if (document[hidden]) {
    // userActivitySendSlack('?', 'user-out');
  } else {
  }
}

// Warn if the browser doesn't support addEventListener or the Page Visibility API
if (typeof document.addEventListener === 'undefined' || hidden === undefined) {
  console.log('This demo requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API.');
} else {
  // Handle page visibility change
  document.addEventListener(visibilityChange, handleVisibilityChange, false);
}

const simpleGUID = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return `${s4()}${s4()}-${s4()}`;
};

const ogid = localStorage.getItem('ogid') || simpleGUID();
localStorage.setItem('ogid', ogid);

const md = new MobileDetect(window.navigator.userAgent);

const isMobile = md.phone() ? `${md.os()},${md.userAgent()}` : 'PC,';
const referer = document.referrer ? document.referrer.replace(/http:\/\/|https:\/\//, '') : 'None';
const utm = window.location.search || 'None';

const findActivityMessage = (activity, name) => {
  let message = '';

  if (activity === 'in-step1') {
    message = '치료 선택(step1)';
  } else if (activity === 'in-step2') {
    message = '유저 정보 입력!(step2)';
  } else if (activity === 'in-step3') {
    message = '핸드폰 번호 입력!(step3)';
  } else if (activity === 'focus-pn-input') {
    message = '핸드폰 번호  입력시도(step3)';
  } else if (activity === 'blur-pn-input') {
    message = '핸드폰 번호 입력완료(step3)';
  } else if (activity === 'done-pin') {
    message = '핸드폰 인증 완료!(step3)';
  } else if (activity === 'send-pin') {
    message = '핸드폰 인증번호 발송(step3)';
  } else if (activity === 'focus-name-input') {
    message = '이름 입력시도(step3)';
  } else if (activity === 'blur-name-input') {
    message = `"${name}"이름 입력완료(step3)`;
  } else if (activity === 'focus-pw-input') {
    message = '비밀번호 입력시도(step3)';
  } else if (activity === 'blur-pw-input') {
    message = '비밀번호 입력완료(step3)';
  } else if (activity === 'done-all') {
    message = '신청완료!╭( ･ㅂ･)و';
  } else if (activity === 'user-out') {
    message = '나감';
  } else if (activity === 'confirmHosResps') {
    message = '유저가 맞춤상담을 확인하였습니다.';
  } else {
    message = '알 수 없는 유저 행동입니다. 개발자에게 문의해주세요.';
  }

  return message;
};

// Slack 로깅.
export const userActivitySendSlack = async (mobileOrDesktop = '?', activity, value) => {
  const now = moment().format('YY-MM-DD HH:mm:ss');
  const env = process.env.REACT_APP_NODE_ENV || 'development';
  const message = findActivityMessage(activity, value);
  const msg = `${ogid},${isMobile},${env},${message},${referer},${utm},${now}`;
  await sendSlack(msg, true); // 여기 두번째 인자를 true 로 넣으면 개발 환경에서도 슬랙을 받아볼 수 있다.
};


// userName: me.username,
//   userId: me.id,
//   userPhone: me.phonenum,
//   hName:hospital.name,
//   hospitalId: hospital.id,
//   answerId: userRequest.id,
export const userPhoneCallSendSlack = async (
  {
    userName,
    userId,
    userPhone,
    hospitalName,
    hospitalId,
    hospitalPhone,
    answerId,
  },
) => {
  moment.locale('ko');
  const now = moment().format('LLLL');
  const msg = ':phone:\n[병원 콜]\n'
    + `유저: ${userName}(${userId}) / ${userPhone}\n`
    + `병원: ${hospitalName}(${hospitalId}) / ${hospitalPhone || '전화번호 미입력'}\n`
    + `답변 아이디: ${answerId}\n`
    + `시간: ${now}\n`;
  await sendSlack(msg, true);
};

// production에서만 ga 정보 수집하도록
const isProductionEnv = () => process.env.REACT_APP_NODE_ENV === 'production';

// GA pageview log
export const logPageView = (pathname) => {
  if (isProductionEnv()) {
    ReactGA.pageview(pathname);
  }
};

// GA event log
export const logEvent = ({
  category, action, label, value,
}) => {
  if (!category || !action) {
    return;
  }

  const evtObj = {
    category,
    action,
    label,
  };

  if (value !== undefined && value !== null) {
    evtObj.value = value;
  }

  if (isProductionEnv()) {
    ReactGA.event(evtObj);
  }
};

// retargeting marketing
export const addADNTag = (ut, uo) => {
  if (isProductionEnv()) {
    const adnParam = window.adn_param || [];
    adnParam.push([{
      ui: '101125',
      ut,
      uo,
    }]);
  }
};

const url = window.location.search;

function getJsonFromUrl(url) {
  if (!url) url = window.location.search;
  const query = url.substr(1);
  const result = {};
  query.split('&')
    .forEach((part) => {
      const item = part.split('=');
      result[item[0]] = decodeURIComponent(item[1]);
    });
  return result;
}

export const setSheet = (obj) => {
  const payload = {
    userId: ogid,
    referrer: referer,
    ...{
      utm_campaign: '',
      utm_source: '',
      utm_medium: '',
      utm_content: '',
      utm_term: '',
      funnel: '',
      ...getJsonFromUrl(url),
    },
    env: process.env.REACT_APP_NODE_ENV,
    ...obj,
  };

  const convertData = Object.entries(payload)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
  const productionSheet = 'https://script.google.com/macros/s/AKfycbzeonktlikptyizJXJOvmR_ssKfe7CXKy1EtyLU7smPbbgCRMI/exec';
  const testSheet = 'https://script.google.com/macros/s/AKfycbyicht2vfI3JN4ez07GIokGY42smVWRwFKw8rorCTdsvw8J_ltS/exec';
  const sheetUrl = isProductionEnv() ? productionSheet : testSheet;

  axios.post(
    sheetUrl,
    convertData,
    {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  )
    .then((response) => {
      console.log(response);
    })
    .catch((error) => {
      console.log(error);
    });
};

export default {};
