import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import withAlertModal from '../../utils/alerthoc';
import { therapyInfoById } from '../form/therapyInfo';
import {
  getOrthoPartName,
  currencyFormat,
} from '../../utils/req-utils';
import { media } from '../../globalStyle';
import { DayTimerComponent } from '../ImporvedCounsel/TimerComponent';
import invisibleOrtho from '../../images/draftform/ic-inivisible.svg';
import metalImg from '../../images/draftform/ic-visible.svg';
import transpOrthoImg from '../../images/draftform/ic-translate.svg';
import { convertTxPricesResponseToObj, convertOrthoPricesResponseToObj } from '../../utils/convertRespPrices';
import BgIng from '../../images/bg-ing.png';
import BgEnd from '../../images/bg-ed.png';
import { Flex } from '../atomic/atoms/Flex';

const GtMark = () => (
  <svg style={{ marginLeft: '5px' }} width="7px" height="9px" viewBox="0 0 7 9" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
    <g id="02_outcome" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
      <g id="user-/-02_outcome-/-2_detail-/-event_ing_ing" transform="translate(-254.000000, -638.000000)" stroke="#FFFFFF" strokeWidth="2">
        <g id="01_coupon-copy-6" transform="translate(30.000000, 501.000000)">
          <g id="Group-2" transform="translate(69.000000, 135.000000)">
            <g id="btn-/-move_white" transform="translate(152.000000, 2.000000)">
              <g id="Rectangle">
                <polyline transform="translate(4.500000, 4.500000) rotate(-315.000000) translate(-4.500000, -4.500000) " points="2 2 7 2 7 7" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const Container = styled.div`
  font-family: 'Saira Semi Condensed', sans-serif;
  vertical-align: top;
  background-color: #ffffff;
  border: 0;
  border-top: solid 1px #e4e4e4;
  padding: 30px 0 90px;
  position: relative;
`;

const StyledCouponContainer = styled.div.attrs(props => ({
  type: 'password',
  size: props.size || '1em',
  couponBtnColor: props.isExpired ? '#000' : '#fff',
}))`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  .coupon-box{
    position: relative;
    background: url(${props => (props.isExpired ? BgEnd : BgIng)}) no-repeat center center;
    background-size: contain;
    width: 300px; 
    height: 167px;
  }
  .coupon-info{
    width: 100%;
    height: 115px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 15px;
    &>div{
      color: #fff;
    }
    .r-info, .l-info{
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: auto;
      justify-content: space-between;
      .desc{
        font-size: 12px;
        font-family: 'Saira Semi Condensed', sans-serif;
        font-weight: 600;
      }
      .dc-rate{
        font-size: 64px;
        font-weight: bold;
        letter-spacing: -.5px;
        line-height: 1;     
      }
      .dc-rate-num{
        line-height: 0;
        font-family: 'Saira Semi Condensed', sans-serif;
        font-weight: 600;
        letter-spacing: -.5px;
      }
    }
    .r-info{
      text-align: right;
      margin: 0;
      .coupon-time-out-message{
        color: #000;
        font-size: 9px;
        letter-spacing: -.5px;        
      }
    }
  }
  .coupon-timer{
      font-family: 'Saira Semi Condensed', sans-serif;
      line-height: 1;
      font-size : 14px !important;
      font-weight: 600;
    }
  .coupon-btn{
    width: 100%;
    height: 50px;
    font-size: 13px;
    color: ${props => props.couponBtnColor};
    letter-spacing: -.5px;
    font-weight: bold;
    text-align: center;
    background: none;
  }
`;

const StyledSpecialPrice = styled.div`
  margin-top: 40px;
  letter-spacing: -.5px;
  .color-price-red{
    font-family: 'Saira Semi Condensed', sans-serif;
    color : ${props => (props.isExpired ? '#b1b3b2' : '#dd4124')};  
  }
  .color-price-title{
    color : ${props => props.isExpired && 'black'};
  }
  .color-price-default{
    font-family: 'Saira Semi Condensed', sans-serif;
    color : #033e52;
  }
  .title{
    font-weight: bold;
    margin-bottom: 10px;
  }
  .flex-table{
    .tr{
      letter-spacing: -0.5px;
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
  }
  .price-table{
    .td{
      font-size: 14px;
    }    
  }
  .table-body{
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    .tr{
      height: 60px;
      border-bottom: 1px solid #e4e4e4;
      &:last-child{
        border: none;      
      }
    }
  }
`;

const StyledPriceNotice = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  margin-top: 20px;
  letter-spacing: -0.5px;
  color: #b1b3b2;
`;


const StyledTreatPeriod = styled.div`
  margin-top: 90px;
  letter-spacing: -.5px;
  .title{
    font-size: 20px;
    font-weight: bold;
    color: #033e52;
    margin-bottom: 10px;
  }
  pre{
    line-height: 1.4;
    font-size: 16px;
    border: none;
    padding: 0;
    background: none;
    word-break: break-word;
    white-space: pre-wrap;    
  }
`;

const StyledDiscountBottomInfoBox = styled.div`
  /* pc 버전에허 할인률 배너 숨김 */
  display: none;
  ${media.miniDesktop`
    display: block;
  `};
  position: fixed;
  z-index: 5;
  bottom: 73px;
  width: 100%;
  height: 75px;
  padding: 0 10px;
  left: 0;
  font-weight: bold;
  letter-spacing: -.5px;
  .dc-rate-num{
    font-size: 36px;
    line-height: 1;
    //font-family: 'Saira Semi Condensed', sans-serif;
    font-weight: 600;
    letter-spacing: -.5px;
  }
`;

const StyledShadowBox = styled(Flex)`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 4px 4px 16px 0 rgba(0, 0, 0, 0.2);
  align-items: center;
  color: #dd4124;
  padding: 0 20px;
  font-size: 14px;
  letter-spacing: -.5px;
  &>div{
    flex: 1 0;
  }
`;

const DiscountBanner = ({ discount, remainTime }) => (
  <StyledDiscountBottomInfoBox>
    <StyledShadowBox>
      <div>
        <div className="dc-rate">
          <span style={{ marginRight: '5px' }}>최대</span>
          <b className="dc-rate-num">{discount.discountRatio}</b>
          <span>% 할인</span>
        </div>
      </div>
      <div>
        <div>
          <span style={{ color: '#033e52' }}>예약 특별혜택 마감까지</span>
          <br />
          {remainTime()}
          {' '}
          <span style={{ color: '#033e52' }}>남았습니다.</span>
        </div>
      </div>
    </StyledShadowBox>
  </StyledDiscountBottomInfoBox>
);

const RemainTime = ({ checkedAnsTime, onTimeout }) => {
  // D-day인지
  const isDay = Math.floor(checkedAnsTime) < (24 * 60 * 60);
  const remainDay = `D-${Math.floor(checkedAnsTime / (24 * 60 * 60))}`;
  if (isDay) {
    return (
      <DayTimerComponent
        init={Math.floor(checkedAnsTime)}
        onTimeOut={onTimeout}
      />
    );
  }
  return (
    <>
      {/* <Clock /> */}
      {remainDay}
    </>
  );
};

class CounselResponseComponent extends Component {
  constructor(props) {
    super(props);

    const {
      priceResult,
      userRequest,
      discount,
    } = props;

    const txPricesObj = convertTxPricesResponseToObj(userRequest.txItems, priceResult.txPrices);
    const orthoPricesObj = convertOrthoPricesResponseToObj(userRequest.orthoItems, priceResult.orthoPrices);

    this.state = {
      isDiscount: discount?.discountRatio, // dc ratio
      isExpired: this.expDay(priceResult.checkedAns.createdAt) < 0, // 시간 마감 체크
      treatPrice: {
        ...txPricesObj,
        ...orthoPricesObj,
      },
    };
  }

  getOrthoImage = (itemcode) => {
    if (!itemcode) {
      return '';
    }

    if (itemcode.indexOf('ORT-001') > -1) {
      return metalImg;
    }

    if (itemcode.indexOf('ORT-002') > -1) {
      return invisibleOrtho;
    }

    if (itemcode.indexOf('ORT-003') > -1) {
      return transpOrthoImg;
    }

    return '';
  };

  expDay = (time) => {
    const chkTime = new Date(time);
    const c = new Date();
    // let c = new Date(checkedAns.createdAt);
    // 답변 남긴 당일 이틀 후 21시 기준
    chkTime.setHours(21, 0, 0, 0);
    chkTime.setDate(chkTime.getDate() + 2);
    return (chkTime.getTime() - c.getTime()) / 1000;
  };

  render() {
    const {
      priceResult,
    } = this.props;

    if (!priceResult) {
      return <div>상담 답변이 없습니다.</div>;
    }

    const {
      isExpired,
      isDiscount,
      treatPrice,
    } = this.state;

    const {
      priceResult: {
        txMaxPrice,
        orthoMaxPrice,
        symptomAnswer,
        treatmentAnswer,
        metadata,
        checkedAns,
        hospital: {
          counselSetting,
        },
      },
      discount,
      userRequest,
      history,
      match,
      hospitalName,
    } = this.props;

    const userItems = [
      ...userRequest.txItems,
      ...userRequest.orthoItems,
    ];

    const isShowBanner = !isExpired && isDiscount;

    return (
      <Container>
        {
          isShowBanner
          && (
          <DiscountBanner
            discount={discount}
            remainTime={() => (
              <RemainTime
                checkedAnsTime={this.expDay(checkedAns.createdAt)}
                onTimeout={() => {
                  this.setState({
                    isExpired: true,
                  });
                }}
              />
            )}
          />
          )
        }
        {
          isDiscount
          && (
            <StyledCouponContainer
              isExpired={isExpired}
              className={`${!isDiscount && 'no-discount'}`}
            >
              <div className="coupon-box">
                <div className="coupon-info">
                  <div
                    className="l-info"
                  >
                    {
                      isExpired ? (
                        <div className="desc">
                          <span>오닥터 혜택마감</span>
                        </div>
                      ) : (
                        <>
                          <div className="desc" style={{ marginRight: '4px', lineHeight: 1 }}>
                            {'마감까지'}
                          </div>
                          <div className="desc coupon-timer">
                            <RemainTime
                              checkedAnsTime={this.expDay(checkedAns.createdAt)}
                              onTimeout={() => {
                                this.setState({
                                  isExpired: true,
                                });
                              }}
                            />
                          </div>
                        </>
                      )
                    }
                  </div>
                  <div className="r-info" style={{ marginRight: '5px' }}>
                    <div className="desc">
                      {'최대'}
                    </div>
                  </div>
                  <div className="r-info">
                    <div className="dc-rate">
                      <b className="dc-rate-num">{discount.discountRatio}</b>
                      <span style={{ fontSize: '24px', fontFamily: 'Saira Semi Condensed' }}>
                        {'%'}
                      </span>
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  className="coupon-btn"
                  onClick={(isExp) => {
                    if (isExp) {
                      history.push(`/reservation/${match.params.id}/${match.params.hid}`);
                    }
                  }}
                >
                  {isExpired ? '특별가 문의는 병원으로 연락주세요' : (
                    <span>
                      {'오닥터 특별 혜택받고 예약하기'}
                      <GtMark />
                    </span>
                  )}
                </button>
              </div>
            </StyledCouponContainer>
          )
        }

        <StyledSpecialPrice
          isExpired={isExpired}
          className={`${!isDiscount && 'no-discount'}`}
        >
          <div className={`flex-table title ${isExpired && 'expired'}`}>
            <div className="tr">
              <div
                className={`td color-price-red color-price-title ${!isDiscount && 'color-price-default'}`}
                style={{ fontSize: '14px', letterSpacing: '-.5px', fontWeight: '700' }}
              >
                {
                  isDiscount
                    ? '오닥터 특별 혜택가'
                    : '치과 예상치료비'
                }
              </div>
              <div
                className={`td color-price-red ${!isDiscount && 'color-price-default'}`}
                style={{ fontSize: '14px', letterSpacing: '-.5px', fontWeight: '700' }}
              >
                {currencyFormat(txMaxPrice + (orthoMaxPrice || 0), '만원~') || '문의필요'}
              </div>
            </div>
          </div>
          <div className="flex-table table-body price-table">
            {
              userItems.map((item, idx) => {
                const priceCode = item.part
                  ? treatPrice[item.itemcode][item.part]
                  : treatPrice[item.itemcode] * item.unit;
                return (
                  <div className="tr" key={idx}>
                    <div className="td">
                      {therapyInfoById[item.itemcode].viewName}
                      {' '}
                      {item.part && `(${getOrthoPartName(item.part)})`}
                    </div>
                    <div
                      className={`td color-price-red ${!isDiscount && 'color-price-default'}`}
                    >
                      {currencyFormat(priceCode, '만원~') || '문의필요'}
                    </div>
                  </div>
                );
              })
            }
          </div>
        </StyledSpecialPrice>
        <StyledTreatPeriod>
          {
            metadata && metadata.expectedPeriod
            && (
              <div className="title">
                {'약 '}
                {metadata.expectedPeriod}
                {'동안 치료가 필요합니다.'}
              </div>
            )
          }
          <div>
            <div style={{ fontSize: '16px' }}>
              {'안녕하세요, '}
              {`${userRequest.user.username}님. `}
              {`${hospitalName}입니다.`}
            </div>
            <br />
            {/* 인삿말이 고정멘트로 바뀜
             <pre>
              {counselSetting.greeting}
             </pre> */}
            <pre>
              {(symptomAnswer || '')}
              <br />
              {treatmentAnswer}
            </pre>

            <br />
            <pre>
              {counselSetting.closing}
            </pre>
          </div>
          <StyledPriceNotice>
            {'해당 금액은 예상 치료비로, 실제 치료비와 다를 수 있습니다.'}
            <br />
            {isDiscount && '급여(보험) 치료는 의료법 상 할인 대상에서 제외됩니다.'}
          </StyledPriceNotice>
        </StyledTreatPeriod>

        {/* 04-27 <CounselInfoBtn
          onClick={() => {
            history.push(`/myrequest/${userRequest.id}`);
            logEvent({
              category: '답변(병원) 상세',
              action: '클릭',
              label: '상담정보 보러가기',
            });
          }}
        >
          상담정보 보러가기
        </CounselInfoBtn> */}
      </Container>
    );
  }
}

export default withRouter(withAlertModal(CounselResponseComponent));
