import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { ProgressBar } from '../atoms/Shape/ProgressBar';

const StyledStepHeader = styled.div`
  width: 100%;
  height: 58px;
  position: absolute;
  top: 0;
  left: 0;
  .prev-icon{
    width: 24px;
    height: 24px;
    padding: 17px 20px;
    cursor: pointer;
    box-sizing: content-box;
  }
  .progress-bar{
    width: 100%;
    height: 3px;
    position: relative;
  }
`;


const PrevButtonHeaderComponent = ({ history, fixedUrl, from, replaceUrl, onClick }) => {
  return (
    <StyledStepHeader>
      { /* 뒤로가기 */ }
      <div
        className="prev-icon"
        onClick={() => {
          if (onClick) {
            onClick();
            return;
          }

          // /case
          // 사례 목록에서 사례 상세페이지로 진입 할 경우
          // 예외처리
          if(from?.match(/case/gi)) {
            history.goBack();
            return;
          }

          // url이 있을 경우 히스토리와 상관 없이 해당 url로 보냄
          if (fixedUrl) {
            // 사례 목록을 거치치 않고, 사례 상세로 바로 진입할 경우..
            // '뒤로가기' 동작시 사례 목록으로 이동하도록 유도.
            history.replace(fixedUrl);
            // history.replace('/hospitals/1/reviews');
            // history.push(fixedUrl);
          } else {
            // 그외 뒤로가기
            history.goBack();
          }
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path fill="#A4A4A4" fillRule="evenodd" d="M23.865 10.08v3l-18.157-.001 6.279 6.278-2.122 2.122-9.9-9.9 9.9-9.9 2.122 2.122-6.278 6.278h18.156z" />
        </svg>
      </div>
      {/* 프로그레스 바 */}
      <div className="progress-bar">
        {/* 1차 */}
        <ProgressBar
          color="#033e52"
          stages={0}
          currStep={0}
        />
      </div>
    </StyledStepHeader>
  );
};

// todo : header module
const Header = () => {
  return (
    <div>Header</div>
  );
};

const PrevButtonHeader = withRouter(PrevButtonHeaderComponent);
export {
  PrevButtonHeader,
  Header,
};
export default Header;
