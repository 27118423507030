import React from 'react';
import styled from 'styled-components';
import { media } from '../globalStyle';

const AlertContent = styled.div`
  font-size: 18px;
  text-align: left;
  color: #484848;
  @media (max-width: 576px) {
    font-size: 12px;
  }
`;

const AlertMessage = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -1px;
  color: #000000;
  white-space: pre-line;
  word-break: break-word;
  margin: 20px 0 30px;
  ${media.tablet`
    margin-top: 0;
    margin-bottom: 90px;
  `};
`;

const AlertBtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin-top: 15px;
  ${media.tablet`
    margin-top: 0;
    display: block;
  `};
`;

const AlertConfirmBtn = styled.button`
  background-color: #033e52;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  text-align: center;
  color: #ffffff;
  border: solid 1px #033e52;
  width: 50%;
  padding: 14px 0;
  &:hover,
  &:focus {
    outline: none;
  }
  ${media.tablet`
    margin: 0;
    display: block;
    width: 100%;
  `};
`;

const AlertCancelBtn = styled(AlertConfirmBtn)`
  margin: 0;
  background-color: #ffffff;
  color: #033e52;
  margin-right: 4px;
  ${media.tablet`
    margin-top: 10px;
  `};
`;

const LogoutForm = ({ close, logout }) => (
  <div>
    <AlertContent>
      <AlertMessage>
        로그아웃 하시겠습니까?<br />
        상담 신청 중에 로그아웃할 경우 작성하던 내용은<br />
        저장되지 않습니다.
      </AlertMessage>
      <AlertBtnContainer>
        <AlertConfirmBtn onClick={logout}>로그아웃</AlertConfirmBtn>
        <AlertCancelBtn onClick={close}>취소</AlertCancelBtn>
      </AlertBtnContainer>
    </AlertContent>
  </div>
);

export default LogoutForm;
