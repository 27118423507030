import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import withToast from './toasthoc';
import { fixBody, unfixBody } from './style-utils';
import withAlertModal from './alerthoc';
import AuthModal from '../components/home/AuthModal';
import { AuthContext } from '../components/AuthProvider';

function withAuthModal(WrappedComponent) {
  class WithAuthModal extends Component {
    constructor(props) {
      super(props);

      this.state = {
        isOpen: false,
        modalType: '',
      };

      this.openAuthModal = this.openAuthModal.bind(this);
      this.openChangePasswordModal = this.openChangePasswordModal.bind(this);
      this.updateAuthModalType = this.updateAuthModalType.bind(this);
      this.openLogoutModal = this.openLogoutModal.bind(this);
      this.closeModal = this.closeModal.bind(this);
      this.login = this.login.bind(this);
      this.regist = this.regist.bind(this);
      this.logout = this.logout.bind(this);
    }

    // 부모 function 후에 모달 닫기.
    login = async (email, password) => {
      const loginRes = await this.context.login(email, password);
      this.closeModal();
      return loginRes;
    }

    // 부모 function 후에 모달 닫기.
    regist = async (username, email, phonenum, password, agreemarket) => {
      const res = await this.context.regist(username, email, phonenum, password, agreemarket);
      this.closeModal();
      return res;
    }

    // 부모 function 후에 모달 닫기.
    logout = () => {
      this.context.logout();
      this.closeModal();
    }

    openAuthModal = modalType => this.setState({ isOpen: true, modalType });

    openChangePasswordModal = () => {
      this.setState({
        isOpen: true,
        modalType: 'changePassword',
      });
    };

    updateAuthModalType = (modalType) => {
      this.setState({
        modalType,
      });
    }

    openLogoutModal = () => {
      this.setState({ isOpen: true, modalType: 'logout' });
    }

    closeModal = () => {
      this.setState({ isOpen: false });
    }

    render() {
      const { me, isLoggedIn } = this.context;

      if (this.state.isOpen) {
        fixBody();
      } else {
        unfixBody();
      }

      return (
        <React.Fragment>
          <WrappedComponent
            {...this.props}
            me={me}
            isLoggedIn={isLoggedIn}
            login={this.login}
            regist={this.regist}
            logout={this.logout}
            openLogoutModal={this.openLogoutModal}
            openChangePasswordModal={this.openChangePasswordModal}            
            openAuthModal={this.openAuthModal}
            updateAuthModalType={this.updateAuthModalType}
            handleLogout={this.context.logout}
          />
          <AuthModal
            {...this.props}
            isOpen={this.state.isOpen}
            modalType={this.state.modalType}
            close={this.closeModal}
            me={me}
            login={this.login}
            regist={this.regist}
            logout={this.logout}
            resetPassword={this.context.resetPassword}
            updateAuthModalType={this.updateAuthModalType}
          />
        </React.Fragment>
      );
    }
  }

  WithAuthModal.contextType = AuthContext;
  return withApollo(withToast(withAlertModal(WithAuthModal)));
}

export default withAuthModal;
