import goImage from '../images/select-go.svg';
import stopImage from '../images/select-stop.svg';
import girlImage from '../images/select-girl.svg';
import boyImage from '../images/select-boy.svg';
import noBody from '../images/select-nobody.svg';

// 거울 속 내 모습은 이미지
import my01 from '../images/my-01.svg';
import my02 from '../images/my-02.svg';
import my03 from '../images/my-03.svg';
import my04 from '../images/my-04.svg';
import my05 from '../images/my-05.svg';
import my06 from '../images/my-06.svg';
import my07 from '../images/my-07.svg';
import my08 from '../images/my-08.svg';
import my09 from '../images/my-09.svg';

// 교정 방법 이미지
import look01 from '../images/look-01.svg';
import look02 from '../images/look-02.svg';
import look03 from '../images/look-03.svg';

// 치료 의향
import speed01 from '../images/speed-01.svg';
import speed02 from '../images/speed-02.svg';
import speed03 from '../images/speed-03.svg';

export const main = {
  type: 'checkbox',
  buttonType: 'textButton',
  data: null,
  dataType: [],
  dataMax: 5,
  cols: 3,
  name: 'txItems',
  title: '많이 불편했죠?\n하고 싶은거 다 골라봐요',
  description: '상담은 무료거든요 (찡긋)', // default : null
  buttons: [{
    id: 'IMP-001',
    name: '임플란트',
    value: 'IMP-001',
  }, {
    id: 'CAV',
    name: '충치때우기',
    value: 'CAV',
  }, {
    id: 'ORT',
    name: '교정치료',
    value: 'ORT',
  }, {
    id: 'AES-003',
    name: '치아미백',
    value: 'AES-003',
  }, {
    id: 'AES-002',
    name: '앞니 심미치료',
    value: 'AES-002',
  }, {
    id: 'GEN-001',
    name: '신경치료',
    value: 'GEN-001',
  }, {
    id: 'GUM-001',
    name: '잇몸치료',
    value: 'GUM-001',
  }, {
    id: 'FACE-001',
    name: '턱관절 치료',
    value: 'FACE-001',
  }, {
    id: 'AES-005',
    name: '스케일링',
    value: 'AES-005',
  }, { // 더보기
    id: 'GEN-002',
    name: '사랑니발치',
    value: 'GEN-002',
  }, {
    id: 'GEN-003',
    name: '불소도포',
    value: 'GEN-003',
  }, {
    id: 'AES-004',
    name: '보톡스',
    value: 'AES-004',
  }, {
    id: 'FACE-002',
    name: '양악수술',
    value: 'FACE-002',
  }],
};

/**
 * null, [] || ' ' 선택필요. '다음'
 * ' ', [] || ' ' 선택픽요없음. '건너뛰기'
 * null, ' ' 선택 후 다음. 버튼 없음
 * @type {{
 * buttonType: string,
 * buttons: *[],
 * data: Array,
 * dataType: Array,
 * name: string,
 * description: string,
 * type: string,
 * title: string,
 * cols: number
 * }}
 */
export const orthoWanna = {
  type: 'checkbox',
  buttonType: 'imageButton',
  data: [],
  dataType: [],
  cols: 3,
  name: 'orthoWanna',
  logName: '교정고민',
  title: '거울 속 내 모습은..?',
  description: '골라담기 가능', // default : null
  // multiSelect: false,
  buttons: [{
    id: 'chkImrpove1',
    name: '토끼이빨',
    value: '토끼이빨',
    image: my01,
  }, {
    id: 'chkImrpove2',
    name: '앞니가 나옴',
    value: '앞니가 나옴',
    image: my02,
  }, {
    id: 'chkImrpove3',
    name: '아랫니가 나옴',
    value: '아랫니가 나옴',
    image: my03,
  }, {
    id: 'chkImrpove4',
    name: '치열이 삐뚤삐뚤',
    value: '치열이 삐뚤삐뚤',
    image: my04,
  }, {
    id: 'chkImrpove6',
    name: '치아가 옥니',
    value: '치아가 옥니',
    image: my05,
  }, {
    id: 'chkImrpove9',
    name: '아랫턱 돌출',
    value: '아랫턱 돌출',
    image: my06,
  }, {
    id: 'chkImrpove5',
    name: '거꾸로 물리는 \n 앞니',
    value: '거꾸로 물리는 앞니',
    image: my07,
  }, {
    id: 'chkImrpove7',
    name: '거꾸로 물리는 \n 어금니',
    value: '거꾸로 물리는 어금니',
    image: my08,
  }, {
    id: 'chkImrpove8',
    name: '치아가 제대로 \n 안물림',
    value: '치아가 제대로 안물림',
    image: my09,
  },
  ],
};

export const orthoPart = {
  type: 'checkbox',
  buttonType: 'textButton',
  data: null,
  dataType: [],
  cols: 2,
  name: 'orthoPart',
  logName: '교정부위',
  title: '윗니..? 아랫니..?\n어디어디..?',
  description: '1+1 가능합니다 :)', // default : null
  // multiSelect: false,
  buttons: [{
    id: 'AU',
    name: '윗니 전체교정',
    value: 'AU',
  }, {
    id: 'PU',
    name: '윗니 부분교정',
    value: 'PU',
  }, {
    id: 'AD',
    name: '아랫니 전체교정',
    value: 'AD',
  }, {
    id: 'PD',
    name: '아랫니 부분교정',
    value: 'PD',
  },
  ],
};

export const ortho = {
  type: 'radio',
  buttonType: 'cardButton',
  data: null,
  dataType: null,
  cols: 1,
  name: 'ortho',
  logName: '교정방법',
  title: '어릴적부터 꿈꿔왔던\n교정이 있나요? ',
  description: null, // default : null
  buttons: [{
    id: 'ORT-001',
    name: '보이는 교정',
    description: '#전체공개 #일반적 #철길 #끼임주의',
    image: look01,
    value: 'ORT-001',
  }, {
    id: 'ORT-002',
    name: '안보이는 교정',
    description: '#비공개 #환한미소 #발음술술',
    image: look02,
    value: 'ORT-002',
  }, {
    id: 'ORT-003',
    name: '투명교정',
    description: '#친구공개 #탈부착 #한듯만듯 #분실주의',
    image: look03,
    value: 'ORT-003',
  }],
};

export const addressRegion = {
  type: 'radio',
  buttonType: 'roundButton',
  data: null,
  dataType: null,
  cols: 3,
  name: 'addressRegion',
  logName: '시도',
  title: '방문하고 싶은 지역은\n어디인가요?',
  description: '만약 선택하신 지역에 파트너 병원이 부족할 경우,\n'
    + '점점 더 지역을 확장하여 상담을 요청합니다.', // default : null
  buttons: [{
    id: '11',
    name: '서울',
    description: null,
    image: '',
    value: '서울',
  }, {
    id: '41',
    name: '경기',
    description: null,
    image: '',
    value: '경기',
  }, {
    id: '28',
    name: '인천',
    description: null,
    image: '',
    value: '인천',
  }],
};

export const addressCity = {
  type: 'component',
  buttonType: 'textButton',
  data: null,
  dataType: null,
  cols: 3,
  name: 'addressCity',
  logName: '시군구',
  title: '@regionCity@에서\n어디요..? :D',
  description: null, // default : null
  buttons: [],
  component: 'cityComponent',
};


export const selectAddress = {
  type: 'component',
  buttonType: 'textButton',
  data: null,
  dataType: [],
  cols: 3,
  name: 'locations',
  logName: '주소',
  title: '방문하고 싶은 지역은\n어디인가요? (최대 2곳)',
  description: '만약 선택하신 지역에 파트너 병원이 부족할 경우,\n'
    + '점점 더 지역을 확장하여 상담을 요청합니다.', // default : null
  buttons: [],
  component: 'selectAddressComponent',
};

export const wantLevel = {
  type: 'radio',
  buttonType: 'cardButton',
  data: null,
  dataType: null,
  cols: 1,
  name: 'wantLevel',
  logName: '치료의향',
  title: '치료가\n얼마나 급하신가요?',
  description: null, // default : null
  buttons: [{
    id: '5',
    name: '삐오삐오 빨리빨리 급해요!!!!',
    description: null,
    image: speed01,
    value: 5,
  }, {
    id: '3',
    name: '치료가 필요하지만~ 급하진 않아요~',
    description: null,
    image: speed02,
    value: 3,
  }, {
    id: '1',
    name: '그냥 쫌… 알아보고 있어요…',
    description: null,
    image: speed03,
    value: 1,
  }],
};

export const isDetail = {
  type: 'radio',
  buttonType: 'textButton',
  data: null,
  dataType: null,
  cols: 1,
  name: 'isDetail',
  logName: '디테일',
  title: '지금부터\n치아 심층 검사가 시작됩니다!',
  description: '비밀이 보장되는 나만의 치과 차트를\n만들기 위해 필요한 정보입니다.', // default : null
  buttons: [{
    id: 'detail',
    name: '네! 알겠습니다.',
    description: null,
    image: goImage,
    value: true,
  },
    // {
    //   id: 'simple',
    //   name: '여기까지만',
    //   description: null,
    //   image: stopImage,
    //   value: false
    // },
  ],
};

export const gender = {
  type: 'radio',
  buttonType: 'imageRoundButton',
  data: null,
  dataType: null,
  cols: 3,
  name: 'gender',
  logName: '성별',
  title: '탁월한 선택입니다!\n성별을 알려주세요',
  description: '더 나은 상담을 위한 정보입니다.', // default : null
  buttons: [{
    id: 'female',
    name: '여자',
    description: null,
    image: girlImage,
    value: 'female',
  }, {
    id: 'male',
    name: '남자',
    description: null,
    image: boyImage,
    value: 'male',
  }, {
    id: 'secret',
    name: '비밀이에요',
    description: null,
    image: noBody,
    value: 'secret',
  }],
};

export const ageGroup = {
  type: 'radio',
  buttonType: 'textButton',
  data: null,
  dataType: null,
  cols: 3,
  name: 'ageGroup',
  logName: '연령대',
  title: '연령대가\n어떻게 되세요?',
  description: '연령대에 따른 맞춤답변을 제공합니다.', // default : null
  buttons: [{
    id: 'less_teens',
    name: '10대 이하',
    value: 'less_teens',
  }, {
    id: 'twenties',
    name: '20대',
    value: 'twenties',
  }, {
    id: 'thirties',
    name: '30대',
    value: 'thirties',
  }, {
    id: 'forties',
    name: '40대',
    value: 'forties',
  }, {
    id: 'fifties',
    name: '50대 이상',
    value: 'fifties',
  }, {
    id: 'secret',
    name: '비밀이에요',
    value: 'secret',
  }],
};

export const considerTags = {
  type: 'checkbox',
  buttonType: 'textButton',
  data: ' ',
  dataType: [],
  dataMax: 3,
  cols: 2,
  name: 'considerTags',
  logName: '희망치과',
  title: '어떤 치과가\n좋아요?',
  description: '최대 3개까지 선택하실 수 있습니다.', // default : null
  buttons: [{
    id: 1,
    name: '가까운 거리',
    value: '가까운거리',
  }, {
    id: 2,
    name: '최신 시설 및 설비',
    value: '최신 시설 및 설비',
  }, {
    id: 3,
    name: '자세한 설명과 친절함',
    value: '자세한 설명과 친절함',
  }, {
    id: 4,
    name: '편안하고 안아픈 진료',
    value: '편안하고 안아픈 진료',
  }, {
    id: 5,
    name: '치료 분야별 주치의',
    value: '치료 분야별 주치의',
  }, {
    id: 6,
    name: '합리적 가격',
    value: '합리적 가격',
  }],
};

export const txImageUrls = {
  type: 'component',
  buttonType: 'photo',
  data: [],
  dataType: [],
  cols: 1,
  name: 'txImageUrls',
  logName: '사진',
  title: '그리고\n치아 상태는?',
  description: '치과에서 찍은 X-ray 사진이 가장 좋아요!', // default : null
  buttons: [],
  component: 'photoComponent',
};

export const desc = {
  type: 'component',
  buttonType: 'textarea',
  data: ' ',
  dataType: ' ',
  cols: 1,
  name: 'desc',
  logName: '설명',
  title: '의사선생님께 하고 싶은\n나의 치아 이야기',
  description: '치료한 이력이나 불편한 점, 요청사항 등을 \n'
    + '구체적으로 입력할수록 정확한 답변을 받을 수 있어요!', // default : null
  buttons: [],
  component: 'descComponent', // descComponent
  beforeNext: (text) => { // for validation
      const inputText = text || '';
      if(inputText.length < 50) {
        return window.confirm('구체적인 답변을 받기에 내용이 조금 부족합니다ㅠㅠ\n' +
          '이대로 상담하시겠어요?');
      }
      return true;
  }
};

export const name = {
  type: 'component',
  buttonType: 'textarea',
  data: null,
  dataType: ' ',
  cols: 1,
  name: 'name',
  logName: '이름',
  title: '이름을\n알려주세욥',
  description: null, // default : null
  buttons: [],
  component: 'nameComponent',
};

export const phone = {
  type: 'component',
  buttonType: 'phone',
  data: null,
  dataType: null,
  cols: 1,
  name: 'phone',
  logName: '연락처',
  title: '어디로\n연락드릴까요?',
  description: '고객님의 연락처는 상담 목적으로만 사용됩니다.\n'
    + '안심하쎄엉 :)', // default : null
  buttons: [],
  component: 'phoneComponent',
};

export const password = {
  type: 'component',
  buttonType: 'textarea',
  data: null,
  dataType: null,
  cols: 1,
  name: 'password',
  logName: '신규비밀',
  title: '보안을 위한\n비번만 있으면 끗~!',
  description: '병원답변 확인 시 필요해요', // default : null
  buttons: [],
  component: 'passwordComponent',
};

export const memberPassword = {
  type: 'component',
  buttonType: 'textarea',
  data: null,
  dataType: null,
  cols: 1,
  name: 'memberPassword',
  logName: '회원비밀',
  title: '이미 오닥터 회원이시네요!\n비밀번호를 입력해주세요',
  description: null, // default : null
  buttons: [],
  component: 'memberPasswordComponent',
};

export const process = {
  type: 'component',
  buttonType: 'process',
  data: null,
  dataType: null,
  cols: 1,
  name: 'process',
  logName: '완료',
  title: '',
  description: null, // default : null
  buttons: [],
  component: 'processComponent',
};
