import styled from 'styled-components';
import ArrowImg from '../../images/ic-arrow-gray-20.png';
import { media } from '../../globalStyle';
import { marginProps, paddingProps } from '../basic/HtmlComponents';

export const CartContainer = styled.div`
  width: 100%;
  height: auto;
  padding: 6px 0 20px;
  position: relative;
  & ~ & {
    padding-top: 20px;
  }
  ${media.miniDesktop`
    padding: 14px 0;
    & ~ & {
      padding-top: 14px;
    }
  `};
  ${paddingProps}
`;

export const CartContainerTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: -0.6px;
  color: #000000;
  ${media.miniDesktop`
    line-height: 1.5;
    letter-spacing: -0.8px;
  `};
`;

export const CollapseHeader = styled.div`
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: -1px;
  color: #bbbcbc;
  position: relative;
  z-index: 8;
  padding: 17px 58px 17px 20px;
  cursor: pointer;
  &::after {
    content: '';
    background-image: url(${ArrowImg});
    position: absolute;
    right: 20px;
    transform: rotate(90deg);
    width: 20px;
    height: 20px;
    top: 20px;
    background-size: contain;
  }

  ${props => props.active && `
    color: #033e52;
    &::after {
      transform: rotate(-90deg);
    }
  `};
`;

export const CartBody = styled.div`
  width: 100%;
  background-color: white;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const TherapyRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 7px;
  ${media.miniDesktop`
    margin-top: 8px;
  `};
  & ~ & {
    margin-top: 21px;
    ${marginProps};
    ${media.miniDesktop`
      margin-top: 10px;
    `};
  }
  ${marginProps};
`;

export const TherapyTitle = styled.div`
  width: 94px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: -0.9px;
  color: #000000;
  display: inline-block;
  vertical-align: top;
  ${media.miniDesktop`
    font-size: 14px;
    line-height: 1.71;
  `};
  ${props => props.disabled && 'color: #bbbcbc'};
`;
export const TherapyRightContainer = styled.div`
  display: inline-block;
  text-align: right;
`;

export const TherapyRightPhotoContainer = styled.div`
  display: inline-block;
  width: 222px;
  ${media.miniDesktop`
    width: 100%;
  `};
`;

export const Item = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: -0.5px;
  text-align: right;
  color: #000000;
  ${props => props.uneditable && `
    color: #bbbcbc;
  `}
  ${media.miniDesktop`
    font-size: 14px;
    line-height: 1.71;
    margin-bottom: 6px;
  `};
  ${TherapyRightContainer} > &:last-of-type {
    margin-bottom: 0;
  }
`;

export const Photo = styled.img`
  width: 68px;
  height: 42px;
  object-fit: contain;
  vertical-align: top;
  margin-left: 4px;
  margin-bottom: 4px;
  border: solid 1px #e0e0e0;
  box-sizing: border-box;
`;

export const CancelBtn = styled.div`
  display: inline-block;
  margin-left: 2px;
  cursor: pointer;
  vertical-align: middle;
  margin-top: -2px;
  img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    vertical-align: middle;
  }
`;

export const PhotoContainer = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: top;
`;

export const PhotoDeleteArea = styled.div`
  position: absolute;
  width: 68px;
  height: 42px;
  top: 0;
  right: 0;
  padding-top: 11px;
  text-align: center;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const DescCartViewContainer = styled.div`
  margin-top: 8px;
  display: flex;
`;

export const Desc = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: -0.8px;
  color: #000000;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${props =>
    props.uneditable &&
    `
    color: #bcbcbc;
  `};
  ${media.miniDesktop`
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: -1px;
  `};
`;


const RightCartCommonComponents = {};

export default RightCartCommonComponents;
