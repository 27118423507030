import React from 'react';
import styled from 'styled-components';
import UncheckedInputImg from '../../images/checkbox-unchecked-32-partner.svg';
import CheckedInputImg from '../../images/checkbox-checked-32.svg';

const FormCheckboxInput = styled.input`
  display: none;
  + label {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.8px;
    color: #000000;
    cursor: pointer;
    line-height: 20px;
    img {
      width: 20px;
      height: 20px;
      margin-right: 8px;
      vertical-align: middle;
    }
  }
`;

class CheckboxInput extends React.Component {
  render() {
    const {
      id, name, checked, onChange, labelText, subLabelText, labelComponent,
    } = this.props;
    return (
      <React.Fragment>
        <FormCheckboxInput
          type="checkbox"
          id={id}
          name={name}
          checked={checked}
          onChange={onChange}
        />
        <label htmlFor={name}>
          <img
            src={checked ? CheckedInputImg : UncheckedInputImg}
            alt={labelText}
          />
          {labelComponent || labelText}
          {subLabelText && <span>{' '}{subLabelText}</span>}
        </label>
      </React.Fragment>
    );
  }
}

export default CheckboxInput;
