import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import StretchButton from '../components/atomic/atoms/Button/StretchButton';
import { media } from '../globalStyle';

const LoungeWrapper = styled.div`
  iframe {
    width: 100%;
    height: 100vh;
    padding-bottom: 64px;
  }
`;

const BottomBtnWapper = styled.div`
  display: none;
  ${media.tablet`
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 64px;
    button > div {
      font-weight: 600;
      font-size: 14px;
    }
  `}
`;

const OdocLounge = ({ match, history }) => {
  const site = match?.params?.page || 'implant';
  return (
    <LoungeWrapper>
      <iframe id="myframe" src={`https://odoctor.kr/${site}`} />
      <BottomBtnWapper>
        <StretchButton
          fixed
          onClick={() => history.push('/')}
        >
          이 치료로 상담받기
        </StretchButton>
      </BottomBtnWapper>
    </LoungeWrapper>
  );
};

export default withRouter(OdocLounge);
