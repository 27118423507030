import styled from 'styled-components';
import { marginProps, sizeProps, hideScrollBar } from '../basic/HtmlComponents';
import { media } from '../../globalStyle';

export const FormInputRow = styled.div`
  width: 100%;
  position: relative;
  & ~ & {
    margin-top: 16px;
  }
`;

export const FormInputRowTitle = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #ffffff;
  margin-bottom: 8px;
`;

export const FormBtnContainer = styled.div`
  margin: 22px 0 40px;
  text-align: center;
  ${marginProps}
`;

export const FormConfirmBtn = styled.button`
  width: 100%;
  min-height: 52px;
  background-color: #033e52;
  border: 0;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  text-align: center;
  color: #ffffff;
  padding: 2px 7px;
  &:disabled {
    background-color: #bbbcbc;
  }
  ${props =>
    props.disabledView &&
    `
    background-color: #bbbcbc;
  `}
  ${sizeProps}
`;

export const AuthFormLink = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  text-align: right;
  color: #000000;
  margin-top: 12px;
`;

export const AuthFormU = styled.u`
  cursor: pointer;
`;

export const FormStyledLink = styled.button`
  padding: 0;
  background: none;
  border: 0;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.8px;
  color: #000000;
  cursor: pointer;
  &:hover, &:focus, &:link {
    color: #000000;
    text-decoration: underline;
  }
`;

export const FormTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1.5px;
  color: #000000;
  margin: 0 auto 30px;
  ${media.tablet`
    margin-bottom: 40px;
  `};
`;

export const FormModalTitle = styled(FormTitle)`
  margin-bottom: 20px;
  ${media.tablet`
    margin-bottom: 10px;
  `};
`;

export const FormSubTitle = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.8px;
  color: #000000;
  margin: -24px auto 30px;
  ${marginProps}
  ${media.tablet`
    margin-top: -36px;
    margin-bottom: 40px;
  `};
`;

export const FormModalSubTitle = styled(FormSubTitle)`
  margin-bottom: 20px;
  ${media.tablet`
    margin-bottom: 20px;
  `};
`;

export const FormContent = styled.div`
  max-width: 400px;
  padding: 0 20px;
  margin: 0 auto;
`;

export const FormInputContainer = styled.div`
  margin: 30px 0 0;
  ${media.tablet`
    margin: 0;
  `};
`;

export const AuthFormPageContainer = styled.div`
  padding: 88px 0 121px;
  max-width: 326px;
  margin: 0 auto;
  ${hideScrollBar}
  ${media.tablet`
    max-width: 100%;
    padding: 88px 20px 40px;
  `};
`;

export const FormCsInfo = styled.div`
  margin-top: 20px;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.7px;
  color: #bbbcbc;
  text-align: left;
`;

export const SignupAndSigninBtn = styled.button`
  width: 100%;
  min-height: 52px;
  border: solid 1px #033e52;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  text-align: center;
  color: #033e52;
  padding: 2px 7px;
  margin-top: 10px;
`;

export default {};
