import React, { useState } from 'react';
import styled from 'styled-components';
import { SingleDatePicker } from 'react-dates';

const SingleDatePickerWapper = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  .SingleDatePicker {
    width: 100%;
    .SingleDatePickerInput__withBorder {
      border: none;
      width: 100%;
      .DateInput {
        width: 100%;
        height: 40px;
        input {
          padding: 0;
          border: 1px solid #d6d6d6;
          text-align: left;
          width: 100%;
          height: 100%;
          padding: 0 17px;
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: -1px;
          color: #000000;
        }
      }
      .SingleDatePickerInput_arrow {
        display: none;
      }
    }
    .SingleDatePickerInput_clearDates {
      right: -23px;
    }
  }
  .CalendarDay__default {
    line-height: 38px;
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover,
  .CalendarDay__selected_span {
    background: #033e52;
    border-color: #033e52;
  }
`;

const DatePicker = ({ id, date, onDateChange, className, ...rest }) => {
  const [focusedInput, setFocusedInput] = useState(false);
  return (
    <SingleDatePickerWapper className={className}>
      <SingleDatePicker
        date={date}
        onDateChange={newDate => onDateChange(newDate)} // PropTypes.func.isRequired
        focused={focusedInput}
        onFocusChange={({ focused }) => setFocusedInput(focused)} // PropTypes.func.isRequired
        id={id}
        displayFormat="YYYY.MM.DD"
        showDefaultInputIcon
        {...rest}
      />
    </SingleDatePickerWapper>
  );
};

export default DatePicker;
