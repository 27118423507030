import React, { Component } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import _ from 'lodash';

import { Card } from '../components/atomic/molecules/Card';
import VideoThumbnailImg01 from '../images/home/video_thumbnail_01.jpg';
import VideoThumbnailImg02 from '../images/home/video_thumbnail_02.jpg';
import VideoThumbnailImg03 from '../images/home/video_thumbnail_03.jpg';
import VideoThumbnailImg04 from '../images/home/video_thumbnail_04.jpg';
import VideoThumbnailImg05 from '../images/home/video_thumbnail_05.jpg';
import VideoThumbnailImg06 from '../images/home/video_thumbnail_06.jpg';
import VideoThumbnailImg07 from '../images/home/video_thumbnail_07.jpg';

const StyledSlider = styled(Slider)`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: #000000;
  .slick-list {
    padding: 0 0;
    margin-right: -20px;
    margin-left: -20px;
    .slick-track{
      margin-left: 20px;
    }
  }
  .slick-slide {
    width: 237px;
    margin-right: 10px;
  }
`;

const ContentDoorImg = styled.img`
  display: block;
  width: 237px;
  height: 133px;
  border-radius: 4px;
`;

const CardWrapper = styled.div`
  display: inline-block;
  .card-title {
    display: none;
  }
`;

const odoctorChannelContents = [
  {
    img: VideoThumbnailImg01,
    url: 'https://youtu.be/MI1rErsftXE',
  },
  {
    img: VideoThumbnailImg02,
    url: 'https://youtu.be/f6u18NiWAjA',
  },
  {
    img: VideoThumbnailImg03,
    url: 'https://youtu.be/0npmnGy_OKM',
  },
  {
    img: VideoThumbnailImg04,
    url: 'https://youtu.be/MJFLuc07zz8',
  },
  {
    img: VideoThumbnailImg05,
    url: 'https://youtu.be/TILPJRqJlK4',
  },
  {
    img: VideoThumbnailImg06,
    url: 'https://youtu.be/AbSaJgkAgho',
  },
  {
    img: VideoThumbnailImg07,
    url: 'https://youtu.be/66B3kfmXlc4',
  },
];

class MainOdoctorChannel extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      className: 'odoc-slide',
      arrows: false,
    };
    return (
      <StyledSlider {...settings}>
        {_.shuffle(odoctorChannelContents).map((content, idx) => (
          <CardWrapper key={`${idx}_${content.url}`} onClick={() => window.open(`${content.url}`)}>
            <Card
              width="100%"
              height="auto"
              imgRender={() => <ContentDoorImg src={content.img} />}
              title=""
            />
          </CardWrapper>
        ))}
      </StyledSlider>
    );
  }
}

export default MainOdoctorChannel;
