const PrivatePolicyData = `<오닥터>(이하 오닥터)는 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 개인정보 보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.
오닥터는 회사는 개인정보처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.
○ 본 방침은 2018년 [ 6]월 [ 20]일부터 시행됩니다.

• “개인정보”란 무엇을 의미합니까?
개인정보는 식별된 또는 식별 가능한 개인과 직간접적으로 연관된 모든 정보를 의미합니다.

• 개인정보 관리자란 누구입니까?
오닥터, [서울 강남구 언주로85길 29 5층], 는 이 방침에서 언급하는 개인 정보와 관련된 처리 활동의 개인정보 관리자 역할을 합니다. 이는 오닥터가 본 방침에서 언급한 정보 처리의 목적과 수단을 결정한다는 것을 의미합니다.

개인정보는 오닥터를 통해 제공된 안면 및 구강 정보를 처리하는 다른 파트너 병원, 개인 의원 (이하 닥터)의 목적과 수단으로 정보가 처리될 수도 있습니다. 그런 경우, 해당 파트너 병원 또는 닥터들이 개인정보 관리자 역할을 하게 되며, 이때 해당 회사의 개인정보 처리방침이 적용됩니다. 

• 개인정보 처리방침의 범위는 어떻게 됩니까?
본 개인정보 처리방침은 개인정보 주체와 관련된 개인정보 처리 및 보호에 대해 설명합니다.

• 오닥터가 개인정보를 중요하게 여기는 이유는 무엇입니까?
정보주체의 개인정보와 당사의 개인정보 보호 능력에 대한 정보주체의 신뢰는 오닥터 사업의 핵심입니다. 오닥터는 개인정보 주체의 사생활과 개인정보를 보호하고 개인정보의 불법적인 유출을 막기 위해 최선을 다할 것을 약속합니다.

• 본 개인정보 처리방침에 포함된 주요 정보는 무엇입니까?
정보주체에 대해 처리하는 개인정보, 처리 목적 및 법적 근거, 정보주체의 권리를 본 방침에서 확인하실 수 있습니다. 실제로 개인정보 주체는 개인정보에 접근하거나, 수정 또는 삭제, 개인정보의 처리를 제한할 수 있는 권리를 포함한 여러 가지 권리를 지닙니다.

• 개인정보 관련 문의는 어디로 합니까?
오닥터는 개인정보 보호책임자를 지정하여 개인정보와 관련된 문의를 처리하고, 개인정보 주체의 권리를 보호하고 있습니다. 오닥터의 개인정보 보호책임자에 대한 자세한 내용은 제 10조를 참조하시기 바랍니다.

1. 개인정보의 처리 목적 
오닥터는 개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음의 목적 이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전동의를 구할 예정입니다. 개인정보 주체가 정보 제공을 원치 않는 경우, 오닥터는 서비스를 제공할 수 없습니다.
가. 홈페이지 회원가입 및 관리
회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별 인증, 회원자격 유지 관리, 서비스 부정이용 방지, 만14세 미만 아동 개인정보 수집 시 법정대리인 동의 여부 확인, 각종 고지 통지, 고충처리, 분쟁 조정을 위한 기록 보존, 파트너병원과 연계하여 맞춤형 상담서비스 제공 등을 목적으로 개인정보를 처리합니다.
나. 마케팅 및 광고에의 활용
신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공 , 인구통계학적 특성에 따른 서비스 제공 및 광고 게재 , 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.

2. 개인정보 파일 현황
- 개인정보 파일명 : 회원 개인정보 - 개인정보 항목 : 이메일, 휴대전화번호, 비밀번호, 로그인ID, 성별, 생년월일, 이름, 서비스 이용 기록, 접속 로그, 접속 IP 정보, 법정대리인 이름, 법정대리인 자택 전화번호, 법정대리인 자택 주소, 법정대리인 휴대전화번호  - 수집방법 : 홈페이지, 앱 - 보유근거 : 회원의 동의 - 보유기간 : 서비스 제공 종료 시까지 - 관련법령 : 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보 보호법 등 관련 법률 
3. 개인정보의 처리 및 보유 기간
① 오닥터는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보 보유, 이용기간 내에서 개인정보를 처리, 보유합니다. 나. 개인화된 정보 및 서비스
② 오닥터는 마케팅 목적으로 개인정보와 웹사이트 행동 정보를 수집할 수 있습니다. 오닥터는 이러한 정보를 바탕으로 필요에 맞는 서비스 및 개별 맞춤 혜택을 제공할 수 있습니다. 
③ 오닥터는 맞춤형 프로모션 정보를 제공하기 위해 개인정보를 활용할 수 있습니다. 당사는 법적 근거가 있는 경우에만 개인정보를 처리하며 사용자의 동의 없이 개인정보를 사용하지 않습니다. 광고성 메일을 더 이상 받고 싶지 않은 경우 언제든지 ‘회원정보변경’에서 프로모션 정보 수신 동의 여부를 변경하거나 이메일에 포함된 “수신거부” 옵션을 활용하여 동의를 철회할 수 있습니다. 광고성 메일 수신에 동의하지 않는 경우 당사에 알려 주시기 바랍니다.
④ 오닥터는 서비스 제공 종료시까지 개인정보를 보유합니다.
-보유근거 : 약관 동의 -관련법령 : 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보 보호법 등 관련 법률  
4. 개인정보의 제3자 제공에 관한 사항 ① 오닥터는 정보주체의 동의, 법률의 특별한 규정 등에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
② 오닥터는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다. 1. 파트너 병원 - 개인정보를 제공받는 자 : 파트너 병원 - 제공받는 자의 개인정보 이용목적 : 회원에 대한 상담 및 예상 비용 제공
- 제공하는 개인정보: 휴대전화번호, 로그인ID, 성별, 생년월일, 이름, 상담 내용, 구강 사진, 안면 사진
- 제공받는 자의 보유 및 이용기간: 서비스 종료 시까지
2. 개인 의원 (“닥터”)
- 개인정보를 제공받는 자 : 닥터 - 제공받는 자의 개인정보 이용목적 : 회원에 대한 상담 및 예상 비용 제공 
- 제공하는 개인정보: 휴대전화번호, 로그인ID, 성별, 생년월일, 이름, 상담 내용, 구강 사진, 안면 사진
- 제공받는 자의 보유 및 이용기간: 서비스 종료 시까지
3. 기타 플랫폼
- 개인정보를 제공받는 자 : [기타 플랫폼] - 제공받는 자의 개인정보 이용목적 : [ ]
- 제공하는 개인정보: 휴대전화번호, 로그인ID, 성별, 생년월일, 이름, 상담 내용, 구강 사진, 안면 사진
- 제공받는 자의 보유 및 이용기간: 서비스 종료 시까지

5. 개인정보처리 위탁 ① 오닥터는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다. 
<회원정보 DB 연동 > - 위탁 받는 자 (수탁자) : [      ] - 위탁하는 업무의 내용 : 회원 개인정보 처리 - 위탁기간 : 서비스 종료시 까지 ② 오닥터는 위탁계약 체결 시 정보통신망 이용촉진 및 정보 보호 등에 관한 법률 및 개인정보 보호법에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적 관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리 감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다. ③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체 없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다. 
6. 정보주체와 법정대리인의 권리·의무 및 그 행사방법 이용자는 개인정보주체로써 다음과 같은 권리를 행사할 수 있습니다.
① 정보주체는 오닥터에 대해 언제든지 개인정보 열람, 정정, 삭제, 처리정지 요구 등의 권리를 행사할 수 있습니다. ② 제1항에 따른 권리 행사 중 민감정보에 관한 부분은 오닥터에 대해 개인정보 보호법 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 오닥터는 이에 대해 지체 없이 조치하겠습니다. ③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다. ④ 개인정보 열람 및 처리정지 요구는 관련 법률에 의하여 정보주체의 권리가 제한 될 수 있습니다. ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다. ⑥ 오닥터는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리 정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
⑦ 정보주체는 개인정보 보호 권한 당국에 (특히, EU 회원국에 거주하거나, EU 회원국의 직장에 재직 중인 경우 개인정보 보호법 위반 혐의로) 불만을 제기할 권리가 있습니다.
⑧ 정보 주체는 프랑스 개인정보 보호법에 따라 개인정보 주체의 사망 후 개인 정보 처리에 대해 결정할 권리

7. 개인정보의 파기 
오닥터는 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체 없이 해당 개인정보를 파기합니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.
- 파기절차 이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.
 - 파기기한 이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.

- 파기방법 전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다. 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.

9. 개인정보 자동 수집 장치의 설치 운영 및 거부에 관한 사항
① 오닥터는 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.
② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다. 
가. 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다. 
나. 쿠키의 설치, 운영 및 거부 : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다. 
다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다. 
10. 개인정보 보호책임자 작성  ① 오닥터는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다. ▶ 개인정보 보호책임자  성명 : [   고석호      ] 직책 :개인정보보호책임자 직급 : [    대표이사    ] 연락처 :  02.3443.1357 ※ 개인정보 보호 담당부서로 연결됩니다. ▶ 개인정보 보호 담당부서 부서명 : [   경영 마케팅전략부     ] 담당자 : [  고석호      ] 연락처 : 02.3443.1357 ② 정보주체는 오닥터의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 오닥터는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.

11. 개인정보처리방침 변경 
이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.

12. 개인정보의 안전성 확보 조치 
오닥터는 개인정보 보호법 및 정보통신망 이용촉진 및 정보 보호 등에 관한 법률 및 GDPR 제32조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.
① 정기적인 자체 감사 실시 개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.
 ② 개인정보 취급 직원의 최소화 및 교육 개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.  ③ 내부관리계획의 수립 및 시행 개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다. 
④ 해킹 등에 대비한 기술적 대책
오닥터는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신 점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.  ⑤ 개인정보의 암호화 이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.  ⑥ 접속기록의 보관 및 위조 • 변조 방지 개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능 사용하고 있습니다.  ⑦ 개인정보에 대한 접근 제한 개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.  ⑧ 문서보안을 위한 잠금장치 사용 개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.  ⑨ 비인가자에 대한 출입 통제 개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.`;

export default PrivatePolicyData;
