import React from 'react'

export default ({className, startTime, closeTime}) => {

  if (!startTime || !closeTime) {
    return (<span>휴진</span>);
  }

  let paddedSTime = startTime;
  let paddedCTime = closeTime;

  if (startTime.length === 3) {
    paddedSTime = "0" + startTime;
  }

  if (closeTime.length === 3) {
    paddedCTime = "0" + closeTime;
  }

  const sTime = paddedSTime.slice(0, 2) + ":" + paddedSTime.slice(2);
  const cTime = paddedCTime.slice(0, 2) + ":" + paddedCTime.slice(2);

  return (
    <span className={className}>{sTime} ~ {cTime}</span>
  );
};
