import React from 'react';
import { Query } from 'react-apollo';
import styled from 'styled-components';
import HospitalInfoContainer from './HospitalInfoContainer';
import { getHospitalQuery } from '../graphql';
import MobileCallModal from '../components/MobileCallModal';

const HospitalDetailContainer = styled.div`
  margin: 0 auto;
`;

class HospitalDetail extends React.Component {
  state = {
    isOpenPhoneModal: false,
  };

  render() {
    const { isOpenPhoneModal } = this.state;
    const { hid } = this.props;
    return (
      <Query query={getHospitalQuery} variables={{ id: hid }}>
        {({ loading, errors, data }) => {
          if (loading) return null;
          if (errors) return null;

          return (
            <HospitalDetailContainer>
              <HospitalInfoContainer
                openCallModal={() => this.setState({ isOpenPhoneModal: true })}
                hospital={data.getHospital}
                recentReservationCnt={data.getHospital.recentReservationCnt}
              />
              <MobileCallModal
                // todo : 유저 정보 meQuery 부모 단에서 가져와서
                onClose={() => this.setState({ isOpenPhoneModal: false })}
                // onBtnClick={this.reserveByCall}
                isOpen={isOpenPhoneModal}
                hospital={data.getHospital}
                me={{}}
                userRequest={{}}
              />
            </HospitalDetailContainer>
          );
        }}
      </Query>
    );
  }
}

export default HospitalDetail;
