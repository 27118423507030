import React from 'react';
import styled from 'styled-components';
import { SingleMarkerMap } from '../basic/GoogleMapComponent';
import { media } from '../../globalStyle';

const MapWrapper = styled.div`
  width: 100%;
  height: 200px;
  margin-top: 10px;
  ${media.miniDesktop`
    height: 180px;
    margin-top: 10px;
  `};
`;

const HospitalMapInfoComponent = ({ hospital }) => (
  <MapWrapper>
    {/* <div><pre>{JSON.stringify(hospital, null, 4)}</pre></div> */}
    <SingleMarkerMap
      hospital={hospital}
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCxRFDWtsni7fh7H2qqpv8RlbJGTOdvrfc&v=3.exp&libraries=geometry,drawing,places"
      loadingElement={<div style={{ height: '100%' }} />}
      containerElement={<div style={{ height: '100%' }} />}
      mapElement={<div style={{ height: '100%' }} />}
    />
  </MapWrapper>
);

export default HospitalMapInfoComponent;
