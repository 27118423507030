import gql from 'graphql-tag';

export const allCasesQuery = gql`
  query($hospitalId: ID, $therapyCategoryId: ID, $currentPage: Int, $keyword: String, $perPage: Int) {
    reqCases(filter: {therapyCategoryId: $therapyCategoryId, keyword: $keyword, hospitalId: $hospitalId}, currentPage: $currentPage, perPage: $perPage) {
      data {
        id
        location { # 사례 지역
          id
          siName
          gunguName
        }
        title
        # content
        contentSummary (maxLength: 150)
        userGender # 사례자 이름
        userAgeGroup # 사례자 나이대
        reqDate  # 사례 날짜
        categories {  # 사례 카테고리 리스트
          id
          name  # 사례 카테고리 이름
        }
        casePhotos { # 사례 사진
          id
          url
        }
        resps { # 사례에 대한 병원의 답변(복수도 가능)
          id
          hospital {
            id
            name
            reviewCnt
            recentReservationCnt
            locationString
            avgRating
          }
          content
          reqCaseId
        }
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const caseQuery = gql`
  query($id: ID!) {
    reqCase(id: $id) {
      id
      location {
        id
        siName
        gunguName
      }
      title
      content
      userGender
      userAgeGroup
      reqDate
      categories {
        id
        name
      }
      casePhotos {
        id
        url
        title
      }
      resps {
        id
        hospital {
          id
          name
          reviewCnt
          recentReservationCnt
          locationString
          avgRating
        }
        content
        reqCaseId
      }
    }
  }
`;

export const categoriesQuery = gql`
    query($type: String) {
      therapyCategories(type: $type) {
        id
        name
        description
        type
      }
    }
`;
