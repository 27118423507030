import { useEffect } from "react";

export default ({
  root,
  target,
  onIntersect,
  threshold = 1.0,
  rootMargin = "0px"
}) => {
  useEffect(
    () => {
      if (!target?.current) {
        return;
      }
      const observer = new IntersectionObserver(onIntersect, {
        root,
        rootMargin,
        threshold,
      });
      if (!target.current) return;
      observer.observe(target.current);
      return () => {
        if (!target?.current) {
          return;
        }
        observer.unobserve(target.current);
      };
    }, [target, rootMargin, onIntersect, threshold]
  );
};
