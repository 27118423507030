import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';

import { media } from '../globalStyle';
import OrthoPartSelectFormContainer from './form/OrthoPartSelectFormContainer';
import TherapyOption, {
  SubOption,
  SubOptionType,
  SelectTherapy,
} from './TherapyOption';
import { PaddingDiv } from './basic/HtmlComponents';

import implantImg from '../images/draftform/ic-imp-01-basic.svg';
import boneTImg from '../images/draftform/ic-imp-02-bone-graft.svg';
import maxillarySinusImg from '../images/draftform/ic-imp-03-sinus-augmentation.svg';
import ResinTherapyImg from '../images/draftform/ic-cavity-01-rezin.svg';
import InlayImg from '../images/draftform/ic-cavity-02-inray.svg';
import OnlayImg from '../images/draftform/ic-cavity-03-onray.svg';
import crownImg from '../images/draftform/ic-cavity-04-crown.svg';
import DentDesignImg from '../images/draftform/ic-beauty-01.svg';
import GumDesignImg from '../images/draftform/ic-beauty-02.svg';
import FrontToothImg from '../images/draftform/ic-beauty-03.svg';
import WhiteningImg from '../images/draftform/ic-beauty-04-bleaching.svg';
import BotoxImg from '../images/draftform/ic-beauty-05-botox.svg';
import ScalingImg from '../images/draftform/ic-beauty-06-scaling.svg';
import PlusImg from '../images/ic-plus-gray-40-px.svg';
import QuestionMarkImg from '../images/ic-question-20.png';
import DeletePhotoBtnImg from '../images/img-delete-cross-btn.svg';
import invisibleOrtho from '../images/draftform/ic-inivisible.svg';
import metalImg from '../images/draftform/ic-visible.svg';
import transpOrthoImg from '../images/draftform/ic-translate.svg';
import GeneralRootCanalImg from '../images/draftform/general-root-canal.png';
import GeneralWisdomtoothImg from '../images/draftform/general-wisdomtooth.png';
import GeneralFluorideImg from '../images/draftform/general-fluorine.png';
import GumTxImg from '../images/draftform/gum-tx.png';
import FacialJawImg from '../images/draftform/facial-jaw.png';
import FacialTowJawImg from '../images/draftform/facial-towjaw.png';


const OrthoDraftFormPanelContainer = styled.div`
  display: ${props => (props.show ? 'block' : 'none')};
`;

const CheckboxInput = styled.input`
  display: none;
  &:checked {
    + label {
      color: #fff;
      border-color: #033e52;
      background-color: #033e52;
      font-weight: bold;
    }
  }
`;

const CheckboxLabel = styled.label`
  width: 160px;
  height: 52px;
  border-radius: 26px;
  border: solid 1px #bbbcbc;
  background-color: #fff;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -1px;
  text-align: center;
  color: #bbbcbc;
  padding-top: 14px;
  cursor: pointer;
  margin: 6px;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  ${media.tablet`
    font-size: 13px;
    width: 135px;
    height: 52px;
    padding-top: 0;
    line-height: 52px;
  `};
`;

const QuestionMark = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
  position: relative;
  top: -1px;
  margin-left: 6px;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
`;

const TherapyContainer = styled.div`
  display: block;
`;

const TherapyTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: -1px;
  color: #000000;
  margin-bottom: 10px;
  h5 {
    font-size: inherit;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    margin: 0;
    line-height: 1;
    display: inline-block;
    vertical-align: middle;
  }  
  span {
    color: #bbbcbc;
    margin-left: 10px;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -1px;
    &.red {
      margin-left: 6px;
      color: #dd4124;
    }
  }
  ${media.tablet`
    margin-bottom: 10px;
    padding: 0;
    span {
      font-size: 12px;
    }
  `};
`;

const TherapySection = styled.div`
  position: relative;
  padding-top: 40px;
  margin-bottom: 32px;
  ${media.tablet`
    padding-top: 30px;
    margin-bottom: 42px;
  `};
`;

const CheckboxContainer = styled.div`
  display: inline-block;
`;

const Dropzones = styled.div`
  margin-right: -10px;
`;

const PreviewImageContainer = styled.div`
  width: 220px;
  height: 140px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  margin-top: 10px;
  border: solid 1px #e4e4e4;
  cursor: pointer;
  margin-right: 10px;
  ${media.tablet`
    width: 135px;
    height: 86px;
  `};
`;

const PreviewImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: relative;
`;

const InfoMessage = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #bbbcbc;
  margin-top: 16px;
  ${media.tablet`
    margin-top: 12px;
    font-size: 12px;
  `};
`;

const CheckBoxContainer = styled.div`
  margin-right: -12px;
  margin-bottom: -10px;
  ${media.tablet`
    margin: 0 -7px -10px;
  `};
`;

const DeletePhotoBtn = styled.img`
  position: absolute;
  z-index: 2;
  right: 0px;
  top: 0;
`;

const DropZoneWrapper = styled.div`
  width: 220px;
  height: 140px;
  margin-top: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  background-color: #fafafa;
  border: solid 1px #e4e4e4;
  color: gray;
  text-align: center;
  cursor: pointer;
  margin-right: 10px;
  img {
    align-self: center;
  }
  ${media.tablet`
    width: 135px;
    height: 86px;
    padding: 31px;
    img {
      width: 24px;
      height: 24px;
      object-fit: contain;
    }
  `};
`;

function StyledDropZone(props) {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file),
        })),
      );

      props.onDrop(acceptedFiles, props.targetName);
    },
  });

  return (
    <DropZoneWrapper {...getRootProps()}>
      <input name={props.name} {...getInputProps()} />
      <img src={PlusImg} alt="dropzonePlus" />
    </DropZoneWrapper>
  );
}

const TxDraftFormPanelContainer = styled.div`
  display: ${props => (props.show ? 'block' : 'none')};
`;

const HeaderContainer = styled.div`
  padding-bottom: 20px;
  border-bottom: 1px solid #000000;
  ${media.miniDesktop`
    padding: 0 0 12px;
  `};
`;

const FormSelectBtn = styled.div`
  font-size: 24px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: -1.3px;
  color: #000000;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  &:first-of-type {
    padding-right: 20px;
    margin-right: 20px;
    border-right: 1px solid #e0e0e0;
  }
  ${props => props.clicked
    && `
      font-weight: bold;
  `};
  ${media.tablet`
    font-size: 18px;
    line-height: 1.33;
    letter-spacing: -1.13px;
  `};
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 140px;
  border: solid 1px #e0e0e0;
  background-color: #ffffff;
  padding: 12px 14px;
  color: #484848;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #000000;
  text-align: left;
  resize: none;
  display: block;
  ${media.tablet`
    padding: 10px 12px;
    letter-spacing: -1px;
  `};
`;

const TabContainer = styled.div`
  width: 100%;
  ${media.miniDesktop`
    margin: 0 auto;
    width: 100%;
    padding: 0 20px 60px;
    max-width: 100%;
  `};
`;

const SectionSubTitle = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 20px;
  ${media.tablet`
    letter-spacing: -0.54px;
  `};
`;

const Panel = styled.div`
  ${media.tablet`
    padding: 0;
  `};
`;

const InfoText = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.8px;
  color: #bbbcbc;
  margin-top: 10px;
  word-break: keep-all;
  text-indent: -6px;
  padding-left: 6px;
`;

const ImageContainer = styled.div`
  margin-right: -10px;
`;

const orthoWannaData = [
  {
    id: 'chkImrpove1',
    value: '토끼이빨',
  },
  {
    id: 'chkImrpove2',
    value: '앞니가 나옴',
  },
  {
    id: 'chkImrpove3',
    value: '아랫니가 나옴',
  },
  {
    id: 'chkImrpove4',
    value: '치열이 삐뚤삐뚤',
  },
  {
    id: 'chkImrpove5',
    value: '거꾸로 물리는 앞니',
  },
  {
    id: 'chkImrpove6',
    value: '치아가 옥니',
  },
  {
    id: 'chkImrpove7',
    value: '거꾸로 물리는 어금니',
  },
  {
    id: 'chkImrpove8',
    value: '치아가 제대로 안물림',
  },
  {
    id: 'chkImrpove9',
    value: '아랫턱 돌출',
  },
];

const TherapySectionSubTitle = ({ title, subTitle, onClick }) => (
  <>
    <TherapyTitle>
      <h5>{ title || '내 치아상태 사진등록' }</h5>
      <QuestionMark
        src={QuestionMarkImg}
        alt="questionMark"
        onClick={onClick}
      />
    </TherapyTitle>
    <SectionSubTitle>
      {
        {
          'ortho': <>치과에서 찍은 X-ray 사진이 가장 좋아요!<br />
            얼굴 정면과 측면을 셀카로 찍어 등록해도 충분해요.</>,
          'tx': <>치과에서 찍은 X-ray 사진이 가장 좋아요!<br />
            불편한 곳이 보이도록 셀카로 찍어 등록해도 충분해요.</>
        }[subTitle]
      }
    </SectionSubTitle>
  </>
);

// 간편상담 옵션
const QuickTherapyOptions = (props) => {
  const {
    data: {
      txImageFiles,
      pickedTxTherapy,
      pickedOrthoType,
      orthoPart,
      orthoWanna,
      orthoImageFiles,
    },
    onOptionUnitChange,
    selectTherapy,
    deselectTherapy,
    onDrop,
    openTxImageFileDeleteModal,
    openOrthoImageFileDeleteModal,
    openOrthoPhotoGuideModal,
    openTxPhotoGuideModal,
    toggleOrthoPart,
    handleOrthoWannaChange,
    selectOrthoType,
    deselectOrthoType,
    isSelectTxTab,
  } = props;
  return (
    <div>
      <TxDraftFormPanelContainer show={isSelectTxTab}>
        <TherapyContainer>
          <TherapySection>
            <TherapyTitle>임플란트</TherapyTitle>
            <SelectTherapy
              id="implant_implant"
              name="implant_implant"
              image={implantImg}
              unit={
                pickedTxTherapy.implant_implant
                  ? pickedTxTherapy.implant_implant.unit
                  : 0
              }
              therapyName="임플란트 식립"
              therapySubs="치아가 없는 부위에 인공치아를 심는 수술"
              onOptionUnitChange={onOptionUnitChange}
              active={pickedTxTherapy.implant_implant}
              selectTherapy={selectTherapy}
              deselectTherapy={deselectTherapy}
            />
          </TherapySection>
          <TherapySection>
            <TherapyTitle>충치치료</TherapyTitle>
            <SelectTherapy
              id="cavity"
              name="cavity"
              image={InlayImg}
              unit={pickedTxTherapy.cavity ? pickedTxTherapy.cavity.unit : 0}
              therapyName="충치때우기"
              therapySubs="충치 혹은 깨진 부위에 치아색상의 재료인 레진으로 때워주는 치료"
              active={pickedTxTherapy.cavity}
              selectTherapy={selectTherapy}
              deselectTherapy={deselectTherapy}
            />
          </TherapySection>
          <TherapySection>
            <TherapyTitle>일반치료</TherapyTitle>
            <SelectTherapy
              id="general_rootCanal"
              name="general_rootCanal"
              image={GeneralRootCanalImg}
              unit={pickedTxTherapy.general_rootCanal ? pickedTxTherapy.general_rootCanal.unit : 0}
              therapyName="신경치료"
              therapySubs="감염된 신경・조직을 제거 후 특수 재료를 충전하여 치아 기능을 유지하는 치료"
              active={pickedTxTherapy.general_rootCanal}
              selectTherapy={selectTherapy}
              deselectTherapy={deselectTherapy}
            />
            <SelectTherapy
              id="general_wisdomtooth"
              name="general_wisdomtooth"
              image={GeneralWisdomtoothImg}
              unit={pickedTxTherapy.general_wisdomtooth ? pickedTxTherapy.general_wisdomtooth.unit : 0}
              therapyName="사랑니발치"
              therapySubs="어금니 뒤쪽을 비집고 나오는 사랑니를 뽑는 치료"
              active={pickedTxTherapy.general_wisdomtooth}
              selectTherapy={selectTherapy}
              deselectTherapy={deselectTherapy}
            />
            <SelectTherapy
              id="aesthetic_scaling"
              name="aesthetic_scaling"
              image={ScalingImg}
              unit={
                pickedTxTherapy.aesthetic_scaling
                  ? pickedTxTherapy.aesthetic_scaling.unit
                  : 0
              }
              therapyName="스케일링"
              therapySubs="양치로 잘 닦이지 않는 치석을 제거해주는 치료"
              selectTherapy={selectTherapy}
              deselectTherapy={deselectTherapy}
              active={pickedTxTherapy.aesthetic_scaling}
            />
            <SelectTherapy
              id="general_fluoride"
              name="general_fluoride"
              image={GeneralFluorideImg}
              unit={pickedTxTherapy.general_fluoride ? pickedTxTherapy.general_fluoride.unit : 0}
              therapyName="불소도포"
              therapySubs="치아를 단단하게 하여 충치를 예방해주는 불소를 치아에 바르는 치료"
              active={pickedTxTherapy.general_fluoride}
              selectTherapy={selectTherapy}
              deselectTherapy={deselectTherapy}
            />
          </TherapySection>
          <TherapySection>
            <TherapyTitle>잇몸치료</TherapyTitle>
            <SelectTherapy
              id="gum_tx"
              name="gum_tx"
              image={GumTxImg}
              unit={pickedTxTherapy.gum_tx ? pickedTxTherapy.gum_tx.unit : 0}
              therapyName="잇몸치료"
              therapySubs="잇몸이 붓고 피가 나게 하는 잇몸 염증을 제거하는 치료"
              active={pickedTxTherapy.gum_tx}
              selectTherapy={selectTherapy}
              deselectTherapy={deselectTherapy}
            />
          </TherapySection>
          <TherapySection>
            <TherapyTitle>미용, 심미치료</TherapyTitle>
            <SelectTherapy
              id="aesthetic_frontTooth"
              name="aesthetic_frontTooth"
              image={FrontToothImg}
              unit={
                pickedTxTherapy.aesthetic_frontTooth
                  ? pickedTxTherapy.aesthetic_frontTooth.unit
                  : 0
              }
              therapyName="앞니 심미치료"
              therapySubs="앞니 치아 스마일라인이 불규칙하거나 마음에 안드는 부분을 다듬는 치료"
              selectTherapy={selectTherapy}
              deselectTherapy={deselectTherapy}
              active={pickedTxTherapy.aesthetic_frontTooth}
            />
            <SelectTherapy
              id="aesthetic_bleaching"
              name="aesthetic_bleaching"
              image={WhiteningImg}
              unit={
                pickedTxTherapy.aesthetic_bleaching
                  ? pickedTxTherapy.aesthetic_bleaching.unit
                  : 0
              }
              therapyName="치아미백"
              therapySubs="누렇게 변색 된 치아를 하얗고 밝게 만들어주는 치료"
              onOptionUnitChange={onOptionUnitChange}
              selectTherapy={selectTherapy}
              deselectTherapy={deselectTherapy}
              active={pickedTxTherapy.aesthetic_bleaching}
            />
            <SelectTherapy
              id="aesthetic_botox"
              name="aesthetic_botox"
              image={BotoxImg}
              unit={
                pickedTxTherapy.aesthetic_botox
                  ? pickedTxTherapy.aesthetic_botox.unit
                  : 0
              }
              therapyName="보톡스"
              therapySubs="근육을 약하게 하는 주사를 놓아 턱을 작게 만들어주거나 주름을 펴주는 시술"
              onOptionUnitChange={onOptionUnitChange}
              selectTherapy={selectTherapy}
              deselectTherapy={deselectTherapy}
              active={pickedTxTherapy.aesthetic_botox}
            />
          </TherapySection>
          <TherapySection>
            <TherapyTitle>턱/얼굴치료</TherapyTitle>
            <SelectTherapy
              id="facial_jaw"
              name="facial_jaw"
              image={FacialJawImg}
              unit={pickedTxTherapy.facial_jaw ? pickedTxTherapy.facial_jaw.unit : 0}
              therapyName="턱관절치료"
              therapySubs="턱관절의 소리나 통증을 유발하는 원인을 찾아 개선하는 치료"
              active={pickedTxTherapy.facial_jaw}
              selectTherapy={selectTherapy}
              deselectTherapy={deselectTherapy}
            />
            <SelectTherapy
              id="facial_twojaw"
              name="facial_twojaw"
              image={FacialTowJawImg}
              unit={pickedTxTherapy.facial_twojaw ? pickedTxTherapy.facial_twojaw.unit : 0}
              therapyName="양악수술"
              therapySubs="부정교합, 안면비대칭과 같은 문제를 턱의 위치나 모양을 변형하는 수술"
              active={pickedTxTherapy.facial_twojaw}
              selectTherapy={selectTherapy}
              deselectTherapy={deselectTherapy}
            />
          </TherapySection>
          <TherapySection>
            <TherapySectionSubTitle
              subTitle={'tx'}
              onClick={openTxPhotoGuideModal}
            />
            <ImageContainer>
              {txImageFiles
                && txImageFiles.length > 0
                && txImageFiles.map((file, idx) => (
                  <PreviewImageContainer onClick={() => openTxImageFileDeleteModal(idx)} key={`txImage_${idx}`}>
                    <DeletePhotoBtn
                      src={DeletePhotoBtnImg}
                      alt="delete photo"
                    />
                    <PreviewImage src={file.preview} alt="preview" />
                  </PreviewImageContainer>
                ))}
              <StyledDropZone
                onDrop={onDrop}
                targetName="tx"
                name="quick_counsel_tx_dropzone"
              />
            </ImageContainer>
          </TherapySection>
        </TherapyContainer>
      </TxDraftFormPanelContainer>
      <OrthoDraftFormPanelContainer show={!isSelectTxTab}>
        <TherapyContainer>
          <TherapySection>
            <TherapyTitle>
              교정 부위
              <span className="red">*필수선택</span>
            </TherapyTitle>
            <OrthoPartSelectFormContainer
              onPartChange={part => toggleOrthoPart(part)}
              part={orthoPart}
            />
          </TherapySection>
          <TherapySection>
            <TherapyTitle>
              희망 교정
              <span className="red">*필수선택</span>
            </TherapyTitle>
            <SelectTherapy
              id="ortho"
              name="ortho_visible"
              image={metalImg}
              unit={
                pickedOrthoType && pickedOrthoType === 'ortho_visible' ? 1 : 0
              }
              therapyName="보이는 교정"
              therapySubs="교정 장치를 겉으로 붙이는 교정치료. 일반적이지만, 웃을때 장치가 보임"
              selectTherapy={selectOrthoType}
              deselectTherapy={deselectOrthoType}
              active={pickedOrthoType && pickedOrthoType === 'ortho_visible'}
            />
            <SelectTherapy
              id="ortho"
              name="ortho_unvisible"
              image={invisibleOrtho}
              unit={
                pickedOrthoType && pickedOrthoType === 'ortho_unvisible' ? 1 : 0
              }
              therapyName="안보이는 교정"
              therapySubs="교정장치를 안쪽으로 붙이는 방식으로, 발음이 불편하거나 부분교정만 가능한 경우가 있음"
              selectTherapy={selectOrthoType}
              deselectTherapy={deselectOrthoType}
              active={pickedOrthoType && pickedOrthoType === 'ortho_unvisible'}
            />
            <SelectTherapy
              id="ortho"
              name="ortho_transparent"
              image={transpOrthoImg}
              unit={
                pickedOrthoType && pickedOrthoType === 'ortho_transparent'
                  ? 1
                  : 0
              }
              therapyName="투명 교정"
              therapySubs="투명한 플라스틱소재로 치열에 맞게 탈착 할 수 있지만. 교정 효과가 약하거나 제한될 수 있음"
              selectTherapy={selectOrthoType}
              deselectTherapy={deselectOrthoType}
              active={
                pickedOrthoType && pickedOrthoType === 'ortho_transparent'
              }
            />
            <InfoMessage>*1가지 필수로 선택해야 합니다.</InfoMessage>
          </TherapySection>
          <TherapySection>
            <TherapyTitle>
              희망하는 개선 부분은?
              <span>(중복선택가능)</span>
            </TherapyTitle>
            <PaddingDiv padding="10px 0">
              <CheckBoxContainer>
                {orthoWannaData.map((option, idx) => (
                  <CheckboxContainer key={`orthoOption_${idx}`}>
                    <CheckboxInput
                      onChange={handleOrthoWannaChange.bind(this, option.value)}
                      type="checkbox"
                      name=""
                      id={option.id}
                      value={option.value}
                      checked={orthoWanna.indexOf(option.value) > -1}
                    />
                    <CheckboxLabel htmlFor={option.id}>
                      {option.value}
                    </CheckboxLabel>
                  </CheckboxContainer>
                ))}
              </CheckBoxContainer>
            </PaddingDiv>
          </TherapySection>
          <TherapySection>
            <TherapySectionSubTitle
              subTitle={'ortho'}
              onClick={openOrthoPhotoGuideModal}
            />
            <Dropzones>
              {orthoImageFiles
                && orthoImageFiles.length > 0
                && orthoImageFiles.map((file, idx) => (
                  <PreviewImageContainer onClick={() => openOrthoImageFileDeleteModal(idx)} key={`orthoImage_${idx}`}>
                    <DeletePhotoBtn
                      src={DeletePhotoBtnImg}
                      alt="delete photo"
                    />
                    <PreviewImage src={file.preview} alt="preview" />
                  </PreviewImageContainer>
                ))}
              <StyledDropZone
                onDrop={onDrop}
                targetName="ortho"
                name="quick_counsel_ortho_dropzone"
              />
            </Dropzones>
          </TherapySection>
        </TherapyContainer>
      </OrthoDraftFormPanelContainer>
    </div>
  );
};

// 자세한상담 옵션
const DetailTherapyOptions = (props) => {
  const {
    data: {
      txImageFiles,
      pickedTxTherapy,
      pickedOrthoType,
      orthoPart,
      orthoWanna,
      orthoImageFiles,
    },
    onOptionUnitChange,
    selectTherapy,
    deselectTherapy,
    onDrop,
    openTxImageFileDeleteModal,
    openOrthoImageFileDeleteModal,
    openOrthoPhotoGuideModal,
    openTxPhotoGuideModal,
    toggleOrthoPart,
    handleOrthoWannaChange,
    selectOrthoType,
    deselectOrthoType,
    onOptionTypeChange,
    isSelectTxTab,
  } = props;

  return (
    <div>
      {/* 치과 치료 탭 */}
      <TxDraftFormPanelContainer show={isSelectTxTab}>
        <TherapyContainer>
          <TherapySection>
            <TherapyTitle>임플란트</TherapyTitle>
            <TherapyOption
              id="implant_implant"
              name="implant_implant"
              image={implantImg}
              unit={
                pickedTxTherapy.implant_implant
                  ? pickedTxTherapy.implant_implant.unit
                  : 0
              }
              therapyName="임플란트 식립"
              therapySubs="치아가 없는 부위에 인공치아를 심는 수술"
              onOptionUnitChange={onOptionUnitChange}
              active={pickedTxTherapy.implant_implant}
            />
            <TherapyOption
              id="implant_boneGraft"
              name="implant_boneGraft"
              image={boneTImg}
              unit={
                pickedTxTherapy.implant_boneGraft
                  ? pickedTxTherapy.implant_boneGraft.unit
                  : 0
              }
              therapyName="뼈이식 수술"
              therapySubs="임플란트 시술할 부위에 부족한 뼈를 보충하는 수술"
              onOptionUnitChange={onOptionUnitChange}
              active={pickedTxTherapy.implant_boneGraft}
            />
            <TherapyOption
              id="implant_maxillarySinus"
              name="implant_maxillarySinus"
              image={maxillarySinusImg}
              unit={
                pickedTxTherapy.implant_maxillarySinus
                  ? pickedTxTherapy.implant_maxillarySinus.unit
                  : 0
              }
              therapyName="상악동 수술"
              therapySubs="위턱 빈 공간에 임플란트 시술을 위한 뼈이식 공간을 만드는 수술"
              onOptionUnitChange={onOptionUnitChange}
              active={pickedTxTherapy.implant_maxillarySinus}
            />
            <InfoMessage>
              * 대략적인 갯수만 선택해 주셔도 상담이 가능합니다.
            </InfoMessage>
          </TherapySection>
          <TherapySection>
            <TherapyTitle>충치치료</TherapyTitle>
            <TherapyOption
              id="cavity_resin"
              name="cavity_resin"
              image={ResinTherapyImg}
              unit={
                pickedTxTherapy.cavity_resin
                  ? pickedTxTherapy.cavity_resin.unit
                  : 0
              }
              therapyName="간단때우기"
              therapySubs="충치 혹은 깨진 부위에 치아색상의 재료인 레진으로 때워주는 치료"
              onOptionUnitChange={onOptionUnitChange}
              active={pickedTxTherapy.cavity_resin}
            />
            <TherapyOption
              id="cavity_inlay"
              name="cavity_inlay_resin"
              image={InlayImg}
              unit={
                pickedTxTherapy.cavity_inlay
                  ? pickedTxTherapy.cavity_inlay.unit
                  : 0
              }
              therapyName="인레이"
              therapySubs="충치의 범위가 넓거나 치아 사이에 충치가 있는 경우 충치 부위에 맞게 외부에서 제작하여 끼워주는 치료"
              onOptionUnitChange={onOptionUnitChange}
              active={pickedTxTherapy.cavity_inlay}
            >
              <SubOption
                id="cavity_inlay"
                name="레진"
                optionName="cavity_inlay_resin"
                onSubOptionChange={onOptionTypeChange}
                value={
                  pickedTxTherapy.cavity_inlay
                    ? pickedTxTherapy.cavity_inlay.name
                    : null
                }
              >
                <SubOptionType name="강도" rating={3} />
                <SubOptionType name="심미성" rating={4} />
                <SubOptionType name="가격" rating={2} />
              </SubOption>
              <SubOption
                id="cavity_inlay"
                name="금"
                optionName="cavity_inlay_gold"
                onSubOptionChange={onOptionTypeChange}
                value={
                  pickedTxTherapy.cavity_inlay
                    ? pickedTxTherapy.cavity_inlay.name
                    : null
                }
              >
                <SubOptionType name="강도" rating={5} />
                <SubOptionType name="심미성" rating={2} />
                <SubOptionType name="가격" rating={4} />
              </SubOption>
              <SubOption
                id="cavity_inlay"
                name="세라믹"
                optionName="cavity_inlay_ceramic"
                onSubOptionChange={onOptionTypeChange}
                value={
                  pickedTxTherapy.cavity_inlay
                    ? pickedTxTherapy.cavity_inlay.name
                    : null
                }
              >
                <SubOptionType name="강도" rating={4} />
                <SubOptionType name="심미성" rating={5} />
                <SubOptionType name="가격" rating={4} />
              </SubOption>
            </TherapyOption>
            <TherapyOption
              id="cavity_onlay"
              name="cavity_onlay_gold"
              image={OnlayImg}
              unit={
                pickedTxTherapy.cavity_onlay
                  ? pickedTxTherapy.cavity_onlay.unit
                  : 0
              }
              therapyName="온레이"
              therapySubs="치아가 물리는 부분까지 충치 범위가 넓은 경우에 물리는 부분 일부까지 포함해 씌워주는 치료"
              onOptionUnitChange={onOptionUnitChange}
              active={pickedTxTherapy.cavity_onlay}
            >
              <SubOption
                id="cavity_onlay"
                name="금"
                optionName="cavity_onlay_gold"
                onSubOptionChange={onOptionTypeChange}
                value={
                  pickedTxTherapy.cavity_onlay
                    ? pickedTxTherapy.cavity_onlay.name
                    : null
                }
              >
                <SubOptionType name="강도" rating={5} />
                <SubOptionType name="심미성" rating={2} />
                <SubOptionType name="가격" rating={4} />
              </SubOption>
              <SubOption
                id="cavity_onlay"
                name="세라믹"
                optionName="cavity_onlay_ceramic"
                onSubOptionChange={onOptionTypeChange}
                value={
                  pickedTxTherapy.cavity_onlay
                    ? pickedTxTherapy.cavity_onlay.name
                    : null
                }
              >
                <SubOptionType name="강도" rating={4} />
                <SubOptionType name="심미성" rating={5} />
                <SubOptionType name="가격" rating={4} />
              </SubOption>
            </TherapyOption>
            <TherapyOption
              id="cavity_crown"
              name="cavity_crown_gold"
              image={crownImg}
              unit={
                pickedTxTherapy.cavity_crown
                  ? pickedTxTherapy.cavity_crown.unit
                  : 0
              }
              therapyName="크라운"
              therapySubs="충치가 심하거나 치아가 깨졌을 때 치아 전체를 씌워주는 치료"
              onOptionUnitChange={onOptionUnitChange}
              active={pickedTxTherapy.cavity_crown}
            >
              <SubOption
                id="cavity_crown"
                name="금"
                optionName="cavity_crown_gold"
                onSubOptionChange={onOptionTypeChange}
                value={
                  pickedTxTherapy.cavity_crown
                    ? pickedTxTherapy.cavity_crown.name
                    : null
                }
              >
                <SubOptionType name="강도" rating={5} />
                <SubOptionType name="심미성" rating={2} />
                <SubOptionType name="가격" rating={4} />
              </SubOption>
              <SubOption
                id="cavity_crown"
                name="pfm"
                optionName="cavity_crown_pfm"
                onSubOptionChange={onOptionTypeChange}
                value={
                  pickedTxTherapy.cavity_crown
                    ? pickedTxTherapy.cavity_crown.name
                    : null
                }
              >
                <SubOptionType name="강도" rating={3} />
                <SubOptionType name="심미성" rating={4} />
                <SubOptionType name="가격" rating={3} />
              </SubOption>
              <SubOption
                id="cavity_crown"
                name="지르코니아"
                optionName="cavity_crown_zirconia"
                onSubOptionChange={onOptionTypeChange}
                value={
                  pickedTxTherapy.cavity_crown
                    ? pickedTxTherapy.cavity_crown.name
                    : null
                }
              >
                <SubOptionType name="강도" rating={4} />
                <SubOptionType name="심미성" rating={5} />
                <SubOptionType name="가격" rating={4} />
              </SubOption>
            </TherapyOption>
            <InfoMessage>
              * 대략적인 갯수만 선택해 주셔도 상담이 가능합니다.
            </InfoMessage>
          </TherapySection>
          <TherapySection>
            <TherapyTitle>일반치료</TherapyTitle>
            <SelectTherapy
              id="general_rootCanal"
              name="general_rootCanal"
              image={GeneralRootCanalImg}
              unit={pickedTxTherapy.general_rootCanal ? pickedTxTherapy.general_rootCanal.unit : 0}
              therapyName="신경치료"
              therapySubs="치아 내부의 감염된 신경・조직을 제거한 후 특수 재료를 충전하여 치아 기능을 유지하는 치료"
              active={pickedTxTherapy.general_rootCanal}
              selectTherapy={selectTherapy}
              deselectTherapy={deselectTherapy}
            />
            <SelectTherapy
              id="general_wisdomtooth"
              name="general_wisdomtooth"
              image={GeneralWisdomtoothImg}
              unit={pickedTxTherapy.general_wisdomtooth ? pickedTxTherapy.general_wisdomtooth.unit : 0}
              therapyName="사랑니발치"
              therapySubs="어금니 뒤쪽을 비집고 나오는 사랑니를 뽑는 치료"
              active={pickedTxTherapy.general_wisdomtooth}
              selectTherapy={selectTherapy}
              deselectTherapy={deselectTherapy}
            />
            <SelectTherapy
              id="aesthetic_scaling"
              name="aesthetic_scaling"
              image={ScalingImg}
              unit={
                pickedTxTherapy.aesthetic_scaling
                  ? pickedTxTherapy.aesthetic_scaling.unit
                  : 0
              }
              therapyName="스케일링"
              therapySubs="양치로 잘 닦이지 않는 치석을 제거해주는 치료"
              selectTherapy={selectTherapy}
              deselectTherapy={deselectTherapy}
              active={pickedTxTherapy.aesthetic_scaling}
            />
            <SelectTherapy
              id="general_fluoride"
              name="general_fluoride"
              image={GeneralFluorideImg}
              unit={pickedTxTherapy.general_fluoride ? pickedTxTherapy.general_fluoride.unit : 0}
              therapyName="불소도포"
              therapySubs="치아를 단단하게 하여 충치를 예방해주는 불소를 치아에 바르는 치료"
              active={pickedTxTherapy.general_fluoride}
              selectTherapy={selectTherapy}
              deselectTherapy={deselectTherapy}
            />
          </TherapySection>
          <TherapySection>
            <TherapyTitle>잇몸치료</TherapyTitle>
            <SelectTherapy
              id="gum_tx"
              name="gum_tx"
              image={GumTxImg}
              unit={pickedTxTherapy.gum_tx ? pickedTxTherapy.gum_tx.unit : 0}
              therapyName="잇몸치료"
              therapySubs="잇몸이 붓고 피가 나게 하는 잇몸 염증을 제거하는 치료"
              active={pickedTxTherapy.gum_tx}
              selectTherapy={selectTherapy}
              deselectTherapy={deselectTherapy}
            />
          </TherapySection>
          <TherapySection>
            <TherapyTitle>미용, 심미치료</TherapyTitle>
            <SelectTherapy
              id="aesthetic_dentDesign"
              name="aesthetic_dentDesign"
              image={DentDesignImg}
              unit={
                pickedTxTherapy.aesthetic_dentDesign
                  ? pickedTxTherapy.aesthetic_dentDesign.unit
                  : 0
              }
              therapyName="치아성형"
              therapySubs="앞니 치아 스마일라인이 불규칙하거나 마음에 안드는 부분을 부분적으로 다듬는 치료"
              selectTherapy={selectTherapy}
              deselectTherapy={deselectTherapy}
              active={pickedTxTherapy.aesthetic_dentDesign}
            />
            <SelectTherapy
              id="aesthetic_gumDesign"
              name="aesthetic_gumDesign"
              image={GumDesignImg}
              unit={
                pickedTxTherapy.aesthetic_gumDesign
                  ? pickedTxTherapy.aesthetic_gumDesign.unit
                  : 0
              }
              therapyName="잇몸 성형"
              therapySubs="웃을때 잇몸라인이 불규칙하거나 마음에 안드는 부분을 부분적으로 다듬는 치료"
              selectTherapy={selectTherapy}
              deselectTherapy={deselectTherapy}
              active={pickedTxTherapy.aesthetic_gumDesign}
            />
            <TherapyOption
              id="aesthetic_frontTooth"
              name="aesthetic_frontTooth_resin"
              image={FrontToothImg}
              unit={
                pickedTxTherapy.aesthetic_frontTooth
                  ? pickedTxTherapy.aesthetic_frontTooth.unit
                  : 0
              }
              therapyName="앞니 심미치료"
              therapySubs="웃을때 이상적인 앞니 치아 모양을 위해 심미적 재료로 만들어 붙이거나 씌워주는 치료"
              onOptionUnitChange={onOptionUnitChange}
              active={pickedTxTherapy.aesthetic_frontTooth}
            >
              <SubOption
                id="aesthetic_frontTooth"
                name="레진"
                optionName="aesthetic_frontTooth_resin"
                onSubOptionChange={onOptionTypeChange}
                value={
                  pickedTxTherapy.aesthetic_frontTooth
                    ? pickedTxTherapy.aesthetic_frontTooth.name
                    : null
                }
              >
                <SubOptionType name="강도" rating={3} />
                <SubOptionType name="심미성" rating={3} />
                <SubOptionType name="가격" rating={2} />
              </SubOption>
              <SubOption
                id="aesthetic_frontTooth"
                name="pfm"
                optionName="aesthetic_frontTooth_pfm"
                onSubOptionChange={onOptionTypeChange}
                value={
                  pickedTxTherapy.aesthetic_frontTooth
                    ? pickedTxTherapy.aesthetic_frontTooth.name
                    : null
                }
              >
                <SubOptionType name="강도" rating={4} />
                <SubOptionType name="심미성" rating={4} />
                <SubOptionType name="가격" rating={3} />
              </SubOption>
              <SubOption
                id="aesthetic_frontTooth"
                name="올세라믹"
                optionName="aesthetic_frontTooth_woolCeramic"
                onSubOptionChange={onOptionTypeChange}
                value={
                  pickedTxTherapy.aesthetic_frontTooth
                    ? pickedTxTherapy.aesthetic_frontTooth.name
                    : null
                }
              >
                <SubOptionType name="강도" rating={4} />
                <SubOptionType name="심미성" rating={5} />
                <SubOptionType name="가격" rating={4} />
              </SubOption>
              <SubOption
                id="aesthetic_frontTooth"
                name="지르코니아"
                optionName="aesthetic_frontTooth_zirconia"
                onSubOptionChange={onOptionTypeChange}
                value={
                  pickedTxTherapy.aesthetic_frontTooth
                    ? pickedTxTherapy.aesthetic_frontTooth.name
                    : null
                }
              >
                <SubOptionType name="강도" rating={5} />
                <SubOptionType name="심미성" rating={4} />
                <SubOptionType name="가격" rating={4} />
              </SubOption>
              <SubOption
                id="aesthetic_frontTooth"
                name="라미네이트"
                optionName="aesthetic_frontTooth_laminate"
                onSubOptionChange={onOptionTypeChange}
                value={
                  pickedTxTherapy.aesthetic_frontTooth
                    ? pickedTxTherapy.aesthetic_frontTooth.name
                    : null
                }
              >
                <SubOptionType name="강도" rating={5} />
                <SubOptionType name="심미성" rating={4} />
                <SubOptionType name="가격" rating={4} />
              </SubOption>
            </TherapyOption>
            <TherapyOption
              id="aesthetic_bleaching"
              name="aesthetic_bleaching"
              image={WhiteningImg}
              unit={
                pickedTxTherapy.aesthetic_bleaching
                  ? pickedTxTherapy.aesthetic_bleaching.unit
                  : 0
              }
              therapyName="치아미백"
              therapySubs="누렇게 변색 된 치아를 미백제를 이용하여 하얗고 밝게 만들어주는 치료"
              onOptionUnitChange={onOptionUnitChange}
              active={pickedTxTherapy.aesthetic_bleaching}
            />
            <TherapyOption
              id="aesthetic_botox"
              name="aesthetic_botox"
              image={BotoxImg}
              unit={
                pickedTxTherapy.aesthetic_botox
                  ? pickedTxTherapy.aesthetic_botox.unit
                  : 0
              }
              therapyName="보톡스"
              therapySubs="근육을 약하게 하는 주사를 놓아 턱을 작게 만들어주거나 주름을 펴주는 시술"
              onOptionUnitChange={onOptionUnitChange}
              active={pickedTxTherapy.aesthetic_botox}
            />
            <InfoMessage>
              * 대략적인 갯수만 선택해 주셔도 상담이 가능합니다.
            </InfoMessage>
          </TherapySection>
          <TherapySection>
            <TherapyTitle>턱/얼굴치료</TherapyTitle>
            <SelectTherapy
              id="facial_jaw"
              name="facial_jaw"
              image={FacialJawImg}
              unit={pickedTxTherapy.facial_jaw ? pickedTxTherapy.facial_jaw.unit : 0}
              therapyName="턱관절치료"
              therapySubs="턱관절의 소리나 통증을 유발하는 원인을 찾아 개선하는 치료"
              active={pickedTxTherapy.facial_jaw}
              selectTherapy={selectTherapy}
              deselectTherapy={deselectTherapy}
            />
            <SelectTherapy
              id="facial_twojaw"
              name="facial_twojaw"
              image={FacialTowJawImg}
              unit={pickedTxTherapy.facial_twojaw ? pickedTxTherapy.facial_twojaw.unit : 0}
              therapyName="양악수술"
              therapySubs="부정교합, 안면비대칭과 같은 문제를 턱의 위치나 모양을 변형하는 수술을 통해 해결하는 치료"
              active={pickedTxTherapy.facial_twojaw}
              selectTherapy={selectTherapy}
              deselectTherapy={deselectTherapy}
            />
          </TherapySection>
          <TherapySection>
            <TherapySectionSubTitle
              subTitle={'tx'}
              onClick={openTxPhotoGuideModal}
            />
            {txImageFiles.map((file, idx) => (
              <PreviewImageContainer onClick={() => openTxImageFileDeleteModal(idx)} key={`txImage_${idx}`}>
                <DeletePhotoBtn src={DeletePhotoBtnImg} alt="delete photo" />
                <PreviewImage src={file.preview} alt="preview" />
              </PreviewImageContainer>
            ))}
            <StyledDropZone
              onDrop={onDrop}
              targetName="tx"
              name="detail_counsel_tx_dropzone"
            />
          </TherapySection>
        </TherapyContainer>
      </TxDraftFormPanelContainer>
      {/* 교정 치료 탭 */}
      <OrthoDraftFormPanelContainer show={!isSelectTxTab}>
        <TherapyContainer>
          <TherapySection>
            <TherapyTitle>
              교정 부위
              <span className="red">*필수선택</span>
            </TherapyTitle>
            <OrthoPartSelectFormContainer
              onPartChange={part => toggleOrthoPart(part)}
              part={orthoPart}
            />
          </TherapySection>
          <TherapySection>
            <TherapyTitle>
              희망 교정
              <span className="red">*필수선택</span>
            </TherapyTitle>
            <SelectTherapy
              id="ortho"
              name="ortho_visible_metal"
              image={metalImg}
              unit={
                pickedOrthoType
                && (pickedOrthoType === 'ortho_visible_metal'
                  || pickedOrthoType === 'ortho_visible_clipyC'
                  || pickedOrthoType === 'ortho_visible_resin'
                  || pickedOrthoType === 'ortho_visible_mta'
                  || pickedOrthoType === 'ortho_visible_damon')
                  ? 1
                  : 0
              }
              therapyName="보이는 교정"
              therapySubs="교정 장치를 겉으로 붙이는 교정치료. 가장 일반적이고 정석적이지만, 웃을때 장치가 보이는 단점이 있음"
              selectTherapy={selectOrthoType}
              deselectTherapy={deselectOrthoType}
              active={
                pickedOrthoType
                && (pickedOrthoType === 'ortho_visible_metal'
                  || pickedOrthoType === 'ortho_visible_clipyC'
                  || pickedOrthoType === 'ortho_visible_resin'
                  || pickedOrthoType === 'ortho_visible_mta'
                  || pickedOrthoType === 'ortho_visible_damon')
              }
            >
              <SubOption
                id="ortho"
                name="메탈 교정"
                optionName="ortho_visible_metal"
                onSubOptionChange={selectOrthoType}
                value={pickedOrthoType || null}
              >
                <SubOptionType name="교정력" rating={5} />
                <SubOptionType name="심미성" rating={2} />
                <SubOptionType name="가격" rating={2} />
              </SubOption>
              <SubOption
                id="ortho"
                name="Clipy-C"
                optionName="ortho_visible_clipyC"
                onSubOptionChange={selectOrthoType}
                value={pickedOrthoType || null}
              >
                <SubOptionType name="교정력" rating={5} />
                <SubOptionType name="심미성" rating={4} />
                <SubOptionType name="가격" rating={4} />
              </SubOption>
              <SubOption
                id="ortho"
                name="레진 브라켓"
                optionName="ortho_visible_resin"
                onSubOptionChange={selectOrthoType}
                value={pickedOrthoType || null}
              >
                <SubOptionType name="교정력" rating={3} />
                <SubOptionType name="심미성" rating={4} />
                <SubOptionType name="가격" rating={3} />
              </SubOption>
              <SubOption
                id="ortho"
                name="MTA"
                optionName="ortho_visible_mta"
                onSubOptionChange={selectOrthoType}
                value={pickedOrthoType || null}
              >
                <SubOptionType name="교정력" rating={2} />
                <SubOptionType name="심미성" rating={5} />
                <SubOptionType name="가격" rating={4} />
              </SubOption>
              <SubOption
                id="ortho"
                name="DAMON"
                optionName="ortho_visible_damon"
                onSubOptionChange={selectOrthoType}
                value={pickedOrthoType || null}
              >
                <SubOptionType name="교정력" rating={4} />
                <SubOptionType name="심미성" rating={4} />
                <SubOptionType name="가격" rating={4} />
              </SubOption>
            </SelectTherapy>
            <SelectTherapy
              id="ortho"
              name="ortho_unvisible_2d"
              image={invisibleOrtho}
              unit={
                pickedOrthoType
                && (pickedOrthoType === 'ortho_unvisible_2d'
                  || pickedOrthoType === 'ortho_unvisible_clipyL'
                  || pickedOrthoType === 'ortho_unvisible_tiny')
                  ? 1
                  : 0
              }
              therapyName="안보이는 교정"
              therapySubs="교정장치를 안쪽으로 붙이는 방식으로 장치가 안보이는 장점이 있음. 발음이 불편하거나 부분교정만 가능한 경우가 있음"
              selectTherapy={selectOrthoType}
              deselectTherapy={deselectOrthoType}
              active={
                pickedOrthoType
                && (pickedOrthoType === 'ortho_unvisible_2d'
                  || pickedOrthoType === 'ortho_unvisible_clipyL'
                  || pickedOrthoType === 'ortho_unvisible_tiny')
              }
            >
              <SubOption
                id="ortho"
                name="2d 교정"
                optionName="ortho_unvisible_2d"
                onSubOptionChange={selectOrthoType}
                value={pickedOrthoType || null}
              >
                <SubOptionType name="교정력" rating={3} />
                <SubOptionType name="심미성" rating={5} />
                <SubOptionType name="가격" rating={4} />
              </SubOption>
              <SubOption
                id="ortho"
                name="Clipy-L"
                optionName="ortho_unvisible_clipyL"
                onSubOptionChange={selectOrthoType}
                value={pickedOrthoType || null}
              >
                <SubOptionType name="교정력" rating={4} />
                <SubOptionType name="심미성" rating={5} />
                <SubOptionType name="가격" rating={4} />
              </SubOption>
              <SubOption
                id="ortho"
                name="티끌 교정"
                optionName="ortho_unvisible_tiny"
                onSubOptionChange={selectOrthoType}
                value={pickedOrthoType || null}
              >
                <SubOptionType name="교정력" rating={2} />
                <SubOptionType name="심미성" rating={5} />
                <SubOptionType name="가격" rating={3} />
              </SubOption>
            </SelectTherapy>
            <SelectTherapy
              id="ortho"
              name="ortho_transparent_transparent"
              image={transpOrthoImg}
              unit={
                pickedOrthoType
                && (pickedOrthoType === 'ortho_transparent_transparent'
                  || pickedOrthoType === 'ortho_transparent_invisalign')
                  ? 1
                  : 0
              }
              therapyName="투명 교정"
              therapySubs="투명한 플라스틱소재 재료로 치열에 맞게 탈착 할수있는 교정. 치열이 심하게 틀어진 경우 교정 효과가 약하거나 제한될 수 있음"
              selectTherapy={selectOrthoType}
              deselectTherapy={deselectOrthoType}
              active={
                pickedOrthoType
                && (pickedOrthoType === 'ortho_transparent_transparent'
                  || pickedOrthoType === 'ortho_transparent_invisalign')
              }
            >
              <SubOption
                id="ortho"
                name="투명 교정"
                optionName="ortho_transparent_transparent"
                onSubOptionChange={selectOrthoType}
                value={pickedOrthoType || null}
              >
                <SubOptionType name="교정력" rating={3} />
                <SubOptionType name="심미성" rating={4} />
                <SubOptionType name="가격" rating={4} />
              </SubOption>
              <SubOption
                id="ortho"
                name="인비잘라인"
                optionName="ortho_transparent_invisalign"
                onSubOptionChange={selectOrthoType}
                value={pickedOrthoType || null}
              >
                <SubOptionType name="교정력" rating={4} />
                <SubOptionType name="심미성" rating={4} />
                <SubOptionType name="가격" rating={5} />
              </SubOption>
            </SelectTherapy>
            <InfoMessage>*1가지 필수로 선택해야 합니다.</InfoMessage>
          </TherapySection>
          <TherapySection>
            <TherapyTitle>
              희망하는 개선 부분은?
              <span>(중복선택가능)</span>
            </TherapyTitle>
            <PaddingDiv padding="10px 0">
              <CheckBoxContainer>
                {orthoWannaData.map((option, idx) => (
                  <CheckboxContainer key={`orthoOption_${idx}`}>
                    <CheckboxInput
                      onChange={handleOrthoWannaChange.bind(this, option.value)}
                      type="checkbox"
                      name=""
                      id={option.id}
                      value={option.value}
                      checked={orthoWanna.indexOf(option.value) > -1}
                    />
                    <CheckboxLabel htmlFor={option.id}>
                      {option.value}
                    </CheckboxLabel>
                  </CheckboxContainer>
                ))}
              </CheckBoxContainer>
            </PaddingDiv>
          </TherapySection>
          <TherapySection>
            <TherapySectionSubTitle
              subTitle={'ortho'}
              onClick={openOrthoPhotoGuideModal}
            />
            <Dropzones>
              {orthoImageFiles
                && orthoImageFiles.length > 0
                && orthoImageFiles.map((file, idx) => (
                  <PreviewImageContainer onClick={() => openOrthoImageFileDeleteModal(idx)} key={`orthoImage_${idx}`}>
                    <DeletePhotoBtn
                      src={DeletePhotoBtnImg}
                      alt="delete photo"
                    />
                    <PreviewImage src={file.preview} alt="preview" />
                  </PreviewImageContainer>
                ))}
              <StyledDropZone
                onDrop={onDrop}
                targetName="ortho"
                name="quick_counsel_ortho_dropzone"
              />
            </Dropzones>
          </TherapySection>
        </TherapyContainer>
      </OrthoDraftFormPanelContainer>
    </div>
  );
};

// 상담페이지 첫번째 스탭 왼쪽 영역
const ReqForm = (props) => {
  const {
    data: { desc },
    mode,
    handleChange,
    isSelectTxTab,
    goToTxPanel,
    goToOrthoPanel,
  } = props;

  return (
    <div>
      <form>
        <div>
          <TabContainer className="cartTop">
            <HeaderContainer>
              <FormSelectBtn clicked={isSelectTxTab} onClick={goToTxPanel}>
                치과치료
              </FormSelectBtn>
              <FormSelectBtn clicked={!isSelectTxTab} onClick={goToOrthoPanel}>
                교정치료
              </FormSelectBtn>
            </HeaderContainer>
            <Panel>
              {/* 간편상담 || 자세한상담 옵션 */}
              {mode === 'quick' ? (
                <QuickTherapyOptions {...props} />
              ) : (
                <DetailTherapyOptions {...props} />
              )}
              <TherapySection style={{ marginBottom: 0 }}>
                <TherapyTitle>의사 선생님께 하고 싶은 말</TherapyTitle>
                <SectionSubTitle>
                  앞서 치료한 이력이나 불편한 점, 요청사항 등을 자세하게 입력할수록 정확한 답변을 받을 수 있어요!
                </SectionSubTitle>
                <TextArea
                  placeholder="예) 금으로 씌운게 떨어졌어요.
오른쪽으로 씹을 때 통증이 있고 시려요.
앞니가 벌어졌어요."
                  name="desc"
                  id="DescTextArea"
                  value={desc}
                  onChange={handleChange}
                />
                <InfoText>
                  * 이용자님의 소중한 개인정보 보호를 위해 전화번호, 메신저 ID는 기재하지 말아주세요!
                </InfoText>
              </TherapySection>
            </Panel>
          </TabContainer>
        </div>
      </form>
    </div>
  );
};

export default withRouter(ReqForm);
