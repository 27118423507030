import React from 'react';
import styled from 'styled-components';
import { ReactComponent as BubbleAngleRightSvg } from '../../../../images/bubble-angle-right.svg';
import { ReactComponent as BubbleInnerAngleLeftSvg } from '../../../../images/odoctor-in.svg';


const BubbleAngle = styled(BubbleAngleRightSvg)``;
const BubbleAngleInner = styled(BubbleInnerAngleLeftSvg)``;

const BubbleContainer = styled.div`
  z-index: 2;
  display: inline-block;
  position: relative;
  height: auto;
  padding: 20px;
  background: ${props => (props.backgroundColor ? props.backgroundColor : '#033e52')};
  border-radius: 4px;
  font-size: 16px;
  text-align: left;
  color: #ffffff;
  max-width: 100%;
  &:after {
    content: '';
    clear: both;
  }

  ${BubbleAngle} {
    position: absolute;
    display: block;
    width: 12px;
    height: 18px;
    z-index: 1;
    left: unset;
    clear: both;
    top: 0px;
    path {
      fill: ${props => (props.backgroundColor ? props.backgroundColor : '#033e52')};
      mask: none;
    }
  }

  ${BubbleAngleInner} {
    position: absolute;
    display: none;
    width: 11px;
    height: 16px;
    z-index: 1;
    left: unset;
    clear: both;
    top: 1px;
    path {
      fill: ${props => (props.borderColor ? props.borderColor : '#033e52')};
      mask: none;
    }
  }

  /* 왼쪽 angle */
  &.left {
    border-top-left-radius: 0;
    ${BubbleAngle} {
      left: -12px;
      transform: rotateY(-180deg);
    }
    ${BubbleAngleInner} {
      left: -11px;
    }
  }

  /* 오른쪽 angle */
  &.right {
    border-top-right-radius: 0;
    ${BubbleAngle} {
      right: -12px;
    }
    ${BubbleAngleInner} {
      right: -11px;
      width: 12px;
      transform: rotateY(-180deg);
    }
  }

  /* border */
  &.border {
    border: solid 1px ${props => (props.borderColor ? props.borderColor : '#033e52')};
    ${BubbleAngle} {
      top: -1px;
      path {
        fill: ${props => (props.borderColor ? props.borderColor : '#033e52')};        
        mask: none;
      }
    }
    ${BubbleAngleInner} {
      display: block;
      top: 0;
      path {
        fill: ${props => (props.backgroundColor ? props.backgroundColor : '#033e52')};
        mask: none;
      }
    }
  }
`;


const SpeechBubble = ({
  children, side = 'left', backgroundColor, className, ...rest
}) => (
  <BubbleContainer className={`${className} ${side}`} backgroundColor={backgroundColor} {...rest}>
    {children || '내용을 넣어주세요'}
    <BubbleAngle />
    <BubbleAngleInner />
  </BubbleContainer>
);

export default SpeechBubble;
