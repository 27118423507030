import React from 'react';
import styled, { css } from 'styled-components';
import { Image } from '../Image';
import { Flex } from '../Flex';

const CssActiveBorder = css`
  border: 1px solid #033e52;
`;

const CssActiveFont = css`
  //font-weight: 600;
  font-weight: bold;
`;

const StyledImageButtonWrap = styled.div`
  width: 64%;
  min-width: 64px;
  border-radius: 50%;
  &>div{
    padding-top: 100%; // aspect ratio
  }
  border: 1px solid #e8e8e8;
  ${props => props.active && CssActiveBorder};
  ${props => props.active && CssActiveFont};
`;

const StyledTitle = styled.div`
  ${props => props.height && `height:${props.height}`};
  font-size: 13px;
  margin-top: 10px;
  text-align: center;
  word-break: keep-all;
  letter-spacing: -0.6px;
`;


const StyledButtonWrap = styled(Flex)`
  width: inherit;
  height: 56px;
  background-color: #fff;
  border-radius: 4px;
  border: solid 1px #e1e3e2;
  ${props => props.active && CssActiveBorder};
  ${props => props.active && CssActiveFont};
`;

const StyledTextButton = styled.div`
  width: 100%;
  text-align: center;
  font-size: 13px;
  letter-spacing: -.6px;
`;

const StyledCardButtonWrap = styled(Flex)`
  width: 100%;
  flex-direction: row;
  justify-content: left;
  border: 1px solid #e1e3e2;
  ${props => props.active && CssActiveBorder};
  ${props => props.active && CssActiveFont};
  border-radius: 4px;
  ${props => (props.description
    ? css`
   padding: 20px 0 20px 15px;
  `
    : css`
    padding: 10px 18px;
  `)};


  .text{
    letter-spacing: -0.6px;
    font-size: 13px;
    ${props => (props.description ? 'margin-left:10px' : 'margin-left:29px')}
  }
  .title{
    font-weight: bold;
  }
  .description{
    letter-spacing: -1px;
  }

  .image-box{
    ${props => (props.description ? css`
      width: 58px; height: 58px;
` : css`
      width: 36px; height: 36px;
`)}
  }

`;

const StyledRoundButtonWrap = styled(Flex)`
  background-color: #fff;
  border-radius: 50%;
  border: solid 1px #e1e3e2;
  ${props => props.active && CssActiveBorder};
  ${props => props.active && CssActiveFont};
  position: relative; // aspect ratio
  padding-top: 100%; // aspect ratio
  width: 100%;
  &> div { // to center an element vertically
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
`;


const StyledImageRoundButtonWrap = styled(Flex)`
  background-color: #fff;
  border-radius: 50%;
  border: solid 1px #e1e3e2;
  ${props => props.active && CssActiveBorder};
  ${props => props.active && CssActiveFont};
  width: 120px;
  max-width: 100%;
  background-image: url(${props => props.bg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: initial;
`;


const StyledImageRoundButton = styled.div`
  width: 100%;
  position: relative; // aspect ratio
  padding-top: 100%; // aspect ratio
  &> div { // to center an element vertically
    position: absolute;
    bottom: -30px;
    letter-spacing: -.6px;
  }
`;

const ReturnNewLine = text => (
  text.split('\n').map((t, i) => (
    <span key={i}>
      {t}
      <br />
    </span>
  ))
);

const CreateButton = (onClick, buttonType, title, description, image, active) => {
  const button = {
    imageButton: (() => (
      <>
        <StyledImageButtonWrap
          className={`type-image-button${active && 'active'}`}
          onClick={onClick}
          active={active}
        >
          <Image
            radius={50}
            imgUrl={image}
          >
            image
          </Image>
        </StyledImageButtonWrap>
        <StyledTitle height="40px">{ReturnNewLine(title)}</StyledTitle>
      </>
    ))(),
    textButton: (() => (
      <StyledButtonWrap
        className={`type-text-button ${active && 'active'}`}
        onClick={onClick}
        active={active}
      >
        <StyledTextButton>{title}</StyledTextButton>
      </StyledButtonWrap>
    ))(),
    cardButton: (() => (
      <StyledCardButtonWrap
        description={description}
        className={`type-card-button ${active && 'active'}`}
        onClick={onClick}
        active={active}
      >
        <div className="image-box">
          <Image
            radius={58}
            imgUrl={image}
          />
        </div>
        <div className="text">
          { description
            ? (
              <>
                <div className="title">{title}</div>
                <div className="description">{description}</div>
              </>
            )
            : (
              <>
                <div>{title}</div>
              </>
            )
          }

        </div>
      </StyledCardButtonWrap>
    ))(),
    roundButton: (() => (
      <StyledRoundButtonWrap
        className={`type-round-button ${active && 'active'}`}
        onClick={onClick}
        active={active}
      >
        <StyledTextButton>{title}</StyledTextButton>
      </StyledRoundButtonWrap>
    ))(),
    imageRoundButton: (() => (
      <StyledImageRoundButtonWrap
        className={`type-image-round-button ${active && 'active'}`}
        onClick={onClick}
        active={active}
        bg={image}
      >
        <StyledImageRoundButton>
          <StyledTextButton>{title}</StyledTextButton>
        </StyledImageRoundButton>
      </StyledImageRoundButtonWrap>
    ))(),
  };
  return button[buttonType];
};

const InputButton = ({
  onClick,
  buttonType,
  title,
  description,
  image,
  active,
}) => CreateButton(
  onClick,
  buttonType,
  title,
  description,
  image,
  active,
);

export default InputButton;
