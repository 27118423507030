import React from 'react';
import styled from 'styled-components';
import ImageGallery from 'react-image-gallery';
import Modal from 'react-modal';
import LeftArrow from '../images/ic-arrow-left-gallery.svg';
import CloseIcon from '../images/btn-big-close.svg';
import { media } from '../globalStyle';

const StyledModal = styled(Modal)``;

const BodyContainer = styled.div`
  width: 100%;
  max-width: 840px;
  height: 100%;
  max-height: 608px;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 44px 20px;
  transform: translate(-50%, -50%);
  .image-gallery {
    height: 100%;
    .image-gallery-content {
      height: 100%;
      .image-gallery-slide-wrapper {
        height: 100%;
        .image-gallery-swipe {
          height: 100%;
        }
        .image-gallery-slides {
          height: 100%;
          .image-gallery-slide {
            height: 100%;
          }
        }
        .image-gallery-index {
          top: -44px;
          width: 100%;
          height: 20px;
          background: none;
          text-align: center;
          span {
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.25;
            letter-spacing: normal;
            text-align: center;
            color: #999999;
          }
        }
      }
    }
  }

  ${media.tablet`
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    height: 100vh;
    transform: none;
    transform: unset;
    .image-gallery {
      position: absolute;
      width: 100%;
      height: 100vh;
      .image-gallery-content > .image-gallery-slide-wrapper {
        height: 100vh;
        .image-gallery-slides {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 50%;
          transform: translateY(-50%);
          .image-gallery-slide {
            height: 100%;
          }
        }
        .image-gallery-index {
          top: 0;
          width: 100%;
          height: 50px;
          background-color: rgba(0, 0, 0, 0.3);
          padding-top: 15px;
        }
      }
  `};
`;

const Photo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  vertical-align: middle;
  background-color: #000000;
  ${media.tablet`
    position: absolute;
    top: 50%;
    margin-top: -104px;
    height: 208px;
  `};
`;

const NavBtn = styled.button`
  width: 120px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  z-index: 102;
  img {
    position: absolute;
    top: 50%;
    margin-top: -30px;
    left: 0;
    z-index: 101;
    display: inline-block;
    vertical-align: middle;
    width: 60px;
    height: 60px;
    object-fit: contain;
  }
  ${props =>
    props.flip &&
    `
    img {
      transform: rotate(-180deg);
      left: unset;
      left: auto;
      right: 0;
    }
    right: 0;
    left: unset;
    left: auto;
  `};
  ${media.tablet`
    width: 50%;
    height: 208px;
    top: 50%;
    transform: translateY(-50%);
    img {
      width: 40px;
      height: 40px;
      margin-top: -20px;
    }
  `};
`;

const CloseBtn = styled.img`
  width: 30px;
  height: 30px;
  object-fit: contain;
  vertical-align: middle;
  position: absolute;
  right: 40px;
  top: 40px;
  cursor: pointer;
  z-index: 103;
  ${media.tablet`
    width: 20px;
    height: 20px;
    right: 15px;
    top: 15px;
  `};
`;

const PhotoContainer = styled.div`
  position: relative;
  height: 100%;
  ${media.tablet`
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
  `};
`;

const PhotoInfo = styled.div`
  position: absolute;
  z-index: 102;
  height: auto;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 16px 24px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff;
  bottom: 0;
  left: 0;
  width: 100%;
  ${media.tablet`
    top: unset;
    top: auto;
    bottom: 0;
  `};
`;

const PhotoTitle = styled.div`
  margin-bottom: 8px;
`;

const PhotoDesc = styled.div`
  font-size: 14px;
  line-height: 1.71;
  white-space: pre-wrap;
  word-break: break-all;
`;

class PhotoViewModal extends React.Component {
  renderLeftNav = (onClick, disabled) => (
    <NavBtn disabled={disabled} onClick={onClick}>
      <img src={LeftArrow} alt="병원사진" />
    </NavBtn>
  );

  renderRightNav = (onClick, disabled) => (
    <NavBtn flip disabled={disabled} onClick={onClick}>
      <img src={LeftArrow} alt="병원사진" />
    </NavBtn>
  );

  renderItem = item => (
    <PhotoContainer>
      <Photo src={item.original} alt="hospitalPhoto" />
      {(item.title || item.desc) && (
        <PhotoInfo>
          {item.title && <PhotoTitle>{item.title}</PhotoTitle>}
          {item.desc && <PhotoDesc>{item.desc}</PhotoDesc>}
        </PhotoInfo>
      )}
    </PhotoContainer>
  );

  render() {
    const { items, isOpen, close } = this.props;
    return (
      <StyledModal
        isOpen={isOpen}
        style={{
          overlay: {
            zIndex: 100,
            height: '100vh',
            width: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
          },
        }}
        ariaHideApp={false}
      >
        <CloseBtn src={CloseIcon} onClick={close} />
        <BodyContainer>
          <ImageGallery
            showIndex
            items={items}
            showThumbnails={false}
            showFullscreenButton={false}
            showPlayButton={false}
            renderItem={this.renderItem}
            renderLeftNav={this.renderLeftNav}
            renderRightNav={this.renderRightNav}
          />
        </BodyContainer>
      </StyledModal>
    );
  }
}

export default PhotoViewModal;
