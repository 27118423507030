import React from 'react';
import { graphql, compose } from 'react-apollo';
import styled from 'styled-components';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { caseQuery } from '../graphql/caseQuery';
import { media } from '../globalStyle';
import { Title } from '../components/atomic/atoms/Title/Title';
import { PrevButtonHeader } from '../components/atomic/organisms/header';
import RespSpeechBubble from '../components/HosRespList/RespSpeechBubble';
import { CaseResponseCard } from '../components/HosRespList/RespCard';
import { StretchButton } from '../components/ImporvedCounsel/EnhancedComponents';
import { getAgeGroupName } from '../utils/getAge';
import PhotoViewModal from '../components/PhotoViewModal';

const ExtendedTitle = styled(Title)`
  font-size: 26px;
  margin-bottom: 90px;
  margin-top: 30px;
  word-break: keep-all;
  >span{
    ${media.tablet`
      display: block
    `};
  }
`;

const HospitalReviewContainer = styled.div`
  margin: 0 auto;
  margin-bottom: 60px;
`;

const StyledWrap = styled.div`
  * {
    font-family: 'Saira Semi Condensed', sans-serif;
    letter-spacing: -.5px;
  }
  pre{
    overflow: hidden;
    line-height: 1.4;
    font-size: 14px;
    border: none;
    padding: 0;
    background: none;
    word-break: break-word;
    white-space: pre-wrap;   
  }
`;

const StyledCasePhoto = styled.div`
  width: 100%;
  height: 100%;
  background: url(${props => props.bg}) no-repeat center center;
  background-size: cover;
`;

class HospitalCaseDetail extends React.PureComponent {
  state = {
    isOpenPhotoView: false,
  };

  render() {
    const {
      reqCase,
      history,
      hid,
      from,
      queryString,
    } = this.props;
    const { isOpenPhotoView } = this.state;

    if (reqCase.loading) return null;

    const data = reqCase.reqCase;

    return (
      <StyledWrap style={{ letterSpacing: '-.5px' }}>
        {
            <PrevButtonHeader from={from} fixedUrl={`/case${queryString}`} />
        }
        <HospitalReviewContainer>
          <ExtendedTitle>
            {data.title}
            <div style={{ fontSize: '18px', marginTop: '30px' }}>
              {
                data.categories.map(c => (
                  // '#{c.name}' X
                  <>
                    {`#${c.name}`}
                    {' '}
                  </>
                ))
              }
            </div>
          </ExtendedTitle>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
            <RespSpeechBubble
              side="right"
              backgroundColor="#fff"
              textColor="#000"
              className="right border"
              borderColor="#e1e3e2"
              style={{ maxWidth: '430px' }}
            >
              <div
                className="user-info"
                style={{
                  color: '#b1b3b2', display: 'flex', justifyContent: 'space-between', marginBottom: '30px',
                }}
              >
                <div>
                  {data.location.siName}
                  {' '}
                  {data.location.gunguName}
                  {' '}
                  {getAgeGroupName(data.userAgeGroup)}
                  {' '}
                  {data.userGender === 'female' ? '여성' : '남성'}
                </div>
                <div className="view-case-btn">{moment(data.reqDate, 'YYYY-MM-DD').format('YYYY.MM.DD')}</div>
              </div>
              <div style={{
                textAlign: 'left', color: '#000', marginBottom: '20px', lineHeight: '22px',
              }}
              >
                <pre>{data.content}</pre>
              </div>
              <div>
                <ul style={{ display: 'flex', margin: 0 }}>
                  {
                    data.casePhotos.map(p => (
                      <li
                        style={{
                          width: '72px', height: '72px', overflow: 'hidden', marginRight: '4px', borderRadius: '3px',
                        }}
                        onClick={() => {
                          this.setState({
                            isOpenPhotoView: true,
                          });
                        }}
                      >
                        <StyledCasePhoto
                          bg={p.url}
                        />
                      </li>
                    ))
                  }
                </ul>
                <PhotoViewModal
                  items={data.casePhotos.map(p => ({
                    original: p.url,
                    // thumbnail: p.url,
                  }))}
                  isOpen={isOpenPhotoView}
                  close={() => {
                    this.setState({
                      isOpenPhotoView: false,
                    });
                  }
                  }
                />
              </div>
              {/* <StyledMyCounsel>
                {getWantLevelText(request?.getMyReq?.wantLevel)}
                <LinkedWord
                  className="my-counsel-btn"
                  onClick={() => {
                    history.push(`/myrequest/${request.getMyReq.id}`);
                    logEvent({
                      category: '답변(병원) 상세',
                      action: '클릭',
                      label: '상담정보 보러가기',
                    });
                  }}
                >
                  {'내 상담보기'}
                </LinkedWord>
              </StyledMyCounsel> */}
            </RespSpeechBubble>
          </div>
          <div>

            <CaseResponseCard
              response={data.resps[0]}
              responseRender={<pre>{data.resps[0].content}</pre>}
              onClick={() => {
                history.push(`/hospitals/${data.resps[0].hospital.id}`);
              }}
            />
            {/* <StyledMyCounsel>
                {getWantLevelText(request?.getMyReq?.wantLevel)}
                <LinkedWord
                  className="my-counsel-btn"
                  onClick={() => {
                    history.push(`/myrequest/${request.getMyReq.id}`);
                    logEvent({
                      category: '답변(병원) 상세',
                      action: '클릭',
                      label: '상담정보 보러가기',
                    });
                  }}
                >
                  {'내 상담보기'}
                </LinkedWord>
              </StyledMyCounsel> */}
          </div>

        </HospitalReviewContainer>
        <div style={{
          position: 'fixed', bottom: 0, left: 0, width: '100%', height: '64px', textAlign: 'center', zIndex: 2,
        }}
        >
          <StretchButton
            style={{ 'max-width': '760px' }}
            className="fs-14"
            fixed
            onClick={() => { window.location.href = '/'; }}
          >
            {'지금 바로 상담받기'}
          </StretchButton>
        </div>
      </StyledWrap>
    );
  }
}

export default compose(
  graphql(caseQuery, {
    name: 'reqCase',
    options: ({ id }) => ({
      variables: {
        id,
      },
    }),
  }),
)(withRouter(HospitalCaseDetail));
