import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
// import {
//   TagContainer,
//   Tag,
// } from './HtmlComponents';

export const HListItem = styled.div`
  position: relative;
  cursor: pointer;
  vertical-align: top;
  padding: 13px;
  border-radius: 3px;
  background: white;
  border: solid 1px white;
  @media (max-width: 576px) {
    width: 100%;
    max-width: unset;
    max-width: none;
  }
  ${props => props.focused && 'border-color: #34bcd7;'};
`;

const RightDescContainer = styled.div``;

const HospitalName = styled.div`
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 4px;
  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const HospitalAddress = styled.div`
  font-size: 12px;
  color: gray;
  max-height: 1em;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  @media (max-width: 600px) {
    font-size: 10px;
    margin-bottom: 3px;
  }
`;

class HospitalListItems extends Component {
  render() {
    const {
      onClick,
      name,
      address,
      // favorited,
      // hid,
      focused,
      // photos,
    } = this.props;
    return (
      <HListItem onClick={onClick} focused={focused}>
        {/* 병원 사진 넣고 싶으면 다음 주석을 풀면된다. */}
        {/* <ImageContainer>
          {(photos && photos.length > 0) ? (<HospitalImg src={photos[0].url} alt="병원 사진" />) : (<span></span>)}
        </ImageContainer> */}
        <RightDescContainer>
          <HospitalName>{name}</HospitalName>
          <HospitalAddress>{address}</HospitalAddress>
        </RightDescContainer>
      </HListItem>
    );
  }
}

export default withRouter(HospitalListItems);
