import React, { useState, useEffect, useRef } from 'react';
import { graphql, compose, Query } from 'react-apollo';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { getHospitalQuery } from "../graphql";
import { categoriesQuery, allCasesQuery } from '../graphql/caseQuery';
import { media } from '../globalStyle';
import { Title } from '../components/atomic/atoms/Title/Title';
import { ReactSelect } from '../components/atomic/atoms/Select/SelectBox';
import { SectionHr } from '../components/atomic/atoms/Shape/Bar';
import { getAgeGroupName } from '../utils/getAge';
import useIntersection from '../components/atomic/useIntersection';
import ScrollNav from '../components/atomic/organisms/horizonScrollNav';
import { PrevButtonHeader } from '../components/atomic/organisms/header';
import { InputRef } from '../components/atomic/atoms/Text/Input';
import { StretchButton } from "../components/atomic/molecules/ButtonGroup";
import { Section} from '../components/atomic/molecules/Section';

const StyledIconDelete = styled.div`
  cursor: pointer;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  margin-right: 10px;
  background: url('/icon/search-delete.svg') no-repeat center center;  
`;

const StyledSection = styled(Section)`
  margin-bottom: 20px;
`;

const ExtendedTitle = styled(Title)`
  font-size: 26px;
  margin-bottom: 20px;
  margin-top: 30px;
  >span{
    ${media.tablet`
      display: block
    `};
  }
`;

const ExtendedReactSelect = styled(ReactSelect)`
  font-size: 13px;
  letter-spacing: -.5px;
  margin-bottom: 90px;
  ${media.tablet`
    margin-bottom: 60px;
    width: 100%;
  `};
`;

const StyledSectionHr = styled(SectionHr)`
  margin: 20px 0;
`;

const HospitalReviewContainer = styled.div`
  margin: 0 auto;
  
  .scroll-nav {
    width: 100%;
    left: 0;
    position: absolute;
    top: 68px;
    
  }
`;

const StyledCaseCard = styled.div`
  letter-spacing: -.5px;
  font-size: 14px;
  font-family: 'Saira Semi Condensed', sans-serif;
  .bold{
    font-weight: bold;
  }
  .cat{
    color: #dd4124;
    margin-bottom: 20px;
  }
  .title{
    line-height: 1.4;
    font-size: 20px;
    margin-bottom: 10px;
  }
  .content{
    margin-bottom: 10px;
    line-height: 22px;
  }
  .user-info{
    color: #b1b3b2;
    .view-case-btn{
      padding: 20px;
      margin: -20px;
      cursor: pointer;
      color: #033e52;
      text-decoration: underline;
    }
  }
`;

const StyledPhotoWrap = styled.div`
  li{
    width: 34px;
    height: 34px;
    overflow: hidden;
    border-radius: 50%;
    background-color: #ddd;
    margin-right: 4px;
    img{
      width: 100%;
      height: 100%;
    }
  }
`;

const ExtendedStretchButton = styled(StretchButton)`
  font-size: 13px;
  letter-spacing: -.5px;
  height: 56px;
  border-radius: 4px;
  border: solid 1px #e1e3e2;
  color: #000;
  font-weight: normal;
  margin-top: 30px;
  background-color: #fff;
`;

const StyledSearchBox = styled.div`
  position: absolute;
  top: 10px;
  width: calc(100% - 84px); // prev 버튼의 넓이+오른쪽 마진 = 84px
  right: 20px;
  height: 36px;
  display: flex;
  .border{
    height: 36px;
    border-radius: 4px;
    padding-left: 35px;
    border: solid 1px #e1e3e2;
    display: flex;
    align-items: center;
    flex: 1 auto;
    background: url('/icon/search.svg') 10px center no-repeat ;
  }
  .input-wrap{
    input{
      width: 100%;
      height: 100%;
      border: none;
    }    
  }
  .place-holder{
    font-size: 13px;
    color: #b1b3b2;
  }
  .search-cancel-btn{
    font-size: 14px;
    flex-shrink: 0;
    margin: -5px -20px -5px -10px;
    padding: 5px 20px;
    background-color: transparent;
  }
`;

class SearchBox extends React.Component {
  static getDerivedStateFromProps(props, state) {
    if ( props.keyword !== state.inputKeyword) {
      return {
        inputKeyword: props.keyword,
      }
    }
  }

  state = {
    inputFocus: false,
    inputKeyword: '',
  };

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.setState({
        inputKeyword: this.searchText.value,
        inputFocus: false,
      });
      // filter
      this.props.onClick(this.searchText.value);
    }
  };

  resetSearchText = () => {
    this.searchText.value ='';
    this.searchText.focus();
    // this.setState({
    //   inputKeyword: null,
    // })
  };

  render() {
    const { inputFocus } = this.state;
    /* 만약 input의 auto focus가 안된다면 setTimeout(()=>{
      this.state.inputFocus && this.searchText.focus();
    }, 100) */
    return (
      <StyledSearchBox>
        <div className='border'>
          {
            this.state.inputFocus ?
            <div style={{width: '100%'}}>
              <div
                className={'input-wrap'}
              >
                <InputRef
                  autoFocus={inputFocus}
                  inputRef={el => this.searchText = el}
                  onKeyDown={this.handleKeyDown}
                  placeholder={this.state.inputKeyword}
                />
              </div>
            </div> : <div style={{width:'100%'}}
                onClick={()=>{
                  this.setState({
                    inputFocus: true,
                  })
                }}
              >
                {this.state.inputKeyword || <span className={'place-holder'}>궁금한 사례검색</span>}
              </div>
          }
          {
            this.state.inputFocus &&
            <StyledIconDelete
              onClick={this.resetSearchText}
            />
          }
        </div>
        {
          this.state.inputFocus
          &&
          <button
            className='search-cancel-btn'
            onClick={()=>{
              this.setState({
                inputFocus: false,
              })
            }}
          >
            {'취소'}
        </button>}
      </StyledSearchBox>
      )

  };
  // return (
  //
  // )
};

//<ScrollNav />
const CategoryWrapComponent = withRouter(({ onChange, keyword, filter, setKeyword, history }) => (
  <Query query={categoriesQuery}>
    {({ loading, error, data }) => {
      if (loading) return null;
      if (error) return `Error! ${error.message}`;

      const cat = data.therapyCategories;

      return (
        <>
          <PrevButtonHeader onClick={() => {
            if (!keyword && !filter ) {
              history.goBack();
            } else {
              setKeyword(null);
              onChange(null);
            }
          }} />
          {/* 검색 */}
          <SearchBox
            keyword={keyword}
            onClick={(word) => {
              // history.push('/case?search=hi')
              setKeyword(word);
            }}
          />

          <div className={'scroll-nav'}>
          <ScrollNav
            selected={filter}
            cat={[
              {id: 'all', name: '전체'},
              ...cat
            ]}
            onClick={(key) => {
              // filterCase(select.value);
              if(key ==='all') {
                onChange(null);
                return;
              }
              onChange(key);
            }}
          />
          </div>
        {/* <ExtendedReactSelect
          prefixText="상담항목: " // 선택시 선택 화면에서 프리픽스 됨
          isSearchable={false}
            // placeholder={'상담항목: 전체'}
          defaultValue={{ value: null, label: '전체' }}
            // defaultValue={defaultValue && { value: defaultValue.id, label:defaultValue.name }}
          options={[
            { value: null, label: '전체' },
            ...cat.map(c => ({ value: c.id, label: c.name })),
          ]}
          onChange={(select) => {
            // filterCase(select.value);
            onChange(select.value);
          }}
        /> */}
        </>
      );
    }}
  </Query>
));

const StyledNoCase = styled.div`
  color: #b1b3b2;
  text-align: left; 
  letter-spacing: -.5px;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 30px;
`;

const StyledScrollTop = styled.div`
  opacity: 0;
  //display: ${props => props.show ? 'block' : 'none'};
  display: block;
  position: fixed;
  right: 20px;
  bottom: 30px;
  ${props => props.show && 'opacity: 1; bottom: 40px;'}
  ${props => props.show && 'transition:opacity 0.5s, bottom 0.5s, transform 2s;'}
  transition-timing-function: ease-out;
  //-webkit-transition:width 2s, height 2s, background-color 2s, -webkit-transform 2s;

`;


export const CaseCard = withRouter(({ list, hidePhoto, history, disabledNestedButton }) => (
  <StyledCaseCard>
    <div className='case-card' style={{ marginBottom: '30px' }}>
      <div className="cat bold">
        {list.categories.map((c, idx) => (
          <React.Fragment key={idx}>
            {'#'}
            {c.name}
            {' '}
          </React.Fragment>
        ))}
      </div>
      <div className="title bold">{list.title}</div>
      <div className="content">{list.contentSummary}</div>
      {
          !hidePhoto
          && (
          <StyledPhotoWrap>
            <ul style={{ display: 'flex' }}>
              {
                list.casePhotos.map((p, idx) => (
                  <li key={idx}>
                    <img src={p.url} alt={p.url} />
                  </li>
                ))
              }
            </ul>
          </StyledPhotoWrap>
          )
        }

    </div>
    <div className="user-info" style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div>
        {list.location.siName}
        {' '}
        {list.location.gunguName}
        {' '}
        {getAgeGroupName(list.userAgeGroup)}
        {' '}
        {list.userGender === 'female' ? '여성' : '남성'}
      </div>
      <div
        className="view-case-btn"
        onClick={(e) => {
          if (disabledNestedButton) return;
          e.stopPropagation();
          history.push(`/case/${list.id}`);
        }}
      >
        {'병원답변 보기'}
      </div>
    </div>

  </StyledCaseCard>
));

export const CaseList = (
  {
    caseList,
    paging,
    loadMore,
    category,
    history,
    onLoadMore,
    moreButtonText,
    keyword,
    title,
    topBorderHide,
    hid, // hospital info 에서 넘어올때..
  }
) => {
  const currentPage = useRef(1);
  const totalPage = useRef(paging?.lastPage || 1);
  const targetRef = useRef(null);
  const scrollRef = useRef(null);
  const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(null);
  const [cases, setCases] = useState(caseList || []);
  const [isTopScroll, setIsTopScroll] = useState(false);

  useEffect(
    () => {
      // if (!root) return;
      setCases(caseList);
      if (caseList.length < 6 ) scrollRef.current = null;
      setLoading(false);
    }, [caseList, keyword],
  );

  useEffect(
    () => {
      currentPage.current = 1;
      setIsTopScroll(false);
    }, [category, keyword],
  );

  // infinite scrolling
  useIntersection({
    // root: null,
    target: targetRef,
    targetRef,
    onIntersect: ([{ isIntersecting }]) => {
      if(cases.length < 7) return;
      if (
        isIntersecting
        && !loading
        && currentPage.current < totalPage.current
      ) {
        setLoading(true);
        currentPage.current += 1;
        loadMore(currentPage.current, category);
      }
    },
  });

  useIntersection({
    // root: null,
    target: scrollRef,
    scrollRef,
    onIntersect: ([{ isIntersecting }]) => {
      if (
        isIntersecting
        && !loading
        && !isTopScroll // 다시 숨기고 싶을땐 이부분 제거
      ) {
        // setIsTopScroll(!isTopScroll)
        setIsTopScroll(true);
      }
    },
  });

  return (
    <>
      <StyledSection title={title}>
      { !topBorderHide && <StyledSectionHr />}
      {
        cases.map((list, idx) => (
          <div
            key={idx}
            onClick={() => {
              //
              history.push({
                pathname: `/case/${list.id}`,
                search: hid ? `?hid=${hid}` : '',
                state: {
                  from: history.location.pathname, // 현재 url을 같이 전달
                }
              });
            }}
          >
            <CaseCard
              disabledNestedButton={true}
              list={list}
            />
            { moreButtonText ? null : <StyledSectionHr /> }
            { // 스크롤 탑 버튼 노출&숨김
              idx === 6 && scrollRef
              && <div ref={scrollRef} />
            }
            {
              cases.length - 1 === idx && targetRef
              && <div ref={targetRef} />
            }
          </div>
        ))
      }

      {
        moreButtonText &&
        <ExtendedStretchButton
          outline
          onClick={onLoadMore}
        >
          {moreButtonText}
        </ExtendedStretchButton>
      }

        <StyledScrollTop
          show={isTopScroll}
          onClick={() => {
            window.scrollTo(0, 0)
            // setIsTopScroll(!isTopScroll)
          }}
        >
          <svg width="62px" height="62px" viewBox="0 0 62 62" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
              <circle id="path-1" cx="25" cy="25" r="25"></circle>
              <filter x="-20.0%" y="-16.0%" width="140.0%" height="140.0%" filterUnits="objectBoundingBox" id="filter-2">
                <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                <feGaussianBlur stdDeviation="3" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
              </filter>
            </defs>
            <g id="04_recent" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="user-/-04_recent-/-1_list" transform="translate(-284.000000, -1223.000000)">
                <g id="btn-/-top_scroll" transform="translate(290.000000, 1227.000000)">
                  <g id="Group-2">
                    <g id="Oval-Copy">
                      <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlinkHref="#path-1"></use>
                      <use fill="#FFFFFF" fill-rule="evenodd" xlinkHref="#path-1"></use>
                    </g>
                    <path d="M18.75,16.25 L21.25,16.25 L21.2490322,26.2497873 L31.25,26.25 L31.25,28.75 L21.25,28.75 L18.75,28.75 L18.75,16.25 Z" id="btn-/-drop_down-copy" fill="#BBBCBC" transform="translate(25.000000, 22.500000) rotate(-225.000000) translate(-25.000000, -22.500000) "></path>
                    <rect id="Rectangle-Copy" fill="#BBBCBC" x="23.75" y="16.25" width="2.5" height="20"></rect>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </StyledScrollTop>
      </StyledSection>
    </>
  );
};

export default ({ hid }) => {
  const [filter, setFilter] = useState(null);
  const [keyword, setKeyword] = useState(null);

  return (
    <div>
      {/* {isMobile && <PrevButtonHeader />} */}
      <HospitalReviewContainer>
        {/* <ExtendedTitle>
          {'최근 오닥터를 통해'}
          <br />
          {'상담받은 사례를 확인해보세요'}
        </ExtendedTitle> */}
        <CategoryWrapComponent// 필터사용 X -> rerendering 방지
          // filterCase={filterCase}
          filter={filter}
          keyword={keyword}
          setKeyword={(word) => { setKeyword(word) }}
          onChange={(value) => { setFilter(value); }}
        />
        <Haa
          keyword={keyword}
          category={filter}
          hid={hid}
        />
      </HospitalReviewContainer>
    </div>
  );
};

// for hospital.name
const HospitalName = ({ hid }) => {
  return (
    <Query query={getHospitalQuery} variables={{ id: hid }}>
      {({ loading, error, data }) => {
        if (loading) return null;
        if (error) return `Error! ${error.message}`;
        const hospital = data.getHospital;
        return (
          <>{hospital.name}</>
        )
      }}
    </Query>
  )
};

const HospitalCase = (props) => {
  const {
    loading,
    loadMore,
    res,
    category,
    keyword,
    history,
    hid,
  } = props;
  if (!res?.data && loading) return null;

  const paging = res.pagination;
  const caseList = res.data;

  return (
    <div style={{'padding-top': '60px'}}>
      <ExtendedTitle>
        { hid && <><HospitalName hid={hid} /><br /></>}
        {`상담사례 ${paging.total}개`}
      </ExtendedTitle>
      {
          caseList.length === 0
            ?
            <>
            <StyledNoCase>
                {'아쉽지만 아직 상담사례가 없어요 ㅠㅠ'}
                <br />
                {'지금 바로 상담을 통해 병원으 답변을 받아보세요'}
              </StyledNoCase>
              <ExtendedStretchButton
                onClick={() => {
                  history.push('/');
                }}
              >
                {'상담 받기'}
              </ExtendedStretchButton>
            </>
            : (
              <CaseList
                caseList={caseList}
                loadMore={loadMore}
                paging={paging}
                keyword={keyword}
                category={category}
                history={history}
              />
            )
        }
    </div>
  );
};

// export default compose(
const Haa = compose(
  graphql(allCasesQuery, {
    name: 'cases',
    options: ({ hid, keyword, category, currentPage }) => ({
      variables: {
        therapyCategoryId: category,
        keyword: keyword,
        hospitalId: hid,
        perPage: 10,
        currentPage,
      },
    }),
    props: ({ cases: { loading, reqCases, fetchMore } }) => (
      {
        a: 1,
        loading,
        res: reqCases,
        loadMore(page, catId, keyword) {
          return fetchMore({
            variables: {
              currentPage: page,
              therapyCategoryId: catId,
              keyword: keyword,
              perPage: 10,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) {
                return previousResult;
              }
              fetchMoreResult.reqCases.data = [
                ...previousResult.reqCases.data,
                ...fetchMoreResult.reqCases.data,
              ];
              return Object.assign({}, previousResult, {
                reqCases: fetchMoreResult.reqCases,
              });
            },
          });
        },
        filterCase(catId, keyword, page) {
          return fetchMore({
            variables: {
              therapyCategoryId: catId,
              keyword: keyword,
              perPage: 10,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => fetchMoreResult || previousResult,
          });
        },
      }
    ),
  }),
  // graphql(HospitalReviewsQuery, {
  //   name: 'jjk',
  //   options: ({hid}) => ({
  //     variables: {
  //       hospitalId: hid,
  //       perPage: 5,
  //       currentPage: 1,
  //     },
  //   }),
  // })
)(withRouter(HospitalCase));
