import React, { useState } from 'react';
import styled from 'styled-components';
import { compose, graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import withAlertModal from '../../utils/alerthoc';
import { AuthContext } from '../AuthProvider';
import { Flex } from '../atomic/atoms/Flex';
import TimerComponent from './TimerComponent';
import {
  isExistingUserMutation,
  loginUserByPhoneMutation,
  phoneNumberConfirmMutation,
  phonePinConfirmMutation, registerUserByPhoneMutation,
} from '../../graphql';
import withToast from '../../utils/toasthoc';
import withAuthModal from '../../utils/authmodalhoc';
import { setSheet } from '../../utils/loging';

const StyledPhoneInputContainer = styled(Flex)`
  flex-wrap: nowrap;
  margin-bottom: 10px;
`;

const StyledPhoneInputWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  border-radius: 4px;
  border: solid 1px #e1e3e2;
  & + button {
    font-size: 13px;
    color: #fff;
    min-width: 100px;
    margin-left: 5px;
    height: 56px;
    border-radius: 4px;
    background-color: #033e52;
    &:disabled{
      background-color: #e1e3e2;    
    }
    &.re-send-pin{
      border: solid 1.5px #033e52;
      background-color: #ffffff;
      color: #000;
    }
  }
`;

const StyledPinInputWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  border-radius: 4px;
  border: solid 1px #e1e3e2;
  &.time-out, &.error{
    border-color: #dd4124;  
  }
  & + button {
    font-size: 13px;
    min-width: 100px;
    margin-left: 5px;
    color: #fff;
    width: 100px;
    height: 56px;
    border-radius: 4px;
    background-color: #033e52;
    &:disabled{
      background-color: #e1e3e2;    
    }
  }
`;

const StyledTimerWrap = styled.div`
  position: absolute;
  right: 10px;
  color: rgb(221, 65, 36);
  font-size: 13px;
  font-family: 'Saira Semi Condensed';
`;

const StyledPhoneErrorMsg = styled.div`
  font-size: 12px;
  letter-spacing: -.6px;
  line-height: 1.2;
  color: rgb(221, 65, 36);
`;

const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  padding:0 14px;
  border: 0;
  outline: 0;
  font-size: 18px;
  letter-spacing: -.6px;
  &.fs-13{
    font-family: 'Saira Semi Condensed';
    font-size: 13px;
    font-weight: normal;
    font-stretch: semi-condensed;
    font-style: normal;
    letter-spacing: normal;
    color: #000000;
  }
`;
let pinId = null;

const PhoneComponent = ({
  onClick, phonePinConfirm, phoneNumberConfirm, isExistingUser, openAlert, toast,
}) => {
  const savedData = JSON.parse(localStorage.getItem('pData'));
  const [phoneNum, setPhoneNum] = useState(savedData.phone);
  const [pinNum, setPinNum] = useState(null);
  const [resetCnt, setResetCnt] = useState(0);
  // const pinNum = useRef(null);
  const [isWaitPin, setIsWaitPin] = useState(false);
  const [isPinTimeOut, setIsPinTimeOut] = useState(false);
  const [isPinError, setIsPinError] = useState(false);

  /* const isExistingUser = () => {
    return isExistingUser({
      variables: {
        phonenum,
      },
    });
  }; */

  const certifyPhone = async () => {
    // 1 핸드폰 번호 중복 검사
    // 2 오닥터 파트너 병원 검사
    // 3 인증 번호 발송

    const phoneDuplimatedRes = await isExistingUser({
      variables: {
        phonenum: phoneNum,
      },
    });

    // isExistingUser 노드 유무
    if (!Object.prototype.hasOwnProperty.call(phoneDuplimatedRes.data, 'isExistingUser')) {
      throw new Error(phoneDuplimatedRes.data.errors);
    }

    const {
      isExistingUser: { ok, error },
    } = phoneDuplimatedRes.data;

    // 핸드폰 번호 중복 (이미 등록된 회원)
    // 비밀번호 입력해서 로그인하는 페이지
    if (ok) {
      // update step memberPassword
      // move to memberPassword
      return 'member';
    }

    // 파트너로 등록된 병원
    if (!ok && error && error.message && error.message === 'isHuser') {
      openAlert({
        title: '오닥터 파트너로 가입되어 있어요!',
        message: (
          <div>
            오닥터 파트너병원으로 가입된 계정입니다.
            파트너병원 페이지로 이동합니다.
          </div>
        ),
        showConfirmBtn: true,
        confirmBtnName: '확인',
        textAlign: 'left',
        confirmBtnOnClick: () => {
          window.location = 'https://hospital.odoctor.co.kr';
        },
      });
      return 'partner';
    }


    // 인증 sms 발송 API
    const ConfirmSmsRes = await phoneNumberConfirm({
      variables: {
        phonenum: phoneNum,
      },
    });

    setSheet({
      name: '인증번호',
      step: 'p',
      isTx: savedData.txItems.length - !!savedData.txItems.includes('ORT'),
      isOrtho: !!savedData.txItems.includes('ORT'),
      isDetail: !!savedData.isDetail,
      data: 'pinNum',
    });

    if (
      ConfirmSmsRes.data.phoneNumberConfirm
      && ConfirmSmsRes.data.phoneNumberConfirm.ok
      && ConfirmSmsRes.data.phoneNumberConfirm.id
    ) {
      // toast 알림
      pinId = ConfirmSmsRes.data.phoneNumberConfirm.id;
      toast(
        <div>입력하신 휴대폰 번호로 인증번호를 발송했습니다.</div>,
        'info',
      );

      return 'pin';
    }
    return false;
  };

  const confirmPinNum = async () => {
    // sms 확인 API
    const res = await phonePinConfirm({
      variables: {
        id: pinId,
        pin: pinNum,
      },
    });

    return res.data.phonePinConfirm && res.data.phonePinConfirm.ok;
  };

  return (
    <div>
      <StyledPhoneInputContainer>
        {/* 번호 입력 */}
        <StyledPhoneInputWrap>
          <StyledInput
            type="tel"
            value={phoneNum}
            maxLength={11}
            className="fs-13"
            placeholder="휴대폰 번호 (-없이 입력)"
            onChange={(e) => {
              setPhoneNum(e.target.value);
            }}
          />
        </StyledPhoneInputWrap>
        <button
          type="button"
          className={`${isWaitPin && 're-send-pin'}`}
          disabled={!(phoneNum && phoneNum.length > 10)}
          onClick={() => {
            // 해당 번호로 인증번호 발송
            const res = certifyPhone();
            res.then(r => r)
              .then((result) => {
                // 인증번호 발송시 인증번호 활성화
                switch (result) {
                  case 'member':
                    // 기존 가입한 전화번호
                    // 회원 비밀번호 입력으로 넘어감
                    onClick({ value: phoneNum, isExistUser: true });
                    break;
                  case 'pin':
                    // 미가입자 인증번호 발송 됨
                    // 인증번호 입력 활성화
                    setIsWaitPin(1);
                    break;
                  default:
                    break;
                }
              });

            // 인증 번호 발송후 flag = true
            // 인증번호 타임아웃 되었을때 재전송을 누르면,
            // 타이머 리셋, 리셋 카운트 1증가
            if (isPinTimeOut) {
              setResetCnt(resetCnt + 1);
              setIsPinTimeOut(false);
            }
          }}
        >
          {isWaitPin ? '재전송' : '확인'}
        </button>

      </StyledPhoneInputContainer>

      { isWaitPin
      && (
      <StyledPhoneInputContainer>
        {/* 인증 번호 입력 */}
        <StyledPinInputWrap
          className={`${isPinTimeOut && 'time-out'}${isPinError && 'error'}`}
        >
          <StyledInput
            type="tel"
            maxLength={4}
            className="fs-13"
            placeholder="인증번호 4자리"
            onChange={(e) => {
              setPinNum(e.target.value);
            }}
          />
          <StyledTimerWrap>
            <TimerComponent
              timerReset={resetCnt} // reset cnt를 변경해서..
              onTimeOut={() => {
                setIsPinTimeOut(true);
              }}
            />
          </StyledTimerWrap>

        </StyledPinInputWrap>
        <button
          type="button"
          disabled={!(pinNum && pinNum.length === 4) || isPinTimeOut}
          onClick={() => {
            const res = confirmPinNum(pinNum);
            res.then(r => r)
              .then((result) => {
                if (result) {
                  // 뉴비 프로세스
                  // 회원가입 후 상담 등록을 위해
                  // 뉴비 비밀번호 입력 페이지로 이동
                  isPinError && setIsPinError(false);
                  onClick({ value: phoneNum, isExistUser: false });
                } else {
                  // 인증번호가 일치 하지 않음 에러
                  setIsPinError(true);
                }
              });
          }}
        >
          확인
        </button>

      </StyledPhoneInputContainer>
      )

      }

      {
        isPinTimeOut
        && (
        <StyledPhoneErrorMsg>
입력시간을 초과하였습니다.
          <br />
인증번호 재전송 후 다시 시도하세요.
        </StyledPhoneErrorMsg>
        )
      }
      {
        isPinError
        && <StyledPhoneErrorMsg>인증번호가 일치하지 않습니다.</StyledPhoneErrorMsg>
      }


    </div>

  );
};

PhoneComponent.contextType = AuthContext;
// export default PhoneComponent;
const CounselUserInfoFormWithFunc = compose(
  graphql(isExistingUserMutation, {
    name: 'isExistingUser',
  }),
  graphql(phoneNumberConfirmMutation, {
    name: 'phoneNumberConfirm',
  }),
  graphql(phonePinConfirmMutation, {
    name: 'phonePinConfirm',
  }),
  graphql(loginUserByPhoneMutation, {
    name: 'loginUserByPhone',
  }),
  graphql(registerUserByPhoneMutation, {
    name: 'registerUserByPhone',
  }),
)(withAlertModal(withRouter(withToast(withAuthModal(PhoneComponent)))));
export default CounselUserInfoFormWithFunc;
