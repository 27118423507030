import React, { useState } from 'react';
import styled from 'styled-components';
import { media } from '../globalStyle';
import { StyledModal } from './basic/HtmlComponents';
import ModalCloseIcon from '../images/ic-close-24.svg';
import ArrowImg from '../images/ic-more-gray-24.png';
import CheckboxInput from "./form/CheckboxInput";

const CheckAnswerConfirmModal = styled(StyledModal)`
  height: auto;
  padding: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  border: 0;
  border-radius: 0;
  width: 380px;
  ${media.tablet`
    width: 100%;
    bottom: 0;
    transform: none;
    top: unset;
    top: auto;
    left: 0;
    padding: 0;
    display: flex;
    flex: 1;
    flex-direction: column;
    border: 0;
    border-radius: 0;
  `};
`;

const ModalHeader = styled.div`
  padding: 20px 20px 0;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
  margin-bottom: 10px;
  ${media.tablet`
    margin-bottom: 0;
    padding: 20px;
    font-size: 16px;
    color: #033e52;
  `}
`;

const ModalCloseBtn = styled.button`
  border: 0;
  padding: 0;
  float: right;
  vertical-align: middle;
  img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    cursor: pointer;
  }
  ${media.tablet`
    img {
      width: 20px;
      height: 20px;
    }
  `};
`;

const ModalContent = styled.div`
  padding: 4px 20px 30px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.6px;
  color: #000000;
  word-break: keep-all;
  height: 100%;
  position: relative;
  ${media.tablet`
    line-height: 0.86;
    letter-spacing: -0.8px;
    padding: 20px;
    min-height: 296px;
  `};
`;

const Form = styled.div`
  margin-top: 40px;
  div ~ div {
    margin-top: 30px;
  }
  select {
    margin-top: 10px;
    display: block;
    width: 100%;
    height: 48px;
    border-radius: 1px;
    border: solid 1px #e0e0e0;
    background-color: #ffffff;
    padding: 14px 8px;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.8px;
    color: #000000;
    background-image: url(${ArrowImg});
    background-position-x: 96%;
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    ::-ms-expand {
      display: none;
    }
    &:active, &:focus {
      outline: none;
    }
  }
  textarea {
    margin-top: 10px;
    width: 100%;
    height: 88px;
    border-radius: 1px;
    border: solid 1px #e0e0e0;
    background-color: #ffffff;
    resize: none;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.8px;
    color: #000000;
    padding: 10px 8px;
  }
  ${media.tablet`
    div ~ div {
      margin-top: 20px;
    }
  `};
`;

const ModalBtn = styled.button`
  bottom: 0;
  width: 100%;
  min-height: 52px;
  padding: 14px 0;
  background-color: #033e52;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  text-align: center;
  color: #ffffff;
  ${media.tablet`
    min-height: 64px;
    padding: 20px 0;
    line-height: 1.5;
    letter-spacing: -0.9px;
  `};
  :disabled{
    background-color: #bbbcbc;
  }
`;

export default ({
  isOpen, onClose, onSubmit, onConfirm, setCookie, hospitalName,
}) => {
  const [checkedAgree, setCheckedAgree] = useState(true);

  return (
    <CheckAnswerConfirmModal
      style={{
        overlay: {
          width: '100%',
          zIndex: 100,
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.85)',
          overflow: 'auto',
        },
      }}
      ariaHideApp={false}
      isOpen={isOpen}
      shouldCloseOnOverlayClick
      onRequestClose={onClose}
    >
      <ModalHeader>
        병원 답변 확인하기
        <ModalCloseBtn onClick={onClose}>
          <img src={ModalCloseIcon} alt="ModalCloseIcon" />
        </ModalCloseBtn>
      </ModalHeader>
      <ModalContent>
        <div>
          {hospitalName}에서 치료에 대한 더 자세한 안내를 위헤 연락을 드립니다. 궁금한 점이나 요구사항을 이야기할 수 있는 기회이니 놓치지 말고 받아주세요!
        </div>
        <Form>
          <CheckboxInput
            type="checkbox"
            id="agreeTerms"
            name="agreeTerms"
            checked={checkedAgree}
            onChange={()=>{setCheckedAgree(!checkedAgree)}}
            labelText="일주일 동안 묻지 않기"
          />
        </Form>
      </ModalContent>
      <ModalBtn
        onClick={() => {
          checkedAgree && setCookie();
          onClose();
          onConfirm();
        }}
      >
        답변 확인하기
      </ModalBtn>
    </CheckAnswerConfirmModal>
  );
};
