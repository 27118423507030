import React from 'react';
import styled from 'styled-components';
import CloseImg from '../../images/ic-close-24.svg';
import { StyledModal, PaddingDiv } from '../basic/HtmlComponents';
import { media } from '../../globalStyle';

const AlertTitle = styled.h5`
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: -1px;
  color: #000000;
  word-wrap: break-word;
  margin: 0 0 30px 0;
  @media (max-width: 576px) {
    font-size: 18px;
  }
`;

const AlertContent = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.64;
  letter-spacing: normal;
  color: #000000;
  @media (max-width: 576px) {
    font-size: 12px;
  }
`;

const CloseBtn = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
  top: 42px;
  right: 30px;
  position: absolute;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  ${media.tablet`
    top: 32px;
    right: 20px;
  `};
`;
const Subtitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: -0.9px;
  color: #033e52;
  margin-bottom: 2px;
`;

const Desc = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.64;
  letter-spacing: normal;
  color: #000000;
`;

export const TxPhotoGuideModal = ({ isOpen, onClose }) => (
  <StyledModal
    style={{
      overlay: {
        zIndex: 100,
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
      },
    }}
    ariaHideApp={false}
    isOpen={isOpen}
  >
    <CloseBtn src={CloseImg} alt="closeImg" onClick={onClose} />
    <div>
      <AlertTitle>사진등록 가이드</AlertTitle>
    </div>
    <AlertContent>
      <div>
        <Subtitle>이전 치료 이력이 있는 경우</Subtitle>
        <Desc>
          이전 치료 이력이 있는 경우, 치과에서 치료 시의 진료기록과 치료를 위해
          찍은 X-ray 사진을 요청할 수 있습니다. 치료를 받은 치과에 문의하신 후,
          해당 내용을 확인하세요
        </Desc>
      </div>
      <PaddingDiv padding="14px 0 0" />
      <div>
        <Subtitle>이전 치료 이력이 없는 경우</Subtitle>
        <Desc>
          이전 치료 이력이 없는 경우, 고객님이 직접 촬영하신 후 등록할 수
          있습니다.
        </Desc>
      </div>
    </AlertContent>
  </StyledModal>
);

export const OrthoPhotoGuideModal = ({ isOpen, onClose }) => (
  <StyledModal
    style={{
      overlay: {
        zIndex: 100,
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
      },
    }}
    ariaHideApp={false}
    isOpen={isOpen}
  >
    <CloseBtn src={CloseImg} alt="closeImg" onClick={onClose} />
    <div>
      <AlertTitle>사진등록 가이드</AlertTitle>
    </div>
    <AlertContent>
      <div>
        <Subtitle>공통</Subtitle>
        <Desc>
          치아를 문 상태에서 치열이 잘 보이는 사진을 등록해주세요.
        </Desc>
      </div>
      <PaddingDiv padding="14px 0 0" />
      <div>
        <Subtitle>정면</Subtitle>
        <Desc>
          치아를 문 상태에서 치열이 잘 보이는 사진을 등록해주세요.
        </Desc>
      </div>
      <PaddingDiv padding="14px 0 0" />
      <div>
        <Subtitle>치아정면</Subtitle>
        <Desc>
          치아를 문 상태에서 치아가 최대한 많이 보이게 <b>정면에서</b> 치열을 촬영해 주세요.
        </Desc>
      </div>
      <PaddingDiv padding="14px 0 0" />
      <div>
        <Subtitle>치아좌측</Subtitle>
        <Desc>
          치아를 문 상태에서 송곳니가 보이도록 <b>좌측치열</b>을 촬영해 주세요.
        </Desc>
      </div>
      <PaddingDiv padding="14px 0 0" />
      <div>
        <Subtitle>치아우측</Subtitle>
        <Desc>
          치아를 문 상태에서 송곳니가 보이도록 <b>우측치열</b>을 촬영해 주세요.
        </Desc>
      </div>
    </AlertContent>
  </StyledModal>
);

const PhotoGuideModal = () => null;

export default PhotoGuideModal;
