import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Hr } from '../basic/HtmlComponents';
import { getTxTherapyNamesByTxItems } from '../../utils/req-utils';
import { ReqData, SettingData } from '../UserRequest';
import { media } from '../../globalStyle';
import { logEvent } from '../../utils/loging';

const Header = styled.div`
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: -1px;
  color: #033e52;
  height: 64px;
  padding: 17px 20px;
`;

const DetailViewBtn = styled.span`
  text-decoration: underline;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: -0.8px;
  text-align: right;
  color: #000000;
  float: right;
  display: block;
  margin-top: 4px;
  height: 24px;
  cursor: pointer;
`;

const GrayBorder = styled.div`
  width: 100%;
  overflow: hidden;
  background: white;
  border: solid 1px #e4e4e4;
  box-sizing: border-box;
  height: 100%;
  position: relative;
  max-height: ${props => `${props.fixedCartMaxHeight}`};
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  &.fixed {
    position: fixed;
    top: 0;
  }
`;

const Sections = styled.div`
  padding: 20px;
`;

const CardView = styled.div`
  display: block;
  ${media.miniDesktop`
    display: none;
  `};
`;

/**
 * describe /userreq/95/hid/2 에서 우측 영역
 */
class MyCounselInfo extends Component {
  render() {
    const {
      txItems,
      orthoItems,
      desc,
      reqType,
      txMaxPrice,
      orthoMaxPrice,
      id,
      directReq,
      wantLevel
    } = this.props.userreq;

    const { implant, cavity, aesthetic, general, gum, facial } = getTxTherapyNamesByTxItems(txItems);

    return (
      <div>
        <CardView>
          <GrayBorder>
            <Header>
              <FormattedMessage id="myCsInfo" />
              <DetailViewBtn
                onClick={() => {
                  this.props.history.push(`/myrequest/${id}`);
                  logEvent({
                    category: '답변(병원) 상세',
                    action: '클릭',
                    label: '상담정보 보러가기',
                  });
                }}
              >
                자세히보기
              </DetailViewBtn>
            </Header>
            <Hr color="#e4e4e4" />
            <Sections>
              <ReqData
                txItems={txItems}
                cavity={cavity}
                implant={implant}
                aesthetic={aesthetic}
                general={general}
                gum={gum}
                facial={facial}
                orthoItems={orthoItems}
                desc={desc}
              />
              <SettingData
                directReq={directReq}
                reqType={reqType}
                txMaxPrice={txMaxPrice}
                orthoMaxPrice={orthoMaxPrice}
                wantLevel={wantLevel}
              />
            </Sections>
          </GrayBorder>
        </CardView>
      </div>
    );
  }
}

export default withRouter(MyCounselInfo);
