import React, { Component } from 'react';
import { Query } from 'react-apollo';
import styled from 'styled-components';
import { userRequestQuery } from '../graphql';
import { getTxTherapyNamesByTxItems } from '../utils/req-utils';
import ReqDoneDetail from '../components/ReqDone/ReqDoneDetail';
import { media } from '../globalStyle';


const Wrapper = styled.div`
  padding: 40px 20px 60px;
  position: relative;
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  ${media.tablet`
    min-width: 320px;
    padding: 78px 0px 0;
  `};
`;

const ConfirmWrapper = styled.div`
  width: 100%;
  background-color: white;
  max-width: 1080px;
  margin: 89px auto 0;
  ${media.tablet`
    padding: 0 20px 60px;
    margin-top: 40px;
  `};
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 60px;
  ${media.tablet`
    margin-top: 45px;
  `};
`;

const RoundBtn = styled.button`
  cursor: pointer;
  display: inline-block;
  width: 320px;
  height: 64px;
  background-color: #033e52;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: -1px;
  text-align: center;
  color: #ffffff;
  ${media.tablet`
    width: 100%;
    height: 52px;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.9px;
  `};
`;

const InnerWrapper = styled.div`
  margin: 0 auto;
  max-width: 640px;
`;

const H3 = styled.h3`
  font-size: 36px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.89;
  letter-spacing: -2px;
  color: #033e52;
`;

const Header = styled.h1`
  font-size: 34px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  ${media.tablet`
    font-size: 24px;
    letter-spacing: -1.7px;
    margin: 0 20px 10px;
  `};
`;

class MyRequest extends Component {
  render() {
    const reqId = this.props.match.params.id;

    return (
      <React.Fragment>
        <Query query={userRequestQuery} variables={{ reqId }}>
          {({ loading, error, data }) => {
            if (loading) return null;
            if (error) return `Error! ${error.message}`;

            const { implant, cavity, aesthetic, general, gum, facial } = getTxTherapyNamesByTxItems(data.getMyReq.txItems);

            return (
              <Wrapper>
                <div>
                  <Header>
                    나의 상담정보
                  </Header>
                </div>
                <ConfirmWrapper>
                  <InnerWrapper>
                    <H3>접수된 상담내역</H3>
                    <ReqDoneDetail getMyReq={data.getMyReq} implant={implant} cavity={cavity} aesthetic={aesthetic} general={general} gum={gum} facial={facial} />
                  </InnerWrapper>
                  <ButtonContainer>
                    <RoundBtn
                      onClick={() =>
                        this.props.history.push({
                          pathname: `/userreq/${data.getMyReq.id}`,
                          state: {
                            isDirect: !!data.getMyReq.directReq,
                          },
                        })
                      }
                    >
                      상담결과보기
                    </RoundBtn>
                  </ButtonContainer>
                </ConfirmWrapper>
              </Wrapper>
            );
          }}
        </Query>
      </React.Fragment>
    );
  }
}

export default MyRequest;
