const activeHospitalList = {
  // 송파
  // 22: [
  //   {
  //     id: 21160,
  //     name: '굿플란트 치과의원',
  //     comment: '6개과, 8인의 전문의가 대학병원처럼 협진하는 치과',
  //   },
  //   {
  //     id: 21158,
  //     name: '케어유 치과의원',
  //     comment: '친절함은 물론 무려 25년의 내공을 자랑하는 원장님',
  //   },
  //   {
  //     id: 21166,
  //     name: '연세굿플란트 치과의원',
  //     comment: '분야별 4명의 원장님이 긴밀하게 협진하는 치과',
  //   },
  //   {
  //     id: 21148,
  //     name: '케이 치과 병원',
  //     comment: '3대를 이어온 90년 전통 치과의사 집안의 자존심',
  //   },
  // ],
  // 강남
  25: [
    // {
    //   id: 19977,
    //   name: '강남 클리어 치과의원',
    //   comment: '스타들이 선택한 치과',
    // },
    {
      id: 19977,
      name: '강남클리어 치과의원',
      comment: '수많은 스타들이 선택한 2020 소비자만족 1위 치과',
    },
    {
      id: 21165,
      name: '고르다 치과의원',
      comment: '복지부 장관상 원장님 + 강남세브란스 교수 원장님',
    },
    {
      id: 9,
      name: '투디(2D) 치과의원',
      comment: '아나운서연합회와 함께하는 특급호텔 뺨치는 치과',
    },
    {
      id: 21161,
      name: '유앤아이 치과 의원 압구정점',
      comment: '고퀄진료를 위해 무려 환자수를 제한하는 치과',
    },
    {
      id: 21167,
      name: '엘투치과의원',
      comment: '연세대 치주과 박사+전문의 원장님이 소신을 지키는 치과',
    },
    {
      id: 18969,
      name: '보아치과의원',
      comment: '서울대로 안가도 서울대 교수님께 진료 받을 수 있는 곳',
    },
  ],
  // 서초
  24: [
    {
      id: 11133,
      name: '체리스마일 치과의원',
      comment: '고려대 교수 원장님이 8년 넘게 강남대로를 지키는 곳',
    },
    {
      id: 21163,
      name: '라이브 치과 병원 강남점',
      comment: '의원X 병원O + 주7일진료 + 누적교정환자 5천명',
    },
    {
      id: 18952,
      name: '연세좋은손 치과의원',
      comment: '명절에도 쉼없이 1년 365일 진료하는 치과',
    },
    {
      id: 21171,
      name: 'TU(티유) 치과의원',
      comment: '연세대의 미소 전문가 원장님들이 계신 힙한 치과',
    },
  ],
  // 강동
  23: [
    {
      id: 21157,
      name: '서울S 치과의원',
      comment: '의사+치과의사, 더블 타이틀 원장님이 계신 곳',
    },
  ],
  // 하남
  67: [
    {
      id: 21159,
      name: '365플러스 치과의원',
      comment: '오직 전문의만 모여 진료하는 하남 어벤져스',
    },
    {
      id: 21147,
      name: '백현 치과의원',
      comment: '미사맘들에게 유명한 가족 모두를 위한 치과',
    },
  ],
};

export const hospitalListByLocationId = {
  // 강남: 강남 + 서초
  // 25: _.concat([], activeHospitalList[25], activeHospitalList[24]),
  25: '25,24,',
  // 송파: 강남 + 서초 + 송파
  // 22: _.concat([], activeHospitalList[25], activeHospitalList[24], activeHospitalList[22]),
  22: '25,24,22,',
  // 관악: 강남 + 서초
  // 21: _.concat([], activeHospitalList[25], activeHospitalList[24]),
  21: '25,24,',
  // 서초: 강남 + 서초
  // 24: _.concat([], activeHospitalList[25], activeHospitalList[24]),
  24: '25,24,',
  // 강동: 송파 + 강동
  // 23: _.concat([], activeHospitalList[22], activeHospitalList[23]),
  23: '22,23,',
  // 하남: 송파 + 강동 + 하남
  // 67: _.concat([], activeHospitalList[22], activeHospitalList[23], activeHospitalList[67]),
  67: '22,23,67,',
};

export const getHospitalNearby = (ids) => {
  const data = [];
  let tempId = '';
  ids.forEach((id) => {
    tempId += hospitalListByLocationId[id];
  });
  // 중복 제거 위해 hashSet 사용
  const setId = new Set(tempId.split(',')); // -> [22,24,22,'']
  for (const id of setId) {
    // 병원이 있을때만 push 하기 대문에
    // [''] 는 자동으로 제거됨
    activeHospitalList[id] && data.push(...activeHospitalList[id]);
  }
  return data;
};

export default {};
