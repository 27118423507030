import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';
import { withApollo, graphql, compose } from 'react-apollo';
import { isAuthenticated, meFetch, logout, setToken } from '../utils/auth';
import { loginUserByPhoneMutation, registerUserByPhoneMutation, passwordResetMutation } from '../graphql';
import withToast from '../utils/toasthoc';
import withAlertModal from '../utils/alerthoc';

export const AuthContext = React.createContext();
const { Provider, Consumer } = AuthContext;
export const AuthConsumer = Consumer;

class AuthProvider extends Component {
  constructor(props) {
    super(props);
    this.login = this.login.bind(this);
    this.autoLogin = this.autoLogin.bind(this);
    this.regist = this.regist.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  state = {
    loaded: false,
    isLoggedIn: false,
    me: null,
  };

  componentDidMount() {
    this.autoLogin();
  }

  setMe = (me) => {
    this.setState({ me });
  };

  autoLogin = async () => {
    if (isAuthenticated() && !this.state.me && !this.state.loaded) {
      const me = await meFetch(this.props.client);

      if (me) {
        this.setMe(me);
        this.updateIsLoggedIn(true);
        // this.forceUpdate();
      }
    }
    this.updateLoaded(true);
  };

  login = async (phonenum, password) => {
    if (phonenum && password) {
      // mutation
      try {
        const loginRes = await this.props.loginUser({
          variables: {
            phonenum,
            password,
          },
        });

        const { ok, isPartner, token, refreshToken } = loginRes.data.loginUserByPhone;

        if (ok && isPartner) {
          this.props.openAlert({
            title: '오닥터 파트너 계정',
            message: (
              <div>
                오닥터 파트너병원으로 가입된 계정입니다.
                <br />
                파트너병원 페이지로 이동합니다.
              </div>
            ),
            showConfirmBtn: true,
            confirmBtnName: '확인',
            textAlign: 'left',
            onClose: this.props.closeAlert,
            confirmBtnOnClick: () => window.location = 'https://hospital.odoctor.co.kr/login',
          });
          return loginRes.data.loginUserByPhone;
        }

        if (ok) {
          // 토큰 저장
          setToken(token, refreshToken);

          if (isAuthenticated()) {
            const me = await meFetch(this.props.client);
            if (me) {
              this.setMe(me);
              this.updateIsLoggedIn(true);
            }
          }
        } else {
          this.props.toast(
            <div>
              입력한 아이디와 비밀번호가 일치하지 않습니다.
              <br />
              아이디 또는 비밀번호를 다시 한번 입력해주세요.
            </div>,
            'left',
          );
          logout();
        }
        return loginRes.data.loginUserByPhone;
      } catch (err) {
        throw err;
      }
    }
  };

  regist = async (username, phonenum, password, agreemarket) => {
    // mutation
    if (username && phonenum && password) {
      const res = await this.props.registUser({
        variables: {
          username,
          phonenum,
          password,
          agreemarket,
        },
      });

      return res.data.registerUserByPhone;
    }
  };

  resetPassword = async (phonenum, resetToken, newPass, forHuser = false) => {
    const result = await this.props.passwordReset({
      variables: {
        phonenum,
        resetToken,
        newPass,
        forHuser,
      },
    });

    return result.data.passwordReset;
  }

  handleLogout = () => {
    this.updateIsLoggedIn(false);
    this.setMe(null);
    this.props.history.push('/');
    logout();
  };

  updateIsLoggedIn = (isLoggedIn) => {
    this.setState({ isLoggedIn });
  };

  updateLoaded = (loaded) => {
    this.setState({ loaded });
  }

  render() {
    if (this.state.loaded) {
      return (
        <Provider
          value={{
            isLoggedIn: this.state.isLoggedIn,
            me: this.state.me,
            loaded: this.state.loaded,
            updateIsLoggedIn: this.updateIsLoggedIn,
            setMe: this.setMe,
            updateLoaded: this.updateLoaded,
            regist: this.regist,
            login: this.login,
            logout: this.handleLogout,
            resetPassword: this.resetPassword,
          }}
        >
          {this.props.children}
        </Provider>
      );
    }

    return null;
  }
}
const AuthProviderWith = withRouter(withApollo(withToast(withAlertModal(AuthProvider))));

export default compose(
  graphql(loginUserByPhoneMutation, {
    name: 'loginUser',
  }),
  graphql(registerUserByPhoneMutation, {
    name: 'registUser',
  }),
  graphql(passwordResetMutation, {
    name: 'passwordReset',
  }),
)(AuthProviderWith);
