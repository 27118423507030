import React from 'react';
import styled from 'styled-components';
import { OkButton, CancelButton, FlexButton } from '../atoms/Button/Button';
import { Flex, FlexItem } from '../atoms/Flex';
import PriceRadioCheckedIcon from '../../../images/checkbox-checked-32.svg';
import PriceRadioUnCheckedIcon from '../../../images/checkbox-unchecked-32.svg';
import { media } from '../../../globalStyle';

const StyledButtonGroup = styled.div`
  margin-top: 30px;
  margin: 30px -10px 0 -10px;
  text-align: right;
  
  > * {   
    display: inline-block;
    &:not(:first-child) {
      margin-left: 20px;
    }
  }
`;

const StyledRadioButtonWrap = styled.div`
  input {
    display: none;
  }
  input + label {
    cursor: pointer;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.07;
    letter-spacing: -1.1px;
    color: #000000;
    background-image: url(${PriceRadioUnCheckedIcon});
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position-y: 50%;
    background-position-x: 0;
    padding: 6px 0 6px 28px;
  }
  input:checked + label {
    background-image: url(${PriceRadioCheckedIcon});
  }
  
  ${media.tablet`
    input + label {
      font-size: 16px;
      line-height: 0.94;
      letter-spacing: -1.2px;
      padding: 4px 0 4px 28px;
    }
  `};
`;
/**
 * buttonOption = { ...belows }
 * @param okButtonName
 * @param cancelButtonName
 * @param onClickOk
 * @param onClickCancel
 * @returns {*}
 * @constructor
 */
export const ButtonGroup = ({
  okButtonName, cancelButtonName, onClickOk, onClickCancel,
}) => (
  <StyledButtonGroup>
    {/* Same as ( onClickOk ? <OkButton onClick~~~ : <OkButton /> ) */}
    <OkButton
      {...(onClickOk && { onClick: onClickOk })}
      {...(okButtonName && { buttonName: okButtonName })}
    />
    <CancelButton
      {...(onClickCancel && { onClick: onClickCancel })}
      {...(cancelButtonName && { buttonName: cancelButtonName })}
    />
  </StyledButtonGroup>
);

/**
 *
 * @param className
 * @param disabled 버튼 숨김 모드 1: 왼쪽, 2 오른쪽, 3 모두
 * @param okButtonName
 * @param cancelButtonName
 * @param onClickOk
 * @param onClickCancel
 * @returns {*}
 * @constructor
 */
export const StretchButtonGroup = ({
  className, disabled, okButtonName, cancelButtonName, onClickOk, onClickCancel,
}) => (
  <Flex>
    {/* Same as ( onClickOk ? <OkButton onClick~~~ : <OkButton /> ) */}
    <FlexItem stretch>
      <FlexButton
        textLeft
        className={className}
        {...(onClickOk && { onClick: onClickOk })}
      >
        {okButtonName}
      </FlexButton>
    </FlexItem>
    <FlexItem stretch>
      <FlexButton
        textRight
        {...(disabled && { disabled })}
        {...(onClickCancel && { onClick: onClickCancel })}
      >
        {cancelButtonName}
      </FlexButton>
    </FlexItem>
  </Flex>
);

export const StretchButton = ({ onClick, children, ...other }) => (
  <Flex>
    {/* Same as ( onClickOk ? <OkButton onClick~~~ : <OkButton /> ) */}
    <FlexItem stretch>
      <FlexButton
        {...other}
        textCenter
        onClick={onClick}
      >
        {children}
      </FlexButton>
    </FlexItem>
  </Flex>
);

// type="radio"
// id="price-care"
// name="carePrice"
// value=""
// onChange={this.handleChange}
// checked={this.state.carePrice === ''}
export const RadioButton = ({
  id, className, children, ...others
}) => (
  <StyledRadioButtonWrap className={className}>
    <input
      id={id}
      name={id}
      type="radio"
      {...others}
    />
    <label
      htmlFor={id}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  </StyledRadioButtonWrap>
);
