import React from 'react';
import PropTypes from 'prop-types';

// https://github.com/blueberryapps/react-load-script
// 위의 module을 변형하여 적용.
class Script extends React.Component {
  static propTypes = {
    onError: PropTypes.func, // 로딩에러 callback
    onLoad: PropTypes.func, // 로딩 완료 callback
    url: PropTypes.string.isRequired, // 스크립트 src 정보
    async: PropTypes.bool, // async 속성
    hardReload: PropTypes.bool, // 스크립트를 강제로 다시 리로딩하는 option
  };

  // 같은 url의 스크립트를 관리하는 dictionary
  // 로딩 되기 전에 대기하는 script 관리
  static scriptObservers = {};

  // 이미 로드 된 스크립트를 저장.
  static loadedScripts = {};

  // 로딩에러가 난 스트립트를 저장.
  static erroredScripts = {};

  // 스크립트 컴포넌트를 이용하는 객체 갯수.
  static scriptCnt = 0;

  static defaultProps = {
    onError: () => {},
    onLoad: () => {},
    async: false,
    hardReload: false,
  };

  constructor(props) {
    super(props);
    this.scriptId = `id${(this.constructor.scriptCnt += 1)}`;
    this.deleteScriptObserver = this.deleteScriptObserver.bind(this);
    this.addToLoadedScript = this.addToLoadedScript.bind(this);
    this.addToErroredScript = this.addToErroredScript.bind(this);
  }

  componentDidMount() {
    const {
      onError, onLoad, url, hardReload,
    } = this.props;

    // 로딩된 스크립트는 다시로딩하지 않는다.
    if (!hardReload && this.constructor.loadedScripts[url]) {
      onLoad();
      return;
    }

    // 로딩된 스크립트는 다시로딩하지 않는다. 로딩 에러 스크립트 error callback
    if (!hardReload && this.constructor.erroredScripts[url]) {
      onError();
      return;
    }

    // 강제 리로딩 시에는 저장된 script 정보, DOM element를 모두 삭제.
    if (hardReload) {
      this.hardDeleteScriptElement(url);
    }

    // observer에 등록
    if (this.constructor.scriptObservers[url]) {
      this.constructor.scriptObservers[url][this.scriptId] = this.props;
      return;
    }
    this.constructor.scriptObservers[url] = { [this.scriptId]: this.props };

    // 스크립트 element 생성.
    this.createScript();
  }

  componentWillUnmount() {
    const { url } = this.props;
    const observers = this.constructor.scriptObservers[url];

    // If the component is waiting for the script to load, remove the
    // component from the script's observers before unmounting the component.
    if (observers) {
      delete observers[this.scriptId];
    }
  }

  // 저장된 script 정보, DOM element를 모두 삭제.
  hardDeleteScriptElement = (url) => {
    const oldScript = document.getElementById(url);
    if (oldScript) document.body.removeChild(oldScript);

    delete this.constructor.loadedScripts[url];
    delete this.constructor.erroredScripts[url];
    delete this.constructor.scriptObservers[url];
  };

  // observer에서 script 삭제
  deleteScriptObserver = (url, scriptId) => {
    delete this.constructor.scriptObservers[url][scriptId];
  };

  // loadedScripts script 추가
  addToLoadedScript = (url) => {
    this.constructor.loadedScripts[url] = true;
  };

  // erroredScripts script 추가
  addToErroredScript = (url) => {
    this.constructor.erroredScripts[url] = true;
  };

  // 스크립트 element 생성.
  createScript = () => {
    const { url, async } = this.props;
    const observer = this.constructor.scriptObservers[url][this.scriptId];
    const script = document.createElement('script');
    script.src = url;
    script.id = url;
    script.async = async;

    // onload 콜백 정의
    script.onload = () => {
      if (observer) {
        observer.onLoad();
        this.deleteScriptObserver(url, this.scriptId);
      }
      this.addToLoadedScript(url);
    };

    // onerror 콜백 정의
    script.onerror = () => {
      if (observer) {
        observer.onError();
        this.deleteScriptObserver(url, this.scriptId);
      }
      this.addToErroredScript(url);
    };

    document.body.appendChild(script);
  };

  render() {
    return null;
  }
}

export default Script;
