import React from 'react';
import styled from 'styled-components';
import { compose, graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';
import gql from 'graphql-tag';
import withAlertModal from '../../utils/alerthoc';
import { AuthContext } from '../AuthProvider';
import {
  getHospitalQuery,
  publishUserReqsMutate,
} from '../../graphql';
import { unBlockEscape } from './unBlock';
import formatFilename from '../../utils/FileUpload';
import loadingAnimationImage from '../../images/loding3.gif';
import { setSheet } from '../../utils/loging';

const StyledProcessWrap = styled.div`
  text-align: center;
`;
/**
 *
 * @param publishUserReqs
 * @param signS3
 * @param photo
 * publishUserReqs(
   directHospitalId: ID
   txItems: [TxItemInput]
   orthoItems: [OrthoItemInput]
   reqType: ReqType
   txMaxPrice: Float
   orthoMaxPrice: Float
   carePrice: String
   orthoWanna: [String]
   txImageUrls: [String]
   orthoImageUrls: [String]
   considerTags: [String]
   desc: String
   gender: String
   ageGroup: String
   addressRegion: String
   addressCity: String
   wantLevel: Int
   ): UserReqs
 * @returns {*}
 * @constructor
 */
const ProcessComponent = ({ publishUserReqs, signS3, photo }) => {
  const savedData = JSON.parse(localStorage.getItem('pData'));
  const reqType = [null, 'ortho', 'tx', 'all'];
  const trashNode = ['isDetail', 'memberPassword', 'name', 'ortho', 'orthoPart', 'password', 'phone'];
  unBlockEscape();

  // 'secret' 값을 가지고 있으면 trashNode에 추가하여
  // 마지막에 모두 제거
  _.forOwn(savedData, (value, key) => {
    if (value === 'secret') {
      trashNode.push(key);
    }
  });

  savedData.txImageUrls = [];
  const { txItems } = savedData;
  // flag 로 쓰인 ort 항목 제거
  _.remove(txItems, t => (t.match(/ort/gi)));
  savedData.txItems = [
    ...txItems.map(tx => (
      {
        itemcode: tx,
        unit: 1,
      }
    )),
  ];

  // orthoItems 변환 {itemcode: "ORT-002", part: "PU"}
  const orthoPart = savedData.orthoPart || []; // [] : // To escape undefined.map()
  savedData.orthoItems = [
    ...orthoPart.map(ot => (
      {
        itemcode: savedData.ortho,
        part: ot,
      }
    )),
  ];

  // locations 의 빈배열 제거
  // 여기서 제거하는 이유는 상담 중 지역 선택시 해당 순서 일정하게 보여 주기 위해
  const tmpLoc = [];
  savedData.locations.forEach((loc) => {
    _.isEmpty(loc) || tmpLoc.push(loc);
  });
  savedData.locations = tmpLoc;

  const getSignedUrl = async (file) => {
    try {
      return await signS3({
        variables: {
          filename: formatFilename(file.name),
          filetype: file.type,
        },
      });
    } catch (error) {
      return error;
    }
  };

  // S3에 이미지 POST
  const uploadToS3 = async (file, signedRequest) => {
    const options = {
      headers: {
        'Content-Type': file.type,
      },
    };
    const resp = await axios.put(signedRequest, file, options);
    return resp;
  };

  const submit = async () => {
    // 1 : ortho
    // 2 : tx
    // 3 : all
    // ${savedData.txItems.length && 1} return 0 or 1
    const reqTypeIndex = parseInt(`${savedData.txItems.length && 1}${savedData.orthoItems.length && 1}`, 2);

    savedData.reqType = reqType[reqTypeIndex];

    setSheet({
      name: '상담완료',
      step: '99',
      isTx: savedData.txItems.length,
      isOrtho: !!savedData.orthoItems.length,
      isDetail: !!savedData.isDetail,
      data: '완료',
    });

    // 필요 없는 노드 제거
    trashNode.forEach((i) => {
      delete savedData[i];
    });

    publishUserReqs({ variables: savedData })
      .then(r => r)
      .then((res) => {
        localStorage.removeItem('pData');
        localStorage.removeItem('pStep');

        const { id } = res.data.publishUserReqs;
        if (id) {
          setTimeout(() => {
            window.location.href = `./reqdone/${id}`;
          }, 1500);
        } else {
          setSheet({
            name: 'ERROR',
            step: 'ERROR',
          });
          // 실패 알럿 후
          // goBack()
        }
      });
  };

  const uploadImage = async (photos) => {
    Promise.all(
      photos.map(async (p) => {
        const signRes = await getSignedUrl(p.file);
        const { signedRequest, url } = signRes.data.signS3;
        savedData.txImageUrls.push(url);
        const dd = await uploadToS3(p.file, signedRequest);
        return dd;
      }),
    )
      .then(() => {
        submit();
      });

    // return photo.map(async (p) => {
    //   await getSignedUrl(p)
    //     .then(async (res) => {
    //       console.log('1 try get signed url');
    //       return res;
    //   })
    //     .then( async (res) => {
    //       console.log('2 get signed url from server');
    //       const { signedRequest, url } = res.data.signS3;
    //       console.log(signedRequest, url)
    //       savedData.txImageUrls.push(url);
    //       return await uploadToS3(p, signedRequest);
    //     })
    //     .then(async (res) => {
    //       console.log('3 uploaded image')
    //       console.log(res);
    //     })
    // });
  };

  // 이미지 업로드 기다렸다가..
  // Promise.all([uploadImage(photo)])
  //   .then(function(values) {
  //   console.log('all done values')
  //   console.log(values);
  //   console.log('value')
  //   // submit();
  // });

  uploadImage(photo);

  return (
    <StyledProcessWrap>
      <img src={loadingAnimationImage} alt="now processing" />
    </StyledProcessWrap>

  );
};

const signS3Mutation = gql`
  mutation($filename: String!, $filetype: String!) {
    signS3(filename: $filename, filetype: $filetype) {
      signedRequest
      url
    }
  }
`;

export default withAlertModal(
  withRouter(
    compose(
      graphql(getHospitalQuery, {
        skip: ({ match }) => !match.params || !match.params.directHosId,
        options: ({ match }) => ({
          variables: { id: match.params.directHosId },
        }),
      }),
      graphql(publishUserReqsMutate, {
        name: 'publishUserReqs',
      }),
      graphql(signS3Mutation, { name: 'signS3' }),
    )(ProcessComponent),
  ),
);
ProcessComponent.contextType = AuthContext;
