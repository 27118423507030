import React from 'react';
import styled from 'styled-components';

const FormInputText = styled.input`
  width: 100%;
  background-color: #ffffff;
  padding: 14px 10px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
  height: 48px;
  border-radius: 1px;
  box-sizing: border-box;
  border: solid 1px #e0e0e0;
  :focus {
    outline: none;
  }
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #bbbcbc;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #bbbcbc;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #bbbcbc;
  }
  :-webkit-autofill { 
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    -webkit-text-fill-color: #767676;
  }
  :-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    -webkit-text-fill-color: #767676;
    outline: none;
  }
  ${props => props.error && 'border-color: #dd4124;'}
`;

const FormValidateMessage = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: -1px;
  color: #dd4124;
  margin-top: 4px;
  white-space: pre-wrap;
`;


class FormInput extends React.Component {
  render() {
    const {
      name,
      value,
      placeholder,
      onChange,
      errorMsg,
      fireSubmitValidation,
      type,
      onBlur,
      maxLength,
      autoCapitalize,
    } = this.props;

    let error = false;
    if (errorMsg || (fireSubmitValidation && !value)) {
      error = true;
    }
    return (
      <React.Fragment>
        <FormInputText
          type={type || 'text'}
          name={name}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          autoComplete="off"
          error={error || errorMsg}
          onBlur={onBlur}
          maxLength={maxLength || ''}
          autoCapitalize={autoCapitalize || 'none'}
          autoCorrect="off"
          {...this.props}
        />
        {fireSubmitValidation &&
          !value && !errorMsg && <FormValidateMessage>필수 정보입니다</FormValidateMessage>}
        {errorMsg && <FormValidateMessage>{errorMsg}</FormValidateMessage>}
      </React.Fragment>
    );
  }
}

export default FormInput;
