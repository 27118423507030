import React, { Component } from 'react';
import NoticeDetailContainer from '../../containers/Manual/NoticeDetailContainer';

class NoticeDetail extends Component {
  render() {
    return (
      <NoticeDetailContainer {...this.props} />
    );
  }
}

export default NoticeDetail;
