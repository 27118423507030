import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { Flex } from '../atomic/atoms/Flex';
import { Title } from '../atomic/atoms/Title/Title';
import {
  FlexGrid, CreateButton, createComponent, StretchButton,
} from './EnhancedComponents';
import { ProgressBar } from '../atomic/atoms/Shape/ProgressBar';
import { setSheet } from '../../utils/loging';
import withToast from '../../utils/toasthoc';

const StyledSection = styled.div`
  /*background-color: #c4e3f3;*/
  width: 100%;
`;

const ExtendedTitle = styled(Title)`
  font-size: ${props => (props.main ? '34px' : '26px')};
  letter-spacing: ${props => (props.main ? '-.7px' : '-.6px')};
  color: #000;
  font-weight: bold;
  line-height: 34px; 
  margin-top: 0;
  /* line-height: 1.31; */
`;

const StyledButtonDrawArea = styled.div`
  margin-top: 57px;
  position: relative;
  margin-bottom: 5px;
`;

const StyledButton = styled.div`
  margin: 5px 0;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledTitleDescription = styled.div`
  margin-top: 10px;
  line-height: 20px;
  font-size: 14px;
  letter-spacing: -0.4px;
  color: #b1b3b2;
`;

const ReturnNewLine = text => (
  text.split('\n').map((t, i) => (
    <span key={i}>
      {t}
      <br />
    </span>
  ))
);

export const Component = withToast((
  {
    title,
    name, // data category name
    type,
    data,
    dataType,
    dataMax,
    buttonType,
    description,
    buttons,
    cols,
    beforeNext,
    more, // 메인페이지 더보기 버튼
    doCounselButton, // 메인페이지 상담 버튼
    component,
    selectedDataSet,
    goToNext,
    updateStep,
    main,
    toast,
  },
) => {
  const [exposureButtonNum, setExposureButtonNum] = useState(9);
  const [photo, setPhoto] = useState([]);
  // const [selected, setSelected] = useState(selectedDataSet);
  const [selected, setSelected] = useState({
    ...selectedDataSet,
    gender: null,
    ageGroup: null, // button 타입일 경우 미리 없으면 에러남 고쳐야함 todo
    orthoImageUrls: [],
    orthoItems: [],
    orthoWanna: [],
    considerTags: [],
    txImageUrls: [],
    desc: null,
    name: null,
    phone: null,
    password: null,
    memberPassword: null,
  });

  const OPTION = buttons.map(
    op => (
      {
        ...op,
        active: false,
      }
    ),
  );

  localStorage.setItem('pData', JSON.stringify(selected));

  const onClickHandler = (item, photoFiles, isPhotoRemove) => {
    // console.log('selected[name]', selected[name]);
    // 사진 첨부 화면에서, 사진파일이 있을 경우에..
    if (photoFiles) {
      // 땜빵
      if( isPhotoRemove ) {
        setPhoto([...photoFiles]);
      } else {
        setPhoto([...photo, ...photoFiles]);
      }
    }

    // setOptions([
    //     ...options,
    //     {
    //       ...item,
    //       active: !item.active
    //     }
    //   ]
    // );

    if (type === 'checkbox') {
      // const prevSelected = selected[name];
      let newSelect = [];

      // 임시 코드
      if (name === 'orthoPart') {
        _.remove(selected[name], t => (
          t.match(new RegExp(`${item.value[1]}`, 'gi'))
        ));
      }

      if (selected[name].find(e => e === item.value)) {
        /* setSelected({
          ...selected,
          [name]:[..._.pull(selected[name], item.value)]
        }); */
        newSelect = [..._.pull(selected[name], item.value)];
      } else {
        /* setSelected({
          ...selected,
          [name]:[...selected[name], item.value]
        }) */
        newSelect = [...selected[name], item.value];
      }

      if (dataMax && newSelect.length > dataMax) {
        toast(
          <div>치료 항목은 5개로 제한됩니다</div>,
          'error',
        );
        return;
      }

      setSelected({
        ...selected,
        [name]: newSelect,
      });

      // checkboxes
      // setCheckboxes([...checkboxes, item]);
    }

    if (type === 'radio' || type === 'component') {
      setSelected({
        ...selected,
        [name]: item.value,
      });


      // 전화번호 인증화면에서만 isExistUser 사용
      // updateStep()에서 (value ? memberStep : newBieStep) 로 분기
      if (buttonType === 'phone') updateStep({ isExistUser: item.isExistUser });
    }

    // 다음, 건너뛰기 버튼이 없는 경우
    // 항목을 선택후 바로 다음 스템으로 이동한다
    // condition : {dataType: null}
    if (!dataType) {
      // 바로 넘어가기 전에,
      // 약간의 딜레이를 줘서 선택한 부분을 인지 하도록 유도를 해본다
      if (name === 'isDetail') {
        // selected.isDetail === 'detail' && a = b;
        updateStep({ [name]: item.value });
        setTimeout(() => {
          goToNext();
        }, 200);
      } else {
        setTimeout(() => {
          goToNext();
        }, 200);
      }
    }
    // dataHandler({name: item.value})
  };

  return (
    <div>
      <Flex
        column
        justifyContent="space-between"
      >
        <StyledSection>
          <ExtendedTitle main={main}>
            {ReturnNewLine(title.replace(/@regionCity@/, selected.addressRegion))}
          </ExtendedTitle>
          {
              description
              && (
              <StyledTitleDescription>
                {ReturnNewLine(description)}
              </StyledTitleDescription>
              )
            }

          {/* {JSON.stringify(selected)} */}
          <StyledButtonDrawArea>
            {
                type === 'component'
                  ? createComponent(onClickHandler, component, photo)
                  : (
                    <FlexGrid
                      column
                      items={OPTION.slice(0, exposureButtonNum)}
                      cols={cols || 2}
                      render={item => (
                        <StyledButton>
                          {
                          CreateButton(() => { onClickHandler(item); },
                            buttonType,
                            item.name,
                            item.description,
                            item.image,
                            // JSON.stringify(selected[name]).match(item.name))
                            //   selected[name] === item.value)
                            //   JSON.stringify(selected[name]).match(item.value))
                            JSON.stringify(selected[name])
                              .replace(/["'[\]]/gi, '')
                              .match(new RegExp(`^${item.value}|,${item.value}`, 'gi')))
                        }
                        </StyledButton>
                      )}
                    />
                  )
                }
          </StyledButtonDrawArea>
          {/* main 에서 더 보기 버튼 영역} */}
          {
              exposureButtonNum !== buttons.length
              && (
                more && more(
                  () => {
                    setExposureButtonNum(buttons.length);
                  },
                )
              )
            }
          {/* main 상담하기 버튼 */}
          {
              doCounselButton && doCounselButton(selected[name])
            }
        </StyledSection>
      </Flex>

      {/* 건너뛰기, 다음 버튼 */}
      {
          more || (data || dataType)

          && (
            <div style={{
              position: 'absolute', bottom: 0, left: 0, width: '100%', height: '64px',
            }}
            >
              {
                (() => {
                  // data 가 [], ' ' 등의로 존재 할경우
                  // 건너뛰기 가능
                  // data 가 null 일경우 선택된 값(selected[name])을 참고하여 다음 버튼을 활성화 시킴
                  const disabled = data ? false : !selected[name] || !selected[name][0];

                  return (
                    <StretchButton
                      className="fs-14"
                      fixed
                      disabled={disabled}
                      onClick={() => {
                        // 해당 step에 textMax가 있으면 (예: 상담 증상 입력)
                        // textMax에 정해 진 만큼 체크
                        if(beforeNext && !beforeNext(selected[name])) {
                          return;
                        }
                        goToNext();
                      }}
                    >
                      {(data && dataType) && (!selected[name] || !selected[name][0]) ? '건너뛰기' : '다음'}
                    </StretchButton>
                  );
                })()
              }
            </div>
          )
        }
    </div>
  );
});

const StyledStepHeader = styled.div`
  width: 100%;
  height: 58px;
  position: absolute;
  top: 0;
  left: 0;
  .prev-icon{
    width: 24px;
    height: 24px;
    padding: 17px 20px;
    cursor: pointer;
    box-sizing: content-box;
  }
  .progress-bar{
    width: 100%;
    height: 3px;
    position: relative;
  }
`;

const ExtendedProgressBar = styled(ProgressBar)`
  position: absolute;
  top: 0;
  width: 100%;
  background-color: transparent;
`;

export const Acc = (props) => {
  const {
    steps,
    simpleSteps,
    dataHandler,
    updateStep,
  } = props;

  const [step, setStep] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  // make init
  const initData = steps.reduce((accumSteps, currentStep) => (
    {
      ...accumSteps,
      [currentStep.name]: currentStep.dataType,
    }
  ), {});
  // get data from local storage 'pData'
  const savedData = JSON.parse(localStorage.getItem('pData'));
  // 초기화된 데이터셋 기준으로,
  // 저장된 데이터셋을 assign 해줌
  const selectedDataSet = {
    ...initData,
    ...savedData,
  };

  return (
    <div>
      {
        steps[step].name !== 'process'
        && (
        <StyledStepHeader>
          { /* 뒤로가기 */ }
          <div
            className="prev-icon"
            onClick={() => {
              if (step === 0) {
                window.location.href = '/';
                return;
              }
              setStep(step - 1);
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path fill="#A4A4A4" fillRule="evenodd" d="M23.865 10.08v3l-18.157-.001 6.279 6.278-2.122 2.122-9.9-9.9 9.9-9.9 2.122 2.122-6.278 6.278h18.156z" />
            </svg>

          </div>
          {/* 프로그레스 바 */}
          <div className="progress-bar">
            {/* 1차 */}
            <ProgressBar
              color="#033e52"
              stages={simpleSteps}
              currStep={step + 1}
            />
            {/* 2차 자세히 묻기 */}
            <ExtendedProgressBar
              color="#dd4124"
              stages={steps.length - simpleSteps - 1}
              currStep={step + 1 - simpleSteps}
            />
          </div>
        </StyledStepHeader>
        )
      }
      {/* step rotation */}
      <Component
        {...steps[step]}
        selectedDataSet={selectedDataSet}
        dataHandler={dataHandler}
        updateStep={updateStep}
        goToNext={() => {
          let tmpData = null;
          const logData = JSON.parse(localStorage.getItem('pData'));
          switch (steps[step].name) {
            case 'txImageUrls':
            case 'desc':
              tmpData = logData[steps[step].name] ? `${logData[steps[step].name].length}개` : '';
              break;
            case 'password':
            case 'phone':
            case 'memeberPassword':
              tmpData = '****';
              break;
            default:
              tmpData = logData[steps[step].name];
              break;
          }

          setSheet({
            name: steps[step].logName,
            step: step + 1,
            isTx: logData.txItems.length - !!logData.txItems.includes('ORT'),
            isOrtho: !!logData.txItems.includes('ORT'),
            isDetail: !!logData.isDetail,
            data: tmpData,
          });
          setStep(step + 1);
        }}
      />
    </div>
  );
};
