import React from 'react';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import gql from 'graphql-tag';

import ScrollToTopOnMount from '../components/basic/ScrollToTopOnMount';
import withAlertModal from '../utils/alerthoc';
import { getHospitalQuery, publishUserReqsMutate } from '../graphql';
import { media } from '../globalStyle';
import { AuthContext } from '../components/AuthProvider';
import { addADNTag } from '../utils/loging';
import Script from '../components/util/Script';
import therapyInfo from '../components/form/therapyInfo';
import CounselTherapySelectForm from '../components/CounselTherapySelectForm';
import CounselSettingForm from '../components/CounselSettingForm';
import CounselUserInfoForm from '../components/CounselUserInfoForm';
import formatFilename from '../utils/FileUpload';

const CounselContainer = styled.div`
  padding: 43px 20px 0;
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  ${media.tablet`
    min-width: 320px;
    padding: 58px 0px 0;
  `};
`;

class Counsel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      directHospitalId: props.match.params && props.match.params.directHosId,
    };

    this.selectedRegionFromMain = props.location.state && props.location.state.region;
    this.selectedTherapyFromMain = props.location.state && props.location.state.therapy;
    this.therapyData = {}; // 선택한 치료
    this.settingData = {}; // 상담 정보
  }

  componentDidMount() {
    // retargeting marketing code
    addADNTag('Complete', '상담신청');
    // 창끄기 이벤트
    window.addEventListener('beforeunload', this.blockEscapeSite);
    window.addEventListener('unload', this.blockEscapeSite);
    this.unblock = this.props.history.block(this.blockEscapeLocation);
  }

  componentWillReceiveProps(nextProps) {
    const { data, openAlert, history } = this.props;
    if (nextProps.match.params && nextProps.match.params.directHosId) {
      const { loading, getHospital } = data;
      if (!loading && !getHospital) {
        openAlert({
          title: '알림',
          message:
            '해당 병원에 다이렉트상담을 진행 할 수 없습니다. 링크를 확인해주세요.',
          showConfirmBtn: true,
          confirmBtnName: '확인',
          confirmBtnOnClick: history.replace('/counsel'),
          showCancelBtn: false,
          textAlign: 'left',
        });
      }
    }
  }

  componentWillUnmount() {
    this.unBlockEscape();
  }

  blockEscapeSite = (e) => {
    const message = "이 페이지에서 나가면 상담이 저장되지 않습니다.";
    e.preventDefault();
    e.stopPropagation();
    // For IE and Firefox
    e.returnValue = message;
    // For Safari
    return message;
  }

  unBlockEscape = () => {
    window.removeEventListener('beforeunload', this.blockEscapeSite);
    window.removeEventListener('unload', this.blockEscapeSite);
    this.unblock();
  }

  blockEscapeLocation = (location) => {
    if (location.pathname === '/counsel') {
      return null;
    }
    return '이 페이지에서 나가면 상담이 저장되지 않습니다.';
  }

  goToStep = (step) => {
    this.setState({ step });
    window.scrollTo(0, 0);
  };

  convertToTxItem = async (pickedTxTherapy) => {
    const txItems = await Object.keys(pickedTxTherapy).map(id => ({
      itemcode: therapyInfo[pickedTxTherapy[id].name].item_id,
      unit: pickedTxTherapy[id].unit,
    }));

    return txItems;
  };

  // orthoPart, pickedOrthoType를 mutateForm에 맞게 orthoItems로 변환
  convertToOrthoItem = async (orthoPart, pickedOrthoType) => {
    const orthoItems = await orthoPart.map(part => ({
      itemcode: therapyInfo[pickedOrthoType].item_id,
      part,
    }));

    return orthoItems;
  };

  // reqType 구하기
  // txItems 길이가 0 보다 크면 tx
  // orthoItems 길이가 0 보다 크면 ortho
  // 둘 다 만족하면 return 'all'
  // 아무것도 만족하지 않으면 return null
  getReqType = async (txItems, orthoItems) => {
    let isTx = false;
    let isOrtho = false;

    if (txItems && txItems.length > 0) {
      isTx = true;
    }

    if (orthoItems && orthoItems.length > 0) {
      isOrtho = true;
    }

    if (isTx && isOrtho) {
      return 'all';
    }
    if (isTx) {
      return 'tx';
    }
    if (isOrtho) {
      return 'ortho';
    }

    return null;
  };

  // 이미지 업로드
  imageUpload = async (files) => {
    const imageUrls = [];

    try {
      const allResult = await Promise.all(files.map(async (file) => {
        const res = await this.props.signS3({
          variables: {
            filename: formatFilename(file.name),
            filetype: file.type,
          },
        });

        const { signedRequest, url } = res.data.signS3;

        try {
          await this.uploadToS3(file, signedRequest);
          imageUrls.push(url);
          return;
        } catch (e) {
          console.log("Image Upload Failed!");
        }
      }));

      return imageUrls;
    } catch (e) {
      console.log(e);
      return [];
    }
  };

  // S3에 이미지 POST
  uploadToS3 = async (file, signedRequest) => {
    const options = {
      headers: {
        'Content-Type': file.type,
      },
    };
    const resp = await axios.put(signedRequest, file, options);
    return resp;
  };

  // 상담신청
  onSubmit = async () => {
    const {
      directHospitalId,
    } = this.state;

    const {
      txImageFiles,
      orthoImageFiles,
      orthoPart,
      pickedTxTherapy,
      pickedOrthoType,
      desc,
      orthoWanna,
    } = this.therapyData;

    const {
      gender, // 성별
      considerTags, // 상담시 고려사항
      txMaxPrice, // 희망 치과 치료비
      orthoMaxPrice, // 희망 교정 치료비
      carePrice, // 가격 입력 여부. default: '', 'dontcare': 상관없음, 'noinput': 없음
      addressRegion, // 상담 - 도시(시/도)
      addressCity, // 상담 - 도시(시/군/구)
      ageGroup, // 연령대
      wantLevel,
      location,
    } = this.settingData;

    const { isLoggedIn } = this.context;

    const newTxImageUrls = await this.imageUpload(txImageFiles);
    const newOrthoImageUrls = await this.imageUpload(orthoImageFiles);
    const txItems = await this.convertToTxItem(pickedTxTherapy);
    const orthoItems = await this.convertToOrthoItem(
      orthoPart,
      pickedOrthoType,
    );
    const reqType = await this.getReqType(txItems, orthoItems);

    if (isLoggedIn) {
      // 상담 추가 parameter 구성 start
      const variables = {
        // forceCreate: false,
        reqType, // 상담 타입
        txImageUrls: newTxImageUrls, // 치과 치료 사진
        orthoImageUrls: newOrthoImageUrls, // 교정 치료 사진
        txItems, // 선택된 일반 치료 항목
        orthoItems, // 교정 치료 항목
        orthoWanna, // 교정 시 개선을 원하는 사항
        considerTags, // 상담시 고려사항
        wantLevel, // 치료 의향
      };

      // 성별
      if (gender) {
        variables.gender = gender;
      }

      // 추가 불편 사항
      if (desc) {
        variables.desc = desc;
      }

      // 연령대
      if (ageGroup) {
        variables.ageGroup = ageGroup;
      }

      // 희망가격
      if (carePrice === 'dontcare') {
        variables.carePrice = carePrice;
        variables.txMaxPrice = null;
        variables.orthoMaxPrice = null;
      }

      if (carePrice !== 'dontcare' && txMaxPrice) {
        const txMaxPriceInt = parseInt(txMaxPrice.replace(/,\s?/g, ''), 10); // Comma 지우고 숫자로 만들어준다.
        variables.txMaxPrice = txMaxPriceInt;
      }

      if (carePrice !== 'dontcare' && orthoMaxPrice) {
        const orthoMaxPriceInt = parseInt(orthoMaxPrice.replace(/,\s?/g, ''), 10);
        variables.orthoMaxPrice = orthoMaxPriceInt;
      }

      // 다이렉트 상담 일 때
      if (directHospitalId) {
        // 선택한 병원 추가
        variables.directHospitalId = directHospitalId;
      } else {
        variables.considerTags = considerTags;
        // variables.addressRegion = addressRegion;
        // variables.addressCity = addressCity;
        variables.locations = [{
          siName: addressRegion,
          gunguName: addressCity,
        }]
      }
      // 상담 추가 parameter 구성 end

      // 실제 상담을 Create
      const res = await this.props.publishUserReqs({ variables });
      const { id } = res.data.publishUserReqs;
      if (id) {
        // escape 막지 않는다.
        this.unBlockEscape();
        this.props.history.push(`/reqdone/${id}`);
      } else {
        this.props.openAlert({
          title: '알림',
          message: '견적 요청에 실패하였습니다.',
          showConfirmBtn: true,
          confirmBtnName: '확인',
          confirmBtnOnClick: this.props.closeAlert,
        });
      }
    }
  };

  render() {
    const {
      step, directHospitalId,
    } = this.state;

    return (
      <div>
        <CounselContainer>
          <ScrollToTopOnMount />
          {/* 치료항목 선택 */}
          {step === 1 && (
            <CounselTherapySelectForm
              isDirect={!!directHospitalId}
              saveTheraphy={(data) => {
                this.therapyData = data;
              }}
              selectedTherapyFromMain={this.selectedTherapyFromMain}
              goToStep={this.goToStep}
              initialData={this.therapyData}
            />
          )}

          {/* 상담 정보 입력 */}
          {step === 2 && this.therapyData && (
            <CounselSettingForm
              isDirect={!!directHospitalId}
              therapyData={this.therapyData}
              goToStep={this.goToStep}
              initialData={this.settingData}
              selectedRegionFromMain={this.selectedRegionFromMain}
              saveSettingData={(data) => {
                this.settingData = data;
              }}
              onSubmit={this.onSubmit}
            />
          )}

          {/* 유저 정보 입력 */}
          {step === 3 && (
            <CounselUserInfoForm therapyData={this.therapyData} goToStep={this.goToStep} onSubmit={this.onSubmit} />
          )}
        </CounselContainer>
        <Script
          url="//fin.rainbownine.net/js/adn_tags_1.0.0.js"
          async
          hardReload
        />
      </div>
    );
  }
}

const signS3Mutation = gql`
  mutation($filename: String!, $filetype: String!) {
    signS3(filename: $filename, filetype: $filetype) {
      signedRequest
      url
    }
  }
`;

const CounselWithFunc = withAlertModal(
  withRouter(
    compose(
      graphql(getHospitalQuery, {
        skip: ({ match }) => !match.params || !match.params.directHosId,
        options: ({ match }) => ({
          variables: { id: match.params.directHosId },
        }),
      }),
      graphql(publishUserReqsMutate, {
        name: 'publishUserReqs',
      }),
      graphql(signS3Mutation, { name: 'signS3' }),
    )(Counsel),
  ),
);
Counsel.contextType = AuthContext;
export default CounselWithFunc;
