import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import FaqRow from '../../components/Manual/FaqRow';
import RouteHead from '../../components/basic/RouteHeader';
import { faqList } from '../../data';

class Faq extends Component {
  render() {
    return (
      <div>
        <RouteHead>FAQ</RouteHead>
        <div>
          {faqList.map((faq, idx) => <FaqRow key={`faq_${idx}`} faq={faq} />)}
        </div>
      </div>
    );
  }
}

export default withRouter(Faq);
