import React from 'react';
import styled from 'styled-components';
import { getPassValidationMessage } from '../../utils/validationInput';
import FormInput from './FormInput';

const PasswordInputContainer = styled.div`
  position: relative;
`;

class PasswordInput extends React.Component {
  state = {
    passValidationMsg: '',
  };

  componentDidMount() {
    if(this.props.scrollTo) {
      this.props.scrollTo();
    }
  }

  onChange = (e) => {
    this.props.onChange(e);
    const passValidationMsg = getPassValidationMessage(e.target.value);
    this.setState({ passValidationMsg });
  }

  render() {
    const { passValidationMsg } = this.state;
    const {
      value, name, placeholder, noValidate, fireSubmitValidation, errorMsg, textVisible,
    } = this.props;

    let passErrorMsg = '';

    if (!noValidate && passValidationMsg) {
      passErrorMsg = passValidationMsg;
    }

    return (
      <PasswordInputContainer>
        <FormInput
          {...this.props}
          type={textVisible ? 'text' : 'password'}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={this.onChange}
          fireSubmitValidation={fireSubmitValidation}
          errorMsg={errorMsg || passErrorMsg || ''}
        />
      </PasswordInputContainer>
    );
  }
}
export default PasswordInput;
