import React, { useState, useEffect } from 'react';

export const DayTimerComponent = ({ onTimeOut, timerReset, init = 3600 }) => {
  const [seconds, setSeconds] = useState(init);
  const [isActive, setIsActive] = useState(true);

  function reset() {
    setSeconds(180);
    setIsActive(true);
  }

  useEffect(
    () => {
      timerReset && reset();
    },
    [timerReset],
  );

  useEffect(() => {
    let interval = null;
    if (seconds === 0) {
      setIsActive(false);
      onTimeOut();
    }

    if (isActive) {
      interval = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  function format(time){
    return `${time < 10 ? '0' : ''}${time}`;
  }

  return (
    <>
      {format(Math.floor(seconds / 3600))}
      :
      {format(Math.floor(seconds / 60 % 60))}
      :
      {seconds % 60 < 10 ? '0' : ''}
      {seconds - Math.floor(seconds / 60) * 60}
    </>
  );
};

const TimerComponent = ({ onTimeOut, timerReset }) => {
  const [seconds, setSeconds] = useState(180);
  const [isActive, setIsActive] = useState(true);

  function reset() {
    setSeconds(180);
    setIsActive(true);
  }

  useEffect(
    () => {
      timerReset && reset();
    },
    [timerReset],
  );

  useEffect(() => {
    let interval = null;
    if (seconds === 0) {
      setIsActive(false);
      onTimeOut();
    }

    if (isActive) {
      interval = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  return (
    <>
      {Math.floor(seconds / 60)}
      :
      {seconds % 60 < 10 ? '0' : ''}
      {seconds - Math.floor(seconds / 60) * 60}
    </>
  );
};

export default TimerComponent;
