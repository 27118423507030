import axios from 'axios';

export const sendSlack = async (msg, forceDevelopmentPush = false, hookUrl = process.env.REACT_APP_SLACK_WEBHOOK_URL) => {
  const env = process.env.REACT_APP_NODE_ENV || 'development';
  const url = hookUrl;
  const message = JSON.stringify({ text: msg });

  if (env !== 'production' && !forceDevelopmentPush) {
    console.log('개발 환경은 슬랙 보내지 않는다.');
    console.log(msg);
    return true;
  }

  try {
    await axios.post(url, message, {
      withCredentials: false,
      transformRequest: [(data, headers) => {
        delete headers.post['Content-Type'];
        return data;
      }],
    });

    return true;
  } catch (err) {
    console.error(err);
    console.error('sendSlack - 메시지를 보낼 수 없습니다.');
    return null;
  }
};

export default {};
