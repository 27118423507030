import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import Slider from 'react-slick';

import { media } from '../globalStyle';
import { Card } from '../components/atomic/molecules/Card';
import { StarWithPoint } from '../components/atomic/molecules/StarRating';
import { liveReviewList } from '../data/index';
import { Flex, FlexItem } from '../components/atomic/atoms/Flex';
import { CaseCard } from './HospitalCase';

const ExtendedFlex = styled(Flex)`
  margin-right: -20px;
  ${media.miniDesktop`
    flex-direction: column;
    margin-right: 0;
  `};
`;

const ExtendedFlexItem = styled(FlexItem)`
  flex: 0 0 33.333333%;
  ${media.miniDesktop`
    width: 100%;
  `};
`;

const StyledSlider = styled(Slider)`
  .case-card{
    min-height: 170px;
    .content{
      max-height: 66px;
      overflow: hidden;
    }
  }
  .slick-list {
    height: 300px;
    padding: 0 0;
    margin-right: -20px;
    margin-left: -20px;
    .slick-track{
      margin-left: 20px;
    }
  }
  .slick-slide {
    margin-top: 2px;
    width: 100%;
    max-width: calc(100vw - 40px);
    margin-right: 10px;
  }
  .slick-dots {
    bottom: -7px;
    li {
      margin: 0 7px;
      width: 6px;
      height: 6px;
      button {
        width: 6px;
        height: 6px;
        padding: 0;
      }
      button:before {
        width: 6px;
        height: 6px;
        opacity: 1;
        color: #e1e3e2;
        font-size: 6px;
        line-height: 6px;
      }
    }
    li.slick-active {
      button:before {
        opacity: 1;
        color: #033e52;
      }
    }
  }
`;

const StyledCardPanel = styled.div`
  margin-right: 20px;
  max-width: 333px;
  height: 204px;
  border-radius: 8px;
  box-shadow: 8px 8px 20px 0 rgba(0, 0, 0, 0.1);
  //box-shadow: 6px 6px 20px 0 rgba(0, 0, 0, 0.14);
  
  background-color: #fff;
  padding: 20px;
  p{
    font-size: 14px;
  }
  .card-title{
    font-weight: bold;
    padding-bottom: 10px;
  }
  .card-text{
    letter-spacing: -0.7px;
    color: #b1b3b2;
  }
  .star-box{
    height: 45px;
    padding-top: 8px;
    margin-bottom: 30px;
    .star-point{
      font-size: 30px;
      margin: 0 10px 0 0;    
    }
    * {
      cursor: default;
    }
  }
  ${media.miniDesktop`
    max-width: none;
    width: 100%;
    margin-bottom: 20px;
  `};
`;

const NewStyledCardPanel = styled.div`
  //max-width: 333px;
  height: 265px;
  border-radius: 4px;
  box-shadow: 2px 6px 13px -2px rgba(0,0,0,0.2);
  background-color: #fff;
  padding: 20px;
  p{
    font-size: 14px;
  }
  .star-box{
    height: 24px;
    margin-bottom: 30px;
    .star-point{
      margin: 0 6px 0 0;
      span {
        font-size: 20px;
      }
    }
    * {
      cursor: default;
    }
  }
  .card-title {
    font-size: 14px;
    letter-spacing: -0.7px;
    color: #b1b3b2;
    margin-bottom: 10px;
    b {
      font-weight: normal;
    }
  }
  .card-text {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: -0.7px;
    color: #000000;
    margin-bottom: 0;
  }
  ${media.miniDesktop`
    max-width: none;
    width: 100%;
    margin-bottom: 20px;
  `};
`;

const dummy = [
  {
    id: 5,
    location: {
      siName: '서울',
      gunguName: '강동구',
    },
    title: '잇몸에 빨간 수포가 생겼어요',
    contentSummary: '어금니 위쪽에 임플란트 예정인데 아직 발치 전입니다.\n' +
      '오늘 갑자기 빨간 수포 같은게 생겨서요 저게 뭐죠?',
    userGender: '남성',
    userAgeGroup: '20대',
    reqDate: '2020-01-22',
    categories: [{
      id: 1,
      name: '임플란트',
    }, {
      id: 8,
      name: '잇몸치료',
    }],
  },
  {
    id: 14,
    categories: [{
      id: 1,
      name: '임플란트',
    }, {
      id: 2,
      name: '충치치료',
    }, {
      id: 6,
      name: '앞니치료',
    }],
    location: {
      siName: '서울',
      gunguName: '강동구',
    },
    userGender: '여성',
    userAgeGroup: '20대',
    reqDate: '2020-01-07',
    title: '앞니 빼고 다 부서졌어요…',
    contentSummary: '위쪽은 앞쪽빼고 치아가 다 부서졌어요 뿌리만 박혀있는거같습니다 어금니도 비슷한 상황이고 ' +
      '앞니는 브릿지를 했는데 이상합니다 브릿지한곳 잇몸쪽이 까매요 턱도 왼쪽으로 돌아갔고 치료는 받고싶은데 ' +
      '무섭기도하고..근데 이제 문득 이러다 틀니하게될거같아서 늦었지만 이제라도 치료받고싶습니다ㅠ'.substr(0, 120),
  },
  {
    id: 16,
    categories: [{
      id: 4,
      name: '신경치료',
    }, {
      id: 3,
      name: '치아씌우기',
    },],
    location: {
      siName: '경기도',
      gunguName: '성남시 분당구',
    },
    userGender: '남성',
    userAgeGroup: '20대',
    reqDate: '2020-03-06',
    title: '크라운 치료 몇개가 필요한가요??',
    contentSummary: '오늘 정기검진을 받았는데 신경치료 및 크라운을 세개를 하는게 좋다고 하는데 한개 빼고는 사진상으로 ' +
      '필요성을 못느꼈습니다. 이게 과잉치료에 해당하지 않을까라는 생각이 드는데 이 부분 문의드립니다'.substr(0, 120),
  },
  {
    id: 22,
    categories: [{
      id: 2,
      name: '충치치료',
    }, {
      id: 4,
      name: '신경치료',
    },],
    location: {
      siName: '인천',
      gunguName: '연수구',
    },
    userGender: '여성',
    userAgeGroup: '20대',
    reqDate: '2019-11-13',
    title: '인접면 충치는 레진으로는 안되나요ㅠㅠ',
    contentSummary: '치아3개(송곳니쪽치아)중 가운데 이가 썩었는데 육안상으로는 그게 양옆으로 충치가 번진거 같아요 이쑤시개로 ' +
      '찔러보니 안에 구멍이 난거 같기두 하구요.. 한 10군데 전화 해봤더니 인접면 충치는 레진으로는 안된다고 하더라구요ㅠㅠ ' +
      '제경우는 구멍까지 난거 같으니까 인레이로 하겠죠...? '.substr(0, 120),
  },
  {
    id: 28,
    categories: [{
      id: 2,
      name: '충치치료',
    }, {
      id: 7,
      name: '사랑니',
    },],
    location: {
      siName: '경기도',
      gunguName: '하남시',
    },
    userGender: '남성',
    userAgeGroup: '30대',
    reqDate: '2019-11-21',
    title: '어금니 구멍..',
    contentSummary: '오른쪽 위 어금니 썩은 곳에 구멍이 났습니다.. 자꾸 음식물도 끼고 안 좋은 냄새가 많이 마서 힘드네요',
  },
  {
    id: 31,
    categories: [{
      id: 5,
      name: '치아교정',
    },],
    location: {
      siName: '서울시',
      gunguName: '노원구',
    },
    userGender: '남성',
    userAgeGroup: '30대',
    reqDate: '2019-11-10',
    title: '치열이 좀 고르지 못해서 고민입니다',
    contentSummary: '혹시 부분적으로 교정해도 될까요?',
  },
  {
    id: 34,
    categories: [{
      id: 9,
      name: '턱관절',
    },],
    location: {
      siName: '서울시',
      gunguName: '강동구',
    },
    userGender: '남성',
    userAgeGroup: '30대',
    reqDate: '2020-04-12',
    title: '스플린트가 필요하다고 하는데',
    contentSummary: 'x-ray로 혹시 어떤 문제가 있는지 확인하실 수 있을까요',
  },
  {
    id: 50,
    categories: [{
      id: 1,
      name: '임플란트',
    },],
    location: {
      siName: '서울시',
      gunguName: '영등포구',
    },
    userGender: '남성',
    userAgeGroup: '40대',
    reqDate: '2019-10-25',
    title: '꼭 발치해야 하나요..',
    contentSummary: '오른쪽 어금니가 오래전부터 염증이 있었으나 신경치료후 아프지않아 그대로 사용하고 있습니다. 동네치과에서는 계속 염증치료를 하면 더 더 사용가능하다고 해서 사용중인데요 우연히 다른병원에 가서 치료를 받다가 엑스레이를 찍고 바로 발치해야된다고 얘기를 들었습니다. 그리고 발치후 공간이 너무커서 뼈 이식을 같이 진행해야 된다고 하는데 어떻게 해야 될까요?'.substr(0, 150),
  },
  {
    id: 53,
    categories: [{
      id: 6,
      name: '앞니치료',
    },],
    location: {
      siName: '경기도',
      gunguName: '안산시 단원구',
    },
    userGender: '남성',
    userAgeGroup: '20대',
    reqDate: '2019-11-10',
    title: '앞니 왜소치',
    contentSummary: '현재 이빨 교정 중이고 교정기를 곧 빼는데 앞니 2 개 왜소치로 라미네이트를 해야할지 크라운을 해야할지 정확한 진단 부탁드립니다.',
  },
  {
    id: 55,
    categories: [{
      id: 6,
      name: '앞니치료',
    }, {
      id: 4,
      name: '신경치료',
    },],
    location: {
      siName: '서울시',
      gunguName: '강남구',
    },
    userGender: '남성',
    userAgeGroup: '20대',
    reqDate: '2020-01-17',
    title: '앞니가 좀 꺠졌..',
    contentSummary: '앞니라서 빨리 치료하고 싶어요',
  },
  {
    id: 56,
    categories: [{
      id: 5,
      name: '치아교정',
    },],
    location: {
      siName: '경기도',
      gunguName: '성남시 분당구',
    },
    userGender: '여성',
    userAgeGroup: '20대',
    reqDate: '2020-03-02',
    title: '교정 중인데 씹으면 어금니가 아파요ㅠㅠ',
    contentSummary: '혹시 교정 중 움직이는 어금니를 씹으면 아픈게 당연한가요ㅠㅠ? 그리고 계속 그 어금니를 씹게되는데 괜찮은건가요?ㅠㅠ..',
  },
  {
    id: 62,
    categories: [{
      id: 2,
      name: '충치치료',
    }, {
      id: 4,
      name: '신경치료',
    }, {
      id: 3,
      name: '치아씌우기',
    },],
    location: {
      siName: '서울시',
      gunguName: '노원구',
    },
    userGender: '여성',
    userAgeGroup: '30대',
    reqDate: '2019-12-24',
    title: '한쪽 이가 욱신거려서 적당히 근처 치과를 방문했는데, 2차충치가 진행되었...',
    contentSummary: '"신경치료까진 아니고 충치부위에 약을 주입해주셨구요 1주일지켜보고 통증이 계속 나면 신경치료를 진행하자고 하시네요.\n' +
      '현재 4일 경과, 단걸 먹을때만 살짝 통증이 오고 그 외에는 별다른 통증이 없는데 이대로 신경치료 안하고 레진으로 막아도 될지 고민됩니다."'.substr(0, 70),
  },
  {
    id: 63,
    categories: [{
      id: 4,
      name: '신경치료',
    }, {
      id: 8,
      name: '잇몸치료',
    },],
    location: {
      siName: '경기도',
      gunguName: '부천시',
    },
    userGender: '여성',
    userAgeGroup: '40대',
    reqDate: '2020-03-24',
    title: '잇몸이 많이 내려가 있는 상태입니다. ',
    contentSummary: '치열이 고르지 않아 치과를 자주 가지 못했습니다. 그래서 스켈링이 필요하구요. 아래 어금니 양쪽을 몇년전에 크라운 했는데 가끔 시리고 한쪽 크라운 씌운 잇몸에 고름이 잡히면서 부풀어 올랐다가 가라 앉기를 반복하고 있어요.'.substr(0, 150),
  },
  {
    id: 69,
    categories: [{
      id: 4,
      name: '신경치료',
    }, {
      id: 3,
      name: '치아씌우기',
    }, {
      id: 1,
      name: '임플란트',
    },],
    location: {
      siName: '서울시',
      gunguName: '강남구',
    },
    userGender: '남성',
    userAgeGroup: '20대',
    reqDate: '2020-04-10',
    title: '왼쪽 어금니 꼭 임플란트를 해야하나요',
    contentSummary: ' 그리고 충치가 몇개고 그런것들 상담받아보고싶어요 상담받고 진료보고싶네요',
  },
  {
    id: 74,
    categories: [{
      id: 6,
      name: '앞니치료',
    },],
    location: {
      siName: '서울시',
      gunguName: '중구',
    },
    userGender: '여성',
    userAgeGroup: '30대',
    reqDate: '2020-02-01',
    title: '앞니가 깨진적이 있어서 붙여 놓은 상태인데 10년정도 지났습니다.',
    contentSummary: '교체를 해야하는지 진료를 원합니다. ',
  },
  {
    id: 78,
    categories: [{
      id: 4,
      name: '신경치료',
    }, {
      id: 3,
      name: '치아씌우기',
    },],
    location: {
      siName: '서울시',
      gunguName: '송파구',
    },
    userGender: '여성',
    userAgeGroup: '20대',
    reqDate: '2020-06-03',
    title: '크라운 씌웠던 어금니가 또 아파요',
    contentSummary: '"어렸을때 해외에서 왼쪽 위어금니쪽 충치치료+신경치료 해서 떼우고 한국들어와서 크라운으로 두차례나 씌웠는데\n' +
      '그로부터 3년정도 된 현재 크라운씌운쪽에 통증이 있어 병원을 방문하니\n' +
      '충치치료 및 재신경치료 크라운 으로 다시 해야한다고 하는데\n' +
      '비용이 너무 합리적이지 않고 전에 병원에서 말했던 부분과 설명이 너무 달라 신뢰가 안가서\n' +
      '이번 재치료는 좀더 신중하게 잘해주셨으면 하는 바람이 있어 문의드립니다."'.substr(0, 120),
  },
  {
    id: 82,
    categories: [{
      id: 2,
      name: '충치치료',
    },],
    location: {
      siName: '경기도',
      gunguName: '하남시',
    },
    userGender: '여성',
    userAgeGroup: '30대',
    reqDate: '2019-04-08',
    title: '치아 충치치료한뒤 매꾼 레진이 떨어졌어요.',
    contentSummary: '다시 레진으로 때우면 되나요?',
  },
  {
    id: 83,
    categories: [{
      id: 2,
      name: '충치치료',
    },],
    location: {
      siName: '서울시',
      gunguName: '강서구',
    },
    userGender: '여성',
    userAgeGroup: '30대',
    reqDate: '2019-05-24',
    title: '1주일 전부터 왼쪽 어금니 2개가 시큰 할때가 있어요',
    contentSummary: '특히 음식을 씹을때, 양치할때..ㅠㅠ',
  },
  {
    id: 86,
    categories: [{
      id: 7,
      name: '사랑니',
    }, {
      id: 1,
      name: '임플란트',
    },],
    location: {
      siName: '인천',
      gunguName: '남동구',
    },
    userGender: '여성',
    userAgeGroup: '30대',
    reqDate: '2019-05-27',
    title: '왼쪽 부분 어금니가 뿌리만 있고 없는상태입니다. ',
    contentSummary: '치과가기 무서워 가지 못하고 있는데.. 구체적으로 알려주시길 바랍니다ㅠㅠ',
  },
  {
    id: 123,
    categories: [{
      id: 5,
      name: '치아교정',
    },],
    location: {
      siName: '서울시',
      gunguName: '도봉구',
    },
    userGender: '남성',
    userAgeGroup: '10대 이하',
    reqDate: '2020-03-16',
    title: '앞니가 세개라서 교정을 꼭 하고 싶은데 이런경우에는 치료가 더 어려운가요..?',
    contentSummary: '교정이 아니라 공간을 만들어서 임플란트 하나요??',
  },

  {
    id: 149,
    categories: [{
      id: 4,
      name: '신경치료',
    },{
      id: 3,
      name: '치아씌우기',
    },],
    location: {
      siName: '인천',
      gunguName: '동구',
    },
    userGender: '여성',
    userAgeGroup: '20대',
    reqDate: '2020-03-16',
    title: '이빨이 노란색으로 변하고 이빨속이 간지럽기도 하고 바람 불면 시리기도...',
    contentSummary: '예전에 신경치료를 했는데 그때 그 치과에서 심(?)같은거를 안넣어서 빈공간에 염증이 생겼습니다',
  },
  {
    id: 163,
    categories: [{
      id: 5,
      name: '치아교정',
    },],
    location: {
      siName: '서울시',
      gunguName: '영등포구',
    },
    userGender: '남성',
    userAgeGroup: '20대',
    reqDate: '2020-06-09',
    title: '이빨이 거꾸로 물리는걸 보니 부정교합 있는거 같아요',
    contentSummary: '이거 때문에 발음이 새는거같구요. 치석같은것도 더 끼는거 같구',
  },
  {
    id: 186,
    categories: [{
      id: 4,
      name: '신경치료',
    },{
      id: 6,
      name: '앞니치료',
    },{
      id: 8,
      name: '잇몸치료',
    },],
    location: {
      siName: '서울시',
      gunguName: '동대문구',
    },
    userGender: '여성',
    userAgeGroup: '30대',
    reqDate: '2020-01-15',
    title: '넘어져서 앞니 1개가 반 이상 부러지고 1개는 끝이 조금 깨졌어요. ',
    contentSummary: '"심하게 부러진 치아는 레진으로 임시치아를 만들어 놓은 상태인데, 색이 살짝 어둡게 변해서 제대로 된 치료를 해야하나 고민중인 상황입니다.\n' +
      '그리고 최근들어 양치질 시에 잇몸에서 피가 가끔 나고 있어요."'.substr(0, 120),
  },
  {
    id: 195,
    categories: [{
      id: 5,
      name: '치아교정',
    },],
    location: {
      siName: '서울시',
      gunguName: '강남구',
    },
    userGender: '여성',
    userAgeGroup: '20대',
    reqDate: '2020-03-20',
    title: '앞니중앙선과 입술 중앙선이 배열이 맞지않아서 스트레스입니다.',
    contentSummary: '이미 교정을 한번했는데, 유지장치를 제대로 안껴서 조금 틀어진 것 같기도 합니다.',
  },
  {
    id: 192,
    categories: [{
      id: 2,
      name: '충치치료',
    },{
      id: 4,
      name: '신경치료',
    },{
      id: 3,
      name: '치아씌우기',
    },],
    location: {
      siName: '서울시',
      gunguName: '중랑구',
    },
    userGender: '여성',
    userAgeGroup: '50대',
    reqDate: '2020-05-28',
    title: '음식 씹기 어려울때가 있어요',
    contentSummary: '찌릿하기도 하고 아프기도하고 차거나 뜨거운 음식은 깜짝 놀랄정도이며 잇몸에서 피가 잘 나고 붓기도 합니다.',
  },
  {
    id: 207,
    categories: [{
      id: 5,
      name: '치아교정',
    },{
      id: 9,
      name: '턱관절',
    },],
    location: {
      siName: '인천',
      gunguName: '연수구',
    },
    userGender: '여성',
    userAgeGroup: '20대',
    reqDate: '2020-05-26',
    title: '입을 벌릴때마다 턱에서 소리가나고 윗니가 돌출되어 있어요~',
    contentSummary: '"아래에 발치가 필요한 치아가 하나 있고 사진에서 보시다시피 위쪽이 돌출되어있습니다.\n' +
      '윗입 돌출로인한 부정교합인지 입을 다물고 있는것보다 벌리고있는게 편하구요\n' +
      '가만히 있어도 이따금씩 턱에 약간의 통증이 있으며 입을 조금만 크게 벌려도 턱에서 뼈 부딫히는? 긁히는? 소리같은게 납니다.\n' +
      '아래에 튀어나온 치아를 발치하고(불필요시 안해도 됨) 돌출된 윗입을 집어넣고 턱이 아프고 소리나는 이유를 알고싶습니다"'.substr(0, 120),
  },
];

const MainCase = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    variableWidth: false,
    className: 'odoc-slide',
    arrows: false,
    responsive: [
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          variableWidth: true,
          dots: true,
        },
      },
    ],
  };
  return (
    <StyledSlider {...settings}>
      {
        _.sampleSize(dummy, 5).map((review, idx) => (
          <NewStyledCardPanel key={`${idx}_${review.name}`}>

            <CaseCard
              style={{ minHeight: '176px' }}
              list={review}
              hidePhoto
            />

          </NewStyledCardPanel>
        ))
      }
    </StyledSlider>
  );
};

export default (props) => {
  const { r } = props;
  return r ? (
    <MainCase />
  ) : (
    <ExtendedFlex>
      {
        _.sampleSize(liveReviewList, 3).map((review, idx) => (
          <ExtendedFlexItem key={idx}>
            <StyledCardPanel>
              <Card
                imgRender={() => (
                  <div className="star-box">
                    <StarWithPoint
                      ahead
                      size={20}
                      selectedNum={Number(review.rating).toFixed(1)}
                    />
                  </div>
                )}
                width="100%"
                height="inherit"
                title={review.name}
              >
                {review.comment}
              </Card>
            </StyledCardPanel>
          </ExtendedFlexItem>
        ))
      }
    </ExtendedFlex>
  );
};
