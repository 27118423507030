// ReqDone : 견적 form 다 적고 나서 보여주는 route
import React, { Component } from 'react';
import styled from 'styled-components';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { media } from '../globalStyle';
import { addADNTag } from '../utils/loging';
import Script from '../components/util/Script';
import { Title } from '../components/atomic/atoms/Title/Title';
import finishBg from '../images/finish.jpg';
import { userRequestQuery, sendHosResRequestMsgMutate } from '../graphql';
import { getHospitalNearby } from '../data/HospitalDataByLocation';
import CheckboxInput from '../components/form/CheckboxInput';
import { StyledModal } from '../components/basic/HtmlComponents';
import { ModalCloseBtn } from '../components/basic/ModalComponents';
import HospitalDetail from '../containers/HospitalDetail';
import withToast from '../utils/toasthoc';
import { sendSlack } from '../utils/slack';
import { AuthContext } from '../components/AuthProvider';
import CounselProcessInfoModal from '../components/CounselProcessInfoModal';

const ReqDoneMessage = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.5px;
  color: #b1b3b2;
  margin: 10px 0 90px;
  &.mt-30 {
    margin-top: 30px;
  }
  &.mb-40 {
    margin-bottom: 40px;
  }
`;

const CounselProcessInfoModalLink = styled.button`
  display: block;
  padding: 0;
  margin: 0 0 90px;
  border: 0;
  text-decoration: underline;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: #b1b3b2;
`;

const BtnWrapper = styled.div`
  width: 100%;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 64px;
  background-color: #033e52;
`;

const Btn = styled.button`
  height: 100%;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -.6px;
  text-align: center;
  color: #ffffff;
  background-color: #033e52;
`;

const Container = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  background-image: url(${props => props.bg});
  background-repeat: no-repeat;
  background-size: 86%;
  padding-top: 120px;
  padding-bottom: 80px;
`;

const Content = styled.div`
  ${media.tablet`
    padding: 0 20px 0;
  `};
`;

const ExtendedTitle1 = styled(Title)`
  margin-top: 0;
  margin-bottom: 20px;
  font-weight: bold;
  color: #033e52;
  font-size: 14px;
`;

const ExtendedTitle2 = styled(Title)`
  font-size: 26px;
  letter-spacing: -0.5px;
  color: #000;
  font-weight: bold;
  line-height: 34px;
  margin: 0;
`;

const HospitalContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px 0;
  border-top: 1px solid #e1e3e2;
  :last-of-type {
    border-bottom: 1px solid #e1e3e2;
  }
  input + label {
    min-width: 20px;
    margin: 0 20px 0 0;
  }
  .hospital-comment {
    width: 100%;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.4px;
    color: #000000;
    white-space: pre-wrap;
    word-break: keep-all;
    margin: 0;
  }
  .hospital-detail-button {
    margin-left: 13px;
    button {
      width: 73px;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: -0.34px;
      text-align: right;
      color: #033e52;
      text-decoration: underline;
      padding: 0;
      border: 0;
      white-space: pre-line;
      word-break: keep-all;
    }
  }
`;

const BottomBtn = styled.button`
  margin-top: 30px;
  width: 100%;
  height: 56px;
  border-radius: 4px;
  background-color: #033e52;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: -0.6px;
  text-align: center;
  color: #ffffff;
  &.disabled {
    border: solid 1px #e1e3e2;
    background-color: #ffffff;
    color: #b1b3b2;
    font-weight: normal;
  }
  &.req-list-btn {
    border: solid 1px #033e52;
    color: #000000;
    background-color: #ffffff;
    margin-top: 60px; 
  }
`;

const ExtendedStyledModal = styled(StyledModal)`
  ${media.tablet`
    outline: none;
    width: 100%;
    height: 100%;
    overflow: auto;
    
  `};
  .mobile-bottom-btns {
    display: none;
  }
  .modal-close {
    width: 100%;
    height: 28px;
  }
`;

const HospitalInfoModal = ({ isOpen, onClose, hid }) => (
  <ExtendedStyledModal
    style={{
      overlay: {
        zIndex: 100,
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
      },
    }}
    isOpen={isOpen}
    ariaHideApp={false}
  >
    <div className="modal-close">
      <ModalCloseBtn onClick={onClose} />
    </div>
    <HospitalDetail hid={hid} />
  </ExtendedStyledModal>
);

class NewDone extends Component {
  state = {
    isOpenInfoModal: false,
    isOpenHospitalModal: false,
    selectedHospitals: [],
  };

  componentDidMount() {
    if (window && window.fbq) {
      window.fbq('track', 'CompleteRegistration');
    }

    // retargeting marketing
    addADNTag('Complete', '상담완료');
  }

  onChange = (hospital) => {
    const { selectedHospitals } = this.state;
    const newSelectedHospitals = _.xor(selectedHospitals, [hospital]);
    this.setState({ selectedHospitals: newSelectedHospitals });
  };

  onSubmit = async () => {
    const { selectedHospitals } = this.state;
    const {
      match, sendMsg, toast, history,
    } = this.props;
    const { me } = this.context;

    if (selectedHospitals?.length === 0) {
      toast('병원을 먼저 선택해주세요');
      return;
    }

    try {
      const res = await sendMsg({
        variables: {
          hospitalIds: selectedHospitals.map(hospital => hospital.id),
          ureqId: match?.params?.reqId,
        },
      });

      if (res?.data?.sendHosRespRequestMessage?.ok) {
        toast('답변 요청이 완료되었어요!');

        const hospitalToString = selectedHospitals
          .reduce((text, hospitalObj) => text += `${hospitalObj.name}(${hospitalObj.id}), `, '');

        const msg = '[답변요청]\n'
        + `사용자: ${me.username}(${me.id})\n`
        + `상담 아이디: ${match?.params?.reqId}\n`
        + `요청 병원 : ${hospitalToString}\n`;

        // slack msg
        await sendSlack(
          msg,
          true,
          process.env.REACT_APP_SLACK_WEBHOOK_RESERVATION_URL,
        );

        // 완료시 답변 상세로 이동
        history.push(`/userreq/${match?.params?.reqId}`);
      }
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const {
      selectedHospitals,
      isOpenHospitalModal,
      selectedHid,
      isOpenInfoModal,
    } = this.state;
    const { history, userReq, match } = this.props;

    if (userReq?.loading) {
      return null;
    }

    const locationIds = userReq.getMyReq.locations.map(l => l.id*1);
    const hospitalsForRequestAnswer = getHospitalNearby(locationIds);

    return (
      <Container bg={finishBg}>
        <div>
          {userReq?.getMyReq?.locations
          && hospitalsForRequestAnswer?.length > 0 ? (
            <Content>
              <ExtendedTitle1>상담신청 완료!</ExtendedTitle1>
              <ExtendedTitle2>
                답변받고 싶은 병원이 있다면
                <br />
                먼저 요청해보세요
              </ExtendedTitle2>
              <ReqDoneMessage>
                병원의 답변이 등록되면 바로 메시지로 알려드려요!
                <br />
                불편하시더라도 조금만 기다려주세요.
              </ReqDoneMessage>
              <div>
                {hospitalsForRequestAnswer.map((hospital) => {
                  const checked = selectedHospitals.filter(el => el.id === hospital.id).length > 0;
                  return (
                    <HospitalContainer key={`hospital_req_resp_list_${hospital.id}`}>
                      <CheckboxInput
                        type="checkbox"
                        id={`req_res_hospital_${hospital.id}`}
                        name={`req_res_hospital_${hospital.id}`}
                        onChange={() => this.onChange(hospital)}
                        checked={checked}
                      />
                      <label
                        htmlFor={`req_res_hospital_${hospital.id}`}
                        className="hospital-comment"
                      >
                        {hospital.comment || '병원 코멘트'}
                      </label>
                      <div className="hospital-detail-button">
                        <button
                          type="button"
                          onClick={() => this.setState({
                            selectedHid: hospital.id,
                            isOpenHospitalModal: true,
                          })}
                        >
                          {hospital.name}
                        </button>
                      </div>
                    </HospitalContainer>
                  );
                })}
                <BottomBtn
                  className={selectedHospitals.length === 0 ? 'disabled' : ''}
                  onClick={this.onSubmit}
                >
                  답변 먼저요청
                </BottomBtn>
                <BottomBtn
                  className="req-list-btn"
                  onClick={() => history.push(`/userreq/${match?.params?.reqId}`)}
                >
                  내 상담 보기
                </BottomBtn>
              </div>
            </Content>
          ) : (
            <Content>
              <ExtendedTitle2>
                상담신청 완료!
                <br />
                조금만 기다려주세요.
              </ExtendedTitle2>
              <ReqDoneMessage className="mt-30 mb-40">
                병원의 답변이 등록되면 바로 메시지로 알려드려요!
                <br />
                불편하시더라도 조금만 기다려주세요.
              </ReqDoneMessage>
              <CounselProcessInfoModalLink onClick={() => this.setState({ isOpenInfoModal: true })}>상담 진행절차 보기</CounselProcessInfoModalLink>
              <BtnWrapper>
                <Btn onClick={() => history.push(`/userreq/${match?.params?.reqId}`)}>내 상담 보기</Btn>
              </BtnWrapper>
            </Content>
          )}
        </div>
        <Script url="//fin.rainbownine.net/js/adn_tags_1.0.0.js" async hardReload />
        <CounselProcessInfoModal isOpen={isOpenInfoModal} onClose={() => this.setState({ isOpenInfoModal: false })} />
        <HospitalInfoModal
          isOpen={isOpenHospitalModal}
          onClose={() => this.setState({ isOpenHospitalModal: false })}
          hid={selectedHid}
        />
      </Container>
    );
  }
}

NewDone.contextType = AuthContext;

export default compose(
  graphql(sendHosResRequestMsgMutate, {
    name: 'sendMsg',
  }),
  graphql(userRequestQuery, {
    name: 'userReq',
    skip: ({ match }) => !match?.params?.reqId,
    options: ({ match }) => ({
      variables: {
        reqId: match?.params?.reqId,
      },
    }),
  }),
)(withRouter(withToast(NewDone)));
