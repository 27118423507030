import React from 'react';
import styled, { css } from 'styled-components';

const CSSMarginLeftAuto = css`
  margin: -10px -10px -10px auto;
`;

const CSSMarginRightAuto = css`
  margin: -10px auto -10px -10px;
`;

const CSSFlexDirectionColumn = css`
  flex-direction: column;
`;

const CSSFlexJustifyContent = css`
  justify-content: ${props => props.justifyContent};
`;

const StyledFlex = styled.div`
  height: inherit;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  ${props => props.column && CSSFlexDirectionColumn};
  ${CSSFlexJustifyContent}
  
  > .flex-item {
    ${props => props.LeftGnb && CSSMarginRightAuto};
    ${props => props.RightGnb && CSSMarginLeftAuto};
  }
`;

const StyledFlexStretch = css`
  flex: 1 1 auto;
`;

const StyledFlexItem = styled.div`
  ${props => props.stretch && StyledFlexStretch};
`;

/**
 *
 * @param children
 * @param props
 * @returns {*}
 * @constructor
 */
export const Flex = ({ children, ...props }) => (
  <StyledFlex className="flex" {...props}>{children}</StyledFlex>
);

/**
 *
 * @param children
 * @param props
 * @returns {*}
 * @constructor
 */
export const FlexItem = ({ children, ...props }) => (
  <StyledFlexItem className="flex-item" {...props}>{children}</StyledFlexItem>
);
