import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import PasswordInput from './PasswordInput';
import { isValidPass, isValidName } from '../../utils/validationInput';
import CheckboxInput from './CheckboxInput';
import withAlertModal from '../../utils/alerthoc';
import withToast from '../../utils/toasthoc';
import NameInput from './NameInput';
import { FormInputRow, FormConfirmBtn, SignupAndSigninBtn } from './FormCommonComponent';
import { media } from '../../globalStyle';
import SignupPhoneConfirmInput from './SignupPhoneConfirmInput';

const PanelContainer = styled.div``;

const BottomContainer = styled.div`
  margin: 12px 0 30px;
  ${media.tablet`
    margin-top: 20px;
    margin-bottom: 40px;
  `};
`;

const FormPanel = styled.div``;

const AgreeTermSpan = styled.span`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.6px;
  color: #000000;
  a,
  a:hover,
  a:link {
    text-decoration: underline;
    color: #000000;
  }
`;

export const AgreeTermCheckboxLabel = () => (
  <AgreeTermSpan>
    (필수)
    {' '}
    <a target="_blank" href="https://odoctor.co.kr/manual/terms">
      이용약관
    </a>
    {' '}
    및
    {' '}
    <a target="_blank" href="https://odoctor.co.kr/manual/privacypolicy">
      개인정보 취급방침
    </a>
    에 동의
  </AgreeTermSpan>
);

class SignupForm extends React.Component {
  static propTypes = {
    goToLogin: PropTypes.func.isRequired,
    regist: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      phonenum: '',
      password: '',
      name: '',
      agreeTerms: true,
      agreeMarket: false,
      fireSubmitValidation: false,
      phoneErrorMsg: null, // 휴대폰 번호 입력 에러 메세지
      phoneConfirmed: false, // 휴대폰 인증 완료 플래그
    };
  }

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value, fireSubmitValidation: false });
  };

  onCheck = (e) => {
    const { name } = e.target;
    if (this.state[name]) {
      this.setState({ [name]: false });
    } else {
      this.setState({ [name]: true });
    }
  };

  handleSubmit = async () => {
    const {
      name,
      phonenum,
      password,
      agreeTerms,
      agreeMarket,
      phoneConfirmed,
    } = this.state;

    // 핸드폰번호 인증
    if ((phonenum.length === 10 || phonenum.length === 11) && !phoneConfirmed) {
      this.setState({ phoneErrorMsg: '휴대폰 번호 인증이 필요합니다.', fireSubmitValidation: true });
    }

    // 버튼 클릭시 확인해야하는 정보가 없을때에 fireSubmitValidation 활성화
    if (
      !name ||
      !phonenum ||
      !password ||
      !(phonenum.length === 10 || phonenum.length === 11) ||
      !phoneConfirmed
    ) {
      this.setState({ fireSubmitValidation: true });
      return;
    }

    // 유효하지 않은 이메일, 비밀번호, 이름을 입력했다면 무시
    if (
      !isValidPass(password) ||
      !isValidName(name)
    ) {
      return;
    }

    if (!agreeTerms) {
      this.props.toast(
        <div>
          약관 및 개인정보 취급방침에 동의해주세요.
        </div>,
        'left',
      );
      return;
    }

    try {
      // 회원가입 Mutation
      const registRes = await this.props.regist(
        name,
        phonenum,
        password,
        agreeMarket,
      );

      // 회원가입 성공시
      if (registRes) {
        // 로그인 Mutation
        const { ok } = registRes;
        if (ok) {
          const loginRes = await this.props.login(phonenum, password);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const {
      password,
      name,
      phonenum,
      agreeTerms,
      agreeMarket,
      fireSubmitValidation,
      phoneConfirmed,
      phoneErrorMsg,
    } = this.state;

    const { goToLogin } = this.props;

    return (
      <div>
        <PanelContainer>
          <FormPanel>
            <FormInputRow>
              <SignupPhoneConfirmInput
                name="phonenum"
                onChange={this.onChange}
                value={phonenum}
                fireSubmitValidation={fireSubmitValidation}
                disabled={phoneConfirmed}
                errorMsg={phoneErrorMsg}
                onDuplicated={this.onPhoneDuplicated}
                onConfirmed={() => this.setState({ phoneConfirmed: true })}
                clearError={() => this.setState({
                  fireSubmitValidation: false,
                  phoneErrorMsg: null,
                })}
                goToLogin={goToLogin}
              />
            </FormInputRow>
            <FormInputRow>
              <NameInput
                id="signupName"
                type="text"
                placeholder="이름"
                name="name"
                onChange={this.onChange}
                value={name}
                fireSubmitValidation={fireSubmitValidation}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    document.getElementById('signupPhone').focus();
                    this.handleSubmit();
                  }
                }}
              />
            </FormInputRow>
            <FormInputRow>
              <PasswordInput
                id="signupPassword"
                name="password"
                onChange={this.onChange}
                value={password}
                textVisible
                placeholder="비밀번호 (4~12자)"
                fireSubmitValidation={fireSubmitValidation}
              />
            </FormInputRow>
            <BottomContainer>
              <CheckboxInput
                type="checkbox"
                id="agreeTerms"
                name="agreeTerms"
                checked={agreeTerms}
                onChange={this.onCheck}
                labelComponent={<AgreeTermCheckboxLabel />}
              />
              <CheckboxInput
                type="checkbox"
                id="agreeMarket"
                name="agreeMarket"
                checked={agreeMarket}
                onChange={this.onCheck}
                labelText="(선택) 광고성 정보 수신 동의"
              />
            </BottomContainer>
            <div>
              <FormConfirmBtn
                onClick={this.handleSubmit}
              >
                가입완료
              </FormConfirmBtn>
            </div>
            <div>
              <SignupAndSigninBtn onClick={goToLogin}>로그인</SignupAndSigninBtn>
            </div>
          </FormPanel>
        </PanelContainer>
      </div>
    );
  }
}

export default withAlertModal(withToast(SignupForm));
