import React from 'react';
import queryString from 'query-string';
import styled from 'styled-components';
import ImageGallery from 'react-image-gallery';
import { withRouter } from 'react-router-dom';
import FirstFigureImage from '../images/cell-phone.png';
import SecondFigureImage from '../images/cell-phone2.png';
import PhoneBGImage from '../images/phone-bg.png';
import PhoneBGMobileImage from '../images/phone-bg-m.png';
import Graph from '../images/graph.png';
import GraphMobile from '../images/graph-m.png';
import PhoneImg from '../images/cell-phone-out.png';
import PhoneMobileImg from '../images/cell-phone-out-m.png';
import Step1 from '../images/step1.gif';
import Step2 from '../images/step2.gif';
import Step3 from '../images/step3.gif';
import Guide1 from '../images/guide1.png';
import Guide2 from '../images/guide2.png';
import Guide3 from '../images/guide3.png';
import FaqOpenIcon from '../images/faq-open.png';
import FaqCloseIcon from '../images/faq-close.png';
import { media } from '../globalStyle';
import { logEvent, userActivitySendSlack } from '../utils/loging';

const isIeOrEdge = document.documentMode || /Edge/.test(navigator.userAgent);
// const isIeOrEdge = true;

const HomeContainer = styled.div`
  background-color: #ffffff;
  position: relative;
`;

const Section = styled.section`
  width: 100%;
  position: relative;
`;

const Bold = styled.span`
  font-weight: 500;
`;

const StickySection = styled(Section)`
  background: #ffffff;
  margin: 0 auto;
  display: block;
  &#no-sticky {
    overflow-x: hidden;
  }
  ${media.tablet`
    display: none;
  `};
`;

const AbsoluteBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  min-height: 768px;
  top: 0;
  clip-path: inset(580px 0 0);
  background-color: #033e52;
  z-index: 1;
  & > div {
    max-width: 1080px;
    width: 100%;
    padding: 0 20px;
    margin: auto;
    height: 100%;
  }
  ${media.midium`
    clip-path: inset(500px 0 0);
  `};
  ${media.tablet`
    position: relative;
    clip-path: none;
    margin-top: 130px;
    padding: 0;
    height: 593px;
    min-height: 0;
    > div {
      width: 100%;
    }
  `};
  #no-sticky & {
    position: relative;
    clip-path: none;
    margin-top: 170px;
    padding: 60px 0 0;
    height: 720px;
    min-height: unset;
    min-height: auto;
    > div {
      width: 100%;
    }
    ${media.midium`
      margin-top: 160px;
    `};
    ${media.tablet`
      height: 593px;
    `};
  }
`;

const FigureWapper = styled.div`
  position: absolute;
  width: 100%;
  max-width: 1080px;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  top: 0;
  overflow: hidden;
  z-index: 1;
  ${media.tablet`
    left: auto;
    transform: none;
    min-width: unset;
    min-width: auto;
    top: 307px;
    height: auto;
  `};
`;

const ContentContainer1 = styled.div`
  display: none;
  transition: all 0.5s ease-in-out;
  position: relative;
  top: 50%;
  left: 0%;
  transform: translateY(-50%);
  z-index: 2;
  width: 100%;
  margin: -180px auto -20px;
  max-width: 1080px;
  padding: 0 20px;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: -1.2px;
  color: #ffffff;
  &.animation {
    display: block;
    animation-name: ContentSlideIn;
    animation-duration: 1.5s;
  }
  @keyframes ContentSlideIn {
    0% {
      transform: translateY(-40%);
    }
    100% {
      transform: translateY(-50%);
    }
  }

  ${media.midium`
    line-height: 1.31;
    letter-spacing: -1px;
  `};

  ${media.tablet`
    display: block;
    position: absolute;
    top: 804px;
    background-color: #033e52;
    left: unset;
    left: auto;
    transform: none;
    margin: 0 auto;
    font-size: 24px;
    line-height: 1.42;
    letter-spacing: -1px;
    text-align: center;
  `};

  #no-sticky & {
    display: block;
    top: 878px;
    margin: 0 auto;
    padding-bottom: 30px;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    ${media.midium`
      top: 610px;
    `};
    ${media.tablet`
      top: 834px;
    `};
  }
`;

const StickyWrapper = styled.div`
  position: relative;
  width: 100%;
  z-index: 0;
  background: transparent;
  min-height: ${props =>
    props.windowHeight && `${props.windowHeight * 2 + 1051}px`};
  
  ${media.tablet`
    min-height: unset;
    min-height: auto;
  `};

  #no-sticky & {
    min-height: unset;
    min-height: auto;
    ${media.midium`
      
    `};
    ${media.tablet`
    `};
  }
`;


const MobileView = styled(Section)`
  display: none;
  ${media.tablet`
    display: block;
  `};
`;


const MobileFigureContainer = styled.div`
  margin-top: 50px;
  position: relative;
  margin-bottom: 110px;
`;

const MobileFirstDesc = styled.div`
  font-size: 24px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.42;
  letter-spacing: -1px;
  text-align: center;
  color: #ffffff;
  position: relative;
  z-index: 1;
`;

const MobileFirstFigure = styled.figure`
  background-image: url(${FirstFigureImage});
  background-repeat: no-repeat;
  width: 100%;
  height: 484px;
  background-size: 375px 484px;
  background-position-x: 50%;
  z-index: 1;
  background-color: transparent;
  position: relative;
`;

const MobileNavyBackground = styled.div`
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  top: 110px;
  background-color: #033e52;
`;


const Title = styled.div`
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
  position: relative;
  z-index: 3;
  margin-top: -50px;
  ${media.midium`
    @media (max-height: 850px) {  
      margin-top: -90px;
    }
  `};
  ${media.tablet`
    margin-top: 0;
    padding-bottom: 30px;
    min-width: unset;
    min-width: auto;
  `};
`;

const H1 = styled.h1`
  font-size: 54px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: -3.1px;
  text-align: center;
  color: #033e52;
  br {
    display: none;
  }
  ${media.midium`
    font-size: 46px;
    letter-spacing: -2.2px;
  `};
  ${media.tablet`
    br {
      display: block;
    }
    font-size: 28px;
    line-height: 1;
    letter-spacing: -1.3px;
  `};
`;

const H2 = styled.h2`
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.42;
  letter-spacing: -1.2px;
  text-align: center;
  color: #033e52;
  margin-top: 10px;
  ${media.tablet`
    margin-top: 6px;
    font-size: 16px;
    line-height: 1.63;
    letter-spacing: -0.8px;
  `};
`;

const ConsultBtn = styled.button`
  margin-top: 40px;
  border: solid 1px #033e52;
  background: transparent;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.29;
  letter-spacing: -0.9px;
  text-align: center;
  color: #033e52;
  width: 180px;
  height: 54px;
  padding: 8px;
  cursor: pointer;
  ${media.tablet`
    width: auto;
    height: auto;
    min-width: 149px;
    min-height: 48px;
    margin-top: 30px;
  `};
`;

const ConsultBtnSpan = styled.span``;

const ImageContainer = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  height: 100vh;
  min-height: 768px;
  padding-top: 230px;
  ${media.midium`
    padding-top: 180px;
  `};
  ${media.tablet`
    position: relative;
    padding-top: 120px;
    height: auto;
    min-height: unset;
    min-height: auto;
  `};
  #no-sticky & {
    position: relative;
    padding-top: 130px;
    height: auto;
    min-height: unset;
    min-height: auto;
    ${media.midium`
      padding-top: 180px;
    `};
    ${media.tablet`
      padding-top: 120px;
    `};
  }
`;

const FirstFigure = styled.figure`
  background-image: url(${FirstFigureImage});
  background-repeat: no-repeat;
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 514px;
  height: 652px;
  background-size: contain;
  text-align: center;
  margin-top: 300px;
  ${media.midium`
    left: auto;
    right: -80px;
    transform: translateY(-50%);
    margin-top: 230px;
  `};
  ${media.tablet`
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    top: unset;
    top: auto;
    margin: 0 auto;
    width: 100%;
    height: 484px;
    background-size: 375px 484px;
    background-position-x: 50%;
  `};

  #no-sticky & {
    /* top: 486px; */
    top: 336px;
    margin: 0;
    transform: translateX(-50%);
    ${media.midium`
      top: 353px;
      transform: none;
    `};
    ${media.tablet`
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      top: unset;
      top: auto;
      margin: 0 auto;
      width: 100%;
      height: 484px;
      background-size: 375px 484px;
      background-position-x: 50%;
    `};
  }
`;

const ContentWrapper = styled.div`
  max-width: 1080px;
  width: 100%;
  padding: 0 20px;
  margin: auto;
  height: 204px;
  z-index: 2;
  top: 50%;
  margin-top: -200px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 42px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: -2px;
  color: #033e52;
  ${media.midium`
    line-height: 1.25;
    letter-spacing: -2.3px;
  `};
  ${media.tablet`
    position: relative;
    transform: none;
    width: 100%;
    margin: 0;
    top: unset;
    left: unset;
    top: none;
    left: none;
    height: auto;
    font-size: 30px;
    line-height: 1.4;
    text-align: center;
    letter-spacing: -1.4px;
    margin-bottom: 30px;
    br:nth-of-type(2) {
      display: none;
    }
  `};
  #no-sticky & {
  }
`;

const Oval = styled.div`
  width: 428px;
  height: 434px;
  border: solid 1px #033e52;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: 50%;
  margin-top: -115px;
  margin-right: -426px;
  &.blue {
    border-color: #033e52;
    margin-top: -99px;
    margin-right: -386px;
  }

  &.pink {
    border-color: #ffb3aa;
  }

  ${media.midium`
    width: 400px;
    height: 400px;
    margin-top: -66px;
    margin-right: -94px;
    right: -80px;
    &.blue {
      margin-top: -41px;
      margin-right: -43px;
    }
  `};

  ${media.tablet`
    width: 319px;
    height: 319px;
    right: auto;
    left: 50%;
    margin: 0 0 0 -48px;
    &.blue {
      margin: 12px 0 0 -73px;
    }
  `};

  #no-sticky & {

  }
`;

const Wiper = styled.div`
  height: 100vh;
  min-height: 768px;
  width: 100%;
  position: absolute;
  top: 0;
  background: #ffffff;
  z-index: 3;
  overflow: hidden;
  clip-path: inset(4440px 0 0);
  #no-sticky  & {
    clip-path: none;
    position: relative;
  }
  ${media.tablet`
    height: auto;
    position: relative;
    clip-path: none;
    min-width: unset;
    min-width: auto;
    min-height: unset;
    min-height: auto;
    overflow: hidden;
    padding: 80px 0 30px;
  `};

  #no-sticky & {
    position: relative;
    clip-path: none;
    min-width: unset;
    min-width: auto;
    min-height: unset;
    min-height: auto;
    overflow: hidden;
    padding: 120px 0 30px;
  }
`;

const SecondFigure = styled.figure`
  background-image: url(${SecondFigureImage});
  background-repeat: no-repeat;
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 514px;
  height: 652px;
  background-size: contain;
  text-align: center;
  ${media.midium`
    left: auto;
    right: -80px;
    transform: translateY(-50%);
  `};
  ${media.tablet`
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    top: unset;
    top: auto;
    margin: 0 auto;
    width: 100%;
    height: 484px;
    background-size: 375px 484px;
    background-position-x: 50%;
  `};

  #no-sticky & {
  }
`;

const WaySection = styled(Section)`
  max-width: 1080px;
  width: 100%;
  padding: 60px 20px 150px;
  text-align: center;
  margin: auto;
  ${media.tablet`
    width: 100%;
    padding: 60px 20px;
  `};
`;

const WayTitle = styled.h1`
  font-size: 46px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: -0.9px;
  text-align: center;
  color: #033e52;
  ${media.tablet`
    font-size: 30px;
    letter-spacing: -1.4px;
    width: 252px;
    margin: auto;
    br {
      display: none;
      word-break: keep-all;
    }
  `};
`;

const WaySubTitle = styled.h2`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
  letter-spacing: -0.4px;
  text-align: center;
  color: #000000;
  margin-top: 20px;
  word-break: keep-all;
  ${media.midium`
    margin-top: 30px;
  `};
  ${media.tablet`
    margin-top: 20px;
    br:last-of-type {
      display: none;
    }
  `};
`;

const WayCarouselWrapper = styled.div`
  margin: 60px auto 0;
  max-width: 691px;
  min-height: 369px;
  position: relative;
  overflow: hidden;
  text-align: center;
  .image-gallery {
    width: 320px;
    height: 275px;
    position: relative;
    margin: 94px auto 0;
    z-index: 1;
    .image-gallery-slide {
      img {
        display: none;
      }
      &.center {
        img {
          display: block;
        }
      }
    }
  }
  ${media.tablet`
    min-height: unset;
    min-height: auto;
    margin: 30px auto 0;
    .image-gallery {
      width: 200px;
      height: 167px;
      position: relative;
      margin: 58px auto 0;
    }
  `};
`;

const WayLine = styled.div`
  max-width: 850px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
`;

const Line = styled.div`
  width: 100%;
  padding: 0 70px;
  & > div {
    width: 100%;
    background-color: #bbbcbc;
    height: 1px;
    position: relative;
    ::after {
      content: '';
      position: absolute;
      width: 0;
      height: 1px;
      background-color: #033e52;
      left: 0;
      ${props =>
    props.idx &&
        `
        width: ${props.idx * 50}%;
      `};

      ${props =>
    props.idx &&
        props.idx > 0 &&
        `
        transition: width 0.7s;        
      `};
    }
  }
  ${media.midium`
    padding: 0;
  `};
`;

const Dots = styled.div`
  padding: 0 64px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  top: -5px;
  ${media.midium`
    padding: 0;
  `};
`;

const Dot = styled.div`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #bbbcbc;
  cursor: pointer;
  ${props => props.active && 'background-color: #033e52;'};
`;

const Steps = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 18px;
  ${media.tablet`
    padding-top: 10px;
  `};
`;

const Step = styled.div`
  font-size: 24px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: -1px;
  text-align: center;
  color: #bbbcbc;
  position: relative;
  cursor: pointer;
  ${props => props.active && 'color: #033e52;'};
  ${media.tablet`
    font-size: 14px;
    line-height: 2.14;
  `};
`;

const PhoneOuterImage = styled.div`
  position: absolute;
  top: 23px;
  left: 50%;
  background-image: url(${PhoneBGImage});
  transform: translateX(-50%);
  width: 514px;
  height: 346px;
  background-repeat: no-repeat;
  background-size: contain;
  ${media.tablet`
    background-image: url(${PhoneBGMobileImage});
    width: 280px;
    height: 224px;
    top: 0;
  `};
`;

const BenefitSection = styled(Section)`
  width: 100%;
  max-width: 1080px;
  padding: 60px 20px 150px;
  text-align: center;
  margin: auto;
  ${media.tablet`
    padding: 60px 20px;
  `};
`;

const Br2 = styled.br`
  display: none;
`;

const BenefitTitle = styled.h1`
  font-size: 46px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: -2px;
  text-align: center;
  color: #033e52;
  ${media.tablet`
    font-size: 30px;
    line-height: 1.4;
    letter-spacing: -1.4px;
    br {
      display: none;
    }
    ${Br2} {
      display: block;
    }
  `};
`;

const BenefitSubTitle = styled.h2`
  width: auto;
  margin: 30px auto 0;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
  letter-spacing: -0.4px;
  text-align: center;
  color: #000000;
  word-break: keep-all;
  ${media.tablet`
    width: 100%;
    max-width: 273px;
    margin-top: 20px;
  `};
`;

const GraphInfo = styled.table`
  display: none;
  ${media.tablet`
    display: table;
    margin: 50px auto 29px;
    td {
      text-align: left;
    }
    tr {
      td:first-of-type {
        min-width: 157px
      }
    }
    tr:nth-of-type(2) {
      td {
        padding-top: 12px;
      }
    }
  `};
`;

const Color = styled.div`
  width: 12px;
  height: 12px;
  margin-right: 6px;
  display: inline-block;
  vertical-align: middle;
  ${props => props.color && `background-color: ${props.color}`};
`;

const GraphText = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.7px;
  color: #000000;
  display: inline-block;
  vertical-align: middle;
`;

const BenefitGraph = styled.div`
  width: 690px;
  height: 382px;
  background-image: url(${Graph});
  background-size: contain;
  background-repeat: no-repeat;
  margin: 40px auto 0;
  ${media.midium`
    width: 600px;
    height: 382px;
    margin-bottom: 20px;
  `};
  ${media.tablet`
    width: 280px;
    height: 302px;
    background-image: url(${GraphMobile});
    margin: 40px auto 0;
  `};
`;

const GraphCaption = styled.div`
  margin-top: 20px;
  text-align: center;
  ${media.tablet`
    margin-top: 12px;
  `};
`;

const CaptionTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: -0.5px;
  text-align: center;
  color: #000000;
  ${media.tablet`
    font-size: 16px;
    line-height: normal;
    letter-spacing: -0.8px;
  `};
`;

const CaptionSubs = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.86;
  letter-spacing: -0.4px;
  text-align: center;
  color: #bbbcbc;
  ${media.tablet`
    line-height: normal;
    letter-spacing: -0.7px;
  `};
`;

const StepSection = styled(Section)`
  background-color: #f2f5f6;
  padding: 60px 20px 150px;
  ${media.midium`
    padding: 120px 20px 0;
  `};
  ${media.tablet`
    padding: 60px 0px 0;
  `};
`;

const StepFlexWrapper = styled.div`
  max-width: 1040px;
  width: 100%;
  text-align: center;
  margin: auto;
  display: flex;
  justify-content: space-between;
  ${media.midium`
    flex-direction: column;
  `};
`;

const StepSectionTitle = styled.div`
  display: none;
  ${media.midium`
    display: block;
    font-size: 46px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: -2px;
    text-align: center;
    color: #033e52;
    margin-bottom: 42px;
    br {
      display: none;
    }
  `};
  ${media.tablet`
    font-size: 30px;
    line-height: 1.4;
    letter-spacing: -1.4px;
    margin-bottom: 17px;
    br {
      display: block;
    }
  `};
`;

const StepTitle = styled.div`
  font-size: 46px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: -2px;
  color: #033e52;
  text-align: left;
  ${media.midium`
    display: none;
  `};
`;

const StepLeft = styled.div`
  padding-top: 107px;
  width: 100%;
  text-align: left;
  ${media.midium`
    padding: 0;
  `};
`;

const StepContextContainer = styled.div`
  margin-top: 82px;
  width: 100%;
  ${media.midium`
    margin-top: 20px;
    min-height: 247px;
  `};
  ${media.tablet`
    margin-top: 15px;
    padding: 0 20px;
  `};
`;

const TabButtons = styled.div`
  display: flex;
  width: 100%;
`;

const MobileHideSpan = styled.span`
  ${media.midium`
    display: none;
  `};
`;

const TabBtn = styled.div`
  width: 33.333%;
  border-bottom: solid 4px #ffffff;
  padding-bottom: 19px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
  letter-spacing: -0.4px;
  color: #000000;
  cursor: pointer;
  ${props =>
    props.active &&
    `
    border-color: #033e52;
    color: #033e52;
    font-weight: bold;
  `};
  ${media.tablet`
    padding-bottom: 15px;
    text-align: center;
  `};
`;

const MobileHide = styled.span`
  ${media.tablet`
    display: none;
  `};
`;

const TabContexts = styled.div`
  width: 100%;
  padding-top: 25px;
  ${media.tablet`
    padding-top: 20px;
  `};
`;

const HideBr = styled.br``;

const TabContext = styled.p`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
  letter-spacing: -0.4px;
  color: #000000;
  text-align: left;
  display: none;
  white-space: pre-wrap;
  word-break: keep-all;
  ${props => props.visible && 'display: block'};
  ${media.tablet`
    br {
      display: block;
    }
    ${HideBr} {
      display: none;
    }
  `};
`;

const StepRight = styled.div`
  position: relative;
  min-width: 450px;
  height: 650px;
  ${media.midium`
    display: flex;
    width: 100%;
    order: -1;
    height: 502px;
    overflow: hidden;
    min-width: unset;
    min-width: auto;
  `};
  ${media.tablet`
    height: 313px;
  `};
`;

const PhoneOutImage = styled.div`
  position: absolute;
  background-image: url(${PhoneImg});
  width: 100%;
  height: 100%;
  background-size: 515px 652px;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 3;
  ${media.midium`
    background-image: url(${PhoneMobileImg});
    width: 600px;
    height: 502px;
    left: 50%;
    transform: translateX(-50%);
    background-size: contain;
  `};
  ${media.tablet`
    width: 375px;
    height: 313px;
  `};
`;

const PhoneImageInnerContainer = styled.div`
  display: inline-block;
  position: absolute;
  top: 42px;
  left: 163px;
  width: 243px;
  height: 525px;
  vertical-align: middle;
  overflow: hidden;
  z-index: 1;
  .image-gallery-bullets {
    .image-gallery-bullet {
      display: none;
      img {
        object-fit: contain;
      }
    }
  }
  ${media.midium`
    position: relative;
    top: 50px;
    left: 50%;
    width: 286px;
    height: 452px;
    margin-left: -143px;
  `};
  ${media.tablet`
    top: 31px;
    width: 177.6px;
    height: 282px;
    margin-left: -88.8px;
  `};
`;

const FaqSection = styled(Section)`
  max-width: 1080px;
  width: 100%;
  padding: 180px 20px;
  text-align: left;
  margin: auto;
  ${media.midium`
    width: 100%;
    padding: 120px 20px 90px;
  `};
  ${media.tablet`
    width: 100%;
    padding: 80px 20px 90px;
  `};
`;

const FaqSectionTitle = styled.h1`
  font-size: 46px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: -0.9px;
  color: #033e52;
  ${media.tablet`
    font-size: 30px;
    line-height: 1.4;
    letter-spacing: -1.4px;
  `};
`;

const Faqs = styled.div`
  margin-top: 60px;
  border-top: 1px solid #d4d4d4;
  ${media.tablet`
    margin-top: 30px;
  `};
`;

const ToggleFaqBtn = styled.div`
  display: inline-block;
  background-image: url(${FaqOpenIcon});
  background-size: 22px 22px;
  background-repeat: no-repeat;
  min-width: 22px;
  width: 22px;
  height: 22px;
  object-fit: contain;
  cursor: pointer;
  ${media.tablet`
    display: none;
  `};
`;

const FaqTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.4px;
  color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  cursor: pointer;
  word-break: keep-all;
  ${media.tablet`
    font-size: 18px;
    letter-spacing: -0.3px;
  `};
`;

const FaqContent = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
  letter-spacing: -0.4px;
  color: #000000;
  text-align: left;
  white-space: pre-wrap;
  word-break: keep-all;
  display: none;
  & > div {
    width: 734px;
    padding: 10px 0 40px;
  }

  hr {
    margin: 0;
    border-color: #d4d4d4;
  }

  ${media.tablet`
    & > div {
      width: 100%;
      padding: 0 0 20px;
    }
  `};
`;

const Faq = styled.div`
  border-bottom: 1px solid #d4d4d4;
  hr {
    display: none;
  }
  ${props =>
    props.active &&
    `
    border: 0;
    hr {
      display: block;
    }
    ${ToggleFaqBtn} {
      background-image: url(${FaqCloseIcon});
      width: 20px;
      height: 20px;
      min-width: 20px;
      background-size: 20px 20px;
    }
    ${FaqContent} {
      display: block;
    }
    ${FaqTitle} {
      color: #033e52;
    }
  `};
`;

const MobileBottomCounselBtn = styled.button`
  display: none;
  ${media.tablet`
    display: none;
    z-index: 4;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px 0;
    background-color: #033e52;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.9px;
    text-align: center;
    color: #ffffff;
    border: 0;
    margin-bottom: 0;
    animation-name: BottomBtnSlideUp;
    animation-duration: 0.5s;
    &.show-btn {
      display: block;
    }
  `};

  @keyframes BottomBtnSlideUp {
    0% {
      transform: translateY(50%);
    }
    100% {
      transform: translateY(0%);
    }
  }
`;


const FirstSection = styled.div`
  padding-top: 121px;
  background-color: #ffffff;
  text-align: center;
  > div:first-of-type {
    padding: 0 20px;
  }
  ${media.tablet`
    padding-top: 63px;
    &.a_type {
      ${H1} {
        font-size: 24px;
        font-weight: normal;
        line-height: 1.33;
      }
      ${H2} {
        margin-top: 11px;
        line-height: 1.5;
      }
      ${ConsultBtn} {
        padding: 8px 24px;
        min-width: 0;
      }
    }
    &.b_type, &.c_type {
      ${H1} {
        font-size: 32px;
        font-weight: 500;
        line-height: 1.25;
        letter-spacing: -1.5px;
      }
      ${H2} {
        margin-top: 10px;
        line-height: 1.5;
      }
      ${ConsultBtn} {
        padding: 8px 27px;
        min-width: 0;
      }
    }
  `};
`;

const MobileSecondSection = styled.div`
  padding: 90px 0 40px;
  position: relative;
  overflow-x: hidden;
`;

const MobileSecondDesc = styled.div`
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: -1.4px;
  text-align: center;
  color: #033e52;
  margin-bottom: 20px;
  ${media.tablet`
    br:nth-of-type(2) {
      display: none;
    }
  `};
`;

const guideItems = [
  {
    original: `${Guide1}`,
  },
  {
    original: `${Guide2}`,
  },
  {
    original: `${Guide3}`,
  },
];

const stepItems = [
  {
    original: `${Step1}`,
  },
  {
    original: `${Step2}`,
  },
  {
    original: `${Step3}`,
  },
];

const FaqList = [
  {
    title: '오닥터는 어떤 서비스인가요?',
    content:
      '오닥터는 여러 병원에 방문하고 비교할 필요 없이, 한번에 여러 병원에게 상담을 받고 나에게 맞는 병원을 방문할 수 있는 온라인 상담실 입니다.\n기존의 의료 프로세스를 스마트하게 바꿔 고객과 병원의 시간 및 비용을 획기적으로 줄인 차세대 메디컬 서비스입니다.',
  },
  {
    title: '상담은 어떻게 진행되나요?',
    content:
      '‘상담받기’ 버튼을 통해 방문 가능한 지역과 간단한 상담 정보를 입력하시면 신청이 완료되며, 곧 오닥터 파트너 병원들의 상담결과를 받아보실 수 있습니다.\n상담을 진행한 병원 중 내가 원하는 병원을 선택하고, 가능한 방문 시간을 예약하면 치료가 시작됩니다.',
  },
  {
    title: '상담 비용을 따로 내야 하나요?',
    content:
      '오닥터는 완전 무료 서비스입니다.\n오닥터에서 무료로 상담과 예상 비용을 확인한 후, 방문한 병원에서 발생하는 치료비용만 지불하는 합리적인 시스템입니다.',
  },
  {
    title: '상담결과를 받기까지 얼마나 걸리나요?',
    content:
      '요청하신 상담은 파트너병원으로 즉시 전달되며, 실시간으로 상담 답변이 등록됩니다. \n다만 상담을 요청하신 시간이 병원 진료시간이 아닐 경우 답변 등록이 다소 늦어질 수 있습니다.\n만약 5일이 경과한 후에도 상담결과를 받아보지 못하신 경우 고객센터 (cs@odoctor.co.kr)로 문의주시기 바랍니다.',
  },
  {
    title: '상담해주는 병원은 어떤 곳인가요?',
    content:
      '오닥터의 파트너 병원은 직접 방문하여 전문성과 시설, 서비스등을 모두 검토한 병원들로 신뢰할 수 있는 병원입니다.',
  },
  {
    title: '지방에서도 이용할 수 있나요?',
    content:
      '오닥터는 전국에서 이용가능하며, 상담신청 시 희망 지역을 설정 할 수 있습니다.\n다만 아직 서비스 초기로, 파트너 병원이 많지 않은 일부 지역 병원의 수가 한정적인 점 양해 바랍니다.\n빠른 시일 내 파트너 병원을 확충할 수 있도록 하겠습니다.',
  },
  {
    title: '병원에서 상담내용과 다르게 치료비를 청구하면 어떻게 하나요?',
    content:
      '오닥터 상담 시 각 병원에서 제시하는 치료비는 입력하신 설명과 사진 등을 토대로 추산한 예상 치료비로, 방문 후 실물 진료 결과에 따라 치료비가 다소 조정될 수 있습니다.\n방문 병원에서 실 치료비가 조정될 경우 변동 내용에 대한 자세한 설명이 있을 예정이며, 오닥터 상담과 판이한 치료 과정과 비용이 발생할 시 고객센터 (cs@odoctor.co.kr)로 문의주시면 검토 후 안내 드리도록 하겠습니다.',
  },
];

const abTestingTitles = {
  default: {
    title: '1분이면 끝나는 상담신청!',
    subTitle: '우리동네 치과선생님들의 상담이 시작됩니다.',
    btnTitle: '상담 신청하기',
  },
  a: {
    title: '당신이 치료를 망설이는 동안<br /><b>1,788분</b>이 오닥터를 통해<br />평생 치과를 만났습니다.',
    subTitle: '치료 방법부터 비용까지,<br />우리 동네 치과들이 답변 대기중',
    btnTitle: '평생 치과 만나보기',
    className: 'a_type',
  },
  b: {
    title: '비싸고 아픈 치과 치료,<br />당연히 따져봐야죠.',
    subTitle: '우리 동네 착한 치과와<br />무료 상담부터 바로 예약까지.',
    btnTitle: '상담 받아나보기',
    className: 'b_type',
  },
  c: {
    title: '백번 검색하는 것보다<br />한번 물어보는게 낫죠',
    subTitle: '치료 방법부터 비용까지,<br />우리 동네 치과들이 알려줘요.',
    btnTitle: '참 쉬운 상담신청',
    className: 'c_type',
  },
  d: {
    title: '백번 검색하는 것보다<br />한번 물어보는게 낫죠',
    subTitle: '치료 방법부터 비용까지,<br />우리 동네 치과들이 알려줘요.',
    btnTitle: '상담신청 (심지어 무료...)',
    className: 'c_type',
  },
};


class Home extends React.Component {
  constructor(props) {
    super(props);

    // 홈에서 계정관련 모달 띄우는 로직.
    const params = queryString.parse(this.props.location.search);
    const action = params.q;
    const path = this.props.location.pathname;

    if (action === 'login') {
      this.props.history.push('/login');
    }

    if (action === 'resetpassword') {
      this.props.history.push('/resetpassword');
    }

    if (action === 'signup') {
      this.props.history.push('/signup');
    }

    if (!path || path === '/') {
      this.titles = abTestingTitles.default;
    }

    if (path === '/a') {
      this.titles = abTestingTitles.a;
    }

    if (path === '/b') {
      this.titles = abTestingTitles.b;
    }

    if (path === '/c') {
      this.titles = abTestingTitles.c;
    }

    if (path === '/d') {
      this.titles = abTestingTitles.d;
    }

    if (
      path !== '/a' && 
      path !== '/b' && 
      path !== '/c' && 
      path !== '/d'
    ) {
      this.titles = abTestingTitles.default;
    }

    // addressbar의 params를 지운다.
    // ab testing 때문에 잠시 disabled
    // window.history.pushState({}, document.title, '/');

    // this binding
    this.state = {
      scrollTop: 0,
      activeGuideIdx: 0,
      activeStepIdx: 0,
      activeFaqIdx: -1, // -1은 아무것도 선택되지 않은 상태
    };

    this.backgroundWiper = React.createRef();
    this.wiper = React.createRef();
    this.carousel = React.createRef();
    this.wayCarousel = React.createRef();
    this.handleScrollEvt = this.handleScrollEvt.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScrollEvt);
    window.addEventListener('resize', this.handleScrollEvt);
    this.handleScrollEvt();
  }

  focusActiveGuide = (currentIndex) => {
    this.setState({ activeGuideIdx: currentIndex });
  };

  focusActiveStep = (currentIndex) => {
    this.setState({ activeStepIdx: currentIndex });
  };

  handleScrollEvt = () => {
    const { scrollTop } = document.scrollingElement || document.documentElement;
    const windowHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const windowWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    const container = document.getElementById('image_container');
    const firstFigure = document.getElementById('first_figure');
    const contentContainer = document.getElementById('content_container1');
    const fixedMobileBtn = document.getElementById('fixed-mobile-btn');

    if (windowWidth < 640 || windowWidth === 640) {
      if (contentContainer && fixedMobileBtn) {
        if (contentContainer.offsetTop - 120 < scrollTop) {
          fixedMobileBtn.classList.add('show-btn');
        } else {
          fixedMobileBtn.classList.remove('show-btn');
        }
      }
    }

    if (isIeOrEdge) {
      return;
    }

    if (container && scrollTop < (windowHeight) + 1051) {
      container.style.top = 0;
    }

    if (container && scrollTop > (windowHeight) + 1051) {
      container.style.top = `${-1 * (scrollTop - (windowHeight * 2 + 1051) + windowHeight)}px`;
    }

    if (windowWidth < 640 || windowWidth === 640) {
      if (this.backgroundWiper && this.backgroundWiper.current) {
        // 배경
        this.backgroundWiper.current.style.webkitClipPath = 'none';
        this.backgroundWiper.current.style.clipPath = 'none';

        // 영역 padding, 사진 margin
        firstFigure.style.margin = '0 auto';
        container.style.padding = '120px 0 0';
      }

      // 두번째 영역 clip-path 컨트롤
      if (this.wiper && this.wiper.current) {
        this.wiper.current.style.webkitClipPath = 'none';
        this.wiper.current.style.clipPath = 'none';
      }

      return;
    }

    if (windowWidth < 985 || windowWidth === 985) {
      // 모바일 하단 상담신청 버튼 숨기기
      if (contentContainer && fixedMobileBtn) {
        fixedMobileBtn.style.display = 'none';
      }

      // 첫번째로 올라오는 배경 clip-path, 첫번째 영역 padding, 첫번째 폰 사진 margin 컨트롤
      if (this.backgroundWiper && this.backgroundWiper.current) {
        // 배경
        const backgroundWiperClipTop = 500 - scrollTop;
        this.backgroundWiper.current.style.webkitClipPath = `inset(${backgroundWiperClipTop}px 0 0)`;
        this.backgroundWiper.current.style.clipPath = `inset(${backgroundWiperClipTop}px 0 0)`;

        // 영역 padding, 사진 margin
        if (scrollTop > 500) {
          const paddingTop = 180 - (scrollTop - 500);
          const marginTop = 230 - (scrollTop - 500);
          container.style.padding = `${paddingTop > 0 ? paddingTop : 0}px 0 0`;
          container.firstChild.style.zIndex = '1';
          firstFigure.style.margin = `${marginTop > 0 ? marginTop : 0}px 0 0`;
        }

        if (scrollTop < 500) {
          container.style.padding = `${246}px 0 0`;
          container.firstChild.style.zIndex = '3';
          firstFigure.style.margin = `${260}px 0 0`;
        }
      }

      // 첫번째 배경 올라오고 난 후 첫번째 텍스트 display: block;
      if (contentContainer) {
        if (scrollTop < 700) {
          contentContainer.classList.remove('animation');
        }
        if (scrollTop > 700) {
          contentContainer.classList.add('animation');
        }
      }

      // 두번째 영역 clip-path 컨트롤
      if (this.wiper && this.wiper.current) {
        if (scrollTop < 1046) {
          this.wiper.current.style.webkitClipPath = `inset(${windowHeight}px 0 0)`;
          this.wiper.current.style.clipPath = `inset(${windowHeight}px 0 0)`;
        }

        if (scrollTop > 1046) {
          const wiperClipTop = windowHeight - (scrollTop - 1046);
          this.wiper.current.style.webkitClipPath = `inset(${wiperClipTop}px 0 0)`;
          this.wiper.current.style.clipPath = `inset(${wiperClipTop}px 0 0)`;
        }
      }
      return;
    }

    // 첫번째로 올라오는 배경 clip-path, 첫번째 영역 padding, 첫번째 폰 사진 margin 컨트롤
    if (this.backgroundWiper && this.backgroundWiper.current) {
      // 배경
      const backgroundWiperClipTop = 580 - scrollTop;
      this.backgroundWiper.current.style.webkitClipPath = `inset(${backgroundWiperClipTop}px 0 0)`;
      this.backgroundWiper.current.style.clipPath = `inset(${backgroundWiperClipTop}px 0 0)`;

      // 영역 padding, 사진 margin
      if (scrollTop > 580) {
        const paddingTop = 230 - (scrollTop - 580);
        const marginTop = 300 - (scrollTop - 580);
        container.style.padding = `${paddingTop > 0 ? paddingTop : 0}px 0 0`;
        container.firstChild.style.zIndex = '1';
        firstFigure.style.margin = `${marginTop > 0 ? marginTop : 0}px 0 0`;
      }

      if (scrollTop < 580) {
        container.style.padding = `${230}px 0 0`;
        container.firstChild.style.zIndex = '3';
        firstFigure.style.margin = `${300}px 0 0`;
      }
    }

    // 첫번째 배경 올라오고 난 후 첫번째 텍스트 display: block;
    if (contentContainer) {
      if (scrollTop < 700) {
        contentContainer.classList.remove('animation');
      }
      if (scrollTop > 700) {
        contentContainer.classList.add('animation');
      }
    }

    // 두번째 영역 clip-path 컨트롤
    if (this.wiper && this.wiper.current) {
      if (scrollTop < 1046) {
        this.wiper.current.style.webkitClipPath = `inset(${windowHeight}px 0 0)`;
        this.wiper.current.style.clipPath = `inset(${windowHeight}px 0 0)`;
      }

      if (scrollTop > 1046) {
        const wiperClipTop = windowHeight - (scrollTop - 1046);
        this.wiper.current.style.webkitClipPath = `inset(${wiperClipTop}px 0 0)`;
        this.wiper.current.style.clipPath = `inset(${wiperClipTop}px 0 0)`;
      }
    }
  };

  focusGuide = (idx) => {
    if (this.carousel && this.carousel.current) {
      this.carousel.current.slideToIndex(idx);
    }
  };

  focusStep = (step) => {
    if (this.wayCarousel && this.wayCarousel.current) {
      this.wayCarousel.current.slideToIndex(step);
    }
  };

  toggleFaq = (faqIdx) => {
    if (this.state.activeFaqIdx === faqIdx) {
      this.setState({ activeFaqIdx: -1 });
      return;
    }
    this.setState({ activeFaqIdx: faqIdx });
  };

  render() {
    return (
      <HomeContainer>
        <MobileBottomCounselBtn
          onClick={async () => {
            await userActivitySendSlack('mobile', 'in-step1');
            this.props.history.push({
              pathname: '/counsel',
              state: {
                mode: 'quick',
              },
            });
            logEvent({
              category: '메인',
              action: '클릭',
              label: '상담신청(하단)',
            });
          }}
          id="fixed-mobile-btn"
        >
          상담 신청하기
        </MobileBottomCounselBtn>
        <StickySection id={isIeOrEdge ? 'no-sticky' : ''}>
          <StickyWrapper
            windowHeight={
              window.innerHeight ||
              document.documentElement.clientHeight ||
              document.body.clientHeight
            }
          >
            <ImageContainer id="image_container">
              <Title>
                <H1>
                  1분이면 끝나는 상담신청!
                </H1>
                <H2>
                  우리동네 치과선생님들의 상담이 시작됩니다.
                </H2>
                <ConsultBtn
                  onClick={async () => {
                    await userActivitySendSlack('desktop', 'in-step1');
                    this.props.history.push({
                      pathname: '/counsel',
                      state: {
                        mode: 'quick',
                      },
                    });
                    logEvent({
                      category: '메인',
                      action: '클릭',
                      label: '상담신청',
                    });
                  }}
                >
                  상담 신청하기
                </ConsultBtn>
              </Title>
              <AbsoluteBackground
                ref={this.backgroundWiper}
                scrollTop={this.state.scrollTop}
              />
              <FigureWapper>
                <FirstFigure id="first_figure" />
              </FigureWapper>
              <ContentContainer1 id="content_container1">
                치료 방법과 기간, 비용까지<br />
                우리동네 치과 선생님을<br />
                오닥터에서 먼저 만나보세요
              </ContentContainer1>
              <Wiper ref={this.wiper}>
                <ContentWrapper>
                  우리 동네<br />
                  모든 치과를<br />{' '}
                  한눈에
                </ContentWrapper>
                <SecondFigure />
                <Oval className="blue" />
                <Oval className="pink" />
              </Wiper>
            </ImageContainer>
          </StickyWrapper>
        </StickySection>
        <MobileView>
          <FirstSection className={this.titles.className}>
            <div>
              <H1 dangerouslySetInnerHTML={{ __html: this.titles.title }} />
              <H2 dangerouslySetInnerHTML={{ __html: this.titles.subTitle }} />
              <div>
                <ConsultBtn
                  onClick={async () => {
                    await userActivitySendSlack('mobile', 'in-step1');
                    this.props.history.push({
                      pathname: '/counsel',
                      state: {
                        mode: 'quick',
                      },
                    });
                    logEvent({
                      category: '메인',
                      action: '클릭',
                      label: '상담신청',
                    });
                  }}
                >
                  <ConsultBtnSpan dangerouslySetInnerHTML={{ __html: this.titles.btnTitle }} />
                </ConsultBtn>
              </div>
            </div>
            <MobileFigureContainer>
              <MobileNavyBackground />
              <MobileFirstFigure />
              <MobileFirstDesc>
              치료 방법과 기간, 비용까지<br />
              우리동네 치과 선생님을<br />
              오닥터에서 먼저 만나보세요
              </MobileFirstDesc>
            </MobileFigureContainer>
            <MobileSecondSection>
              <MobileSecondDesc>
                우리 동네<br />
                모든 치과를<br />{' '}
                한눈에
              </MobileSecondDesc>
              <SecondFigure />
              <Oval className="blue" />
              <Oval className="pink" />
            </MobileSecondSection>
          </FirstSection>
        </MobileView>
        <WaySection>
          <WayTitle>
            오닥터,
            <br />
            <Bold>진료의 순서</Bold>를 바꿨습니다.
          </WayTitle>
          <WaySubTitle>
            이제 상담 먼저 받고 방문하세요. <br />
            우리동네 치과들의 소견과 예상비용을 한눈에 확인하고, <br />내 마음에 드는 치과를 선택하면 치료가 시작됩니다.
          </WaySubTitle>
          <WayCarouselWrapper>
            <ImageGallery
              ref={this.wayCarousel}
              items={stepItems}
              showThumbnails={false}
              showNav={false}
              showPlayButton={false}
              showFullscreenButton={false}
              autoPlay
              onSlide={this.focusActiveStep}
              slideInterval={5000}
              slideDuration={0}
            />
            <PhoneOuterImage />
          </WayCarouselWrapper>
          <WayLine>
            <Line className="animation" idx={this.state.activeStepIdx}>
              <div />
            </Line>
            <Dots>
              <Dot onClick={this.focusStep.bind(this, 0)} active />
              <Dot
                onClick={this.focusStep.bind(this, 1)}
                active={this.state.activeStepIdx > 0}
              />
              <Dot
                onClick={this.focusStep.bind(this, 2)}
                active={this.state.activeStepIdx === 2}
              />
            </Dots>
            <Steps>
              <Step onClick={this.focusStep.bind(this, 0)} active>
                오닥터 상담신청
              </Step>
              <Step
                onClick={this.focusStep.bind(this, 1)}
                active={this.state.activeStepIdx > 0}
              >
                치과 비교
              </Step>
              <Step
                onClick={this.focusStep.bind(this, 2)}
                active={this.state.activeStepIdx === 2}
              >
                선택한 치과 방문
              </Step>
            </Steps>
          </WayLine>
        </WaySection>
        <BenefitSection>
          <BenefitTitle>
            고객과 병원이 한곳에 <Br2 />모여 <br />
            <Bold>더욱 합리적인</Bold> <Br2 />오닥터
          </BenefitTitle>
          <BenefitSubTitle>
            고객과 병원은 서로를 찾기 위해 많은 시간과 비용을 소모하고
            있었습니다.
            <br />
            이제 오닥터에서 만나기로 약속하면서, 절약할 수 있는 것들이 아주
            많아졌죠.
            <br />
            오닥터로 절약한 시간과 비용은 더욱 투명한 치료비로 고객에게
            돌아갑니다.
            <br />
          </BenefitSubTitle>
          <div>
            <GraphInfo>
              <tbody>
                <tr>
                  <td>
                    <Color color="#dd5124" />
                    <GraphText>의사의 경력차이</GraphText>
                  </td>
                  <td>
                    <Color color="#e47450" />
                    <GraphText>치료 소재 차이</GraphText>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Color color="#eb977c" />
                    <GraphText>치아상태 (치료면수)</GraphText>
                  </td>
                  <td>
                    <Color color="#f1b9a7" />
                    <GraphText>위치 시설차이</GraphText>
                  </td>
                </tr>
              </tbody>
            </GraphInfo>
            <BenefitGraph />
            <GraphCaption>
              <CaptionTitle>골드크라운 (금니) 예시</CaptionTitle>
              <CaptionSubs>건강보험심사평가원 2018</CaptionSubs>
            </GraphCaption>
          </div>
        </BenefitSection>
        <StepSection>
          <StepSectionTitle>
            오닥터는
            <br />
            이렇게 이용하세요
          </StepSectionTitle>
          <StepFlexWrapper>
            <StepLeft>
              <StepTitle>오닥터는 이렇게 이용하세요</StepTitle>
              <StepContextContainer>
                <TabButtons>
                  <TabBtn
                    onClick={this.focusGuide.bind(this, 0)}
                    active={this.state.activeGuideIdx === 0}
                  >
                    진료 정보 입력
                  </TabBtn>
                  <TabBtn
                    onClick={this.focusGuide.bind(this, 1)}
                    active={this.state.activeGuideIdx === 1}
                  >
                    <MobileHideSpan>한눈에 보는 </MobileHideSpan>상담결과
                  </TabBtn>
                  <TabBtn
                    onClick={this.focusGuide.bind(this, 2)}
                    active={this.state.activeGuideIdx === 2}
                  >
                    <MobileHide>편리한</MobileHide> 예약시스템
                  </TabBtn>
                </TabButtons>
                <TabContexts>
                  <TabContext visible={this.state.activeGuideIdx === 0}>
                    임플란트, 충치치료, 심미치료, 교정치료 등 <HideBr />
                    치과 전문 플랫폼으로 모든 치과 진료 항목을 자세하게
                    담았습니다. <br />
                    필요한 진료, 선택만 하세요.
                  </TabContext>
                  <TabContext visible={this.state.activeGuideIdx === 1}>
                    오닥터의 검증된 치과선생님들이 보낸 소견과 예상 비용, <HideBr />
                    시설과 위치를 미리 확인하고 내게 맞는 병원을 선택할 수
                    있습니다.
                  </TabContext>
                  <TabContext visible={this.state.activeGuideIdx === 2}>
                    내가 선택한 병원과 방문 시간을 약속하세요. <br />
                    개인정보는 예약이 완료된 병원에게만 전달됩니다.
                  </TabContext>
                </TabContexts>
              </StepContextContainer>
            </StepLeft>
            <StepRight>
              <PhoneOutImage />
              <PhoneImageInnerContainer>
                <ImageGallery
                  ref={this.carousel}
                  items={guideItems}
                  showThumbnails={false}
                  showNav={false}
                  showPlayButton={false}
                  showFullscreenButton={false}
                  onSlide={this.focusActiveGuide}
                  slideInterval={2000}
                />
              </PhoneImageInnerContainer>
            </StepRight>
          </StepFlexWrapper>
        </StepSection>
        <FaqSection>
          <FaqSectionTitle>조금 더 알고 싶어요</FaqSectionTitle>
          <Faqs>
            {FaqList.map((faq, idx) => (
              <Faq key={faq.title} active={this.state.activeFaqIdx === idx}>
                <FaqTitle
                  onClick={() => {
                    this.toggleFaq(idx);
                    logEvent({
                      category: '메인',
                      action: '클릭',
                      label: 'FAQ',
                      value: idx,
                    });
                  }}
                >
                  <div>{faq.title}</div>
                  <ToggleFaqBtn />
                </FaqTitle>
                <FaqContent>
                  <div>{faq.content}</div>
                  <hr />
                </FaqContent>
              </Faq>
            ))}
          </Faqs>
        </FaqSection>
      </HomeContainer>
    );
  }
}

const HomeWithFuction = withRouter(Home);

export default HomeWithFuction;
