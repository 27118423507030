import React, { useState } from 'react';
import styled from 'styled-components';
import { compose, graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import withAlertModal from '../../utils/alerthoc';
import { AuthContext } from '../AuthProvider';
import { Flex } from '../atomic/atoms/Flex';
import { FlexGrid } from './EnhancedComponents';
import {
  isExistingUserMutation,
  loginUserByPhoneMutation,
  phoneNumberConfirmMutation,
  phonePinConfirmMutation, registerUserByPhoneMutation,
} from '../../graphql';
import withToast from '../../utils/toasthoc';
import withAuthModal from '../../utils/authmodalhoc';
import cameraImage from '../../images/camera.svg';
import iconDeletePhoto from '../../images/delete-photo.svg';
import StarbucksLogo from '../../images/starbucks-logo.jpg';
import { TxPhotoGuideModal } from '../form/PhotoGuideModal';


const StyledPhotoGuideButton = styled.button`
  color: #b1b3b2;
  font-size: 14px;
  padding: 0;
  letter-spacing: -.6px;
  position: absolute;
  top: -35px;  
  left: 0;
  text-decoration: underline;
  cursor: pointer;
`;


const StyledAddPhotoButton = styled(Flex)`
  width: inherit;
  height: 56px;
  background-color: #fff;
  border-radius: 4px;
  border: solid 1px #e1e3e2;
  justify-content: center;
  .text{
    font-size: 13px;
    letter-spacing: -.6px;
  }
  label{
    width: 100%;
    height: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  input[type="file"] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
  }
`;

const StyledPreviewWrap = styled.div`
  padding-top: 15px;
`;

const StyledPreview = styled.div`
  width: 100%;
  height: 103px;
  margin: 5px 0;
  background-image: url(${props => props.bg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledStarbucksBanner = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 64px;
  border-radius: 4px;
  background-color: #f5f7f7;
  background-image: url(${props => props.logo});
  background-repeat: no-repeat;
  background-size: 60px 46px;
  background-position: right bottom;
  padding:0 16px;
  margin-bottom: 20px;
  p{
    margin: 0;
  }
  .banner-title{
    font-weight: bold;
    font-size: 14px;
    letter-spacing: -.5px;
    margin-bottom: 2px;
  }
  .banner-description{
    color: #b1b3b2;
    font-size: 12px;
    letter-spacing: -.5px;
  }
`;

const PhotoComponent = ({
  onClick,
}) => {
  const savedPhoto = JSON.parse(localStorage.getItem('pData')).txImageUrls;
  const [files, setFiles] = useState(savedPhoto || []);
  const [isOpenTxPhotoGuideModal, setIsOpenTxPhotoGuideModal] = useState(false);

  return (
    <>
      <StyledPhotoGuideButton
        onClick={() => {
          // openOrthoPhotoGuideModal
          setIsOpenTxPhotoGuideModal(true);
        }}
      >
        사진 등록 가이드
      </StyledPhotoGuideButton>
      <div style={{ paddingTop: '50px' }}>
        <StyledStarbucksBanner
          logo={StarbucksLogo}
        >
          <div>
            <p className="banner-title">사진을 등록하고 스타벅스 기프트콘 받으세요!</p>
            <p className="banner-description">매월 100분을 선정하여 익월 3일 전달드립니다.</p>
          </div>
        </StyledStarbucksBanner>
        <StyledAddPhotoButton className="add-photo-button" onClick={onClick}>
          <label htmlFor="user-file-upload" className="user-file-upload">
            <img src={cameraImage} style={{ marginRight: '10px' }} alt="camera shape" />
            <div className="text">사진추가</div>
            <input
              type="file"
              accept="image/x-png,image/jpeg"
              id="user-file-upload"
              multiple
              onChange={(e) => {
                const tmp = [];
                const aa = [];
                _.forOwn(e.target.files, (v) => {
                  // tmp.push(URL.createObjectURL(v));
                  tmp.push({
                    blob: URL.createObjectURL(v),
                    file: v,
                  });

                  aa.push({
                    blob: URL.createObjectURL(v),
                    file: v,
                  });
                  // tmp.push(v);
                });
                setFiles([...files, ...tmp]);

                onClick({ value: [...files, ...tmp] }, aa);

                // var filea = new File([tmp[0]], "filename");
                // console.log(filea)
              }}
            />
          </label>
        </StyledAddPhotoButton>
      </div>
      <StyledPreviewWrap className="preview-wrap">
        <FlexGrid
          column
          items={files}
          cols={2}
          render={item => (
            <StyledPreview
              className="preview"
              bg={item.blob}
              onClick={() => {
                // 첨부한 사진 삭제
                const newFiles = files.filter(i => i !== item);
                setFiles(newFiles);
                onClick({ value: newFiles }, newFiles, 1);
              }}
            >
              <img src={iconDeletePhoto} alt="cross icon" />
            </StyledPreview>
          )}
        />
      </StyledPreviewWrap>
      <TxPhotoGuideModal
        isOpen={isOpenTxPhotoGuideModal}
        onClose={() => {
          setIsOpenTxPhotoGuideModal(false);
        }}
      />

    </>
  );
};

PhotoComponent.contextType = AuthContext;
// export default PhoneComponent;
const CounselUserInfoFormWithFunc = compose(
  graphql(isExistingUserMutation, {
    name: 'isExistingUser',
  }),
  graphql(phoneNumberConfirmMutation, {
    name: 'phoneNumberConfirm',
  }),
  graphql(phonePinConfirmMutation, {
    name: 'phonePinConfirm',
  }),
  graphql(loginUserByPhoneMutation, {
    name: 'loginUserByPhone',
  }),
  graphql(registerUserByPhoneMutation, {
    name: 'registerUserByPhone',
  }),
)(withAlertModal(withRouter(withToast(withAuthModal(PhotoComponent)))));
export default CounselUserInfoFormWithFunc;
