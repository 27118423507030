import React from 'react';
import styled from 'styled-components';
import HospitalCaseDetail from '../containers/HospitalCaseDetail';
import ScrollToTopOnMount from '../components/basic/ScrollToTopOnMount';
import { FormContainer } from '../components/basic/HtmlComponents';
import { media } from '../globalStyle';
import queryString from "query-string";

const HospitalDetailContainer = styled.div`
  padding: 40px 0;
  ${media.tablet`
    padding: 90px 0;
  `};
`;

class HsCase extends React.Component {
  render() {
    const { match, location } = this.props;
    const { id } = match.params; // case id
    const query = queryString.parse(this.props.location.search)
    return (
      <div>
        <ScrollToTopOnMount />
        <FormContainer>
          <HospitalDetailContainer>
            <HospitalCaseDetail
              from={location?.state?.from}
              id={id}
              {...query}
              queryString={this.props.location.search}
            />
          </HospitalDetailContainer>
        </FormContainer>
      </div>
    );
  }
}

export default HsCase;
