import React, { Component } from 'react';
import styled from 'styled-components';
import { media } from '../globalStyle';
import { SmVisibleBr } from './basic/HtmlComponents';

const HeaderWrapper = styled.div`
  margin: 0;
  @media (max-width: 800px) {
    flex-wrap: wrap;
  }

  ${media.tablet`
    padding: 38px 20px 0;
  `};
`;

const TitleContainer = styled.div`
  display: inline-block;
  margin-right: 10px;
  @media (max-width: 800px) {
    margin: 0;
    display: block;
  }
`;

const Title = styled.div`
  font-size: 34px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
  margin-bottom: 8px;
  ${media.tablet`
    font-size: 26px;
    line-height: 1.38;
    letter-spacing: -1.88px;
    margin: 0;
  `};
`;

const ModeChangeBtn = styled.button`
  width: 110px;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.58px;
  text-align: center;
  border: solid 1px #033e52;
  border-radius: 21px;  
  color: #ffffff;
  background-color: #033e52;
  padding: 11px 16px;
  font-weight: normal;
  z-index: 0;
  position: relative;
  :disabled {
    color: #033e52;
    background-color: #ffffff;  
    font-weight: bold;
    padding: 11px 19px;
    z-index: 1;
  }
  &:first-of-type {
    position: absolute;
    left: 0;
  }
  @media (max-width: 800px) {
    width: 90px;
    font-size: 12px;
    letter-spacing: -0.5px;
    padding: 10px 0;
    :disabled {
      padding: 10px 0;
    }
  }
`;

const ModeChangeWrapper = styled.div`
  display: none;
  float: right;
  width: 200px;
  border-radius: 21px;
  background-color: #033e52;
  position: relative;
  text-align: right;
  @media (max-width: 800px) {
    width: 170px;
    /* display: block; */
    display: none;
    float: none;
    margin-bottom: 60px;
  }
`;

const MobileProgressBar = styled.div`
  display: none;
  ${media.tablet`
    display: block;
    width: ${props => props.width};
    height: 2px;
    background-color: #dd4124;
    position: fixed;
    top: 58px;
    left: 0;
    z-index: 4;
  `};
`;

class SubHeader extends Component {
  render() {
    const {
      step,
      mode,
      phoneConfirmed,
      isOdoctorUser
    } = this.props;

    return (
      <div>
        {step < 4 && <MobileProgressBar width={`${step * 33.333}%`} />}
        <HeaderWrapper>
          {step === 1 && (
            <ModeChangeWrapper
              onClick={this.props.toggleQuickCounsel}
            >
              <ModeChangeBtn
                disabled={this.props.mode === 'quick'}
              >
                간편상담
              </ModeChangeBtn>
              <ModeChangeBtn
                disabled={this.props.mode !== 'quick'}
              >
                자세한상담
              </ModeChangeBtn>
            </ModeChangeWrapper>
          )}
          <TitleContainer>
            <Title>
              {step === 1
                && (mode !== 'quick' ? (
                  <span>
                    조금 더 자세하게
                    <br />
                    알려주실 수 있으신가요?
                  </span>
                ) : (
                  <span>
                    반갑습니다.
                    {' '}
                    <br />
                    어떤 치료가 필요하신가요?
                  </span>
                ))}
              {step === 2 && <span>쉿, 의사선생님께만 <SmVisibleBr />전달드릴게요</span>}
              {
                step === 3 && (
                  !phoneConfirmed ?
                    <span>병원의 답변을<br />어디로 알려드릴까요?</span> :
                    (
                      !isOdoctorUser ? <span>비밀스런 상담을 위해<br />이름과 비밀번호를 입력해주세요.</span> :
                        <span>이미 오닥터 회원이시네요!<br />비밀번호를 입력해주세요.</span>
                    )
                )
              }
              {step === 4 && <span>완료되었습니다. <br />조금만 기다려주세요</span>}
            </Title>
          </TitleContainer>

        </HeaderWrapper>
      </div>
    );
  }
}

export default SubHeader;
