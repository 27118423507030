import React, { Component } from 'react';
import styled from 'styled-components';
import TopMenu from '../containers/TopMenu';
import Footer from './Footer';
import { media } from '../globalStyle';

const FlexContainer = styled.div`
  display: flex;
`;

const AppBody = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
`;

const SiteContent = styled.div`
  height: auto;
  margin: 0 auto;
  flex-grow: 1;
  width: 760px;
  position: relative;
  background-color: #fff;
  ${media.miniDesktop`
    width: 100%;
  `};
`;

const Top = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`;

class AppLayout extends Component {
  render() {
    // 주의 : scrollto 함수는 AppLayout 처럼 상위 Component 일 때에만 render함수에 넣어야 함.
    window.scrollTo(0, 0);

    return (
      <FlexContainer>
        <AppBody>
          <Top>
            { this.props.counselHeader ? null : <TopMenu {...this.props} hideTopMenu={this.props.hideTopMenu} />}
            <SiteContent>{this.props.children}</SiteContent>
          </Top>
          <Footer hideMobileFooter={this.props.hideMobileFooter} />
        </AppBody>
      </FlexContainer>
    );
  }
}

export default AppLayout;
