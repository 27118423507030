import React from 'react';
import Pagination from 'rc-pagination';
import styled from 'styled-components';
import PrevCaret from '../../images/paging-pre-default.png';
import NextCaret from '../../images/paging-next-default.png';
import PrevActiveCaret from '../../images/paging-pre-select.png';
import NextActiveCaret from '../../images/paging-next-select.png';
import { media } from '../../globalStyle';

const Caret = styled.div`
  display: inline-block;
  width: 12px;
  height: 12px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${props => props.activeSrc});
  background-position-x: center;
  .rc-pagination > li.rc-pagination-disabled > & {
    background-image: url(${props => props.disabledSrc});
  }
`;

const StyledPaginationWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  .rc-pagination {
    display: block;
    text-align: center;
    margin: 0;
    li {
      margin: 0 4px;
      width: 32px;
      height: 32px;
      border-radius: 2.3px;
      border: 1px solid #d6d6d6;
      padding: 0;
      line-height: 32px;
      cursor: pointer;
      a {
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        text-align: center;
        color: #b2b4b2;
      }
      &:hover,
      &:active,
      &:focus {
        background-color: #ffffff;
        outline: none;
      }
      &.rc-pagination-item-active {
        border: 1px solid #d6d6d6;
        background-color: #ffffff;
        a {
          color: #033e52;
        }
      }
      &.rc-pagination-item-active:hover {
        border: 1px solid #d6d6d6;
        background-color: #ffffff;
        a {
          color: #033e52;
        }
      }
      &.rc-pagination-jump-prev,
      &.rc-pagination-jump-next {
        border: 0;
        width: 26px;
        min-width: 26px;
        margin: 0;
        &:hover:after {
          color: #033e52;
        }
      }
      ${media.tablet`
        width: 28px;
        height: 28px;
        line-height: 28px;
        a {
          font-size: 13px;
        }
      `};
    }
  }
`;

const StyledPagination = ({
  activePage, itemsCountPerPage, totalItemsCount, onChange,
}) => (
  <StyledPaginationWrapper>
    <Pagination
      className="ant-pagination"
      current={activePage}
      defaultPageSize={itemsCountPerPage}
      total={totalItemsCount}
      prevIcon={<Caret activeSrc={PrevActiveCaret} disabledSrc={PrevCaret} />}
      nextIcon={<Caret activeSrc={NextActiveCaret} disabledSrc={NextCaret} />}
      onChange={onChange}
      hideOnSinglePage
    />
  </StyledPaginationWrapper>
);

export default StyledPagination;
