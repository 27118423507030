import React from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

const GoogleMapComponent = withScriptjs(withGoogleMap(props => (
  <GoogleMap
    ref={props.onMapLoad}
    defaultZoom={12}
    defaultCenter={{ lat: 37.5326, lng: 127.024612 }}
    onIdle={props.onIdle}
    defaultOptions={{
      zoomControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_TOP,
      },
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
    }}
  >
    {props.children}
  </GoogleMap>
)));

// Hospital Detail 에 사용될 Single Marker Map
export const SingleMarkerMap = withScriptjs(withGoogleMap(props => (
  <GoogleMap
    defaultZoom={17}
    defaultCenter={{ lat: props.hospital.lat, lng: props.hospital.lng }}
  >
    <Marker key={props.hospital.id} position={{ lat: props.hospital.lat, lng: props.hospital.lng }} />
  </GoogleMap>
)));

export default GoogleMapComponent;
