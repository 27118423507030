import React from 'react';
import styled, { css } from 'styled-components';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { media } from '../globalStyle';
import PasswordInput from '../components/form/PasswordInput';
import FormInput from '../components/form/FormInput';
import { updatePasswordMutation } from '../graphql';
import { isValidPass } from '../utils/validationInput';
import withAlertModal from '../utils/alerthoc';
import { FormConfirmBtn, FormBtnContainer } from '../components/form/FormCommonComponent';

const visible = css`
  input {
    visibility: hidden;
  }
`;

const Container = styled.div`
  ${props => props.done && visible };
  padding: 40px 0 121px;
  width: 100%;
  max-width: 326px;
  margin: 50px auto 0;
  ${media.tablet`
    max-width: unset;
    max-width: auto;
    min-width: 320px;
    padding: 80px 20px 40px;
    margin: 0 auto;
  `};
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1.5px;
  color: #000000;
  ${media.tablet`
    font-size: 20px;
    letter-spacing: -0.6px;
  `};
`;

const SubTitle = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.8px;
  color: #000000;
  margin: 4px 0 30px;
`;

const InputContainer = styled.div`
  & ~ & {
    margin-top: 8px;
  }
`;

const StyledSubDescribe = styled.div`
  height: 40px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
  margin-bottom: 40px;
`;

const SubDescribe = ({done}) => (
  <StyledSubDescribe>
    { done ? <>비밀번호 변경이 완료되었습니다.<br />변경된 비밀번호로 로그인 하실 수 있습니다.</> : '새로운 비밀번호를 입력하세요.' }
  </StyledSubDescribe>

);

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      origpass: '',
      newpass: '',
      fireSubmitValidation: false,
      passwordError: null,
      done: false,
    };
  }

  handleSubmit = async () => {
    const {
      origpass,
      newpass,
      // newpassConfirm,
    } = this.state;

    if (
      !origpass ||
      !newpass
    ) {
      this.setState({ fireSubmitValidation: true });
      return;
    }

    try {
      const res = await this.props.updatePassword({
        variables: {
          origpass,
          newpass,
        },
      });

      if (res.data.updateUserPassword && res.data.updateUserPassword.ok) {
        this.setState({
          done: true,
        });
        return;
      }

      if (res.data.updateUserPassword.errors) {
        this.setState({ passwordError: `${res.data.updateUserPassword.errors[0].message}` });
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value, fireSubmitValidation: false, passwordError: null });
  };

  render() {
    const { hideTitle, className, close } = this.props;
    const {
      origpass,
      newpass,
      fireSubmitValidation,
      passwordError,
      done,
    } = this.state;

    return (
      <Container className={className} done={done}>
        {
          !hideTitle &&
          <>
            <Title>비밀번호 변경</Title>
            <SubTitle>새로운 비밀번호를 입력하세요.</SubTitle>
          </>
        }
        <SubDescribe done={done} />
        <div>
          <InputContainer>
            <FormInput
              type="password"
              placeholder="현재 비밀번호 (4-12자)"
              id="origpass"
              name="origpass"
              onChange={this.handleChange}
              value={origpass}
              fireSubmitValidation={fireSubmitValidation}
              errorMsg={passwordError}
            />
          </InputContainer>
          <InputContainer>
            <PasswordInput
              value={newpass}
              name="newpass"
              onChange={this.handleChange}
              placeholder="새 비밀번호 (4-12자)"
              textVisible
              fireSubmitValidation={fireSubmitValidation}
            />
          </InputContainer>
          <FormBtnContainer
            margin="30px 0 0"
          >
            {done ? (
              <FormConfirmBtn
                onClick={close}
              >
                확인
              </FormConfirmBtn>
            ) : (
              <FormConfirmBtn
                disabledView={
                  !origpass ||
                  !newpass ||
                  !isValidPass(newpass)
                }
                onClick={this.handleSubmit}
              >
              비밀번호 변경
              </FormConfirmBtn>
            )}
          </FormBtnContainer>
        </div>
      </Container>
    );
  }
}

export default graphql(updatePasswordMutation, {
  name: 'updatePassword',
})(withRouter(withAlertModal(ChangePassword)));
