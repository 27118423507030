/* todo : move to molecules */
import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { media } from '../../../../globalStyle';
import { CloseWindowButton } from '../Button/Button';
import { ButtonGroup, StretchButtonGroup } from '../../molecules/ButtonGroup';
import { GNBHeader, TitleHeader } from '../../molecules/Header';
import { SubTitle } from '../Title/Title';
import { LabelTitle } from '../Title/LabelTitle';
import { ProgressBar } from '../Shape/ProgressBar';

export const StyledModal = styled(Modal)`
  width: 460px;
  left: 50%;
  border-radius: 2px;  
  background-color: #ffffff;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  height: auto;
  transform: translate(-50%, -50%);
  top: 50%;
  position: absolute;
  border: 1px solid #eeeeee;
  padding: 30px;
  &:hover,
  &:focus {
    outline: none;
  }
  ${media.tablet`
    width: 280px;
    height: auto;
    left: 50%;
    top: 50%;
    margin: 0;
    padding: 20px;
    transform: translate(-50%, -50%);
  `};
`;

const StyledModalHeader = styled.div`
  margin: 0px 30px 20px 0;
`;

const StyledModalBody = styled.div`

`;

const StyledModalFooter = styled.div`

`;

const StyledBasicModalWrap = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.85);    
`;

const StyledBasicModalContent = styled.div`
  width: 460px;
  left: 50%;
  border-radius: 2px;
  background-color: #ffffff;   
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.16);
  height: auto;
  -webkit-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  top: 50%;
  position: absolute;
  border: 1px solid #eeeeee;
  padding: 30px;
  
  ${media.tablet`
    width: 280px;
    height: auto;
    padding: 20px;
  `}
  
  .b-modal-body{
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    letter-spacing: normal;
    color: #000;
    text-align: left;
    white-space: pre-line;
    word-break: keep-all;
  }
  .b-modal-footer{
    text-align: right;
    margin-top: 30px;
    .confirm-button, .cancel-button{
      cursor: pointer;
      border-radius: 4px;
      background-color: #ffffff;
      font-size: 18px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      -webkit-letter-spacing: -1px;
      -moz-letter-spacing: -1px;
      -ms-letter-spacing: -1px;
      letter-spacing: -1px;
      text-align: center;
      color: #033e52;
      border: 0;
      padding: 0;
     ${media.tablet`
      font-size: 18px;
     `}
    }
    
    .cancel-button{
      color: #8a8a8a;
      margin-left: 40px;
    }
    
  }
`;

const StyledBasicModalTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-letter-spacing: -1px;
  -moz-letter-spacing: -1px;
  -ms-letter-spacing: -1px;
  letter-spacing: -1px;
  color: #000000;
  word-wrap: break-word;
  word-break: keep-all;
  margin: 0px 30px 20px 0;
  ${media.tablet`
    font-size: 20px;
    font-weight: bold;
    line-height: 1.4;
  `}
`;

/**
 *
 * @param title
 * @param bodyRender
 * @param footerRender
 * @param buttonOption [op] fn: onClickOk, fn: onClickcancel, o
 * @returns {*}
 * @constructor
 */
export const BasicModalz = ({
  bodyRender, footerRender, buttonOption,
}) => (
  <StyledModal
    style={{
      overlay: {
        zIndex: 100,
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
      },
    }}
    ariaHideApp={false}
    isOpen
  >
    <TitleHeader closeBtn />
    <ProgressBar
      currStep={1}
    />
    <LabelTitle />
    <GNBHeader />
    <StyledModalHeader>
      <SubTitle title="알림" />
    </StyledModalHeader>
    <CloseWindowButton />
    <StyledModalBody>
      {bodyRender ? bodyRender() : '냉무<br><small>bodyRender()에 내용을 넣어주세요</small>'}
    </StyledModalBody>
    <StyledModalFooter>
      {footerRender ? footerRender() : <ButtonGroup {...buttonOption} />}
      <div>
        <StretchButtonGroup {...buttonOption} />
      </div>
    </StyledModalFooter>
  </StyledModal>
);

export const BasicModal = ({
  title, bodyDesc, okFunc, cancelFunc, isOpen,
}) => (isOpen
    && (
    <StyledBasicModalWrap>
      <StyledBasicModalContent>
        <div className="b-modal-top">
          <StyledBasicModalTitle>{title || '알림'}</StyledBasicModalTitle>
        </div>
        <div className="b-modal-body">{bodyDesc || '내용무'}</div>
        <div className="b-modal-footer">
          <button
            type="button"
            className="confirm-button"
            onClick={okFunc}
          >
            확인
          </button>
          <button
            type="button"
            className="cancel-button"
            onClick={cancelFunc}
          >
            취소
          </button>
        </div>


      </StyledBasicModalContent>
    </StyledBasicModalWrap>
    )
);
