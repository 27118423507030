import React from 'react';
import { withRouter } from 'react-router-dom';

import NavigationBar from '../components/basic/NavigationBar';
import withAlertModal from '../utils/alerthoc';

class TopMenu extends React.Component {
  render() {
    return (
      <NavigationBar
        {...this.props}
        hideTopMenu={this.props.hideTopMenu}
      />
    );
  }
}

export default withRouter(withAlertModal(TopMenu));
