import React from 'react';
import queryString from 'query-string';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { media } from '../globalStyle';
import { main } from '../data/sheets';
// import MainFeatureComponent from '../containers/MainFeature';
import MainLiveReivewComponent from '../containers/MainLiveReview';
import MainCaseComponent from '../containers/MainCase';
import FaqComponent from '../containers/Faq';
import { Section as StyledSection } from '../components/atomic/molecules/Section';
import { Component as EComponent } from '../components/ImporvedCounsel/EnhancedContainer';
import { StretchButton } from '../components/ImporvedCounsel/EnhancedComponents';
import IconPass from '../images/home/icon-pass.svg';
import MainOdoctorShortcut from '../containers/MainOdoctorShortcut';
import MainOdoctorLounge from '../containers/MainOdoctorLounge';
import IconWait from '../images/home/icon-wait.svg';
import IconChannel from '../images/home/icon-ch.svg';
import MainOdoctorChannel from '../containers/MainOdoctorChannel';
import { setSheet } from '../utils/loging';

const StyledMainBody = styled.div`
  .row {
    padding: 120px 20px;
    margin: 0;
    &.ml-0 {
      padding-left: 0;
    }
    &.mr-0 {
      padding-right: 0;
    }
    &.mt-0 {
      padding-top: 0;
    }
    &.mb-0 {
      padding-bottom: 0;
    }
    &.mb-30 {
      padding-bottom: 30px;
    }
    &.mt-60 {
      padding-top: 60px;
    }
    &.mb-60 {
      padding-bottom: 60px;
    }
    &.mt-90 {
      padding-top: 90px;
    }
    &.mb-90 {
      padding-bottom: 90px;
    }
    &.mt-120 {
      padding-top: 120px;
    }
    &.mb-120 {
      padding-bottom: 120px;
    }
    &.mt-148 {
      padding-top: 148px;
    }
    &.bg-f6 {
      background-color: #f6f6f6;
      .flex {
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 20px;
        ${media.tablet`
          margin-left: 0;
          margin-right: 0;
          padding-left: 0;
        `};
      }
    }
    &.bg-mf {
      padding: 0;
      background-size: contain;
    }
  }

  // NavigationBar 에서 margin-top: 161px 넣는 것 방지
  ${media.tablet`
    margin-top: 0 !important;
   `}
`;

const Section = styled(StyledSection)`
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  & ~ & {
    margin: 0;
  }
  & > h3 {
    font-size: 20px;
    line-height: 24px;
    margin: 0 0 30px 0;
    color: #000;
  }
  &.left-title {
    h3 {
      text-align: left;
    }
  }
  &.title-mb-20 {
    h3 {
      margin-bottom: 20px;
    }
  }
  
`;

const StyledCounselButtonWrap = styled.div`
  text-align: center;
  margin-top: 25px;
  height: 56px;
  >button{
    //max-width: 50%;
  }
`;

const StyledMoreButtonWrap = styled.div`
  text-align: center;
  height: 56px;
  margin-bottom: 5px;
  >button{
    //max-width: 50%;
  }
  &.case{
    margin-top: 35px;
  }
`;

const PartnerBanner = styled.div`
  width: 100%;
  border-radius: 4px;
  border: solid 1px #000000;
  background-color: #ffffff;
  padding: 18px 16px 18px 20px;
  font-family: Saira Semi Condensed;
  font-size: 14px;
  font-weight: 600;
  font-stretch: semi-condensed;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #000000;
  img {
    float: right;
    width: 22px;
    height: 16px;
    object-fit: contain;
  }
`;

const SectionImageTitle = styled.div`
  line-height: 28px;
  img {
    width: 28px;
    height: 28px;
    margin-right: 6px;
    vertical-align: bottom;
  }
  span,
  span > a {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.4px;
    text-align: right;
    color: #b1b3b2;
    float: right;
    text-decoration: underline;
    margin-top: 4px;
  }
`;

class Home extends React.Component {
  constructor(props) {
    super(props);
    const { location, history } = props;

    // 홈에서 계정관련 모달 띄우는 로직.
    const params = queryString.parse(location.search);
    const action = params.q;

    if (action === 'login') {
      history.push('/login');
    }

    if (action === 'resetpassword') {
      history.push('/resetpassword');
    }

    if (action === 'signup') {
      history.push('/signup');
    }

    // window.history.pushState({}, document.title, '/');
    localStorage.removeItem('pStep');
    localStorage.removeItem('pData');
  }

  render() {
    const { history } = this.props;

    const orthoStep = ['orthoWanna', 'orthoPart', 'ortho'];

    const simpleStep = [
      'selectAddress',
      'wantLevel',
      'isDetail',
    ];

    return (
      <StyledMainBody id="main-body">
        {/* {Main} */}
        <div className="row main mt-148 mb-0">
          <Section>
            <EComponent
              main
              {...main}
              selectedDataSet={{ txItems: [] }}
              more={onClick => (
                <StyledMoreButtonWrap className="more-btn">
                  <StretchButton
                    white
                    onClick={onClick}
                  >
                    {'더보기'}
                  </StretchButton>
                </StyledMoreButtonWrap>
              )}
              doCounselButton={(selected) => {
                const num = selected.length;
                const processStep = simpleStep.slice();

                // 상담신청 버튼
                return (
                  <StyledCounselButtonWrap>
                    <StretchButton
                      disabled={!num}
                      onClick={() => {
                        // 교정치료 항목 찾고,
                        // 교정치료를 선택한경우 step에 교정치료 문진과정을 unshift() 해서 앞쪽에 넣어줌
                        // ['orthoWanna','orthoPart','ortho']
                        // JSON.stringify(selected).match(/ort/gi) && processStep.unshift(...orthoStep);
                        const isOrtho = JSON.stringify(selected).match(/ort/gi);
                        if (isOrtho) {
                          processStep.unshift(...orthoStep);
                        }

                        setSheet({
                          name: '상담시작',
                          step: 0,
                          isTx: selected.length - !!isOrtho,
                          isOrtho: !!isOrtho,
                          isDetail: false,
                          data: selected.join(','),
                        });

                        // localStorage에 저장
                        localStorage.setItem(
                          'pStep',
                          JSON.stringify(processStep),
                        );
                        history.push('/counsel');
                      }}
                    >
                      <span style={{ fontWeight: 600 }}>
                        {num > 0 && `${num}개의 `}
                        {'상담신청'}
                      </span>
                    </StretchButton>
                  </StyledCounselButtonWrap>
                );
              }}
            />
          </Section>
        </div>

        {/* Counsel Cases */}
        <div className="row mt-120 mb-0">
          <Section
            title="최근 상담사례"
          >
            <MainCaseComponent r />
            <StyledMoreButtonWrap
              className="more-btn case"
            >
              <StretchButton
                white
                onClick={() => {
                  history.push('/case');
                }}
              >
                {'342개 상담사례 전체보기'}
              </StretchButton>
            </StyledMoreButtonWrap>
          </Section>
        </div>

        {/* {OdoctorShortcut} */}
        <div className="row mt-120 mb-120 ml-0 mr-0">
          <Section>
            <MainOdoctorShortcut />
          </Section>
        </div>
        {/* {liveReview} */}
        <div className="row bg-f6 mt-90 mb-90">
          <Section title="오닥터 고객들의 후기">
            <MainLiveReivewComponent r />
          </Section>
        </div>
        {/* {OdoctorFeatures} */}
        {/* <div className="row mt-0 mb-120">
          <Section>
            <MainFeatureComponent r />
          </Section>
        </div> */}
        {/* {Odoctor Lounge} */}
        <div className="row mt-120 mb-120">
          <Section
            className="left-title title-mb-20"
            title={(
              <SectionImageTitle>
                <img src={IconWait} />
                {'오닥터 대기실'}
              </SectionImageTitle>
            )}
          >
            <MainOdoctorLounge />
          </Section>
        </div>
        {/* {Odoctor Channel} */}
        <div className="row mt-0 mb-120">
          <Section
            className="left-title title-mb-20"
            title={(
              <SectionImageTitle>
                <img src={IconChannel} />
                {'채널 오닥터'}
                <span>
                  <a
                    href="https://www.youtube.com/channel/UCrdnbZS7Hp_lV1s_I-Br3FA"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {'전체보기'}
                  </a>
                </span>
              </SectionImageTitle>
            )}
          >
            <MainOdoctorChannel />
          </Section>
        </div>
        {/* {faq} */}
        <div className="row mt-0 mb-120">
          <Section className="left-title" title="자주 묻는 질문">
            <FaqComponent />
          </Section>
        </div>
        {/* partner banner */}
        <div className="row mt-0 mb-30">
          <Section>
            <PartnerBanner
              onClick={() => {
                window.open('https://hospital.odoctor.co.kr/', '_blank');
              }}
            >
              {'Odoctor partner'}
              <img src={IconPass} width="100%" alt="오닥터 배너 이미지" />
            </PartnerBanner>
          </Section>
        </div>
      </StyledMainBody>
    );
  }
}

export default withRouter(Home);
