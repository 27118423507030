import React, { Component } from 'react';
import styled from 'styled-components';
import { Col } from '../basic/HtmlComponents';
import { therapyInfoById } from '../form/therapyInfo';
import { getOrthoPartName, getPriceFormat } from '../../utils/req-utils';
import { media } from '../../globalStyle';
import { getAgeGroupName } from '../../utils/getAge';
import { draftData as data } from '../../data';

const H4 = styled.h4`
  margin-bottom: 10px;
  margin-top: 20px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.71;
  letter-spacing: -0.5px;
  color: #033e52;
  ${media.tablet`
    font-size: 13px;
  `};
`;

const LabelDiv = styled.div`
  display: inline-block;
  width: 120px;
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: -0.5px;
  color: #000000;
  ${media.tablet`
    font-size: 14px;
  `};
`;

const ResultSpan = styled.span`
  line-height: 1.71;
  white-space: pre-wrap;
  word-break: break-all;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.5px;
  color: #000000;
  ${media.tablet`
    font-size: 14px;
  `};
`;

const PriceText = styled.span`
  font-size: 16px;
  line-height: 1.71;
  color: #000000;
`;

const PhotoContainer = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-top: 4px;
`;

const Photo = styled.img`
  width: 68px;
  height: 42px;
  margin-right: 6px;
  border: solid 1px #e0e0e0;
`;

const Row = styled.div`
  margin-bottom: 6px;
  &.location{
    span:nth-child(odd)::before{
      content: ' / '
    }
  }
`;

const Hr = styled.hr`
  margin: 24px 0 24px;
  border-top: 1px solid #f1f1f1;
  ${media.tablet`
    margin: 24px 0 20px;
  `};
`;

const HrBold = styled.hr`
  margin: 24px 0;
  border-top: 2px solid #033e52;
  ${media.tablet`
    margin: 30px 0;
    border-top: 1px solid #000000;
  `};
`;

export default class ReqDoneDetail extends Component {
  render() {
    const {
      getMyReq,
      implant,
      cavity,
      aesthetic,
      general,
      gum,
      facial,
      getMyReq: { wantLevel },
    } = this.props;

    return (
      <React.Fragment>
        <HrBold />
        {(getMyReq.reqType === 'tx' || getMyReq.reqType === 'all') &&
          (getMyReq.txItems.length > 0 ||
            getMyReq.txImageUrls.length > 0) && (
            <div>
              <H4>치과치료</H4>

              {implant.length > 0 && (
                <Row>
                  <LabelDiv>임플란트</LabelDiv>
                  <Col>
                    {implant.map((item, idx) => (
                      <ResultSpan key={item.itemcode}>
                        {therapyInfoById[item.itemcode].viewName}{' '}
                        {item.unit > 1 ? item.unit : ''}
                        {idx !== implant.length - 1 && ', '}
                      </ResultSpan>
                    ))}
                  </Col>
                </Row>
              )}
              {cavity.length > 0 && (
                <Row>
                  <LabelDiv>충치치료</LabelDiv>
                  <Col>
                    {cavity.map((item, idx) => (
                      <ResultSpan key={item.itemcode}>
                        {therapyInfoById[item.itemcode].viewName}{' '}
                        {item.unit > 1 ? item.unit : ''}
                        {idx !== cavity.length - 1 && ', '}
                      </ResultSpan>
                    ))}
                  </Col>
                </Row>
              )}
              {aesthetic.length > 0 && (
                <Row>
                  <LabelDiv>미용, 심미치료</LabelDiv>
                  <Col>
                    {aesthetic.map((item, idx) => (
                      <ResultSpan key={item.itemcode}>
                        {therapyInfoById[item.itemcode].viewName}{' '}
                        {item.unit > 1 ? item.unit : ''}
                        {idx !== aesthetic.length - 1 && ', '}
                      </ResultSpan>
                    ))}
                  </Col>
                </Row>
              )}
              {general.length > 0 && (
                <Row>
                  <LabelDiv>일반치료</LabelDiv>
                  <Col>
                    {general.map((item, idx) => (
                      <ResultSpan key={item.itemcode}>
                        {therapyInfoById[item.itemcode].viewName}{' '}
                        {item.unit > 1 ? item.unit : ''}
                        {idx !== general.length - 1 && ', '}
                      </ResultSpan>
                    ))}
                  </Col>
                </Row>
              )}
              {gum.length > 0 && (
                <Row>
                  <LabelDiv>잇몸치료</LabelDiv>
                  <Col>
                    {gum.map((item, idx) => (
                      <ResultSpan key={item.itemcode}>
                        {therapyInfoById[item.itemcode].viewName}{' '}
                        {item.unit > 1 ? item.unit : ''}
                        {idx !== gum.length - 1 && ', '}
                      </ResultSpan>
                    ))}
                  </Col>
                </Row>
              )}
              {facial.length > 0 && (
                <Row>
                  <LabelDiv>구강(턱/얼굴)치료</LabelDiv>
                  <Col>
                    {facial.map((item, idx) => (
                      <ResultSpan key={item.itemcode}>
                        {therapyInfoById[item.itemcode].viewName}{' '}
                        {item.unit > 1 ? item.unit : ''}
                        {idx !== facial.length - 1 && ', '}
                      </ResultSpan>
                    ))}
                  </Col>
                </Row>
              )}
              {getMyReq.txImageUrls.length > 0 && (
                <Row>
                  <LabelDiv>등록된 사진</LabelDiv>
                  <Col>
                    <PhotoContainer>
                      {getMyReq.txImageUrls.map((image, idx) => (
                        <Photo src={image} key={`image-tx-${idx}`} />
                      ))}
                    </PhotoContainer>
                  </Col>
                </Row>
              )}
            </div>
          )}
        {(getMyReq.reqType === 'ortho' || getMyReq.reqType === 'all') &&
          (getMyReq.orthoItems.length > 0 ||
            getMyReq.orthoWanna.length > 0 ||
            getMyReq.orthoImageUrls.length > 0) && (
            <div>
              <H4>교정치료</H4>
              {getMyReq.orthoItems &&
                getMyReq.orthoItems.length > 0 && (
                  <Row>
                    <LabelDiv>교정 부위</LabelDiv>
                    <Col>
                      {getMyReq.orthoItems.map((orthoItem, idx) => (
                        <ResultSpan key={`part_${orthoItem.part}`}>
                          {getOrthoPartName(orthoItem.part)}
                          {idx !== getMyReq.orthoItems.length - 1 && ', '}
                        </ResultSpan>
                      ))}
                    </Col>
                  </Row>
                )}
              {getMyReq.orthoItems &&
                getMyReq.orthoItems.length > 0 && (
                  <Row>
                    <LabelDiv>희망 교정</LabelDiv>
                    <Col>
                      <ResultSpan
                        key={`item_${getMyReq.orthoItems[0].itemcode}`}
                      >
                        {
                          therapyInfoById[getMyReq.orthoItems[0].itemcode]
                            .viewName
                        }
                      </ResultSpan>
                    </Col>
                  </Row>
                )}
              {getMyReq.orthoWanna.length > 0 && (
                <Row>
                  <LabelDiv>희망 개선 부분</LabelDiv>
                  <Col>
                    {getMyReq.orthoWanna.map((want, idx) => (
                      <ResultSpan key={`wanna-${want}`}>
                        {want}
                        {idx !== getMyReq.orthoWanna.length - 1 && ', '}
                      </ResultSpan>
                    ))}
                  </Col>
                </Row>
              )}
              {getMyReq.orthoImageUrls.length > 0 && (
                <Row>
                  <LabelDiv>등록된 사진</LabelDiv>
                  <Col>
                    <PhotoContainer>
                      {getMyReq.orthoImageUrls.map((image, idx) => (
                        <Photo src={image} key={`image-ortho-${idx}`} />
                      ))}
                    </PhotoContainer>
                  </Col>
                </Row>
              )}
            </div>
          )}

        <Hr />
        {getMyReq.desc && (
          <Row>
            <H4>자세한 상담</H4>
            <ResultSpan>{getMyReq.desc}</ResultSpan>
            <Hr />
          </Row>
        )}

        {(getMyReq.gender || getMyReq.ageGroup) && (
          <Row>
            <H4>기본정보</H4>
            {getMyReq.gender && (
              <Row>
                <LabelDiv>성별</LabelDiv>
                <Col>
                  <ResultSpan>
                    {getMyReq.gender === 'female' ? '여자' : '남성' }
                  </ResultSpan>
                </Col>
              </Row>
            )}
            {getMyReq.ageGroup && (
              <Row>
                <LabelDiv>연령대</LabelDiv>
                <Col>
                  <ResultSpan>
                    {getAgeGroupName(getMyReq.ageGroup)}
                  </ResultSpan>
                </Col>
              </Row>
            )}
          </Row>
        )}

        {getMyReq.directReq ? (
          <Row>
            <H4>선택한 병원</H4>
            <ResultSpan>{getMyReq.directReq.hospital.name}</ResultSpan>
          </Row>
        ) : (
          <Row className='location'>
            <H4>희망지역</H4>
            {
              getMyReq.locations.map( (lo, idx) => (
                <ResultSpan key={idx}>{lo.siName} {lo.gunguName}</ResultSpan>
              ))
            }
          </Row>
        )}

        <Row>
          <H4>치료의향</H4>
          <Col style={{ verticalAlign: 'top' }}>
            {data.wantLevel.filter((d) => (
              wantLevel === d.value
            )).map((d, idx) => (
                <ResultSpan key={idx}>{d.title.replace(/<b>|<\/b>/gi, '')}</ResultSpan>
              )
            )}
          </Col>
        </Row>

        {/* <H4>희망치료비</H4>
        {(getMyReq.reqType === 'tx' || getMyReq.reqType === 'all') && (
          <Row>
            <LabelDiv>치과치료</LabelDiv>
            {getMyReq.txMaxPrice ? (
              <ResultSpan>
                <PriceText>{getPriceFormat(getMyReq.txMaxPrice)}</PriceText>
                {' 만원'}
              </ResultSpan>
            ) : (
              <ResultSpan>
                {getMyReq.carePrice === 'dontcare' ? '상관없음' : '없음'}
              </ResultSpan>
            )}
          </Row>
        )} */}
        {(getMyReq.reqType === 'ortho' || getMyReq.reqType === 'all') && (
          <Row>
            <LabelDiv>교정치료</LabelDiv>
            {getMyReq.orthoMaxPrice ? (
              <ResultSpan>
                <PriceText>{getPriceFormat(getMyReq.orthoMaxPrice)}</PriceText>
                {' 만원'}
              </ResultSpan>
            ) : (
              <ResultSpan>
                {getMyReq.carePrice === 'dontcare' ? '상관없음' : '없음'}
              </ResultSpan>
            )}
          </Row>
        )}

        {!getMyReq.directReq && getMyReq.considerTags && getMyReq.considerTags.length > 0 && (
          <Row>
            <H4>
              상담시 고려사항
            </H4>
            <Col style={{ verticalAlign: 'top' }}>
              {getMyReq.considerTags.map((tag, idx) => (
                <ResultSpan key={`considerTag_${tag}`}>
                  {tag}
                  {idx !== getMyReq.considerTags.length - 1 && ', '}
                </ResultSpan>
              ))}
            </Col>
          </Row>
        )}
      </React.Fragment>
    );
  }
}
