import React from 'react';
import { withRouter, Route, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import NoticeLeftMenu from '../components/Manual/NoticeLeftMenu';
import Notice from './Manual/Notice';
import NoticeDetail from './Manual/NoticeDetail';
import Faq from './Manual/Faq';
import Terms from './Manual/Terms';
import PrivacyPolicy from './Manual/PrivacyPolicy';
import { media } from '../globalStyle';

const Container = styled.div`
  padding: 40px 20px;
  max-width: 1080px;
  width: 100%;
  margin: auto;
  ${media.tablet`
    padding: 80px 0 40px;
  `};
`;

const Title = styled.div`
  margin-bottom: 40px;
  font-size: 34px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
  ${media.tablet`
    padding: 0 20px;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -1.7px;
    color: #000000;
  `};
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-beteween;
  ${media.tablet`
    display: block;
  `};
`;

const RightContainer = styled.div`
  width: 100%;
  margin: 0 0 0 73px;
  max-width: 738px;
  ${media.tablet`
    margin: 0 auto;
    padding: 20px;
  `};
`;

class Manual extends React.Component {
  render() {
    const { match } = this.props;

    return (
      <Container>
        <Title>이용 안내</Title>
        <Content>
          <NoticeLeftMenu />
          <RightContainer>
            <Route
              path={match.url}
              exact
              render={() => (
                <Redirect
                  to={{
                    pathname: '/manual/faq',
                  }}
                />
              )}
            />
            <Route path={`${match.url}/notice`} exact component={Notice} />
            <Route
              path={`${match.url}/notice/:id`}
              exact
              render={props => (
                <NoticeDetail {...this.props} id={props.match.params.id} />
              )}
            />
            <Route path={`${match.url}/faq`} exact component={Faq} />
            <Route path={`${match.url}/terms`} exact component={Terms} />
            <Route path={`${match.url}/privacypolicy`} exact component={PrivacyPolicy} />
          </RightContainer>
        </Content>
      </Container>
    );
  }
}

export default withRouter(Manual);
