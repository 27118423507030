import React from 'react';
import styled from 'styled-components';
import { media } from '../../../../globalStyle';

const StyledLabelTitle = styled.div`
  color: #fff;
  background-color: #033e52;
  width: 100%;
  height: 100%;
  padding:5px;
  text-align: center;  
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: -1px;
  word-wrap: break-word;
  word-break: keep-all;
  ${media.tablet`
    font-size: 20px;
    font-weight: bold;
    line-height: 1.4;
  `};
`;

const LabelTitle = ({ children, className }) => (
  <StyledLabelTitle className={className}>
    { children || '제목' }
  </StyledLabelTitle>
);

export { LabelTitle };
