import React from 'react';
import styled from 'styled-components';

const StyledHorizonLine = styled.hr`
  border-top: 1px solid #e4e4e4;
`;

// const StyledProgressThumb = styled.div`
//   height: 100%;
//   width: ${props => props.pct}%;
//   background-color: ${props => props.color || '#1b6d85'};
//   transition:width 0.3s;
// `;

const SectionHr = ({ className }) => (
  <StyledHorizonLine className={className} />
);

export { SectionHr };
