import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { media } from '../../globalStyle';
import CloseImg from '../../images/ic-close-24.svg';

export const StyledModal = styled(Modal)`
  width: 460px;
  left: 50%;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  height: auto;
  transform: translate(-50%, -50%);
  top: 50%;
  position: absolute;
  border: 1px solid #eeeeee;
  padding: 30px;
  &:hover,
  &:focus {
    outline: none;
  }
  ${media.tablet`
    width: 280px;
    height: auto;
  `};
`;

export const ModalTitle = styled.div`
  font-size: 26px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: -.5px;
  color: #000000;
`;

export const ModalContentContainer = styled.div`
  margin: 20px 0 30px;
`;

export const ModalMessage = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.64;
  letter-spacing: normal;
  color: #000000;
`;

export const ModalCloseBtnImg = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
  top: 42px;
  right: 30px;
  position: absolute;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  ${media.tablet`
    top: 32px;
    right: 20px;
  `};
`;

export const ModalCloseBtn = ({ onClick, ...rest }) => (
  <ModalCloseBtnImg onClick={onClick} src={CloseImg} alt="closeImg" {...rest} />
);

export const ModalBtnContainer = styled.div`
  text-align: right;
`;

export const ModalConfirmBtn = styled.button`
  background-color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #033e52;
  border: 0;
  padding: 0;
  &:hover,
  &:focus {
    outline: none;
  }
`;

export const ModalCancleBtn = styled(ModalConfirmBtn)`
  color: #bbbcbc;
  margin-right: 30px;
`;


export default {};
