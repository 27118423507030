import React from 'react';
import { compose, graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import {
  Marker,
} from 'react-google-maps';
import { allHospitalsQuery } from '../graphql';
import StyledPagination from '../components/basic/StyledPagination';
import GoogleMapComponent from '../components/basic/GoogleMapComponent';
import HospitalListItems from '../components/basic/HospitalListItems';
import LocationTartgetIcon from '../images/tracker.png';
import withToast from '../utils/toasthoc';

const LeftSide = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 400px;
  bottom: 0;
  height: 100%;
`;

const RightSide = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 400px;
  height: 100%;
  background: #f6f6f6;
`;

const Container = styled.div`
  position: relative;
  padding: 40px 20px;
  max-width: 1080px;
  width: 100%;
  height: 640px;
  margin: auto;
`;

const HosListContainer = styled.div`
  min-height: 565px;
  padding: 4px;
  & > div:not(:first-child) {
    margin-top: 4px;
  }
`;

const HospitalNullMsg = styled.div`
  padding: 15px 4px;
  text-align: center;
`;

const CurrentIcon = styled.img`
  position: absolute;
  top: 15px;
  left: 15px;
  cursor: pointer;
`;

const HospitalReloadBtn = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  cursor: pointer;
  text-align: center;
  padding: 8px;
  background: #34bcd7;
  color: #fff;
`;

const ITEMS_PER_PAGE = 8;
class HospitalList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedHospitalId: null,
      queryLatStart: null,
      queryLatEnd: null,
      queryLngStart: null,
      queryLngEnd: null,
    };

    this.googleMap = null;
  }

  onMapIdle = () => {
    if (this.googleMap) {
      window.setTimeout((this.onMapChanged), 1500);
    }
  }

  getGeoLocation = () => {
    const self = this;
    if (navigator.permissions) {
      navigator.permissions
        .query({ name: 'geolocation' })
        .then((result) => {
          if (result.state === 'denied') {
            self.props.toast('위치추적을 허용해주세요', 'center');
            return;
          }
        });
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        self.googleMap.panTo({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        self.loadHospitalByMapBounds();
      });
    }
  }

  focusHospital = (hospital) => {
    this.setState({ focusedHospitalId: hospital.id });
  }

  handlePageChange = (page) => {
    const { allHospitals, loading } = this.props;
    const {
      queryLatStart,
      queryLatEnd,
      queryLngStart,
      queryLngEnd,
    } = this.state;
    if (!loading && allHospitals) {
      if (allHospitals.pagination.currentPage !== page) {
        this.props.loadMoreEntries(page, queryLatStart, queryLatEnd, queryLngStart, queryLngEnd);
      }
    }
  };

  loadHospitalByMapBounds = () => {
    if (this.googleMap) {
      const mapBounds = this.googleMap.getBounds();
      const latStart = mapBounds.getSouthWest().lat();
      const latEnd = mapBounds.getNorthEast().lat();
      const lngStart = mapBounds.getSouthWest().lng();
      const lngEnd = mapBounds.getNorthEast().lng();
      this.props.refetch({
        perPage: ITEMS_PER_PAGE,
        currentPage: 1,
        latStart,
        latEnd,
        lngStart,
        lngEnd,
      });
      this.setState({
        queryLatStart: latStart,
        queryLatEnd: latEnd,
        queryLngStart: lngStart,
        queryLngEnd: lngEnd,
      });
    }
  }

  render() {
    const { allHospitals, loading } = this.props;
    return (
      <Container>
        <LeftSide>
          <GoogleMapComponent
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCxRFDWtsni7fh7H2qqpv8RlbJGTOdvrfc&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: '100%' }} />}
            containerElement={<div style={{ height: '100%' }} />}
            mapElement={<div style={{ height: '100%' }} />}
            onMapLoad={(googleMap) => {
              this.googleMap = googleMap;
            }}
            onIdle={this.onMapIdle}
          >
            <CurrentIcon src={LocationTartgetIcon} onClick={this.getGeoLocation} />
            {!loading &&
              allHospitals.hospitals.length > 0 &&
              allHospitals.hospitals.map(hospital => (
                <Marker
                  key={hospital.id}
                  position={{ lat: hospital.lat, lng: hospital.lng }}
                  onClick={this.focusHospital.bind(this, hospital)}
                />
              ))}
            <HospitalReloadBtn onClick={this.loadHospitalByMapBounds}>현재 지도에서 다시 검색하기</HospitalReloadBtn>
          </GoogleMapComponent>
        </LeftSide>
        <RightSide>
          {!loading && (
            <div>
              {allHospitals && allHospitals.hospitals && allHospitals.hospitals.length > 0 ? (
                <div>
                  <HosListContainer>
                    {allHospitals.hospitals.map(h => (
                      <HospitalListItems
                        key={`HospitalListItem_${h.id}`}
                        onClick={() =>
                          this.props.history.push(`/hospitals/${h.id}`)
                        }
                        photos={h.photos}
                        name={h.name}
                        address={h.address}
                        hid={h.id}
                        focused={this.state.focusedHospitalId === h.id}
                        refetchQueries={[
                          {
                            query: allHospitalsQuery,
                            variables: {
                              perPage: ITEMS_PER_PAGE,
                              currentPage: allHospitals.pagination.currentPage,
                            },
                          },
                        ]}
                      />
                    ))}
                  </HosListContainer>
                  <StyledPagination
                    activePage={allHospitals.pagination.currentPage}
                    itemsCountPerPage={ITEMS_PER_PAGE}
                    totalItemsCount={allHospitals.pagination.total}
                    pageRangeDisplayed={3}
                    onChange={this.handlePageChange}
                  />
                </div>
              ) : (
                <HospitalNullMsg>병원을 찾을 수 없습니다.</HospitalNullMsg>
              )}
            </div>
          )}
        </RightSide>
      </Container>
    );
  }
}

export default compose(graphql(allHospitalsQuery, {
  name: 'allHospitals',
  options: () => ({
    variables: {
      perPage: ITEMS_PER_PAGE,
      currentPage: 1,
    },
  }),
  props: ({ allHospitals: { loading, allHospitals, fetchMore, refetch } }) => ({
    loading,
    allHospitals,
    loadMoreEntries(page, latStart, latEnd, lngStart, lngEnd) {
      return fetchMore({
        variables: {
          currentPage: page,
          latStart,
          latEnd,
          lngStart,
          lngEnd,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return previousResult;
          }
          return Object.assign({}, previousResult, {
            allHospitals: fetchMoreResult.allHospitals,
          });
        },
      });
    },
    refetch,
  }),
}))(withToast(withRouter(HospitalList)));
