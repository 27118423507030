import React from 'react';
import styled from 'styled-components';
import RouteHead from '../../components/basic/RouteHeader';
import TermsData from '../../data/TermsData';

const TermsContent = styled.div`
  white-space: pre-wrap;
  word-break: break-all;
  font-size: 18px;
  text-align: left;
  color: #484848;
  @media (max-width: 576px) {
    font-size: 13px;
  }
`;

const Terms = () => (
  <div>
    <RouteHead>이용약관</RouteHead>
    <TermsContent>
      {TermsData}
    </TermsContent>
  </div>
);

export default Terms;
