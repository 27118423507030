import React, { Component } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import { Card } from '../molecules/Card';

const StyledSlider = styled(Slider)`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: #000000;
  .slick-list {
    padding: 0 0;
    margin-right: -20px;
    margin-left: -20px;
    .slick-track{
      margin-left: 20px;
    }
  }
  .slick-slide {
    width: 237px;
    margin-right: 10px;
  }
  .card-title {    
    font-weight: bold;
    line-height: 1.71;
    letter-spacing: -0.44px;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 0;
    .orange {
      line-height: 1.71;
      letter-spacing: normal;
      color: #dd4124;
      font-family: Saira Semi Condensed;
      font-size: 14px;
      font-weight: 600;
      font-stretch: semi-condensed;
    }
  }
  .card-text {
    line-height: 1.43;
    letter-spacing: -.5px;
    color: #b1b3b2;
    margin: 0;
  } 
`;

const ContentDoorImg = styled.img`
  display: block;
  width: 237px;
  height: 133px;
  border-radius: 4px;
`;

const CardWrapper = styled.div`
  display: inline-block;
`;

export const Slide = ({ data = [] }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    className: 'odoc-slide',
    arrows: false,
  };

  return (
    <StyledSlider {...settings}>
      {data.map((content, idx) => (
        <CardWrapper key={`${idx}_${content.url}`} onClick={() => window.open(`${content.url}`)}>
          <Card
            width="100%"
            height="auto"
            imgRender={() => <ContentDoorImg src={content.img} />}
            title={content.title}
          >
            {content.desc}
          </Card>
        </CardWrapper>
      ))}
    </StyledSlider>
  );
};

export default Slide;
