import React from 'react';
import styled from 'styled-components';
import { media } from '../../../globalStyle';

const Container = styled.div`
  max-width: 1080px;
  margin: 0 auto;
  padding: 40px 20px;
  ${media.miniDesktop`
    max-width: 100%;
    padding: 118px 20px 40px;
  `};
  
`;

export default ({ children }) => (<Container>{children}</Container>);
