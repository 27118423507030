import React from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import { locationGunguQuery } from '../../graphql';
import { FlexGrid, CreateButton } from './EnhancedComponents';

const StyledButton = styled.div`
  margin: 5px 0;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center;
`;

const CityComponent = ({ onClick }) => {
  const siName = JSON.parse(localStorage.getItem('pData')).addressRegion;

  return (
    <Query
      query={locationGunguQuery}
      variables={{
        siName,
      }}
    >
      {({ loading, error, data }) => {
        if (loading) return null;
        if (error) return `Error! ${error.message}`;

        if (loading && data && data.locationGungu) {
          // this.setState({ cityList: gunGu.data.locationGungu });
        }

        // 가나다 정렬
        const sortGungu = data.locationGungu.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));

        const OPTION = sortGungu.map(
          op => (
            {
              ...op,
              active: false,
              value: op.name,
            }
          ),
        );

        const selectedData = localStorage.getItem('pData');

        return ( // Todo : rebuiild , FlexGrid가 component안의 FelxGrid와 중복됨 정리되야함
          <div>
            <FlexGrid
              column
              items={OPTION}
              cols={3}
              render={item => (
                <StyledButton>
                  {
                    CreateButton(() => { onClick(item); }, // onClickHandler
                      'textButton', // buttonType,
                      item.name,
                      item.description,
                      item.image,
                      // selectedData.replace(/\"/gi, '').match(new RegExp('^' + item.value, 'gi')))
                      selectedData.match(`"${item.value}`))
                  }
                </StyledButton>
              )}
            />
          </div>
        );
      }}
    </Query>
  );
};

export default CityComponent;
