import React from 'react';
import styled from 'styled-components';
import { InlineDiv } from '../basic/HtmlComponents';
import { media } from '../../globalStyle';

const CheckBoxContainer = styled.div`
  padding: 10px 0;
  margin: 0 -6px;
  ${media.tablet`
    padding: 14px 0;
    margin: 0 -7px;
  `};
`;

const CheckboxInput = styled.input`
  display: none;
  &:checked {
    + label {
      font-weight: bold;
      color: #ffffff;
      border-color: #033e52;
      background-color: #033e52;
    }
  }
`;

const CheckboxLabel = styled.label`
  width: 160px;
  height: 52px;
  border-radius: 26px;
  border: solid 1px #bbbcbc;
  background-color: #fff;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -1px;
  text-align: center;
  color: #bbbcbc;
  padding-top: 14px;
  cursor: pointer;
  margin: 6px;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  ${media.tablet`
    width: 135px;
    height: 52px;
    font-size: 13px;
    padding-top: 0;
    line-height: 52px;
  `};
`;

class OrthoPartSelectFormContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this); // Lifting state up
  }

  handleClick(part, e) {
    const { name } = e.target;
    const parts = this.props.part;
    const newOrthoParts = parts;

    if (parts.indexOf(part) > -1) {
      // list에 같은 교정 부위가 존재하면 삭제
      newOrthoParts.splice(newOrthoParts.indexOf(part), 1);
    } else {
      // list에 같은 교정 부위가 존재하지 않을 때, 부위의 상하 여부에 따라 삭제하고 추가
      if (name === 'upper') {
        if (parts.indexOf('AU') > -1) {
          newOrthoParts.splice(newOrthoParts.indexOf('AU'), 1);
        } else if (parts.indexOf('PU') > -1) {
          newOrthoParts.splice(newOrthoParts.indexOf('PU'), 1);
        }
      }

      if (name === 'down') {
        if (parts.indexOf('AD') > -1) {
          newOrthoParts.splice(newOrthoParts.indexOf('AD'), 1);
        } else if (parts.indexOf('PD') > -1) {
          newOrthoParts.splice(newOrthoParts.indexOf('PD'), 1);
        }
      }
      newOrthoParts.push(part);
    }

    this.props.onPartChange(newOrthoParts);
  }

  render() {
    const { part } = this.props;

    return (
      <CheckBoxContainer>
        <InlineDiv>
          <CheckboxInput
            onChange={this.handleClick.bind(this, 'PU')}
            type="checkbox"
            name="upper"
            id="PU"
            value="PU"
            checked={part ? part.indexOf('PU') > -1 : false}
          />
          <CheckboxLabel htmlFor="PU">윗니 부분교정</CheckboxLabel>
        </InlineDiv>
        <InlineDiv>
          <CheckboxInput
            onChange={this.handleClick.bind(this, 'AU')}
            type="checkbox"
            name="upper"
            id="AU"
            value="AU"
            checked={part ? part.indexOf('AU') > -1 : false}
          />
          <CheckboxLabel htmlFor="AU">윗니 전체교정</CheckboxLabel>
        </InlineDiv>
        <InlineDiv>
          <CheckboxInput
            onChange={this.handleClick.bind(this, 'PD')}
            type="checkbox"
            name="down"
            id="PD"
            value="PD"
            checked={part ? part.indexOf('PD') > -1 : false}
          />
          <CheckboxLabel htmlFor="PD">아랫니 부분교정</CheckboxLabel>
        </InlineDiv>
        <InlineDiv>
          <CheckboxInput
            onChange={this.handleClick.bind(this, 'AD')}
            type="checkbox"
            name="down"
            id="AD"
            value="AD"
            checked={part ? part.indexOf('AD') > -1 : false}
          />
          <CheckboxLabel htmlFor="AD">아랫니 전체교정</CheckboxLabel>
        </InlineDiv>
      </CheckBoxContainer>
    );
  }
}

export default OrthoPartSelectFormContainer;
