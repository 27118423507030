import React, { Component } from 'react';
import MyProfileContainer from '../../containers/MyPage/MyProfileContainer';

class MyProfile extends Component {
  render() {
    return (
      <MyProfileContainer {...this.props} />
    );
  }
}

export default MyProfile;
