import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { Flex } from '../atomic/atoms/Flex';
import { AuthContext } from '../AuthProvider';
import { StretchButton } from './EnhancedComponents';
import withAuthModal from '../../utils/authmodalhoc';

const StyledPhoneInputContainer = styled(Flex)`
  flex-wrap: nowrap;
  margin-bottom: 10px;
`;

const StyledPhoneInputWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  border-radius: 4px;
  border: solid 1px #e1e3e2;
  &.error {
    border-color: #dd4124;  
  }
  & + button {
    font-size: 13px;
    color: #fff;
    min-width: 100px;
    margin-left: 5px;
    height: 56px;
    border-radius: 4px;
    background-color: #033e52;
    &:disabled{
      background-color: #e1e3e2;    
    }
    &.re-send-pin{
      border: solid 1.5px #033e52;
      background-color: #ffffff;
      color: #000;
    }
  }
`;


const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  border: 0;
  outline: 0;
  font-size: 18px;
  letter-spacing: -.6px;
  padding: 0 14px;
  &.fs-13{
    font-family: 'Saira Semi Condensed';
    font-size: 13px;
    font-weight: normal;
    font-stretch: semi-condensed;
    font-style: normal;
    letter-spacing: normal;
    color: #000000;
  }
`;

const StyledPhotoGuideButton = styled.button`
  color: #b1b3b2;
  font-size: 12px;
  padding: 0;
  letter-spacing: -.6px;
  text-decoration: underline;
  cursor: pointer;
`;

const MemberPasswordComponent = ({ onClick, openAuthModal }) => {
  // 이미 가입한 전화번호 유저
  // 입력 받은 비밀 번호로 로그인만 진행하고,
  // progress 화면에서 submit() 담당
  // const inputPW = useRef(null);
  const [inputPW, setInputPm] = useState('');
  const [loginFail, setLoginFail] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const contextLogin = useContext(AuthContext);

  const login = async ({ phonenum, password }) => {
    try {
      // login
      return await contextLogin.login(phonenum, password);
    } catch (error) {
      return error;
    }
  };

  const tryLogin = () => {
    const phonenum = JSON.parse(localStorage.getItem('pData')).phone;
    const password = inputPW;
    setButtonDisabled(true);
    // 1 로그인 시도
    // 로그인이 되면 다음 페이지로 이동
    login({ phonenum, password })
      .then(r => r)
      .then((res) => {
        if (res.ok) {
          // 로그인 성공 이동
          setLoginFail(false);
          onClick({ value: true });
        } else {
          setLoginFail(true);
          setButtonDisabled(false);
          // 로그인 실패
        }
      });
  };

  return (
    <div>
      <StyledPhoneInputContainer>
        {/* 번호 입력 */}
        <StyledPhoneInputWrap
          className={`${loginFail && 'error'}`}
        >
          <StyledInput
            onChange={(e) => {
              setInputPm(e.target.value);
            }}
            type="password"
            maxLength={12}
            className="fs-13"
            placeholder="4-12자"
          />
        </StyledPhoneInputWrap>

        <div style={{
          position: 'fixed', bottom: 0, left: 0, width: '100%', height: '64px',
        }}
        >
          <StretchButton
            className="fs-14"
            fixed
            disabled={inputPW.length < 4 || buttonDisabled}
            onClick={tryLogin}
          >
            상담신청 완료
          </StretchButton>
        </div>
      </StyledPhoneInputContainer>
      <StyledPhotoGuideButton
        onClick={() => {
          openAuthModal('resetpassword');
        }}
      >
        비밀번호를 잊으셨나요?
      </StyledPhotoGuideButton>
    </div>
  );
};

MemberPasswordComponent.contextType = AuthContext;
export default withAuthModal(MemberPasswordComponent);
