import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import Slider from 'react-slick';

import { media } from '../globalStyle';
import { Card } from '../components/atomic/molecules/Card';
import { StarWithPoint } from '../components/atomic/molecules/StarRating';
import { liveReviewList } from '../data/index';
import { Flex, FlexItem } from '../components/atomic/atoms/Flex';

const ExtendedFlex = styled(Flex)`
  margin-right: -20px;
  ${media.miniDesktop`
    flex-direction: column;
    margin-right: 0;
  `};
`;

const ExtendedFlexItem = styled(FlexItem)`
  flex: 0 0 33.333333%;
  ${media.miniDesktop`
    width: 100%;
  `};
`;

const StyledSlider = styled(Slider)`
  .slick-list {
    height: 221px;
    padding: 0 0;
    margin-right: -20px;
    margin-left: -20px;
    .slick-track{
      margin-left: 20px;
    }
  }
  .slick-slide {
    width: 100%;
    max-width: calc(100vw - 40px);
    margin-right: 10px;
  }
  .slick-dots {
    bottom: -16px;
    li {
      margin: 0 7px;
      width: 6px;
      height: 6px;
      button {
        width: 6px;
        height: 6px;
        padding: 0;
      }
      button:before {
        width: 6px;
        height: 6px;
        opacity: 1;
        color: #e1e3e2;
        font-size: 6px;
        line-height: 6px;
      }
    }
    li.slick-active {
      button:before {
        opacity: 1;
        color: #033e52;
      }
    }
  }
`;

const StyledCardPanel = styled.div`
  margin-right: 20px;
  max-width: 333px;
  height: 204px;
  border-radius: 8px;
  box-shadow: 2px 8px 13px -2px rgba(0,0,0,0.2);
  background-color: #fff;
  padding: 20px;
  p{
    font-size: 14px;
  }
  .card-title{
    font-weight: bold;
    padding-bottom: 10px;
  }
  .card-text{
    letter-spacing: -0.7px;
    color: #b1b3b2;
  }
  .star-box{
    height: 45px;
    padding-top: 8px;
    margin-bottom: 30px;
    .star-point{
      font-size: 30px;
      margin: 0 10px 0 0;    
    }
    * {
      cursor: default;
    }
  }
  ${media.miniDesktop`
    max-width: none;
    width: 100%;
    margin-bottom: 20px;
  `};
`;

const NewStyledCardPanel = styled.div`
  //max-width: 333px;
  height: 201px;
  border-radius: 4px;
  box-shadow: 6px 7px 10px -4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 20px;
  p{
    font-size: 14px;
  }
  .star-box{
    height: 24px;
    margin-bottom: 30px;
    .star-point{
      margin: 0 6px 0 0;
      span {
        font-size: 20px;
      }
    }
    * {
      cursor: default;
    }
  }
  .card-title {
    font-size: 14px;
    letter-spacing: -0.7px;
    color: #b1b3b2;
    margin-bottom: 10px;
    b {
      font-weight: normal;
    }
  }
  .card-text {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: -0.7px;
    color: #000000;
    margin-bottom: 0;
  }
  ${media.miniDesktop`
    max-width: none;
    width: 100%;
    margin-bottom: 20px;
  `};
`;

const NewReview = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    variableWidth: false,
    className: 'odoc-slide',
    arrows: false,
    responsive: [
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          variableWidth: true,
          dots: true,
        },
      },
    ],
  };
  return (
    <StyledSlider {...settings}>
      {
        _.sampleSize(liveReviewList, 5).map((review, idx) => (
          <NewStyledCardPanel key={`${idx}_${review.name}`}>
            <Card
              imgRender={() => (
                <div className="star-box">
                  <StarWithPoint
                    ahead
                    size={16}
                    selectedNum={Number(review.rating).toFixed(1)}
                  />
                </div>
              )}
              width="100%"
              height="inherit"
              title={review.name}
            >
              {review.comment}
            </Card>
          </NewStyledCardPanel>
        ))
      }
    </StyledSlider>
  );
};

export default (props) => {
  const { r } = props;
  return r ? (
    <NewReview />
  ) : (
    <ExtendedFlex>
      {
        _.sampleSize(liveReviewList, 3).map((review, idx) => (
          <ExtendedFlexItem key={idx}>
            <StyledCardPanel>
              <Card
                imgRender={() => (
                  <div className="star-box">
                    <StarWithPoint
                      ahead
                      size={20}
                      selectedNum={Number(review.rating).toFixed(1)}
                    />
                  </div>
                )}
                width="100%"
                height="inherit"
                title={review.name}
              >
                {review.comment}
              </Card>
            </StyledCardPanel>
          </ExtendedFlexItem>
        ))
      }
    </ExtendedFlex>
  );
};
