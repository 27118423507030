import React, { Component } from 'react';
import styled from 'styled-components';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import withAlertModal from '../../utils/alerthoc';
import withAuthModal from '../../utils/authmodalhoc';
import { logout } from '../../utils/auth';
import { MyPageRouteHead } from '../../components/basic/RouteHeader';
import NameInput from '../../components/form/NameInput';
import { terminateUserMutation, updateUserInfoMutation } from '../../graphql';
import PhonenumInput from '../../components/form/PhonenumInput';
import { marginProps } from '../../components/basic/HtmlComponents';
import { FormConfirmBtn } from '../../components/form/FormCommonComponent';
import PhoneConfirmInput from '../../components/form/PhoneConfirmInput';
import { isValidName } from '../../utils/validationInput';
import { media } from '../../globalStyle';

const ProfileInputRow = styled.div`
  display: flex;
  margin-right: 15px;
  margin-bottom: 10px;
  align-items: flex-start;
  ${media.tablet`
    display: block;
    margin: 0;
    & ~ & {
      margin-top: 24px;
    }
  `};
`;
const ProfilePhotoInputRow = styled(ProfileInputRow)`
  margin-top: 20px;
  margin-bottom: 0;
`;

const ProfileInputTitle = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
  width: 120px;
  text-align: left;
  line-height: 48px;
  ${media.tablet`
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -1px;
    color: #000000;
    margin-bottom: 8px;
  `};
`;

const PrifilePhotoInputTitle = styled(ProfileInputTitle)`
  line-height: 20px;  
`;

const ProfileInputTextContainer = styled.div`
  max-width: 326px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  line-height: 38px;
  div,
  input {
    width: 100%;
  }
  ${media.tablet`
    max-width: unset;
    max-width: auto;
    font-size: 12px;
  `};
`;

const PrifilePhotoInputContainer = styled(ProfileInputTextContainer)`
  line-height: 20px;
`;

const PhoneConfirmInputContainer = styled.div`
  max-width: 326px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  line-height: 38px;
  ${media.tablet`
    max-width: unset;
    max-width: auto;
    font-size: 12px;
  `};
`;

const UpdateButton = styled.button`
  min-width: 100px;
  height: 48px;
  background-color: #033e52;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  text-align: center;
  color: #ffffff;
  ${marginProps}
`;

const UpdatePasswordButton = styled(UpdateButton)`
  width: 160px;
  height: 48px;
  background-color: #033e52;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  text-align: center;
  color: #ffffff;
  ${marginProps}
  ${media.tablet`
    width: 140px;
  `};
`;

const TerminateWrapper = styled.div`
  /* TODO : 탈퇴기능 마무리, 일단 display: none 으로 해놓음. */
  /* display: none; */
  text-align: right;
`;

const ProfileBtnContainer = styled.div`
  width: 240px;
  height: 52px;
  margin: 30px auto 0;
  ${media.tablet`
    width: 100%;
  `};
`;

const TerminateButton = styled.a`
  cursor: pointer;
  text-decoration: underline;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.9px;
  text-align: right;
  color: #000000;
  &:active, &:hover, &:link, &:visited {
    text-decoration: underline;
    color: #000000;
  }
`;

const Container = styled.div`
  padding: 0 0 30px;
  border-bottom: 1px solid #000000;
  margin-bottom: 12px;
`;

const FileUploadBtn = styled.u`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -1px;
  color: #033e52;
  line-height: 20px;
  cursor: pointer;
`;

class MyProfileContainer extends Component {
  constructor(props) {
    super(props);

    const { username, phonenum } = { ...props.me };
    this.state = {
      username: username || '',
      phonenum: phonenum || '',
      phoneEditable: false,
      fireSubmitValidation: false,
      phoneErrorMsg: '',
      phoneConfirmed: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  onPhoneConfirmed = () => {
    this.setState({ phoneEditable: false, phoneConfirmed: true });
  };

  onPhoneDuplicated = () => {
    this.setState({
      phoneErrorMsg: '이미 가입된 핸드폰 번호입니다.',
      phoneConfirmed: false,
    });
  };

  terminateUserClick = async () => {
    const response = await this.props.terminateUser({
      variables: {
        reason: '',
      },
    });

    if (response.data && response.data.terminateUser) {
      if (response.data.terminateUser.ok) {
        this.openTerminateUserConfirmed();
      }
    }
  };

  openTerminateUserConfirmed = () => {
    this.props.openAlert({
      title: '회원 탈퇴 완료',
      message: '오닥터를 이용해주셔서 감사합니다.',
      showConfirmBtn: true,
      confirmBtnName: '확인',
      textAlign: 'left',
      confirmBtnOnClick: () => {
        logout();
        document.location.href = '/';
      },
    });
  }

  handleSubmit = async () => {
    const { username, phonenum, phoneConfirmed } = this.state;
    const { me } = this.props;

    if (
      !username ||
      !phonenum ||
      !phonenum.match(/^\d+$/) ||
      !(phonenum.length === 10 || phonenum.length === 11)
    ) {
      this.setState({ fireSubmitValidation: true });
      return;
    }

    if (me.phonenum !== phonenum && !phoneConfirmed) {
      this.setState({ phoneErrorMsg: '휴대폰 번호 인증이 필요합니다.' });
      return;
    }

    if (!isValidName(username)) {
      return;
    }

    try {
      const resp = await this.props.updateUserInfo({ variables: { username, phonenum } });
      if (!resp.data.updateUserInfo) throw new Error('updateUserInfo');
    } catch (error) {
      this.props.openAlert({
        title: '알림',
        message: '프로필 수정 실패',
        showConfirmBtn: true,
        confirmBtnName: '확인',
        confirmBtnOnClick: this.props.closeAlert,
      });
      return;
    }

    this.props.openAlert({
      title: '프로필 수정',
      message: '프로필 수정이 완료되었습니다.',
      showConfirmBtn: true,
      confirmBtnName: '확인',
      confirmBtnOnClick: this.props.closeAlert,
    });
  };

  handleInputChange({ target }) {
    const { name, value } = target;
    this.setState({
      [name]: value,
      phoneErrorMsg: '',
      fireSubmitValidation: false,
    });
  }

  render() {
    const {
      fireSubmitValidation,
      phoneEditable,
      username,
      phonenum,
      phoneConfirmed,
    } = this.state;
    const { me, openChangePasswordModal } = this.props;
    return (
      <div>
        {/*<MyPageRouteHead>나의 정보</MyPageRouteHead>*/}
        <Container>
          <ProfileInputRow>
            <ProfileInputTitle>휴대폰번호</ProfileInputTitle>
            {phoneEditable ? (
              <PhoneConfirmInputContainer>
                <PhoneConfirmInput
                  value={phonenum}
                  name="phonenum"
                  onChange={(e) => {
                    this.handleInputChange(e);
                    this.setState({ phoneConfirmed: false });
                  }}
                  fireSubmitValidation={fireSubmitValidation}
                  onDuplicated={this.onPhoneDuplicated}
                  onConfirmed={this.onPhoneConfirmed}
                  errorMsg={this.state.phoneErrorMsg}
                  clearError={() => this.setState({
                    fireSubmitValidation: false,
                    phoneErrorMsg: null,
                  })}
                />
              </PhoneConfirmInputContainer>
            ) : (
              <ProfileInputTextContainer>
                <PhonenumInput
                  value={this.state.phonenum}
                  name="phonenum"
                  onChange={this.handleInputChange}
                  readOnly={!this.state.phoneEditable}
                />
                <UpdateButton
                  marginLeft="4px"
                  onClick={() => this.setState({ phoneEditable: true })}
                >
                  변경하기
                </UpdateButton>
              </ProfileInputTextContainer>
            )}
          </ProfileInputRow>

          <ProfileInputRow>
            <ProfileInputTitle>이름</ProfileInputTitle>
            <ProfileInputTextContainer>
              <NameInput
                placeholder="이름"
                type="text"
                value={username}
                name="username"
                onChange={this.handleInputChange}
                fireSubmitValidation={fireSubmitValidation}
              />
            </ProfileInputTextContainer>
          </ProfileInputRow>

          <ProfileInputRow>
            <ProfileInputTitle>비밀번호</ProfileInputTitle>
            <ProfileInputTextContainer>
              <UpdatePasswordButton
                onClick={openChangePasswordModal}
              >
                변경하기
              </UpdatePasswordButton>
            </ProfileInputTextContainer>
          </ProfileInputRow>
          {/* <ProfilePhotoInputRow>
            <PrifilePhotoInputTitle>사진</PrifilePhotoInputTitle>
            <PrifilePhotoInputContainer>
              <FileUploadBtn>파일 첨부하기</FileUploadBtn>
            </PrifilePhotoInputContainer>
          </ProfilePhotoInputRow> */}
        </Container>
        <TerminateWrapper>
          <TerminateButton href="mailto: cs@odoctor.co.kr?subject=탈퇴요청&body=오닥터%20고객센터입니다.%0A오닥터에서%20탈퇴를%20희망하시는%20고객님의%20정보를%20작성해주세요%0A%0A성함%20:%0A연락처%20:%0A탈퇴사유%20:">
            오닥터와 이별하고 싶으세요..?
          </TerminateButton>
        </TerminateWrapper>
        <ProfileBtnContainer>
          <FormConfirmBtn
            disabledView={
              !username ||
              !isValidName(username) ||
              !phonenum ||
              (me.phonenum !== phonenum && !phoneConfirmed)
            }
            onClick={this.handleSubmit}
          >
            저장하기
          </FormConfirmBtn>
        </ProfileBtnContainer>
      </div>
    );
  }
}

export default compose(
  graphql(updateUserInfoMutation, {
    name: 'updateUserInfo',
  }),
  graphql(terminateUserMutation, {
    name: 'terminateUser',
  }),
)(withRouter(withAlertModal(withAuthModal(MyProfileContainer))));
