// ReqDone : 견적 form 다 적고 나서 보여주는 route
import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import SubHeader from '../components/SubHeader';
import { media } from '../globalStyle';
import { addADNTag } from '../utils/loging';
import Script from '../components/util/Script';
import YoutubeIcon from '../images/ic-youtube.png';
import CounselProcessInfoModal from '../components/CounselProcessInfoModal';

const ReqDoneMessage = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.41px;
  color: #000000;
  margin: 30px 0 10px;
`;

const CounselProcessInfoModalLink = styled.button`
  display: block;
  padding: 0;
  margin: 0 0 60px;
  border: 0; 
  text-decoration: underline;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.41px;
  color: #033e52;
`;

const YoutubeBtn = styled.button`
  padding: 14px 0;
  width: 246px;
  border: solid 1px #e1e3e2;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  color: #000000;
  img {
    width: 14px;
    height: 14px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
  }
  ${media.tablet`
    width: 100%;
    padding: 16px 0;
  `};
`;

const BtnWrapper = styled.div`
  margin: 90px 0 120px;
  text-align: center;
  ${media.tablet`
    width: 100%;
    margin: 10px 0 60px;
  `};
`;

const Btn = styled.button`
  width: 260px;
  padding: 20px 0;
  background-color: #033e52;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -0.89px;
  text-align: center;
  color: #ffffff;
  ${media.tablet`
    width: 100%;
    padding: 14px 0;
  `};
`;


const Container = styled.div`
  padding: 43px 20px 0;
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  ${media.tablet`
    min-width: 320px;
    padding: 58px 0px 0;
  `};
`;

const Content = styled.div`
  ${media.tablet`
    padding: 0 20px 0;
  `};
`;

class ReqDone extends Component {
  state = {
    isOpenInfoModal: false,
  };

  componentDidMount() {
    if (window && window.fbq) {
      window.fbq('track', 'CompleteRegistration');
    }

    // retargeting marketing
    addADNTag('Complete', '상담완료');
  }

  render() {
    return (
      <Container>
        <div>
          <div>
            <SubHeader step={4} />
          </div>
          <Content>
            <ReqDoneMessage>
              병원의 답변이 등록되면, 바로 문자로 알려드려요!
              <br />
              불편하시더라도 조금만 기다려주세요.
            </ReqDoneMessage>
            <CounselProcessInfoModalLink onClick={() => this.setState({ isOpenInfoModal: true })}>상담 진행절차가 궁금하다면?</CounselProcessInfoModalLink>
            <YoutubeBtn onClick={() => window.open('https://www.youtube.com/channel/UC3DlNVdlp3GkgsuTRmfOyzA')}>
              <img src={YoutubeIcon} alt="유튜브연결" />
              오닥터 유튜브 채널 보러가기
            </YoutubeBtn>
            <BtnWrapper>
              <Btn onClick={() => this.props.history.push(`/userreq/${this.props?.match?.params?.reqId}`)}>상담내역 보기</Btn>
            </BtnWrapper>
          </Content>
        </div>
        <Script url="//fin.rainbownine.net/js/adn_tags_1.0.0.js" async hardReload />
        <CounselProcessInfoModal isOpen={this.state.isOpenInfoModal} onClose={() => this.setState({ isOpenInfoModal: false })} />
      </Container>
    );
  }
}

export default withRouter(ReqDone);
