import React from 'react';
import {
  BrowserRouter, Route, Switch, Redirect,
} from 'react-router-dom';

import Home from './Home';
// import NewHome from './NewHome';
import TheNewHome from './TheNewHome'; // << Enhanced UI like CareOf
// import UpdatePassword from './UpdatePassword';
// import PageNotFound from './PageNotFound';
import Manual from './Manual';
import MyPage from './MyPage';
import ReqDone from './ReqDone';
import NewDone from './NewDone';
import MyRequest from './MyRequest';
import HsList from './HsList';
import HsDetail from './HsDetail';
import HsReview from './HsReview';
import HsCase from './HsCase';
import HsCaseDetail from './HsCaseDetail';
import HsDemo from './HsDemo';
// import ChangePassword from './ChangePassword';

// import ReqsHsList from './ReqsHsList';
import MobileHosRespList from './MobileHosRespList';
import RespsHsDetail from './RespsHsDetail';
import Favorites from './Favorites';
import Counsel from './Counsel';
import TheNewCounsel from './TheNewCounsel';
import AppLayout from '../components/AppLayout';
import Login from './Login';
import Signup from './Signup';
import AuthProvider, { AuthConsumer } from '../components/AuthProvider';
import Errors from './Errors';
import CommonErrorBoundary from '../utils/CommonErrorBoundary';
import { unfixBody } from '../utils/style-utils';
import Doctor from './Doctor';
import { logPageView } from '../utils/loging';
import MobileDetect from '../utils/mobileDetect';
import ReservationCreate from './ReservationCreate';
import OdocLounge from './OdocLounge';

const isMobile = new MobileDetect(window.navigator.userAgent).phone();

// GA pageview setting
const OdocRoute = ({
  component: Component, location, ...rest
}) => {
  logPageView(location.pathname);
  unfixBody();
  return (
    <Route
      {...rest}
      render={props => (
        <CommonErrorBoundary {...rest} {...props}>
          <Component {...rest} {...props} />
        </CommonErrorBoundary>
      )}
    />
  );
};

const PrivateRoute = ({ component: Component, location, ...rest }) => (
  <AuthConsumer>
    {value => (
      value.isLoggedIn && value.me ? (
        <OdocRoute {...rest} location={location} component={Component} />
      ) : (
        <Redirect
          {...rest}
          to={{
            pathname: '/login',
            search: '',
            state: { back: location.pathname },
          }}
        />
      ))
    }
  </AuthConsumer>
);

const AppRoute = ({ component: Component, requireAuth, ...rest }) => (
  <AppLayout {...rest}>
    {requireAuth ? (
      <PrivateRoute {...rest} component={Component} />
    ) : (
      <OdocRoute {...rest} component={Component} />
    )}
  </AppLayout>
);

// route for ui
const MainRoute = (props) => {
  const component = TheNewHome; // isMobile ? TheNewHome : TheNewHome; // NewHome
  return (
    <AppRoute {...props} component={component} />
  );
};

// route for ui
const CounselRoute = (props) => {
  const component = TheNewCounsel; // isMobile ? TheNewCounsel : Counsel;
  const savedData = JSON.parse(localStorage.getItem('pData'));
  const savedStep = JSON.parse(localStorage.getItem('pStep'));

  if (!savedData?.txItems?.[0] && !savedStep) {
    window.location.href = '/';
    return;
  }

  return (
    <AppRoute
      {...props}
      component={component}
      counselHeader={isMobile}
      hideMobileFooter
    />
  );
};

// route for ui
const DoneRoute = (props) => {
  const component = isMobile ? NewDone : ReqDone;
  return (
    <AppRoute
      {...props}
      component={component}
      counselHeader={isMobile}
      hideMobileFooter={isMobile}
    />
  );
};

export default () => (
  <BrowserRouter>
    <AuthProvider>
      <Switch>
        <MainRoute path="/" exact />
        <CounselRoute path="/counsel" exact />
        <DoneRoute path="/reqdone/:reqId" requireAuth />
        {/* <AppRoute path="/om" exact component={Home} event />
        <AppRoute path="/" exact component={NewHome} event /> */}

        {/* ab testing */}
        <AppRoute exact path="/a" component={Home} event />
        <AppRoute exact path="/b" component={Home} event />
        <AppRoute exact path="/c" component={Home} event />
        <AppRoute exact path="/d" component={Home} event />

        <AppRoute path="/manual" component={Manual} />


        {/* hospital */}
        <AppRoute component={HsDetail} exact path="/hospitals/:id" />
        <AppRoute path="/hospitals" exact component={HsList} />
        <AppRoute component={Doctor} exact path="/doctors/:id" />
        <OdocRoute component={HsDemo} exact path="/hospitals/demo/:compId" />
        <AppRoute component={HsReview} counselHeader={isMobile} exact path="/hospitals/:id/reviews" />
        <AppRoute component={HsReview} counselHeader={isMobile} exact path="/hospitals/:id/reviews/:doctorId" />

        {/* counsel case : 상담사례 */}
        <AppRoute
          hideMobileFooter
          component={HsCase}
          counselHeader={isMobile}
          exact
          path="/case"
        />

        {/* counsel case : 상담사례 */}
        <AppRoute
          hideMobileFooter
          component={HsCaseDetail}
          counselHeader={isMobile}
          exact
          path="/case/:id"
        />

        {/* counsel */}
        <AppRoute
          requireAuth
          path="/userreq/:id"
          exact
          // component={isMobile ? MobileHosRespList : ReqsHsList}
          component={MobileHosRespList}
          hideMobileFooter
        />
        <AppRoute
          requireAuth
          path="/hosresps/:id/hid/:hid"
          exact
          component={RespsHsDetail}
        />
        <AppRoute
          path="/counsel/:directHosId"
          exact
          component={Counsel}
          hideMobileFooter
        />
        {/* 새로운 UI로 라우트 분기 처리 */}
        {/* <AppRoute path="/counsel" exact component={Counsel} hideMobileFooter /> */}
        {/* <AppRoute
          requireAuth
          path="/reqdone"
          exact
          component={ReqDone}
        /> */}
        <AppRoute requireAuth path="/myrequest/:id" exact component={MyRequest} />

        {/* reservation */}
        <AppRoute counselHeader={isMobile} requireAuth path="/reservation/:hrid/:hid" exact component={ReservationCreate} hideMobileFooter />

        {/* mypage */}
        <AppRoute requireAuth path="/favorites" component={Favorites} />
        <AppRoute requireAuth path="/mypage" component={MyPage} />

        {/* auth */}
        {/* <AppRoute
          path="/updatepassword/:resetToken/:email"
          component={UpdatePassword}
        /> */}
        {/* <AppRoute
          requireAuth
          path="/changepassword"
          component={ChangePassword}
          hideTopMenu
          hideMobileFooter
        /> */}
        <AppRoute path="/login" component={Login} hideMobileFooter />
        <AppRoute path="/signup" component={Signup} hideMobileFooter />

        {/* 오닥터대기실 */}
        <AppRoute path="/lounge/:page" component={OdocLounge} hideMobileFooter />

        {/* ERROR PAGE */}
        <AppRoute path="/errors" component={Errors} hideMobileFooter />

        {/* 404 PAGE */}
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </AuthProvider>
  </BrowserRouter>
);
