import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';

const NoticeContainer = styled.div`
  border-bottom: 1px solid #d9d9d9;
  height: 44px;
  cursor: pointer;
  display: table-row;
  padding: 10px 0;
`;

const NoticeNum = styled.div`
  width: 90px;
  line-height: 22px;  
  font-size: 15px;
  font-weight: 300;
  text-align: center;
  color: #000000;
  display: table-cell;
  @media (max-width: 576px) {
    font-size: 13px;
    width: 30px;
  }
`;
const NoticeTitle = styled.div`
  line-height: 22px;
  font-size: 16px;
  text-align: left;
  color: #000000;
  display: table-cell;
  @media (max-width: 576px) {
    font-size: 14px;
    font-weight: 500;
  }
`;
const NoticeDate = styled.div`
  width: 90px;
  line-height: 22px;
  font-size: 15px;
  font-weight: 300;
  text-align: left;
  color: #000000;
  display: table-cell;
  @media (max-width: 576px) {
    font-size: 13px;
    width: 70px;
    line-height: 1.4;
  }
`;

class NoticeRow extends Component {
  render() {
    const {
      data: { title, createdAt }, idx, onClick,
    } = this.props;
    return (
      <NoticeContainer onClick={onClick}>
        <NoticeNum>{idx + 1}</NoticeNum>
        <NoticeTitle>{title}</NoticeTitle>
        <NoticeDate>{moment(createdAt).format('YYYY.MM.DD')}</NoticeDate>
      </NoticeContainer>
    );
  }
}

export default NoticeRow;
