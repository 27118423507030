import React, { useState } from 'react';
import styled from 'styled-components';

const StyledInputWarp = styled.div`
  padding: 8px 0;
  width: 100%;
  border-bottom: 1px solid #000;
  margin-bottom: 10px;
  &.error{
    border-color: #dd4124;
  }
`;

const StyledInput = styled.input`
  border: 0;
  outline: 0;
  font-size: 18px;
  letter-spacing: -.6px;
  &.fs-13{
    font-family: 'Saira Semi Condensed';
    font-size: 13px;
    font-weight: normal;
    font-stretch: semi-condensed;
    font-style: normal;
    letter-spacing: normal;
    color: #000000;
  }
`;

const StyledNameErrorMsg = styled.div`
  font-size: 12px;
  letter-spacing: -.6px;
  line-height: 1.2;
  color: rgb(221, 65, 36);
`;

export const NameComponent = ({ onClick }) => {
  const savedData = JSON.parse(localStorage.getItem('pData'));
  const [isValidate, setIsValidate] = useState(true);

  return (
    <div>
      <StyledInputWarp className={`${!isValidate && 'error'}`}>
        <StyledInput
          value={savedData.name}
          placeholder="오영희"
          onChange={(e) => {
            // 완성형 한글 or 영문자+띄어쓰기만
            const validate = /^[가-힣a-z\s]+$/gi.test(e.target.value);
            setIsValidate(validate);
            if (validate) {
              onClick({ value: e.target.value });
            } else {
              // 영한 혼용 및 숫자, 특문이 들어갔을 경우
              // value 에 초기값 null을 넣어 '다음'버튼 비활성화
              onClick({ value: null });
            }
          }}
        />
      </StyledInputWarp>
      {
        !isValidate && <StyledNameErrorMsg>한글이나 영어로만 입력해주세요.</StyledNameErrorMsg>
      }
    </div>
  );
};

export default NameComponent;
