import React, { Component } from 'react';
import styled from 'styled-components';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';

import { getHosRespsListQuery, userRequestQuery, checkHosRespsMutate } from '../graphql';
import MobilePageContainer from '../components/atomic/layouts/MobilePageContainer';
import { RespCard, CounselProcessCard, ContentCard } from '../components/HosRespList/RespCard';
import RespListHeader from '../components/HosRespList/RespListHeader';
import { getCookie, setCookie } from '../utils/cookie';
import { userActivitySendSlack, logEvent } from '../utils/loging';
import MobileCheckAnswerConfirmModal from '../components/MobileCheckAnswerConfirmModal';
import RespSpeechBubble from '../components/HosRespList/RespSpeechBubble';
import { getWantLevelText } from '../data';
import { LinkedWord } from '../components/atomic/atoms/Text/Text';

const StyledMyCounsel = styled.div`
  .my-counsel-btn{
    margin-top: 7px;
    display: block;
    color: #fff;
  }
`;
// 상담결과리스트
class MobileHosRespList extends Component {
  state = {
    isOpenCheckAnswerModal: false,
    clickedHospitalName: null,
    pcap: !!getCookie('pcap'), // passCheckAnswerPopup
    onConfirm: null,
  };

  onClick = async (item) => {
    const { pcap } = this.state;
    if (pcap || item.checkedAns) {
      // 일주일간 팝업 숨김 있을 때 or 이미 답변 조회를 한 경우
      // 팝업을 노출하지 않는다.
      this.viewDetail(item);
    } else {
      // 팝업 노출 GA logging
      logEvent({
        category: '상담결과',
        action: '팝업노출',
        label: '답변확인',
      });

      this.setState({
        isOpenCheckAnswerModal: true,
        clickedHospitalName: item?.hospital?.name,
        onConfirm: () => {
          // 팝업 ok 버튼 클릭 GA log
          logEvent({
            category: '상담결과',
            action: '클릭',
            label: '답변확인',
          });
          this.viewDetail(item);
        },
      });
    }
  }

  viewDetail = async (item) => {
    const { checkHosResps, history } = this.props;
    // slack log
    await userActivitySendSlack('?', 'confirmHosResps');

    // ga log
    logEvent({
      category: '상담결과',
      action: '클릭',
      label: '답변한 치과 확인',
      value: item.txPriceMatchScore + item.orthoPriceMatchScore + item.locationMatchScore,
    });

    // 답변확인 mutation
    if (!item.checkedAns) {
      const res = await checkHosResps({ variables: { hrId: item.id } });
      if (res?.error) {
        // console.log(res.error)
        // todo : 예외처리 필요
      }
    }

    // 답변상세로 routing
    history.push(`/hosresps/${item.id}/hid/${item.hospital.id}`);
  };

  setPcapCookie = (days = 7) => {
    setCookie('pcap', 1, days);
  };

  render() {
    const { request, hosResps, history } = this.props;
    const { isOpenCheckAnswerModal, onConfirm, clickedHospitalName } = this.state;

    if (request?.getMyReq && hosResps?.getHosRespsList) {
      return (
        <MobilePageContainer>
          {/* 페이지 제목, 메세지, 연락방법 selector, 치료의향 */}
          <RespListHeader
            request={request?.getMyReq}
            resps={hosResps?.getHosRespsList?.hospitals || []}
          />

          {/* 치료 의향 */}
          <RespSpeechBubble
            side="right"
            backgroundColor="#033e52"
            textColor="#ffffff"
            className="right"
          >
            <StyledMyCounsel>
              {getWantLevelText(request?.getMyReq?.wantLevel)}
              <LinkedWord
                className="my-counsel-btn"
                onClick={() => {
                  history.push(`/myrequest/${request.getMyReq.id}`);
                  logEvent({
                    category: '답변(병원) 상세',
                    action: '클릭',
                    label: '상담정보 보러가기',
                  });
                }}
              >
                {'내 상담보기'}
              </LinkedWord>
            </StyledMyCounsel>
          </RespSpeechBubble>

          {/* 상담결과 리스트 */}
          {hosResps?.getHosRespsList?.hospitals?.length > 0
            && hosResps?.getHosRespsList?.hospitals.map(hosresp => (
              <RespCard key={`hosresp_${hosresp.id}`} hosresp={hosresp} onClick={this.onClick} />
            ))}

          {/* 답변확인모달 */}
          {isOpenCheckAnswerModal && (
            <MobileCheckAnswerConfirmModal
              onConfirm={onConfirm}
              setCookie={this.setPcapCookie}
              isOpen={isOpenCheckAnswerModal}
              onClose={() => this.setState({ isOpenCheckAnswerModal: false })}
              hospitalName={clickedHospitalName}
            />
          )}

          {/* 상담 진행절차 */}
          <CounselProcessCard />

          {/* 병원 가기전 알아보기 컨텐츠 */}
          <ContentCard reqItems={request?.getMyReq?.txItems.concat(request?.getMyReq?.orthoItmes)} />
        </MobilePageContainer>
      );
    }
    return null;
  }
}

export default compose(
  graphql(userRequestQuery, {
    name: 'request',
    options: ({ match }) => ({
      variables: {
        reqId: match.params.id,
      },
    }),
  }),
  graphql(getHosRespsListQuery, {
    name: 'hosResps',
    options: ({ match }) => ({
      variables: {
        ureqId: match.params.id,
      },
    }),
  }),
  graphql(checkHosRespsMutate, {
    name: 'checkHosResps',
  }),
)(withRouter(MobileHosRespList));
