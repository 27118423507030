import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import {
  CartBody,
  // CartContainer,
  CartContainerTitle,
  TherapyRow,
  TherapyTitle,
  TherapyRightContainer,
  Item,
  DescCartViewContainer,
  Desc,
} from '../components/form/RightCartCommonComponents';
import { Hr, PaddingDiv } from '../components/basic/HtmlComponents';
import {
  getTxTherapyNamesByTxItems,
  getOrthoPartName,
  getPriceFormat,
} from '../utils/req-utils';
import { therapyInfoById } from './form/therapyInfo';
import Angle from '../images/ic-arrow-gray-20.png';
import { media } from '../globalStyle';
import { fixBody, unfixBody } from '../utils/style-utils';
import { getAgeGroupName } from '../utils/getAge';
import { logEvent } from '../utils/loging';
import {draftData as data} from "../data";

const GrayBorder = styled.div`
  width: 350px;
  overflow: hidden;
  background: white;
  border: solid 1px #dedede;
  box-sizing: initial;
  height: 100%;
  position: relative;
  max-height: ${props => `${props.fixedCartMaxHeight}`};
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  &.fixed {
    position: fixed;
    top: 0;
  }
`;
const Header = styled.div`
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: -1px;
  color: #033e52;
  height: 64px;
  padding: 17px 20px;
`;

const DetailViewBtn = styled.span`
  text-decoration: underline;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: -0.8px;
  text-align: right;
  color: #000000;
  float: right;
  display: block;
  margin-top: 4px;
  height: 24px;
  cursor: pointer;
  ${media.miniDesktop`
    margin: 10px 0 30px;
    display: block;
    float: none;
  `};
`;

const Container = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const Body = styled.div`
  display: block;
  max-height: unset;
  max-height: none;
  overflow-y: hidden;
  position: relative;
  ${props => props.folded && 'max-height: 365px;'};
`;

const Sections = styled.div`
  padding: 20px;
`;

const Section = styled.div`
  & ~ & {
    border-top: solid 1px #e4e4e4;
    margin-top: 20px;
    padding-top: 20px;
  }
`;

const ReadMore = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: none;
  ${props => props.visible && 'display: block'};
`;

const Mask = styled.div`
  height: 88px;
  width: 100%;
  text-align: center;
  margin: 0;
  background-image: linear-gradient(to bottom, transparent, #ffffff);
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

const DesktopView = styled.div`
  width: 350px;
  display: block;
  position: relative;
  ${media.miniDesktop`
    display: none;
  `};
`;

const MobileView = styled.div`
  display: none;
  ${media.miniDesktop`
    position: fixed;
    background: #e5e5e5;
    width: 100%;
    bottom: 54px;
    left: 0;
    display: block;
    z-index: 5;
  `};
`;

const ReadMoreBtn = styled.button`
  width: 100%;
  height: 64px;
  border: solid 1px #033e52;
  background-color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: -1px;
  text-align: center;
  color: #033e52;
`;

const MovileViewBody = styled.div`
  background: white;
  height: 383px;
  padding: 20px;
  overflow-y: auto;
  width: 100%;
`;

const MobileViewOpenBtn = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 54px;
  background-color: #ffffff;
  padding: 10px 20px;
  border-top: solid 1px #e0e0e0;
  width: 100%;
`;

const MobileViewOpenBtnText = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #033e52;
`;

const MobileViewOpenBtnArrow = styled.img`
  transform: rotate(-90deg);
  width: 16px;
  height: 16px;
  object-fit: contain;
  ${props =>
    props.open &&
    `
      transform: rotate(90deg);
  `};
`;

const MobileCartBackdrop = styled.div`
  display: none;
  ${media.miniDesktop`
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    opacity: 0.3;
    background-color: #000000;
    z-index: 4;
  `};
`;

export const ReqData = ({
  txItems,
  implant,
  cavity,
  aesthetic,
  general,
  gum,
  facial,
  orthoItems,
  orthoWanna,
  desc,
}) => (
  <React.Fragment>
    {txItems.length > 0 && (
      <Section>
        <CartContainerTitle>치과 치료</CartContainerTitle>
        {implant.length > 0 && (
          <TherapyRow>
            <TherapyTitle>임플란트</TherapyTitle>
            <TherapyRightContainer>
              {implant.map(data => (
                <Item key={data.itemcode}>
                  {therapyInfoById[data.itemcode].viewName}{' '}
                  {data.unit > 1 ? data.unit : ''}
                </Item>
              ))}
            </TherapyRightContainer>
          </TherapyRow>
        )}
        {cavity.length > 0 && (
          <TherapyRow>
            <TherapyTitle>충치치료</TherapyTitle>
            <TherapyRightContainer>
              {cavity.map(data => (
                <Item key={data.itemcode}>
                  {therapyInfoById[data.itemcode].viewName}{' '}
                  {data.unit > 1 ? data.unit : ''}
                </Item>
              ))}
            </TherapyRightContainer>
          </TherapyRow>
        )}
        {aesthetic.length > 0 && (
          <TherapyRow>
            <TherapyTitle>미용, 심미치료</TherapyTitle>
            <TherapyRightContainer>
              {aesthetic.map(data => (
                <Item key={data.itemcode}>
                  {therapyInfoById[data.itemcode].viewName}{' '}
                  {data.unit > 1 ? data.unit : ''}
                </Item>
              ))}
            </TherapyRightContainer>
          </TherapyRow>
        )}
        {general.length > 0 && (
          <TherapyRow>
            <TherapyTitle>일반치료</TherapyTitle>
            <TherapyRightContainer>
              {general.map(data => (
                <Item key={data.itemcode}>
                  {therapyInfoById[data.itemcode].viewName}{' '}
                  {data.unit > 1 ? data.unit : ''}
                </Item>
              ))}
            </TherapyRightContainer>
          </TherapyRow>
        )}
        {gum.length > 0 && (
          <TherapyRow>
            <TherapyTitle>잇몸치료</TherapyTitle>
            <TherapyRightContainer>
              {gum.map(data => (
                <Item key={data.itemcode}>
                  {therapyInfoById[data.itemcode].viewName}{' '}
                  {data.unit > 1 ? data.unit : ''}
                </Item>
              ))}
            </TherapyRightContainer>
          </TherapyRow>
        )}
        {facial.length > 0 && (
          <TherapyRow>
            <TherapyTitle>구강(턱/얼굴)치료</TherapyTitle>
            <TherapyRightContainer>
              {facial.map(data => (
                <Item key={data.itemcode}>
                  {therapyInfoById[data.itemcode].viewName}{' '}
                  {data.unit > 1 ? data.unit : ''}
                </Item>
              ))}
            </TherapyRightContainer>
          </TherapyRow>
        )}
      </Section>
    )}
    {(orthoItems.length > 0 || (orthoWanna && orthoWanna.length > 0)) && (
      <Section>
        <CartContainerTitle>교정 치료</CartContainerTitle>
        {orthoItems && orthoItems.length > 0 && (
          <TherapyRow>
            <TherapyTitle>교정 부위</TherapyTitle>
            <TherapyRightContainer>
              {orthoItems.map(orthoItem => (
                <Item key={`part_${orthoItem.part}`}>
                  {getOrthoPartName(orthoItem.part)}
                </Item>
              ))}
            </TherapyRightContainer>
          </TherapyRow>
        )}
        {orthoItems && orthoItems.length > 0 && (
          <TherapyRow>
            <TherapyTitle>희망 교정</TherapyTitle>
            <TherapyRightContainer>
              <Item key={`item_${orthoItems[0].itemcode}`}>
                {therapyInfoById[orthoItems[0].itemcode].viewName}
              </Item>
            </TherapyRightContainer>
          </TherapyRow>
        )}
        {orthoWanna && orthoWanna.length > 0 && (
          <TherapyRow>
            <TherapyTitle>희망 개선 부분</TherapyTitle>
            <TherapyRightContainer>
              {orthoWanna.map(want => (
                <Item key={`wanna-${want}`}>
                  {want}
                </Item>
              ))}
            </TherapyRightContainer>
          </TherapyRow>
        )}
      </Section>
    )}
    {desc && (
      <Section>
        <CartContainerTitle>자세한 상담</CartContainerTitle>
        <DescCartViewContainer>
          <Desc>{desc}</Desc>
        </DescCartViewContainer>
      </Section>
    )}
  </React.Fragment>
);

export const SettingData = ({
  directReq,
  wantLevel,
  reqType,
  txMaxPrice,
  orthoMaxPrice,
  carePrice,
  considerTags,
  gender,
  ageGroup,
  locations,
}) => (
  <React.Fragment>
    {(gender || ageGroup) && (
      <Section>
        <CartContainerTitle>기본정보</CartContainerTitle>
        {gender && (
          <TherapyRow marginTop="6px">
            <TherapyTitle>성별</TherapyTitle>
            <TherapyRightContainer>
              <Item>{gender === 'female' ? '여자' : '남자'}</Item>
            </TherapyRightContainer>
          </TherapyRow>
        )}
        {ageGroup && (
          <TherapyRow marginTop="6px">
            <TherapyTitle>나이대</TherapyTitle>
            <TherapyRightContainer>
              <Item>{getAgeGroupName(ageGroup)}</Item>
            </TherapyRightContainer>
          </TherapyRow>
        )}
      </Section>
    )}

    {!directReq && locations?.length > 0 && (
      <Section>
        <CartContainerTitle>희망지역</CartContainerTitle>
        <TherapyRow>
          <TherapyTitle>지역</TherapyTitle>
          <TherapyRightContainer>
            {
              locations.map( loc => (
                <Item>{loc.siName} {loc.gunguName}</Item>
              ))
            }
          </TherapyRightContainer>
        </TherapyRow>
      </Section>
    )}

    {directReq && directReq.hospital && directReq.hospital.name && (
      <Section>
        <CartContainerTitle>선택한 병원</CartContainerTitle>
        <TherapyRow>
          <TherapyTitle />
          <TherapyRightContainer>
            <Item>{directReq.hospital.name}</Item>
          </TherapyRightContainer>
        </TherapyRow>
      </Section>
    )}

    <Section>
      <CartContainerTitle>치료의향</CartContainerTitle>
      <TherapyRow marginTop="6px">
        <TherapyTitle />
        <TherapyRightContainer>
          {data.wantLevel.filter((d) => (
            wantLevel === d.value
          )).map((d, idx) => (
              <Item key={idx}>{d.title.replace(/<b>|<\/b>/gi, '')}</Item>
            )
          )}
        </TherapyRightContainer>
      </TherapyRow>
    </Section>

    <Section style={{ display: 'none' }}>
      <CartContainerTitle>희망 치료비</CartContainerTitle>
      <PaddingDiv padding="2px 0 0" />
      {(reqType === 'tx' || reqType === 'all') && (
        <TherapyRow marginTop="6px">
          <TherapyTitle>치과치료</TherapyTitle>
          <TherapyRightContainer>
            <Item>
              {txMaxPrice ? (
                <React.Fragment>
                  {getPriceFormat(txMaxPrice)}
                  {' 만원'}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {carePrice === 'dontcare' ? '상관없음' : '없음'}
                </React.Fragment>
              )}
            </Item>
          </TherapyRightContainer>
        </TherapyRow>
      )}
      {(reqType === 'ortho' || reqType === 'all') && (
        <TherapyRow marginTop="6px">
          <TherapyTitle>교정치료</TherapyTitle>
          <TherapyRightContainer>
            <Item>
              {orthoMaxPrice ? (
                <React.Fragment>
                  {getPriceFormat(orthoMaxPrice)}
                  {' 만원'}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {carePrice === 'dontcare' ? '상관없음' : '없음'}
                </React.Fragment>
              )}
            </Item>
          </TherapyRightContainer>
        </TherapyRow>
      )}
    </Section>

    {!directReq && considerTags && considerTags.length > 0 && (
      <Section>
        <CartContainerTitle>상담시 고려사항</CartContainerTitle>
        <TherapyRow marginTop="6px">
          <TherapyTitle>
            상담시
            <br />
            고려사항
          </TherapyTitle>
          <TherapyRightContainer>
            {considerTags.map(tag => (
              <Item key={`considerTag_${tag}`}>
                {tag}
              </Item>
            ))}
          </TherapyRightContainer>
        </TherapyRow>
      </Section>
      )}
  </React.Fragment>
);

class UserRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cartFixed: false,
      cartMaxHeight: 'none',
      showBodyMask: false,
      isOpenMobileView: false,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResizeWindow);
    this.handleResizeWindow();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResizeWindow);
  }

  handleResizeWindow = () => {
    const { offsetWidth } =
      document.scrollingElement || document.documentElement;
    if (offsetWidth > 930) {
      this.closeMobileView();
    }
  };

  bodyRefCallback = (element) => {
    if (element) {
      const size = element.getBoundingClientRect();
      if (size.height > 365) {
        this.setState({ showBodyMask: true });
      }
    }
  };

  openMobileView = () => {
    this.setState({ isOpenMobileView: true });
    logEvent({
      category: '상담결과',
      action: '클릭',
      label: '나의 상담정보 펼치기',
    });
    fixBody();
  };

  closeMobileView = () => {
    this.setState({ isOpenMobileView: false });
    unfixBody();
  };

  render() {
    const {
      reqType,
      txItems,
      orthoItems,
      txMaxPrice,
      orthoMaxPrice,
      orthoWanna,
      considerTags,
      desc,
      directReq,
      gender,
      ageGroup,
      locations,
      wantLevel,
    } = this.props.userRequest;

    const { id } = this.props.match.params;

    const { implant, cavity, aesthetic, general, gum, facial } = getTxTherapyNamesByTxItems(txItems);

    const { isOpenMobileView } = this.state;

    return (
      <div>
        <DesktopView>
          <GrayBorder
            fixedCartMaxHeight={this.state.cartMaxHeight}
            className={this.state.cartFixed && 'fixed'}
          >
            <CartBody>
              <Container>
                <Header>
                  <FormattedMessage id="myCsInfo" />
                  <DetailViewBtn
                    onClick={() => {
                      this.props.history.push(`/myrequest/${id}`);
                      logEvent({
                        category: '상담결과',
                        action: '클릭',
                        label: '상담정보 자세히보기',
                      });
                    }}
                  >
                    자세히보기
                  </DetailViewBtn>
                </Header>
                <Hr color="#e4e4e4" />
                <Body folded={this.state.showBodyMask}>
                  <div ref={this.bodyRefCallback}>
                    <Sections>
                      <ReqData
                        txItems={txItems}
                        cavity={cavity}
                        implant={implant}
                        aesthetic={aesthetic}
                        general={general}
                        gum={gum}
                        facial={facial}
                        orthoItems={orthoItems}
                        orthoWanna={orthoWanna}
                        desc={desc}
                      />
                      <SettingData
                        directReq={directReq}
                        reqType={reqType}
                        txMaxPrice={txMaxPrice}
                        orthoMaxPrice={orthoMaxPrice}
                        considerTags={considerTags}
                        gender={gender}
                        ageGroup={ageGroup}
                        locations={locations}
                        wantLevel={wantLevel}
                      />
                    </Sections>
                  </div>
                </Body>
              </Container>
            </CartBody>
            <ReadMore visible={this.state.showBodyMask}>
              <Mask />
              <ReadMoreBtn
                onClick={() => this.setState({ showBodyMask: false })}
              >
                더보기
              </ReadMoreBtn>
            </ReadMore>
          </GrayBorder>
        </DesktopView>
        <MobileView>
          <MobileViewOpenBtn onClick={isOpenMobileView ? this.closeMobileView : this.openMobileView}>
            <MobileViewOpenBtnText>
              나의 상담정보
            </MobileViewOpenBtnText>
            <MobileViewOpenBtnArrow open={isOpenMobileView} src={Angle} />
          </MobileViewOpenBtn>
          {isOpenMobileView && (
            <MovileViewBody>
              <DetailViewBtn
                onClick={() => {
                  this.props.history.push(`/myrequest/${id}`);
                  this.closeMobileView();
                  logEvent({
                    category: '상담결과',
                    action: '클릭',
                    label: '상담정보 자세히보기',
                  });
                }}
              >
                자세히보기
              </DetailViewBtn>
              <ReqData
                txItems={txItems}
                cavity={cavity}
                implant={implant}
                aesthetic={aesthetic}
                orthoItems={orthoItems}
                orthoWanna={orthoWanna}
                desc={desc}
                general={general}
                gum={gum}
                facial={facial}
              />
              <SettingData
                directReq={directReq}
                reqType={reqType}
                txMaxPrice={txMaxPrice}
                orthoMaxPrice={orthoMaxPrice}
                considerTags={considerTags}
                gender={gender}
                ageGroup={ageGroup}
                locations={locations}
                wantLevel={wantLevel}
              />
            </MovileViewBody>
          )}
        </MobileView>
        {isOpenMobileView && (
          <MobileCartBackdrop onClick={this.closeMobileView} />
        )}
      </div>
    );
  }
}

export default withRouter(UserRequest);
