import React from 'react';
import styled from 'styled-components';

const StyledStarWarp = styled.div`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  font-size: 0;
  line-height: 1;
  &:not(:first-child) {
      margin-left: 0;
    }
`;

const StyledStar = styled.svg`
  vertical-align: middle;
`;

const Star = ({
  className, selected, onClick, size = '24',
}) => (
  <StyledStarWarp onClick={onClick} className={className}>
    <StyledStar xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="-3 -3 30 30">
      <path fill={selected ? '#033e52' : '#fff'} stroke="#033e52" d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z" />
    </StyledStar>
  </StyledStarWarp>
);
export default Star;
