import React, { Component } from 'react';
import styled from 'styled-components';

import NoticeRow from '../../components/Manual/NoticeRow';
import RouteHead from '../../components/basic/RouteHeader';

const NoticeTable = styled.div`
  width: 100%;
  margin-top: 20px;
  display: table;
`;

class NoticeContainer extends Component {
  render() {
    const { announcementsList } = this.props;

    if (announcementsList && announcementsList.length === 0) {
      return (
        <div>
          <RouteHead>공지사항</RouteHead>
          <NoticeTable>등록된 공지사항이 없습니다.</NoticeTable>
        </div>
      );
    }

    return (
      <div>
        <RouteHead>공지사항</RouteHead>
        <NoticeTable>
          {announcementsList && announcementsList.map((data, idx) => (
            <NoticeRow
              onClick={() => this.props.history.push(`/manual/notice/${data.id}`)}
              key={`notice_${idx}`}
              data={data}
              idx={idx}
            />
          ))}
        </NoticeTable>
      </div>
    );
  }
}

export default NoticeContainer;
