import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import LogoSvg from '../images/img-logo-gnb.svg';
import { media } from '../globalStyle';
import { logEvent } from '../utils/loging';

const FooterContainer = styled.div`
  width: 100%;
  border-top: 1px solid #e1e3e2;
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 60px;
  ${props => props.hideMobileFooter && 'display: none'};
  
  ${media.miniDesktop`
    padding-top: 20px;
    padding-bottom: 80px;
  `};
`;

const FooterWrapper = styled.div`
  max-width: 760px;
  padding: 0 20px;
  margin: 0 auto;
`;

const MobileMenus = styled.ul`
  display: none;
  ${media.miniDesktop`
    display: block;
    margin-bottom: 20px;
    list-style: none;
    & > li ~ li {
      border-left: 1px solid #e1e3e2;
      padding-left: 10px;
      margin-left: 10px;
    }

    li {
      display: inline-block;
      cursor: pointer;
      line-height: 1;
      a {
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -0.7px;
        color: #000000;
        vertical-align: middle;
      }
    }
  `};
`;

const Menus = styled.ul`
  ${media.miniDesktop`
    display: none;
  `};
  float: right;
  list-style: none;
  & > li ~ li {
    border-left: 1px solid #e1e3e2;
    padding-left: 14px;
    margin-left: 14px;
  }

  li {
    display: inline-block;
    cursor: pointer;
    line-height: 1;
    a {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -1px;
      color: #000000;
      vertical-align: middle;
    }
  }
`;

const CopyRight = styled.div`
  font-family: 'Saira Semi Condensed', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-top: 13px;
`;

const DisclaimerPolicy = styled.div`
  font-size: 14px;
  margin: auto;
  letter-spacing: -0.8px;
  color: #000000;
  text-align: left;
  ${media.miniDesktop`
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.7px;
  `};
`;

const SpanWithRightDash = styled.div`
  position: relative;
  display: inline-block;
  margin: 0;
  &:after {
    display: none;
    position: absolute;
    right: -10px;
    content: '';
    height: 14px;
    top: 1px;
    border-right: 1px solid #e1e3e2;
  }
  ${media.miniDesktop`
    &:after {
      content: none;
    }
  `};
`;

const Row = styled.div`
  display: block;
  margin-bottom: 3px;
  position: relative;
  div:not(${SpanWithRightDash}) {
    //display: inline-block;
    //margin-right: 4px;
  }
`;

const LogoImg = styled.img`
  width: 80px;
  height: 16px;
  margin-bottom: 19px;
`;

const DesktopShow = styled.div`
  display: block;
  ${media.miniDesktop`
    display: none;
  `};
`;

const MobileShow = styled.div`
  display: none;
  ${media.miniDesktop`
    display: block;
  `};
`;

const NumEn = styled.span`
  font-family: 'Saira Semi Condensed', sans-serif;
  letter-spacing: 0px;
`;

const PartnerSiteBtn = styled.div`
  cursor: pointer;
  margin-top: 12px;
  margin-right: 0px !important;
  height: 44px;
  line-height: 44px;
  width: 152px;
  border: solid 1px #033e52;
  color: #033e52;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  position: absolute;
  bottom: 0;
  right: 0;
`;

class Footer extends Component {
  openPartnerSite = () => {
    window.open(`${process.env.REACT_APP_PARTNER_APP_URL}`);
  };

  render() {
    return (
      <FooterContainer hideMobileFooter={this.props.hideMobileFooter} id="footer">
        <FooterWrapper>
          <MobileMenus>
            <li>
              <Link
                onClick={() =>
                  logEvent({
                    category: 'Footer',
                    action: '클릭',
                    label: '공지사항',
                  })
                }
                to="/manual/notice"
              >
                공지사항
              </Link>
            </li>
            <li>
              <Link
                onClick={() =>
                  logEvent({
                    category: 'Footer',
                    action: '클릭',
                    label: 'FAQ',
                  })
                }
                to="/manual/faq"
              >
                <NumEn>FAQ</NumEn>
              </Link>
            </li>
            <li>
              <Link to="/manual/terms">이용약관</Link>
            </li>
            <li>
              <Link to="/manual/privacypolicy">개인정보처리방침</Link>
            </li>
          </MobileMenus>
          <LogoImg src={LogoSvg} alt="" />
          <DisclaimerPolicy>
            <Row>
              <SpanWithRightDash>대표 고석호</SpanWithRightDash>
              <div style={{ marginLeft: '20px', display: 'inline-block' }}>사업자번호 <NumEn>522-81-01167</NumEn></div>
              <Menus>
                <li>
                  <Link
                    to="/manual/notice"
                    onClick={() =>
                      logEvent({
                        category: 'Footer',
                        action: '클릭',
                        label: '공지사항',
                      })
                    }
                  >
                    공지사항
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() =>
                      logEvent({
                        category: 'Footer',
                        action: '클릭',
                        label: 'FAQ',
                      })
                    }
                    to="/manual/faq"
                  >
                    <NumEn>FAQ</NumEn>
                  </Link>
                </li>
                <li>
                  <Link to="/manual/terms">이용약관</Link>
                </li>
                <li>
                  <Link to="/manual/privacypolicy">개인정보처리방침</Link>
                </li>
              </Menus>
            </Row>
            <DesktopShow>
              <Row style={{ marginTop: '9px' }}>
                <SpanWithRightDash>서울 강남구 언주로 <NumEn>85</NumEn>길 <NumEn>29</NumEn> <NumEn>5</NumEn>층</SpanWithRightDash>
                <div>전화 <NumEn>02-3443-1357</NumEn> (평일 <NumEn>10:00 - 19:00</NumEn>)</div>
                <div>이메일 <NumEn>cs@odoctor.co.kr</NumEn></div>
                <PartnerSiteBtn
                  onClick={() => {
                    this.openPartnerSite();
                    logEvent({
                      category: 'Footer',
                      action: '클릭',
                      label: '파트너병원',
                    });
                  }}
                >
                  오닥터 파트너 병원
                </PartnerSiteBtn>
              </Row>
            </DesktopShow>
            <MobileShow>
              <Row style={{ marginTop: '6px' }}>
                <div style={{ marginBottom: '6px' }}>서울 강남구 언주로 <NumEn>85</NumEn>길 <NumEn>29</NumEn> <NumEn>5</NumEn>층</div><br />
                <SpanWithRightDash>전화 <NumEn>02-3443-1357</NumEn> (평일 <NumEn>10:00 - 19:00</NumEn>)</SpanWithRightDash><br />
                <div style={{ marginTop: '6px' }}>이메일 <NumEn>cs@odoctor.co.kr</NumEn></div>
              </Row>
            </MobileShow>
          </DisclaimerPolicy>
          <CopyRight>© 2019 Odoctor all rights reserved.</CopyRight>
        </FooterWrapper>
      </FooterContainer>
    );
  }
}

export default Footer;
