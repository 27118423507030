export const getNameValidationMessage = (name) => {
  if (!name) {
    return '';
  }

  // 숫자와 특수문자 입력시
  const digitAndSpecialChar = /[0-9]+|[$&+,:;=?@#|'<>.^*()%!-]+/;
  if (
    digitAndSpecialChar.test(name)
  ) {
    return '이름에 숫자와 특수문자는 입력할 수 없습니다.';
  }

  return '';
};

export const isValidName = (name) => {
  if (getNameValidationMessage(name) === '') {
    return true;
  }

  return false;
};

export const getPassValidationMessage = (pass) => {
  if (!pass) {
    return '';
  }

  if (pass.length < 4 || pass.length > 12) {
    return '비밀번호는 4자 이상 ~ 12자 이하로 입력하세요.';
  }

  return '';
};

export const isValidPass = (pass) => {
  if (getPassValidationMessage(pass) === '') {
    return true;
  }

  return false;
};


export const isValidEmail = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (email && !re.test(String(email).toLowerCase())) {
    return false;
  }

  return true;
};

const validationInput = '';

export default validationInput;
