import React from 'react';
import { graphql, compose } from 'react-apollo';
import styled from 'styled-components';
import { getHospitalQuery } from '../graphql';
import { ReviewViewerComponent } from '../components/ReviewViewer/ReviewViewerComponent';
import { HospitalReviewsQuery } from '../graphql/reviewQuery';
import { therapyDataJson } from '../components/Review/data';
import { media } from '../globalStyle';
import { Title } from '../components/atomic/atoms/Title/Title';
import { ReactSelect } from '../components/atomic/atoms/Select/SelectBox';
import { PrevButtonHeader } from '../components/atomic/organisms/header'
import {StarWithPoint} from "../components/atomic/molecules/StarRating";
import {SectionHr} from "../components/atomic/atoms/Shape/Bar";
import MobileDetect from "../utils/mobileDetect";

const ExtendedTitle = styled(Title)`
  font-size: 26px;
  margin-bottom: 20px;
  margin-top: 30px;
  ${media.tablet`
    font-size: 24px;
  `};
  >span{
    ${media.tablet`
      display: block
    `};
  }
`;

const ExtendedReactSelect = styled(ReactSelect)`
  margin-bottom: 90px;
  ${media.tablet`
    margin-bottom: 60px;
    width: 100%;
  `};
`;

const StyledSectionHr = styled(SectionHr)`
  margin: 30px 0;
`;

const HospitalReviewContainer = styled.div`
  margin: 0 auto;
`;

const isMobile = new MobileDetect(window.navigator.userAgent).phone();

class HospitalReview extends React.PureComponent {
  render() {
    if (this.props.hospital.loading) return null;

    const {
      doctorId,
      hospital,
      hospitalReviews,
      loading,
    } = this.props;

    if(!hospitalReviews && loading) return null;

    const doctors = hospital.getHospital.doctors;
    // const { id: value, name: label } = doctors.find(doc=>doc.id === doctorId);
    const defaultValue = doctors.find(doc=>doc.id === doctorId);

    const totlaReviewCount = hospital.getHospital.reviewCnt;

    return (
      <div>
        {isMobile && <PrevButtonHeader />}
        <HospitalReviewContainer>
          <ExtendedTitle>{`${hospital.getHospital.name}의`} <span>{`${totlaReviewCount}개 리뷰`}</span></ExtendedTitle>
          <div style={{paddingBottom: '30px'}}>
            <StarWithPoint
              starsNum={1}
              size={'24px'}
              fontSize='24px'
              selectedNum={1}
            >
              {hospital.getHospital.avgRating}
            </StarWithPoint>
          </div>
          <StyledSectionHr/>
          {/*<ExtendedReactSelect
            isSearchable={false}
            placeholder={'의사선택'}
            defaultValue={defaultValue && { value: defaultValue.id, label:defaultValue.name }}
            options={[
              {value: null, label: '전체보기'},
              ...doctors.map((doc)=>({ value: doc.id, label: doc.name }))
            ]}
            onChange={(selectedDoctor) => {
              this.props.filterEntries({
                doctorId: selectedDoctor.value
              });
            }}
          />*/}
          <ReviewViewerComponent
            viewAll
            starSize='14'
            reviews={this.props.hospitalReviews.data}
            paging={this.props.hospitalReviews.pagination}
            doctors={doctors}
            therapy={therapyDataJson()}
            onLoadMore={(page) => {
              this.props.loadMoreReview(page);
            }}
          />
        </HospitalReviewContainer>
      </div>
    );
  }
}

export default compose(
  // graphql(UserReviewListQuery, {
  //   name: 'userReviewList',
  //   options: ({hid, doctorId}) => ({
  //     variables: {
  //       hospitalId: hid,
  //       perPage: 10,
  //       currentPage: 1,
  //       doctorId
  //     },
  //   }),
  //   props: ({userReviewList: { loading, userReviewList, fetchMore}}) => {
  //     return {
  //     loading,
  //     userReviewList,
  //     loadMoreEntries(page) {
  //       return fetchMore({
  //         variables: {
  //           currentPage: page,
  //         },
  //         updateQuery: (previousResult, { fetchMoreResult }) => {
  //           if (!fetchMoreResult) {
  //             return previousResult;
  //           }
  //           fetchMoreResult.userReviewList.reviews = [
  //             ...previousResult.userReviewList.reviews,
  //             ...fetchMoreResult.userReviewList.reviews
  //           ];
  //           return Object.assign({}, previousResult, {
  //             userReviewList: fetchMoreResult.userReviewList,
  //           });
  //         },
  //       });
  //     },
  //     filterEntries(filter) {
  //       return fetchMore({
  //         variables: {
  //           ...filter
  //         },
  //         updateQuery: (previousResult, { fetchMoreResult }) => (
  //           fetchMoreResult || previousResult
  //         ),
  //       });
  //     }
  //   }}
  // }),
  graphql(HospitalReviewsQuery, {
    name: 'hospitalReviews',
    options: ({hid}) => ({
      variables: {
        hospitalId: hid,
        perPage: 5,
        currentPage: 1,
      },
    }),
    props: ({hospitalReviews: { loading, hospitalReviews, fetchMore}}) => {
      return {
        loading,
        hospitalReviews,
        loadMoreReview(page) {
          return fetchMore({
            variables: {
              currentPage: page,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) {
                return previousResult;
              }

              const newDdata = [
                ...previousResult.hospitalReviews.data,
                ...fetchMoreResult.hospitalReviews.data,
              ];
              // fetchMoreResult.hospitalReviews.reviews = [
              //   ...previousResult.hospitalReviews.data,
              //   ...fetchMoreResult.hospitalReviews.hospitalReviews.data
              // ];
              // return Object.assign({}, previousResult, {
              //   hospitalReviews: {
              //     data: newDdata,
              //     ...fetchMoreResult.hospitalReviews
              //   } ,
              // });

              fetchMoreResult.hospitalReviews.data = [
                ...previousResult.hospitalReviews.data,
                ...fetchMoreResult.hospitalReviews.data
              ];
              return Object.assign({}, previousResult, {
                hospitalReviews: fetchMoreResult.hospitalReviews,
              });


            },
          });
        },
        filterEntries(filter) {
          return fetchMore({
            variables: {
              ...filter
            },
            updateQuery: (previousResult, { fetchMoreResult }) => (
              fetchMoreResult || previousResult
            ),
          });
        }
      }}
  }),
  graphql(getHospitalQuery, {
    name: 'hospital',
    options: ({ hid }) => ({
      variables: { id: hid },
    }),
  }),
  // graphql(HospitalReviewsQuery, {
  //   name: 'jjk',
  //   options: ({hid}) => ({
  //     variables: {
  //       hospitalId: hid,
  //       perPage: 5,
  //       currentPage: 1,
  //     },
  //   }),
  // })
)(HospitalReview)
