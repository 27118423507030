import decode from 'jwt-decode';
import 'url-search-params-polyfill';
import {channelCheckIn} from './channel';
import {meQuery} from '../graphql/me';

// const BACKEND_URL = process.env.REACT_APP_API_URL;

export const isAuthenticated = () => {
  try {
    // 생각보다 localStorage 를 못쓰는 브라우져가 종종 등장한다. 2019/4/29 kosick
    if (!localStorage) {
      return false;
    }

    // https://stackoverflow.com/questions/46632093/getting-security-error-on-iphone-when-using-localstorage
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refreshToken');
    decode(token);
    decode(refreshToken);
  } catch (err) {
    if (!localStorage) {
      return false;
    }

    // decode 가 안되는 token 을 받은 경우 token 지우고 로그아웃 시킨다.
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    return false;
  }

  return true;
};

export const setToken = (token, refreshToken) => {
  if (!localStorage) {
    return null;
  }

  localStorage.setItem('token', token);
  localStorage.setItem('refreshToken', refreshToken);
};

export const meFetch = async (apolloClient) => {
  const me = await apolloClient.query({
    query: meQuery,
  });

  if (me && me.data && me.data.me) {
    return me.data.me;
  }

  return null;
};

export const channelLogin = (me) => {
  if (me) {
    channelCheckIn({
      id: me.id,
      name: me.username,
      mobileNumber: me.phonenum,
    });
  }
};

export const logout = async () => {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  // channelCheckOut();
};

export default isAuthenticated;
