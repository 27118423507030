import React from 'react';
import styled, { css } from 'styled-components';

const fakeDotImage = `data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg0K
PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAyMDAxMDkwNC8vRU4iDQogImh0dHA6Ly93d3cud
zMub3JnL1RSLzIwMDEvUkVDLVNWRy0yMDAxMDkwNC9EVEQvc3ZnMTAuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4wIiB
4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciDQogd2lkdGg9IjEuMDAwMDAwcHQiIGhlaWdodD0iMS4wMD
AwMDBwdCIgdmlld0JveD0iMCAwIDEuMDAwMDAwIDEuMDAwMDAwIg0KIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZT
WlkIG1lZXQiPg0KDQo8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjAwMDAwMCwxLjAwMDAwMCkgc2NhbGUoMC4xMDAwM
DAsLTAuMTAwMDAwKSINCmZpbGw9IiMwMDAwMDAiIHN0cm9rZT0ibm9uZSI+DQo8L2c+DQo8L3N2Zz4NCg==`;

const ab = css`
  position: absolute;
  top: 0;
  left: 0;
`;

const StyledBox = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const StyledRoundImage = styled.img.attrs(props => ({
  width: props.width || '100%',
  height: props.height || '100%',
  imgUrl: props.imgUrl,
  radius: props.radius || '50%',
}))`
  ${ab};
  width: 100%;
  height: 100%;
  background: url(${props => props.imgUrl}) no-repeat center center;
  background-size: cover;
  border-radius: ${props => props.radius}
`;

const Active = styled.div.attrs(props => ({
  width: props.width || '100%',
  height: props.height || '100%',
  imgUrl: props.imgUrl,
  radius: props.radius || '50%',
}))`
  ${ab};
  width: ${props => props.width};
  height: ${props => props.height};
  border-radius: ${props => props.radius};
  border: 2px solid #033e52;
`;

/**
 *
 * @param imgUrl
 * @param radius
 * @param active
 * @param className
 * @returns {*}
 * @constructor
 */
const Image = ({
  imgUrl, radius, active, className,
}) => (
  <StyledBox>
    <StyledRoundImage
      className={className}
      radius={radius}
      imgUrl={imgUrl}
      src={fakeDotImage}
    />
    {active && <Active />}
  </StyledBox>
);

export { Image };
